<template>
    <div id="viewerheader" v-if="initWebtoonInfo !== null && initEpisodeState !== null && initisShowViewer">
        <div class="container">
            <router-link tag="h1" to="/" class="logo">artube</router-link>
            <p class="back-list">{{initWebtoonInfo.titleName}}</p>
            <p>></p>
            <p>{{initEpisodeIdx}}화</p>
            <div class="home" @click="$router.push('/')" v-if="initWebtoonInfo !== null && initWebtoonInfo.viewType == 1">
                <img src="@/assets/images/viewer-header-home.svg" />
            </div>
            <div class="go-list" @click="$router.push('/Webtoon/Info/' + initWebtoonInfo.webToonIndex)" v-if="initWebtoonInfo !== null && initWebtoonInfo.viewType == 1">
                <img src="@/assets/images/viewer-header-menu.svg" />
            </div>
            <div class="level">
                <div class="prev" @click="prevEpisode" :class="[initEpisodeState.preEpisodeIdx === null ? 'none' : '']"></div>
                <div class="now">{{initEpisodeIdx}}화</div>
                <div class="next" @click="nextEpisode" :class="[initEpisodeState.nextEpisodeIdx === null ? 'none' : '']"></div>
            </div>
        </div>
        <Login :initIsShowPopup="isShowLoginPopup" @close="close('Login')" />
        <Buy  :initIsShowPopup="isShowBuyPopup" :initWebtoonInfo="initWebtoonInfo" :initEpisodeInfo="chagneEpisodeInfo" :initSiteConfig="$store.state.siteConfig" @close="close('Buy')"  />
        <PopupOK :initIsShowPopup="isShowPopupOkPopup" :initPopupOkMessage="initPopupOkMessage" @close="close('PopupOk')" />
    </div>
</template>

<script>

import Buy from '@/components/webtoon/Buy.vue'
import Login from '@/components/common/Login.vue'
import PopupOK from '@/components/common/PopupOK.vue'

export default {
    name: 'ViewerHeader',
    components: {
        Buy,
        Login,
        PopupOK,
    },
    data(){
        return {
            chagneEpisodeInfo: null,

            isShowLoginPopup: false,
            isShowBuyPopup: false,

            isShowPopupOkPopup: false,
            initPopupOkMessage: '',
            PopupOkCallbakPage: '',    // 확인 팝업에서 확인 버튼 눌렀을때 이동할 페이지
        }
    },
    props: {
        initWebtoonInfo: {
            type: Object,
            default: null
        },
        initEpisodeIdx:{
            type: Number,
            default: 0,
        },
        initEpisodeState: {
            type: Object,
            default: null
        },
        initisShowViewer: {
            type: Boolean,
            default: true,
        }
    },
    async created() {
        await this.$store.commit("setSiteConfig", this.host);
        window.scroll(0,0);
    },
    mounted(){
    },
    beforeDestroy() {
	},
    methods: {
        closePopup(){
            this.$emit('close');
        },
        async prevEpisode(){
            if(this.initEpisodeState.preEpisodeIdx !== null){
                this.checkEpisodeState(this.initWebtoonInfo.webToonIndex, this.initEpisodeState.preEpisodeIdx);
            }
        },
        async nextEpisode(){
            if(this.initEpisodeState.nextEpisodeIdx !== null){
                this.checkEpisodeState(this.initWebtoonInfo.webToonIndex, this.initEpisodeState.nextEpisodeIdx)
            }
        },
        async checkEpisodeState(wtIdx, epIdx){
            var param = 
            {
                ticket: this.$store.state.ticket,
                sign: this.$store.state.sign,
                viewAdult: this.$store.state.isCheckedAdult ? 1 : 0,

                webtoonIdx: wtIdx,
                episodeIdx: epIdx,
                lang: 'KR'
            }
            await this.$Axios.post(`${this.host}/episode/state`, param, { headers: { 'Pragma' : 'no-cache', 'Cache-Control': 'no-cache', 'Access-Control-Allow-Origin': '*', 'Content-type': 'application/json', }}).then(async(res) => {
                // console.log('----episode state----');
                // console.log(res);

                if(res.data.epData != null){
                    this.chagneEpisodeInfo = res.data.epData;
                }

                if (res.data.returnCode === 0) {
                    this.$router.push('/Webtoon/Viewer/' + wtIdx + '/' + epIdx);
                }
                else if(res.data.returnCode === 300){
                    this.initPopupOkMessage = '해당 작품은 운영상의 문제에 위반되어 열람 및 구매 할 수 없습니다.';
                    this.PopupOkCallbakPage = '/';
                    this.isShowPopupOkPopup = true;
                }
                else if(res.data.returnCode === 353){
                    this.initPopupOkMessage = '회당 회차는 운영상의 문제에 위반되어 열람 및 구매 할 수 없습니다.';
                    this.PopupOkCallbakPage = '/Webtoon/Info/' + this.initWebtoonInfo.webToonIndex;
                    this.isShowPopupOkPopup = true;
                }
                else if(res.data.returnCode === 503){   //로그인 필요
                    this.isShowLoginPopup = true;
                }
                else if(res.data.returnCode === 504){   //구매 필요
                    this.isShowBuyPopup = true;
                }
                else{
                    console.log(res.data.dsscription);
                }
            })
            .catch((error) => {
                console.log(error);
            });
        },
        backList(){
            this.$router.push('/Webtoon/Info/' + this.initWebtoonInfo.webToonIndex);
        },
        close(popupType){
            switch(popupType){
                case 'Login':
                    this.isShowLoginPopup = false;
                    break;
                case 'Buy':
                    this.isShowBuyPopup = false;
                    break;
                case 'PopupOk':
                    this.isShowPopupOkPopup = false;
                    if(this.PopupOkCallbakPage === 'reload'){
                        location.reload();
                    }
                    else if(this.PopupOkCallbakPage !== ''){
                        console.log(this.PopupOkCallbakPage);
                        // this.$router.push(this.PopupOkCallbakPage);
                    }
                    this.PopupOkCallbakPage = '';
                    break;
            }
        },
        
    },
}
</script>