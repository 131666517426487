<template>
    <div class="enrollment3">
        <Header :initHeaderType="initHeaderType" />
        <Login />
        <b-container>
            <div class="title_area">
                <p>회차 수정</p>
                <p>내용이나 데이터를 수정하게 되면 비공개 처리되며 등록신청을 하셔야 플랫폼에 공개됩니다.</p>
            </div>
            <b-row class="round_area">
                <b-col lg="2" md="2">회차</b-col>
                <b-col lg="10" md="10">
                    <p class="round">{{currEpisodeIdx}}</p>
                    <!-- <p class="ps">해당 회차는 자동으로 표기되며 수정 할 수 없습니다.</p> -->
                </b-col>
            </b-row>
            <b-row class="round_title">
                <b-col lg="2" md="2">회차제목</b-col>
                <b-col lg="10" md="10">
                    <div class="title_typing">
                        <input type="text" v-model="title" @input="checkTitle" maxlength="20">
                        <p>{{title.length}}/20</p>
                    </div>
                    <div class="round_check">
                        <p class="ps">제목은 띄어쓰기 포함 최대 20자 까지 가능합니다.</p>
                        <b-form-checkbox id="end" v-model="isComplete" name="end" :value="true" :unchecked-value="false">완결</b-form-checkbox>
                    </div>
                </b-col>
            </b-row>
            <b-row class="thumnail">
                <b-col lg="2" md="2">썸네일 <span>이미지</span></b-col>
                <b-col lg="10" md="10">
                    <div class="image-upload" @click="confirmChangeThumbnail">
                        <img :src="thumbnail.path" v-if="thumbnail !== null" />
                        <img :src="$store.state.siteConfig.cdnUrl + '/CHALLENGE/thumbnail/'+ webtoonInfo.ctCdnFolder+'/' + episodeState.ceUrl" alt="thumbnail" v-else-if="episodeState !== null && episodeState.ceUrl !== null && $store.state.siteConfig !== null">
                        <p v-else>208*140 <span>에피소드 썸네일</span></p>
                        <input class="display-none" id="idThumbnail" name="image" ref="files"  @change="uploadThumbnail" :accept="accept" type="file">
                    </div>
                    <p class="ps">
                        <span>- 파일명 : 특수문자 불가, 한글, 영문, 숫자만 가능합니다.  </span>
                        <span>- 썸네일 이미지 : 208*140px</span>
                        <span>- 이미지 파일 크기는 300KB이하, jpg만 업로드 가능합니다.</span>
                    </p>
                </b-col>
            </b-row>
            <b-row class="manuscript">
                <b-col lg="2" md="2">원고등록</b-col>
                <b-col lg="10" md="10">
                    <div class="webtoon-upload">
                        <div class="p_upload">
                            <div class="btn-upload" @click="confirmEpisodeInfoChange('upload')">작품 업로드</div>
                            <div class="p_list">
                                <label v-for="(image, index) in episodeImageList" :key="index" @change="changeHighlight(index)" :for="'check-' + index" class="list-item" :class="[image.isChecked ? 'selected': '']">
                                    <b-form-checkbox :id="'check-'+index" :name="'check-'+index" v-model="image.isChecked" :value="true" :unchecked-value="false">{{image.ciCreateDate+ '' + image.ciIdx + '.' + image.ciFormat}}</b-form-checkbox>
                                </label>
                            </div>
                            <div class="gray_part">
                                <b-form-checkbox v-model="isCheckedAll" id="checkboxAll" name="checkboxAll" :value="true" :unchecked-value="false" @change="checkAll"></b-form-checkbox>
                                <div class="button"  @click="confirmEpisodeInfoChange('positionUp')"></div>
                                <div class="button down" @click="confirmEpisodeInfoChange('positionDown')"></div>
                                <div class="btn" @click="confirmEpisodeInfoChange('delete')">삭제</div>
                            </div>
                        </div>
                        <div class="preview_cover">
                            <a class="full" v-if="episodeImageList.length > 0" @click="previewAll">전체 미리보기</a>
                            <div class="preview">
                                <div>
                                    <p class="one-image" v-if="imagePreview === ''">원고 미리보기</p>
                                    <img class="show-img img-responsive" :src="imagePreview" v-else>
                                </div>
                            </div>
                        </div>
                        <div>
                            <input class="display-none" :id="idUpload" @change="uploadFieldChange" name="images" :multiple="multiple" :accept="episodeAccept" type="file" :disabled="disabled" >
                        </div>

                        <PopupFullPreview :initIsShowPopup="isShowPreviewPopup" :initImageList="episodeImageList" @close="previewClose"/>
                    </div>
                    <div class="ps_box">
                        <p class="ps">- jpg, gif 확장자만 가능합니다.</p>
                        <p class="ps">- 가로사이즈는 720px만 가능하며 세로 사이즈는 제한이 없습니다.</p>
                        <p class="ps">- 원고 이미지 1개당 용량 5MB 이하, 총 업로드한 원고는 <b>50MB</b> 이하로 제한됩니다.</p>
                        <!-- <p class="ps">- 선택 파일을 드래그하여 순서를 바꿀 수 있습니다.</p> -->
                    </div>
                </b-col>
            </b-row>
            <div class="notice-box">
                <p class="ps2">* 등록신청을 해야 이후 플랫폼에 공개됩니다. 뒤로가기를 할 경우 해당 작품은 수정된 부분이 있을 시 비공개 되며 자동 저장 됩니다.</p>
            </div>
            <div class="buttons">
                <router-link tag="p" :to="'/League/Writer/Webtoon/Info/' + currWebtoonIdx" class="cancel">뒤로가기</router-link>
                <p class="next" @click="editEpisode(false)">저장</p>
                <p class="save" @click="editEpisode(true)">등록 신청</p>
            </div>
        </b-container>
        <PopupOK :initIsShowPopup="isShowPopupOkPopup" :initPopupOkMessage="initPopupOkMessage" @close="close('PopupOk')" />
        <PopupConfirm :initIsShowPopup="isShowPopupConfirmPopup" :initPopupConfrimMessage="initPopupConfrimMessage" @confirmCancel="close('PopupConfirm')" @confirmOk="confirmOk" />
        <PopupProgress :initIsShowPopup="isShowPopupProgress" />
        <Footer />
    </div>
</template>

<script>
import Header from '@/components/common/Header.vue'
import Footer from '@/components/common/Footer.vue'
import Login from '@/components/common/Login.vue'
import PopupOK from '@/components/common/PopupOK.vue'
import PopupProgress from '@/components/league/PopupProgress.vue'
import PopupFullPreview from '@/components/league/PopupFullPreview.vue'
import PopupConfirm from '@/components/common/PopupConfirm.vue'
import { findIndex } from 'lodash'

export default {
    name: 'LeagueWriterEpisodeEdit',
    pageCategory: 'League',
    components: {
        Header,
        Footer,
        Login,
        PopupOK,
        PopupProgress,
        PopupFullPreview,
        PopupConfirm,
    },
    data(){
        return {
            initIsEdit: true,

            writerInfo: null,
            currWebtoonIdx: 0,
            currEpisodeIdx: 0,
            webtoonInfo: null,
            episodeState: null,
            episodeImageList: [],

            title: '',

            isShowPopupOkPopup: false,
            initPopupOkMessage: '',
            PopupOkCallbakPage: '',    // 확인 팝업에서 확인 버튼 눌렀을때 이동할 페이지

            isShowPopupConfirmPopup: false,
            initPopupConfrimMessage: '',
            PopupConfirmCallbakPage: '',    // 확인 팝업에서 확인 버튼 눌렀을때 이동할 페이지
            
            changeEpisodeType: '',
            isShowEpisodeChangeAlert: false,

            isComplete: false,
            isAgree: false,
            accept: 'image/jpeg,image/jpg',
            thumbnail: null,
            // thumbnailFormData: null,

            currentIndexImage: 0,
            isCheckedAll: false,
            fileMaxSize: 5 * 1024 * 1024,   //5MB

            isShowPreviewPopup: false,
            isShowPopupProgress: false,

            episodeAccept: 'image/gif,image/jpeg,image/jpg',
            multiple: true,
            maxImage: 100,
            idUpload: 'image-upload',
            disabled: false,

            initHeaderType: 'type1',
        }
    },
    computed: {
        imagePreview () {
            let index = findIndex(this.episodeImageList, { highlight: 1 })
            if(index > -1){
                if(this.episodeImageList[index].isChecked){
                    return this.episodeImageList[index].path;
                } else {
                    return '';
                }
            } else {
                return '';
            }
        },
    },
    async created(){
        var isPc = this.isPc();
        if(!isPc){
            this.initPopupOkMessage = '모바일 환경에서는 감상만 가능합니다.<br/>작가 활동은 PC 환경에서 이용해주세요.'
            this.PopupOkCallbakPage = '/League';
            this.isShowPopupOkPopup = true;
            return;
        }
        // await this.mixinSiteConfigCheck();
        await this.mixinLoginCheck();

        var checkWriter = await this.checkWriter(); //작가 등록 여부 확인
        if(checkWriter === null){   //체크 에러
            this.initPopupOkMessage = '작가 정보 체크 에러'
            this.PopupOkCallbakPage = '/League';
            this.isShowPopupOkPopup = true;
            return;
        }
        else if(!checkWriter){  //작가 등록 안된 상태
            this.initPopupOkMessage = '접근 권한이 없습니다.'
            this.PopupOkCallbakPage = '/League';
            this.isShowPopupOkPopup = true;
            return;
        }        

        if(this.$route.params.wtidx){   //Webtoon Idx 
            this.currWebtoonIdx = Number(this.$route.params.wtidx);
            await this.getWebtoonInfo();
        }
        else{
            this.$router.push('/League')
        }

        if(this.$route.params.epidx){   //Episode Idx 
            this.currEpisodeIdx = Number(this.$route.params.epidx);
            await this.getEpisodeState();
            await this.getEpisodeImageList();
        }
        else{
            this.$router.push('/League')
        }
    },
    methods:{
        async checkWriter(){    //작가 체크
            var param = 
            {
                sign: this.$store.state.sign,
                ticket: this.$store.state.ticket,
                viewAdult: this.$store.state.isCheckedAdult ? 1: 0
            }
            return await this.$Axios.post(`${this.league_host}/challenge/writer/check`, param, { headers: { 'Pragma' : 'no-cache', 'Cache-Control': 'no-cache', 'Access-Control-Allow-Origin': '*', 'Content-type': 'application/json', }}).then(async(res) => {
                // console.log(res.data.writer);
                if (res.data.returnCode === 0) {    //작가 등록됨
                    this.writerInfo = res.data.writer;
                    return true;
                }
                else if(res.data.returnCode === 41){    //작가 등록 안됨
                    return false;
                }
                else{   //기타 에러
                    return null;
                }
            })
            .catch((error) => {
                console.log(error);
                return null;
            });
        },
        async getWebtoonInfo(){ //웹툰 정보
            var param = 
            {
                sign: this.$store.state.sign,
                ticket: this.$store.state.ticket,
                viewAdult: this.$store.state.isCheckedAdult ? 1 : 0,
                wtIdx: this.currWebtoonIdx,
                writerIdx: this.writerInfo.cwWriterIdx,
                wrName: this.writerInfo.cwPenName
            }
            // console.log(param)
            await this.$Axios.post(`${this.league_host}/challenge/writer/webtoon`, param, { headers: { 'Pragma' : 'no-cache', 'Cache-Control': 'no-cache', 'Access-Control-Allow-Origin': '*', 'Content-type': 'application/json', }}).then(async(res) => {
                // console.log('--webtoon info--')
                // console.log(res.data);
                if (res.data.returnCode === 0) {
                    this.webtoonInfo = res.data.data;
                }
                else if(res.data.returnCode === 300){
                    this.initPopupOkMessage = '존재하지 않는 웹툰 입니다.';
                    this.PopupOkCallbakPage = '/League/Writer/Info';
                    this.isShowPopupOkPopup = true;
                }
            })
            .catch((error) => {
                console.log(error);
            });
        },
        async getEpisodeState(){    //에피소드 정보 및 상태
            var param = 
            {
                ticket: this.$store.state.ticket,
                sign: this.$store.state.sign,
                viewAdult: this.$store.state.isCheckedAdult ? 1 : 0,

                webtoonIdx: this.currWebtoonIdx,
                episodeIdx: this.currEpisodeIdx,
                writerIdx: this.writerInfo.cwWriterIdx
                // lang: 'KR'
            }
            // console.log(param)
            await this.$Axios.post(`${this.league_host}/challenge/writer/episode/state`, param, { headers: { 'Pragma' : 'no-cache', 'Cache-Control': 'no-cache', 'Access-Control-Allow-Origin': '*', 'Content-type': 'application/json', }}).then(async(res) => {
                // console.log('----episode state----');
                // console.log(res.data);
                if (res.data.returnCode === 0) {
                    this.episodeState = res.data.epData;
                    this.title = this.episodeState.ceTitleName;

                }
                // else{
                //     this.$router.push('/');
                // }
            })
            .catch((error) => {
                console.log(error);
            });
        },
        async getEpisodeImageList(){    //에피소드 원고 이미지 리스트
            var param = 
            {
                sign: this.$store.state.sign,
                ticket: this.$store.state.ticket,
                viewAdult: this.$store.state.isCheckedAdult ? 1 : 0,
                episodeIdx: this.currEpisodeIdx,
                webtoonIdx: this.currWebtoonIdx,
                writerIdx: this.writerInfo.cwWriterIdx,
                pageNo: 1,
                pageSize: 1000,
                // lang: 'KR'
            }
            await this.$Axios.post(`${this.league_host}/challenge/writer/episode/image/list`, param, { headers: { 'Pragma' : 'no-cache', 'Cache-Control': 'no-cache', 'Access-Control-Allow-Origin': '*', 'Content-type': 'application/json', }}).then(async(res) => {
                // console.log('----image list----');
                // console.log(res.data);
                this.episodeImageList = [];
                if (res.data.returnCode === 0) {
                    res.data.list.forEach((item)=> {
                        this.addEpisodeImage(item);
                    });
                }
                else{
                    console.log(res.data.description);
                    // this.$router.push('/League');
                }
            })
            .catch((error) => {
                console.log(error);
            });
        },
        changeHighlight (currentIndex) {    //원고 미리보기에 표시될 이미지 계산
            this.isCheckedAll = false;
            this.currentIndexImage = currentIndex;
            let arr = this.episodeImageList;
            this.episodeImageList = [];
            arr.map((item, index) => {
                if (currentIndex === index) {
                    if(item.isChecked) item.highlight = 1;
                    else item.highlight = 0;
                } else {
                    item.highlight = 0;
                }
                return item;
            })
            this.episodeImageList = arr;
        },
        async addEpisodeImage(item){    //원고 리스트에 이미지 정보 추가
            var image = {
                ciCreateDate : item.ciCreateDate,
                ciEpisodeIdx : item.ciEpisodeIdx,
                ciFormat : item.ciFormat,
                ciIdx : item.ciIdx,
                ciPageIdx : item.ciPageIdx,
                ciUpdateDate : item.ciUpdateDate,
                ciUrl : item.ciUrl,
                ciWebtoonIdx : item.ciWebtoonIdx,
                isChecked: false,
                path: this.league_host + '/challenge/episode/image/view/' + this.$store.state.ticket + '/'+ this.$store.state.sign + '/'+ this.writerInfo.cwWriterIdx + '/'+ this.currWebtoonIdx + '/'+ this.currEpisodeIdx + '/'+ item.ciPageIdx,
                highlight: 0
            }
            await this.episodeImageList.push(image);
        },
        confirmChangeThumbnail(){
            this.initPopupConfrimMessage = '썸네일 교체 시 서버에 실시간 저장되며 기존 이미지는 삭제 됩니다.<br/>썸네일 이미지를 수정 하시겠습니까?';
            this.PopupConfirmCallbackPage = 'changeThumbnail';
            this.isShowPopupConfirmPopup = true;
        },
        async uploadThumbnail(e) {  // 썸네일 이미지 변경했을 때 호출
            let files = e.target.files || e.dataTransfer.files;
            if (!files.length) {
                return false;
            }
            let file = files[0];
            let fileName = file.name.substring(0,file.name.lastIndexOf('.'));
            let filter =  /^[ㄱ-ㅎ|가-힣|a-z|A-Z|0-9|_|-|]+$/;
            var fileMaxSize = 1 * 300 * 1024;

            if(!filter.test(fileName)){
                this.initPopupOkMessage = '파일명은 한글, 영문, 숫자만 가능합니다.';
                this.isShowPopupOkPopup = true;
            }
            else if(file.size > fileMaxSize){
                this.initPopupOkMessage = '최대 파일 사이즈는 300KB 입니다.';
                this.isShowPopupOkPopup = true;
            }else{
                this.createImage(file, 208, 140);
            }
            if (document.getElementById('idThumbnail')) {
                document.getElementById('idThumbnail').value = '';
            }
        },
        createImage (file, limit_width, limit_height) {    //썸네일 이미지 화면에 실시간으로 표시
            var that = this;
            let reader = new FileReader();
            reader.onload = (e) => {
                if(limit_height === undefined) return;
                var image = new Image();
                image.src = e.target.result;

                image.onload = async function () {
                    var height = this.height;
                    var width = this.width;
                    if (height !== limit_height || width !== limit_width) {
                        that.initPopupOkMessage = '썸네일 이미지 크기는 ' + limit_width + '*' + limit_height + 'px 로 제한됩니다.'
                        that.isShowPopupOkPopup = true;
                    } else {
                        var isSuccessUpload = await that.reqUploadThumbnail(file);
                        if(isSuccessUpload)
                            that.thumbnail = {name: file.name, path: image.src};
                    }
                };
            }
            reader.readAsDataURL(file);
        },
        async reqUploadThumbnail(file){ //썸네일 이미지 CDN에 업로드

            let formData = new FormData();
            formData.append('ticket', this.$store.state.ticket);
            formData.append('sign', this.$store.state.sign);
            formData.append('viewAdult', this.$store.state.isCheckedAdult ? 1 : 0);
            formData.append('webtoonkey', this.webtoonInfo.ctCdnFolder );
            formData.append('writerIdx',  this.writerInfo.cwWriterIdx);
            formData.append('uploadType',  3);
            formData.append('episodeNumber',  this.currEpisodeIdx);
            formData.append('file', file);

            return await this.$Axios.post(`${this.league_host}/challenge/writer/imageUpload/thumbnail`, formData, { headers: { 'Pragma' : 'no-cache', 'Cache-Control': 'no-cache', 'Access-Control-Allow-Origin': '*', 'Content-type': 'multipart/form-data', }}).then(async(res) => {
                // console.log(res.data);
                if (res.data.returnCode === 0) {
                    this.createImage(file);
                    this.initPopupOkMessage = '썸네일 업로드 완료';
                    this.isShowPopupOkPopup = true;
                    return true;
                }
                else{
                    this.initPopupOkMessage = '썸네일 업로드 실패';
                    this.isShowPopupOkPopup = true;
                    return false;
                }
            })
            .catch((error) => {
                console.log(error);
                this.initPopupOkMessage = '썸네일 업로드 실패';
                this.isShowPopupOkPopup = true;
                return false;
            });
        },
        
        confirmEpisodeInfoChange(type){
            this.changeEpisodeType = type;
            if(!this.isShowEpisodeChangeAlert){
                this.initPopupConfrimMessage = '원고 수정(작품 업로드, 순서 변경, 삭제) 시 변경된 내용은 서버에 실시간 저장되며, 이전 상태로 되돌릴 수 없습니다.';
                this.PopupConfirmCallbackPage = 'changeEpisodeInfo';
                this.isShowPopupConfirmPopup = true;
            }
            else{
                switch(type){
                    case 'upload' :
                        document.getElementById(this.idUpload).click();
                        break;
                    case 'positionUp' :
                        this.changePosition('up');
                        break;
                    case 'positionDown' :
                        this.changePosition('down');
                        break;
                    case 'delete' :
                        this.deleteImage();
                        break;
                }
                this.changeEpisodeType = '';
            }
        },
        async uploadFieldChange (e) {   //원고 이미지 추가 등록
            this.isShowPopupProgress = true;
             let files = e.target.files || e.dataTransfer.files;
            if (!files.length) {
                this.isShowPopupProgress = false;
                return false;
            }
            if (!this.isValidNumberOfImages(files.length)) {
                this.isShowPopupProgress = false;
                // this.initPopupOkMessage = '파일은 최대 100개까지 업로드할 수 있습니다.';
                // this.isShowPopupOkPopup = true;
                return false;
            }
            var that = this;
            var isPassLoop = false;
            files = [].slice.call(files);
            files.sort((a, b) => a.name.localeCompare(b.name));

            for(const file of files) {
                let fileName = file.name.substring(0,file.name.lastIndexOf('.'));
                let filter =  /^[ㄱ-ㅎ|가-힣|a-z|A-Z|0-9|_|-|]+$/;
                if(!isPassLoop){
                    if(file.size > this.fileMaxSize){
                        this.initPopupOkMessage = '최대 파일 사이즈는 5MB 입니다.<br/>( ' + file.name + ' )';
                        this.isShowPopupOkPopup = true;
                        isPassLoop = true;
                        break;
                    }
                    else if(!filter.test(fileName)){
                        this.initPopupOkMessage = '파일명은 한글, 영문, 숫자만 가능합니다.<br/>( ' + file.name + ' )';
                        this.isShowPopupOkPopup = true;
                        isPassLoop = true;
                        break;
                    }
                    else{

                        const fileReader = new this.SyncFileReader(file);
                        var image = await fileReader.getImageObject();
                        var width = image.width;

                        if (width !== 720) {
                            that.initPopupOkMessage = '원고 이미지 가로 사이즈는 720px 로 제한됩니다.<br/>( ' + file.name + ' )';
                            that.isShowPopupOkPopup = true;
                            isPassLoop = true;
                        } else {
                            var isSuccessUpload = await that.reqUploadEpisodeImage(file);
                            if(!isSuccessUpload){
                                isPassLoop = true;
                            }
                        }
                    }
                }
            }
            if(!isPassLoop){
                this.isShowPopupProgress = false;
                this.initPopupOkMessage = '원고 이미지 업로드 완료';
                this.isShowPopupOkPopup = true;
            }
            if (document.getElementById(this.idUpload)) {
                document.getElementById(this.idUpload).value = [];
            }
        },
        checkFileSize(file){    //파일 크기 체크(5MB)
            if(file.size > this.fileMaxSize){
                return false;
            }
            return true;
        },
        SyncFileReader(file) {
            let self = this;
            let ready = false;
            let result = '';
            let image = new Image();

            const sleep = function (ms) {
            return new Promise(resolve => setTimeout(resolve, ms));
            }

            self.readAsDataURL = async function() {
                while (ready === false) {
                    await sleep(100);
                }

                return result;
            }
            self.getImageObject = async function(){
                while (ready === false) {
                    await sleep(100);
                }
                return image;
            }

            const reader = new FileReader();
            reader.onloadend = function(evt) {
                result = evt.target.result;
                image.src = result;
                image.onload = function () {
                    ready = true;
                }
            };
            reader.readAsDataURL(file);
        },
        async reqUploadEpisodeImage(file){  //원고 이미지 CDN 업로드
            try{
                let formData = new FormData();
                formData.append('ticket', this.$store.state.ticket);
                formData.append('sign', this.$store.state.sign);
                formData.append('viewAdult', this.$store.state.isCheckedAdult ? 1 : 0);
                formData.append('wtIdx',  this.currWebtoonIdx);
                formData.append('epIdx',  this.currEpisodeIdx);
                formData.append('writerIdx',  this.writerInfo.cwWriterIdx);
                formData.append('file', file);

                return await this.$Axios.post(`${this.league_host}/challenge/writer/webtoon/episode/update/create`, formData, { headers: { 'Pragma' : 'no-cache', 'Cache-Control': 'no-cache', 'Access-Control-Allow-Origin': '*', 'Content-type': 'multipart/form-data', }}).then(async(res) => {
                    // console.log('----episode update create----');
                    // console.log(res.data);
                    if (res.data.returnCode === 0) {
                        this.addEpisodeImage(res.data.episodeImage);
                        return true;
                    }
                    else if (res.data.returnCode === 610){
                        this.isShowPopupProgress = false;
                        this.initPopupOkMessage = '업로드 가능한 용량을 초과 했습니다. (회차별 최대 용량 : 50MB)';
                        this.isShowPopupOkPopup = true;
                        return false;
                    }
                    else{
                        this.isShowPopupProgress = false;
                        this.initPopupOkMessage = '파일 업로드 실패<br/>( ' + file.name + ' )';
                        this.isShowPopupOkPopup = true;
                        return false;
                    }
                })
            }catch(err){
                this.isShowPopupProgress = false;
                this.initPopupOkMessage = '파일 업로드 실패<br/>( ' + file.name + ' )';
                this.isShowPopupOkPopup = true;
                return false;
            }
        },
        async deleteImage(){      //체크 선택된 원고 이미지 삭제
            this.isShowPopupProgress = true;

            var isSuccessTotalFiles = true;
            var deletedImageFile = [];
            for(const image of this.episodeImageList){
                if(image.isChecked){
                    var isSuccessDelete = await this.reqDeleteEpisodeImage(image)   //CDN에서 삭제 후 list에서 삭제할 아이템 선별
                    if(isSuccessDelete){
                        deletedImageFile.push(image);
                    }
                }
            }
            for(const item of deletedImageFile){    //list에서 지워져야할 item 제거
                let delIndex = findIndex(this.episodeImageList, { ciIdx: item.ciIdx })
                this.episodeImageList.splice(delIndex, 1)
            }
            if(isSuccessTotalFiles){
                this.isShowPopupProgress = false;
                this.initPopupOkMessage = '원고 이미지 삭제 완료';
                this.isShowPopupOkPopup = true;
            }
            if (document.getElementById(this.idUpload)) {
                document.getElementById(this.idUpload).value = [];
            }
            if(this.isCheckedAll){
                this.isCheckedAll = false;
            }

        },
        async reqDeleteEpisodeImage(item){    //에피소드 원고 이미지 리스트
            var param = 
            {
                sign: this.$store.state.sign,
                ticket: this.$store.state.ticket,
                viewAdult: this.$store.state.isCheckedAdult ? 1 : 0,
                wtIdx: this.currWebtoonIdx,
                epIdx: this.currEpisodeIdx,
                writerIdx: this.writerInfo.cwWriterIdx,
                epPageIdx: item.ciPageIdx
            }
            return await this.$Axios.post(`${this.league_host}/challenge/writer/webtoon/episode/update/del`, param, { headers: { 'Pragma' : 'no-cache', 'Cache-Control': 'no-cache', 'Access-Control-Allow-Origin': '*', 'Content-type': 'application/json', }}).then(async(res) => {
                // console.log('----episode update del----');
                // console.log(res.data);
                if (res.data.returnCode === 0) {
                    return true;
                }
                else{
                    console.log(res.data.description);
                    // this.$router.push('/League');
                    return false;
                }
            })
            .catch((error) => {
                console.log(error);
                return false;
            });
        },
        async changePosition(type){       //원고 순서 변경(체크 선택된 1개만 가능)
            var checkedItemCount = 0;
            var fromIndex = findIndex(this.episodeImageList, { isChecked: true });
            var toIndex = -1;
            this.episodeImageList.forEach(item =>{
                if(item.isChecked) checkedItemCount++;
            })
            if(checkedItemCount === 1){
                if(type === 'up' && fromIndex > 0){
                    toIndex = fromIndex--;
                } else if(type === 'down' && (fromIndex + 1) < this.episodeImageList.length){
                    toIndex = fromIndex++;
                } else {
                    return;
                }
                this.isShowPopupProgress = true;
                var fromItem = this.episodeImageList[fromIndex];
                var toItem = this.episodeImageList[toIndex];

                var isSuccessSwap = await this.reqSwapEpisodeImage(fromItem, toItem);

                if(isSuccessSwap){
                    this.episodeImageList.splice(fromIndex, 1);
                    this.episodeImageList.splice(toIndex, 0, fromItem);
                }
                this.isShowPopupProgress = false;
                
            }
            // this.$emit('imageDataChange', this.images);
        },
        async reqSwapEpisodeImage(fromItem, toItem){    //에피소드 원고 이미지 리스트
            var param = 
            {
                sign: this.$store.state.sign,
                ticket: this.$store.state.ticket,
                viewAdult: this.$store.state.isCheckedAdult ? 1 : 0,
                wtIdx: this.currWebtoonIdx,
                epIdx: this.currEpisodeIdx,
                writerIdx: this.writerInfo.cwWriterIdx,
                swapEpPageIdx1: fromItem.ciPageIdx,
                swapEpPageIdx2: toItem.ciPageIdx
            }
            return await this.$Axios.post(`${this.league_host}/challenge/writer/webtoon/episode/update/swap`, param, { headers: { 'Pragma' : 'no-cache', 'Cache-Control': 'no-cache', 'Access-Control-Allow-Origin': '*', 'Content-type': 'application/json', }}).then(async(res) => {
                // console.log('----episode update swap----');
                // console.log(res.data);
                if (res.data.returnCode === 0) {
                    await this.changeEpisodeImage(res.data.episodeImage1);
                    await this.changeEpisodeImage(res.data.episodeImage2);
                    return true;
                }
                else{
                    console.log(res.data.description);
                    // this.$router.push('/League');
                    return false;
                }
            })
            .catch((error) => {
                console.log(error);
                return false;
            });
        },
        async changeEpisodeImage(item){    //원고 리스트에 이미지 정보 추가
            let index = findIndex(this.episodeImageList, { ciIdx: item.ciIdx })
            this.episodeImageList[index].ciCreateDate = item.ciCreateDate;
            this.episodeImageList[index].ciEpisodeIdx = item.ciEpisodeIdx;
            this.episodeImageList[index].ciFormat = item.ciFormat;
            this.episodeImageList[index].ciIdx = item.ciIdx;
            this.episodeImageList[index].ciPageIdx = item.ciPageIdx;
            this.episodeImageList[index].ciUpdateDate = item.ciUpdateDate;
            this.episodeImageList[index].ciUrl = item.ciUrl;
            this.episodeImageList[index].ciWebtoonIdx = item.ciWebtoonIdx;
            this.episodeImageList[index].path = this.league_host + '/challenge/episode/image/view/' + this.$store.state.ticket + '/'+ this.$store.state.sign + '/'+ this.writerInfo.cwWriterIdx + '/'+ this.currWebtoonIdx + '/'+ this.currEpisodeIdx + '/'+ item.ciPageIdx;
        },
        isValidNumberOfImages (amount) {    //한번에 업로드 할수 있는 파일 개수 체크 (최대 100개)
            if (amount > this.maxImage) {
                this.$emit('limit-exceeded', amount);
                return false;
            } else {
                return true;
            }
        },
        editEpisode(isReqInspect){  //회차 수정
            if(!isReqInspect){   // 회차 저장일 경우 팝업 띄우고 종료
                this.initPopupConfrimMessage = '수정된 내용을 저장하시겠습니까?';
                this.PopupConfirmCallbackPage = 'updateEpisode';
                this.isShowPopupConfirmPopup = true;
            } else {
                this.initPopupConfrimMessage = '수정된 내용 저장 후 등록 신청하시겠습니까?<br/>2~3일의 검수 시간이 소요됩니다.';
                this.PopupConfirmCallbackPage = 'updateEpisodeInspect';
                this.isShowPopupConfirmPopup = true;
            }
        },
        async reqEpisodeUpdate(isReqInspect){    //에피소드 원고 이미지 리스트
            var that = this;
            this.isShowPopupProgress = true;

            var param = 
            {
                sign: this.$store.state.sign,
                ticket: this.$store.state.ticket,
                viewAdult: this.$store.state.isCheckedAdult ? 1 : 0,
                wtIdx: this.currWebtoonIdx,
                epIdx: this.currEpisodeIdx,
                writerIdx: this.writerInfo.cwWriterIdx,
                epTitle: this.title,
                complete: this.isComplete ? 1 : 0
            }
            return await this.$Axios.post(`${this.league_host}/challenge/writer/webtoon/episode/update`, param, { headers: { 'Pragma' : 'no-cache', 'Cache-Control': 'no-cache', 'Access-Control-Allow-Origin': '*', 'Content-type': 'application/json', }}).then(async(res) => {
                // console.log('----episode update----');
                // console.log(res.data);
                if (res.data.returnCode === 0) {
                    if(!isReqInspect){   // 회차 저장일 경우 팝업 띄우고 종료
                        setTimeout(()=>{
                            that.isShowPopupProgress = false;
                            that.initPopupOkMessage = '회차 저장 완료'
                            that.PopupOkCallbakPage = '/League/Writer/Webtoon/Info/' + this.currWebtoonIdx;
                            that.isShowPopupOkPopup = true;
                        }, 3000)
                    } else {
                        var isSuccessReqUpload = await this.reqEpisodeInspect();   //회차 정보&원고 이미지 업로드
                        if(isSuccessReqUpload){
                            setTimeout(()=>{
                                that.isShowPopupProgress = false;
                                that.initPopupOkMessage = '회차 저장 및 등록 신청 완료'
                                that.PopupOkCallbakPage = '/League/Writer/Webtoon/Info/' + this.currWebtoonIdx;
                                that.isShowPopupOkPopup = true;
                            }, 3000)
                        }
                        else{
                            this.isShowPopupProgress = false;
                            this.initPopupOkMessage = '회차 저장 완료, 등록 신청 실패'
                            this.PopupOkCallbakPage = '/League/Writer/Webtoon/Info/' + this.currWebtoonIdx;
                            this.isShowPopupOkPopup = true;
                        }
                    }
                }
                else{
                    console.log(res.data.description);
                    // this.$router.push('/League');
                    return false;
                }
            })
            .catch((error) => {
                console.log(error);
                return false;
            });
        },
        async reqEpisodeInspect(){
            var param = 
            {
                sign: this.$store.state.sign,
                ticket: this.$store.state.ticket,
                viewAdult: this.$store.state.isCheckedAdult ? 1 : 0,

                wtIdx: this.currWebtoonIdx,
                epIdx: this.currEpisodeIdx,
                writerIdx: this.writerInfo.cwWriterIdx,
            }
            // console.log(param);
            return await this.$Axios.post(`${this.league_host}/challenge/writer/episode/inspect`, param, { headers: { 'Pragma' : 'no-cache', 'Cache-Control': 'no-cache', 'Access-Control-Allow-Origin': '*', 'Content-type': 'application/json', }}).then(async(res) => {
                // console.log('--episode inspect--')
                // console.log(res.data);
                if (res.data.returnCode === 0) {
                    return true;
                }
                else{
                    return false;
                }
                // else if(res.data.returnCode === 300){
                //     this.initPopupOkMessage = '존재하지 않는 웹툰 입니다.';
                //     this.PopupOkCallbakPage = '/';
                //     this.isShowPopupOkPopup = true;
                // }
                // else{
                //     // console.log(5);
                //     this.$router.push('/');
                // }
            })
            .catch((error) => {
                console.log(error);
                return false;
            });
        },
        checkAll(){     //체크박스 전체 선택 & 전체 해제
            var isChecked = this.isCheckedAll;
            this.episodeImageList.forEach(item=>{
                if(item.isChecked === false && item.highlight === 1){item.highlight = 0;}
                item.isChecked = isChecked;
            })
        },
        previewAll(){   //전체 미리보기 열기
            this.isShowPreviewPopup = true;
        },
        previewClose(){ //전체 미리보기 끄기
            this.isShowPreviewPopup = false;
        },
        checkTitle(){
            var filter = /[^a-z|A-Z|0-9|ㄱ-ㅎ|가-힣|\-|(),._!:/'"?|\s+]/g;
            this.title = this.title.replace(filter, "");
        },
        close(popupType){   //팝업 끄기
            switch(popupType){
                case 'PopupConfirm':
                    this.isShowPopupConfirmPopup = false;
                    break;
                case 'PopupOk':
                    this.isShowPopupOkPopup = false;
                    if(this.PopupOkCallbakPage === 'reload'){
                        location.reload();
                    }
                    else if(this.PopupOkCallbakPage !== ''){
                        this.$router.push(this.PopupOkCallbakPage);
                    }
                    this.PopupOkCallbakPage = '';
                    break;
            }
        },
        confirmOk(){
            if(this.PopupConfirmCallbackPage === 'updateEpisode'){
                this.reqEpisodeUpdate(false);
            } else if(this.PopupConfirmCallbackPage === 'updateEpisodeInspect'){
                this.reqEpisodeUpdate(true);
            } else if(this.PopupConfirmCallbackPage === 'changeThumbnail'){
                document.getElementById('idThumbnail').click();
            } else if(this.PopupConfirmCallbackPage === 'changeEpisodeInfo'){
                this.isShowEpisodeChangeAlert = true;
                this.confirmEpisodeInfoChange(this.changeEpisodeType);
            }
            this.isShowPopupConfirmPopup = false;
        },
    }
}
</script>