<template>
    <div class="charge wrap">
        <Header :initHeaderType="initHeaderType" />
        <b-container>
            <div class="menu_left_box">
                <Chargenav :selectedType="NavSelecteType" />
            </div>
            <div class="charge_box">
                <div class="title">
                    <h1>아트포인트 충전</h1>
                    <p>아튜브 웹툰에서 사용할 아트포인트를 충전할 수 있습니다.</p>
                </div>
                <div class="pay">
                    <router-link tag="p" to="/Point/Charge" :class="isPurple ? 'on' : null">일반 결제</router-link>
                    <router-link tag="p" to="/Point/Charge/ATT" :class="isPurple ? null : 'on'">ATT Coin 결제</router-link>
                </div>
                <div class="mobile_item">
                    <p>아트포인트 패키지</p>
                    <p>충전 금액이 커질수록 늘어나는 보너스 혜택! 부족한 아트포인트를 신용/페크카드로 충전하여 사용하실 수 있습니다.</p>
                </div>
                <div class="banner_box">
                    <!-- <h2>아트포인트 패키지</h2>
                    <p>충전 금액이 커질수록 늘어나는 보너스 혜택!<br>부족한 아트포인트를 신용/체크카드로 충전하여 사용하실 수 있습니다.</p> -->
                    <div class="banner" v-if="isPurple">
                        <Banner :initBannerNumber="bannerNumber1" v-if="$store.state.chargeCount <= 0"/>
                        <Banner :initBannerNumber="bannerNumber2" v-else/>
                    </div>
                    <div class="banner" v-else>
                        <Banner :initBannerNumber="bannerNumber3" />
                    </div>
                </div>
                <!-- 일반 결제 시작 -->
                <div v-if="isPurple">
                    <div class="first_pay" v-if="$store.state.chargeCount <= 0">
                        <p>첫 결제, 생애 단 한번 최대 6만원 할인!</p>
                        <span>본 상품은 구매 시 다른 가격으로 중복 구매할 수 없으며, 당사 사정에 따라 종료될 수 있습니다.</span>
                    </div>
                    <div class="package">
                        <div>
                            <b-row class="pyo_head">
                                <b-col><p>금액</p></b-col>
                                <b-col><p>기본 + 보너스 + 이벤트</p></b-col>
                                <!-- <b-col><p>혜택</p></b-col> -->
                            </b-row>
                            <b-container class="bv-example-row" v-if="goodsList.length > 0">
                                <b-row v-for="(item, index) in goodsList" :key="index" @click="purchase(item)">
                                    <b-col class="price">
                                        <p class="price_before" v-if="item.priceBefore !== item.priceFinal">{{item.priceBefore | comma}}원</p>
                                        <p class="price_final">{{item.priceFinal | comma}}원</p>
                                    </b-col>
                                    <b-col class="bonus">
                                        <p>
                                            <span v-if="item.pointBase  > 0" class="point_base">   {{item.pointBase | comma}}</span>
                                            <span v-if="item.pointBonus > 0" class="point_bonus"> + {{item.pointBonus | comma}}</span>
                                            <span v-if="item.pointEvent > 0" class="point_event"> + {{item.pointEvent | comma}}</span>
                                        </p>
                                        <a>충전</a>
                                    </b-col>
                                    <!-- <b-col class="sale">
                                        <p v-if="item.priceBefore !== item.priceFinal">{{item.priceBefore - item.priceFinal | comma}}원 할인</p>
                                        <a>충전</a>
                                    </b-col> -->
                                </b-row>

                            </b-container>
                            <p class="use_guide">아트포인트 이용안내</p>
                            <p class="text1">- 이 콘텐츠는 청약철회가 불가능한 상품으로 결제(사용)이 완료된 콘텐츠에 대한 구매 취소(청약 철회)는 불가능 합니다. 단, 법정대리인의 동의가없는 미성년자의 결제는 취소될 수 있습니다.</p>
                            <p class="text">- 아트포인트 가격은 부가가치세가 포함된 가격입니다.</p>
                            <p class="text">- 충전 후 사용하신 상품은 해지 및 취소/변경을 할 수 없습니다.</p>
                            <p class="text">- 아트포인트가 사용될 때는 ATT 코인 충전 금액, 보너스포인트, 일반 결제 금액 순으로 사용됩니다.</p>
                            <p class="text">- 결제가 완료되기 전에 결제창을 닫으면 결제가 완료되지 않을 수 있습니다.</p>
                        </div>
                    </div>
                </div>
                <!-- 일반 결제 끝 -->
                <!-- ATT Coin결제 시작 -->
                <div class="coin" v-else>
                    <div class="qoute">
                        <p class="title">실시간 시세 /  Coinone 거래소 기준</p>
                        <div class="qoute_box">
                            <div class="number_box">
                                <p v-if="$store.state.tokenInfo !== null">{{$store.state.tokenInfo.price | comma}} <span>KRW</span></p>
                                <p v-else>- <span>KRW</span></p>
                                <!-- <p class="red">+12.00%(24h)</p> -->
                            </div>
                            <div class="refresh_box">
                                <div class="button" @click="refreshTokenInfo">
                                    시세 갱신    
                                </div>
                                <p>{{$store.state.tokenInfoTimerCount}}초 후 자동 갱신</p>
                            </div>
                        </div>
                    </div>
                    <div class="back_gray">
                        <div class="balance">
                            <p class="title">ATT보유 잔액</p>
                            <div class="balance_box">
                                <div class="number_box">
                                    <p>{{$store.state.attTokenBalance | comma}} <span>ATT</span></p>
                                    <p class="gray" v-if="$store.state.tokenInfo !== null">({{($store.state.attTokenBalance * Number($store.state.tokenInfo.price)).toFixed(2) | comma}} KRW)</p>
                                    <p class="gray" v-else>(0 KRW)</p>
                                </div>
                                <!-- <div class="button">
                                    잔액 갱신
                                </div> -->
                            </div>
                        </div>
                        <div class="send">
                            <div class="title_box">
                                <p class="title">결제할 ATT코인 <span class="gray">(최소 충전 수량 : {{getMinChargeToken()}} ATT)</span></p>
                                <p class="add" @click="addToken" v-if="$store.state.isEnableKaikas">지갑에 ATT 추가</p>
                            </div>
                            <div class="send_box">
                                <input placeholder="전송할 ATT코인을 입력해주세요" v-model="attChargeAmount" @keyup="commasAttChargeAmount()" min="0">
                                <p v-if="$store.state.tokenInfo !== null">({{setComma((numberAttChargeAmount * $store.state.tokenInfo.price).toFixed(2).toString())}} KRW)</p>
                                <p v-else>(0 KRW)</p>
                            </div>
                            <div class="button_box">
                                <div class="button" @click="changeAmount(0.1)">10%</div>
                                <div class="button" @click="changeAmount(0.25)">25%</div>
                                <div class="button" @click="changeAmount(0.50)">50%</div>
                                <div class="button" @click="changeAmount(0.70)">70%</div>
                                <div class="button" @click="setMaxAmount">최대</div>
                                <div class="button" @click="resetAmount">초기화</div>
                            </div>
                            <!-- <div class="warn">
                                <p>보유한 잔액을 초과했습니다. 다시 입력해 주세요.</p>
                            </div> -->
                        </div>
                        <div class="expectation">
                            <p class="title">예상 아트포인트</p>
                            <div class="point">
                                <p v-if="$store.state.tokenInfo !== null">{{ Math.ceil(((numberAttChargeAmount * $store.state.tokenInfo.price) / 200)) | comma}} <span>AP</span></p>
                                <p v-else>0 <span>AP</span></p>
                                <p class="bonus" v-if="$store.state.tokenInfo !== null">+{{ Math.ceil((Math.ceil(((numberAttChargeAmount * $store.state.tokenInfo.price) / 200)) * Number($store.state.tokenInfo.depositBonusRate))) | comma}} Bonus
                                    {{$store.state.tokenInfo.depositBonusRate}}
                                </p>
                                <p class="bonus" v-else>+0 Bonus</p>
                                <!-- <p class="bonus">+{{((((numberAttChargeAmount * $store.state.tokenInfo.price) / 200).toFixed(0)) * Number('0.3')).toFixed(0) | comma}} Bonus</p> -->
                            </div>
                            <p class="know1">ATT 입금 주의사항</p>
                            <p class="know">-충전되는 아트포인트는 서버에 입금 처리가 완료된 시점의 시세를 기준으로 합니다.</p>
                            <p class="know">-시세가변동에 따라 예상 충전 아트포인트와 실제 충전 수량은 일부 달라질 수 있습니다.</p>
                            <p class="know">-충전 보너스 포인트는 회사 사정상 조기 종료 될 수 있습니다.</p>
                            <div class="coin_charge" @click="chargePoint">충전하기</div>
                        </div>
                    </div>
                    <p class="use_guide">아트포인트 이용안내</p>
                    <p class="text1">- 이 콘텐츠는 청약철회가 불가능한 상품으로 결제(사용)이 완료된 콘텐츠에 대한 구매 취소( 청약 철회)는 불가능합니다. 단, 법정대리인의 동의가 없는 미성년자의 결제는 취소될 수 있습니다.</p>
                    <p class="text">- 아트포인트 가격은 부가가치세가 부과되지 않습니다.</p>
                    <p class="text">- 충전 후 사용하신 상품은 해지 및 취소/변경을 할 수 없습니다.</p>
                    <p class="text">- 아트포인트가 사용될 때는 ATT 코인 충전 금액, 보너스포인트, 일반 결제 금액 순으로 사용됩니다.</p>

                </div>
                <!-- ATT Coin결제 끝 -->
            </div>
        </b-container>
        <Login :initIsShowPopup="isShowLoginPopup" @close="close('Login')" />
        <PopupWallet :initIsShowPopup="isShowWalletPopup" @close="close('Wallet')" />
        <PopupOK :initIsShowPopup="isShowPopupOkPopup" :initPopupOkMessage="initPopupOkMessage" @close="close('PopupOk')" />
        <PopupQR :initIsShowPopup="isShowPopupQR" :initRequestKey="initRequestKey" :initRequestType="initRequestType" @close="close('PopupQR')" @onComplete="onComplete" />
        <Footer />
    </div>
</template>

<script>
import Header from '@/components/common/Header.vue'
import Footer from '@/components/common/Footer.vue'
import Chargenav from '@/components/point/Chargenav.vue'
import Login from '@/components/common/Login.vue'
import PopupWallet from '@/components/wallet/PopupWallet.vue'
import PopupOK from '@/components/common/PopupOK.vue'
import Caver from "caver-js";
import PopupQR from '@/components/point/PopupQR.vue'
import { prepare } from 'klip-sdk'
import Banner from '@/components/common/Banner.vue'

export default {
    name: 'PointCharge',
    pageCategory: 'Point',
    pageTitle: '포인트 충전',
    components: {
        Header,
        Footer,
        Chargenav,
        Login,
        PopupWallet,
        PopupOK,
        PopupQR,
        Banner,
    },
    data() {
        return {
            NavSelecteType: 'Charge',

            goodsList: [],
            selectedGoodsPrice: 0,

            isPurple:true,

            isShowPurchasePopup: false,
            isShowLoginPopup: false,
            isShowMetamaskPopup: false,
            isShowWalletPopup: false,
            
            isShowPopupOkPopup: false,
            initPopupOkMessage: '',
            PopupOkCallbakPage: '',    // 확인 팝업에서 확인 버튼 눌렀을때 이동할 페이지

            attChargeAmount: 0,
            numberAttChargeAmount: 0,

            isShowPopupQR: false,
            initRequestKey: null,
            initRequestType: 0,

            bannerNumber1: -1,
            bannerNumber2: -1,
            bannerNumber3: -1,

            initHeaderType: 'type2',
        }
    },
    async created(){
        // await this.mixinSiteConfigCheck();
        await this.mixinLoginCheck();
    },
    mounted(){
        var that = this;
        if(!this.$store.state.loginCheck){
            this.initPopupOkMessage = '로그인 후 이용하실 수 있습니다.'
            this.PopupOkCallbakPage = '/';
            this.isShowPopupOkPopup = true;
        }
        else{
            if(this.$route.params.type === 'ATT'){
                this.isPurple = false;
                setTimeout(()=>{
                    if(!that.$store.state.adult){
                        that.initPopupOkMessage = '성인인증 후 이용하실 수 있습니다.'
                        that.PopupOkCallbakPage = '/';
                        that.isShowPopupOkPopup = true;
                        return;
                    }
                    else {
                        if(!that.$store.state.isEnableKaikas && !that.$store.state.isEnableKlip){
                            that.isShowWalletPopup = true;
                            return;
                        }
                    }
                }, 1000);
            }
            this.getGoodsList();
        }

        this.bannerNumber1 = 500;
        this.bannerNumber2 = 501;
        this.bannerNumber3 = 502;
    },
    methods:{
        checkWallet(){
            if(this.isPurple){
                return true;
            }
            else{
                if(!this.$store.state.isEnableKaikas && !this.$store.state.isEnableKlip){
                    this.isShowWalletPopup = true;
                    return false;
                }
                return true;
            }
        },
        getGoodsList(){ // 결제 항목 정보
            var param = 
            {
                sign: this.$store.state.sign,
                ticket: this.$store.state.ticket
            }
            this.$Axios.post(`${this.host}/chargeItem`, param, { headers: { 'Pragma' : 'no-cache', 'Cache-Control': 'no-cache', 'Access-Control-Allow-Origin': '*', 'Content-type': 'application/json', }}).then(res => {
                // console.log(res.data);
                if (res.data.returnCode === 0) {
                    this.goodsList = res.data.list;
                }
            })
            .catch((error) => {
                console.log(error);
            });
        },
        purchase(item){
            if(this.$store.state.loginCheck){
                var src = this.$store.state.siteConfig.pnlinkUrl + '?ticket=' + this.$store.state.ticket + '&sign=' + this.$store.state.sign + '&idx=' + item.idx;
                window.open(src, 'popupPG', 'width=500, height=876, top=100, left=100, fullscreen=no, menubar=no, status=no, toolbar=no, titlebar=no, location=no, scrollbar=no');
                // this.isShowPurchasePopup = true;
            }
            else{
                this.isShowLoginPopup = true;
            }
            
        },
        async refreshTokenInfo(){ // att 토큰 정보 갱신
            this.$store.state.tokenInfoTimerCount = 10;
            var param = 
            {
            }
            await this.$Axios.post(`${this.host}/tokenInfo`, param, { headers: { 'Pragma' : 'no-cache', 'Cache-Control': 'no-cache', 'Access-Control-Allow-Origin': '*', 'Content-type': 'application/json', }}).then(res => {
                if (res.data.returnCode === 0) {
                    this.$store.state.tokenInfo = res.data.data;
                }
            })
            .catch((error) => {
                console.log(error);
            });
        },
        async addToken(){   //Kaikas

            const tokenAddress = this.$store.state.tokenInfo.contractAddress; 
            const tokenSymbol =  this.$store.state.tokenInfo.symbol; //'ATT';
            const tokenDecimals = 18
            const tokenImage = 'https://rlmkskkd9.toastcdn.net/KR/etc/artube_logo.png'

            window.klaytn.sendAsync(
            {
                method: 'wallet_watchAsset',
                params: {
                    type: 'ERC20', // Initially only supports ERC20, but eventually more!
                    options: {
                        address: tokenAddress, // The address that the token is at.
                        symbol: tokenSymbol, // A ticker symbol or shorthand, up to 5 chars.
                        decimals: tokenDecimals, // The number of decimals in the token
                        image: tokenImage // A string url of the token logo
                    }
                },
                id: Math.round(Math.random() * 100000)
            },
            (err, wasAdded) => {
                if(err){console.log(err);return;}
                wasAdded;
                // console.log(wasAdded);

                // if (wasAdded.result) { //토큰 추가 성공 시
                //     this.initPopupOkMessage = '토큰이 정상적으로 추가 되었습니다.'
                //     this.PopupOkCallbakPage = '';
                //     this.isShowPopupOkPopup = true;
                // }
                // else {   //실패 시(사용자 취소 포함)
                //     console.log('토큰 추가 실패');
                // }
            });
        },
        chargePoint(){
            if(this.numberAttChargeAmount <= 0){
                this.initPopupOkMessage = '결제할 ATT코인을 입력해 주세요.'
                this.isShowPopupOkPopup = true;
                return;
            }
            var minToken = this.getMinChargeToken();
            if(this.numberAttChargeAmount < minToken){
                this.initPopupOkMessage = '최소 결제 수량 이상 입력해주세요.'
                this.isShowPopupOkPopup = true;
                return;
            }

            if(this.$store.state.isEnableKaikas){
                this.chargeKaikas();
            } else if(this.$store.state.isEnableKlip){
                this.chargeKlip();
            }
        },
        chargeKaikas(){
            var that = this;
            let contractAddress = this.$store.state.tokenInfo.contractAddress;
            // let contractAddress = "0xeab9a65eb0f098f822033192802b53ee159de5f0";  //baobab ATT contract
            let value = this.attChargeAmount.toString();
            value = value.replace(/[^-.0-9]/g, "");
            var caver = new Caver(window.klaytn);
            // console.log(caver.utils.toPeb(value, 'KLAY'));
            const data = caver.klay.abi.encodeFunctionCall(
                {
                    name: 'transfer',
                    type: 'function',
                    inputs: [
                    {
                        type: 'address',
                        name: 'recipient'
                    },
                    {
                        type: 'uint256',
                        name: 'amount'
                    }
                    ]
                },
                [this.$store.state.siteConfig.depositAddress, caver.utils.toPeb(value, 'KLAY')]
            )

            caver.klay.sendTransaction({
                type: 'SMART_CONTRACT_EXECUTION',
                from: this.$store.state.walletAddress,
                to: contractAddress,
                gas: '100000',
                data
            }).then(function(receipt){
                // console.log(receipt);
                receipt;
                that.initPopupOkMessage = '아트포인트 충전 요청이 완료 되었습니다.<br/>충전 완료까지 몇 분의 시간이 소요됩니다.<br/>충전 결과는 충전내역에서 확인하실 수 있습니다.'
                that.PopupOkCallbakPage = '/Point/Charge/ATT';
                that.isShowPopupOkPopup = true;
            }).catch(err =>{
                console.log(err)
            });
        },
        async chargeKlip(){
            try{
                var param = { 
                    bappName: 'Artube', 
                    from: this.$store.state.account, 
                    to: this.$store.state.siteConfig.depositAddress, 
                    amount: this.numberAttChargeAmount.toString(),
                    contract: this.$store.state.tokenInfo.contractAddress
                };
                // console.log(param);
                const res = await prepare.sendToken(param);
                this.initRequestKey = res.request_key;
                this.initRequestType = 2;
                this.isShowPopupQR = true;
                
            } catch(err){
                console.log(err);
            }
        },
        onComplete(data){   //Klip 리턴 대기
            if(data.type === 'FAILED_CREATE_QRCODE'){
                this.initPopupOkMessage = 'QR 코드를 생성하지 못했습니다.<br/>잠시 후 다시 시도해 주세요.';
                this.isShowPopupOkPopup = true;
            }
            else if(data.type === 'FAILED_REQUEST'){
                this.initPopupOkMessage = '모바일 기기에서 이용 가능한 기능입니다.<br/>카카오톡이 설치된 스마트폰에서 이용해 주세요.';
                this.isShowPopupOkPopup = true;
            }
            else if(data.type === 'CANCLED_KLIP_RESULT'){
                this.initPopupOkMessage = 'KLIP 인증을 취소하셨습니다.';
                this.isShowPopupOkPopup = true;
            }
            else if(data.type === 'SUCCESS_KLIP_RESULT'){
                if(data.requestType === 2){     //requestType - 1:prepareAuth, 2:prepareToken
                    this.initPopupOkMessage = '아트포인트 충전 요청이 완료 되었습니다.<br/>충전 완료까지 몇 분의 시간이 소요됩니다.<br/>충전 결과는 충전내역에서 확인하실 수 있습니다.'
                    this.PopupOkCallbakPage = '/Point/Charge/ATT';
                    this.isShowPopupOkPopup = true;
                }
            }
        },
        close(popupType){
            switch(popupType){
                case 'PurchasePnlink':
                    this.selectedGoodsPrice = 0;
                    this.isShowPurchasePopup = false;
                    break;
                case 'Login':
                    this.isShowLoginPopup = false;
                    break;
                case 'Wallet':
                    this.isShowWalletPopup = false;
                    if(!this.checkWallet()){
                        this.initPopupOkMessage = '지갑 연결 후 이용하실 수 있습니다.'
                        this.PopupOkCallbakPage = '/Point/Charge';
                        this.isShowPopupOkPopup = true;
                    }
                    break;
                case 'Metamask':
                    this.isShowMetamaskPopup = false;
                    if(!this.checkWallet()){
                        this.initPopupOkMessage = '지갑 연결 후 이용하실 수 있습니다.'
                        this.PopupOkCallbakPage = '/Point/Charge';
                        this.isShowPopupOkPopup = true;
                    }
                    break;
                case 'PopupOk':
                    this.isShowPopupOkPopup = false;
                    if(this.PopupOkCallbakPage !== ''){
                        this.$router.push(this.PopupOkCallbakPage);
                    }
                    this.PopupOkCallbakPage = '';
                    break;
                case 'PopupQR':
                    this.isShowPopupQR = false;
                    this.initRequestKey = null;
                    this.initRequestType = 0;
                    break;
            }
        },
        commasAttChargeAmount() {
            var x = this.attChargeAmount.toString();
            x = x.replace(/[^-.0-9]/g, "");
            // x = Number(x).toString();
            var decimalNumber = '';
            if(x.indexOf('.', 0) >= 0){
                decimalNumber = x.substring(x.indexOf('.', 0));
            }
            else{
                x = Number(x).toString();
            }

            if(decimalNumber.length > 1){
                if(Number(decimalNumber.substring(1)) > 0){
                    x = Number(x.substring(0, x.indexOf('.'))).toString();
                    x += decimalNumber.substring(0,5);
                }
            }
            
            if(this.$store.state.attTokenBalance < Number(x)){
                x = this.$store.state.attTokenBalance.toString();
            }
            this.numberAttChargeAmount = Number(x);
            this.attChargeAmount = this.setComma(x);
            // console.log(this.attChargeAmount);
        },
        changeAmount(per){
            var amount = Number((this.$store.state.attTokenBalance * per).toFixed(4).toString());
            this.attChargeAmount = this.setComma(amount.toString());
            this.numberAttChargeAmount = amount;
        },
        setMaxAmount(){
            this.attChargeAmount = this.setComma(this.$store.state.attTokenBalance.toString());
            this.numberAttChargeAmount = this.$store.state.attTokenBalance;
        },
        resetAmount(){
            this.attChargeAmount = 0;
            this.numberAttChargeAmount = 0;
        },
        getMinChargeToken(){
            var minToken = 0;
            if(this.$store.state.tokenInfo !== null){
                minToken = Math.ceil((2000000 / this.$store.state.tokenInfo.price)) / 10000;
            }
            return minToken;
        },
        setComma(obj) {
            var regx = new RegExp(/(-?\d+)(\d{3})/);
            var bExists = obj.indexOf(".", 0);//0번째부터 .을 찾는다.
            var strArr = obj.split('.');
            while (regx.test(strArr[0])) {//문자열에 정규식 특수문자가 포함되어 있는지 체크
                //정수 부분에만 콤마 달기 
                strArr[0] = strArr[0].replace(regx, "$1,$2");//콤마추가하기
            }
            if (bExists > -1) {
                //. 소수점 문자열이 발견되지 않을 경우 -1 반환
                obj = strArr[0] + "." + strArr[1];
            } else { //정수만 있을경우 //소수점 문자열 존재하면 양수 반환 
                obj = strArr[0];
            }
            return obj;//문자열 반환
        },
    }
}
</script>