<template>
    <div class="leagueevent wrap gossip">
        <Header :initHeaderType="initHeaderType" />
        <div class="container">
            <img src="@/assets/images/gossip-banner.png" class="event_page_img0" />
            <div class="gossip-event">
                <div class="info">
                    <b-row>
                        <b-col cols="3" class="blue-box"><p>응모기간</p></b-col>
                        <b-col cols="9" class="blue-text">~ 2022.02.23(수)</b-col>
                        <b-col cols="3" class="blue-box"><p>당첨발표</p></b-col>
                        <b-col cols="9" class="blue-text">~ 2022.02.28(월)</b-col>
                        <b-col cols="3" class="blue-box no"><p>경품내용</p></b-col>
                        <b-col cols="9" class="blue-text no">
                            <p><span>1등(1명):</span> 각 4,000ATT+치킨set교환권 5매</p>
                            <p><span>2등(3명):</span> 각 2,000ATT+치킨set교환권 3매</p>
                            <p><span>3등(10명):</span> 각 1,000ATT+치킨set교환권 1매</p>
                            <p><span>4등(30명):</span> 각 편의점 상품권 3,000원권 1매</p>
                            <p class="ps">* 참가자 전원: 각 50 ATT 증정</p>
                            <p class="ps">* 경품발송: 3월 2주 이내</p>
                        </b-col>
                    </b-row>
                </div>
                <div class="participate">
                    <div class="title_box">
                        <div class="line"></div>
                        <p class="title">참가 방법</p>
                        <div class="line"></div>
                    </div>
                    <b-row>
                        <b-col><img src="../../assets/images/gossip-step1.png" /></b-col>
                        <b-col class="arrow"><img src="../../assets/images/gossip-step-button.svg" /></b-col>
                        <b-col><img src="../../assets/images/gossip-step2.png" /></b-col>
                        <b-col class="arrow"><img src="../../assets/images/gossip-step-button.svg" /></b-col>
                        <b-col><img src="../../assets/images/gossip-step3.png" /></b-col>
                    </b-row>
                    <div class="button-box">
                        <a class="download" @click="downloadWithAxios">이벤트 참여용 이미지 다운로드</a>
                        <a href="https://forms.gle/PgV4taV9zSv68fxM6" target="_blank" class="google-form">구글폼 작성하기</a>
                    </div>
                </div>
                <img src="../../assets/images/gossip-give.png" class="gossip-give" />
            </div>
            <div class="event_notice">
                <div class="event_notice_inner">
                    <div class="box">
                        <p class="name">[참고사항]</p>
                        <p>- 이벤트는 1인 1계정 참여로 제한됩니다.</p>
                        <p>- 경품 발송을 위해 당첨된 분들의 개인정보수집을 요청 드릴 수 있습니다.</p>
                        <p>- 경품 발송은 당사 사정에 의해 연기될 수 있으며, 별도의 공지가 이뤄질 예정입니다.</p>
                        <p>- 경품의 경우 판매처 사정에 따라 별도의 공지 없이 변경될 수 있습니다.</p>
                        <p>- 이벤트 경품은 클레이튼 지갑 및 기프티콘 형태로 발송되며 수신자 핸드폰 사정에 따라 스팸처리 될 수 있으며 이 경우 책임은 본인에게 있습니다.</p>
                        <p>- 잘못된 개인정보 전달로 인한 당첨의 불이익 (개인정보, 연락처 누락 및 기간 내 미전달) 등은 책임지지 않습니다.</p>
                        <p>- 이벤트 종료 및 당첨자 발표는 아튜브 공식 홈페이지 및 SNS 채널을 통해 안내됩니다. </p>
                    </div>
                </div>
            </div>
        </div>
        <!-- <Login :initIsShowPopup="isShowLoginPopup" @close="close('Login')" /> -->
        <Footer />
    </div>
</template>


<script>
import Header from '@/components/common/Header.vue'
import Footer from '@/components/common/Footer.vue'
import axios from 'axios'

export default {
    name: 'EventLeague',
    pageCategory: 'Event',
    pageTitle: '이벤트',
    components: {
        Header,
        Footer,
        // Login
    },
    data(){
        return{
            file: {
                title: 'artubeleague_event.jpg',
                src: require('@/assets/images/event/artubeleague_event.jpg')
            },

            initHeaderType: 'type2',
        }
    },
    methods:{
        forceFileDownload(response, title) {
            const url = window.URL.createObjectURL(new Blob([response.data]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', title)
            document.body.appendChild(link)
            link.click()
        },
        downloadWithAxios() {
            var title = this.file.title
            var url = this.file.src;
            axios({
                method: 'get',
                url,
                responseType: 'arraybuffer',
            })
            .then((response) => {
                this.forceFileDownload(response, title)
            })
            .catch(() => console.log('error occured'))
        },
    }
}
</script>