<template>
    <div id="chargenav">
        <h1>Artpoint</h1>
        <ul>
            <router-link tag="li" to="/Point/Charge" :class="{'on' : selectedType === 'Charge'}">충전</router-link>
            <router-link tag="li" to="/Point/Withdraw" :class="{'on' : selectedType === 'Withdraw'}">출금</router-link>
            <router-link tag="li" to="/Point/History/Withdraw" :class="{'on' : selectedType === 'Withdrawhistory'}">출금내역</router-link>
            <router-link tag="li" to="/Point/History/Charge" :class="{'on' : selectedType === 'ChargeHistory' || selectedType === 'ChargeHistory2'}">충전내역</router-link>
            <router-link tag="li" to="/Point/History/Charge" :class="{'on' : selectedType === 'ChargeHistory'}" class="payment">일반 결제</router-link>
            <router-link tag="li" to="/Point/History/Charge/ATT" :class="{'on' : selectedType === 'ChargeHistory2'}" class="payment">ATT coin 결제</router-link>
            <router-link tag="li" to="/Point/History/Usage" :class="{'on' : selectedType === 'UseHistory'}">사용내역</router-link>
        </ul>
    </div>
</template>

<script>
export default {
    name: 'ChargeNav',
    data(){
        return {
            colorType: this.selectedType,
        }
    },
    props: {
        selectedType : {
            type: String,
            default: 'Charge'
        },
    },
    created() {        
    },
    mounted(){
    },
    beforeDestroy() {
	},
    methods: {

    },
}
</script>