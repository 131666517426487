<template>
    <div id="policy" v-if="initIsShowPopup">
        <div class="terms_box">
            <div class="title">
                <h1>아튜브 챌린지 운영 원칙</h1>
                <img src="@/assets/images/close.svg" alt="닫기" class="close" @click="closePopup">
            </div>
            <div class="article_box">
                <div class="article">
                    <h2>1. 주요 서비스</h2><br/>

                    <h2>아튜브 챌린지란?</h2>
                    <p>프로작가로의 데뷔를 위한 공간으로 아튜브 회원의 성인 회원은 누구나 작품을 등록할 수 있습니다. 각 작품의 매월에 발생한 별점과 조회수를 바탕으로 이달의 베스트 전당에 게시됩니다. 또한 아튜브 챌린지 내의 작품 중 별점, 조회수, 별점 참여자 수와 재미, 상업성, 완성도 등을 고려하여 정식 연재의 기회를 드립니다.<br/><br/></p>
                    
                    <h2>작품/회차 검수 절차</h2>
                    <p>모든 작품, 회차는 등록, 수정 시 2~3일 정도의 검수 날짜가 소요되며 (당사 사정에 따라 변동될 수 있습니다) 검수 이후 통과된 작품만 다른 사용자에게 보일 수 있도록 게시됩니다. 작품 정보 수정의 경우는 검수 기간 동안 수정 부분만 비공개되는 것이 아닌 해당 작품 전체가 비공개됩니다.</p>
                    <p><b>대기 상태</b> : 저장만 한 경우로 등록 신청 전 상태이며 수정과 삭제가 가능합니다.</p>
                    <p><b>검수 중 상태</b> : 수정과 삭제가 불가능합니다.</p>
                    <p><b>운영원칙 위반</b> : 운영 원칙 위반으로 게시 불가 상태이며 작품/회차를 수정 후 다시 신청하여 게시 가능합니다.</p>
                    <p><b>게시 완료</b> : 플렛폼에 노출 됩니다.</p>
                    <p>!! 아튜브 웹툰은 전 연령 등급의 웹툰과 성인 등급의 웹툰을 서비스합니다. 성인 등급의 웹툰은 만 19세 이상만 감람할 수 있으며 이에 따라 등급을 위반하거나 게시 불가 콘텐츠를 등록하는 경우 운영원칙에 따라 비공개처리 될 수 있으며 계정 정지 처리될 수 있습니다.<br/><br/></p>

                    <h2>성인 등급 기준</h2>
                    <table class="no1">
                        <tr>
                            <th>구분</th>
                            <th>선정성</th>
                            <th>폭력성</th>
                            <th>언어</th>
                            <th>번죄 및 약물</th>
                            <th>기타</th>
                        </tr>
                        <tr>
                            <th>성인</th>
                            <td>신체 묘사 중 노출의 강도와 성기와 가슴 등의 구체적인 묘사 등 (노골적으로 특정 신체 부위를 부각할 수 있는 장면 포함. 단 직접적인 성기 묘사 금지) 동성,이성 간 성행위와 유사 성행위, 자위 및 변태 성행위등의 내용을 묘사</td>
                            <td>살인, 폭력, 상해,  고문, 유괴등의 행위를 묘사</td>
                            <td>비속어, 욕설, 폭언, 성적인 대화등의 사용</td>
                            <td>구체적인 범죄에 대한 묘사나 유도, 강간,감금등의 성범죄, 향정신성 약물의 흡연 섭취와 같은 내용 등 </td>
                            <td>가출,자살 유도,자해,불륜등 청소년에게 금지되나가 유해하다고 판단되는 모든 묘사</td>
                        </tr>
                        <tr>
                            <th>전연령</th>
                            <td>이성,동성과의 착의 상태에서 성행위 없는 키스, 접촉, 신체 일부의 노출</td>
                            <td>비현실적인 전투,격투 또는 폭력 행위 없음</td>
                            <td>비속어,욕설,성적인 대화 없음</td>
                            <td>범죄관련 및 약물 관련 묘사 없음</td>
                            <td>성인등급의 수위, 묘사와 유해 컨텐츠가 포함되지 않음</td>
                        </tr>
                    </table><br/>
                    <p><span>&middot;</span> 신체의 절단면이나 성기를 모자이크를 하여 윤곽이나 구체적인 디테일을 확인할 수 없도록 처리를 해야 게시가 가능하며  그렇지 않은 경우 플랫폼에 노출이 제한됩니다.</p>
                    <p><span>&middot;</span> 비속어의 경우는 1음절 이상 마스킹을 해서 직접적인 표현을 되지 않게 해야 하며 그렇지 않은 경우 플랫폼에 노출이 제한됩니다.</p>
                    <p><span>&middot;</span> 위에 포함되지 않은 사항에 대해서는 관현 법령이나 고시, 정부기관의 명령에 따라 판단합니다.</p>
                    <p><span>&middot;</span> 해당 기준은 당사의 정책 변경, 법령이나 고시 변경, 정부기관의 명령 등에 따라 변경될 수 있습니다</p>
                </div>
                
                <div class="article">
                    <h2>2. 게시 불가 기준</h2><br/>
                    
                    <h2>명예훼손</h2>
                    <p><span>&middot;</span> 개인의 사생활 및 초상권 침해</p>
                    <p><span>&middot;</span> 개인이나 단체에 대해 허위사실을 유포하거나 비방, 권리를 침해</p>
                    <p><span>&middot;</span> 실명, 주민등록번호, 전화번호, 주소, SNS 등의 개인 정보를 해당 본인의 동의 없이 고의적, 악의적으로 게시</p>
                    <p><span>&middot;</span> 특정인에게 욕설, 언어폭력 등으로 인격모독을 하거나 불쾌함을 주는 경우</p>
                    <br/>
                    <h2>반사회적</h2>
                    <p><span>&middot;</span> 특정 계층 또는 종교를 비하하는 경우</p>
                    <p><span>&middot;</span> 자살을 미화, 권유하거나 해당 방법을 적시, 유도하는 경우</p>
                    <p><span>&middot;</span> 성폭력, 약물, 살인, 스토킹 기타 범죄 관련 내용을 미화, 권유, 조장하는 경우</p>
                    <p><span>&middot;</span> 도박 등의 사행심을 미화, 권유, 조장하는 경우 </p>
                    <p><span>&middot;</span> 부녀자 및 어린이 학대 등 폭력행위를 미화, 권유, 조장하는 경우  </p>
                    <p><span>&middot;</span> 심한 혐오감이나 불쾌감을 유발하는 경우</p>
                    <br/>
                    <h2>음란물</h2>
                    <p><span>&middot;</span> 아동 청소년의 성 보호에 관한 법률, 정보통신망 이용 촉진 및 정보보호 등에 관한 법률, 기타 법령에 위반되는 내용이 포함된 경우</p>
                    <p><span>&middot;</span> 근친, 강간, 감금, 윤간, 시간 등 기타 변태적 행위를 유도, 미화하거나 사실적으로 묘사한 경우</p>
                    <p><span>&middot;</span> 음란사이트나 자료 등이  포함되는 경우  </p>
                    <br/>
                    <h2>저작권 침해</h2>
                    <p><span>&middot;</span> 저작권자의 동의를 구하지 않은 자료를 사용</p>
                    <p><span>&middot;</span> 다른 서비스 사이트의 자료나 공유가 허락되지 않은 자료를 사용</p>
                    <p><span>&middot;</span> 불법 다운로드, 불법 프로그램을 설치를 권유나 판매하는 내용 </p>
                    <p><span>&middot;</span> 저작권, 상표권, 의장권 등을 무단으로 침해하는 내용</p>
                    <p><span>&middot;</span> 침해 주장 게시물이 있는 경우</p>
                    <br/>
                    <h2>기타</h2>
                    <p><span>&middot;</span> 특정 상품, 판매 사이트, 업체 등의 상호를 기재한 경우</p>
                    <p><span>&middot;</span> 특정 개인이나 회사의 이익을 목적으로 내용을 게시하는 경우</p>
                    <p><span>&middot;</span> 도박관련 사이트의 노출이나 특정 주식 또는 코인을 매도, 매수를 유도</p>
                    <p><span>&middot;</span> 아무런 내용이 없거나 게시판의 성격과  맞지 않는 게시물, 동일한 게시물은 연속으로 올려서 도배를 하는 경우</p>
                    <p><span>&middot;</span> 운영자, 직원 및 관계자를 사칭하는 경우</p>
                    <p><span>&middot;</span> 정부 기관의 명령, 고시 등에 따라 게시가 불가능한 것으로 판단되는 경우</p>
                    <br/>
                    <h2>위반 조치</h2>
                    <p><span>&middot;</span> 동일 게시물이 3회 이상 신고된 경우 관리자의 검수 후 신고 내용이 적절할 경우 비공개 처리됩니다. </p>
                    <p><span>&middot;</span> 작품 등록, 회차 등록 시 2~3일의 검수를 거쳐 부적합하다고 판단되면 비공개 처리됩니다. </p>
                    <p><span>&middot;</span> 운영정책 위반으로 비공개 처리된 게시물의 경우 수정을 통하여 재 검수 후 해제 가능합니다. </p>
                    <p><span>&middot;</span> 운영 원칙에 위반되는 내용을 게시하여 누적된 수치에 따라 일시정지, 영구 정지 처리 될 수 있습니다.</p>
                    <p><span>&middot;</span> 누적과 별개로 게시물의 위반 정도가 중한 경우 조치 없이 영구 정지될 수 있습니다.</p>
                    <br/>
                    <h2>신고</h2>
                    <p><span>&middot;</span> 감상한 웹툰 회차의 하단에 신고하기 버튼이 있으며 유해하거나 게시 불가로 생각되는 컨텐츠의 경우 해당 버튼 클릭하여 신고할 수 있습니다.</p>
                    <p><span>&middot;</span> 신고된 게시물은 담당자 검수 후 판단하여 위반으로 판단되면 비공개 처리됩니다. </p>
                    <p><span>&middot;</span> 신고된 게시물의 경우 동일 회원의 신고는  1회만 유효합니다.</p>
                    <p><span>&middot;</span> 허위 신고 또는 같은 게시물을 중복신고를 할 경우 서비스 이용에 제한받을 수 있습니다. </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Policy',
    data(){
        return {
        }
    },
    props: {
        initIsShowPopup: {
            type: Boolean,
            default: false
        }
    },
    created() {
    },
    mounted(){
    },
    beforeDestroy() {
	},
    methods: {
        closePopup(){
            this.$emit('close');
        }
    },
}
</script>