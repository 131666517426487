<template>
    <div class="leagueevent wrap legend" >
    <Header :initHeaderType="initHeaderType" />
    <div class="container">
        <img src="../../assets/images/event/legend/legend-banner.png" class="legend-cover" >
        <div class="legend-event-sec">
            <div class="container">
                <div class="text-area">
                    <img class="text-area-img" src="@/assets/images/event/legend/legend-title.png" alt="">
                </div>
                <div class="top-box">
                    <img src="@/assets/images/event/legend/legend-card1.png" alt="">
                    <div class="top-box-info">
                        <p class="top-box-title">이벤트 관련사항</p>
                        <div class="top-box-text">
                            <p class="term">이벤트 기간</p>
                            <p class="date">5월 4일(수) ~ 6월 3일(금)</p>
                            <p class="text">본 이벤트는 <span>본인 인증 참여자</span>에 한해 참여 가능합니다.</p>
                        </div>
                    </div>
                </div>
                <div class="row-box">
                    <img src="@/assets/images/event/legend/legend-card2.png" alt="">
                    <div class="row-box-info">
                        <p class="row-box-title">이벤트 안내</p>
                        <div class="row-box-text">
                            <p class="text">이벤트 기간동안, <span class="bg">하단 작품 중 5 작품 이상</span> <br class="mbbr"> 감상하신 분들 <br class="br"> 전원에게 15포인트를 지급합니다.</p>
                            <p class="text"><span class="deco">1인 1계정만</span> 이벤트 참여가 가능합니다.</p>
                            <p class="text">포인트는 <span class="bg">보너스 포인트 15포인트</span>로 지급되며,<br> <br class="mbbr"> <span class="deco">매주 금요일에 지급</span>됩니다.</p>
                            <p class="text">본 이벤트는 작품을 열람해야 대상에 포함됩니다.</p>
                            <p class="text">본 이벤트는 당사 사정에 따라 사전 예고없이 변경되거나 <br>취소될 수 있습니다.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="legend-event-sec2">
            <div class="container">
                <div class="legend-event-sec2-inner1">
                    <img src="@/assets/images/event/legend/legend-title2.png" alt="">
                </div>
                <div class="legend-event-sec2-inner2">
                    <p><span>레전드 작품</span>들을 감상하고 <span>보너스 포인트</span>받아가세요!</p>
                    <i class="legend-click"></i> 
                </div>
                <div class="info row">
                    <div class="col-6 col-sm-4" v-for="(item,idx) in legendProject" :key="idx" >
                        <img :src="item.picture" @click="moveDetail(item)">
                    </div>
                </div>
            </div>
        </div>
        <!-- <div class="legend-event-footer">
            <div class="container">
                <h3>샬라샬라랴라라라라</h3>
            </div>
        </div> -->
    </div>
    <Login :initIsShowPopup="isShowLoginPopup" @close="close('Login')" />
    <Footer />
    </div>
</template>

<script>
import Footer from '@/components/common/Footer.vue'
import Header from '@/components/common/Header.vue'
import Login from '@/components/common/Login.vue'

export default {
    pageTitle: '이벤트',
    components: { 
        Header,
        Footer,
        Login,
    },
    created(){
    },
    data(){
        return{
            isShowLoginPopup: false,
            initHeaderType: 'type2',

            protocol:'',
            host:'',
            legendProject:[
                {   
                    adult:1,
                    webToonIndex: 40001,
                    picture:require('@/assets/images/event/legend/legend1.png')
                },
                {   
                    adult:1,
                    webToonIndex: 40004,
                    picture:require('@/assets/images/event/legend/legend2.png')
                },
                {   
                    adult:1,
                    webToonIndex: 40003,
                    picture:require('@/assets/images/event/legend/legend3.png')
                },
                {   
                    adult:1,
                    webToonIndex: 40002,
                    picture:require('@/assets/images/event/legend/legend4.png')
                },
                {   
                    adult:0,
                    webToonIndex: 40035,
                    picture:require('@/assets/images/event/legend/legend5.png')
                },
                {   
                    adult:0,
                    webToonIndex: 40033,
                    picture:require('@/assets/images/event/legend/legend6.png')
                },
                {   
                    adult:0,
                    webToonIndex: 40031,
                    picture:require('@/assets/images/event/legend/legend7.png')
                },
                {   
                    adult:1,
                    webToonIndex: 40030,
                    picture:require('@/assets/images/event/legend/legend8.png')
                },
                {   
                    adult:0,
                    webToonIndex: 40015,
                    picture:require('@/assets/images/event/legend/legend9.png')
                },
                {   
                    adult:0,
                    webToonIndex: 40016,
                    picture:require('@/assets/images/event/legend/legend10.png')
                },
                {   
                    adult:0,
                    webToonIndex: 40017,
                    picture:require('@/assets/images/event/legend/legend11.png')
                },
                {   
                    adult:1,
                    webToonIndex: 40018,
                    picture:require('@/assets/images/event/legend/legend12.png')
                },
                {   
                    adult:1,
                    webToonIndex: 40019,
                    picture:require('@/assets/images/event/legend/legend13.png')
                },
                {   
                    adult:0,
                    webToonIndex: 40021,
                    picture:require('@/assets/images/event/legend/legend14.png')
                },
                {   
                    adult:1,
                    webToonIndex: 40023,
                    picture:require('@/assets/images/event/legend/legend15.png')
                },
                {   
                    adult:0,
                    webToonIndex: 40025,
                    picture:require('@/assets/images/event/legend/legend16.png')
                },
                {   
                    adult:0,
                    webToonIndex: 40027,
                    picture:require('@/assets/images/event/legend/legend17.png')
                },
                {   
                    adult:0,
                    webToonIndex: 40028,
                    picture:require('@/assets/images/event/legend/legend18.png')
                },
                
            ],
        }
        

    },
    methods:{
        moveDetail(item){
            if(item.adult === 1){
                if(this.$store.state.loginCheck === false){
                    this.isShowLoginPopup = true;
                }
                else if(!this.$store.state.adult){
                    var src = this.$store.state.siteConfig.niceUrl + '?email=' + this.$store.state.email;
                    window.open(src, 'popupChk', 'width=500, height=550, top=100, left=100, fullscreen=no, menubar=no, status=no, toolbar=no, titlebar=no, location=no, scrollbar=no');
                }
                else{
                    // this.$router.push('/Webtoon/Info/' + item.englishName);
                    this.$router.push('/Webtoon/Info/' + item.webToonIndex);
                }
            }else{
                // this.$router.push('/Webtoon/Info/' + item.englishName);
                this.$router.push('/Webtoon/Info/' + item.webToonIndex);
            }
        },
        close(popupType){
            switch(popupType){
                case 'Login':
                    this.isShowLoginPopup = false;
                    break;
            }
        },
    }
}
</script>

<style>

</style>