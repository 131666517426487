<template>
    <div id="popup_ok" v-if="initIsShowPopup">
        <div class="popup_ok_box">
            <p v-html="initPopupOkMessage"></p>
            <a @click="closePopup">확인</a>
        </div>
    </div>
</template>

<script>
export default {
    name: 'PopupOk',
    data(){
        return {
        }
    },
    props: {
        initIsShowPopup: {
            type: Boolean,
            default: false
        },
        initPopupOkMessage:{
            type: String,
            default: ''
        },
    },
    created() {
    },
    mounted(){
    },
    beforeDestroy() {
	},
    methods: {
        closePopup(){
            this.$emit('close');
        },
    },
}
</script>