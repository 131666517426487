<template>
    <div class="contact wrap">
        <Header :initHeaderType="initHeaderType" />
            <b-container>
                <div class="menu_left_box">
                    <Noticenav :selectedType="NavSelecteType" />
                </div>
                <div class="contact_box">
                    <div class="title">
                        <h1>1:1 문의하기</h1>
                    </div>
                    <div class="accordion np_tab" role="tablist">
                        <b-card class="head">
                            <b-row>
                            <b-col>제목</b-col>
                            <b-col>처리상태</b-col>
                            </b-row>
                        </b-card>
                        <b-card no-body class="mb-1" v-for="(item,index) in contactList" :key="index">
                            <b-card-header header-tag="header" class="p-1" role="tab">
                                <b-button block v-b-toggle="'accordion-' + index" variant="info">
                                    <b-row>
                                        <b-col>{{item.title}}</b-col>
                                        <b-col class="wait" v-if="item.answerFlag === 0">답변대기중</b-col>
                                        <b-col v-else>답변완료</b-col>
                                        <b-col><img src="@/assets/images/icon_down_arrow.svg" alt="화살표"></b-col>
                                    </b-row>
                                </b-button>
                            </b-card-header>
                            <b-collapse :id="'accordion-'+index" accordion="my-accordion" role="tabpanel">
                                <b-card-body>
                                    <b-card-text v-html="item.message"></b-card-text>
                                    <b-card-text class="text" v-if="item.answerFlag !== 0" v-html="item.answerMessage"></b-card-text>
                                    </b-card-body>
                            </b-collapse>
                        </b-card>
                    </div>
                    <Pagination :initMaxRecordCount="currentPageSize" :initMaxPageCount="maxPageCount" :initPagingData="initPagingData" @movePaging="movePaging" />
                    <div class="button clearfix">
                        <router-link tag="p" to="/Contact/Write">문의하기</router-link>
                    </div>
                </div>
            </b-container>
        <PopupOK :initIsShowPopup="isShowPopupOkPopup" :initPopupOkMessage="initPopupOkMessage" @close="close('PopupOk')" />
        <Footer />
    </div>
</template>

<script>
import Header from '@/components/common/Header.vue'
import Footer from '@/components/common/Footer.vue'
import Noticenav from '@/components/support/Noticenav.vue'
import PopupOK from '@/components/common/PopupOK.vue'
import Pagination from '@/components/common/Pagination.vue'

export default {
    name: 'Contact',
    pageCategory: 'Member',
    pageTitle: '1:1 문의하기',
    components: {
        Header,
        Footer,
        Noticenav,
        PopupOK,
        Pagination,
    },
    data() {
        return {
            NavSelecteType: 'Contact',
            // text: `A.화면 상단에 있는 로그인 화면을 누르면 화면 하단에 회원가입 버튼이 있습니다. 오늘도 아튜브 웹툰을 이용해주셔서 감사합니다.`,
            isShowPopupOkPopup: false,
            initPopupOkMessage: '',
            PopupOkCallbakPage: '',    // 확인 팝업에서 확인 버튼 눌렀을때 이동할 페이지

            contactList: [],

            currentPageNo: 1,      //현재 페이지 번호
            currentPageSize: 10,    //한번에 불러올 게시물 수
            maxPageCount:10,        //한 화면에 보여질 페이지 수
            initPagingData: null,

            initHeaderType: 'type2',
        }
    },
    async created(){
        // await this.mixinSiteConfigCheck();
        await this.mixinLoginCheck();
        if(this.$store.state.loginCheck === false){
            this.initPopupOkMessage = '로그인 후 접근할 수 있습니다.';
            this.PopupOkCallbakPage = '/';
            this.isShowPopupOkPopup = true;
        }
        else{
            this.getContact();
        }
    },
    methods:{
        getContact(){
            var param = 
            {
                pageNo: this.currentPageNo,
                pageSize: this.currentPageSize,
                sign: this.$store.state.sign,
                ticket: this.$store.state.ticket,
                viewAdult: this.$store.state.isCheckedAdult ? 1 : 0,
            }
            this.$Axios.post(`${this.host}/contractus`, param, { headers: { 'Pragma' : 'no-cache', 'Cache-Control': 'no-cache', 'Access-Control-Allow-Origin': '*', 'Content-type': 'application/json', }}).then(async(res) => {
                // console.log(res.data);
                if (res.data.returnCode === 0) {
                    this.contactList = res.data.list;
                    this.initPagingData = res.data.page;
                }
            })
            .catch((error) => {
                console.log(error);
            });
        },
        movePaging(pageNo){
            this.currentPageNo = pageNo;
            this.getContact();
        },
        close(popupType){
            switch(popupType){
                case 'PopupOk':
                    this.isShowPopupOkPopup = false;
                    if(this.PopupOkCallbakPage !== ''){
                        this.$router.push(this.PopupOkCallbakPage);
                    }
                    this.PopupOkCallbakPage = '';
                    break;
            }
        },
    }
}
</script>