<template>
    <div class="joinus wrap">
        <Header :initHeaderType="initHeaderType" v-if="!isWebview" />
        <div id="joinus">
            <div class="agree_box">
                <h1>artube</h1>
                <div class="join_box">
                    
                    <h2>Artube계정</h2>
                    
                    <p class="plz">서비스 이용 약관에 동의해 주세요</p>

                    <input class="styled-checkbox no" id="cbxAllAgree" type="checkbox" v-model="checkedAllAgree" @change="allAgree()">
                    <label for="cbxAllAgree">모두 동의합니다</label>
                    <p class="text">전체동의는 필수 및 선택정보에 대한 동의도 포함되어 있으며, 개별적으로도 동의를 선택하실 수 있습니다. 선택항목에 대한 동의를 거부하시는 경우에도 서비스는 이용이 가능합니다.</p>
                    
                    <div class="check_box">
                        <input class="styled-checkbox" id="agree1" type="checkbox" v-model="checkedAgree1" @change="agree(1)">
                        <label for="agree1">만 14세 이상입니다</label>
                    </div>

                    <div class="check_box">
                        <input class="styled-checkbox" id="agree2" type="checkbox" v-model="checkedAgree2" @change="agree(2)">
                        <label for="agree2">[필수] 서비스 이용약관</label>
                        <i @click="Terms" class="icon-right-arrow arrow-more"></i>
                    </div>

                    <div class="check_box">
                        <input class="styled-checkbox" id="agree3" type="checkbox" v-model="checkedAgree3" @change="agree(3)">
                        <label for="agree3">[필수] 개인정보 수집 및 이용 동의</label>
                        <i @click="Policy" class="icon-right-arrow arrow-more2"></i>
                    </div>
                    <div class="check_box">
                        <input class="styled-checkbox" id="agree4" type="checkbox" v-model="checkedAgree4" @change="agree(4)">
                        <label for="agree4">[선택] 프로모션 이메일 알림</label>
                    </div>
                    <div class="agree_button" @click="signup">동의 후 회원가입</div>
                </div>
            </div>
        </div>
        <Policy :initIsShowPopup="isClickPolicyPopup" @close="close('PopupPolicy')"/>
        <Terms :initIsShowPopup="isClickTermsPopup" @close="close('PopupTerms')"/>
        <PopupOK :initIsShowPopup="isShowPopupOkPopup" :initPopupOkMessage="initPopupOkMessage" @close="close('PopupOk')" />
        <Footer v-if="!isWebview" />
    </div>
</template>

<script>
import Header from '@/components/common/Header.vue'
import Footer from '@/components/common/Footer.vue'
import PopupOK from '@/components/common/PopupOK.vue'
import Policy from '@/components/support/Policy.vue'
import Terms from '@/components/support/Terms.vue'

export default {
    name: 'SignupSNS',
    pageCategory: 'Member',
    pageTitle: '회원가입',
    components: {
        Header,
        Footer,
        PopupOK,
        Policy,
        Terms,
    },
    data() {
        return {
            isWebview: false,

            //서비스 이용 약관
            isPassAgree: false,

            checkedAllAgree: false,
            checkedAgree1: false,
            checkedAgree2: false,
            checkedAgree3: false,
            checkedAgree4: false,

            //회원정보 get 파라미터
            resTicket: '',
            resSign: '',
            resSocial: 0,

            isShowPopupOkPopup: false,
            initPopupOkMessage: '',
            PopupOkCallbakPage: '',    // 확인 팝업에서 확인 버튼 눌렀을때 이동할 페이지

            initHeaderType: 'type2',

            isClickPolicyPopup: false,
            isClickTermsPopup: false,

            timerAppApi: null,
        }
    },
    created(){
        this.isWebview = this.isArtubeWebview();

        if(this.$route.params.ticket){
            this.resTicket = this.$route.params.ticket;
        }
        else{
            this.routingHome();
        }

        if(this.$route.params.sign){
            this.resSign = this.$route.params.sign;
        }
        else{
            this.routingHome();
        }

        if(this.$route.params.social){
            this.resSocial = this.$route.params.social;
        }
        else{
            this.routingHome();
        }
    },
    mounted(){
        // console.log(this.$store.state.email);
        //     console.log(this.$store.state.ticket);
        //     console.log(this.$store.state.sign);
    },
    beforeDestroy(){
        clearInterval(this.timerAppApi);
    },
    methods: {
        routingHome(){
            if(this.isWebview){
                this.$router.push('/M/Member/Login');
            } else {
                this.$router.push('/');
            }
        },
        Policy(){
            this.isClickPolicyPopup= true;
        },
        Terms(){
            this.isClickTermsPopup= true;
        },
        allAgree(){
            this.checkedAgree1 = this.checkedAllAgree;
            this.checkedAgree2 = this.checkedAllAgree;
            this.checkedAgree3 = this.checkedAllAgree;
            this.checkedAgree4 = this.checkedAllAgree;
        },
        agree(no){
            if(no === 1){
                if(!this.checkedAgree1){
                    this.checkedAgree1 = false;
                }else{
                    this.checkedAgree1 = true;
                }
            }
            else if(no === 2){
                if(!this.checkedAgree2){
                    this.checkedAgree2 = false;
                }else{
                    this.checkedAgree2 = true;
                }
            }
            else if(no === 3){
                if(!this.checkedAgree3){
                    this.checkedAgree3 = false;
                }else{
                    this.checkedAgree3 = true;
                }
            }
            else if(no === 4){
                if(!this.checkedAgree4){
                    this.checkedAgree4 = false;
                }else{
                    this.checkedAgree4 = true;
                }
            }
            
            if(this.checkedAgree1 && this.checkedAgree2 && this.checkedAgree3 && this.checkedAgree4){
                this.checkedAllAgree = true;
            } else{
                this.checkedAllAgree = false;
            }
        },
        checkAgree(){

            var isValidAgree = false;
            if(!this.checkedAgree1){ isValidAgree = true }
            else if(!this.checkedAgree2) { isValidAgree = true }
            else if(!this.checkedAgree3) { isValidAgree = true }
            // else if(!this.checkedAgree4) return;

            if(isValidAgree){
                this.initPopupOkMessage = '서비스 이용 약관에 동의해 주세요.';
                this.isShowPopupOkPopup = true;
                return;
            }
            this.isPassAgree = true;
        },
        async signup(){
            // console.log(this.$store.state.email);
            // console.log(this.$store.state.ticket);
            // console.log(this.$store.state.sign);

            this.checkAgree();
            if(!this.isPassAgree){
                this.initPopupOkMessage = '서비스 이용 약관에 동의해 주세요.';
                this.isShowPopupOkPopup = true;
                return;
            }

            var param = 
            {
                ticket: this.resTicket,
                sign: this.resSign,
            }
            await this.$Axios.post(`${this.oauth_host}/register`, param, { headers: { 'Pragma' : 'no-cache', 'Cache-Control': 'no-cache', 'Access-Control-Allow-Origin': '*', 'Content-type': 'application/json', }}).then(async(res) => {
                // console.log(res.data);
                if (res.data.returnCode === 0) {
                    if(!this.isWebview){    //아튜브 앱이 아닐 경우 로그인 처리
                        await this.loginSubmit(res.data);
                        this.PopupOkCallbakPage = '/Signup/Complete';
                    } else {
                        this.PopupOkCallbakPage = 'AppLoginSuccess';
                    }
                    this.initPopupOkMessage = "회원 가입이 완료 되었습니다.";
                    this.isShowPopupOkPopup = true;
                }
                else if(res.data.returnCode === 141){
                    this.initPopupOkMessage = "티켓 확인 오류<br/>SNS 인증을 다시 시도해 주세요.";
                    this.PopupOkCallbakPage = '';
                    this.isShowPopupOkPopup = true;
                }
                else if(res.data.returnCode === 142){
                    this.initPopupOkMessage = "SNS 인증 확인 실패<br/>SNS 인증을 다시 시도해 주세요.";
                    this.PopupOkCallbakPage = '';
                    this.isShowPopupOkPopup = true;
                }
                else if(res.data.returnCode === 143){
                    this.initPopupOkMessage = "중복된 이메일 입니다.";
                    this.PopupOkCallbakPage = '';
                    this.isShowPopupOkPopup = true;
                }
                else {
                    this.initPopupOkMessage = "회원 가입이 실패 했습니다.";
                    this.PopupOkCallbakPage = '';
                    this.isShowPopupOkPopup = true;
                }
            })
            .catch((error) => {
                console.log(error);
            });
        },
        async loginSubmit(data) {
            await this.$store.commit("loginTicket", data.ticket);
            await this.$store.commit("loginSign", data.sign);
            await this.$store.commit("setProfile", this.host);
        },
        close(popupType){
            var that = this;
            switch(popupType){
                case 'PopupOk':
                    this.isShowPopupOkPopup = false;
                    if(this.PopupOkCallbakPage !== ''){
                        if(this.PopupOkCallbakPage === 'AppLoginSuccess'){
                            this.timerAppApi = setInterval(()=>{
                                window.appApi("loginSuccess", {
                                    returnCode: 0,
                                    ticket: that.resTicket,
                                    sign: that.resSign,
                                    social: that.resSocial
                                })
                            }, 1000)
                        } else {
                            this.$router.push(this.PopupOkCallbakPage);
                        }
                    }
                    this.PopupOkCallbakPage = '';
                    break;
                case 'PopupPolicy':
                    this.isClickPolicyPopup = false;
                    break;
                case 'PopupTerms':
                    this.isClickTermsPopup = false;
                    break;
            }
        },
    }
}
</script>