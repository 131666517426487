<template>
    <div class="floating" :class="[$store.state.isShowFloating ? 'floating-show' : 'floating-hidden']" v-if="$store.state.floatingData !== null">
        <div class="top">
            <p><span></span>알림</p>
            <img src="@/assets/images/assist/floatingClose.svg" @click="closeFloating" />
        </div>
        <!-- <p>1아트포인트가 변환되어 추가되었습니다.</p> -->
        <p>{{$store.state.floatingData.message}}</p>
        <div class="target">
            <img :src="$store.state.siteConfig.cdnUrl + '/KR/title/' + $store.state.floatingData.englishName + '.jpg'" v-if="$store.state.siteConfig !== null" />
            <div>
                <p class="name">{{$store.state.floatingData.titleName}}</p>
                <p>{{ (Math.floor($store.state.floatingData.point * 10000) / 10000) | comma }}P</p>
            </div>
            <a class="move" @click ="moveLibraryAssist()"><p>자세히</p><img src="@/assets/images/assist/whiteArrow.svg" /></a>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Floating',
    data(){
        return {
        }
    },
    props: {
        initConnectWebSocket:{
            type: Boolean,
            default: null
        },
    },
    watch:{
        initConnectWebSocket(){
            if(this.initConnectWebSocket === true){
                this.connectWebSocket();
            } else if(this.initConnectWebSocket === false) {
                this.disconnectWebSocket();
                this.closeFloating();
            }
        }
    },
    created() {
    },
    mounted(){
    },
    beforeDestroy() {
	},
    methods: {
        connectWebSocket(){
            try {
                // 1, 접속
                let that = this;
                if(this.$store.state.wsAlram === null){
                    this.$store.state.wsAlram = new WebSocket(this.ws_host); //소켓 연결 후 store 에 소켓 객체 저장
                    if(this.$store.state.wsAlram === undefined || this.$store.state.wsAlram === null) {     //소켓 연결이 잘 됐는지 체크
                        console.log('failed to connect websocket');     //연결 안됐으면 return
                        return; 
                    }
                    this.$store.state.wsAlram.onopen = function () {    //소켓 연결 후 onopen(소켓이 연결이 열렸음을 감지) 이벤트 헨들러에 해당 유저 email 채널로 입장
                        let channel = {     //채널 입장을 위한 메세지
                            cmd: 'userinfo',
                            userid: that.$store.state.email 
                        }
                        // console.log(channel);
                        that.$store.state.wsAlram.send(JSON.stringify(channel));    //웹소켓 서버로 채널 입장 메세지를 send 함
                        if(that.$store.state.intervalCheckWebSocketState !== null){ //소켓 상태 체크를 위한 interval 타이머를 초기화
                            clearInterval(that.$store.state.intervalCheckWebSocketState);
                        }
                        that.$store.state.intervalCheckWebSocketState = setInterval(()=>{ that.checkWebSocketState();}, 120000);    //소켓 상태 체크를 진행하는 타이머를 지속적으로 돌림(웹소켓 연결된 후 일정시간 동안 서로간의 통신이 없으면 자동으로 연결이 끊어지기 때문)
                    };
                    this.$store.state.wsAlram.onmessage = function (event) {    //웹소켓 서버로부터 받은 데이터를 처리하는 이벤트 핸들러
                        // 2, 웹소켓 응답
                        try {
                            // console.log(event.data);
                            let jsonObject = JSON.parse(event.data);    //서버에서 받은 데이터를 Json 형식으로 파싱
                            // console.log(jsonObject);
                            if(jsonObject){     //파싱된 데이터가 정상적으로 존재하는 경우 
                                if(jsonObject.alarmType){
                                    // console.log(jsonObject);
                                    if(jsonObject.alarmType === 1){ //알람타입 1 (후원 포인트 페이백 정산 알림)
                                        that.$store.state.floatingData = jsonObject;    //플로팅 팝업에서 사용할 store 변수에 데이터 입력
                                        that.$store.state.isShowFloating = true;        //플로팅 팝업 오픈
                                    }
                                }
                                if(jsonObject.returnCode){  //데이터에 returnCode 값이 존재하는 경우(최초 채널에 입장시 또는 웹소켓 연결 상태 체크 메세지를 보냈을때 돌아오는 응답을 처리 )
                                    if(jsonObject.returnCode === 0){    // 0일 경우 정상적인 상태임
                                        that.$store.state.wsCheckState = 2; //소켓 연결 상태가 정상적인 상태라는 값을 저장(0 연결 안함, 1: 연결 확인 요청 중, 2: 연결 확인 됨)
                                    } else {
                                        that.disconnectWebSocket(); //연결이 정상적이지 않을 경우 웹소켓 연결을 종료
                                    }
                                }
                            }
                        }
                        catch(e) {
                            console.error(event.data);
                            // console.error(e);
                        }
                    };
                    this.$store.state.wsAlram.onclose = function (event) { event;       //웹소켓 연결이 끊겼을때 실행되는 이벤트 핸들러
                        clearInterval(that.$store.state.intervalCheckWebSocketState);   //웹소켓 연결 체크 타이머를 종료
                        that.$store.commit("disconnectWebSocket");  //store 의 웹소켓 데이터를 초기화
                    };
                } else {    //이전에 웹소켓을 연결했던 경우 소켓 상태를 체크
                    var state = this.$store.state.wsAlram.readyState;
                    if(state !== 1){    //소켓 상태가 정상적이지 않을 경우 기존 웹소켓 연결을 종료하고 다시 접속
                        this.$store.commit("disconnectWebSocket")
                        this.connectWebSocket();
                    }
                }
            }
            catch(e){
                this.closeFloating();
                console.error(e);
            }
        },
        disconnectWebSocket(){  //소켓 연결 해제
            try{
                clearInterval(this.$store.state.intervalCheckWebSocketState);   //소켓 상태 체크 타이머 초기화

                if(this.$store.state.wsAlram !== null){ //store 에 저장된 웹소켓 객체가 존재할 경우
                    var state = this.$store.state.wsAlram.readyState;   //소켓의 현재 상태
                    if(state === 1){    //소켓이 연결되어 있는경우 소켓 연결을 해제
                        this.$store.state.wsAlram.close();  //소켓 연결 해제
                    }
                }
                this.$store.commit("disconnectWebSocket");  //store 의 웹소켓 데이터 초기화
            }
            catch(e){
                this.$store.commit("disconnectWebSocket");
                return;
            }
        },
        checkWebSocketState(){  //소켓 연결 상태 체크
            if(this.initConnectWebSocket){  //props 값이 소켓 연결인 경우(로그인 후 웹소켓을 연결하라는 값이 넘어 옴)
                if(this.$store.state.wsAlram !== null){ //기존 웹소켓 데이터가 있는 경우
                    if(this.$store.state.wsCheckState === 1){   //웹소켓 상태가 연결된 상태라면
                        this.disconnectWebSocket(); //기존 연결을 끊고
                        this.connectWebSocket();    //다시 연결
                    } else {
                        this.$store.state.wsCheckState = 1; //웹소켓 연결 체크 상태를 체크중으로 변경
                        this.$store.state.wsAlram.send(" ");    //서버로 웹소켓 연결 체크 요청 보냄
                    }
                } else {
                    this.connectWebSocket();    //기존 웹소켓 데이터가 없는 경우 소켓 연결 
                }
            } else {
                this.disconnectWebSocket(); //props 값이 소켓 연결 해제인 경우 웹소켓 연결 해제
                this.closeFloating();   //플로팅 알림도 닫음
            }
        },
        moveLibraryAssist(){
            this.$router.push('/LibraryAssist');
            this.$store.commit("resetFloating");
        },
        closeFloating() {
            var that = this;
            this.$store.state.isShowFloating = false;
            if(this.$store.state.floatingData !== null){
                setTimeout(() => {
                    that.$store.commit("resetFloating");
                }, 1000);
            } else {
                this.$store.commit("resetFloating");
            }
        },
    },
}
</script>