<template>
    <div>
        <div id="popup-wallet" v-if="initIsShowPopup && !isShowPopupOkPopup && !isShowPopupQR">
            <div class="box" :class="{'mobile' : !isCheckPc}">
                <img src="@/assets/images/icon_close.svg" alt="close" class="close" @click="closePopup" />
                <img src="@/assets/images/logo_klip.png" class="logo-klip" v-if="!isCheckPc"/>
                <h1>지갑 연결이 필요 합니다.</h1>
                <p class="text">ATT 코인으로 충전을 하기 위해서<br>지갑을 연결하거나, 새 지갑 생성이 필요합니다.</p>
                <p class="mobile-text" v-if="!isCheckPc">*모바일에선 Klip만 지원합니다.<br/>KAIKAS를 사용할 고객님은 PC를 이용해주세요.</p>
                <div class="contact_button klip" @click="connectKlip">
                    <img src="@/assets/images/icon/icon_klip_white.svg" />
                    <p>Klip 지갑으로 연결</p>
                </div>
                <div class="contact_button kaikas" @click="connectKaikas" v-if="isCheckPc">
                    <img src="@/assets/images/icon/icon_kaikas_white.svg" />
                    <p>Kaikas 지갑 연결</p>
                </div>
                
                <div class="see_you_later" @click="closePopup">다음에 하기</div>
            </div>
        </div>
        <VueKaikas :initLoadKaikas="$store.state.isEnableKaikas" :initIsMainNetForce="initIsMainNetForce" @onComplete="onComplete" />
        <PopupQR :initIsShowPopup="isShowPopupQR" :initRequestKey="initRequestKey" :initRequestType="initRequestType" @close="close('PopupQR')" @onComplete="onComplete" />
        <PopupOK :initIsShowPopup="isShowPopupOkPopup" :initPopupOkMessage="initPopupOkMessage" @close="close('PopupOk')" />
    </div>
</template>

<script>
import VueKaikas from '@/components/wallet/VueKaikas.vue'
import PopupOK from '@/components/common/PopupOK.vue'
import PopupQR from '@/components/point/PopupQR.vue'

import Caver from "caver-js";
import { prepare } from 'klip-sdk'

export default {
    name: 'Kaikas',
    components: {
        VueKaikas,
        PopupOK,
        PopupQR,
    },
    
    data() {
        return {
            isCheckPc: false,

            tokenInterval: null,

            isShowPopupQR: false,
            initRequestKey: null,
            initRequestType: 0,

            isShowPopupOkPopup: false,
            initPopupOkMessage: '',
            PopupOkCallbakPage: '',    // 확인 팝업에서 확인 버튼 눌렀을때 이동할 페이지
        }
    },
    props: {
        initIsMainNetForce: {   //메인넷 고정 여부
            type: Boolean,
            default: false
        },
        initIsShowPopup: {      //지갑 연결 팝업 표시 여부
            type: Boolean,
            default: false
        },
        initRunInterval:{       //Token Info 타이머 실행 여부
            type: Boolean,
            default: false
        }
    },
    watch:{
        initIsShowPopup(){
            if(this.initIsShowPopup){
                if(!this.$store.state.adult){
                    this.initPopupOkMessage = '성인인증 후 지갑연결이 가능합니다.';
                    this.PopupOkCallbakPage = 'cert-adult'
                    this.isShowPopupOkPopup = true;
                }
            }
        }
    },
    async created() {
        this.isCheckPc = this.isPc();
        await this.mixinLoginCheck();
        await this.checkWallet();
    },
    mounted(){
    },
    beforeDestroy() {
        if(this.tokenInterval){
            clearInterval(this.tokenInterval);
        }
	},
    methods: {
        setIntervalTokenInfo(){
            var that = this;
            if(that.$store.state.isEnableKaikas || that.$store.state.isEnableKlip){
                this.getTokenInfo();
                if(this.initRunInterval){
                    this.$store.state.tokenInfoTimerCount = 10;
                    this.tokenInterval = setInterval(() => {
                        if(that.$store.state.tokenInfoTimerCount <= 0){
                            that.getTokenInfo();
                            that.$store.state.tokenInfoTimerCount = 10;
                        }else{
                            that.$store.state.tokenInfoTimerCount--;
                        }
                    }, 1000);
                }
            }
        },
        closePopup(){
            this.$emit('close');
        },
        async checkWallet(){  //메타 마스크, 카이카스 체크
			var that = this;
            if(this.$store.state.isEnableKaikas || this.$store.state.isEnableKlip){
                if(this.isCheckPc){
                    var klaytn = await window.klaytn;
                    if(typeof klaytn !== 'undefined'){
                        setTimeout(async function(){
                            if(that.$store.state.loginCheck){
                                if(that.$store.state.isEnableKaikas && that.$store.state.erc20Address === that.$store.state.walletAddress){
                                    await klaytn.enable().then(accounts => {
                                        var account = accounts[0].toLowerCase();
                                        that.$store.commit("connectKaikas", account);
                                        that.setIntervalTokenInfo();
                                    });
                                } 
                                else if(that.$store.state.isEnableKlip && that.$store.state.klipAddress === that.$store.state.walletAddress){
                                    that.setIntervalTokenInfo();
                                }
                            }
                        }, 500)
                    }
                }
                else {
                    if(that.$store.state.isEnableKlip && that.$store.state.klipAddress === that.$store.state.walletAddress){
                        that.setIntervalTokenInfo();
                    }
                }
            }
            // else if(this.$store.state.isEnableKlip){
            //     if(that.$store.state.loginCheck && that.$store.state.klipAddress !== '' && that.$store.state.klipAddress !== null){
            //         console.log(this.$store.state.isEnableKlip);
            //         console.log(this.$store.state.klipAddress);
            //         console.log(this.$store.state.walletAddress);
            //     }
            // }
            
        },
        async connectKaikas(){
            var that = this;
            var klaytn = await window.klaytn;
            if(typeof klaytn !== 'undefined'){
                await klaytn.enable().then(accounts => {
                    var account = accounts[0].toLowerCase();        

                    if(this.$store.state.erc20Address === '' || this.$store.state.erc20Address === null){
                        setTimeout(()=>{that.signature(account)}, 1000);
                    }
                    else if(this.$store.state.erc20Address !== account){
                        this.initPopupOkMessage = '기존 등록된 지갑만 연결할 수 있습니다.<br/>Kaikas 지갑 주소를 확인해 주세요.';
                        this.isShowPopupOkPopup = true;
                    }
                    else {
                        this.$store.commit("connectKaikas", account);
                        this.closePopup();
                        this.setIntervalTokenInfo();
                    }
                });
            }
            else{
                this.initPopupOkMessage = 'Kaikas 미설치 또는 잠금 상태입니다.<br/>설치된 상태면 실행하여 로그인 해주세요.<br/><a href="https://chrome.google.com/webstore/detail/kaikas/jblndlipeogpafnldhgmapagcccfchpi?hl=ko" target="_blank" class="metamask_download">Kaikas 설치하기</a>';
                this.isShowPopupOkPopup = true;
            }
        },
        async connectKlip(){
            try{
                const res = await prepare.auth({bappName: "Artube"});
                this.initRequestKey = res.request_key;
                this.initRequestType = 1;
                this.isShowPopupQR = true;
            } catch(err){
                console.log(err);
            }
        },
        async onComplete(data){   //메타 마스크, 카이카스, Klip 리턴 대기
            // console.log(data);
            if(data.type === "CHANGED_ACCOUNT"){
                this.initPopupOkMessage = 'Kaikas 지갑이 변경되었습니다.';
                this.PopupOkCallbakPage = 'reload'
                this.isShowPopupOkPopup = true;
			}
            else if(data.type === "LOCKED_WALLET"){
                this.initPopupOkMessage = 'Kaikas가 잠금 설정 중입니다.';
                this.isShowPopupOkPopup = true;
				this.disconnectKaikas();
			}
            else if(data.type === "EMPTY_WALLET_ACCOUNT"){
                this.initPopupOkMessage = 'Kaikas에 연결된 지갑 없습니다.';
                this.isShowPopupOkPopup = true;
				this.disconnectKaikas();
			}
            else if(data.type === "NOT_INSTALL"){
                this.initPopupOkMessage = 'Kaikas가 설치되지 않았습니다.';
                this.isShowPopupOkPopup = true;
                this.disconnectKaikas();
            }
            else if(data.type === 'LOAD_MATAMASK_WALLET_ERROR'){
                this.initPopupOkMessage = 'Kaikas를 정상적으로 로드하지 못했습니다.';
                this.isShowPopupOkPopup = true;
                this.disconnectKaikas();
            }
            else if(data.type === 'MAIN_NET_FORCE'){
                this.initPopupOkMessage = 'Kaikas 네트워크를 메인넷으로 변경해 주세요.';
                this.PopupOkCallbakPage = 'reload'
                this.isShowPopupOkPopup = true;
            }
            // else if(data.type === 'TEST_NET'){
            //     this.initPopupOkMessage = 'Kaikas 네트워크가 메인넷이 아닙니다.';
            //     this.isShowPopupOkPopup = true;
            //     this.disconnectKaikas();
            // }
            else if(data.type === 'CHANGED_NETWORK'){
                this.initPopupOkMessage = 'Kaikas 네트워크가 변경되었습니다.';
                this.PopupOkCallbakPage = 'reload'
                this.isShowPopupOkPopup = true;
                // this.disconnectKaikas();
            }
            
            //---------------------KLIP 인증 관련-----------------------------
            else if(data.type === 'FAILED_CREATE_QRCODE'){
                this.initPopupOkMessage = 'QR 코드를 생성하지 못했습니다.<br/>잠시 후 다시 시도해 주세요.';
                this.isShowPopupOkPopup = true;
            }
            else if(data.type === 'FAILED_REQUEST'){
                this.initPopupOkMessage = '모바일 기기에서 이용 가능한 기능입니다.<br/>카카오톡이 설치된 스마트폰에서 이용해 주세요.';
                this.isShowPopupOkPopup = true;
            }
            else if(data.type === 'CANCLED_KLIP_RESULT'){
                this.initPopupOkMessage = 'KLIP 인증을 취소하셨습니다.';
                this.isShowPopupOkPopup = true;
            }
            else if(data.type === 'SUCCESS_KLIP_RESULT'){
                if(data.requestType === 1){     //requestType - 1:prepareAuth, 2:prepareToken
                    var klaytnAddress = data.klaytnAddress.toLowerCase();
                    if(this.$store.state.klipAddress === '' || this.$store.state.klipAddress === null){ //최초 지갑 연결일 경우 
                        var isResult = await this.reqKlipConnect(klaytnAddress, data.requestKey);
                        if(isResult) this.$store.commit("connectKlip", klaytnAddress);
                    }
                    else if(this.$store.state.klipAddress !== klaytnAddress){
                        this.initPopupOkMessage = '기존 등록된 지갑만 연결할 수 있습니다.<br/>Klip 지갑 주소를 확인해 주세요.';
                        this.isShowPopupOkPopup = true;
                    }
                    else {
                        this.$store.commit("connectKlip", klaytnAddress);
                        this.setIntervalTokenInfo();
                        this.closePopup();
                    }
                } 
            }

        },
        disconnectKaikas(){   //메타 마스크, 카이카스 연결 끊기
            this.$store.commit("disconnectKaikas");
		},
        async getTokenInfo(){ // att 토큰 정보
            var param = 
            {
            }
            await this.$Axios.post(`${this.host}/tokenInfo`, param, { headers: { 'Pragma' : 'no-cache', 'Cache-Control': 'no-cache', 'Access-Control-Allow-Origin': '*', 'Content-type': 'application/json', }}).then(res => {
                // console.log(res.data);
                if (res.data.returnCode === 0) {
                    this.$store.state.tokenInfo = res.data.data;

                    if(this.$store.state.tokenInfo && (this.$store.state.isEnableKaikas || this.$store.state.isEnableKlip)){
                        this.getTokenBalance(this.$store.state.tokenInfo.contractAddress, (balance)=>{
                            if(balance !== null){
                                this.$store.state.attTokenBalance = Number(balance.toString());
                            }
                            else{
                                this.$store.state.attTokenBalance = 0;
                            }
                        });
                    }
                }
                else{
                    this.$store.state.tokenInfo = null;
                }
            })
            .catch((error) => {
                console.log(error);
            });
        },
        async signature(account){  //메타 마스크, 카이카스 사이닝
            var caver = new Caver(window.klaytn);
            var message = 'Artube Signature(' + Date.now().toString() + ')';

            return await caver.klay.sign(message, account).then(signature => {
                var param = 
                {
                    address: account,
                    message: message,
                    signature : signature,
                    sign: this.$store.state.sign,
                    ticket: this.$store.state.ticket,
                }
                // console.log(param)
                this.$Axios.post(`${this.host}/erc20/connect`, param, { headers: { 'Pragma' : 'no-cache', 'Cache-Control': 'no-cache', 'Access-Control-Allow-Origin': '*', 'Content-type': 'application/json', }}).then(res => {
                    // console.log(res.data);
                    if (res.data.returnCode === 0) {
                        this.initPopupOkMessage = 'Kaikas 지갑 연결이 완료 되었습니다.';
                        // this.PopupOkCallbakPage = 'wallet-connect'
                        this.PopupOkCallbakPage = 'reload'
                        this.isShowPopupOkPopup = true;
                    }
                    else if(res.data.returnCode === 252){
                        this.initPopupOkMessage = '성인인증 후 지갑연결이 가능합니다.';
                        this.isShowPopupOkPopup = true;   
                    }
                    else if(res.data.returnCode === 914){
                        this.initPopupOkMessage = 'Kaikas 서명 오류 입니다.';
                        this.isShowPopupOkPopup = true;   
                    }
                    else if(res.data.returnCode === 915){
                        this.initPopupOkMessage = '이미 다른 계정에서 사용중인 지갑 주소입니다.';
                        this.isShowPopupOkPopup = true;   
                    }
                    else if(res.data.returnCode === 916){
                        this.initPopupOkMessage = '기존 등록된 지갑만 연결할 수 있습니다.<br/> Kaikas 지갑을 확인해 주세요';
                        this.isShowPopupOkPopup = true;   
                    }
                    else{
                        this.initPopupOkMessage = '지갑 연결이 실패했습니다.<br/>'+ res.data.description;
                        this.isShowPopupOkPopup = true;
                        // alert("지갑 연결 실패\n" + res.data.description);
                    }
                    return true;
                })
                .catch((error) => {
                    console.log(error);
                    return false;
                });
            })
            .catch(err => {
                // console.log(err);
                err;
                return false;
            })
        },
        async reqKlipConnect(klaytnAddress, requestKey){
            var param = 
            {
                address: klaytnAddress,
                requestKey: requestKey,
                sign: this.$store.state.sign,
                ticket: this.$store.state.ticket,
                viewAdult: this.$store.state.isCheckedAdult ? 1 : 0
            }
            // console.log(param)
            return await this.$Axios.post(`${this.host}/klip/connect`, param, { headers: { 'Pragma' : 'no-cache', 'Cache-Control': 'no-cache', 'Access-Control-Allow-Origin': '*', 'Content-type': 'application/json', }}).then(res => {
                // console.log(res.data);
                if (res.data.returnCode === 0) {
                    this.initPopupOkMessage = 'Klip 지갑 연결이 완료 되었습니다.';
                    // this.PopupOkCallbakPage = 'wallet-connect'
                    this.PopupOkCallbakPage = 'reload'
                    this.isShowPopupOkPopup = true;
                }
                else if(res.data.returnCode === 914){
                    this.initPopupOkMessage = 'Klip 서명 오류 입니다.';
                    this.isShowPopupOkPopup = true;   
                }
                else if(res.data.returnCode === 915){
                    this.initPopupOkMessage = '이미 다른 계정에서 사용중인 지갑 주소입니다.';
                    this.isShowPopupOkPopup = true;   
                }
                else if(res.data.returnCode === 916){
                    this.initPopupOkMessage = '기존 등록된 지갑 주소로만 연결할 수 있습니다.<br/>확인 후 다시 시도해 주세요.';
                    this.isShowPopupOkPopup = true;   
                }
                else{
                    this.initPopupOkMessage = '지갑 연결이 실패했습니다.<br/>'+ res.data.description;
                    this.isShowPopupOkPopup = true;
                    // alert("지갑 연결 실패\n" + res.data.description);
                }
                return true;
            })
            .catch((error) => {
                console.log(error);
                return false;
            });
        },
        async getTokenBalance(tokenAddress, callback) {
            const nodeApiUrl = process.env.VUE_APP_NODE_API_URL;
            const accessKeyId = process.env.VUE_APP_ACCESS_KEY_ID;
            const secretAccessKey = process.env.VUE_APP_SECRET_ACCESS_KEY;
            const chainId = process.env.VUE_APP_CHAIN_ID;
            const option = {
                headers: [
                    {
                        name: 'Authorization',
                        value: `Basic ${Buffer.from(`${accessKeyId}:${secretAccessKey}`).toString('base64')}`,
                    },
                    { name: 'x-chain-id', value: chainId },
                ],
            }
            
            try{
                var caver = null;
                if(this.isCheckPc){
                    caver = new Caver(window.klaytn);   //PC일 경우 klaytn API 사용
                } else {
                    caver = new Caver(new Caver.providers.HttpProvider(nodeApiUrl, option));    //mobile 일 경우 KAS API 사용
                }
                var objKip7 = await new caver.kct.kip7(tokenAddress);
                await objKip7.balanceOf(this.$store.state.walletAddress).then(balance => {
                    objKip7.decimals().then(decimals=>{
                        balance = balance.div(10**decimals);
                        callback(balance);
                    })
                });
            }
            catch(err){
                return;
            }
		},
        close(popupType){
            switch(popupType){
                case 'PopupQR':
                    this.isShowPopupQR = false;
                    this.initRequestKey = null;
                    this.initRequestType = 0;
                    this.closePopup();
                    break;
                case 'PopupOk':
                    if(this.PopupOkCallbakPage === 'wallet-connect'){
                        this.closePopup();
                    }
                    else if(this.PopupOkCallbakPage === 'cert-adult'){
                        var src = this.$store.state.siteConfig.niceUrl + '?email=' + this.$store.state.email;
                        window.open(src, 'popupChk', 'width=500, height=550, top=100, left=100, fullscreen=no, menubar=no, status=no, toolbar=no, titlebar=no, location=no, scrollbar=no');
                        this.closePopup();
                    }
                    else if(this.PopupOkCallbakPage === 'reload'){
                        location.reload();
                    }
                    else if(this.PopupOkCallbakPage !== ''){
                        this.$router.push(this.PopupOkCallbakPage);
                    }
                    this.isShowPopupOkPopup = false;
                    this.PopupOkCallbakPage = '';
                    break;
            }
        },
    }
}
</script>