<template>
    <div :class="[isSuccessSendMail === true ? 'findpass2 wrap' : 'findpass wrap' ]">
        <Header :initHeaderType="initHeaderType" />
        <div class="findpass_box" v-if="!isSuccessSendMail">
            <h1>비밀번호 재설정</h1>
            <p>비밀번호를 다시 설정하시려면 아래 입력창에 본인의 메일 주소를 입력하세요.</p>
            <div class="email">
                <input type="text" autocomplete="off" placeholder="이메일 주소를 입력해주세요" v-model="email" :disabled="isReqEmail" @input="validateEmail">
            </div>
            <div class="empty_box">
                <p class="error" v-if="isShowErrorEmail">{{errorMsgEmail}}</p>
                <p class="sending" v-if="isReqEmail">이메일 발송 중입니다. 잠시만 기다려 주세요...</p>
            </div>
            <div class="confirm" @click="sendEmail">확인</div>
            <div class="ps">
                ※ 이용 중 도움이 필요하시면 [고객지원] 페이지로, 로그인에 문제가 있다면 <a href="mailto:contact@artube.video">contact@artube.video</a>으로 문의해주세요.
            </div>
        </div>
        <div class="findpass2_box" v-else>
            <h1>비밀번호 재설정</h1>
            <p>비밀번호 재설정 메일을 보냈습니다.<br>메일함을 확인해 보세요.</p>
            <p>메일이 수신되지 않는 경우에는 스팸 메일함<br>을 확인해보시기 바랍니다.</p>
            <router-link tag="a" to="/" class="home">아튜브 홈으로</router-link>
            <div class="ps">※ 이용 중 도움이 필요하시면 [고객지원] 페이지로, 로그인에 문제가 있다면 <a href="mailto:contact@artube.video">contact@artube.video</a>으로 문의해주세요.</div>
        </div>
        <PopupOK :initIsShowPopup="isShowPopupOkPopup" :initPopupOkMessage="initPopupOkMessage" @close="close('PopupOk')" />
        <Footer />
    </div>
</template>

<script>
import Header from '@/components/common/Header.vue'
import Footer from '@/components/common/Footer.vue'
import PopupOK from '@/components/common/PopupOK.vue'

export default {
    name: 'PasswordResetReq',
    pageCategory: 'Member',
    pageTitle: '비밀번호 재설정',
    components: {
        Header,
        Footer,
        PopupOK,
    },
    data() {
        return {
            email: '',
            isPassEmail: false,
            isReqEmail: false,
            isShowErrorEmail: false,
            isSuccessSendMail: false,

            errorMsgEmail: '',

            isShowPopupOkPopup: false,
            initPopupOkMessage: '',
            PopupOkCallbakPage: '',    // 확인 팝업에서 확인 버튼 눌렀을때 이동할 페이지

            initHeaderType: 'type2',
        }
    },
    methods: {
        validateEmail() {
            this.isShowErrorEmail = false;
            let filter = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;

            if(this.email === ''){
                return;
            }
            else if (!filter.test(this.email)) {
                this.isShowErrorEmail = true;
                this.errorMsgEmail = '잘못된 이메일 형식 입니다.';
                return;
            }
            else {
                this.isPassEmail = true;
            }
        },

        sendEmail(){

            if(this.email === ''){
                this.initPopupOkMessage = '이메일을 입력해 주세요.';
                this.isShowPopupOkPopup = true;

                this.errorMsgEmail = '이메일을 입력해 주세요.';
                this.isShowErrorEmail = true;
                return;
            }
            this.validateEmail();

            if(this.isPassEmail){
                if(!this.isReqEmail){
                    
                    this.isReqEmail = true;

                    var param = 
                    {
                        email: this.email,
                    }
                    this.$Axios.post(`${this.host}/password/edit`, param, { headers: { 'Pragma' : 'no-cache', 'Cache-Control': 'no-cache', 'Access-Control-Allow-Origin': '*', 'Content-type': 'application/json', }}).then(async(res) => {
                        this.isReqEmail = false;
                        // console.log(res.data);
                        if (res.data.returnCode === 0) {
                            this.initPopupOkMessage = '이메일이 정상적으로 발송 되었습니다.';
                            this.isShowPopupOkPopup = true;
                            this.isSuccessSendMail = true;
                            // this.$router.push('/Findpass2');
                        }
                        else if(res.data.returnCode === 208){
                            this.initPopupOkMessage = '가입되지 않은 이메일 주소입니다.<br/>확인 후 다시 시도해 주세요.';
                            this.isShowPopupOkPopup = true;
                        }
                        else {
                            alert(res.data.description);
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                    });
                }
            } 
        },
        close(popupType){
            switch(popupType){
                case 'PopupOk':
                    this.isShowPopupOkPopup = false;
                    if(this.PopupOkCallbakPage !== ''){
                        this.$router.push(this.PopupOkCallbakPage);
                    }
                    this.PopupOkCallbakPage = '';
                    break;
            }
        },
    }

}
</script>