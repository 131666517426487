<template>
    <div id="secession" v-if="initIsShowPopup">
        <div class="box">
            <p>아튜브 회원 탈퇴시 보유하신 아트포인트<br>및 구매 콘텐츠 이용이 불가능합니다.<br>그래도 탈퇴하시겠습니까?</p>
            <div class="buttons">
                <p class="cancel" @click="close">취소</p>
                <p class="bye" @click="leave">탈퇴하기</p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Secession',
    data(){
        return {
        }
    },
    props: {
        initIsShowPopup: {
            type: Boolean,
            default: false
        }
    },
    async created() {
        // await this.mixinLoginCheck();
        // if(this.$store.state.loginCheck === false){
        //     this.$router.push('/');
        // }
    },
    mounted(){
    },
    beforeDestroy() {
	},
    methods: {
        close(){
            this.$emit('close');
        },
        leave() {
            var param = 
            {
                sign: this.$store.state.sign,
                ticket: this.$store.state.ticket,
                viewAdult: this.$store.state.isCheckedAdult ? 1 : 0
            }
            this.$Axios.post(`${this.host}/member/leave`, param, { headers: { 'Pragma' : 'no-cache', 'Cache-Control': 'no-cache', 'Access-Control-Allow-Origin': '*', 'Content-type': 'application/json', }}).then(res => {
                if (res.data.returnCode === 0) {
                    this.$store.commit("logout");
                    this.$router.push('/');
                } 
                else {
                    console.log(res.data.description);
                }
            })
            .catch((error) => {
                console.log(error);
            });
        },
    },
}
</script>