<template>
    <div class="full-preview" v-if="initIsShowPopup && initImageList.length > 0">
        <div class="container">
            <img src="@/assets/images/icon_close.svg" alt="close" class="close" @click="closePopup" />
            <div class="header">
                <h2>전체 미리보기</h2>
            </div>
            <div class="body">
                <img class="show-img img-responsive" :src="image.path" v-for="(image, index) in initImageList" :key="index">
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'PopupFullPreview',
    components: {
    },
    data(){
        return {
        }
    },
    props: {
        initIsShowPopup: {
            type: Boolean,
            default: false
        },
        initImageList: {
            type: Array,
            default: () => {
                return []
            }
        },
    },
    created(){
    },
    mounted(){
    },
    beforeDestroy() {
	},
    methods: {
        closePopup(){
            this.$emit('close');
        },
    },
}
</script>