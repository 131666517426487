<template>
    <b-col cols="6" sm="3" class="webtoon-curation-item" @click="selected">
        <div class="boxBorder">
            <div class="head">
                <img :src="$store.state.siteConfig.cdnUrl + '/KR/title/' + initWebtoonInfo.twebtoonForApi.englishName + '.jpg'" class="webtoon-thumbnail" />
                <img src="@/assets/images/adult_mark.svg" alt="성인 마크" class="mark-adult" v-if="initWebtoonInfo.twebtoonForApi.adult === 1">
            </div>
            <div class="body">
                <p class="webtoon-name">{{initWebtoonInfo.twebtoonForApi.titleName}}</p>
                <p class="webtoon-intro" >{{initWebtoonInfo.twebtoonForApi.publisher}}</p>
            </div>
        </div>
    </b-col>
</template>
<script>
export default {
    name: 'AssistCurationItem',
    data(){
        return {
        }
    },
    props: {
        initWebtoonInfo: {
            type: Object,
            default: null
        },
    },
    watch: {
    },
    created() {
    },
    mounted(){
    },
    beforeDestroy() {
	},
    methods: {
        selected(){
            this.$emit('selected')
        }
    },
}
</script>
