var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:{'comic-book' : _vm.webtoonInfo !== null && _vm.webtoonInfo.viewType == 1},attrs:{"id":"viewer"}},[_c('Header',{attrs:{"initHeaderType":_vm.initHeaderType,"initIsHeaderShow":_vm.isShowViewer},on:{"changeState":_vm.changeState}}),_c('Viewerheader',{attrs:{"initWebtoonInfo":_vm.webtoonInfo,"initEpisodeIdx":_vm.currEpisodeIdx,"initEpisodeState":_vm.episodeState,"initisShowViewer":_vm.isShowViewer}}),(_vm.webtoonInfo !== null && _vm.webtoonInfo.viewType == 0)?_c('div',{staticClass:"container"},[_c('div',{attrs:{"id":"viewer-img"}},_vm._l((_vm.imageList),function(item,index){return _c('img',{key:index,attrs:{"src":_vm.host + '/episode/image/view/' 
                        + _vm.$store.state.ticket + '/'
                        + _vm.$store.state.sign + '/'
                        + _vm.currWebtoonIdx + '/'
                        + _vm.currEpisodeIdx + '/'
                        + item.page + '/'
                        + 'KR'},on:{"click":_vm.touchImg}})}),0),(_vm.episodeList.length > 0 && _vm.$store.state.siteConfig !== null)?_c('div',{staticClass:"group"},[_c('div',{staticClass:"prev",on:{"click":function($event){return _vm.prevPage()}}},[_vm._v("prev")]),_vm._l((_vm.episodeList),function(item,index){return _c('div',{key:index,staticClass:"toon",class:[item.episodeIdx === _vm.currEpisodeIdx ? 'now' : 'secret'],on:{"click":function($event){return _vm.changeEpisode(_vm.currWebtoonIdx, item.episodeIdx)}}},[_c('img',{staticClass:"ssum",attrs:{"src":_vm.$store.state.siteConfig.cdnUrl + '/KR/' + item.url,"alt":"썸네일"}}),_c('p',[_vm._v(_vm._s(item.episodeIdx)+"화")])])}),_c('div',{staticClass:"next",on:{"click":function($event){return _vm.nextPage()}}},[_vm._v("next")])],2):_vm._e(),_c('div',{staticClass:"buttons"},[_c('div',{staticClass:"list",on:{"click":_vm.backList}},[_vm._v("목록 보기")])])]):_vm._e(),(_vm.webtoonInfo !== null && _vm.webtoonInfo.viewType == 1)?_c('div',[(_vm.$store.state.siteConfig !== null)?_c('div',{staticClass:"comic-viewer"},[(_vm.imageList.length > 0 && _vm.isShowSlickViewer)?_c('VueSlickCarousel',_vm._b({ref:"comicSlider",staticClass:"slider",style:({maxHeight: _vm.currImgHeight+'px'}),on:{"init":_vm.initSliderViewer,"beforeChange":_vm.sliderViewerBeforeChange}},'VueSlickCarousel',_vm.sliderViewerSettings,false),[_vm._l((_vm.imageList),function(item,index){return _c('div',{key:index},[_c('img',{attrs:{"id":'comicImg-' + index,"src":_vm.host + '/episode/image/view/' 
                            + _vm.$store.state.ticket + '/'
                            + _vm.$store.state.sign + '/'
                            + _vm.currWebtoonIdx + '/'
                            + _vm.currEpisodeIdx + '/'
                            + item.page + '/'
                            + 'KR'},on:{"click":_vm.touchImg,"load":function($event){return _vm.onImgLoaded(index)}}})])}),(_vm.imageList.length % 2 === 1)?_c('div',[_c('img',{attrs:{"src":require("@/assets/images/viewer/last_blank.jpg")},on:{"click":_vm.touchImg}})]):_vm._e()],2):_vm._e()],1):_vm._e()]):_vm._e(),(_vm.webtoonInfo !== null && _vm.webtoonInfo.viewType == 0)?_c('Footer'):_vm._e(),_c('Viewermove',{attrs:{"initWebtoonInfo":_vm.webtoonInfo,"initEpisodeState":_vm.episodeState,"initisShowViewer":_vm.isShowViewer,"initEpisodeIdx":_vm.currEpisodeIdx,"initPagingData":_vm.initPagingData,"initEpisodeOptimum":_vm.episodeOptimum},on:{"scrollBottom":_vm.scrollBottom,"checkEpisodeState":_vm.changeEpisode}}),_c('Login',{attrs:{"initIsShowPopup":_vm.isShowLoginPopup},on:{"close":function($event){return _vm.close('Login')}}}),_c('Buy',{attrs:{"initIsShowPopup":_vm.isShowBuyPopup,"initWebtoonInfo":_vm.webtoonInfo,"initEpisodeInfo":_vm.changeEpisodeInfo,"initSiteConfig":_vm.$store.state.siteConfig},on:{"close":function($event){return _vm.close('Buy')}}}),(_vm.webtoonInfo !== null && _vm.webtoonInfo.viewType == 1)?_c('ViewerWidthPopup',{attrs:{"initWebtoonInfo":_vm.webtoonInfo,"initIsShowPopup":_vm.isShowPopup,"initPopupOkMessage1":_vm.initPopupOkMessage1,"initPopupOkMessage2":_vm.initPopupOkMessage2,"initPopupOkMessage3":_vm.initPopupOkMessage3},on:{"close":function($event){return _vm.close('ViewerWidthPopup')}}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }