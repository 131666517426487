<template>
    <div class="footer">
        <b-container>
            <div class="top">
                <h1 class="logo">artube</h1>
                <a href="https://artube.video/biz.html" target="_blank" class="business">비즈니스 페이지</a>
                <router-link tag="a" to="/Swap" class="business footer-swap">ATT간편스왑</router-link>
                <a @click="showPolicy">개인정보처리방침</a>
                <a @click="showTerms">서비스 이용약관</a>
                <a @click="showYouth">청소년보호정책</a>
                <!-- <router-link tag="a" to="/Alliance">광고제휴문의</router-link> -->
                <a class="serial-inquiry" @click="showJeanGuidePopup">연재문의</a>
                <a href="https://market.artube.video/" target="_blank" class="marketplace">NFT 마켓플레이스</a>
                <a href="https://governance.artube.video/" target="_blank" class="governance">거버넌스</a>
            </div>
            <div class="mid">
                <div class="left">
                    <p class="ps">※ 본 컨텐츠의 저작권은 저자 또는 제공처에 있으며 이를 무단 이용하는 경우 저작권법 등에 따라 법적 책임을 질 수 있습니다.</p>
                    <p class="m_ps">Artube는 블록체인과 결합한 차세대 컨텐츠 서비스 입니다.<span>아티스트와 팬, 플랫폼이 함께 성장해 나갑니다.</span></p>
                    <div class="three">
                        <p>(주)넘버스 사업자등록번호 : 773-87-00680</p>
                        <p>통신판매업신고번호 : 2019-제주이도2-0009</p>
                        <a @click="openBizCommmPop">사업자 정보 확인</a>
                    </div>
                    <div class="three">
                        <p>대표이사 문영오</p>
                        <p>대표번호 02-3444-5567</p>
                        <p>이메일 contact@artube.video</p>
                    </div>
                    <p class="adress">제주도 제주시 중앙로 217, 제주벤처마루 4층 제주창조경제혁신센터 ㈜넘버스</p>
                </div>
                <div class="right">
                    <div class="categori">
                        <p class="title">카테고리</p>
                        <ul>
                            <li v-for="(item, index) in categoryList" :key="index" :class="[index % 2 == 0 ? '' : 'hidden']">
                                <router-link tag="a" :to="'/Category/' + item.idx">{{item.name}}</router-link>
                            </li>
                        </ul>
                        <ul class="mr0">
                            <li v-for="(item, index) in categoryList" :key="index" :class="[index % 2 == 1 ? '' : 'hidden']">
                                <router-link tag="a" :to="'/Category/' + item.idx">{{item.name}}</router-link>
                            </li>
                        </ul>
                    </div>
                    <div class="categori">
                        <p class="title">아트포인트</p>
                        <ul>
                            <li>
                                <router-link tag="a" to="/Point/Charge">충전</router-link>
                            </li>
                            <!-- <li>
                                <router-link tag="a" to="Withdraw">출금</router-link>
                            </li> -->
                            <!-- <li>
                                <router-link tag="a" to="/Point/History/Withdraw">출금내역</router-link>
                            </li> -->
                            <li v-if="$store.state.loginCheck">
                                <router-link tag="a" to="/Point/History/Charge">충전내역</router-link>
                            </li>
                            <li v-if="$store.state.loginCheck">
                                <router-link tag="a" to="UseHistory">사용내역</router-link>
                            </li>
                        </ul>
                    </div>
                    <div class="categori">
                        <p class="title">설정</p>
                        <ul class="mr0">
                            <li v-if="$store.state.loginCheck">
                                <router-link tag="a" to="/Member/Info">내 정보</router-link>
                            </li>
                            <li v-if="$store.state.loginCheck">
                                <router-link tag="a" to="/Member/Library">내 서재</router-link>
                            </li>
                            <li>
                                <router-link tag="a" to="/Notice">공지사항</router-link>
                            </li>
                            <li>
                                <router-link tag="a" to="/FAQ">자주하는 질문</router-link>
                            </li>
                            <li v-if="$store.state.loginCheck">
                                <router-link tag="a" to="/Contact">1:1 문의</router-link>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="mid-m">
                <a @click="isShowCompanyInfo = !isShowCompanyInfo">Artube 사업자 정보 <i class="icon icon-footer-arrow-down" :class="[isShowCompanyInfo ? 'icon-footer-arrow-up' : 'icon-footer-arrow-down']"></i> </a>
                <b-collapse id="collapse" class="xs-2 mid-m-dotted" v-model="isShowCompanyInfo">
                    <b-card >
                        <p>(주)넘버스 사업자등록번호 : 773-87-00680</p>
                        <p>통신판매업신고번호 : 2019-제주이도2-0009</p>
                        <p>대표이사 문영오</p>
                        <p>대표번호 02-3444-5567</p>
                        <p>이메일 contact@artube@video</p>
                        <p>제주도 제주시 중앙로 217, 제주벤처마루 4층 제주창조경제혁신센터 ㈜넘버스</p>
                        <div class="mid-m-link">
                            <a @click="openBizCommmPop">사업자 등록정보 확인</a>
                            <a @click="showPolicy">개인정보 처리방침</a>
                            <a @click="showYouth">청소년 보호정책</a>
                        </div>
                    </b-card>
                </b-collapse>
            </div>
            <div class="bottom">
                <p class="copy">Copyright © 2021 artube. ALL rights reserved.</p>
                <div class="icon_box">
                    <a href="https://twitter.com/Artube_Token" target="_blank" class="twitter">twitter</a>
                    <a href="https://t.me/att_chat" target="_blank" class="message">telegram</a>
                    <a href="https://medium.com/artube" target="_blank" class="circle">medium</a>
                    <a href="https://www.youtube.com/channel/UC79E7h4jhmIaIAJpFlZ1jPA" target="_blank" class="youtube">youtube</a>
                </div>
            </div>
        </b-container>
        <Policy :initIsShowPopup="isShowPolicyPopup" @close="close('Policy')" />
        <Terms  :initIsShowPopup="isShowTermsPopup" @close="close('Terms')" />
        <Youth  :initIsShowPopup="isShowYouthPopup" @close="close('Youth')" />
        <Jeanguide :initIsShowPopup="isShowJeanguidePopup" @close="close('JeanGuide')" />
    </div>
</template>


<script>

import Policy from '@/components/support/Policy.vue'
import Terms from '@/components/support/Terms.vue'
import Youth from '@/components/support/Youth.vue'
import Jeanguide from '@/components/support/Jeanguide.vue'

export default {
name: 'Header',
    components: {
        Policy,
        Terms,
        Youth,
        Jeanguide,
    },
    
    data() {
        return {
            isShowPolicyPopup: false,
            isShowTermsPopup: false,
            isShowYouthPopup: false,
            isShowJeanguidePopup: false,

            isShowCompanyInfo: false,
            
            categoryList: [],
        }
    },
    props: {
    },
    created() {
        this.mixinReqPageView();        //페이지 접속 카운팅
        this.getCategory();
    },
    methods: {
        showPolicy(){
            this.isShowPolicyPopup = true;
        },
        showTerms(){
            this.isShowTermsPopup = true;
        },
        showYouth(){
            this.isShowYouthPopup = true;
        },
        showJeanGuidePopup(){
            this.isShowJeanguidePopup = true;
        },
        openBizCommmPop()
        {
            var url ="http://www.ftc.go.kr/bizCommPop.do?wrkr_no=7738700680";
            window.open(url, "bizCommPop", "width=750, height=700;");
        },
        close(popupType){
            switch(popupType){
                case 'Policy':
                    this.isShowPolicyPopup = false;
                    break;
                case 'Terms':
                    this.isShowTermsPopup = false;
                    break;
                case 'Youth':
                    this.isShowYouthPopup = false;
                    break;
                case 'JeanGuide':
                    this.isShowJeanguidePopup = false;
                    break;
            }
        },

        getCategory(){ // 카테고리 정보
            var param = 
            {
                // limit : 3
            }
            this.$Axios.post(`${this.host}/infocategory`, param, { headers: { 'Pragma' : 'no-cache', 'Cache-Control': 'no-cache', 'Access-Control-Allow-Origin': '*', 'Content-type': 'application/json', }}).then(res => {
                if (res.data.returnCode === 0) {
                    this.categoryList = res.data.list;
                }
            })
            .catch((error) => {
                console.log(error);
            });
        },
    }
}
</script>