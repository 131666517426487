<template>
    <div id="viewermove" v-if="initEpisodeState !== null">
        <div class="controller">
            <p class="title">{{initWebtoonInfo.ctTitleName}}</p>
            <p class="ep">{{initEpisodeIdx}}화</p>
            <div class="move-button">
                <div class="prev end" @click="endPrevEpisode" :class="[initPreEpisodeIdx !== null ? 'click' : '']">
                    <img src="@/assets/images/controller_end_arrow_disable.svg" alt="이전화">
                </div>
                <div class="prev" @click="prevEpisode" :class="[initPreEpisodeIdx !== null ? 'click' : '']">
                    <img src="@/assets/images/controller_arrow_disable.svg" alt="이전화">
                </div>
                <div class="next" @click="nextEpisode" :class="[initNextEpisodeIdx !== null ? '' : 'click']">
                    <img src="@/assets/images/controller_arrow_able.svg" alt="다음화">
                </div>
                <div class="next end" @click="endNextEpisode" :class="[initNextEpisodeIdx !== null ? '' : 'click']">
                    <img src="@/assets/images/controller_end_arrow_able.svg" alt="다음화">
                </div>
            </div>
            <div class="move-want">
                <input type="text" v-model="wantPage" maxlength="3" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');" @click="[wantPage='', wantPagePlaceholder='']">
                <p v-if="initPagingData !== null">/ {{initPagingData.totalRecordSize}}</p>
                <a @click="getEpisodeCount">
                    <img src="@/assets/images/controller-move.svg" />
                </a>
            </div>
            <div class="controller-menu">
                <div @click="scrollTop">
                    <img src="@/assets/images/controller-up.svg" />
                </div>
                <div @click="scrollBottom">
                    <img src="@/assets/images/controller-down.svg" />
                </div>
                <div @click="$router.push('/League')">
                    <img src="@/assets/images/controller-home.svg" />
                </div>
                <div class="end" @click="$router.push('/League/Webtoon/Info/' + initWebtoonInfo.ctWebtoonIdx)">
                    <img src="@/assets/images/controller-list.svg" />
                </div>
            </div>
        </div>
        <div class="viewermove_box" v-if="initisShowViewer">
            <div class="prev" @click="prevEpisode" :class="[initPreEpisodeIdx !== null ? 'click' : '']">
                <img src="@/assets/images/mobile/next_arrow.svg" alt="이전화">
                <p>이전화</p>
            </div>
            <div class="go-list" @click="$router.push('/League/Webtoon/Info/' + initWebtoonInfo.ctWebtoonIdx)">
                <img src="@/assets/images/viewer-menu-m.svg" />
            </div>
            <div class="next" @click="nextEpisode" :class="[initNextEpisodeIdx !== null ? 'click' : '']">
                <p>다음화</p>
                <img src="@/assets/images/mobile/next_arrow.svg" alt="다음화">
            </div>
        </div>
        <Login :initIsShowPopup="isShowLoginPopup" @close="close('Login')" />
    </div>
</template>

<script>

import Login from '@/components/common/Login.vue'

export default {
    name: 'Viewermove',
    components: {
        Login,
    },
    data(){
        return {
            episodeCount: null,
            chagneEpisodeInfo: null,
            isShowLoginPopup: false,

            wantPage: '',
            wantPagePlaceholder: this.$route.params.epidx,
        }
    },
    props: {
        initWebtoonInfo: {
            type: Object,
            default: null
        },
        initEpisodeState: {
            type: Object,
            default: null
        },
        initPreEpisodeIdx: {
            type: Object,
            default: null
        },
        initNextEpisodeIdx: {
            type: Object,
            default: null
        },
        initEpisodeOptimum: {
            type: Object,
            default: null
        },
        initisShowViewer: {
            type: Boolean,
            default: true,
        },
        initEpisodeIdx:{
            type: Number,
            default: 0,
        },
        initPagingData: {
            type: Object,
            default: null,
        },
    },
    created() {
        // this.mixinSiteConfigCheck();
    },
    mounted(){
    },
    beforeDestroy() {
	},
    methods: {
        scrollTop(){
            document.documentElement.scrollTop = 0;
        },
        scrollBottom(){
            this.$emit('scrollBottom');
        },
        async moveWebtoonPage(){
                this.checkEpisodeState(this.initWebtoonInfo.ctWebtoonIdx, this.wantPage);
        },
        async endPrevEpisode(){
            if(this.initEpisodeOptimum.min !== null){
                this.checkEpisodeState(this.initWebtoonInfo.ctWebtoonIdx, this.initEpisodeOptimum.min);
            }
        },
        async prevEpisode(){
            if(this.initPreEpisodeIdx !== null){
                this.checkEpisodeState(this.initWebtoonInfo.ctWebtoonIdx, this.initPreEpisodeIdx.idx);
            }
        },
        async nextEpisode(){
            if(this.initNextEpisodeIdx !== null){
                this.checkEpisodeState(this.initWebtoonInfo.ctWebtoonIdx, this.initNextEpisodeIdx.idx)
            }
        },
        async endNextEpisode(){
            if(this.initEpisodeOptimum.max !== null){
                this.checkEpisodeState(this.initWebtoonInfo.ctWebtoonIdx, this.initEpisodeOptimum.max);
            }
        },
        async checkEpisodeState(wtIdx, epIdx){
            var param = 
            {
                ticket: this.$store.state.ticket,
                sign: this.$store.state.sign,
                viewAdult: this.$store.state.isCheckedAdult ? 1 : 0,

                webtoonIdx: wtIdx,
                episodeIdx: epIdx,
                // lang: 'KR'
            }
            // console.log(param);
            await this.$Axios.post(`${this.league_host}/challenge/episode/state`, param, { headers: { 'Pragma' : 'no-cache', 'Cache-Control': 'no-cache', 'Access-Control-Allow-Origin': '*', 'Content-type': 'application/json', }}).then(async(res) => {
                // console.log('----episode state----');
                // console.log(res);

                if(res.data.epData != null){
                    this.chagneEpisodeInfo = res.data.epData;
                }

                if (res.data.returnCode === 0) {
                    this.$router.push('/League/Webtoon/Viewer/' + wtIdx + '/' + epIdx);
                }
                else if(res.data.returnCode === 503){   //로그인 필요
                    this.isShowLoginPopup = true;
                }
                // else{
                //     console.log(res.data.dsscription);
                // }
            })
            .catch((error) => {
                console.log(error);
            });
        },
        async getEpisodeCount(){
            var param =
            {
                webtoonidx: this.initWebtoonInfo.ctWebtoonIdx,
                episodeCountNumber: this.wantPage
            }
            await this.$Axios.post(`${this.league_host}/challenge/episode/cntoi`, param, { headers: { 'Pragma' : 'no-cache', 'Cache-Control': 'no-cache', 'Access-Control-Allow-Origin': '*', 'Content-type': 'application/json', }}).then(async(res) => {
                // console.log(res);
                if(res.data.returnCode === 0){
                    this.episodeCount = res.data;
                    this.checkEpisodeState(this.initWebtoonInfo.ctWebtoonIdx, this.episodeCount.index);
                    // this.$router.push('/League/Webtoon/Viewer/' + this.initWebtoonInfo.ctWebtoonIdx + '/' + this.episodeCount.index);
                }
                else{
                    this.$router.push('/');
                }
            })
            .catch((error) => {
                console.log(error);
            });
        },
        close(popupType){
            switch(popupType){
                case 'Login':
                    this.isShowLoginPopup = false;
                    break;
            }
        },
    },
}
</script>