<template>
    <div class="swapHistory popup" v-if="initIsShowPopup">
        <div class="popupBox">
            <div class="popupSwapHead">
                <p>스왑 내역</p>
                <a class="close" @click="closePopup"><img src="@/assets/images/assist/close.svg" /></a>
            </div>
            <b-row class="name">
                <b-col>날짜</b-col>
                <b-col>보유 페이백</b-col>
                <b-col>스왑한 포인트</b-col>
                <b-col>잔액</b-col>
            </b-row>
            <b-row v-for="(item, idx) in swapList" :key="idx">
                <b-col>{{ item.createDate }}</b-col>
                <b-col>{{ (Math.floor(item.paybackBefore * 10000) / 10000 ) | comma }} P</b-col>
                <b-col>{{ item.amount }} P</b-col>
                <b-col>{{ (Math.floor(item.paybackAfter * 10000) / 10000 ) | comma }} P</b-col>
            </b-row>
            <Pagination :initMaxRecordCount="currentPageSize" :initMaxPageCount="maxPageCount" :initPagingData="initPagingData" @movePaging="movePaging" />
        </div>
    </div>
</template>

<script>
import Pagination from '@/components/common/Pagination.vue'

export default {
    name: 'PopupSwapHistory',
    components: {
        Pagination
    },
    data(){
        return {
            swapList: [],

            currentPageNo: 1,       //현재 페이지 번호
            currentPageSize: 5,    //한번에 불러올 게시물 수
            maxPageCount:10,        //한 화면에 보여질 페이지 수
            initPagingData: null,
        }
    },
    props: {
        initIsShowPopup: {
            type: Boolean,
            default: false
        },
    },
    created() {
    },
    mounted(){
    },
    watch:{
        initIsShowPopup(){
            if(this.initIsShowPopup){
                this.getAssistSwapList();
            }
        }
    },
    methods: {
        getAssistSwapList(){ // 후원 스왑 내역
            var param =
            {
                ticket: this.$store.state.ticket,
                sign: this.$store.state.sign,
                viewAdult: this.$store.state.isCheckedAdult ? 1 : 0,

                pageNo: this.currentPageNo,
                pageSize: this.currentPageSize,
            }
            this.$Axios.post(`${this.host}/support/swap/list`, param, { headers: { 'Pragma' : 'no-cache', 'Cache-Control': 'no-cache', 'Access-Control-Allow-Origin': '*', 'Content-type': 'application/json', }}).then(res => {
                if (res.data.returnCode === 0) {
                    this.swapList = res.data.list;
                    this.initPagingData = res.data.page;
                }
            })
            .catch((error) => {
                console.log(error);
            });
        },
        movePaging(pageNo){
            this.currentPageNo = pageNo;
            this.getAssistSwapList();
        },
        closePopup(){
            this.$emit('close');
        },
    },
}
</script>