<template>
    <div class="kakao" v-if="initIsShowPopup && isCheckPc">
        <div class="kakao_box">
            <div class="box-qr">
                <img src="@/assets/images/icon_close.svg" alt="close" class="close" @click="closePopup" />
                <div class="title">
                    <img src="@/assets/images/icon/icon_klip.svg" alt="Klip" class="icon-klip" />
                    <p>카카오톡으로 Klip 연결하기</p>
                </div>
                <img :src="QRCodeURL" v-if="QRCodeURL !== null" />
                <p class="time" v-if="remainTime !== '' && QRCodeURL !== null">남은 시간 <span>{{remainTime}}</span></p>
            </div>
            <div class="box-manual">
                <p class="scan_please">QR 코드 리더기, 카카오톡<br>앱을 통해 QR코드를 스캔해주세요.</p>
                <img src="@/assets/images/klip_manual.png" class="klip-manual"/>
                <!-- <b-row>
                    <b-col class="yes_icon">
                        <img src="@/assets/images/klip_manual_1.svg" />
                        <p>카메라 앱 실행</p>
                    </b-col>
                    <b-col class="no_icon_text">
                        <p>OR</p>
                    </b-col>
                    <b-col class="yes_icon">
                        <img src="@/assets/images/klip_manual_2.svg" />
                        <p>카카오톡<br>실행</p>
                    </b-col>
                    <b-col class="no_icon">
                        <img src="@/assets/images/kakao_arrow.svg" />
                    </b-col>
                    <b-col class="yes_icon long">
                        <img src="@/assets/images/klip_manual_3.svg" />
                        <p>상단 검색창<br>클릭</p>
                    </b-col>
                    <b-col class="no_icon">
                        <img src="@/assets/images/kakao_arrow.svg" />
                    </b-col>
                    <b-col class="yes_icon">
                        <img src="@/assets/images/klip_manual_4.svg" />
                        <p>코드 스캔</p>
                    </b-col>
                </b-row> -->
            </div>
        </div>
    </div>
</template>

<script>
import QRCode from 'qrcode'
import { getResult, request } from 'klip-sdk'

export default {
    name: 'PopupKakao',
    data(){
        return {
            isCheckPc: false,
            requestKey: '',
            QRCodeURL: null,
            resultKlaytnAddress: '',
            isException: false,

            distance: 300 * 1000,
            second: 1000,
            minute: 1000 * 60,
            hour: 1000 * 60 * 60,
            remainTime: '5분 0초',
            intervalCountdown: null,

            intervalCheckResult: null
        }
    },
    props: {
        initIsShowPopup: {
            type: Boolean,
            default: false
        },
        initRequestKey: {
            type: String,
            default: ''
        },
        initRequestType: {  //initRequestType = 1:login (prepareAuth), 2:sendToken (prepareToken)
            type: Number,
            default: 0
        },
    },
    watch:{
        initIsShowPopup(){
            var that = this;
            this.isCheckPc = this.isPc();
            if(this.initIsShowPopup){
                if(this.isCheckPc){
                    this.CountDownTimer();
                    this.intervalCountdown = setInterval(()=>{that.CountDownTimer()}, 1000);
                }
                this.checkResult();
            } else {
                this.closePopup();
            }
        },
        async initRequestKey(){
            this.isCheckPc = this.isPc();
            this.requestKey = this.initRequestKey;
            if(this.initRequestKey !== null){
                if(this.isCheckPc){
                    this.QRCodeURL = await this.getQRCode(this.requestKey);
                } 
                else {
                    this.reqRequest();
                }
            } else {
                this.distance = 300 * 1000;
                this.remainTime = '5분 00초';
                this.QRCodeURL = null;
                this.closePopup();
            }
        }
    }, 
    beforeDestroy(){
        if(this.intervalCountdown) clearInterval(this.intervalCountdown);
        if(this.intervalCheckResult) clearInterval(this.intervalCheckResult);
    },
    methods: {
        checkResult(){
            var that = this;
            this.intervalCheckResult = setInterval(async() => {
                const res = await getResult(that.requestKey);
                if (res.status === 'completed'){
                    // console.log(res);
                    if(that.initRequestType === 1){
                        that.resultKlaytnAddress = res.result.klaytn_address;
                    }
                    // console.log(that.requestKey);
                    // console.log(that.resultKlaytnAddress);
                    that.Log('SUCCESS_KLIP_RESULT');
                    that.closePopup();
                } else if(res.status === 'canceled'){
                    that.Log('CANCLED_KLIP_RESULT');
                    that.closePopup();
                }
            }, 1000);
        },
        reqRequest(){
            try{
                request(this.initRequestKey);
            } catch(err){ err;
                this.Log('FAILED_REQUEST');
                this.closePopup();
            }
        },
        async getQRCode(){
            const a2aApiURL = process.env.VUE_APP_KLIP_A2A_API_URL;
            const qrcode = `${a2aApiURL}?request_key=${this.requestKey}`;
            const options = {
                type: 'svg',
                width: 160,
                height: 160,
            }
            return await QRCode.toDataURL(qrcode, options).then(url => {
                return url;
            }).catch(err => {
                console.log(err);
                this.Log('FAILED_CREATE_QRCODE');
                this.closePopup();
                return null;
            })
        },
        CountDownTimer() {
            var _minutes = Math.floor((this.distance % this.hour) / this.minute);
            var _seconds = Math.floor((this.distance % this.minute) / this.second);

            this.remainTime = _minutes.toString();
            this.remainTime += '분 ';
            this.remainTime += _seconds.toString();
            this.remainTime += '초';

            this.distance = this.distance - this.second;

            if (this.distance <= 0) {
                this.closePopup();
                return;
            }
        },
        Log(type = "") {
            // console.log(type);
            this.$emit("onComplete", {
                requestKey:  this.initRequestKey,
                requestType: this.initRequestType,
                type: type,
                klaytnAddress: this.resultKlaytnAddress.toLowerCase()
            });
        },
        closePopup(){
            if(this.intervalCountdown) clearInterval(this.intervalCountdown);
            if(this.intervalCheckResult) clearInterval(this.intervalCheckResult);
            this.$emit('close');
        },
    },
}
</script>