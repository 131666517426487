<template>
    <div class="contact2 wrap">
        <Header :initHeaderType="initHeaderType" />
            <b-container>
                <div class="menu_left_box">
                    <Noticenav :selectedType="NavSelecteType" />
                </div>
                <div class="contact2_box">
                    <div class="title">
                        <h1>1:1 문의하기</h1>
                    </div>
                    <div class="cont_title">
                        <p>문의 제목</p>
                        <input placeholder="문의 제목을 입력해주세요" v-model="title">
                    </div>
                    <div class="cont_contents">
                        <p>문의 내용</p>
                        <div class="text_box">
                        <b-textarea maxlength="300" placeholder="문의 내용을 300자 이내에서 입력해주세요" v-model="message"></b-textarea>
                        <p class="number"><span>{{ message.length }}</span>/300</p>
                        </div>
                    </div>
                    <div class="button">
                    <p class="sub">* 문의 내용이 많을 경우에 답변이 늦어질 수 있습니다. 최대한 빠른 시간내에 답변드리고자 하오니 조금만 시간을 가지고 기다려주시기 바랍니다. 간단한 사이트 이용일 경우 자주하는 질문에 자세한 내용이 안내되어 있으니 확인해주시기 바랍니다.</p>
                    <p class="end" @click="register">작성완료</p>
                    </div>
                </div>
            </b-container>
        <PopupOK :initIsShowPopup="isShowPopupOkPopup" :initPopupOkMessage="initPopupOkMessage" @close="close('PopupOk')" />
        <Footer />
    </div>
</template>

<script>
import Header from '@/components/common/Header.vue'
import Footer from '@/components/common/Footer.vue'
import Noticenav from '@/components/support/Noticenav.vue'
import PopupOK from '@/components/common/PopupOK.vue'

export default {
    name: 'ContactWrite',
    pageCategory: 'Member',
    pageTitle: '1:1 문의하기',
    components: {
        Header,
        Footer,
        Noticenav,
        PopupOK,
    },
    data() {
        return {
            NavSelecteType: 'Contact',

            title: '',
            message: '',

            isShowPopupOkPopup: false,
            initPopupOkMessage: '',
            PopupOkCallbakPage: '',    // 확인 팝업에서 확인 버튼 눌렀을때 이동할 페이지

            initHeaderType: 'type2',
        }
    },
    async created(){
        // await this.mixinSiteConfigCheck();
        await this.mixinLoginCheck();
        if(this.$store.state.loginCheck === false){
            this.initPopupOkMessage = '로그인 후 접근할 수 있습니다.';
            this.PopupOkCallbakPage = '/';
            this.isShowPopupOkPopup = true;
        }
    },
    methods:{
        register(){
            
            if(this.title === ''){
                this.initPopupOkMessage = '제목을 입력해 주세요.';
                this.isShowPopupOkPopup = true;
                return;
            }

            if(this.message === ''){
                this.initPopupOkMessage = '문의 내용을 입력해 주세요.';
                this.isShowPopupOkPopup = true;
                return;
            }

            var param = 
            {
                filename: '',
                title: this.title,
                message: this.message,
                sign: this.$store.state.sign,
                ticket: this.$store.state.ticket,
                viewAdult: this.$store.state.isCheckedAdult ? 1 : 0
            }
            this.$Axios.post(`${this.host}/contractus/register`, param, { headers: { 'Pragma' : 'no-cache', 'Cache-Control': 'no-cache', 'Access-Control-Allow-Origin': '*', 'Content-type': 'application/json', }}).then(async(res) => {
                // console.log(res.data);
                if (res.data.returnCode === 0) {
                    this.initPopupOkMessage = '1:1 문의 등록이 완료 되었습니다.';
                    this.PopupOkCallbakPage = '/Contact';
                    this.isShowPopupOkPopup = true;
                }
                else{
                    this.initPopupOkMessage = '1:1 문의 등록이 실패 했습니다.';
                    this.isShowPopupOkPopup = true;
                }
            })
            .catch((error) => {
                console.log(error);
            });
        },
        close(popupType){
            switch(popupType){
                case 'PopupOk':
                    this.isShowPopupOkPopup = false;
                    if(this.PopupOkCallbakPage !== ''){
                        this.$router.push(this.PopupOkCallbakPage);
                    }
                    this.PopupOkCallbakPage = '';
                    break;
            }
        },
    }
}
</script>