<template>
    <b-col cols="6" sm="3" v-if="initWebtoonInfo !== null" class="webtoon-curation-item" @click="moveDetail">
        <div class="head">
            <img :src="$store.state.siteConfig.cdnUrl + '/KR/title/' + initWebtoonInfo.twebtoonForApi.englishName + '.jpg'" class="webtoon-thumbnail" />
            <img src="@/assets/images/adult_mark.svg" alt="성인 마크" class="mark-adult" v-if="initWebtoonInfo.twebtoonForApi.adult === 1">
        </div>
        <div class="body">
            <p class="webtoon-name">{{initWebtoonInfo.twebtoonForApi.titleName}}</p>
            <p class="webtoon-intro" >{{initWebtoonInfo.twebtoonForApi.desc}}</p>
        </div>
        <div class="emblems">
            <p class="emblem emblem-up" v-if="initWebtoonInfo.twebtoonSymbolForApi !== null && initWebtoonInfo.twebtoonSymbolForApi.up">UP</p>
            <p class="emblem emblem-event" v-if="initWebtoonInfo.twebtoonSymbolForApi !== null && initWebtoonInfo.twebtoonSymbolForApi.event">이벤트</p>
            <p class="emblem emblem-genre">{{initCategoryName}}</p>
            <p class="emblem emblem-new" v-if="initWebtoonInfo.twebtoonSymbolForApi !== null && initWebtoonInfo.twebtoonSymbolForApi.newWebtoon">NEW</p>
            <p class="emblem emblem-hot" v-if="initWebtoonInfo.twebtoonSymbolForApi !== null && initWebtoonInfo.twebtoonSymbolForApi.hot">HOT</p>
        </div>
    </b-col>
</template>
<script>
export default {
    name: 'WebtoonCurationItem',
    data(){
        return {
        }
    },
    props: {
        initWebtoonInfo: {
            type: Object,
            default: null
        },
        initCategoryName:{
            type: String,
            default: ''
        }
    },
    watch: {
        // initWebtoonInfo(){
        //     console.log(this.initWebtoonInfo);
        // }
        // "adult": 0,	 - 성인(1:성인 0:비성인)
        // "complete": 1,	 -  완결(1: 완결 0:미완결)
        // "stopSeries": 0,   - 연재중지여부(1: 연재중지 0:연재중)
        // "hot": null,    	 - hot
        // "up": null,     	 - up
        // "event": null, 	- event
    },
    created() {
    },
    mounted(){
    },
    beforeDestroy() {
	},
    methods: {
        moveDetail(){
            // console.log(this.initWebtoonInfo);
            this.$emit('moveDetail', this.initWebtoonInfo)
        }
    },
}
</script>
