<template>
    <div class="banner-v2" :class="{'no-link' : bannerInfo.linkType === 3}" v-if="$store.state.siteConfig !== null && bannerInfo !== null">
        <img :src="$store.state.siteConfig.cdnUrl + bannerInfo.pcPath + bannerInfo.pcFilename" class="pc" @click="clickBanner"/>
        <img :src="$store.state.siteConfig.cdnUrl + bannerInfo.mpath + bannerInfo.mfilename" class="mobile" @click="clickBanner"/>

        <Login :initIsShowPopup="isShowLoginPopup" @close="close('Login')" />
        <PopupOK :initIsShowPopup="isShowPopupOkPopup" :initPopupOkMessage="initPopupOkMessage" @close="close('PopupOk')" />
    </div>
</template>
<script>
import Login from '@/components/common/Login.vue'
import PopupOK from '@/components/common/PopupOK.vue'

export default {
    name: 'Banner',
    components: {
        Login,
        PopupOK,
    },
    data(){
        return {
            bannerInfo: null,

            isShowLoginPopup: false,

            isShowPopupOkPopup: false,
            initPopupOkMessage: '',
            PopupOkCallbakPage: '',    // 확인 팝업에서 확인 버튼 눌렀을때 이동할 페이지
        }
    },
    props: {
        initBannerNumber: {
            type: Number,
            default: -1
        }
    },
    watch: {
        initBannerNumber(){
            if(this.initBannerNumber > -1){
                this.getBanner();
            }
        }
    },
    created() {
    },
    mounted(){
    },
    beforeDestroy() {
	},
    methods: {
        getBanner(){
            var param = 
            {
                ticket: this.$store.state.ticket,
                sign: this.$store.state.sign,
                viewAdult: this.$store.state.isCheckedAdult ? 1 : 0,
                number: this.initBannerNumber
            }
            this.$Axios.post(`${this.host}/bannerintegration/list`, param, { headers: { 'Pragma' : 'no-cache', 'Cache-Control': 'no-cache', 'Access-Control-Allow-Origin': '*', 'Content-type': 'application/json', }}).then(async(res) => {
                // console.log('----배너----' + this.initBannerNumber);
                // console.log(res.data);
                if (res.data.returnCode === 0) {
                    if(res.data.list.length > 0){
                        this.bannerInfo = res.data.list[0];
                    }
                }
            })
            .catch((error) => {
                console.log(error);
            });
        },
        async clickBanner(){
            // console.log(bannerInfo);
            // private Integer newPage;    새로운페이지여부(1:새창 0:현재페이지)
            // private Integer linkType;	링크타입(0:풀URL 1:내부 라우터 2:웹툰)

            var linkUrl = this.bannerInfo.link;
            var linkType = this.bannerInfo.linkType;
            var newPage = this.bannerInfo.newPage;

            if(linkType === 3){  //링크 없는 배너
                return;
            }

            await this.reqBannerClick(this.bannerInfo.idx);      //배너 클릭 카운팅

            if(linkType === 2){     //웹툰 링크
                var webtoonInfo = await this.checkWebtoon(linkUrl);
                if(webtoonInfo.adult === 1){
                    if(this.$store.state.loginCheck === false){
                        this.isShowLoginPopup = true;
                        return;
                    }
                    else if(!this.$store.state.adult){
                        var src = this.$store.state.siteConfig.niceUrl + '?email=' + this.$store.state.email;
                        window.open(src, 'popupChk', 'width=500, height=550, top=100, left=100, fullscreen=no, menubar=no, status=no, toolbar=no, titlebar=no, location=no, scrollbar=no');
                        return;
                    }
                    else{
                        linkUrl = '/Webtoon/Info/' + webtoonInfo.webToonIndex;
                    }
                } else {
                    linkUrl = '/Webtoon/Info/' + webtoonInfo.webToonIndex;
                }
            }
            if(newPage === 0){   //현재 창에서 이동
                if(linkType === 0){  //외부 링크
                    location.href = linkUrl;
                } else {
                    this.$router.push(linkUrl)
                }
            }
            else if(newPage === 1){  //새창으로 열기
                if(linkType !== 0){
                    let route = this.$router.resolve({path: linkUrl});
                    linkUrl = route.href;
                }
                window.open(linkUrl);
            }
        },
        async reqBannerClick(idx){
            var param = 
            {
                index: idx
            }
            return await this.$Axios.post(`${this.host}/click/banner`, param, { headers: { 'Pragma' : 'no-cache', 'Cache-Control': 'no-cache', 'Access-Control-Allow-Origin': '*', 'Content-type': 'application/json', }}).then(async(res) => {
                if(res.data.returnCode === 0){
                    return true;
                }
                else {
                    return false;
                }
                
            })
            .catch((error) => {
                console.log(error);
                return false;
            });
        },
        async checkWebtoon(webtoonIdx){
            var param = 
            {
                idx: webtoonIdx,
                sign: this.$store.state.sign,
                ticket: this.$store.state.ticket,
                viewAdult: this.$store.state.isCheckedAdult ? 1 : 0,
            }
            return await this.$Axios.post(`${this.host}/webtoon`, param, { headers: { 'Pragma' : 'no-cache', 'Cache-Control': 'no-cache', 'Access-Control-Allow-Origin': '*', 'Content-type': 'application/json', }}).then(async(res) => {
                // console.log('--webtoon info--')
                // console.log(res.data);
                if (res.data.returnCode === 0) {
                    return res.data.data;
                }
                else if(res.data.returnCode === 300){
                    this.initPopupOkMessage = '해당 작품은 운영상의 문제에 위반되어 열람 및 구매 할 수 없습니다.';
                    this.PopupOkCallbakPage = '';
                    this.isShowPopupOkPopup = true;
                    return null;
                }
                else if(res.data.returnCode === 301){
                    if(this.$store.state.loginCheck === false){
                        this.isShowLoginPopup = true;
                    }
                    else if(!this.$store.state.adult){
                        var src = this.$store.state.siteConfig.niceUrl + '?email=' + this.$store.state.email;
                        window.open(src, 'popupChk', 'width=500, height=550, top=100, left=100, fullscreen=no, menubar=no, status=no, toolbar=no, titlebar=no, location=no, scrollbar=no');
                    }
                    return null;
                }
                else {
                    return null;
                }
                
            })
            .catch((error) => {
                console.log(error);
                return null;
            });
        },
        moveDetail(item){
            if(item.twebtoonForApi.adult === 1){
                if(this.$store.state.loginCheck === false){
                    this.isShowLoginPopup = true;
                }
                else if(!this.$store.state.adult){
                    var src = this.$store.state.siteConfig.niceUrl + '?email=' + this.$store.state.email;
                    window.open(src, 'popupChk', 'width=500, height=550, top=100, left=100, fullscreen=no, menubar=no, status=no, toolbar=no, titlebar=no, location=no, scrollbar=no');
                }
                else{
                    // this.$router.push('/Webtoon/Info/' + item.englishName);
                    this.$router.push('/Webtoon/Info/' + item.twebtoonForApi.webToonIndex);
                }
            }else{
                // this.$router.push('/Webtoon/Info/' + item.englishName);
                this.$router.push('/Webtoon/Info/' + item.twebtoonForApi.webToonIndex);
            }
        },
        close(popupType){
            switch(popupType){
                case 'Login':
                    this.isShowLoginPopup = false;
                    break;
                case 'PopupOk':
                    this.isShowPopupOkPopup = false;
                    if(this.PopupOkCallbakPage === 'reload'){
                        location.reload();
                    }
                    else if(this.PopupOkCallbakPage !== ''){
                        this.$router.push(this.PopupOkCallbakPage);
                    }
                    this.PopupOkCallbakPage = '';
                    break;
            }
        },
    },
}
</script>
