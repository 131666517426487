<template>
    <div class="privacy">
        <div class="terms_box">
            <div class="title">
                <h1>아튜브 개인정보 처리방침</h1>
            </div>
            <div class="article_box">
                <div class="article">
                    <p>㈜넘버스(이하 ‘’넘버스’ 또는 ‘회사’)는 서비스 기획부터 종료까지 개인정보보호법 및 관련 법령을 철저히 준수하며, 이에 따라 개인정보 보호를 위해서 다음과 같이 개인정보처리방침을 공개합니다.</p>
                </div>
                <div class="article">
                    <h2>1. 개인정보의 수집 및 이용</h2>
                    <table class="no1">
                        <th>분류</th>
                        <th>수집 시점</th>
                        <th>수집 항목</th>
                        <th>수집 목적</th>
                        <th>보유 및 이용기간</th>
                        <tr>
                            <td rowspan="4" class="classification">필수</td>
                            <td class="view">회원가입</td>
                            <td>이메일, 비밀번호</td>
                            <td>회원제 서비스 제공</td>
                            <td rowspan="7">목적 달성 후 바로 파기 단, 관련 법령에서 별도의 보관기간을 정하는 경우 해당 시점까지 보관</td>
                        </tr>
                        <tr>
                            <td class="view">본인인증</td>
                            <td>휴대폰 번호, 비밀번호, 성별, CI</td>
                            <td>성인(본인) 인증</td>
                        </tr>
                        <tr>
                            <td class="view">아트포인트 결제</td>
                            <td>결제 금액, 결제 일시, 결제수단, 통화, 결제 IP, 코인 전송 금액, 코 전송 일시, 코인 전송 시 시세</td>
                            <td>결제 이력 관리</td>
                        </tr>
                        <tr>
                            <td class="view">컨텐츠 이용</td>
                            <td>콘텐츠 구매 일시, 금액, 구매한 콘텐츠</td>
                            <td>텐츠 이용 내역 관리</td>
                        </tr>
                        <tr>
                            <td rowspan="2" class="classification">선택</td>
                            <td class="view">고객 센터 이용</td>
                            <td>계정 분실 문의 : 신분증 사본 및 내용 결제 및 환불 문의 : 결제정보, 결제수단, 핸드폰번호</td>
                            <td>고객 서비스 지원</td>
                        </tr>
                        <tr>
                            <td class="view">작가 등록 시</td>
                            <td>작가명</td>
                            <td>작가 등록 및 공개</td>
                        </tr>
                        <tr>
                            <td class="classification">기타</td>
                            <td class="view">서비스 이용</td>
                            <td>쿠키, 서비스 이용 기록, IP 주소, 브라우저 종류, 모바일 기기정보</td>
                            <td>소비자보호를 위한 법적 의무</td>
                        </tr>
                    </table>
                </div>
                <div class="article">
                    <h2>2. 개인정보의 3자 제공</h2>
                    <p>아튜브는 이용자 동의 없이 개인정보를 외부에 제공하지 않습니다. 단, 이용자가 외부 제휴사의 서비스를 이용하기 위해서 사전에 동의한 경우, 관련 법령에 따라 개인정보 제공 의무가 발생한 경우에 한하여 개인정보를 제공하고 있습니다.</p>
                    <table class="no_give">
                        <th>제공받는 자</th>
                        <th>제공 목적</th>
                        <th>제공 항목</th>
                        <th>보유 및 이용기간</th>
                        <tr>
                            <td>없음.</td>
                            <td>-</td>
                            <td>-</td>
                            <td>-</td>
                        </tr>
                    </table>
                </div>
                <div class="article">
                    <h2>3. 개인정보의 처리 위탁</h2>
                    <table class="consignment">
                        <th>수탁자</th>
                        <th>위탁업무</th>
                        <th>보유 및 이용기간</th>
                        <tr>
                            <td>(주) 피앤링크</td>
                            <td>결제 처리 및 요금 정산을 위한 결제 대행, 간편 결제 서비스</td>
                            <td rowspan="2">수탁 업체에서 보유하고 있는 정보로 아튜브에서는 별도 저장하지 않음</td>
                        </tr>
                        <tr>
                            <td>(주)나이스평가정보</td>
                            <td>본인 및 성인인증 서비스</td>
                        </tr>
                    </table>
                </div>
                <div class="article">
                    <h2>4. 개인정보의 파기</h2>
                    <p>(1) 회사는 원칙적으로 이용자의 개인정보를 회원 탈퇴 시 바로 파기하고 있습니다. 단, 이용자에게 개인정보 보관기간에 대해 별도의 동의를 얻은 경우, 또는 법령에서 일정 기간 정보 보관 의무를 부과하는 경우에는 해당 기간 동안 개인정보를 안전하게 보관합니다.</p>
                    <p>(2) 회사는 ‘1년간 서비스를 이용하지 않은 회원의 개인정보를 별도로 분리 보관하여 관리하고 있습니다.</p>
                    <p>(3) 회원 탈퇴, 서비스 종료, 이용자에게 동의받은 개인정보 보유기간의 도래와 같이 개인정보의 수집 및 이용목적이 달성된 개인정보는 재생이 불가능한 방법으로 파기하고 있습니다. 법령에서 보존의무를 부과한 정보에 대해서도 해당 기간 경과 후 바로 재생이 불가능한 방법으로 파기합니다.</p>
                    <p>전자적 파일 형태의 경우 복구 및 재생이 되지 않도록 기술적인 방법을 이용하여 안전하게 삭제하며, 출력물 등은 분쇄하거나 소각하는 방식 등으로 파기합니다.</p>
                </div>
                <div class="article">
                    <h2>5. 이용자 및 법정대리인의 권리와 행사방법</h2>
                    <p>(1) 이용자는 언제든지 회사에 자신의 개인정보를 조회하거나 수정 요청할 수 있습니다.</p>
                    <p class="middot"><span>&middot;</span> 회사가 가지고 있는 이용자의 개인정보</p>
                    <p class="middot"><span>&middot;</span> 회사가 이용자의 개인정보를 이용하거나 제3자에게 제공한 현황</p>
                    <p class="middot"><span>&middot;</span> 회사에게 개인정보 수집·이용·제공 등의 동의를 한 현황</p>
                    <p class="middot_space">개인정보 조회, 수정은 ‘메뉴 > 내정보 > 계정관리’ 또는 개인정보보호 책임자 및 담당자 이메일로 요청하여 처리 가능합니다. 이를 위하여 본인확인 등의 절차가 진행될 수 있습니다.</p>
                    <p>(2) 이용자는 언제든지 ‘회원 탈퇴’ 등을 통해 개인정보의 수집 및 이용 동의를 철회할 수 있습니다.</p>
                    <p>개인정보 수집, 이용 동의 메뉴 > 내정보 > 계정탈퇴 또는 개인정보보호 책임자 및 담당자 이메일로 요청하여 처리 가능합니다. 이를 위하여 본인확인 등의 절차가 진행될 수 있습니다.</p>
                    <p>(3) 이용자가 개인정보의 오류에 대한 정정을 요청한 경우, 정정을 완료하기 전까지 해당 개인정보를 이용 또는 제공하지 않습니다. 또한 잘못된 개인정보를 제3자에게 이미 제공한 경우에는 정정 처리결과를 제3자에게 바로 통지하여 정정이 이루어지도록 하겠습니다.</p>
                    <p>(4) 이용자는 자신의 개인정보를 최신의 상태로 유지해야 하며, 이용자의 부정확한 정보 입력으로 발생하는 문제의 책임은 이용자 자신에게 있습니다.</p>
                    <p>(5) 타인의 개인정보를 도용한 회원가입의 경우 이용자 자격을 상실하거나 관련 개인정보보호 법령에 의해 처벌받을 수 있습니다.</p>
                    <p>(6) 이용자는 전자우편, 비밀번호 등에 대한 보안을 유지할 책임이 있으며 제3자에게 이를 양도하거나 대여할 수 없습니다.</p>
                </div>
                <div class="article">
                    <h2>6. 개인정보보호를 위한 노력</h2>
                    <p>(1) 회사는 이용자의 개인정보를 안전하게 관리하기 위하여 최선을 다하며, 정보통신망법 및 개인정보보호법에서 요구하는 수준 이상으로 개인정보를 보호하고 있습니다.</p>
                    <p>(2) 개인정보를 암호화하고 있습니다. 비밀번호는 일방향 암호화하고 있으며, 카드번호 등을 수집하는 경우 암호화하여 저장합니다.</p>
                    <p>(3) 대내외의 보안 위험으로부터 개인정보를 안전하게 관리합니다. 해킹이나 컴퓨터 바이러스 등에 의해 회원의 개인정보가 유출되거나 훼손되는 것을 막기 위해 외부로부터 접근이 통제된 구역에 시스템을 설치하고 있습니다. 또한, 자료를 수시로 백업하고 있고, 최신 백신 프로그램을 이용하여 이용자들의 개인정보나 자료가 유출되거나 손상되지 않도록 방지하고 있습니다. 암호화 통신 등을 통하여 네트워크상에서 개인정보를 안전하게 송수신하고 있습니다.</p>
                    <p>(4) 개인정보취급자를 최소한으로 유지하며, 개인정보취급자에 대한 정기 교육, 전사 임직원에 대한 수시 교육 및 캠페인을 통하여 법령 및 내부 방침 등의 준수를 강조하고 있습니다.</p>
                    <p>(5) 개인정보의 보호를 위해 개인정보보호 전담부서를 운영하고 있으며, 개인정보 처리방침의 이행사항 및 담당자의 준수 여부를 확인하여 문제가 발견될 경우 즉시 해결하고 바로 잡을 수 있도록 최선을 다하고 있습니다.</p>
                </div>
                <div class="article">
                    <h2>7. 개인정보 보호책임자 및 담당자 안내</h2>
                    <table class="person">
                        <th>개인정보 보호 책임자</th>
                        <th>개인정보 보호 담당자</th>
                        <tr>
                            <td>이름 : 전종경<br>소속 : 개발팀<br>직위 : CIO<br>전화 : 02-3444-5567<br>이메일 : contact@artube.video</td>
                            <td>이름 : 황진중<br>소속 : 사업실<br>직위 : 팀장<br>전화 : 02-3444-5567<br>이메일 : contact@artube.video</td>
                        </tr>
                    </table>
                    <p>기타 개인정보 침해에 대한 신고나 상담이 필요한 경우에 아래 기관에 문의 가능합니다.</p>
                    <p class="middot"><span>&middot;</span> 개인정보침해신고센터 (privacy.kisa.or.kr / 국번없이 118)</p>
                    <p class="middot"><span>&middot;</span> 대검찰청 사이버수사과 (www.spo.go.kr / 국번없이 1301)</p>
                    <p class="middot"><span>&middot;</span> 경찰청 사이버수사국 (police.go.kr / 국번없이 182)</p>
                </div>
                <div class="article">
                    <h2>8. 본 개인정보처리방침의 적용 범위</h2>
                    <p>본 개인정보처리방침은 회사의 브랜드 중 하나인 ‘아튜브(artube.video)’ 및 관련 제반 서비스에 적용되며, 다른 브랜드로 제공되는 서비스에 대해서는 별개의 개인정보처리방침이 적용될 수 있습니다.</p>
                </div>
                <div class="article last">
                    <h2>9. 개정전 고지 의무</h2>
                    <p>본 개인정보처리방침의 내용 추가, 삭제 및 수정이 있을 경우 개정 최소 7일 전에 ‘공지사항’을 통해 사전 공지를 할 것입니다.</p>
                    <p>다만, 수집하는 개인정보의 항목, 이용목적의 변경 등과 같이 이용자 권리의 중대한 변경이 발생 할 때에는 최소 30일 전에 공지하며, 필요 시 이용자 동의를 다시 받을 수도 있습니다.</p>
                    <p>- 공표 : 2021년 9월 28일</p>
                    <p>- 적용 : 2021년 9월 28일</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Policy',
    data(){
        return {
        }
    },
    created() {
    },
    mounted(){
    },
    beforeDestroy() {
	},
    methods: {
    },
}
</script>