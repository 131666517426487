import Vue from 'vue'
import VueRouter from 'vue-router'

//메인 페이지 /views
import Index from '../views/index.vue'

//공통, 별도 페이지 /views/common
import Category                 from '../views/common/Category.vue'                 //장르 카테고리
import DailyToon                from '../views/common/DailyToon.vue'                //요일별 웹툰
import FAQ                      from '../views/common/Faq.vue'                      //자주하는 질문
import Notice                   from '../views/common/Notice.vue'                   //공지사항
import NoticeDetail             from '../views/common/NoticeDetail.vue'             //공지사항
import Swap                     from '../views/common/Swap.vue'                     //간편 스왑
import Privacy                  from '../views/common/Privacy.vue'                  //개인정보 처리방침
import Policy                   from '../views/common/Policy.vue'                   //서비스 이용약관
// import Alliance                 from '../views/common/Alliance.vue'                 //광고 제휴
// import Allianceend              from '../views/common/Allianceend.vue'              //광고 제휴
// import Alliancequestion         from '../views/common/Alliancequestion.vue'         //광고 제휴
// import Hashtag                  from '../views/common/Hashtag.vue'                  //해시태그 

//이벤트 관련 페이지 /views/event
import Event                     from '../views/event/Event.vue'                     //이벤트 목록
import EventCompetition          from '../views/event/EventCompetition.vue'          //공모전 안내
import EventLeague               from '../views/event/EventLeague.vue'               //리그 이벤트 안내
import EventLeagueResult         from '../views/event/EventLeagueResult.vue'         //리그 이벤트 수상자 
import EventChallengeViral       from '../views/event/EventChallengeViral.vue'       //리그 이벤트 소문내기 안내
import EventFeatured             from '../views/event/EventFeatured.vue'             //이벤트 피쳐드
import EventWinner               from '../views/event/EventWinner.vue'               //공모전수상자 
import EventChallengeViralResult from '../views/event/EventChallengeViralResult.vue' //소문내기당첨자 
import EventLegendEnjoy          from '../views/event/EventLegendEnjoy.vue'          //레전드 액션 기획전 
import EventAirDrop              from '../views/event/EventAirDrop.vue'              //레전드 액션 기획전 

//아튜리그 관련 페이지 /views/league
import LeagueIndex               from '../views/league/LeagueIndex.vue'              //리그 메인
import LeagueWriterSignup        from '../views/league/LeagueWriterSignup.vue'       //리그 작가 등록
import LeagueWriterInfo          from '../views/league/LeagueWriterInfo.vue'         //리그 작가 마이 페이지
import LeagueWriterWebtoon       from '../views/league/LeagueWriterWebtoon.vue'      //리그 작가 웹툰 등록, 수정
import LeagueWriterWebtoonInfo   from '../views/league/LeagueWriterWebtoonInfo.vue'  //리그 작가 웹툰 정보
import LeagueWriterViewer        from '../views/league/LeagueWriterViewer.vue'       //리그 작가 웹툰 보기
import LeagueWriterEpisodeReg    from '../views/league/LeagueWriterEpisodeReg.vue'   //리그 작가 에피소드 등록
import LeagueWriterEpisodeEdit   from '../views/league/LeagueWriterEpisodeEdit.vue'  //리그 작가 에피소드 수정
import LeagueWebtoonInfo         from '../views/league/LeagueWebtoonInfo.vue'        //리그 웹툰 정보(사용자)
import LeagueWebtoonViewer       from '../views/league/LeagueWebtoonViewer.vue'      //리그 웹툰 보기(사용자)

//회원 관련 페이지 /views/member
import MemberInfo                from '../views/member/MemberInfo.vue'               //내정보
import MemberPasswordChagne      from '../views/member/MemberPasswordChagne.vue'     //비밀번호 변경
import MemberLibrary             from '../views/member/MemberLibrary.vue'            //내 서재
import Contact                   from '../views/member/Contact.vue'                  //1:1문의
import ContactWrite              from '../views/member/ContactWrite.vue'             //1:1문의 등록
import PasswordResetReq          from '../views/member/PasswordResetReq.vue'         //비밀번호 재설정(이메일 인증번호 발송)
import PasswordReset             from '../views/member/PasswordReset.vue'            //비밀번호 재설정(비밀번호 변경)
import Signup                    from '../views/member/Signup.vue'                   //회원 가입
import SignupSNS                 from '../views/member/SignupSNS.vue'                //회원 가입(SNS)
import SignupComplete            from '../views/member/SignupComplete.vue'           //회원가입 완료
import OAuthCheck                from '../views/member/OAuthCheck.vue'              //SNS인증 처리
// import Alarm                     from '../views/member/Alarm.vue'                    //알림함

//포인트 관련 페이지 /views/point
import PointCharge               from '../views/point/PointCharge.vue'               //포인트 충전
import PointWithdraw             from '../views/point/PointWithdraw.vue'             //포인트 출금
import PointHistoryUsage         from '../views/point/PointHistoryUsage.vue'         //포인트 사용내역
import PointHistoryCharge        from '../views/point/PointHistoryCharge.vue'        //포인트 충전내역(PG)
import PointHistoryChargeATT     from '../views/point/PointHistoryChargeATT.vue'     //포인트 충전내역(ATT 코인)
import PointHistoryWithdraw      from '../views/point/PointHistoryWithdraw.vue'      //포인트 출금내역

//웹툰 관련 페이지 /views/webtoon
import WebtoonInfo               from '../views/webtoon/WebtoonInfo.vue'             //웹툰 정보
import WebtoonViewer             from '../views/webtoon/WebtoonViewer.vue'           //웹툰 보기

// 후원 관련 페이지 /views/assist
// import Assist                    from '../views/assist/Assist.vue'                   //후원하기 홈
import LibraryAssist             from '../views/assist/LibraryAssist.vue'            //내 서재 후원하기


// 모바일 관련
import M_Login                   from '../views/mobile/Login.vue'                    //로그인
import M_OAuthCheck              from '../views/mobile/OAuthCheck.vue'               //로그인 처리


const routes = [
    //메인 페이지
    {path: '/',                                           name: 'Index',                     component:Index                     },
    {path: '*',                                           name: '404',                       redirect: "/"                       },

    //공통, 별도 페이지 /views/common
    {path: '/Category',                                   name: 'Category',                  component:Category                  },
    {path: '/Category/:idx',                              name: 'CategoryIdx',               component:Category                  },
    {path: '/Category/:idx/:searchText',                  name: 'CategorySearch',            component:Category                  },
    {path: '/DailyToon',                                  name: 'DailyToon',                 component:DailyToon                 },
    {path: '/DailyToon/:day',                             name: 'DailyToonDay',              component:DailyToon                 },
    {path: '/FAQ',                                        name: 'FAQ',                       component:FAQ                       },
    {path: '/Notice',                                     name: 'Notice',                    component:Notice                    },
    {path: '/Notice/:idx',                                name: 'NoticeDetail',              component:NoticeDetail              },
    {path: '/Swap',                                       name: 'Swap',                      component:Swap                      },
    {path: '/Privacy',                                    name: 'Privacy',                   component:Privacy                      },
    {path: '/Policy',                                     name: 'Policy',                    component:Policy                      },

    //이벤트 관련 페이지 /views/event
    {path: '/Event',                                         name: 'Event',                     component:Event                     },
    {path: '/Competition',                                   name: 'EventCompetition',          component:EventCompetition          },
    {path: '/League/Event',                                  name: 'EventLeague',               component:EventLeague               },
    {path: '/Event/Challenge/Viral',                         name: 'EventChallengeViral',       component:EventChallengeViral       },
    {path: '/Event/Featured',                                name: 'EventFeatured',             component:EventFeatured             },
    {path: '/Event/Competition/Result',                      name: 'EventWinner',               component:EventWinner               },
    {path: '/League/Event/Result',                           name: 'EventLeagueResult',         component:EventLeagueResult         },
    {path: '/Event/Challenge/Viral/Result',                  name: 'EventChallengeViralResult', component:EventChallengeViralResult },
    {path: '/Event/EnjoyLegend',                             name: 'EventLegendEnjoy',          component:EventLegendEnjoy          },
    {path: '/Event/AirDrop',                                 name: 'EventAirDrop',              component:EventAirDrop              },

    //아튜리그 관련 페이지 /views/league   
    {path: '/League',                                        name: 'LeagueIndex',               component:LeagueIndex               },
    {path: '/League/Webtoon/Info/:idx',                      name: 'LeagueWebtoonInfo',         component:LeagueWebtoonInfo         },
    {path: '/League/Webtoon/Viewer/:wtidx/:epidx',           name: 'LeagueWebtoonViewer',       component:LeagueWebtoonViewer       },
    {path: '/League/Writer/Signup',                          name: 'LeagueWriterSignup',        component:LeagueWriterSignup        },
    {path: '/League/Writer/Info',                            name: 'LeagueWriterInfo',          component:LeagueWriterInfo          },
    {path: '/League/Writer/Webtoon',                         name: 'LeagueWriterWebtoonReg',    component:LeagueWriterWebtoon       },
    {path: '/League/Writer/Webtoon/:idx',                    name: 'LeagueWriterWebtoonEdit',   component:LeagueWriterWebtoon       },
    {path: '/League/Writer/Webtoon/Info/:idx',               name: 'LeagueWriterWebtoonInfo',   component:LeagueWriterWebtoonInfo   },
    {path: '/League/Writer/Webtoon/Viewer/:wtidx/:epidx',    name: 'LeagueWriterViewer',        component:LeagueWriterViewer        },
    {path: '/League/Writer/Episode/Regist/:idx',             name: 'LeagueWriterEpisodeReg',    component:LeagueWriterEpisodeReg    },
    {path: '/League/Writer/Episode/Edit/:wtidx/:epidx',      name: 'LeagueWriterEpisodeEdit',   component:LeagueWriterEpisodeEdit   },

    //회원 관련 페이지 /views/member   
    {path: '/Member/Info',                                   name: 'MemberInfo',                component:MemberInfo                },
    {path: '/Member/Password/Change',                        name: 'MemberPasswordChagne',      component:MemberPasswordChagne      },
    {path: '/Member/Library',                                name: 'MemberLibrary',             component:MemberLibrary             },
    {path: '/Member/OAuthCheck/:type',                       name: 'MemberOAuthCheckClose',     component:OAuthCheck                },
    {path: '/Member/OAuthCheck/:type/:email',                name: 'MemberOAuthCheckEmail',     component:OAuthCheck                },
    {path: '/Member/OAuthCheck/:type/:ticket/:sign/:social', name: 'MemberOAuthCheck',          component:OAuthCheck                },
    {path: '/Password/Reset',                                name: 'PasswordResetReq',          component:PasswordResetReq          },
    {path: '/Password/Reset/:email/:code',                   name: 'PasswordReset',             component:PasswordReset             },
    {path: '/Signup',                                        name: 'Signup',                    component:Signup                    },
    {path: '/Signup/:ticket/:sign/:social',                  name: 'SignupSNS',                 component:SignupSNS                 },
    {path: '/Signup/Complete',                               name: 'SignupComplete',            component:SignupComplete            },
    {path: '/Contact',                                       name: 'Contact',                   component:Contact                   },
    {path: '/Contact/Write',                                 name: 'ContactWrite',              component:ContactWrite              },

    //포인트 관련 페이지 /views/point        
    {path: '/Point/Charge',                                  name: 'PointCharge',               component:PointCharge               },
    {path: '/Point/Charge/:type',                            name: 'PointChargeType',           component:PointCharge               },
    {path: '/Point/Withdraw',                                name: 'PointWithdraw',             component:PointWithdraw             },
    {path: '/Point/History/Usage',                           name: 'PointHistoryUsage',         component:PointHistoryUsage         },
    {path: '/Point/History/Charge',                          name: 'PointHistoryCharge',        component:PointHistoryCharge        },
    {path: '/Point/History/Charge/ATT',                      name: 'PointHistoryChargeATT',     component:PointHistoryChargeATT     },
    {path: '/Point/History/Withdraw',                        name: 'PointHistoryWithdraw',      component:PointHistoryWithdraw      },

    //웹툰 관련 페이지 /views/webtoon        
    {path: '/Webtoon/Info/:idx',                             name: 'WebtoonInfo',               component:WebtoonInfo               },
    {path: '/Webtoon/Viewer/:wtidx/:epidx',                  name: 'WebtoonViewer',             component:WebtoonViewer             },

    // 후원 관련 페이지 /views/assist    
    // {path: '/Assist',                                        name: 'Assist',                    component:Assist                    },
    {path: '/LibraryAssist',                                 name: 'LibraryAssist',             component:LibraryAssist             },
    {path: '/LibraryAssist/:tabName',                        name: 'LibraryAssistTabName',      component:LibraryAssist             },


    // 모바일 관련 페이지 /views/Mobile
    {path: '/M/Member/Login',                                   name: 'MobileLogin',                    component:M_Login        },
    {path: '/M/Member/OAuthCheck/:type',                        name: 'MobileMemberOAuthCheckClose',    component:M_OAuthCheck   },
    {path: '/M/Member/OAuthCheck/:type/:ticket/:sign/:social',  name: 'MobileMemberOAuthCheck',         component:M_OAuthCheck   },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    // scrollBehavior () {
    //     // page scroll to top for all route navigations
    //     return { x: 0, y: 0 }
    // }
})

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(() => {
        return window.location.reload();
    })
};

Vue.use(VueRouter)

export default router;