<template>
    <div class="changeinfo wrap">
        <Header :initHeaderType="initHeaderType" />
        <div class="changeinfo_box">
            <h1>회원정보 변경</h1>
            <p class="plz">Artube 회원 정보를 변경할 수 있습니다.</p>
            <div class="profile" v-on:click="changeProfilePhoto()">
                <img src="@/assets/images/img_profile_default.svg" alt="profile photo" v-if="$store.state.photo === '' || $store.state.photo === null || $store.state.siteConfig === null" >
                <img :src="$store.state.siteConfig.cdnUrl + '/KR/profilepicture/'+ $store.state.photo" v-else />
                <input type="file" name="file" id="files" class="attatch" ref="files" accept=".gif, .jpg, .png, " v-on:change="handleFileUploads()"/>
            </div>
            <div class="name_box">
                <p class="username">{{email}}</p>
                    <div class="metamask_box">
                        <p>지갑 연결</p>
                        <div class="meta_wallet2">
                            <div class="box-address connected" v-if="$store.state.isEnableKaikas || $store.state.isEnableKlip">
                                <p>
                                    <img src="@/assets/images/icon/icon_kaikas_small_black.svg" class="wallet-icon" v-if="$store.state.isEnableKaikas" />
                                    <img src="@/assets/images/icon/icon_klip_small_blue.svg"    class="wallet-icon" v-else-if="$store.state.isEnableKlip" />
                                    {{$store.state.walletAddress}}
                                </p>
                                <img src="@/assets/images/icon/icon_copy.svg" v-clipboard:copy="$store.state.walletAddress" v-clipboard:success="clipboardSuccessHandler" class="icon-copy" />
                            </div>
                            <div class="box-address" v-else>
                                <p class="desc">지갑을 연결해 주세요</p>
                            </div>
                            <a class="btn-wallet change-wallet" @click="disconnectWallet" v-if="$store.state.isEnableKaikas || $store.state.isEnableKlip">연결해제</a>
                            <a class="btn-wallet connect-wallet" @click="connectWallet" v-else>지갑연결</a>
                        </div>
                    </div>
                <p class="title-nickname">닉네임 변경</p>
                <input placeholder="닉네임을 입력해주세요" type="text" class="nickname" v-model="nickname" autocomplete='off' @input="inputNickname"/>
                <a class="update" @click="changeNickname">변경</a>
                <div class="empty_box">
                    <p class="error"  v-if="isShowErrorNickname">{{errorMsgNickname}}</p>
                    <!-- <p class="success"  v-if="isShowSuccessNickname">닉네임이 변경되었습니다.</p> -->
                </div>
            </div>
            <div class="pass_box">
                <p>비밀번호 변경</p>
                <router-link pag="a" to="/Member/Password/Change" class="btn-changepass">변경 <img src="@/assets/images/icon_arrow_right.svg" /></router-link>
            </div>
            <!-- <div class="pass_box">
                <p>비밀번호 변경</p>
                <input placeholder="현재 비밀번호" type="password" class="pass" v-model="currPassword" autocomplete='off' @input="inputPassword"/>
                <input placeholder="비밀번호(8~32자리)" type="password" class="pass" v-model="password" autocomplete='off' @input="inputPassword"/>
                <input placeholder="비밀번호 재입력" type="password" class="repass" v-model="password_confirm" autocomplete='off' @input="inputPassword"/>
                <div class="empty_box">
                    <p class="error" v-if="isShowErrorPassword">{{errorMsgPassword}}</p>
                    <p class="success"  v-if="isShowSuccessPassword">비밀번호가 변경되었습니다.</p>
                </div>
            </div> -->
            <!-- <div class="language">
                <h2>기본 언어/국가</h2>
                <div class="language_box">
                    <b-radio class="on">한국어/한국</b-radio>
                    <b-radio>中文/中国</b-radio>
                    <b-radio>English/US</b-radio>
                    <p>* 선택하신 언어/국가별로 서비스되는 작품 및 운영<br>방침이 다르게 적용될 수 있습니다.</p>
                </div>
            </div>
            <div class="alarm">
                <h2>알림 설정</h2>
                <div class="alarm_box">
                    <div class="subscribe">
                        <p>구독<span>구독중인 전체 작품 업데이트 알림</span></p>
                        <img src="@/assets/images/btn_toggle_off.svg" alt="on/off">
                    </div>
                    <div class="event_alarm">
                        <p>이벤트 알림<span>무료 공개된 작품 공개 등 이벤트 알림</span></p>
                        <img src="@/assets/images/btn_toggle_on.svg" alt="on/off">
                    </div>
                </div>
            </div> -->
            
            <router-link tag="p" to="/" class="back-home">메인화면으로 돌아가기</router-link>
            <div class="button_box">
                <p>회원 탈퇴하시겠습니까?</p>
                <a class="btn-withdraw" @click="showLeavePopup">탈퇴하기</a>
            </div>
        </div>
        <Secession :initIsShowPopup="isShowLeavePopup" @close="close('Leave')" />
        <!-- <Metamask :initIsShowPopup="isShowMetamaskPopup" @close="close('Metamask')" /> -->
        <PopupWallet :initIsShowPopup="isShowWalletPopup" @close="close('Wallet')" />
        <PopupOK :initIsShowPopup="isShowPopupOkPopup" :initPopupOkMessage="initPopupOkMessage" @close="close('PopupOk')" />
        <Footer />
    </div>
</template>

<script>
import Header from '@/components/common/Header.vue'
import Footer from '@/components/common/Footer.vue'
import Secession from '@/components/member/Secession.vue'
// import Metamask from '@/components/PopupMetamask.vue'
import PopupWallet from '@/components/wallet/PopupWallet.vue'
import PopupOK from '@/components/common/PopupOK.vue'

export default {
    name: 'MemberInfo',
    pageCategory: 'Member',
    pageTitle: '회원정보 변경',
    components: {
        Header,
        Footer,
        Secession,
        PopupWallet,
        PopupOK,
    },
    data(){
        return{
            
            email: '',
            nickname: '',
            currPassword: '',
            password: '',
            password_confirm: '',

            isShowLeavePopup: false,

            isShowErrorNickname: false,
            isShowErrorPassword: false,

            isShowSuccessNickname: false,
            isShowSuccessPassword: false,

            errorMsgNickname: '',
            errorMsgPassword: '',

            isShowMetamaskPopup: false,
            isShowWalletPopup: false,

            isShowPopupOkPopup: false,
            initPopupOkMessage: '',
            PopupOkCallbakPage: '',    // 확인 팝업에서 확인 버튼 눌렀을때 이동할 페이지

            initHeaderType: 'type2',
        }
    },
    async created(){
        // await this.mixinSiteConfigCheck();
        await this.mixinLoginCheck();

        if(!this.$store.state.loginCheck){
            this.initPopupOkMessage = '로그인 후 이용하실 수 있습니다.'
            this.PopupOkCallbakPage = '/';
            this.isShowPopupOkPopup = true;
        }else{
            this.email = this.$store.state.contactEmail;
            this.nickname = this.$store.state.nickName;
        }
    },
    methods:{
        inputNickname(){
            this.isShowErrorNickname = false;
            this.isShowSuccessNickname = false;
        },
        inputPassword(){
            this.isShowErrorPassword = false;
            this.isShowSuccessPassword = false;
        },
        showLeavePopup(){
            this.isShowLeavePopup = true;
        },
        showMetamaskPopup(){
            // this.isShowMetamaskPopup = true;

            if(this.$store.state.isEnableMetamask === false || this.$store.state.erc20Address === null || this.$store.state.erc20Address === ''){
                this.isShowMetamaskPopup = true;
            }
            else if(this.$store.state.erc20Address !== null && this.$store.state.erc20Address !== '' && this.$store.state.erc20Address !== this.$store.state.walletAddress){
                this.initPopupOkMessage = '이미 연결된 지갑 주소와 MetaMask 지갑 주소가 다릅니다.<br/>MetaMask에서 활성화된 지갑을 확인해 주세요.';
                this.isShowPopupOkPopup = true;
            }
        },
        connectWallet(){
            if(!this.$store.state.isEnableKaikas && !this.$store.state.isEnableKlip){
                this.isShowWalletPopup = true;
            }
            else if(this.$store.state.isEnableKaikas && this.$store.state.erc20Address !== null && this.$store.state.erc20Address !== '' && this.$store.state.erc20Address !== this.$store.state.walletAddress){
                // this.isShowWalletPopup = true;
                this.initPopupOkMessage = '이미 연결된 지갑 주소와 Kaikas 지갑 주소가 다릅니다.<br/>Kaikas에서 활성화된 지갑을 확인해 주세요.';
                this.isShowPopupOkPopup = true;
            }
        },
        disconnectWallet(){
            this.$store.commit("disconnectKaikas");
            this.$store.commit("disconnectKlip");
            this.connectWallet();
        },
        changeProfilePhoto(){
            this.$refs.files.click();
        },
        handleFileUploads(){
            var fileMaxSize = 1 * 1024 * 1024;

            let userUploadFile = this.$refs.files.files;
            if(userUploadFile.length <= 0){
                return;
            }

            let file = userUploadFile[0];

            if(file.size > fileMaxSize){
                // alert("파일사이즈 : "+ file.size +", 최대파일사이즈 : 1MB");
                this.initPopupOkMessage = '파일 크기는 최대 1MB까지만 가능합니다.';
                this.isShowPopupOkPopup = true;
                return;
            }
            
            let formData = new FormData();
            formData.append('ticket', this.$store.state.ticket);
            formData.append('sign', this.$store.state.sign);
            formData.append('viewAdult', this.$store.state.isCheckedAdult ? 1 : 0);
            formData.append('file', file);

            this.$Axios.post(`${this.host}/imageupload/profilepicture`, formData, { headers: { 'Content-type': 'multipart/form-data'}}).then(async(res) => {
                // console.log(res);
                if(res.data.returnCode === 0){
                    await this.mixinLoginCheck();
                    this.initPopupOkMessage = '프로필 사진이 변경 되었습니다.';
                    this.isShowPopupOkPopup = true;
                }
                else if(res.data.returnCode === 650){
                    this.initPopupOkMessage = '파일 크기는 최대 1MB까지만 가능합니다.';
                    this.isShowPopupOkPopup = true;
                }
                else if(res.data.returnCode === 651){
                    this.initPopupOkMessage = 'JPG, PNG, GIF 파일만 가능합니다.';
                    this.isShowPopupOkPopup = true;
                }
                else if(res.data.returnCode === 651){
                    this.initPopupOkMessage = '올바르지 않은 파일입니다.';
                    this.isShowPopupOkPopup = true;
                }
                else{
                    this.initPopupOkMessage = '파일 업로드 실패';
                    this.isShowPopupOkPopup = true;
                }
            })

        },

        changeNickname(){

            this.isShowErrorNickname = false;
            this.isShowSuccessNickname = false;

            if(this.nickname === ''){
                this.initPopupOkMessage = '닉네임을 입력해 주세요.'
                this.isShowPopupOkPopup = true;

                this.errorMsgNickname = "닉네임을 입력해 주세요.";
                this.isShowErrorNickname = true;
                return;
            }
            else if(this.nickname === this.$store.state.nickName){
                this.initPopupOkMessage = '현재 닉네임과 동일합니다.'
                this.isShowPopupOkPopup = true;
                
                this.errorMsgNickname = "현재 닉네임과 동일합니다.";
                this.isShowErrorNickname = true;
                return;
            }
            else{
                var param = 
                {
                    nickname: this.nickname,
                    sign: this.$store.state.sign,
                    ticket: this.$store.state.ticket,
                }
                this.$Axios.post(`${this.host}/member/update/info`, param, { headers: { 'Pragma' : 'no-cache', 'Cache-Control': 'no-cache', 'Access-Control-Allow-Origin': '*', 'Content-type': 'application/json', }}).then(async(res) => {
                    // console.log(res.data);
                    if (res.data.returnCode === 0) {
                        // this.isShowSuccessNickname = true;
                        this.initPopupOkMessage = '닉네임이 변경되었습니다.';
                        this.isShowPopupOkPopup = true;

                        this.$store.commit("setProfile", this.host);
                    }
                    else if(res.data.returnCode === 43){
                        this.initPopupOkMessage = '중복된 닉네임 입니다.';
                        this.isShowPopupOkPopup = true;

                        this.errorMsgNickname = '중복된 닉네임 입니다.'
                        this. isShowErrorNickname = true;
                    }
                    else if(res.data.returnCode === 47){
                        this.initPopupOkMessage = '사용할 수 없는 닉네임 입니다.';
                        this.isShowPopupOkPopup = true;

                        this.errorMsgNickname = '사용할 수 없는 닉네임 입니다.'
                        this. isShowErrorNickname = true;
                    }
                    else if(res.data.returnCode === 50){
                        this.initPopupOkMessage = '닉네임에는 공백이 포함될 수 없습니다.';
                        this.isShowPopupOkPopup = true;

                        this.errorMsgNickname = '공백이 포함될 수 없습니다.'
                        this. isShowErrorNickname = true;
                    }
                    else {
                        this.initPopupOkMessage = '닉네임 변경이 실패했습니다.';
                        this.isShowPopupOkPopup = true;

                        // this.errorMsgNickname = res.data.description;
                        // this.isShowErrorNickname = true;
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
            }
        },
        changePassword(){

            this.isShowErrorPassword = false;
            this.isShowSuccessPassword = false;
            
            if(this.currPassword === ''){
                this.initPopupOkMessage = '현재 비밀번호를 입력해 주세요.';
                this.isShowPopupOkPopup = true;

                this.errorMsgPassword = '현재 비밀번호를 입력해 주세요.';
                this.isShowErrorPassword = true;
                return;
            }
            else if(this.password === '' || this.password_confirm === ''){
                this.initPopupOkMessage = '변경할 비밀번호를 입력해 주세요.';
                this.isShowPopupOkPopup = true;

                this.errorMsgPassword = '변경할 비밀번호를 입력해 주세요.';
                this.isShowErrorPassword = true;
                return;
            }
            else if(this.password !== this.password_confirm){
                this.initPopupOkMessage = '변경할 비밀번호가 일치하지 않습니다.';
                this.isShowPopupOkPopup = true;

                this.errorMsgPassword = '변경할 비밀번호가 일치하지 않습니다.';
                this.isShowErrorPassword = true;
                return;
            }

            var param = 
                {
                    sign: this.$store.state.sign,
                    ticket: this.$store.state.ticket,
                    priorPassword: this.currPassword,
                    newPassword: this.password
                }
                this.$Axios.post(`${this.host}/member/update/password`, param, { headers: { 'Pragma' : 'no-cache', 'Cache-Control': 'no-cache', 'Access-Control-Allow-Origin': '*', 'Content-type': 'application/json', }}).then(async(res) => {
                    // console.log(res.data);
                    if (res.data.returnCode === 0) {
                        this.initPopupOkMessage = '비밀번호가 변경되었습니다.';
                        this.isShowPopupOkPopup = true;

                        this.currPassword = '';
                        this.password = '';
                        this.password_confirm = '';
                        // this.isShowSuccessPassword = true;
                    }
                    else if(res.data.returnCode === 92){
                        this.initPopupOkMessage = '현재 비밀번호가 일치하지 않습니다.';
                        this.isShowPopupOkPopup = true;

                        this.errorMsgPassword = '현재 비밀번호가 일치하지 않습니다.';
                        this.isShowErrorPassword = true;
                    }
                    else {
                        this.initPopupOkMessage = '비밀번호 변경이 실패했습니다.';
                        this.isShowPopupOkPopup = true;

                        // this.errorMsgNickname = res.data.description;
                        // this.isShowErrorNickname = true;
                    }
                })
                .catch((error) => {
                    console.log(error);
                });

        },
        close(popupType){
            switch(popupType){
                case 'Leave':
                    this.isShowLeavePopup = false;
                    break;
                case 'Wallet':
                    this.isShowWalletPopup = false;
                    break;
                case 'Metamask':
                    this.isShowMetamaskPopup = false;
                    break;
                case 'PopupOk':
                    this.isShowPopupOkPopup = false;
                    if(this.PopupOkCallbakPage === 'reload'){
                        location.reload();
                    }
                    else if(this.PopupOkCallbakPage !== ''){
                        this.$router.push(this.PopupOkCallbakPage);
                    }
                    this.PopupOkCallbakPage = '';
                    break;
            }
        },
        clipboardSuccessHandler ({ value, event }) {
            value; event;
            this.isShowPopupOkPopup = true;
            this.initPopupOkMessage = '지갑 주소가 복사되었습니다.'
        },

    }
}
</script>