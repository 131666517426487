<template>
    <div class="leagueevent wrap winner upload-winner" >
    <Header :initHeaderType="initHeaderType" />
    <div class="container">
        <img src="../../assets/images/event/upload-event-banner.png" class="winner-cover" >
        <div class="upload-event">
            <div class="upload-notice">
                <img src="../../assets/images/event/upload-event-notice.png" alt="">
                <p class="upload-notice-text1">아튜브 챌린지 오픈 기념으로 진행된 웹툰 업로드 이벤트에 <br>참여해 주신 모든 분들의 성원에 감사드립니다. <br>더욱 좋은 콘텐츠로 보답하는 아튜브가 되겠습니다.</p>
                <p class="upload-notice-text2">당첨되신 분들께는 이메일을 통해<br> 개별 연락을 드릴 예정입니다.</p>
            </div>
            <div class="upload-main">
                <div class="upload-card">
                    <img class="upload-1st-present" src="../../assets/images/event/upload-1st-present.png" alt="">
                    <div class="upload-card-box row">
                        <div class="upload-price col-12">
                            <h2><img class="upload-1st-price" src="../../assets/images/event/upload-price-1st.png" alt=""></h2>
                        </div>
                        <div class="card-inner col-6 col-sm-5">
                            <img src="../../assets/images/event/upload-1st.png" alt="">
                        </div>
                        <div class="card-list col-6 col-sm-7">
                            <h3>강상련</h3>
                            <h4>작가: 절부순목사님</h4>
                            <span>판타지</span>
                            <p class="upload-winner-info">태초의 생물로부터 힘을 받은 영물인 '흉수'와 '신수' . 흉수들이 인간에게 해를 끼치지만 신수들은 묵묵히 방관한다. 그때, 인간에게 애착을 가진 특이한 신수가 나타나는데...</p>
                            <p class="upload-winner-info2">당첨자 이메일 . <br class="upload-430br"> ***ht_nebula@naver.com</p>
                            <router-link tag="a" class="upload-morelink" to="/League/Webtoon/Info/10000057"> 보러가기</router-link>
                            <router-link tag="a" class="upload-morelink-m" to="/League/Webtoon/Info/10000057"></router-link>
                        </div>
                    </div>
                </div>
                    <!-- <img class="mb-upload-present" src="../../assets/images/event/upload-1st-present-m.png" alt=""> -->
                <div class="upload-card upload-card2 ">
                    <img class="upload-2nd-present" src="../../assets/images/event/upload-2nd-present.png" alt="">
                    <div class="upload-card-box row">
                        <div class="upload-price col-12">
                            <h2><img class="upload-2nd-price" src="../../assets/images/event/upload-price-2nd.png" alt=""></h2>
                        </div>
                        <div class="card-inner col-6 col-sm-5">
                            <img src="../../assets/images/event/upload-2nd-1.png" alt="">
                        </div>
                        <div class="card-list col-6 col-sm-7">
                            <h3>내가 그랬어</h3>
                            <h4>작가: 노을</h4>
                            <span>판타지</span>
                            <p class="upload-winner-info">베가성에서 지구로 보내진 외계인 코코. 그녀의 옆 집에 섬세한 인간인 관유가 이사를 오게 된다. 관유가 준 떡을 먹고나서부터 코코는 여러가지 사건에 휘말리게 되는데..</p>
                            <p class="upload-winner-info2">당첨자 이메일 . <br class="upload-430br"> ***eldrawu@naver.com</p>
                            <router-link tag="a" class="upload-morelink" to="/League/Webtoon/Info/10000053">보러가기 ></router-link>
                            <router-link tag="a" class="upload-morelink-m" to="/League/Webtoon/Info/10000053" ></router-link>
                        </div>
                        <div class="upload-empty-space"></div>
                        <div class="card-inner col-6 col-sm-5">
                            <img src="../../assets/images/event/upload-2nd-2.png" alt="">
                        </div>
                        <div class="card-list col-6 col-sm-7">
                            <h3>집으로 가는 길</h3>
                            <h4>작가: 후하다</h4>
                            <span>드라마</span>
                            <p class="upload-winner-info">도시 생활에 지쳐 고향으로 돌아가는 한유아. 행복한 삶을 원하는 유아의 농촌 하경기! 과연 그녀에겐 어떤 파란만장한 일이 일어날까?</p>
                            <p class="upload-winner-info2">당첨자 이메일 . <br class="upload-430br"> ***hada@naver.com</p>
                            <!-- <router-link tag="a" class="upload-morelink" to="/League/Webtoon/Info/10000057"> 보러가기</router-link> -->
                            <router-link tag="a" class="upload-morelink" to="/League/Webtoon/Info/10000051">보러가기 ></router-link>
                            <router-link tag="a" class="upload-morelink-m" to="/League/Webtoon/Info/10000051" ></router-link>
                        </div>
                    </div>
                </div>
                    <!-- <img class="mb-upload-present2" src="../../assets/images/event/upload-2nd-present-m.png" alt=""> -->
                <div class="upload-card upload-card3 ">
                    <img class="upload-3rd-present" src="../../assets/images/event/upload-3rd-present.png" alt="">
                    <div class="upload-card-box row">
                        <div class="upload-price col-12">
                            <h2><img class="upload-3rd-price" src="../../assets/images/event/upload-price-3rd.png" alt=""></h2>
                        </div>
                        <div class="card-inner col-6 col-sm-5">
                            <img src="../../assets/images/event/upload-3rd-1.png" alt="">
                        </div>
                        <div class="card-list col-6 col-sm-7">
                            <h3>농장의 주황머리</h3>
                            <h4>작가: 강아강</h4>
                            <span>일상</span>
                            <p class="upload-winner-info">주근깨와 곱슬머리가 인상깊은 소년의 농장 라이프!</p>
                            <p class="upload-winner-info2">당첨자 이메일 . <br class="upload-430br"> ***99999@naver.com</p>
                            <router-link tag="a" class="upload-morelink" to="/League/Webtoon/Info/10000038">보러가기 ></router-link>
                            <router-link tag="a" class="upload-morelink-m" to="/League/Webtoon/Info/10000038" ></router-link>
                        </div>
                        <div class="upload-empty-space"></div>
                        <div class="card-inner col-6 col-sm-5">
                            <img src="../../assets/images/event/upload-3rd-2.png" alt="">
                        </div>
                        <div class="card-list col-6 col-sm-7">
                            <h3>나는 직장인</h3>
                            <h4>작가: sksmswlrwkddls</h4>
                            <span>일상</span>
                            <p class="upload-winner-info">매일 같은 하루를 반복하는 직장인들의 꽃같은 사회생활을 들려주는 일상 에피소드!</p>
                            <p class="upload-winner-info2">당첨자 이메일 . <br class="upload-430br"> ***ynaisgood@naver.com</p>
                            <router-link tag="a" class="upload-morelink" to="/League/Webtoon/Info/10000036">보러가기 ></router-link>
                            <router-link tag="a" class="upload-morelink-m" to="/League/Webtoon/Info/10000036" ></router-link>
                        </div>
                        <div class="upload-empty-space"></div>
                        <div class="card-inner col-6 col-sm-5">
                            <img src="../../assets/images/event/upload-3rd-3.png" alt="">
                        </div>
                        <div class="card-list col-6 col-sm-7">
                            <h3>바버란 붉은 열기구 도적단</h3>
                            <h4>작가: laffi</h4>
                            <span>액션</span>
                            <p class="upload-winner-info">전쟁 고아인 바버란과 마리는 소매치기를 하며 같이 살았다. 붉은 열기구 도적단의 단장 도노반이 바버란과 마리를 단원으로 품으며 이들은 본격적인 도적 생활을 시작했다. </p>
                            <p class="upload-winner-info2">당첨자 이메일 . <br class="upload-430br"> ***filmkim@gmail.com</p>
                            <router-link tag="a" class="upload-morelink" to="/League/Webtoon/Info/10000060">보러가기 ></router-link>
                            <router-link tag="a" class="upload-morelink-m" to="/League/Webtoon/Info/10000060" ></router-link>
                        </div>
                        <div class="upload-empty-space"></div>
                        <div class="card-inner col-6 col-sm-5">
                            <img src="../../assets/images/event/upload-3rd-4.png" alt="">
                        </div>
                        <div class="card-list col-6 col-sm-7">
                            <h3>평범한 가족입니다</h3>
                            <h4>작가: 울랄라</h4>
                            <span>일상</span>
                            <p class="upload-winner-info">평범한 가족들의 평범한 일상 이야기.</p>
                            <p class="upload-winner-info2">당첨자 이메일 . <br class="upload-430br"> ***yeyoung93@naver.com</p>
                            <router-link tag="a" class="upload-morelink" to="/League/Webtoon/Info/10000039">보러가기 ></router-link>
                            <router-link tag="a" class="upload-morelink-m" to="/League/Webtoon/Info/10000039" ></router-link>
                        </div>
                        <div class="upload-empty-space"></div>
                        <div class="card-inner col-6 col-sm-5">
                            <img src="../../assets/images/event/upload-3rd-5.png" alt="">
                        </div>
                        <div class="card-list col-6 col-sm-7">
                            <h3>소소</h3>
                            <h4>작가: 고양고</h4>
                            <span>일상</span>
                            <p class="upload-winner-info">소중한 강아지들과 함께 하는 소소한 이야기. 나이 많은 와와와 어린 두두의 귀염뽀짝한 일상, 함께 보러 가실까요?</p>
                            <p class="upload-winner-info2">당첨자 이메일 . <br class="upload-430br"> ***9z9@gmail.com</p>
                            <router-link tag="a" class="upload-morelink" to="/League/Webtoon/Info/10000041">보러가기 ></router-link>
                            <router-link tag="a" class="upload-morelink-m" to="/League/Webtoon/Info/10000041" ></router-link>
                        </div>
                        <div class="upload-empty-space"></div>
                        <div class="card-inner col-6 col-sm-5">
                            <img src="../../assets/images/event/upload-3rd-6.png" alt="">
                        </div>
                        <div class="card-list col-6 col-sm-7">
                            <h3>E.C.O</h3>
                            <h4>작가: 비바</h4>
                            <span>판타지</span>
                            <p class="upload-winner-info">지구를 정복하러 온 외계인 GT 대장 일행의 배에 떠밀러 들어간 북극곰. 특수한 총을 쏴 곰과 소통하려하지만 말이 잘 통하지 않자 밖에다가 버릴려고 하는데...</p>
                            <p class="upload-winner-info2">당첨자 이메일 . <br class="upload-430br"> ***@naver.com</p>
                            <router-link tag="a" class="upload-morelink" to="/League/Webtoon/Info/10000058">보러가기 ></router-link>
                            <router-link tag="a" class="upload-morelink-m" to="/League/Webtoon/Info/10000058" ></router-link>
                        </div>
                        <div class="upload-empty-space"></div>
                        <div class="card-inner col-6 col-sm-5">
                            <img src="../../assets/images/event/upload-3rd-7.png" alt="">
                        </div>
                        <div class="card-list col-6 col-sm-7">
                            <h3>할 수 있어</h3>
                            <h4>작가: 고파</h4>
                            <span>일상</span>
                            <p class="upload-winner-info">뭐든 할 수 있는 통통이의 일상 이야기.</p>
                            <p class="upload-winner-info2">당첨자 이메일 . <br class="upload-430br"> ***rhfdl3@naver.com</p>
                            <router-link tag="a" class="upload-morelink" to="/League/Webtoon/Info/10000050">보러가기 ></router-link>
                            <router-link tag="a" class="upload-morelink-m" to="/League/Webtoon/Info/10000050" ></router-link>
                        </div>
                        <div class="upload-empty-space"></div>
                        <div class="card-inner col-6 col-sm-5">
                            <img src="../../assets/images/event/upload-3rd-8.png" alt="">
                        </div>
                        <div class="card-list col-6 col-sm-7">
                            <h3>MBTI툰</h3>
                            <h4>작가: 위즈투</h4>
                            <span>일상</span>
                            <p class="upload-winner-info">사람의 성격을 16가지 유형으로 나누는 mbti에 대한 이야기.</p>
                            <p class="upload-winner-info2">당첨자 이메일 . <br class="upload-430br"> ***s20101@naver.com</p>
                            <router-link tag="a" class="upload-morelink" to="/League/Webtoon/Info/10000061">보러가기 ></router-link>
                            <router-link tag="a" class="upload-morelink-m" to="/League/Webtoon/Info/10000061" ></router-link>
                        </div>
                        <div class="upload-empty-space"></div>
                        <div class="card-inner col-6 col-sm-5">
                            <img src="../../assets/images/event/upload-3rd-9.png" alt="">
                        </div>
                        <div class="card-list col-6 col-sm-7">
                            <h3 class="upload-long-text">사람.저마다.비밀.하나.숨김</h3>
                            <h4>작가: 안경쥐</h4>
                            <span>드라마</span>
                            <p class="upload-winner-info">사람에게는 저마다의 비밀을 가지고 살아간다. 훈훈하게 생긴 소년에겐 비밀이 있는데... 과연 그 비밀은 무엇일까?</p>
                            <p class="upload-winner-info2">당첨자 이메일 . <br class="upload-430br"> ***0731@jr.naver.com</p>
                            <router-link tag="a" class="upload-morelink" to="/League/Webtoon/Info/10000059">보러가기 ></router-link>
                            <router-link tag="a" class="upload-morelink-m" to="/League/Webtoon/Info/10000059" ></router-link>
                        </div>
                        <div class="upload-empty-space"></div>
                        <div class="card-inner col-6 col-sm-5">
                            <img src="../../assets/images/event/upload-3rd-10.png" alt="">
                        </div>
                        <div class="card-list col-6 col-sm-7">
                            <h3>지옥의 출근길</h3>
                            <h4>작가: 쿤쿤2</h4>
                            <span>일상</span>
                            <p class="upload-winner-info">지옥같이 힘든 출근길을 가는 일반 회사원의 이야기.</p>
                            <p class="upload-winner-info2">당첨자 이메일 . <br class="upload-430br"> ***ogus52@naver.com</p>
                            <router-link tag="a" class="upload-morelink" to="/League/Webtoon/Info/10000062">보러가기 ></router-link>
                            <router-link tag="a" class="upload-morelink-m" to="/League/Webtoon/Info/10000062" ></router-link>
                        </div>
                    </div>
                </div>
                    <!-- <img class="mb-upload-present" src="../../assets/images/event/upload-3rd-present-m.png" alt=""> -->
            </div>
        </div>
        <div class="gossip-result-footer upload-footer">
            <div class="result-footer-box">
                <h2>이벤트 참고사항</h2>
                <p>1. 라이징 스타상, 베스트 재능러상 당첨자에 한해 경품 수령을 위한 이름과 연락처, 주소에 대한 정보제공을 요청드릴 예정입니다.</p>
                <p>2. 아튜브 인기상 경품은 당첨자 메일을 통해 개별적으로 지급할 예정입니다. <br>경품은 1회 지급되며, 지급된 경품에 대한 미확인/ 분실/ 유효기간 만료 등의 사유로 재발송 되지 않습니다.  </p>
                <p>3. 개인의 부주의로 인한 잘못된 정보 입력 시 경품 오발송 등은 당사가 책임지지 않습니다.</p>
                <p>4. 당첨자 정보가 수령인의 정보가 다르거나, 개인 정보 수집을 거부할 경우 당첨이 무효될 수 있습니다.</p>
                <p>5. 제세공과금은 아튜브에서 부담하며, 당첨자 발표 후 7일간 연락이 되지 않는 경우 당첨이 취소됩니다.</p>

                <p class="upload-footer-caution">*4월 8일 중으로 메일 발송 예정이며, 스팸 메일함에 수신될 수 있으니 확인 부탁드립니다. <br class="upload-event-footer-br"> 메일이 발송되지 않았을 경우, 아튜브 공식 메일인 contact@artube.video로 연락주시면 바로 조치 취해드릴 수 있도록 하겠습니다.</p>
            </div>
        </div>
    </div>
    <Footer />
    </div>
</template>

<script>
import Footer from '@/components/common/Footer.vue'
import Header from '@/components/common/Header.vue'

export default {
    pageTitle: '이벤트',
    components: { 
        Header,
        Footer 
    },
    created(){
    },
    data(){
        return{
            initHeaderType: 'type2',

            protocol:'',
            host:'',
        }
        

    },
    methods:{
        moveScroll(no){
            var pointY = document.getElementById('section-' + no).offsetTop;
            window.scrollTo(0, pointY);
        }
    },
}
</script>

<style>

</style>