<template>
    <div class="leaguemydetail">
        <Header :initHeaderType="initHeaderType" />
            <div class="detail_top" v-if="$store.state.siteConfig !== null && webtoonInfo !== null">
                <img :src="$store.state.siteConfig.cdnUrl + '/CHALLENGE/cover/' + webtoonInfo.ctCdnFolder + '.jpg'" alt="커버" class="pc">
                <img :src="$store.state.siteConfig.cdnUrl + '/CHALLENGE/mainthumbnail/' + webtoonInfo.ctCdnFolder + '.jpg'" alt="커버" class="mobile">
                <b-container v-if="webtoonInfo !== null">
                    <p class="genre" v-if="categoryList.length > 0">{{getCategoryName(webtoonInfo.category)}}</p>
                    <h1>{{webtoonInfo.ctTitleName}}</h1>
                    <p class="writer">{{webtoonInfo.cwPenName}}</p>
                    <div class="util">
                        <!-- <a class="start_see">첫화 보기</a> -->
                        <!-- <div class="util_right">
                            <a class="subscribe" @click="unsubscribe" v-if="isSubscribe">♥ 구독 중</a>
                            <a class="subscribe" @click="subscribe" v-else>♡ 구독</a>
                        </div> -->
                    </div>
                    <div class="m_story">
                        <div class="info">
                            <img src="@/assets/images/mobile/m_genre.svg" alt="장르">
                            <p>{{getCategoryName(webtoonInfo.category)}}</p>
                            <img src="@/assets/images/hot/eye.svg" alt="본 수">
                            <p>{{webtoonInfo.ctViewTotal | comma}}</p>
                            <img src="@/assets/images/mobile/star_line.svg" alt="star" @click="showGradePopup()">
                            <p v-if="webtoonInfo !== null">{{webtoonInfo.ctStarAvg}}</p>
                        </div>
                        <div class="text_box fold">
                            <p class="story_text" v-if="webtoonInfo !== null" v-html="webtoonInfo.ctDesc"></p>
                        </div>
                    </div>
                </b-container>
            </div>
            <div class="writer_menu_box" v-if="webtoonInfo !== null">
                <b-row class="writer_menu">
                    <b-col lg="2" class="col">
                        <p @click="addEpisode">신규 회차 등록</p>
                    </b-col>
                    <b-col lg="2" class="col" v-if="webtoonInfo.ctState !== 0">
                        <p @click="modifyWebtoon">웹툰 정보 수정</p>
                    </b-col>
                    <b-col lg="2" class="col" v-if="webtoonInfo.ctState !== 0">
                        <p @click="deleteWebtoon">웹툰 작품 삭제</p>
                    </b-col>
                </b-row>
            </div>
            <div class="detail_bottom" v-if="webtoonInfo !== null">
                <b-container>
                    <LeagueFloating />
                    <div class="toon_number clearfix">
                        <div class="left">
                            <div class="story">
                                <p class="title">작품 스토리</p>
                                <p class="cont" v-html="webtoonInfo.ctDesc"></p>
                            </div>
                            <div class="page">
                                <div class="page_head">
                                    <p>총 {{webtoonInfo.epCount}}화</p>
                                    <a @click="toggleSort">정렬변경</a>
                                </div>
                                <div class="page_cont">
                                    <div v-for="(item, index) in episodeList" :key="index" class="page_detail">
                                        <div class="episode-img">
                                            <div class="league_problem blue" v-if="item.ceState === 0">
                                                <div class="league_inner_icon">
                                                    <img src="@/assets/images/league_inspection.svg" />
                                                    <p>검수 중</p>
                                                </div>
                                            </div>
                                            <div class="league_problem red" v-if="item.ceState === 2">
                                                <div class="league_inner_icon">
                                                    <img src="@/assets/images/league_violation.svg" />
                                                    <p>운영 정책 위반</p>
                                                </div>
                                            </div>
                                            <img :src="$store.state.siteConfig.cdnUrl + '/CHALLENGE/thumbnail/'+ webtoonInfo.ctCdnFolder + '/' + item.ceUrl" alt="썸네일" class="ssum" @click="showEpisode(item)" v-if="$store.state.siteConfig !== null">
                                        </div>
                                        <div class="info"  @click="showEpisode(item)">
                                            <p class="episode">{{item.ceEpisodeIdx}}</p>
                                            <p class="name">{{item.ceTitleName}}</p>
                                            <div>
                                                <p class="day">{{item.ceCreateDate.substring(0,10)}}</p>
                                                <p class="round_star">{{item.ceStarAvg}}</p>
                                            </div>
                                            <p class="lookup">조회 {{item.ceViewCount}}</p>
                                        </div>
                                        <div class="retouch_box">
                                            <p class="application" @click="episodeInspect(item)" v-if="item.ceState === 3">등록 신청</p>
                                            <p class="retouch" @click="editEpisode(item)" v-if="item.ceState !== 0">수정</p>
                                            <p class="delete" @click="deleteEpisode(item)" v-if="item.ceState !== 0">삭제</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <Pagination :initMaxRecordCount="currentPageSize" :initMaxPageCount="maxPageCount" :initPagingData="initPagingData" @movePaging="movePaging" />
                        </div>
                        <div class="right">
                            <div class="subs_star">
                                <img src="@/assets/images/star.svg" alt="star"> 
                                <p v-if="webtoonInfo !== null"><span>별점</span>{{webtoonInfo.ctStarAvg.toFixed(1)}}</p>
                            </div>
                            <div class="mobile_see">
                                <a>첫화 보기</a>
                            </div>
                        </div>
                    </div>
                    <div class="enroll_warn">
                        <p>작품등록 주의사항</p>
                        <p>- 정책 위반 시, 원고는 반려되며 해당 회차는 게시되지 않습니다.</p>
                        <p>- 수정 이후 약 2~3일간의 검수 시간이 필요합니다.</p>
                    </div>
                </b-container>
            </div>
        <Login :initIsShowPopup="isShowLoginPopup" @close="close('Login')" />
        <!-- <Subscribe :initIsShowPopup="isShowSubscribePopup" :initWebtoonInfo="webtoonInfo" :initSiteConfig="siteConfig" @close="close('Subscribe')" /> -->
        <PopupOK :initIsShowPopup="isShowPopupOkPopup" :initPopupOkMessage="initPopupOkMessage" @close="close('PopupOk')" />
        <PopupConfirm :initIsShowPopup="isShowPopupConfirmPopup" :initPopupConfrimMessage="initPopupConfrimMessage" @confirmCancel="close('PopupConfirm')" @confirmOk="confirmOk" />
        <Footer />
    </div>
</template>

<script>
import Header from '@/components/common/Header.vue'
import Footer from '@/components/common/Footer.vue'
import Login from '@/components/common/Login.vue'
import PopupOK from '@/components/common/PopupOK.vue'
import PopupConfirm from '@/components/common/PopupConfirm.vue'
import Pagination from '@/components/common/Pagination.vue'
import LeagueFloating from '@/components/league/LeagueFloating.vue'

export default {
    name: 'LeagueWriterWebtoonInfo',
    pageCategory: 'League',
    components: {
        Header,
        Footer,
        Login,
        PopupOK,
        PopupConfirm,
        Pagination,
        LeagueFloating,
    },
    data() {
        return {
            categoryList: [],

            writerInfo: null,

            currWebtoonIdx: 0,
            currWebtoonEngName: '',
            webtoonInfo: null,
            episodeList: [],
            episodeSortType: 0,     //desc : 1, asc : 0
            firstEpisodeInfo: null,
            selectedEpisodieInfo: null,

            starPoint: 0,

            isShowLoginPopup: false,
            isShowAllBuyPopup: false,
            isShowGradePopup: false,
            isShowSubscribePopup: false,

            isShowPopupOkPopup: false,
            initPopupOkMessage: '',
            PopupOkCallbakPage: '',    // 확인 팝업에서 확인 버튼 눌렀을때 이동할 페이지

            isShowPopupConfirmPopup: false,
            initPopupConfrimMessage: '',
            PopupConfirmCallbakPage: '',    // 확인 팝업에서 확인 버튼 눌렀을때 이동할 페이지

            currentPageNo: 1,      //현재 페이지 번호
            currentPageSize: 10,    //한번에 불러올 게시물 수
            maxPageCount:10,        //한 화면에 보여질 페이지 수
            initPagingData: null,

            initHeaderType: 'type1',
        }
    },
    async created(){
        var isPc = this.isPc();
        if(!isPc){
            this.initPopupOkMessage = '모바일 환경에서는 감상만 가능합니다.<br/>작가 활동은 PC 환경에서 이용해주세요.'
            this.PopupOkCallbakPage = '/League';
            this.isShowPopupOkPopup = true;
            return;
        }

        if(this.$route.params.idx){
            this.currWebtoonIdx = Number(this.$route.params.idx);
            // await this.mixinSiteConfigCheck();
            await this.mixinLoginCheck();
            var checkWriter = await this.checkWriter(); //작가 등록 여부 확인
            if(checkWriter === null){   //체크 에러
                this.initPopupOkMessage = '작가 정보 체크 에러'
                this.PopupOkCallbakPage = '/League';
                this.isShowPopupOkPopup = true;
                return;
            }
            else if(!checkWriter){  //작가 등록 안된 상태
                this.initPopupOkMessage = '접근 권한이 없습니다.'
                this.PopupOkCallbakPage = '/League';
                this.isShowPopupOkPopup = true;
                return;
            }
            this.getCategory();
            this.getWebtoonInfo();
            this.getFirstEpisodeInfo();
            this.getEpisodeList();
            // if(this.$store.state.loginCheck){
            //     this.getStarCheck();
            // }
        }
        else{
            this.$router.push('/League/Writer/Info');
        }
    },
    methods: {
        toggleSort(){
            if(this.episodeSortType === 1){
                this.episodeSortType = 0;
            } else {
                this.episodeSortType = 1;
            }
            this.episodeList = [];
            this.currentPageNo = 1;
            this.getEpisodeList();
        },
        getCategory(){ // 카테고리 정보
            var param = 
            {
                // limit : 3
            }
            this.$Axios.post(`${this.host}/infocategory`, param, { headers: { 'Pragma' : 'no-cache', 'Cache-Control': 'no-cache', 'Access-Control-Allow-Origin': '*', 'Content-type': 'application/json', }}).then(res => {
                if (res.data.returnCode === 0) {
                    this.categoryList = res.data.list;
                }
            })
            .catch((error) => {
                console.log(error);
            });
        },
        getCategoryName(category_idx){
            var name = '';
            this.categoryList.forEach(element =>{
                if(category_idx === element.idx){
                    name = element.name;
                }
            })
            return name;
        },
        async checkWriter(){
            var param = 
            {
                sign: this.$store.state.sign,
                ticket: this.$store.state.ticket,
                viewAdult: this.$store.state.isCheckedAdult ? 1: 0
            }
            return await this.$Axios.post(`${this.league_host}/challenge/writer/check`, param, { headers: { 'Pragma' : 'no-cache', 'Cache-Control': 'no-cache', 'Access-Control-Allow-Origin': '*', 'Content-type': 'application/json', }}).then(async(res) => {
                // console.log(res.data.writer);
                if (res.data.returnCode === 0) {    //작가 등록됨
                    this.writerInfo = res.data.writer;
                    return true;
                }
                else if(res.data.returnCode === 41){    //작가 등록 안됨
                    return false;
                }
                else{   //기타 에러
                    return null;
                }
            })
            .catch((error) => {
                console.log(error);
                return null;
            });
        },
        getWebtoonInfo(){
            var param = 
            {
                sign: this.$store.state.sign,
                ticket: this.$store.state.ticket,
                viewAdult: this.$store.state.isCheckedAdult ? 1 : 0,
                wtIdx: this.currWebtoonIdx,
                writerIdx: this.writerInfo.cwWriterIdx,
                wrName: this.writerInfo.cwPenName
            }
            // console.log(param)
            this.$Axios.post(`${this.league_host}/challenge/writer/webtoon`, param, { headers: { 'Pragma' : 'no-cache', 'Cache-Control': 'no-cache', 'Access-Control-Allow-Origin': '*', 'Content-type': 'application/json', }}).then(async(res) => {
                // console.log('--webtoon info--')
                // console.log(res.data);
                if (res.data.returnCode === 0) {
                    this.webtoonInfo = res.data.data;
                }
                else if(res.data.returnCode === 550){
                    this.initPopupOkMessage = '웹툰을 찾을 수 없습니다.';
                    this.PopupOkCallbakPage = '/League';
                    this.isShowPopupOkPopup = true;
                }
                // else if(res.data.returnCode === 300){
                //     this.initPopupOkMessage = '존재하지 않는 웹툰 입니다.';
                //     this.PopupOkCallbakPage = '/';
                //     this.isShowPopupOkPopup = true;
                // }
                // else if(res.data.returnCode === 301){
                //     this.initPopupOkMessage = '성인 인증 후 이용 가능합니다.';
                //     this.PopupOkCallbakPage = '/';
                //     this.isShowPopupOkPopup = true;
                // }
                // else{
                //     this.$router.push('/');
                // }
            })
            .catch((error) => {
                console.log(error);
            });
        },
        getFirstEpisodeInfo(){
            var param = 
            {
                sign: this.$store.state.sign,
                ticket: this.$store.state.ticket,
                viewAdult: this.$store.state.isCheckedAdult ? 1 : 0,
                pageNo: 1,
                pageSize: 1,
                wtIdx: this.currWebtoonIdx,
                writerIdx: this.writerInfo.cwWriterIdx,
            }
            this.$Axios.post(`${this.league_host}/challenge/writer/webtoon/episode/list`, param, { headers: { 'Pragma' : 'no-cache', 'Cache-Control': 'no-cache', 'Access-Control-Allow-Origin': '*', 'Content-type': 'application/json', }}).then(async(res) => {
                // console.log('--webtoon episode first info--')
                // console.log(res.data.list);
                if (res.data.returnCode === 0) {
                    if(res.data.list[0]){
                        this.firstEpisodeInfo = res.data.list[0];
                    }
                }
            })
            .catch((error) => {
                console.log(error);
            });
        },
        getEpisodeList(){
            var param = 
            {
                sign: this.$store.state.sign,
                ticket: this.$store.state.ticket,
                viewAdult: this.$store.state.isCheckedAdult ? 1 : 0,
                pageNo: this.currentPageNo,
                pageSize: this.currentPageSize,
                wtIdx: this.currWebtoonIdx,
                writerIdx: this.writerInfo.cwWriterIdx,
                sortSeq: this.episodeSortType
            }
            this.$Axios.post(`${this.league_host}/challenge/writer/webtoon/episode/list`, param, { headers: { 'Pragma' : 'no-cache', 'Cache-Control': 'no-cache', 'Access-Control-Allow-Origin': '*', 'Content-type': 'application/json', }}).then(async(res) => {
                // console.log('--webtoon episode list--')
                // console.log(res.data.list);
                if (res.data.returnCode === 0) {
                    this.episodeList = res.data.list;
                    this.initPagingData = res.data.page;
                }
                // else if(res.data.returnCode === 300){
                //     this.initPopupOkMessage = '존재하지 않는 웹툰 입니다.';
                //     this.PopupOkCallbakPage = '/';
                //     this.isShowPopupOkPopup = true;
                // }
                // else{
                //     // console.log(5);
                //     this.$router.push('/');
                // }
            })
            .catch((error) => {
                console.log(error);
            });
        },

        addEpisode(){
            this.$router.push('/League/Writer/Episode/Regist/' + this.currWebtoonIdx);
        },
        modifyWebtoon(){
            this.initPopupConfrimMessage = '내용과 데이터가 바뀌었을 시 검수가 완료되기 전까지 해당 작품은 플랫폼에서 비공개 상태가 됩니다.';
            this.PopupConfirmCallbackPage = 'editWebtoon';
            this.isShowPopupConfirmPopup = true;
        },
        deleteWebtoon(){
            this.initPopupConfrimMessage = '해당 웹툰과 회차 전부를 삭제하시겠습니까?<br/>삭제된 작품은 복구되지 않습니다.';
            this.PopupConfirmCallbackPage = 'deleteWebtoon';
            this.isShowPopupConfirmPopup = true;
        },
        editEpisode(item){
            this.selectedEpisodieInfo = item;
            this.initPopupConfrimMessage = '내용과 데이터가 바뀌었을 시 검수가 완료되기 전까지 해당 회차는 플랫폼에서 비공개 상태가 됩니다.<br/>회차를 수정하시겠습니까?';
            this.PopupConfirmCallbackPage = 'editEpisode';
            this.isShowPopupConfirmPopup = true;
        },
        deleteEpisode(item){
            this.selectedEpisodieInfo = item;
            this.initPopupConfrimMessage = ' 해당 회차를 삭제하시겠습니까?<br/>삭제된 작품은 복구되지 않습니다.';
            this.PopupConfirmCallbackPage = 'deleteEpisode';
            this.isShowPopupConfirmPopup = true;
        },
        episodeInspect(item){
            this.selectedEpisodieInfo = item;
            this.initPopupConfrimMessage = '신청하겠습니까?<br/>2~3일의 검수 시간이 소요됩니다.';
            this.PopupConfirmCallbackPage = 'episodeInspect';
            this.isShowPopupConfirmPopup = true;
        },
        close(popupType){
            switch(popupType){
                case 'Login':
                    this.isShowLoginPopup = false;
                    break;
                case 'PopupOk':
                    this.isShowPopupOkPopup = false;
                    if(this.PopupOkCallbakPage === 'reload'){
                        location.reload();
                    }
                    else if(this.PopupOkCallbakPage !== ''){
                        this.$router.push(this.PopupOkCallbakPage);
                    }
                    this.PopupOkCallbakPage = '';
                    break;
                case 'PopupConfirm':
                    this.isShowPopupConfirmPopup = false;
                    break;
            }
        },
        confirmOk(){
            if(this.PopupConfirmCallbackPage === 'deleteWebtoon'){
                this.reqWebtoonDelete();
            }
            else if(this.PopupConfirmCallbackPage === 'deleteEpisode'){
                this.reqEpisodeDelete();
            }
            else if(this.PopupConfirmCallbackPage === 'episodeInspect'){
                this.reqEpisodeInspect();
            }
            else if(this.PopupConfirmCallbackPage === 'editWebtoon'){
                this.$router.push('/League/Writer/Webtoon/' + this.currWebtoonIdx);
            }
             else if(this.PopupConfirmCallbackPage === 'editEpisode'){
                this.$router.push('/League/Writer/Episode/Edit/' + this.currWebtoonIdx + '/' + this.selectedEpisodieInfo.ceEpisodeIdx);
            }
            this.isShowPopupConfirmPopup = false;
        },
        reqWebtoonDelete(){

            var param = 
            {
                sign: this.$store.state.sign,
                ticket: this.$store.state.ticket,
                viewAdult: this.$store.state.isCheckedAdult ? 1 : 0,

                webtoonIdx: this.currWebtoonIdx,
                writerIdx: this.writerInfo.cwWriterIdx,
            }
            this.$Axios.post(`${this.league_host}/challenge/writer/webtoon/del`, param, { headers: { 'Pragma' : 'no-cache', 'Cache-Control': 'no-cache', 'Access-Control-Allow-Origin': '*', 'Content-type': 'application/json', }}).then(async(res) => {
                // console.log('--delete webtoon--')
                // console.log(res.data);
                if (res.data.returnCode === 0) {
                    this.initPopupOkMessage = '작품 삭제 완료'
                    this.PopupOkCallbakPage = '/League/Writer/Info';
                    this.isShowPopupOkPopup = true;
                }
                // else if(res.data.returnCode === 300){
                //     this.initPopupOkMessage = '존재하지 않는 웹툰 입니다.';
                //     this.PopupOkCallbakPage = '/';
                //     this.isShowPopupOkPopup = true;
                // }
                // else{
                //     // console.log(5);
                //     this.$router.push('/');
                // }
            })
            .catch((error) => {
                console.log(error);
            });

            this.isShowPopupConfirmPopup = false;
        },
        reqEpisodeDelete(){
            // console.log(this.selectedEpisodieInfo);
            var param = 
            {
                sign: this.$store.state.sign,
                ticket: this.$store.state.ticket,
                viewAdult: this.$store.state.isCheckedAdult ? 1 : 0,

                wtIdx: this.currWebtoonIdx,
                epIdx: this.selectedEpisodieInfo.ceEpisodeIdx,
                writerIdx: this.writerInfo.cwWriterIdx,
            }
            this.$Axios.post(`${this.league_host}/challenge/writer/webtoon/episode/del`, param, { headers: { 'Pragma' : 'no-cache', 'Cache-Control': 'no-cache', 'Access-Control-Allow-Origin': '*', 'Content-type': 'application/json', }}).then(async(res) => {
                // console.log('--delete episode--')
                // console.log(res.data);
                if (res.data.returnCode === 0) {
                    this.initPopupOkMessage = '회차 삭제 완료'
                    this.PopupOkCallbakPage = 'reload';
                    this.isShowPopupOkPopup = true;
                }
                // else if(res.data.returnCode === 300){
                //     this.initPopupOkMessage = '존재하지 않는 웹툰 입니다.';
                //     this.PopupOkCallbakPage = '/';
                //     this.isShowPopupOkPopup = true;
                // }
                // else{
                //     // console.log(5);
                //     this.$router.push('/');
                // }
            })
            .catch((error) => {
                console.log(error);
            });

            
            this.isShowPopupConfirmPopup = false;
        },
        reqEpisodeInspect(){
            var param = 
            {
                sign: this.$store.state.sign,
                ticket: this.$store.state.ticket,
                viewAdult: this.$store.state.isCheckedAdult ? 1 : 0,

                wtIdx: this.currWebtoonIdx,
                epIdx: this.selectedEpisodieInfo.ceEpisodeIdx,
                writerIdx: this.writerInfo.cwWriterIdx,
            }
            // console.log(param);
            this.$Axios.post(`${this.league_host}/challenge/writer/episode/inspect`, param, { headers: { 'Pragma' : 'no-cache', 'Cache-Control': 'no-cache', 'Access-Control-Allow-Origin': '*', 'Content-type': 'application/json', }}).then(async(res) => {
                // console.log('--episode inspect--')
                // console.log(res.data);
                if (res.data.returnCode === 0) {
                    this.initPopupOkMessage = '검수 신청 완료'
                    this.PopupOkCallbakPage = 'reload';
                    this.isShowPopupOkPopup = true;
                }
                // else if(res.data.returnCode === 300){
                //     this.initPopupOkMessage = '존재하지 않는 웹툰 입니다.';
                //     this.PopupOkCallbakPage = '/';
                //     this.isShowPopupOkPopup = true;
                // }
                // else{
                //     // console.log(5);
                //     this.$router.push('/');
                // }
            })
            .catch((error) => {
                console.log(error);
            });

            
            this.isShowPopupConfirmPopup = false;
        },
        showEpisode(episodeInfo){
            // if(this.$store.state.loginCheck === false){
            //     this.isShowLoginPopup = true;
            //     return;
            // }
            this.$router.push('/League/Writer/Webtoon/Viewer/'+ this.currWebtoonIdx + '/' + episodeInfo.ceEpisodeIdx);
        },
        movePaging(pageNo){
            this.currentPageNo = pageNo;
            this.getEpisodeList();
        },
    }
}
</script>