<template>
<div class="wrap main">
    <Header :initIsShowCategory="initIsShowCategory" @selectCategory="selectCategory" :initHeaderType="initHeaderType" />
    <!-- <Tabs /> -->
    <!-- banner -->
    <div id="featured" v-if="$store.state.siteConfig !== null">
        <VueSlickCarousel v-bind="FeaturedSliderSettings" class="slider" ref="featuredSlider" @beforeChange="featuredSlideChange()" v-if="bannerFeaturedList.length > 0">
            <a v-for="(item, index) in bannerFeaturedList" :key="index" class="slide" @click="clickBanner(item)">
                <img :src="$store.state.siteConfig.cdnUrl + item.pcPath + item.pcFilename" class="pc"/>
                <img :src="$store.state.siteConfig.cdnUrl + item.mpath + item.mfilename" class="mobile"/>
            </a>
        </VueSlickCarousel>
    </div>
    <!-- banner end -->
    <!-- section2 인기 작품-->
    <div id="section2" class="section" v-if="$store.state.siteConfig !== null && hitList !== null">
        <div class="container">
            <h1 class="title">{{hitList.ctTitle}}</h1>
            <h3 class="sub_title">{{hitList.ctDesc}}</h3>
            <div class="best_box">
                <b-row class="up">
                    <b-col cols="4" class="pc hot_cover">
                        <div class="hot" @click="moveDetail(hitList.data[0])">
                            <img src="@/assets/images/adult_mark.svg" alt="성인 마크" class="adult_mark" v-if="hitList.data[0].twebtoonForApi.adult === 1">
                            <img :src="$store.state.siteConfig.cdnUrl + '/KR/title/' + hitList.data[0].twebtoonForApi.englishName + '.jpg'">

                            <div class="top">
                                <p>{{hitList.data[0].twebtoonForApi.titleName}}</p>
                                <div class="hot_mark">HOT</div>
                                <!-- <p class="what">{{getCategoryName(hitList.data[0].category)}}</p> -->
                            </div>
                            <div class="bottom">
                                <p class="desc" v-html="hitList.data[0].twebtoonForApi.desc"></p>
                            </div>
                            <div class="tag-list" v-if="hitList.data[0].hashtag.length > 0">
                                <p class="tag" v-for="(item, index) in hitList.data[0].hashtag" :key="index">{{item.hsName}}</p>
                            </div>
                        </div>
                    </b-col>
                    <b-col cols="12" sm="8" class="last_col">
                        <b-row>
                            <b-col sm="4" v-for="(item, index) in hitList.data" :key="index" :class="[index === 0 ? 'mobile' : '']">
                                <img src="@/assets/images/adult_mark.svg" alt="성인 마크" class="adult_mark" v-if="item.twebtoonForApi.adult === 1">
                                <div class="item" @click="moveDetail(item)">
                                    <div class="top">
                                        <img :src="$store.state.siteConfig.cdnUrl + '/KR/title/' + item.twebtoonForApi.englishName + '.jpg'">
                                    </div>
                                    <div class="bottom">
                                        <div class="top_text">
                                            <p>{{item.twebtoonForApi.titleName}}</p>
                                            <div class="hot_mark" v-if="index === 0">HOT</div>
                                            <div class="up_mark" v-else>UP</div>
                                            <!-- <p class="what">{{getCategoryName(item.twebtoonForApi.category)}}</p> -->
                                        </div>
                                        <div class="bottom_text">
                                            <p class="desc" v-html="item.twebtoonForApi.desc"></p>
                                        </div>
                                    </div>
                                </div>
                            </b-col>
                        </b-row>
                    </b-col>
                </b-row>
            </div>
            <!-- <div class="next" @click="moveSlide('next', 'hit')">
                <img src="@/assets/images/league_new_next.svg" alt="next">
            </div>
            <div class="prev" @click="moveSlide('prev', 'hit')">
                <img src="@/assets/images/league_new_next.svg" alt="next">
            </div> -->
        </div>
    </div>
    <!-- section2 end -->
    <!-- section4 -->
    <div id="section4" class="section curation" v-if="$store.state.siteConfig !== null && challengeList.length > 0">
        <div class="container">
            <div class="title_box">
                <h1 class="title">아튜브챌린지 웹툰</h1>
                <router-link tag="a" to="/League" class="more">아튜브 챌린지<span>></span></router-link>
            </div>
            <h3 class="sub_title">누구나 참여할 수 있는 공간!! 아튜브 챌린지의 작품을 감상하세요</h3>
            <!-- <div class="pagenation">
                <div class="circle" :class="[index === selectedChallengeSlideIdx ? 'check' : '']" v-for="(item, index) in challengeList" :key="index" @click="changeSlideIdx('sale', index)"></div>
            </div> -->
            <div class="row">
                <div v-for="(item, index) in challengeList" :key="index" @click="moveDetail(item, 'challenge')" class="jagpum" :class="[index >= selectedChallengeSlideIdx && index < selectedChallengeSlideIdx + 4 ? 'show' : '' ]">
                    <img src="@/assets/images/adult_mark.svg" alt="성인 마크" class="adult_mark" v-if="item.ctAdult === 1">
                    <img :src="$store.state.siteConfig.cdnUrl + '/CHALLENGE/mainthumbnail/' + item.ctCdnFolder + '.jpg'" v-if="$store.state.siteConfig !== null"/>
                    <div class="middle">
                        <p class="name">{{item.ctTitleName}}</p>
                        <p class="star">{{item.ctStarAvg.toFixed(1)}}</p>
                    </div>
                    <div class="bottom">
                        <p class="all_hwa">총 {{item.epCount !== null ? item.epCount : 0}}화</p>
                        <p class="view_count">{{item.ctViewTotal !== null ? item.ctViewTotal : 0}}회</p>
                    </div>
                </div>
            </div>
            <!-- <div class="next" @click="moveSlide('next', 'sale')">
                <img src="@/assets/images/league_new_next.svg" alt="next">
            </div>
            <div class="prev" @click="moveSlide('prev', 'sale')">
                <img src="@/assets/images/league_new_next.svg" alt="next">
            </div> -->
        </div>
    </div>
    <!-- section4 end -->
    <!-- section1 신규 업데이트 작품 -->
    <div id="section1" class="section curation" v-if="$store.state.siteConfig !== null && newUpdateList !== null">  
        <div class="container">
            <h1 class="title">{{newUpdateList.ctTitle}}</h1>
            <h3 class="sub_title">{{newUpdateList.ctDesc}}</h3>
        </div>
        <div class="book_table">
            <div class="container">
                <div class="row">
                    <div cols="3" sm="3" v-for="(item, index) in newUpdateList.data" :key="index" @click="moveDetail(item)" class="col" :class="[index >= selectedNewUpdateSlideIdx && index < selectedNewUpdateSlideIdx + 4 ? 'show' : '' ]">
                        <p>{{item.twebtoonForApi.titleName}}</p>
                        <img src="@/assets/images/adult_mark.svg" alt="성인 마크" class="adult_mark" v-if="item.twebtoonForApi.adult === 1">
                        <img :src="$store.state.siteConfig.cdnUrl + '/KR/title/'+ item.twebtoonForApi.englishName + '.jpg'">
                    </div>
                </div>
                <div class="next" @click="moveSlide('next', 'newUpdate')" v-if="newUpdateList.data.length > 4">
                    <img src="@/assets/images/league_new_next.svg" alt="next">
                </div>
                <div class="prev" @click="moveSlide('prev', 'newUpdate')" v-if="newUpdateList.data.length > 4">
                    <img src="@/assets/images/league_new_prev.svg" alt="prev">
                </div>
            </div>
        </div>
        <div class="work">
            <div class="container">
                <div cols="3" sm="3" class="work_box" v-for="(item, index) in newUpdateList.data" :key="index" @click="moveDetail(item)">
                    <div class="left">
                        <p>{{item.twebtoonForApi.titleName}}</p>
                        <p v-html="item.twebtoonForApi.desc"></p>
                        <div class="left_inner">
                            <p class="genre_3">{{getCategoryName(item.twebtoonForApi.category)}}</p>
                        </div>
                        <div class="go_see" @click="moveDetail(item)">
                            <p>보러가기</p>
                            <img src="@/assets/images/mobile/icon_veiw.svg" alt="화살표">
                        </div>
                    </div>
                    <div class="right">
                        <img src="@/assets/images/adult_mark.svg" alt="성인 마크" class="adult_mark" v-if="item.twebtoonForApi.adult === 1">
                        <img :src="$store.state.siteConfig.cdnUrl + '/KR/bigtitle/'+ item.twebtoonForApi.englishName + '.jpg'">
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- section1 end -->
    <!-- section3 -->
    <div id="section3" class="section" v-if="$store.state.siteConfig !== null && categoryList.length > 0">
        <div class="container">
            <h1 class="title">장르 카테고리</h1>
            <h3 class="sub_title">다양한 장르의 작품들을 즐겨보세요</h3>
            <b-row cols="6">
                <b-col v-for="(item,index) in categoryList" :key="index" tag="div">
                    <router-link tag="div" :to="'/Category/' + item.idx" class="col">
                        <!-- <img src="@/assets/images/category/all.svg" alt="전체"> -->
                        <p>{{item.name}}</p>
                    </router-link>
                </b-col>
            </b-row>
        </div>
    </div>
    <!-- section3 end -->
    <!-- advertising -->
    <div class="container">
        <Banner :initBannerNumber="bannerNumber1" />
    </div>
    <!-- advertising end -->
    
    <div class="category_menu">
        <div class="container">
            <ul>
                <li v-for="(item, index) in categoryList" :key="index">
                    <router-link tag="a" :to="'/Category/' + item.idx+ '/'">
                        {{item.name}}
                    </router-link>
                </li>
            </ul>
        </div>
    </div>
    <!-- section5 -->
    <div id="section5" class="section curation" v-if="genreHitList.length > 0 && $store.state.siteConfig !== null">
        <div class="container">
            <h1 class="title">장르별 인기 순위</h1>
            <div class="row pc">
                <div class="col show" v-for="(items, index) in genreHitList" :key="index">
                    <ul>
                        <li @click="moveLink('/Category/' + items.category.cgIdx)">
                            <img :src="$store.state.siteConfig.cdnUrl + items.category.cgSmallPath + items.category.cgSmallFilename">
                            <h1>{{items.category.cgName}}</h1>
                            <a class="more">더보기 +</a>
                        </li>
                        <li v-for="(item, sub_index) in items.webtoonList" :key="sub_index" class="genre genre2" @click="moveDetail(item)"> <!-- :class="[sub_index > 0 ? 'genre2' : 'genre1']" -->
                            <p class="number">{{sub_index + 1}}</p>
                            <div class="text">
                                <p class="name">{{item.twebtoonForApi.titleName}}</p>
                                <p class="introduce" v-html="item.twebtoonForApi.desc"></p>
                            </div>
                            <div class="thumnail">
                                <img :src="$store.state.siteConfig.cdnUrl + '/KR/title/' + item.twebtoonForApi.englishName + '.jpg'" class="img-thum">
                                <img src="@/assets/images/adult_mark.svg" alt="성인 마크" class="adult-mark" v-if="item.twebtoonForApi.adult === 1">
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <!-- <div class="next" @click="moveSlide('next', 'genreHit')">
                <img src="@/assets/images/league_new_next.svg" alt="next">
            </div>
            <div class="prev" @click="moveSlide('prev', 'genreHit')">
                <img src="@/assets/images/league_new_next.svg" alt="next">
            </div>
            <div class="pagenation">
                <div class="circle" :class="[index === selectedGenreHitSlideIdx ? 'check' : '']" v-for="(item, index) in genreHitList" :key="index" @click="changeSlideIdx('genreHit', index)"></div>
            </div> -->
            <div class="popular_mobile" v-for="(items, index) in genreHitList" :key="index">
                <h1>{{items.category.cgName}}</h1>
                <div class="row">
                    <div v-for="(item, sub_index) in items.webtoonList" :key="sub_index" class="col show" @click="moveDetail(item)">
                        <img src="@/assets/images/adult_mark.svg" alt="성인 마크" class="adult_mark" v-if="item.twebtoonForApi.adult === 1">
                        <div class="top">
                            <img :src="$store.state.siteConfig.cdnUrl + '/KR/title/' + item.twebtoonForApi.englishName + '.jpg'">
                        </div>
                        <div class="bottom">
                            <p class="name">{{sub_index + 1}}. {{item.twebtoonForApi.titleName}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- section5 end -->
    <!-- advertising2 -->
    <div class="container">
        <Banner :initBannerNumber="bannerNumber2" />
    </div>
    <!-- advertising2 end -->
    <!-- section6 명작 추천-->
    <div id="section6" class="section curation" v-if="$store.state.siteConfig !== null && classicList !== null">
        <div class="container">
            <div class="m_more_box">
                <h1 class="title">{{classicList.ctTitle}}</h1>
            </div>
            <h3 class="sub_title">{{classicList.ctDesc}}</h3>
            
            <b-row cols="5">
                <WebtoonCurationItem v-for="(item, index) in classicList.data" :key="index" :initWebtoonInfo="item" :initCategoryName="getCategoryName(item.twebtoonForApi.category)" @moveDetail="moveDetail" :class="[index >= selectedClassicSlideIdx && index < selectedClassicSlideIdx + 5 ? 'show' : '' ]" />
            </b-row>
            <div class="next" @click="moveSlide('next', 'classic')" v-if="classicList.data.length > 5">
                <img src="@/assets/images/league_new_next.svg" alt="next">
            </div>
            <div class="prev" @click="moveSlide('prev', 'classic')" v-if="classicList.data.length > 5">
                <img src="@/assets/images/league_new_prev.svg" alt="prev">
            </div>
        </div>

        <!-- <div class="row">
                <div v-for="(item, index) in classicList" :key="index" class="col" :class="[index >= selectedClassicSlideIdx && index < selectedClassicSlideIdx + 5 ? 'show' : '' ]"  @click="moveDetail(item)">
                    <img src="@/assets/images/adult_mark.svg" alt="성인 마크" class="adult_mark" v-if="item.adult === 1">
                    <div class="top">
                        <img :src="$store.state.siteConfig.cdnUrl + '/KR/title/' + item.englishName + '.jpg'">
                    </div>
                    <div class="middle">
                        <p class="genre_2">{{getCategoryName(item.twebtoonForApi.category)}}</p>
                    </div>
                    <div class="bottom">
                        <p class="name">{{item.titleName}}</p>
                        <p class="introduce" v-html="item.desc"></p>
                    </div>
                </div>
            </div> -->

    </div>
    <!-- section6 end -->
    <!-- section7 -->
    <div id="section7" class="section" v-if="$store.state.siteConfig !== null && hitGenreList.length > 1">
    <!-- <div id="section7" class="section">  -->
        <div class="container">
            <h1 class="title">인기 장르 소개</h1>
            <h3 class="sub_title">재미로 보는 장르추천!</h3>
            <div class="popular_box">
                <router-link tag="div" :to="'/Category/' + hitGenreList[0].cgIdx" class="main">
                    <div class="top">
                        <img :src="$store.state.siteConfig.cdnUrl + hitGenreList[0].cgBigPath + hitGenreList[0].cgBigFilename">
                    </div>
                    <div class="bottom">
                        <p class="name" v-html="hitGenreList[0].cgName"></p>
                        <p class="intro" v-html="hitGenreList[0].cgDesc"></p>
                        <div class="hash">
                            <p v-if="hitGenreList[0].cgHashtag1Idx !== null && hitGenreList[0].cgHashtag1Idx > 0">{{hitGenreList[0].cgHashtag1Str}}</p>
                            <p v-if="hitGenreList[0].cgHashtag2Idx !== null && hitGenreList[0].cgHashtag2Idx > 0">{{hitGenreList[0].cgHashtag2Str}}</p>
                            <p v-if="hitGenreList[0].cgHashtag3Idx !== null && hitGenreList[0].cgHashtag3Idx > 0">{{hitGenreList[0].cgHashtag3Str}}</p>
                            <p v-if="hitGenreList[0].cgHashtag4Idx !== null && hitGenreList[0].cgHashtag4Idx > 0">{{hitGenreList[0].cgHashtag4Str}}</p>
                            <p v-if="hitGenreList[0].cgHashtag5Idx !== null && hitGenreList[0].cgHashtag5Idx > 0">{{hitGenreList[0].cgHashtag5Str}}</p>
                            <p v-if="hitGenreList[0].cgHashtag6Idx !== null && hitGenreList[0].cgHashtag6Idx > 0">{{hitGenreList[0].cgHashtag6Str}}</p>
                        </div>
                    </div>
                </router-link>
                <div class="sub">
                    <div class="row">
                        <router-link tag="div" :to="'/Category/' + item.cgIdx" v-for="(item, index) in hitGenreList" :key="index" class="col" :class="[index === 0 ? 'mobile' : '']">    
                            <div class="bg">
                                <img :src="$store.state.siteConfig.cdnUrl + item.cgCoverPath + item.cgCoverFilename" class="pc"/>
                                <img :src="$store.state.siteConfig.cdnUrl + item.cgMiddlePath + item.cgMiddleFilename" class="mobile"/>
                            </div>
                            <div class="content">
                                <p class="name" v-html="item.cgName"></p>
                                <p class="intro" v-html="item.cgDesc"></p>
                                <div class="hash">
                                    <p v-if="item.cgHashtag1Idx !== null && item.cgHashtag1Idx > 0">{{item.cgHashtag1Str}}</p>
                                    <p v-if="item.cgHashtag2Idx !== null && item.cgHashtag2Idx > 0">{{item.cgHashtag2Str}}</p>
                                    <p v-if="item.cgHashtag3Idx !== null && item.cgHashtag3Idx > 0">{{item.cgHashtag3Str}}</p>
                                    <p v-if="item.cgHashtag4Idx !== null && item.cgHashtag4Idx > 0">{{item.cgHashtag4Str}}</p>
                                    <p v-if="item.cgHashtag5Idx !== null && item.cgHashtag5Idx > 0">{{item.cgHashtag5Str}}</p>
                                    <p v-if="item.cgHashtag6Idx !== null && item.cgHashtag6Idx > 0">{{item.cgHashtag6Str}}</p>
                                </div>
                            </div>
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- section7 end -->
    <!-- section8 -->
    <div id="section8" class="section" v-if="$store.state.siteConfig !== null && keywordFullList.length > 0">
        <div class="container">
            <h1 class="title">맞춤키워드</h1>
            <h3 class="sub_title">AI 추천으로 나에게 딱 맞는 작품들을 만나보세요!</h3>
            <b-row cols="8" class="menu">
                <!-- <router-link tag="div" to="/Hashtag/0" class="col">#전체</router-link> -->
                <b-col tag="div" v-for="(item, index) in keywordFullList" :key="index" @click="changeKeyword(item.csKeyWord)" :class="{'active' : item.csKeyWord === selectedKeywordTagIdx}">
                    {{item.csKeyWordStr}}
                </b-col>
            </b-row>

            <b-row cols="5" class="list">
                <WebtoonCurationItem v-for="(item, index) in keywordList" :key="index" :initWebtoonInfo="item" :initCategoryName="getCategoryName(item.twebtoonForApi.category)" @moveDetail="moveDetail" :class="[index >= selectedKeywordSlideIdx && index < selectedKeywordSlideIdx + 5 ? 'show' : '' ]" />
            </b-row>
            
            <div class="next" @click="moveSlide('next', 'keyword')" v-if="keywordList.length > 5">
                <img src="@/assets/images/league_new_next.svg" alt="next">
            </div>
            <div class="prev" @click="moveSlide('prev', 'keyword')" v-if="keywordList.length > 5">
                <img src="@/assets/images/league_new_prev.svg" alt="prev">
            </div>
        </div>
    </div>
    <!-- section8 end -->
    <!-- section9 -->
    <div id="section9" class="section curation" v-if="waitFreeList.length > 0 && $store.state.siteConfig !== null">
        <div class="container">
            <div class="m_more_box">
                <h1 class="title">기다리면 무료</h1>
                <!-- <router-link tag="a" to="/" class="more">더보기 +</router-link> -->
            </div>
            <h3 class="sub_title">매일 한편씩 무료로 즐겨보세요!</h3>
            <!-- <div class="pagenation">
                <div class="circle" :class="[index === selectedWaitFreeSlideIdx ? 'check' : '']" v-for="(item, index) in waitFreeList" :key="index" @click="changeSlideIdx('waitFree', index)"></div>
            </div> -->
            <div class="row">
                <div v-for="(item, index) in waitFreeList" :key="index" class="col" :class="[index >= selectedWaitFreeSlideIdx && index < selectedWaitFreeSlideIdx + 5 ? 'show' : '' ]" @click="moveDetail(item)">
                    <div class="top">
                        <img :src="$store.state.siteConfig.cdnUrl + '/KR/title/' + item.englishName + '.jpg'"/>
                    </div>
                    <div class="middle">
                        <p class="genre_2">{{getCategoryName(item.twebtoonForApi.category)}}</p>
                        <!-- <p class="up_check">UP</p>
                        <p class="event">이벤트</p> -->
                    </div>
                    <div class="bottom">
                        <p class="name">{{item.titleName}}</p>
                        <p class="introduce" v-html="item.desc"></p>
                    </div>
                </div>
            </div>
            <div class="next" @click="moveSlide('next', 'waitFree')">
                <img src="@/assets/images/league_new_next.svg" alt="next">
            </div>
            <div class="prev" @click="moveSlide('prev', 'waitFree')">
                <img src="@/assets/images/league_new_prev.svg" alt="prev">
            </div>
        </div>
    </div>
    <!-- section9 end -->
    <!-- section10 -->
    <div id="section10" class="section curation" v-if="$store.state.siteConfig !== null && bestCompleteList !== null">
        <div class="container">

            <div class="m_more_box">
                <h1 class="title">베스트 완결작</h1>
                <!-- <router-link tag="a" to="/" class="more">더보기 +</router-link> -->
            </div>
            <h3 class="sub_title">아튜브 추천 베스트 완결작 모음</h3>
            <!-- <div class="pagenation">
                <div class="circle" :class="[index === selectedBestCompleteSlideIdx ? 'check' : '']" v-for="(item, index) in bestCompleteList" :key="index" @click="changeSlideIdx('bestComplete', index)"></div>
            </div> -->
            <b-row cols="5">
                <WebtoonCurationItem v-for="(item, index) in bestCompleteList.data" :key="index" :initWebtoonInfo="item" :initCategoryName="getCategoryName(item.twebtoonForApi.category)" @moveDetail="moveDetail" :class="[index >= selectedBestCompleteSlideIdx && index < selectedBestCompleteSlideIdx + 5 ? 'show' : '' ]" />
            </b-row>
            
            <!-- <div class="row">
                <div v-for="(item, index) in bestCompleteList" :key="index" class="col" :class="[index >= selectedBestCompleteSlideIdx && index < selectedBestCompleteSlideIdx + 5 ? 'show' : '' ]" @click="moveDetail(item)">
                    <img src="@/assets/images/adult_mark.svg" alt="성인 마크" class="adult_mark" v-if="item.adult === 1">
                    <div class="top">
                        <img :src="$store.state.siteConfig.cdnUrl + '/KR/title/' + item.englishName + '.jpg'">
                    </div>
                    <div class="middle">
                        <p class="genre_2">{{getCategoryName(item.twebtoonForApi.category)}}</p>
                    </div>
                    <div class="bottom">
                        <p class="name">{{item.titleName}}</p>
                        <p class="introduce" v-html="item.desc"></p>
                    </div>
                </div>
            </div> -->
            <div class="next" @click="moveSlide('next', 'bestComplete')" v-if="bestCompleteList.data.length > 5">
                <img src="@/assets/images/league_new_next.svg" alt="next">
            </div>
            <div class="prev" @click="moveSlide('prev', 'bestComplete')" v-if="bestCompleteList.data.length > 5">
                <img src="@/assets/images/league_new_prev.svg" alt="prev">
            </div>
        </div>
    </div>
    <!-- section10 end -->
    <!-- advertising3 -->
    <div class="container">
        <Banner :initBannerNumber="bannerNumber3" />
    </div>
    <!-- advertising3 end -->

    <div class="section section11" v-if="youtubeList.length > 0">
        <b-container>
            <b-row>
                <b-col cols="12" md="7" class="media_cont">
                    <h1 class="title">영상콘텐츠</h1>
                    <a href="https://www.youtube.com/channel/UC79E7h4jhmIaIAJpFlZ1jPA/videos" target="_blank" class="more">+</a>
                    <b-row cols="3">
                        <b-col class="media" cols="4" md="4" v-for="(item,index) in youtubeList" :key="index" @click="moveLink(item.ytLink, true)">
                            <div class="head">
                                <img :src="'https://img.youtube.com/vi/'+item.ytLinkId+'/mqdefault.jpg'" />
                            </div>
                            <div class="body">
                                <p>{{item.ytName}}</p>
                            </div>
                        </b-col>
                    </b-row>
                </b-col>
                <b-col cols="12" md="5" class="note">
                    <h1 class="title">공지사항</h1>
                    <router-link tag="a" to="/Notice" class="more">+</router-link>
                    <div v-for="(item, index) in noticeList" :key="index" @click="moveLink('/Notice/' + item.idx)">
                        <p class="note_title">{{item.title}}</p>
                        <p class="note_date">{{item.createDate.substring(0,10)}}</p>
                    </div>
                </b-col>
            </b-row>
        </b-container>
    </div>

    <Footer />
    <Login :initIsShowPopup="isShowLoginPopup" @close="close('Login')" />
    <PopupNotice />
    <PopupOK :initIsShowPopup="isShowPopupOkPopup" :initPopupOkMessage="initPopupOkMessage" @close="close('PopupOk')" />
</div>
</template>

<script>

import Header from '@/components/common/Header.vue'
import Footer from '@/components/common/Footer.vue'
// import Tabs from '@/components/Tabs.vue'
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
import Login from '@/components/common/Login.vue'
import PopupNotice from '@/components/PopupNotice.vue'
import WebtoonCurationItem from '@/components/common/WebtoonCurationItem.vue'
import Banner from '@/components/common/Banner.vue'
import PopupOK from '@/components/common/PopupOK.vue'

export default {
    name: 'Home',
    pageCategory: 'Home',
    components: {
        Header,
        Footer,
        VueSlickCarousel,
        Login,
        PopupNotice,
        WebtoonCurationItem,
        Banner,
        PopupOK,
    },
    
    data() {
        return {
            initIsShowCategory: true,   //카테고리 탭 표시 여부

            selectedFeaturedSlideIdx: 0,
            FeaturedSliderSettings:{lazyLoad: "ondemand", dots: true, arrows: true, fade: false, infinite: true, speed: 500, autoplaySpeed: 5000, slidesToShow: 1, slidesToScroll: 1, autoplay: true,},
            
            categoryList: [],
            hashList: [],

            bannerFeaturedList : [],    //키비주얼 슬라이드
            bannerNumber1: -1,
            bannerNumber2: -1,
            bannerNumber3: -1,

            //큐레이션 타입별 전체 데이터 리스트
            newUpdateList    : null,  //0 신규 업데이트 
            hitFeatured      : [],  //1 인기작품 메인 작품
            hitList          : null,  //1 인기작품
            challengeList    : [],  //2 아튜브챌린지 작품
            genreHitList     : [],  //3 장르별인기순위
            classicList      : null,  //4 명작추천
            hitGenreFeatured : [],  //5 인기장르소개 메인 작품
            hitGenreList     : [],  //5 인기장르소개
            keywordFullList  : [],  //6 맞춤키워드 전체 리스트
            keywordList      : [],  //6 맞춤키워드
            waitFreeList     : [],  //7 기다리면무료
            bestCompleteList : null,  //8 베스트완결작
            youtubeList      : [],  //영상 컨텐츠
            noticeList       : [],  //공지사항

            //화면에 표시중인 슬라이드 인덱스 번호
            selectedNewUpdateSlideIdx        : 0, //0 신규 업데이트 
            selectedHitFeaturedSlideIdx      : 0, //1 인기작품 메인 작품
            selectedHitSlideIdx              : 0, //1 인기작품
            selectedChallengeSlideIdx        : 0, //2 아튜브챌린지 작품
            selectedGenreHitSlideIdx         : 2, //3 장르별인기순위
            selectedClassicSlideIdx          : 0, //4 명작추천
            selectedHitGenreFeaturedSlideIdx : 0, //5 인기장르소개 메인 작품
            selectedHitGenreSlideIdx         : 0, //5 인기장르소개
            selectedKeywordSlideIdx          : 0, //6 맞춤키워드
            selectedWaitFreeSlideIdx         : 0, //7 기다리면무료
            selectedBestCompleteSlideIdx     : 0, //8 베스트완결작
                                        
            //화면에 표시중인 항목
            selectedNewUpdateSlideItem        : null, //0 신규 업데이트
            selectedHitFeaturedSlideItem      : null, //1 인기작품 메인 작품
            selectedHitSlideItem              : null, //1 인기작품
            selectedSaleSlideItem             : null, //2 아튜브챌린지 작품
            selectedGenreHitSlideItem         : null, //3 장르별인기순위
            selectedClassicSlideItem          : null, //4 명작추천
            selectedHitGenreFeaturedSlideItem : null, //5 인기장르소개 메인 작품
            selectedHitGenreSlideItem         : null, //5 인기장르소개
            selectedKeywordSlideItem          : null, //6 맞춤키워드
            selectedWaitFreeSlideItem         : null, //7 기다리면무료
            selectedBestCompleteSlideItem     : null, //8 베스트완결작

            //
            selectedKeywordTagIdx: 0,

            isShowLoginPopup: false,

            isShowPopupOkPopup: false,
            initPopupOkMessage: '',
            PopupOkCallbakPage: '',    // 확인 팝업에서 확인 버튼 눌렀을때 이동할 페이지

            initHeaderType: 'type1',
        }
    },
    async created(){
        // await this.mixinSiteConfigCheck();
        await this.mixinLoginCheck();

        this.getCategory();
        // this.getHash();
        
        this.setFeaturedList();

        this.getNewUpdate();        //0 신규 업데이트   //
        this.getHit();              //1 인기작품    //
        this.getChallenge();        //2 아튜브챌린지 작품
        this.getGenreHit();         //3 장르별인기순위
        this.getClassic();          //4 명작추천    //
        this.getHitGenre();         //5 인기장르소개
        this.getKeyword();          //6 맞춤키워드
        // this.getWaitFree();         //7 기다리면무료
        this.getBestComplete();     //8 베스트완결작    //
        this.getYoutubeList();      //영상컨텐츠
        this.getNoticeList();       //공지사항

    },
    mounted(){
        var that = this;
        setTimeout(()=>{
            window.scroll(0,0)
            that.bannerNumber1 = 101;
            that.bannerNumber2 = 102;
            that.bannerNumber3 = 103;
        }, 500) ;
    },
    methods: {
        featuredSlideChange(event, slick) {
            // if(slider_no === 0){
                //console.log('handleBeforeChange', event, slick, currentSlide, nextSlide);
                this.selectedFeaturedSlideIdx = Number(slick);
            // }
        },
        changeSlide(idx){
            this.selectedFeaturedSlideIdx = idx;
            this.$refs.featuredSlider.goTo(idx, true);
        },
        moveSlide(arrow, type){
            switch(type){
                case "newUpdate":   //신규 업데이트
                    if(arrow === 'next' && this.selectedNewUpdateSlideIdx + 4 < this.newUpdateList.data.length){this.selectedNewUpdateSlideIdx += 1;}
                    else if(arrow === 'prev' && this.selectedNewUpdateSlideIdx - 1 >= 0){this.selectedNewUpdateSlideIdx -= 1;}
                    // this.selectedNewUpdateSlideItem = this.newUpdateList[this.selectedNewUpdateSlideIdx];
                    break;
                // case "hit":     //인기 작품
                //     if(arrow === 'next' && this.selectedHitSlideIdx + 1 < this.hitList.data.length){this.selectedHitSlideIdx += 1;}
                //     else if(arrow === 'prev' && this.selectedHitSlideIdx - 1 >= 0){this.selectedHitSlideIdx -= 1;}
                //     // this.selectedHitSlideItem = this.hitList[this.selectedHitSlideIdx];
                //     break;
                case "sale":
                    if(arrow === 'next' && this.selectedChallengeSlideIdx + 5 < this.challengeList.data.length){this.selectedChallengeSlideIdx += 1;}
                    else if(arrow === 'prev' && this.selectedChallengeSlideIdx - 1 >= 0){this.selectedChallengeSlideIdx -= 1;}
                    // this.selectedSaleSlideItem = this.challengeList[this.selectedChallengeSlideIdx];
                    break;
                case "genreHit":
                    if(arrow === 'next' && this.selectedGenreHitSlideIdx + 1 < this.genreHitList.data.length){this.selectedGenreHitSlideIdx += 1;}
                    else if(arrow === 'prev' && this.selectedGenreHitSlideIdx - 1 >= 2){this.selectedGenreHitSlideIdx -= 1;}
                    // this.selectedGenreHitSlideItem = this.genreHitList[this.selectedGenreHitSlideIdx];
                    break;
                case "classic":
                    if(arrow === 'next' && this.selectedClassicSlideIdx + 5 < this.classicList.data.length){this.selectedClassicSlideIdx += 1;}
                    else if(arrow === 'prev' && this.selectedClassicSlideIdx - 1 >= 0){this.selectedClassicSlideIdx -= 1;}
                    // this.selectedClassicSlideItem = this.classicList[this.selectedClassicSlideIdx];
                    break;
                case "hitGenre":
                    if(arrow === 'next' && this.selectedHitGenreSlideIdx + 1 < this.hitGenreList.data.length){this.selectedHitGenreSlideIdx += 1;}
                    else if(arrow === 'prev' && this.selectedHitGenreSlideIdx - 1 >= 0){this.selectedHitGenreSlideIdx -= 1;}
                    // this.selectedHitGenreSlideItem = this.hitGenreList[this.selectedHitGenreSlideIdx];

                    if(arrow === 'next' && this.selectedHitGenreFeaturedSlideIdx + 1 < this.hitGenreFeatured.data.length){this.selectedHitGenreFeaturedSlideIdx += 1;}
                    else if(arrow === 'prev' && this.selectedHitGenreFeaturedSlideIdx - 1 >= 0){this.selectedHitGenreFeaturedSlideIdx -= 1;}
                    // this.selectedHitGenreFeaturedSlideItem = this.hitGenreFeatured[this.selectedHitGenreFeaturedSlideIdx];
                    break;
                case "keyword":
                    if(arrow === 'next' && this.selectedKeywordSlideIdx + 5 < this.keywordList.length){this.selectedKeywordSlideIdx += 1;}
                    else if(arrow === 'prev' && this.selectedKeywordSlideIdx - 1 >= 0){this.selectedKeywordSlideIdx -= 1;}
                    // this.selectedKeywordSlideItem = this.keywordList[this.selectedKeywordSlideIdx];
                    break;
                case "waitFree":
                    if(arrow === 'next' && this.selectedWaitFreeSlideIdx + 5 < this.waitFreeList.data.length){this.selectedWaitFreeSlideIdx += 1;}
                    else if(arrow === 'prev' && this.selectedWaitFreeSlideIdx - 1 >= 0){this.selectedWaitFreeSlideIdx -= 1;}
                    // this.selectedWaitFreeSlideItem = this.waitFreeList[this.selectedWaitFreeSlideIdx];
                    break;
                case "bestComplete":
                    if(arrow === 'next' && this.selectedBestCompleteSlideIdx + 5 < this.bestCompleteList.data.length){this.selectedBestCompleteSlideIdx += 1;}
                    else if(arrow === 'prev' && this.selectedBestCompleteSlideIdx - 1 >= 0){this.selectedBestCompleteSlideIdx -= 1;}
                    // this.selectedBestCompleteSlideItem = this.bestCompleteList[this.selectedBestCompleteSlideIdx];
                    break;
            }
        },
        changeSlideIdx(type, idx){
            switch(type){
                case "newUpdate":
                    this.selectedNewUpdateSlideIdx = idx;
                    this.selectedNewUpdateSlideItem = this.newUpdateList[this.selectedNewUpdateSlideIdx];
                    break;
                case "hit":
                    this.selectedHitSlideIdx = idx;
                    this.selectedHitSlideItem = this.hitList[this.selectedHitSlideIdx];

                    this.selectedHitFeaturedSlideIdx = idx;
                    this.selectedHitFeaturedSlideItem = this.hitFeatured[this.selectedHitFeaturedSlideIdx];
                    break;
                case "sale":
                    this.selectedChallengeSlideIdx = idx;
                    this.selectedSaleSlideItem = this.challengeList[this.selectedChallengeSlideIdx];
                    break;
                case "genreHit":
                    break;
                case "classic":
                    this.selectedClassicSlideIdx = idx;
                    this.selectedClassicSlideItem = this.classicList[this.selectedClassicSlideIdx];
                    break;
                case "hitGenre":
                    this.selectedHitGenreSlideIdx = idx;
                    this.selectedHitGenreSlideItem = this.hitGenreList[this.selectedHitGenreSlideIdx];

                    this.selectedHitGenreFeaturedSlideIdx = idx;
                    this.selectedHitGenreFeaturedSlideItem = this.hitGenreFeatured[this.selectedHitGenreFeaturedSlideIdx];
                    break;
                case "keyword":
                    this.selectedKeywordSlideIdx  = idx;
                    this.selectedKeywordSlideItem = this.keywordList[this.selectedKeywordSlideIdx];
                    break;
                case "waitFree":
                    this.selectedWaitFreeSlideIdx  = idx;
                    this.selectedWaitFreeSlideItem = this.waitFreeList[this.selectedWaitFreeSlideIdx];
                    break;
                case "bestComplete":
                    this.selectedBestCompleteSlideIdx  = idx;
                    this.selectedBestCompleteSlideItem = this.bestCompleteList[this.selectedBestCompleteSlideIdx];
                    break;
            }
        },
        changeKeyword(keyword){
            if(this.selectedKeywordTagIdx === keyword){
                return;
            }
            
            this.selectedKeywordTagIdx = keyword;
            this.keywordList = [];
            this.selectedKeywordSlideIdx = 0;

            var keywordInfo = this.keywordFullList.find((list) => list.csKeyWord === keyword);
            if(keywordInfo === undefined){
                return;
            }
            this.keywordList = keywordInfo.webtoonList;
        }, 
        getCategory(){ // 카테고리 정보
            var param = 
            {
                // limit : 3
            }
            this.$Axios.post(`${this.host}/infocategory`, param, { headers: { 'Pragma' : 'no-cache', 'Cache-Control': 'no-cache', 'Access-Control-Allow-Origin': '*', 'Content-type': 'application/json', }}).then(res => {
                if (res.data.returnCode === 0) {
                    this.categoryList = res.data.list;
                }
            })
            .catch((error) => {
                console.log(error);
            });
        },
        getCategoryName(category_idx){
            var name = '';
            this.categoryList.forEach(element =>{
                if(category_idx === element.idx){
                    name = element.name;
                }
            })
            return name;
        },
        setFeaturedList(){
            var param = 
            {
                ticket: this.$store.state.ticket,
                sign: this.$store.state.sign,
                viewAdult: this.$store.state.isCheckedAdult ? 1 : 0,
                number: 100
            }
            this.$Axios.post(`${this.host}/bannerintegration/list`, param, { headers: { 'Pragma' : 'no-cache', 'Cache-Control': 'no-cache', 'Access-Control-Allow-Origin': '*', 'Content-type': 'application/json', }}).then(async(res) => {
                if (res.data.returnCode === 0) {
                    this.bannerFeaturedList = res.data.list;
                    this.bannerFeaturedList.sort(function(){return Math.random() - Math.random();});    //순서 랜덤
                }
            })
            .catch((error) => {
                console.log(error);
            });
        },
        getNewUpdate(){ // 0 신규 업데이트 작품
            var param = 
            {
                pageNo: 1,
                pageSize: 12,
                ticket: this.$store.state.ticket,
                sign: this.$store.state.sign,
                viewAdult: this.$store.state.isCheckedAdult ? 1 : 0
            }
            this.$Axios.post(`${this.host}/curation/type0`, param, { headers: { 'Pragma' : 'no-cache', 'Cache-Control': 'no-cache', 'Access-Control-Allow-Origin': '*', 'Content-type': 'application/json', }}).then(res => {
                // console.log('----신규 업데이트 작품----');
                // console.log(res.data);
                if (res.data.returnCode === 0) {
                    this.newUpdateList = res.data.list;
                    this.selectedNewUpdateSlideIdx = 0;
                }
            })
            .catch((error) => {
                console.log(error);
            });
        },
        getHit(){  // 1 인기 작품
            var param = 
            {
                pageNo: 1,
                pageSize: 7,
                ticket: this.$store.state.ticket,
                sign: this.$store.state.sign,
                viewAdult: this.$store.state.isCheckedAdult ? 1 : 0
            }
            this.$Axios.post(`${this.host}/curation/type1`, param, { headers: { 'Pragma' : 'no-cache', 'Cache-Control': 'no-cache', 'Access-Control-Allow-Origin': '*', 'Content-type': 'application/json', }}).then(res => {
                // console.log('----인기 작품----');
                // console.log(res);
                if (res.data.returnCode === 0) {
                    this.hitList = res.data.list;
                    this.selectedHitSlideIdx = 0;
                }
            })
            .catch((error) => {
                console.log(error);
            });
        },
        getChallenge(){  // 2 아튜브 챌린지 작품
            var param = 
            {
                pageNo: 1,
                pageSize: 4,
                ticket: this.$store.state.ticket,
                sign: this.$store.state.sign,
                viewAdult: this.$store.state.isCheckedAdult ? 1 : 0
            }
            this.$Axios.post(`${this.host}/curation/type2`, param, { headers: { 'Pragma' : 'no-cache', 'Cache-Control': 'no-cache', 'Access-Control-Allow-Origin': '*', 'Content-type': 'application/json', }}).then(res => {
                // console.log('----아튜브챌린지 작품----');
                // console.log(res.data);
                if (res.data.returnCode === 0) {
                    this.challengeList = res.data.list.list;
                    // res.data.setList.forEach(element => {
                    //     element.list.forEach(element2 =>{
                    //         this.challengeList.push(element2);
                    //     })
                    // })

                    // if(this.challengeList.length > 0){
                    //     this.selectedChallengeSlideIdx = 0;
                    //     // this.selectedSaleSlideItem = this.challengeList[0];
                    // }
                }
            })
            .catch((error) => {
                console.log(error);
            });
        },
        getGenreHit(){  // 3 장르별 인기 순위
            var param = 
            {
                ticket: this.$store.state.ticket,
                sign: this.$store.state.sign,
                viewAdult: this.$store.state.isCheckedAdult ? 1 : 0
            }
            this.$Axios.post(`${this.host}/curation/type3`, param, { headers: { 'Pragma' : 'no-cache', 'Cache-Control': 'no-cache', 'Access-Control-Allow-Origin': '*', 'Content-type': 'application/json', }}).then(res => {
                // console.log('----장르별 인기 순위----');
                // console.log(res.data);
                if (res.data.returnCode === 0) {
                    this.genreHitList = res.data.setlist;
                    // console.log(this.genreHitList);
                    if(this.genreHitList.length > 0){
                        this.selectedGenreHitSlideIdx = 0;
                        // this.selectedGenreHitSlideItem = this.genreHitList[0];
                    }
                }
            })
            .catch((error) => {
                console.log(error);
            });
        },
        getClassic(){  // 4 명작추천
            var param = 
            {
                pageNo: 1,
                pageSize: 15,
                ticket: this.$store.state.ticket,
                sign: this.$store.state.sign,
                viewAdult: this.$store.state.isCheckedAdult ? 1 : 0
            }
            this.$Axios.post(`${this.host}/curation/type4`, param, { headers: { 'Pragma' : 'no-cache', 'Cache-Control': 'no-cache', 'Access-Control-Allow-Origin': '*', 'Content-type': 'application/json', }}).then(res => {
                // console.log('----명작 추천----');
                // console.log(res.data);
                if (res.data.returnCode === 0) {
                    this.classicList = res.data.list;
                    this.selectedClassicSlideIdx = 0;
                }
            })
            .catch((error) => {
                console.log(error);
            });
        },
        getHitGenre(){  // 5 인기장르소개
            var param = 
            {
                ticket: this.$store.state.ticket,
                sign: this.$store.state.sign,
                viewAdult: this.$store.state.isCheckedAdult ? 1 : 0,
                pageNo: 1,
                pageSize: 4,
            }
            this.$Axios.post(`${this.host}/curation/type5`, param, { headers: { 'Pragma' : 'no-cache', 'Cache-Control': 'no-cache', 'Access-Control-Allow-Origin': '*', 'Content-type': 'application/json', }}).then(res => {
                // console.log('----인기 장르 소개----');
                // console.log(res.data.list.tcategory);
                if (res.data.returnCode === 0) {
                    this.hitGenreList = res.data.list.tcategory;
                    this.selectedHitGenreSlideIdx = 0;

                    // this.hitGenreList.forEach((item, index) => {
                    //     this.hitGenreFeatured.push(item.list[0]);
                    //     this.hitGenreList[index].list.shift();
                    // });

                    // if(this.hitGenreList.length > 0){
                    //     this.selectedHitGenreSlideIdx = 0;
                    //     // this.selectedHitGenreSlideItem = this.hitGenreList[0];

                    //     this.selectedHitGenreFeaturedSlideIdx = 0;
                    //     // this.selectedHitGenreFeaturedSlideItem = this.hitGenreFeatured[0];
                    // }
                }
            })
            .catch((error) => {
                console.log(error);
            });
        },
        getKeyword(){  // 6 맞춤키워드
            var param = 
            {
                ticket: this.$store.state.ticket,
                sign: this.$store.state.sign,
                viewAdult: this.$store.state.isCheckedAdult ? 1 : 0
            }
            // console.log(param)
            this.$Axios.post(`${this.host}/curation/type6`, param, { headers: { 'Pragma' : 'no-cache', 'Cache-Control': 'no-cache', 'Access-Control-Allow-Origin': '*', 'Content-type': 'application/json', }}).then(res => {
                // console.log('----맞춤 키워드----');
                // console.log(res.data);
                if (res.data.returnCode === 0) {
                    this.keywordFullList = res.data.setlist;
                    this.selectedKeywordSlideIdx = 0;

                    if(this.keywordFullList.length > 0){
                        this.changeKeyword(this.keywordFullList[0].csKeyWord)
                    }
                }
            })
            .catch((error) => {
                console.log(error);
            });
        },
        getWaitFree(){  // 7 기다리면 무료
            var param = 
            {
                type: 7,
                setCount: 5,
                ticket: this.$store.state.ticket,
                sign: this.$store.state.sign,
                viewAdult: this.$store.state.isCheckedAdult ? 1 : 0
            }
            this.$Axios.post(`${this.host}/curation`, param, { headers: { 'Pragma' : 'no-cache', 'Cache-Control': 'no-cache', 'Access-Control-Allow-Origin': '*', 'Content-type': 'application/json', }}).then(res => {
                // console.log('----기다리면 무료----');
                // console.log(res.data);
                if (res.data.returnCode === 0) {
                    res.data.setList.forEach(element => {
                        element.list.forEach(element2 =>{
                            this.waitFreeList.push(element2);
                        })
                    })
                    if(this.waitFreeList.length > 0){
                        this.selectedWaitFreeSlideIdx = 0;
                        // this.selectedWaitFreeSlideItem = this.waitFreeList[0];
                    }
                }
            })
            .catch((error) => {
                console.log(error);
            });
        },

        getBestComplete(){  // 8 베스트 완결작
            var param = 
            {
                pageNo: 1,
                pageSize: 20,
                ticket: this.$store.state.ticket,
                sign: this.$store.state.sign,
                viewAdult: this.$store.state.isCheckedAdult ? 1 : 0
            }
            this.$Axios.post(`${this.host}/curation/type8`, param, { headers: { 'Pragma' : 'no-cache', 'Cache-Control': 'no-cache', 'Access-Control-Allow-Origin': '*', 'Content-type': 'application/json', }}).then(res => {
                // console.log('----베스트 완결작----');
                // console.log(res.data);
                if (res.data.returnCode === 0) {
                    this.bestCompleteList = res.data.list;
                    this.selectedBestCompleteSlideIdx = 0;
                }
            })
            .catch((error) => {
                console.log(error);
            });
        },

        getYoutubeList(){  // 영상컨텐츠
            var param = 
            {
                pageNo: 1,
                pageSize: 3,
            }
            this.$Axios.post(`${this.host}/youtube/list`, param, { headers: { 'Pragma' : 'no-cache', 'Cache-Control': 'no-cache', 'Access-Control-Allow-Origin': '*', 'Content-type': 'application/json', }}).then(res => {
                // console.log(res.data);
                if (res.data.returnCode === 0) {
                    this.youtubeList = res.data.list;
                }
            })
            .catch((error) => {
                console.log(error);
            });
        },

        getNoticeList(){  // 영상컨텐츠
            var param = 
            {
                pageNo: 1,
                pageSize: 4,
            }
            this.$Axios.post(`${this.host}/newNotice/list`, param, { headers: { 'Pragma' : 'no-cache', 'Cache-Control': 'no-cache', 'Access-Control-Allow-Origin': '*', 'Content-type': 'application/json', }}).then(res => {
                // console.log(res.data);
                if (res.data.returnCode === 0) {
                    this.noticeList = res.data.list;
                }
            })
            .catch((error) => {
                console.log(error);
            });
        },

        moveDetail(item, webtoonType = 'webtoon'){
            var webtoonInfoUrl = '';
            var webtoonAdult = 0;

            if(webtoonType === 'challenge'){
                webtoonInfoUrl = '/League/Webtoon/Info/' + item.ctWebtoonIdx;
                webtoonAdult = item.ctAdult;
            } else {
                webtoonInfoUrl = '/Webtoon/Info/' + item.twebtoonForApi.webToonIndex;
                webtoonAdult = item.twebtoonForApi.adult;
            }

            if(webtoonAdult === 1){
                if(this.$store.state.loginCheck === false){
                    this.isShowLoginPopup = true;
                }
                else if(!this.$store.state.adult){
                    var src = this.$store.state.siteConfig.niceUrl + '?email=' + this.$store.state.email;
                    window.open(src, 'popupChk', 'width=500, height=550, top=100, left=100, fullscreen=no, menubar=no, status=no, toolbar=no, titlebar=no, location=no, scrollbar=no');
                }
                else{
                    this.$router.push(webtoonInfoUrl);
                }
            }else{
                this.$router.push(webtoonInfoUrl);
            }
        },

        moveLink(linkUrl, isBlank = false){
            if(isBlank){
                window.open(linkUrl);
            } else {
                this.$router.push(linkUrl);
                // location.href = linkUrl;
            }
        },

        selectCategory(categoryIdx){
            this.$router.push('/Category/' + categoryIdx);
        },

        close(popupType){
            switch(popupType){
                case 'Login':
                    this.isShowLoginPopup = false;
                    break;
                case 'PopupOk':
                    this.isShowPopupOkPopup = false;
                    if(this.PopupOkCallbakPage === 'reload'){
                        location.reload();
                    }
                    else if(this.PopupOkCallbakPage !== ''){
                        this.$router.push(this.PopupOkCallbakPage);
                    }
                    this.PopupOkCallbakPage = '';
                    break;
            }
        },
        async clickBanner(bannerInfo){
            // console.log(bannerInfo);
            // private Integer newPage;    새로운페이지여부(1:새창 0:현재페이지)
            // private Integer linkType;	링크타입(0:풀URL 1:내부 라우터 2:웹툰)

            var linkUrl = bannerInfo.link;
            var linkType = bannerInfo.linkType;
            var newPage = bannerInfo.newPage;
            await this.reqBannerClick(bannerInfo.idx);      //배너 클릭 카운팅

            if(linkType === 2){     //웹툰 링크
                var webtoonInfo = await this.checkWebtoon(linkUrl);
                if(webtoonInfo === null){return;}
                if(webtoonInfo.adult === 1){
                    if(this.$store.state.loginCheck === false){
                        this.isShowLoginPopup = true;
                        return;
                    }
                    else if(!this.$store.state.adult){
                        var src = this.$store.state.siteConfig.niceUrl + '?email=' + this.$store.state.email;
                        window.open(src, 'popupChk', 'width=500, height=550, top=100, left=100, fullscreen=no, menubar=no, status=no, toolbar=no, titlebar=no, location=no, scrollbar=no');
                        return;
                    }
                    else{
                        linkUrl = '/Webtoon/Info/' + webtoonInfo.webToonIndex;
                    }
                } else {
                    linkUrl = '/Webtoon/Info/' + webtoonInfo.webToonIndex;
                }
            }

            if(newPage === 0){   //현재 창에서 이동
                if(linkType === 0){  //외부 링크
                    location.href = linkUrl;
                } else {
                    this.$router.push(linkUrl)
                }
            }
            else if(newPage === 1){  //새창으로 열기
                if(linkType !== 0){
                    let route = this.$router.resolve({path: linkUrl});
                    linkUrl = route.href;
                }
                window.open(linkUrl);
            }
        },
        async reqBannerClick(idx){
            var param = 
            {
                index: idx
            }
            return await this.$Axios.post(`${this.host}/click/banner`, param, { headers: { 'Pragma' : 'no-cache', 'Cache-Control': 'no-cache', 'Access-Control-Allow-Origin': '*', 'Content-type': 'application/json', }}).then(async(res) => {
                if(res.data.returnCode === 0){
                    return true;
                }
                else {
                    return false;
                }
                
            })
            .catch((error) => {
                console.log(error);
                return false;
            });
        },
        async checkWebtoon(webtoonIdx){
            var niceSrc = this.$store.state.siteConfig.niceUrl + '?email=' + this.$store.state.email;
            var param = 
            {
                idx: webtoonIdx,
                sign: this.$store.state.sign,
                ticket: this.$store.state.ticket,
                viewAdult: this.$store.state.isCheckedAdult ? 1 : 0,
            }
            return await this.$Axios.post(`${this.host}/webtoon`, param, { headers: { 'Pragma' : 'no-cache', 'Cache-Control': 'no-cache', 'Access-Control-Allow-Origin': '*', 'Content-type': 'application/json', }}).then(async(res) => {
                // console.log('--webtoon info--')
                // console.log(res.data);
                if (res.data.returnCode === 0) {
                    return res.data.data;
                }
                else if(res.data.returnCode === 252){
                    window.open(niceSrc, 'popupChk', 'width=500, height=550, top=100, left=100, fullscreen=no, menubar=no, status=no, toolbar=no, titlebar=no, location=no, scrollbar=no');
                    return null;
                }
                else if(res.data.returnCode === 300){
                    this.initPopupOkMessage = '해당 작품은 운영상의 문제에 위반되어 열람 및 구매 할 수 없습니다.';
                    this.PopupOkCallbakPage = '';
                    this.isShowPopupOkPopup = true;
                    return null;
                }
                else if(res.data.returnCode === 301){
                    if(this.$store.state.loginCheck === false){
                        this.isShowLoginPopup = true;
                    }
                    else if(!this.$store.state.adult){
                        window.open(niceSrc, 'popupChk', 'width=500, height=550, top=100, left=100, fullscreen=no, menubar=no, status=no, toolbar=no, titlebar=no, location=no, scrollbar=no');
                    }
                    return null;
                }
                else {
                    return null;
                }
                
            })
            .catch((error) => {
                console.log(error);
                return null;
            });
        },
    },    
}
</script>

<style scoped>

</style>
