<template>
    <div class="league wrap">
        <Header :initHeaderType="initHeaderType" />
        <div class="league_box">
            <b-container>
                <LeagueFloating />
                <div class="best" v-if="bestList !== null && bestList.length > 0">
                    <div class="top">
                        <div class="title">
                            <p>이달의 베스트 전당</p>
                            <img src="@/assets/images/icon_exclamation_mark_w.svg" id="tooltip1"/>
                            <b-tooltip target="tooltip1" triggers="hover" placement="bottom" custom-class="imgTooltip">
                                <img src="@/assets/images/leage_best_toast.png" />
                            </b-tooltip>
                        </div>
                        <p class="white_line"></p>
                    </div>
                    <b-row>
                        <b-col cols="3" sm="3" class="col" v-for="(item,index) in bestList" :key="index" @click="moveDetail(item)">
                            <div class="rank">
                                <img src="@/assets/images/img_rank_01.svg" v-if="index === 0"/>
                                <img src="@/assets/images/img_rank_02.svg" v-if="index === 1"/>
                                <img src="@/assets/images/img_rank_03.svg" v-if="index === 2"/>
                                <img src="@/assets/images/img_rank_04.svg" v-if="index === 3"/>
                            </div>
                            <div class="league_best_cover">
                                <div class="league_problem blue" v-if="item.ctState === 0">
                                    <div class="league_inner_icon">
                                        <img src="@/assets/images/league_inspection.svg" />
                                        <p>검수 중</p>
                                    </div>
                                </div>
                                <div class="league_problem red" v-else-if="item.ctState === 2">
                                    <div class="league_inner_icon">
                                        <img src="@/assets/images/league_violation.svg" />
                                        <p>운영 원칙 위반</p>
                                    </div>
                                </div>
                                <div class="league_problem gray" v-else-if="item.ctAdult === 1 && $store.state.isCheckedAdult === false">
                                    <img src="@/assets/images/nineteen.svg" class="league_inner_icon"/>
                                </div>
                                <img :src="$store.state.siteConfig.cdnUrl + '/CHALLENGE/bestthumbnail/' + item.ctCdnFolder + '.jpg'" class="league_img_set" v-if="$store.state.siteConfig !== null">
                            </div>
                            <div class="name_tag">
                                <p>{{item.ctTitleName}}</p>
                            </div>
                            <!-- <div class="chapter">
                                <p class="all_hwa">총{{item.epCount}}화</p>
                            </div> -->
                        </b-col>
                    </b-row>
                </div>
                <img src="@/assets/images/league_best_banner.png" class="best_banner" v-else/>
                <div class="new_upload">
                    <p class="title">새로 올라온 작품</p>
                    <b-row>
                        <b-col lg="2" class="jagpum" v-for="(item,index) in newUploadList" :key="index" :class="[index < selectedNewUploadSlideIdx || index >= selectedNewUploadSlideIdx + 5 ? 'hidden' : '' ]"  @click="moveDetail(item)">
                            <div class="up_mark2" v-if="item.upBadge !== null && item.upBadge < 7"></div>
                            <div class="top">
                                <img :src="$store.state.siteConfig.cdnUrl + '/CHALLENGE/mainthumbnail/' + item.ctCdnFolder + '.jpg'" class="league_img_set2" v-if="$store.state.siteConfig !== null"/>
                            </div>
                            <div class="middle">
                                <p class="name">{{item.ctTitleName}}</p>
                                <p class="star">{{item.ctStarAvg.toFixed(1)}}</p>
                            </div>
                            <div class="bottom">
                                <p class="all_hwa">총 {{item.epCount !== null ? item.epCount : 0}}화</p>
                                <p class="view_count">{{item.ctViewTotal !== null ? item.ctViewTotal : 0}}회</p>
                                <!-- <p class="introduce">{{item.ctDesc}}</p> -->
                            </div>
                        </b-col>
                    </b-row>
                    <div class="next" @click="moveSlide('next', 'newUpload')" v-if="newUploadList.length > 5">
                        <img src="@/assets/images/league_new_next.svg" alt="next">
                    </div>
                    <div class="prev" @click="moveSlide('prev', 'newUpload')" v-if="newUploadList.length > 5">
                        <img src="@/assets/images/league_new_prev.svg" alt="next">
                    </div>
                </div>
                <Banner :initBannerNumber="bannerNumber2" />
                <div class="league_list">
                    <div class="top">
                        <b-select v-model="currCategoryIdx" @change="changeCategory" v-if="categoryList.length > 0">
                            <b-select-option v-for="(item,index) in categoryList" :key="index" :value="item.idx">{{item.name}}</b-select-option>
                        </b-select>
                        <div class="m_menu" v-if="categoryList.length > 0">
                            <b-container>
                                <ul>
                                    <li v-for="(item, index) in categoryList" :key="index" :class="{'on' : currCategoryIdx === item.idx}">
                                        <a @click="changeSelectedCategory(item.idx)">{{item.name}}</a>
                                    </li>
                                </ul>
                            </b-container>
                        </div>
                        <ul v-if="leagueList.length > 0">
                            <li :class="[currSortIdx === 2 ? 'on' : '']" @click="changeSort(2)">별점순</li>
                            <li :class="[currSortIdx === 0 ? 'on' : '']" @click="changeSort(0)">조회순</li>
                            <li :class="[currSortIdx === 1 ? 'on' : '']" @click="changeSort(1)">업데이트순</li>
                            <!-- <li :class="[currSortIdx === 1 ? 'on' : '']">작가순</li> -->
                        </ul>
                    </div>
                    <b-row>
                        <b-col lg="2" class="jagpum" v-for="(item,index) in leagueList" :key="index"  @click="moveDetail(item)">
                            <div class="end_mark" v-if="item.ctComplete === 1"></div>
                            <div class="up_mark2" v-else-if="item.upBadge !== null && item.upBadge < 7"></div>
                            <!-- <div class="stop_mark" v-if="index ===1"></div> -->
                            <!-- <div class="rest_mark" v-if="index ===2"></div> -->
                            
                            <div class="top">
                                <img :src="$store.state.siteConfig.cdnUrl + '/CHALLENGE/mainthumbnail/' + item.ctCdnFolder + '.jpg'" class="league_img_set2" v-if="$store.state.siteConfig !== null" />
                            </div>
                            <div class="middle">
                                <p class="name">{{item.ctTitleName}}</p>
                                <p class="star">{{item.ctStarAvg.toFixed(1)}}</p>
                            </div>
                            <div class="bottom">
                                <p class="all_hwa">총 {{item.epCount !== null ? item.epCount : 0}}화</p>
                                <p class="view_count">{{item.ctViewTotal !== null ? item.ctViewTotal : 0}}회</p>
                                <!-- <p class="introduce">{{item.ctDesc}}</p> -->
                            </div>
                        </b-col>
                    </b-row>
                    <Pagination :initMaxRecordCount="currentPageSize" :initMaxPageCount="maxPageCount" :initPagingData="initPagingData" @movePaging="movePaging" />
                </div>
                <Banner :initBannerNumber="bannerNumber" />
            </b-container>
        </div>
        <Login :initIsShowPopup="isShowLoginPopup" @close="close('Login')" />
        <PopupOK :initIsShowPopup="isShowPopupOkPopup" :initPopupOkMessage="initPopupOkMessage" @close="close('PopupOk')" />
        <Footer />
    </div>
</template>

<script>
import Header from '@/components/common/Header.vue'
import Footer from '@/components/common/Footer.vue'
import PopupOK from '@/components/common/PopupOK.vue'
import Login from '@/components/common/Login.vue'
import Pagination from '@/components/common/Pagination.vue'
import Banner from '@/components/common/Banner.vue'
import LeagueFloating from '@/components/league/LeagueFloating.vue'

export default {
    name: 'LeagueMain',
    pageCategory: 'League',
    components: {
        Header,
        Footer,
        Login,
        PopupOK,
        Pagination,
        Banner,
        LeagueFloating,
    },
    data(){
        return{

            categoryList: [],
            currCategoryIdx: 0,

            bestList: [],

            newUploadList: [],
            selectedNewUploadSlideIdx: 0,

            leagueList: [],
            currSortIdx: 2, ////조회 순  : 0, 업데이트 순  : 1, 별점 순  : 2, 신규 순 : 3

            bannerNumber: -1,
            bannerNumber2: -1,
            
            //---리그 작품 페이징---
            currentPageNo: 1,      //현재 페이지 번호
            currentPageSize: 20,   //한번에 불러올 게시물 수
            maxPageCount:10,       //한 화면에 보여질 페이지 수
            initPagingData: null,

            //---리그 작품 페이징---

            isShowLoginPopup: false,

            isShowPopupOkPopup: false,
            initPopupOkMessage: '',
            PopupOkCallbakPage: '',    // 확인 팝업에서 확인 버튼 눌렀을때 이동할 페이지

            initHeaderType: 'type1',
        }
    },
    async created(){
        await this.mixinLoginCheck();

        this.getCategory();
        this.getBestList();
        this.getNewUploadList();
        this.getLeagueList();
    },
    mounted(){
        // setTimeout(()=>{window.scroll(0,0)}, 500) ;
        this.bannerNumber = 300;
        this.bannerNumber2 = 301;
    },
    methods:{
        getCategory(){ // 카테고리 정보
            var param = 
            {
                // limit : 3
            }
            this.$Axios.post(`${this.host}/infocategory`, param, { headers: { 'Pragma' : 'no-cache', 'Cache-Control': 'no-cache', 'Access-Control-Allow-Origin': '*', 'Content-type': 'application/json', }}).then(res => {
                if (res.data.returnCode === 0) {
                    this.categoryList = res.data.list;
                }
            })
            .catch((error) => {
                console.log(error);
            });
        },
        getBestList(){
            var param = 
            {
            }
            this.$Axios.post(`${this.league_host}/challenge/best`, param, { headers: { 'Pragma' : 'no-cache', 'Cache-Control': 'no-cache', 'Access-Control-Allow-Origin': '*', 'Content-type': 'application/json', }}).then(async(res) => {
                // console.log('--best list--')
                // console.log(res.data);
                if (res.data.returnCode === 0) {
                    this.bestList = res.data.list;
                    
                }
            })
            .catch((error) => {
                console.log(error);
            });
        },
        getNewUploadList(){
            var param = 
            {
                sign: this.$store.state.sign,
                ticket: this.$store.state.ticket,
                viewAdult: this.$store.state.isCheckedAdult ? 1 : 0,
                pageNo: 1,
                pageSize: 20,
                category: 0,
                league: 1,
                sort: 3 //조회 순  : 0, 업데이트 순  : 1, 별점 순  : 2, 신규 순 : 3
            }
            this.$Axios.post(`${this.league_host}/challenge/webtoon/list`, param, { headers: { 'Pragma' : 'no-cache', 'Cache-Control': 'no-cache', 'Access-Control-Allow-Origin': '*', 'Content-type': 'application/json', }}).then(async(res) => {
                // console.log('--new upload list--')
                // console.log(res.data);
                if (res.data.returnCode === 0) {
                    this.newUploadList = res.data.list;
                    this.selectedNewUploadSlideIdx = 0;
                }
            })
            .catch((error) => {
                console.log(error);
            });
        },
        getLeagueList(){
            var param = 
            {
                sign: this.$store.state.sign,
                ticket: this.$store.state.ticket,
                viewAdult: this.$store.state.isCheckedAdult ? 1 : 0,
                pageNo: this.currentPageNo,
                pageSize: this.currentPageSize,
                category: this.currCategoryIdx,
                sort: this.currSortIdx, //조회 순  : 0, 업데이트 순  : 1, 별점 순  : 2
                league: 1,
            }
            // console.log(param);
            this.$Axios.post(`${this.league_host}/challenge/webtoon/list`, param, { headers: { 'Pragma' : 'no-cache', 'Cache-Control': 'no-cache', 'Access-Control-Allow-Origin': '*', 'Content-type': 'application/json', }}).then(async(res) => {
                // console.log('--league list--')
                // console.log(res.data);
                if (res.data.returnCode === 0) {
                    this.leagueList = res.data.list;
                    this.initPagingData = res.data.page;
                }
            })
            .catch((error) => {
                console.log(error);
            });
        },
        moveSlide(arrow, type){
            switch(type){
                case "newUpload":
                    if(arrow === 'next' && this.selectedNewUploadSlideIdx + 5 < this.newUploadList.length){this.selectedNewUploadSlideIdx += 1;}
                    else if(arrow === 'prev' && this.selectedNewUploadSlideIdx - 1 >= 0){this.selectedNewUploadSlideIdx -= 1;}
                    break;
            }
        },
        changeCategory(){
            this.currentPageNo = 1;
            this.leagueList = [];
            this.getLeagueList();
        },
        changeSelectedCategory(idx){
            this.currCategoryIdx = idx;
            this.currentPageNo = 1;
            this.leagueList = [];
            this.getLeagueList();
        },
        changeSort(idx){
            this.currentPageNo = 1;
            this.currSortIdx = idx;
            this.leagueList = [];
            this.getLeagueList();
        },
        moveDetail(item){
            // console.log(item);
            if(item.ctAdult === 1){
                if(this.$store.state.loginCheck === false){
                    this.isShowLoginPopup = true;
                }
                else if(!this.$store.state.adult){
                    var src = this.$store.state.siteConfig.niceUrl + '?email=' + this.$store.state.email;
                    window.open(src, 'popupChk', 'width=500, height=550, top=100, left=100, fullscreen=no, menubar=no, status=no, toolbar=no, titlebar=no, location=no, scrollbar=no');
                }
                else if(!this.$store.state.isCheckedAdult){
                    this.initPopupOkMessage = '성인 컨텐츠 활성화 후 이용 가능합니다.';
                    // this.PopupOkCallbakPage = '/League';
                    this.isShowPopupOkPopup = true;
                }
                else{
                    this.$router.push('/League/Webtoon/Info/' + item.ctWebtoonIdx);
                }
            }else{
                this.$router.push('/League/Webtoon/Info/' + item.ctWebtoonIdx);
            }
        },
        movePaging(pageNo){
            this.currentPageNo = pageNo;
            this.getLeagueList();
        },
        close(popupType){
            switch(popupType){
                case 'Login':
                    this.isShowLoginPopup = false;
                    break;
                case 'PopupOk':
                    this.isShowPopupOkPopup = false;
                    if(this.PopupOkCallbakPage === 'reload'){
                        location.reload();
                    }
                    else if(this.PopupOkCallbakPage !== ''){
                        this.$router.push(this.PopupOkCallbakPage);
                    }
                    this.PopupOkCallbakPage = '';
                    break;
            }
        },
    }
}
</script>