<template>
    <div class="complete2 wrap">
        <Header :initHeaderType="initHeaderType" />
            <div class="complete2_box">
                <img src="@/assets/images/img_finished_sign_up.svg" alt="가입 끝 이미지">
                <h1>회원가입을 축하합니다.<br>지금부터 Artube프리미엄 웹툰을 즐겨보세요</h1>
                <p><span>{{distance}}초 후</span>메인화면으로 이동합니다.</p>
                <div class="button" @click="moveHome">확인</div>
            </div>
        <Footer />
    </div>
</template>

<script>
import Header from '@/components/common/Header.vue'
import Footer from '@/components/common/Footer.vue'

export default {
    name: 'SignupComplete',
    pageCategory: 'Member',
    components: {
        Header,
        Footer
    },
    data(){
        return{
            timer: null,
            distance: 5,

            initHeaderType: 'typex',
        }
    },
    mounted(){
        this.timer = setInterval(this.showRemaining, 1000);
    },
    beforeDestroy(){
        if(this.timer !== null){
            clearInterval(this.timer);
        }
    },
    methods:{
        showRemaining() {
            if (this.distance <= 0) {
                this.moveHome();
                return;
            }
            this.distance = this.distance - 1;
        },
        moveHome(){
            clearInterval(this.timer);
            this.$router.push('/');
        }
    }
}
</script>