<template>
    <div id="popup_ok" v-if="initIsShowPopup">
        <div class="popup_ok_box">
            <div>
                <div class="loading"><div class="cycle"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div></div>
                <p>
                    잠시만 기다려주세요...<span v-if="initProgressMaxCount > 0">{{initProgressCurrCount}} / {{initProgressMaxCount}}</span>
                </p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'PopupProgress',
    data(){
        return {
        }
    },
    props: {
        initIsShowPopup: {
            type: Boolean,
            default: false
        },
        initProgressMaxCount:{
            type: Number,
            default: 0
        },
        initProgressCurrCount:{
            type: Number,
            default: 0
        }
    },
    created() {
    },
    mounted(){
    },
    beforeDestroy() {
	},
    methods: {
    },
}
</script>