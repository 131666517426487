<template>
    <div id="jeanguide" v-if="initIsShowPopup">
        <div class="jeanguide_box">
            <img src="@/assets/images/close.svg" alt="close" class="close" @click="closePopup">
            <p class="title">연재 제안 안내</p>
            <p>최소 2화 분량의 완성 원고와<span>시놉시스 작가님 연락처를 메일로 보내주세요</span></p>
            <div class="mail_box">
                <p>지원메일</p>
                <div class="mail">
                    <a v-clipboard:copy="contactEmail">{{contactEmail}}</a>
                    <img src="@/assets/images/icon_copy.svg" alt="copy" v-clipboard:copy="contactEmail">
                </div>
            </div>
            <p class="ps">캐릭터 설명, 전반적인 스토리, 장르 등을 <span>꼼꼼하게 보내주시면 작품 검토에 도움이 됩니다.</span></p>
            <a href="mailto:contact@artube.video">지원하기</a>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Jeanguide',
    data(){
        return {
            contactEmail: 'contact@artube.video',
        }
    },
    props: {
        initIsShowPopup: {
            type: Boolean,
            default: false
        }
    },
    created() {
    },
    mounted(){
    },
    beforeDestroy() {
	},
    methods: {
        closePopup(){
            this.$emit('close');
        },
    },
}
</script>