<template>
<div :class="initHeaderType" v-if="initIsHeaderShow">
    <div class="header-empty"></div>
    <section class="header">
        <div class="header-top">
            <b-container>
                <router-link tag="a" to="/" class="logo"><img src="@/assets/images/logo1_black.png" class="menu-img" /></router-link>
                <a @click="$router.go(-1)" class="back-btn"><i class="icon icon-back"></i></a>
                <router-link tag="a" to="/" class="home-btn"><a><i class="icon icon-home" /></a></router-link>
                <p class="page-name">{{$parent.$options.pageTitle}}</p>
                <ul class="menu-list tools">
                    <router-link tag="li" to="/" class="home-btn">
                        <a><i class="icon icon-home" /></a>
                    </router-link>
                    <li class="att-swap"><a href="/Swap" target="_blank"><i class="icon icon-swap"></i>ATT 간편스왑</a></li>
                    <li class="token-info"><a href="https://scope.klaytn.com/token/0x07aa7ae19b17579f7237ad72c616fecf4ccc787b?tabId=tokenTransfer" target="_blank"><i class="icon icon-token-info"></i>Token Info</a></li>
                    <li class="switch-adult">
                        <a @click="toggleAdult">
                            <i class="icon icon-19-on" v-if="$store.state.isCheckedAdult" />
                            <i class="icon icon-19-off" v-else />
                        </a>
                    </li>
                    <li class="search">
                        <a @click="toggleSearch"><i class="icon icon-search" /></a>
                        <div class="box-search" v-click-outside="closeSearch" v-if="isOpenSearch">
                            <div class="box-body">
                                <input id="inputSearch" placeholder ="작품/작가명을 검색해주세요" v-model="searchText" @keyup.enter="search">
                                <a @click="search"><i class="icon icon-search-gray" /></a>
                            </div>
                        </div>
                    </li>

                    <li v-if="$store.state.loginCheck" class="artube-point"><router-link tag="a" to="/Point/Charge"><i class="icon icon-point"></i> {{$store.state.pgPoint + $store.state.pgBounsPoint + $store.state.attPoint + $store.state.attBounsPoint | comma}}P</router-link></li>
                    <li v-if="$store.state.loginCheck" class="wallet">
                        <a class="connect-wallet connected" @click="toggleWalletInfo" v-if="($store.state.isEnableKaikas && $store.state.erc20Address === $store.state.walletAddress) || ($store.state.isEnableKlip && $store.state.klipAddress === $store.state.walletAddress)">
                            <span>
                                <i class="icon icon-kaikas-small" v-if="$store.state.isEnableKaikas"/>
                                <i class="icon icon-klip-small" v-else-if="$store.state.isEnableKlip"/>
                                {{$store.state.walletAddress}}
                            </span>
                        </a>
                        <a class="connect-wallet connect" @click="toggleWalletInfo" v-else>
                            <span><i class="icon icon-wallet" />지갑 연결</span>
                        </a>

                        <div class="box-wallet-info" v-click-outside="closeKaikas" v-if="isOpenWalletInfo">
                            <div class="box-header">
                                <p class="title">지갑 정보</p>
                                <a @click="closeKaikas" class="close"><i class="icon icon-close" /></a>
                            </div>
                            <div class="box-profile">
                                <img src="@/assets/images/img_profile_default.svg" alt="profile photo" v-if="$store.state.photo === '' || $store.state.photo === null || $store.state.siteConfig === null" >
                                <img :src="$store.state.siteConfig.cdnUrl + '/KR/profilepicture/'+ $store.state.photo" v-else />
                                <div class="info">
                                    <p class="name">{{$store.state.nickName}}</p>
                                    <p class="email">
                                        <i v-if="$store.state.siteConfig !== null && $store.state.social === 0" class="icon icon-symbol-artube" />
                                        <i v-else-if="$store.state.siteConfig !== null && $store.state.social === 1" class="icon icon-symbol-google" />
                                        <i v-else-if="$store.state.siteConfig !== null && $store.state.social === 3" class="icon icon-symbol-fb" />
                                        <i v-else-if="$store.state.siteConfig !== null && $store.state.social === 4" class="icon icon-symbol-naver" />
                                        <i v-else-if="$store.state.siteConfig !== null && $store.state.social === 5" class="icon icon-symbol-kakao" />
                                        {{$store.state.contactEmail}}
                                    </p>
                                    <div class="buttons">
                                        <router-link tag="a" to="/Point/Charge/ATT" class="btn btn-charge"><p>아트포인트 충전</p></router-link>
                                    </div>
                                </div>
                            </div>
                            <b-row class="box-common">
                                <b-col cols="12" class="box token-balance">
                                    <p class="title">보유 코인</p>
                                    <p class="point">{{$store.state.attTokenBalance | comma}} ATT</p>
                                </b-col>
                            </b-row>
                            <div class="box-common">
                                <div class="address-info">
                                    <p class="name">내 지갑 주소 <i class="icon icon-paper" v-clipboard:copy="$store.state.walletAddress" v-clipboard:success="clipboardSuccessHandler"/></p>
                                    <p class="address">{{$store.state.walletAddress}}</p>
                                </div>
                                <a class="disconnect-wallet" @click="disconnectWallet">연결해제</a>
                            </div>
                        </div>
                    </li>

                    <li v-if="!$store.state.loginCheck" class="login">
                        <a class="btn-login" @click="showLoginPopup" >
                            <span>로그인</span>
                        </a>
                    </li>

                    <li class="my-menu">
                        <a class="hamburger" @click="toggleMyPage"><i class="icon icon-burger" /></a>
                        <div class="box-mypage" v-click-outside="closeMypage" v-if="isOpenMypage">
                            <div class="box-header">
                                <p class="title">메뉴</p>
                                <a @click="closeMypage" class="close"><i class="icon icon-close" /></a>
                            </div>

                            <b-row v-if="!$store.state.loginCheck" class="not-login-menu">
                                <!-- <b-col cols="6">
                                    <a @click="showLoginPopup" class="btn btn-login">로그인</a>
                                </b-col>
                                <b-col cols="6">
                                    <router-link tag="a" to="Signup" class="btn btn-signup">회원가입</router-link>
                                </b-col> -->
                                <b-col cols="12">
                                    <a @click="showLoginPopup" class="full-btn btn-login-signup">로그인 / 회원가입</a>
                                </b-col>
                            </b-row>

                            <div class="login-menu" v-else>
                                <div class="box-profile">
                                    <img src="@/assets/images/img_profile_default.svg" alt="profile photo" v-if="$store.state.photo === '' || $store.state.photo === null || $store.state.siteConfig === null" >
                                    <img :src="$store.state.siteConfig.cdnUrl + '/KR/profilepicture/'+ $store.state.photo" v-else />
                                    <div class="info">
                                        <p class="name">{{$store.state.nickName}}</p>
                                        <p class="email">
                                            <i v-if="$store.state.siteConfig !== null && $store.state.social === 0" class="icon icon-symbol-artube" />
                                            <i v-else-if="$store.state.siteConfig !== null && $store.state.social === 1" class="icon icon-symbol-google" />
                                            <i v-else-if="$store.state.siteConfig !== null && $store.state.social === 3" class="icon icon-symbol-fb" />
                                            <i v-else-if="$store.state.siteConfig !== null && $store.state.social === 4" class="icon icon-symbol-naver" />
                                            <i v-else-if="$store.state.siteConfig !== null && $store.state.social === 5" class="icon icon-symbol-kakao" />
                                            {{$store.state.contactEmail}}
                                        </p>
                                        <p class="address" v-if="($store.state.isEnableKaikas && $store.state.erc20Address === $store.state.walletAddress) || ($store.state.isEnableKlip && $store.state.klipAddress === $store.state.walletAddress)">
                                            {{($store.state.walletAddress).toString().substr(0,10)}}......{{($store.state.walletAddress).toString().substr(-6)}} 
                                            <i class="icon icon-paper" v-clipboard:copy="$store.state.walletAddress" v-clipboard:success="clipboardSuccessHandler"/>
                                        </p>
                                        <div class="buttons">
                                            <router-link tag="a" to="/Point/Charge" class="btn btn-charge"><p>아트포인트 충전</p></router-link>
                                            <a @click="openNicePopup" class="btn btn-verify-adult" v-if="!$store.state.adult"><p>성인 인증하기</p></a>
                                            <p class="btn btn-verify-adult-complete" v-else>성인인증 완료</p>
                                        </div>
                                    </div>
                                </div>

                                <div class="box-connect-klip" v-if="$store.state.loginCheck && isCheckMobile">
                                    <div class="icon">
                                        <img src="@/assets/images/icon/icon_klip_small_blue.svg" class="icon-klip" />
                                    </div>
                                    <div class="box-address connected" v-if="$store.state.isEnableKlip && $store.state.klipAddress === $store.state.walletAddress">
                                        <p>
                                            {{$store.state.walletAddress}}
                                        </p>
                                        <img src="@/assets/images/icon/icon_copy.svg" v-clipboard:copy="$store.state.walletAddress" v-clipboard:success="clipboardSuccessHandler" class="icon-copy" />
                                    </div>
                                    <div class="box-address" v-else>
                                        <p>Klip</p>
                                    </div>
                                    <div class="box-buttons">
                                        <a class="btn-wallet change-wallet" @click="disconnectWallet" v-if="$store.state.isEnableKlip">연결해제</a>
                                        <a class="btn-wallet connect-wallet" @click="toggleWalletInfo" v-else>지갑연결</a>
                                    </div>
                                </div>

                                <b-row class="box-quarter box-common">
                                    <b-col cols="6" class="box att-point">
                                        <p class="title">아트포인트</p>
                                        <p class="point">{{$store.state.pgPoint + $store.state.attPoint | comma}}</p>
                                    </b-col>
                                    <b-col cols="6" class="box bonus-point">
                                        <p class="title">보너스 아트포인트</p>
                                        <p class="point">{{$store.state.pgBounsPoint + $store.state.attBounsPoint | comma}}</p>
                                    </b-col>
                                    <!-- <a href="/Swap" target="_blank" class="col-6 box easy-swap">
                                        <i class="icon icon-swap" />
                                        <p>ATT 간편스왑</p>
                                    </a> -->
                                    <a href="https://scope.klaytn.com/token/0x07aa7ae19b17579f7237ad72c616fecf4ccc787b?tabId=tokenTransfer" target="_blank" class="col-6 box token-info">
                                        <i class="icon icon-token-info" />
                                        <p>Token Info</p>
                                    </a>
                                </b-row>
                            </div>

                            <ul class="link-list">
                                <li><div @click="movePage(true, '/Member/Info')"><i class="icon icon-user"></i><p>내 정보</p><i class="icon icon-direct"></i></div></li>
                                <li><div @click="movePage(true, '/Member/Library')"><i class="icon icon-book"></i><p>내 서재</p><i class="icon icon-direct"></i></div></li>
                                <li><div class="sub-link-list" v-b-toggle.assistSubList><i class="icon icon-assist" /><p>후원하기</p><i class="icon icon-direct" /></div></li>
                                <b-collapse id="assistSubList" tag="li" class="sub-list">
                                    <div @click="movePage(true, '/LibraryAssist')">- 후원 목록</div>
                                    <div @click="movePage(true, '/LibraryAssist/Detail')">- 후원 상세 내역</div>
                                </b-collapse>
                                <li><div @click="movePage(false, '/Notice')"><i class="icon icon-notice"></i><p>공지사항</p><i class="icon icon-direct"></i></div></li>
                                <li><div @click="movePage(false, '/FAQ')"><i class="icon icon-faq"></i><p>자주하는 질문</p> <i class="icon icon-direct"></i></div></li>
                                <li><div @click="movePage(true, '/Contact')"><i class="icon icon-contact"></i><p>1:1 문의하기</p><i class="icon icon-direct"></i></div></li>
                                <li><div class="sub-link-list" v-b-toggle.chargeSubList><i class="icon icon-attpoint" /><p>아트포인트</p><i class="icon icon-direct" /></div></li>
                                <b-collapse id="chargeSubList" tag="li" class="sub-list">
                                    <div @click="movePage(true, '/Point/Charge')">- 충전</div>
                                    <div @click="movePage(true, '/Point/History/Charge')">- 충전내역</div>
                                    <div @click="movePage(true, '/Point/Withdraw')">- 출금</div>
                                    <div @click="movePage(true, '/Point/History/Withdraw')">- 출금내역</div>
                                    <div @click="movePage(true, '/Point/History/Usage')">- 사용내역</div>
                                </b-collapse>
                                <li class="link"><a href="https://market.artube.video/" target="_blank"><i class="icon icon-market"></i><p>아튜브 NFT 마켓</p></a><i class="icon icon-direct"></i></li>
                                <li class="link"><a href="https://governance.artube.video/" target="_blank"><i class="icon icon-governance"></i><p>아튜브 거버넌스</p></a><i class="icon icon-direct"></i></li>
                                <li class="banner">
                                    <div class="banner-v2" v-if="$store.state.siteConfig !== null && bannerInfo !== null">
                                        <img :src="$store.state.siteConfig.cdnUrl + bannerInfo.pcPath + bannerInfo.pcFilename" class="pc" @click="clickBanner(bannerInfo)"/>
                                        <img :src="$store.state.siteConfig.cdnUrl + bannerInfo.mpath + bannerInfo.mfilename" class="mobile" @click="clickBanner(bannerInfo)"/>
                                    </div>
                                </li>
                                <li class="logout" v-if="$store.state.loginCheck"><div @click="logout"><span>로그아웃</span></div></li>
                            </ul>
                        </div>
                    </li>

                </ul>
            </b-container>
        </div>
        <div class="header-body">
            <b-container>
                <router-link tag="a" to="/" class="logo"><img src="@/assets/images/logo1_black.png" class="menu-img" /></router-link>
                <ul class="menu-list menus">
                    <li :class="{'active' : currentPageCategory === 'Home'}"><router-link tag="a" to="/" >홈</router-link></li>
                    <!-- <li :class="{'active' : currentPageCategory === 'Common' && currentPageName === 'Category'}"><router-link tag="a" to="/Category" >웹툰</router-link></li> -->
                    <!-- <li :class="{'active' : currentPageCategory === 'Ranking'}"><router-link tag="a" to="/" >랭킹</router-link></li> -->
                    <li :class="{'active' : currentPageCategory === 'Common' && currentPageName === 'DailyToon'}"><router-link tag="a" to="/DailyToon" >웹툰/만화</router-link></li>
                    <li :class="{'active' : currentPageCategory === 'League'}"><router-link tag="a" to="/League" >아튜브 챌린지</router-link></li>
                    <li :class="{'active' : currentPageCategory === 'Event'}"><router-link tag="a" to="/Event" >이벤트</router-link></li>
                    <!-- <li :class="{'active' : currentPageCategory === 'Assist'}"><router-link tag="a" to="/Assist" >후원하기 </router-link></li> -->
                </ul>
                <ul class="menu-list links">
                    <li><a href="https://market.artube.video/" target="_blank">아튜브 NFT 마켓</a></li>
                    <li><a href="https://governance.artube.video/" target="_blank">아튜브 거버넌스</a></li>
                </ul>
            </b-container>
        </div>
        <Login :initIsShowPopup="isShowLoginPopup" :initInputEmailForce="loginEmailForce" @loginComplete="loginComplete" @close="close('Login')" />
        <Jeanguide :initIsShowPopup="isShowJeanguidePopup" @close="close('JeanGuide')" />
        <Floating :initConnectWebSocket="initConnectWebSocket" />
        <PopupWallet :initIsShowPopup="isShowWalletPopup" :initRunInterval="initWalletRunInterval" :initIsMainNetForce="initIsMainNetForce" @close="close('Wallet')" />
        <PopupOK :initIsShowPopup="isShowPopupOkPopup" :initPopupOkMessage="initPopupOkMessage" @close="close('PopupOk')" />
    </section>
</div>
</template>

<script>

import Login from '@/components/common/Login.vue'
import Jeanguide from '@/components/support/Jeanguide.vue'
import Floating from '@/components/common/Floating.vue'
import PopupWallet from '@/components/wallet/PopupWallet.vue'
import PopupOK from '@/components/common/PopupOK.vue'
import vClickOutside from 'v-click-outside'

export default {
    name: 'Header',
    directives: {
        clickOutside: vClickOutside.directive
    },
    components: {
        Login,
        Jeanguide,
        Floating,
        PopupWallet,
        PopupOK,
    },
    data() {
        return {
            initConnectWebSocket: null,
            isCheckMobile: false,

            currentPageName: '',
            currentPageCategory: '',

            searchText: '',

            categoryList: [],
            bannerInfo: null,

            isCheckedAdult: false,
            isOpenSearch: false,
            isOpenMypage: false,
            isOpenWalletInfo: false,
            isShowLoginPopup: false,
            isShowJeanguidePopup: false,
            isShowAdultConfirmPopup: false,
            isShowWalletPopup: false,
            
            isDisconnectWallet: false,

            initWalletRunInterval: true,
            initIsMainNetForce: false,      //메인넷으로 고정할지 여부

            isShowPopupOkPopup: false,
            initPopupOkMessage: '',
            PopupOkCallbakPage: '',    // 확인 팝업에서 확인 버튼 눌렀을때 이동할 페이지

            loginEmailForce: '',    //로그인 이메일 주소를 강제로 입력할때
        }
    },
    props: {
        initIsShowCategory: {
            type: Boolean,
            default: false
        },
        initCategoryIdx: {
            type: Number,
            default: -1
        },
        initHeaderType: {
            type: String,
            default: 'type0',
        },
        initIsHeaderShow: {
            type: Boolean,
            default: true,
        },
    },
    async created() {
        this.isCheckMobile = this.isMobile();
        this.currentPageName = this.$parent.$options.name;
        this.currentPageCategory = this.$parent.$options.pageCategory;

        var that = this;
        if (window.addEventListener) {
            window.addEventListener("message", that.onMessage, false);
        }
        else if (window.attachEvent) {
            window.attachEvent("onmessage", that.onMessage, false);
        }

        await this.$store.commit("setSiteConfig", this.host);
        await this.mixinLoginCheck();
        if(!this.$store.state.loginCheck){
            this.initConnectWebSocket = false;
            await this.mixinCheckOAuthRedirect(
                (res)=>{
                    // console.log('---showLoginPopupCallback ---');
                    // console.log(this.$store.state.isOAuthRedirectPage);
                    // console.log(this.$store.state.isOAuthShowLoginPopup);
                    // console.log(this.$store.state.isShowLoginPopupForce);
                    this.isShowLoginPopup = true;
                    this.loginEmailForce = res.email;
                }
            );
        } else {
            this.initConnectWebSocket = true;
            await this.$store.commit("resetOAuthData");
            
        }
        this.getCategory();
        this.getBanner();

        window.scroll(0,0);
    },
    mounted(){
    },
    beforeDestroy() {
	},
    methods: {
        getCategory(){ // 카테고리 정보
            var param = 
            {
                // limit : 3
            }
            this.$Axios.post(`${this.host}/infocategory`, param, { headers: { 'Pragma' : 'no-cache', 'Cache-Control': 'no-cache', 'Access-Control-Allow-Origin': '*', 'Content-type': 'application/json', }}).then(res => {
                if (res.data.returnCode === 0) {
                    this.categoryList = res.data.list;
                    // console.log(this.categoryList);
                }
            })
            .catch((error) => {
                console.log(error);
            });
        },
        getCategoryName(category_idx){
            var name = '';
            this.categoryList.forEach(element =>{
                if(category_idx === element.idx){
                    name = element.name;
                }
            })
            return name;
        },
        getBanner(){
            var param = 
            {
                ticket: this.$store.state.ticket,
                sign: this.$store.state.sign,
                viewAdult: this.$store.state.isCheckedAdult ? 1 : 0,
                number: 600
            }
            this.$Axios.post(`${this.host}/bannerintegration/list`, param, { headers: { 'Pragma' : 'no-cache', 'Cache-Control': 'no-cache', 'Access-Control-Allow-Origin': '*', 'Content-type': 'application/json', }}).then(async(res) => {
                if (res.data.returnCode === 0) {
                    if(res.data.list.length > 0){
                        this.bannerInfo = res.data.list[0];
                    }
                }
            })
            .catch((error) => {
                console.log(error);
            });
        },
        async clickBanner(bannerInfo){
            // console.log(bannerInfo);
            // private Integer newPage;    새로운페이지여부(1:새창 0:현재페이지)
            // private Integer linkType;	링크타입(0:풀URL 1:내부 라우터 2:웹툰)

            var linkUrl = bannerInfo.link;
            var linkType = bannerInfo.linkType;
            await this.reqBannerClick(bannerInfo.idx);      //배너 클릭 카운팅

            if(bannerInfo.linkType === 2){     //웹툰 링크
                var webtoonInfo = await this.checkWebtoon(bannerInfo.link);
                if(webtoonInfo.adult === 1){
                    if(this.$store.state.loginCheck === false){
                        this.isShowLoginPopup = true;
                        return;
                    }
                    else if(!this.$store.state.adult){
                        var src = this.$store.state.siteConfig.niceUrl + '?email=' + this.$store.state.email;
                        window.open(src, 'popupChk', 'width=500, height=550, top=100, left=100, fullscreen=no, menubar=no, status=no, toolbar=no, titlebar=no, location=no, scrollbar=no');
                        return;
                    }
                    else{
                        linkUrl = '/Webtoon/Info/' + webtoonInfo.webToonIndex;
                    }
                } else {
                    linkUrl = '/Webtoon/Info/' + webtoonInfo.webToonIndex;
                }
            }

            if(bannerInfo.newPage === 0){   //현재 창에서 이동
                if(bannerInfo.linkType === 0){  //외부 링크
                    location.href = linkUrl;
                } else {
                    this.$router.push(linkUrl)
                }
            }
            else if(bannerInfo.newPage === 1){  //새창으로 열기
                if(linkType !== 0){
                    let route = this.$router.resolve({path: linkUrl});
                    linkUrl = route.href;
                }
                window.open(linkUrl);
            }
        },
        async reqBannerClick(idx){
            var param = 
            {
                index: idx
            }
            return await this.$Axios.post(`${this.host}/click/banner`, param, { headers: { 'Pragma' : 'no-cache', 'Cache-Control': 'no-cache', 'Access-Control-Allow-Origin': '*', 'Content-type': 'application/json', }}).then(async(res) => {
                if(res.data.returnCode === 0){
                    return true;
                }
                else {
                    return false;
                }
                
            })
            .catch((error) => {
                console.log(error);
                return false;
            });
        },
        async checkWebtoon(webtoonIdx){
            var param = 
            {
                idx: webtoonIdx,
                sign: this.$store.state.sign,
                ticket: this.$store.state.ticket,
                viewAdult: this.$store.state.isCheckedAdult ? 1 : 0,
            }
            return await this.$Axios.post(`${this.host}/webtoon`, param, { headers: { 'Pragma' : 'no-cache', 'Cache-Control': 'no-cache', 'Access-Control-Allow-Origin': '*', 'Content-type': 'application/json', }}).then(async(res) => {
                // console.log('--webtoon info--')
                // console.log(res.data);
                if (res.data.returnCode === 0) {
                    return res.data.data;
                }
                else if(res.data.returnCode === 300){
                    this.initPopupOkMessage = '해당 작품은 운영상의 문제에 위반되어 열람 및 구매 할 수 없습니다.';
                    this.PopupOkCallbakPage = '';
                    this.isShowPopupOkPopup = true;
                    return null;
                }
                else if(res.data.returnCode === 301){
                    if(this.$store.state.loginCheck === false){
                        this.isShowLoginPopup = true;
                    }
                    else if(!this.$store.state.adult){
                        var src = this.$store.state.siteConfig.niceUrl + '?email=' + this.$store.state.email;
                        window.open(src, 'popupChk', 'width=500, height=550, top=100, left=100, fullscreen=no, menubar=no, status=no, toolbar=no, titlebar=no, location=no, scrollbar=no');
                    }
                    return null;
                }
                else {
                    return null;
                }
                
            })
            .catch((error) => {
                console.log(error);
                return null;
            });
        },
        async uploadWebtoon(){
            if(this.$store.state.loginCheck === false){ //로그인 확인
                this.isShowLoginPopup = true;
            }else if(!this.$store.state.adult){ //성인 인증 확인
                var src = this.$store.state.siteConfig.niceUrl + '?email=' + this.$store.state.email;
                window.open(src, 'popupChk', 'width=500, height=550, top=100, left=100, fullscreen=no, menubar=no, status=no, toolbar=no, titlebar=no, location=no, scrollbar=no');
            }
            else{
                var checkWriter = await this.checkWriter(); //작가 등록 여부 확인
                if(checkWriter === null){   //체크 에러
                    this.initPopupOkMessage = '작가 등록 여부 체크 에러'
                    // this.PopupOkCallbakPage = 'reload';
                    this.isShowPopupOkPopup = true;
                }
                else if(checkWriter){   //작가 등록된 상태
                    this.$router.push('/League/Writer/Info')
                }
                else{   //작가 등록 안된 상태
                    this.$router.push('/League/Writer/Signup')
                }
            }
        },
        async checkWriter(){
            var param = 
            {
                sign: this.$store.state.sign,
                ticket: this.$store.state.ticket,
                viewAdult: this.$store.state.isCheckedAdult ? 1: 0
            }
            return await this.$Axios.post(`${this.league_host}/challenge/writer/check`, param, { headers: { 'Pragma' : 'no-cache', 'Cache-Control': 'no-cache', 'Access-Control-Allow-Origin': '*', 'Content-type': 'application/json', }}).then(async(res) => {
                // console.log(res.data);
                if (res.data.returnCode === 0) {
                    return true;
                }
                else if(res.data.returnCode === 41){
                    return false;
                }
                else{
                    return null;
                }
            })
            .catch((error) => {
                console.log(error);
                return null;
            });
        },
        toggleAdult(){
            if(this.$store.state.loginCheck === false){
                this.isShowLoginPopup = true;
            }else if(!this.$store.state.adult){
                this.openNicePopup();
            }
            else{
                this.$store.commit("checkAdult", !this.$store.state.isCheckedAdult);
                location.reload();
            }
        },
        openNicePopup(){
            var src = this.$store.state.siteConfig.niceUrl + '?email=' + this.$store.state.email;
            window.open(src, 'popupChk', 'width=500, height=550, top=100, left=100, fullscreen=no, menubar=no, status=no, toolbar=no, titlebar=no, location=no, scrollbar=no');
        },
        toggleSearch(){
            this.searchText = '';
            this.isOpenSearch = !this.isOpenSearch;
        },
        search(){
            if(this.searchText.length > 0){
                this.$router.push('/Category/0/' + this.searchText);
            }
        },
        closeSearch(){
            this.searchText = '';
            this.isOpenSearch = false;
        },
        toggleMyPage(){
            this.isOpenMypage = !this.isOpenMypage;
            this.$emit('changeState', 'isOpenMypage', this.isOpenMypage);
        },
        closeMypage(){
            this.isOpenMypage = false;
            this.$emit('changeState', 'isOpenMypage', this.isOpenMypage);
        },
        toggleWalletInfo(){
            if(!this.$store.state.isEnableKaikas && !this.$store.state.isEnableKlip){
                this.isShowWalletPopup = true;
            }
            else if(this.$store.state.isEnableKaikas && this.$store.state.erc20Address !== null && this.$store.state.erc20Address !== '' && this.$store.state.erc20Address !== this.$store.state.walletAddress){
                // this.isShowWalletPopup = true;
                this.initPopupOkMessage = '이미 연결된 지갑 주소와 Kaikas 지갑 주소가 다릅니다.<br/>Kaikas에서 활성화된 지갑을 확인해 주세요.';
                this.isShowPopupOkPopup = true;
            }
            else{
                this.isOpenWalletInfo = !this.isOpenWalletInfo;
            }
        },
        disconnectWallet(){
            this.$store.commit("disconnectKaikas");
            this.$store.commit("disconnectKlip");
            this.isDisconnectWallet = true;
            this.toggleWalletInfo();
        },
        closeKaikas(){
            this.isOpenWalletInfo = false;
        },
        showLoginPopup(){
            if(this.$store.state.loginCheck === false){
                this.closeMypage();
                this.isShowLoginPopup = true;
            }
            else{
                this.initPopupOkMessage = '이미 로그인 상태 입니다.'
                this.isShowPopupOkPopup = true;
            }
        },
        showJeanGuidePopup(){
            this.isShowJeanguidePopup = true;
        },
        close(popupType){
            switch(popupType){
                case 'Login':
                    this.isShowLoginPopup = false;
                    break;
                case 'JeanGuide':
                    this.isShowJeanguidePopup = false;
                    break;
                case 'AdultConfirm':
                    this.isShowAdultConfirmPopup = false;
                    break;
                case 'Wallet':
                    var moveUrl = document.URL.replace('://','');
                    moveUrl = moveUrl.substring(moveUrl.indexOf("/"));
                    this.isShowWalletPopup = false;
                    if(this.$store.state.isEnableKaikas || this.$store.state.isEnableKlip){
                        this.initPopupOkMessage = '지갑이 연결 되었습니다.';
                        this.isShowPopupOkPopup = true;
                        // if(this.isDisconnectWallet){
                        //     this.PopupOkCallbakPage = moveUrl;  //지갑 연결 끊고 다시 연결했을 경우 새로고침
                        // }
                    }else if(this.isDisconnectWallet){
                        this.$router.push(moveUrl);
                    }
                    break;
                case 'PopupOk':
                    this.isShowPopupOkPopup = false;
                    if(this.PopupOkCallbakPage === 'reload'){
                        location.reload();
                    }
                    else if(this.PopupOkCallbakPage !== ''){
                        this.$router.push(this.PopupOkCallbakPage);
                    }
                    this.PopupOkCallbakPage = '';
                    break;
            }
        },
        logout(){
            this.$store.commit("logout");
            this.$router.push('/');
        },
        loginComplete(){
            this.isShowLoginPopup = false;
        },
        addClass(id, classe) {
            try{
                var element = document.getElementById(id);
                var classes = element.className.split(' ');
                var getIndex = classes.indexOf(classe);

                if (getIndex === -1) {
                    classes.push(classe);
                    element.className = classes.join(' ');
                }
            }catch(err){err;}
		},
		delClass(id, classe) {
            try{
                var element = document.getElementById(id);
                var classes = element.className.split(' ');
                var getIndex = classes.indexOf(classe);

                if (getIndex > -1) {
                    classes.splice(getIndex, 1);
                }
                element.className = classes.join(' ');
            }catch(err){err;}
		},
        onMessage(event) {
            // Check sender origin to be trusted
            // if (event.origin !== "http://172.30.1.107:9094") return;
            var data = event.data;
            // console.log(event.data);

            if(data.func === "niceSuccess"){
                this.confirmNiceResult();
            }
            if(data.func === "pnlinkSuccess"){
                // location.reload();
                this.$store.commit("setProfile", this.host);
            }
            // if(data.func === "pnlinkFail"){
            //     console.log(' pnlinkFail ' );
            // }
        },
        confirmNiceResult(){ // 나이스 인증 확인
            var param = 
            {
                sign: this.$store.state.sign,
                ticket: this.$store.state.ticket,
                viewAdult: this.$store.state.isCheckedAdult ? 1 : 0
            }
            this.$Axios.post(`${this.host}/adult/result`, param, { headers: { 'Pragma' : 'no-cache', 'Cache-Control': 'no-cache', 'Access-Control-Allow-Origin': '*', 'Content-type': 'application/json', }}).then(res => {
                if (res.data.returnCode === 0) {
                    this.initPopupOkMessage = '본인 인증이 완료 되었습니다.'
                    this.PopupOkCallbakPage = 'reload';
                    this.isShowPopupOkPopup = true;
                }
            })
            .catch((error) => {
                console.log(error);
            });
        },
        clipboardSuccessHandler ({ value, event }) {
            value; event;
            this.isShowPopupOkPopup = true;
            this.initPopupOkMessage = '지갑 주소가 복사되었습니다.'
        },
        movePage(isNeedLogin, pageName){
            if(isNeedLogin && !this.$store.state.loginCheck){
                this.showLoginPopup();
                return;
            } else {
                this.$router.push(pageName);
            }

        },
        selectCategory(categoryIdx){
            this.$emit('selectCategory', categoryIdx);
        }
    },
}
</script>

<style scoped>
    /* @import url("@/assets/css/header.css"); */
</style>