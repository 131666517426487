<template>
    <div id="login" v-if="initIsShowPopup">
        <div class="login-box">
            <img src="@/assets/images/close.svg" alt="close" class="close" @click="closePopup">
            <h1>artube</h1>
            <input placeholder="이메일" class="id" v-model="email" @keyup.enter="loginSubmit">
            <input type="password" placeholder="비밀번호" class="pass"  v-model="password" @keyup.enter="loginSubmit">
            <div class="error-box">
                <p class="error" v-if="isShowError">{{errorMsg}}</p>
            </div>
            <!-- <div class="check_box">
                <input class="styled-checkbox" id="styled-checkbox-1" type="checkbox" value="value1">
                <label for="styled-checkbox-1">아이디 저장</label>
                <input class="styled-checkbox" id="styled-checkbox-2" type="checkbox" value="value2">
                <label for="styled-checkbox-2">로그인 유지</label>
            </div> -->
            <b-button class="login-button" @click="loginSubmit">로그인</b-button>
            <div class="util">
                <router-link tag="p" to="/Signup" class="join">이메일로 회원가입</router-link>
                <router-link tag="p" to="/Password/Reset">비밀번호 재설정</router-link>
            </div>
            <div class="util-sns">
                <p class="util-sns-join">SNS 계정으로 로그인 / 회원가입</p>
            </div>

            

            <div class="login-sns">
                <!-- <div class="login-sns-icon" v-for="item in loginIcon" :key="item">
                    <router-link :to="item.iconUrl" ><img :src="item.iconImg"></router-link>
                </div>  -->
                <!-- <a @click="appleSignIn()">애플 로그인 테스트</a> -->
                <a @click="reqOAuth('kakao')"><i class="login-sns-icon icon-symbol-kakao" /></a>
                <a @click="reqOAuth('naver')"><i class="login-sns-icon icon-symbol-naver" /></a>
                <a @click="reqOAuth('facebook')"><i class="login-sns-icon icon-symbol-fb" /></a>
                <a @click="reqOAuth('google')"><i class="login-sns-icon icon-symbol-google" /></a>
                <a><vue-apple-signin type="sign in" :onSuccess="callsuccess"></vue-apple-signin></a>
                <!-- <a v-if="!isWebview" ><vue-apple-signin type="sign in" :onSuccess="callsuccess"></vue-apple-signin></a> -->
                <!-- <a v-if="isWebview"><vue-apple-signin-m type="sign in" :onSuccess="callsuccess"></vue-apple-signin-m></a> -->
            </div>
            <!-- <div class="login-card">
                <a class="login-card-kakao" @click="reqOAuth('kakao')">
                    <img src="@/assets/images/icon/icon_kakao_small.svg">
                    <p>카카오 계정으로 로그인</p>
                </a>
                <a class="login-card-fb" @click="reqOAuth('facebook')">
                    <img src="@/assets/images/icon/icon_fb_small.svg">
                    <p>페이스북 계정으로 로그인</p>
                </a>
                <a class="login-card-google" @click="reqOAuth('google')">
                    <img src="@/assets/images/icon/icon_google_small.svg">
                    <p>구글 계정으로 로그인</p>
                </a>
            </div> -->
        </div>
    </div>
</template>

<script>
export default {
    name: 'Login',
    data(){
        return {
            isWebview: false,
            email: "",         //아이디
            password: "",   //비밀번호

            isShowError: false,
            errorMsg: '',
        }
    },
    props: {
        initIsShowPopup: {
            type: Boolean,
            default: false
        },
        initInputEmailForce:{
            type: String,
            default: ''
        }
    },
    created() {
        this.isWebview = this.isArtubeWebview();
        // this.isWebview = this.isMobile();
    },
    mounted(){
    },
    beforeDestroy() {
	},
    watch:{
        initInputEmailForce(){
            if(this.initInputEmailForce !== ''){
                this.email = this.initInputEmailForce;
            }
        }
    },
    methods: {
        closePopup(){
            this.email = '';
            this.password = '';
            this.$store.commit("resetOAuthData");
            this.$emit('close');
        },
        async loginSubmit() {

            this.isShowError = false;
            this.errorMsg = '';

            if (!this.email || !this.password) {

                this.isShowError = true;
                this.errorMsg = '이메일과 비밀번호를 입력해 주세요.';
                return;
            }

            let filter = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;
            if(!filter.test(this.email)){
                this.errorMsg = '잘못된 이메일 형식 입니다.'
                this.isShowError = true;
                return;
            }

            var param = 
            {
                email: this.email,
                password: this.password
            }
            this.$Axios.post(`${this.host}/member/login`, param, { headers: { 'Pragma' : 'no-cache', 'Cache-Control': 'no-cache', 'Access-Control-Allow-Origin': '*', 'Content-type': 'application/json', }}).then(async(res) => {
                // console.log(res);
                if (res.data.returnCode === 0) {
                    await this.$store.commit("setEmail", this.email);
                    await this.$store.commit("loginTicket", res.data.ticket);
                    await this.$store.commit("loginSign", res.data.sign);
                    await this.$store.commit("setProfile", this.host);
                    this.email = '';
                    this.password = '';
                    // this.$emit("loginComplete");
                    location.reload();
                } 
                else if(res.data.returnCode === 41){
                    this.isShowError = true;
                    this.errorMsg = '존재하지 않는 회원정보 입니다.';
                }
                else if(res.data.returnCode === 46){
                    this.isShowError = true;
                    this.errorMsg = '이미 탈퇴한 계정입니다.';
                }
                else if(res.data.returnCode === 90 || res.data.returnCode === 91 || res.data.returnCode === 92){
                    this.isShowError = true;
                    this.errorMsg = '비밀번호를 다시 확인해 주세요.';
                }
                else {
                    console.log(res.data.description);

                    this.isShowError = true;
                    this.errorMsg = '로그인 실패, 잠시 후 다시 시도해 주세요.';
                }
            })
            .catch((error) => {
                console.log(error);
            });
        },
        callsuccess(data) {
            console.log(data);
        },
        async reqOAuth(snsType){
            // console.log('--Call reqOAuth--');
            // console.log(snsType);
            // console.log(this.$store.state.reqOAuthKey);
            var isSavedReqUrl = await this.reqSaveReqUrl();
            if(!isSavedReqUrl){
                return;
            }
            var oAuthUrl = '';
            switch(snsType){
                case 'kakao':
                    oAuthUrl = this.$store.state.siteConfig.csKakao;
                    break;
                case 'facebook':
                    oAuthUrl = this.$store.state.siteConfig.csFacebook;
                    break;
                case 'google':
                    oAuthUrl = this.$store.state.siteConfig.csGoogle;
                    break;
                case 'naver':
                    oAuthUrl = this.$store.state.siteConfig.csNaver;
                    break;
            }
            // console.log(oAuthUrl);
            location.href = oAuthUrl;
        },
        async reqSaveReqUrl(){
            var param = 
            {
                url: location.href,
                popup: 1
            }
            // console.log(param);
            return await this.$Axios.post(`${this.host}/frontpos/save`, param, { headers: { 'Pragma' : 'no-cache', 'Cache-Control': 'no-cache', 'Access-Control-Allow-Origin': '*', 'Content-type': 'application/json', }}).then(async(res) => {
                // console.log('--Call reqSaveReqUrl--');
                // console.log(res.data);
                if (res.data.returnCode === 0) {
                    await this.$store.commit("setReqOAuthKey", res.data.key);
                    return true;
                } 
                else {
                    return false;
                }
            })
            .catch((error) => {
                console.log(error);
                return false;
            });
        }
    },
}
</script>