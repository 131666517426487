<template>
    <div class="leagueevent wrap">
        <Header :initHeaderType="initHeaderType" />
        <div class="container">
            <img src="@/assets/images/upload_event.png" class="event_page_img0" />
            <div class="upload_event">
                <img src="@/assets/images/event_page_1.png" class="event_page_img1" />
                <img src="@/assets/images/event_page_2.png" class="event_page_img2"/>
                <div class="m_event">
                    <img src="@/assets/images/m_event_page2.png" class="m_event_page_img"/>
                    <img src="@/assets/images/m_event_page3.png" class="m_event_page_img"/>
                    <img src="@/assets/images/m_event_page4.png" class="m_event_page_img"/>
                </div>
                <div class="button" @click="moveLeague"><img src="@/assets/images/btn_webtoon.png" /></div>
                <div class="text">
                    <p>·  아튜브챌린지 이벤트 관련에 대한 자세한 사항은 이벤트 유의사항을 참고해주시기 바랍니다.</p>
                    <p>·  아튜브챌린지 작품등록은 데스크탑에서만 가능하오니 참고해주시기 바랍니다.</p>
                </div>
            </div>
            <div class="event_notice">
                <div class="event_notice_inner">
                    <p class="title">이벤트 유의사항</p>
                    <!-- <div class="box">
                        <p class="name">응모자격</p>
                        <p>- 대한민국에 거주하는 만 19세 이상의 사용자라면 누구나 참여 가능</p>
                    </div>
                    <div class="box">
                        <p class="name">당첨자 발표</p>
                        <p>- 아튜브 홈페이지 및 미디움(www.medium.com/artube)에서 발표</p>
                        <p>- 당첨자에게는 이메일 별도 안내 진행</p>
                    </div> -->
                    <div class="box">
                        <!-- <p class="name">유의사항</p> -->
                        <p>- 대한민국에 거주하는 만 19세 이상의 사용자라면 누구나 참여 가능합니다.</p>
                        <p>- 한 작가가 다수의 작품을 업로드하여 응모할 수 있습니다. <span>(단, 중복 당첨 불가)</span></p>
                        <p>- 이벤트 참여는 본인의 이메일 주소로 생성된 아이디를 기준으로 하며, 당첨자에게는 해당 이메일을 통해 별도 안내 드립니다.</p>
                        <p>- 당첨자 선정은 객관적인 내부 심사 기준에 따라 심사되며, 발표 이후 선정 기준에 대한 답변 및 응대는 제공되지 않습니다.</p>
                        <p>- 부득이하게 당첨 취소가 발생하여도 추가 당첨자를 선정하지 않습니다.</p>
                        <p>- 상기 이미지는 실제와 일부 다를 수 있습니다.</p>
                        <p>- 당첨자 정보가 수령인의 정보가 다르거나, 개인정보 수집을 거부할 경우 당첨이 무효될 수 있습니다.</p>
                        <p>- 제세공과금은 아튜브에서 부담하며, 당첨자 발표 후 7일간 연락이 되지 않는  경우 당첨이 취소됩니다.</p>
                        <p>- 본 이벤트는 당사 사정에 의하여 조기 종료될 수 있습니다.</p>
                    </div>
                </div>
            </div>
        </div>
        <Footer />
        <Login :initIsShowPopup="isShowLoginPopup" @close="close('Login')" />
    </div>
</template>

<script>
import Header from '@/components/common/Header.vue'
import Footer from '@/components/common/Footer.vue'
import Login from '@/components/common/Login.vue'

export default {
    name: 'EventLeague',
    pageCategory: 'Event',
    pageTitle: '이벤트',
    components: {
        Header,
        Footer,
        Login,
    },
    data(){
        return{
            isShowLoginPopup: false,
            initHeaderType: 'type2',
        }
    },
    async created(){
        // await this.mixinSiteConfigCheck();
        await this.mixinLoginCheck();
    },
    methods:{
        moveLeague(){
            if(this.$store.state.loginCheck){
                this.$router.push('/League/Writer/Signup')
            }
            else {
                this.isShowLoginPopup = true;
            }
        },
        close(popupType){
            switch(popupType){
                case 'Login':
                    this.isShowLoginPopup = false;
                    break;
            }
        },
    }
}
</script>