<template>
    <div class="enrollment3">
        <Header :initHeaderType="initHeaderType" />
        <Login />
        <b-container>
            <div class="title_area">
                <p>회차등록</p>
                <p></p>
            </div>
            <b-row class="round_area">
                <b-col lg="2" md="2">회차</b-col>
                <b-col lg="10" md="10">
                    <p class="round">{{episodeIdx}}</p>
                    <p class="ps">해당 회차는 자동으로 표기되며 수정 할 수 없습니다.</p>
                </b-col>
            </b-row>
            <b-row class="round_title">
                <b-col lg="2" md="2">회차제목</b-col>
                <b-col lg="10" md="10">
                    <div class="title_typing">
                        <input type="text" v-model="title" @input="checkTitle" maxlength="20">
                        <p>{{title.length}}/20</p>
                    </div>
                    <div class="round_check">
                        <p class="ps">제목은 띄어쓰기 포함 최대 20자 까지 가능합니다.</p>
                        <b-form-checkbox id="end" v-model="isComplete" name="end" :value="true" :unchecked-value="false">완결</b-form-checkbox>
                    </div>
                </b-col>
            </b-row>
            <b-row class="thumnail">
                <b-col lg="2" md="2">썸네일 <span>이미지</span></b-col>
                <b-col lg="10" md="10">
                    <label class="image-upload" for="idThumbnail">
                        <img :src="thumbnail.path" v-if="thumbnail !== null" />
                        <p v-else>208*140 <span>에피소드 썸네일</span></p>
                        <input class="display-none" id="idThumbnail" name="image" ref="files"  @change="uploadThumbnail" :accept="accept" type="file">
                    </label>
                    <p class="ps">
                        <span>- 파일명 : 특수문자 불가, 한글, 영문, 숫자만 가능합니다.  </span>
                        <span>- 썸네일 이미지 : 208*140px</span>
                        <span>- 이미지 파일 크기는 300KB이하, jpg만 업로드 가능합니다.</span>
                    </p>
                </b-col>
            </b-row>
            <b-row class="manuscript">
                <b-col lg="2" md="2">원고등록</b-col>
                <b-col lg="10" md="10">
                    <WebtoonMultipleUpload @imageDataChange="imageDataChange" />
                    <div class="ps_box">
                        <p class="ps">- jpg, gif 확장자만 가능합니다.</p>
                        <p class="ps">- 가로사이즈는 720px만 가능하며 세로 사이즈는 제한이 없습니다.</p>
                        <p class="ps">- 원고 이미지 1개당 용량 5MB 이하, 총 업로드한 원고는 <b>50MB</b> 이하로 제한됩니다.</p>
                        <!-- <p class="ps">- 선택 파일을 드래그하여 순서를 바꿀 수 있습니다.</p> -->
                    </div>
                </b-col>
            </b-row>
            <b-row class="notice">
                <b-col lg="2" md="2">유의사항</b-col>
                <b-col lg="10" md="10">
                    <b-form-checkbox id="agree" v-model="isAgree" name="agree" :value="true" :unchecked-value="false">아래 항목에 동의합니다</b-form-checkbox>
                    <div>
                        <p>˙불법 게시물을 등록할 경우, 게시 중단 및 삭제될 수 있습니다.</p>
                        <p @click="showTermsPopup">자세히 보기</p>
                    </div>
                </b-col>
            </b-row>
            <div class="buttons">
                <router-link tag="p" :to="'/League/Writer/Webtoon/Info/' + currWebtoonIdx" class="cancel">취소</router-link>
                <p class="next" @click="reqEpisode(false)">저장</p>
                <p class="save" @click="reqEpisode(true)">등록 신청</p>

            </div>
        </b-container>
        <PopupOK :initIsShowPopup="isShowPopupOkPopup" :initPopupOkMessage="initPopupOkMessage" @close="close('PopupOk')" />
        <PopupProgress :initIsShowPopup="isShowPopupProgress" :initProgressCurrCount="initProgressCurrCount" :initProgressMaxCount="initProgressMaxCount"/>
        <PopupConfirm :initIsShowPopup="isShowPopupConfirmPopup" :initPopupConfrimMessage="initPopupConfrimMessage" @confirmCancel="close('PopupConfirm')" @confirmOk="confirmOk" />
        <PopupLeagueTerms :initIsShowPopup="isShowPopupTerms" @close="close('PopupTerms')" />
        <Footer />
    </div>
</template>

<script>
import Header from '@/components/common/Header.vue'
import Footer from '@/components/common/Footer.vue'
import Login from '@/components/common/Login.vue'
import WebtoonMultipleUpload from '@/components/league/WebtoonMultipleUpload.vue'
import PopupOK from '@/components/common/PopupOK.vue'
import PopupProgress from '@/components/league/PopupProgress.vue'
import PopupLeagueTerms from '@/components/league/PopupLeagueTerms.vue'
import PopupConfirm from '@/components/common/PopupConfirm.vue'

export default {
    name: 'LeagueWriterEpisodeReg',
    pageCategory: 'League',
    components: {
        Header,
        Footer,
        Login,
        PopupOK,
        PopupProgress,
        WebtoonMultipleUpload,
        PopupLeagueTerms,
        PopupConfirm,
    },
    data(){
        return {
            writerInfo: null,
            currWebtoonIdx: 0,
            webtoonInfo: null,
            episodeIdx: 0,

            title: '',
            imageList: [],

            isShowPopupOkPopup: false,
            initPopupOkMessage: '',
            PopupOkCallbakPage: '',    // 확인 팝업에서 확인 버튼 눌렀을때 이동할 페이지

            isShowPopupConfirmPopup: false,
            initPopupConfrimMessage: '',
            PopupConfirmCallbakPage: '',    // 확인 팝업에서 확인 버튼 눌렀을때 이동할 페이지

            isShowPopupProgress: false,
            initProgressCurrCount: 0,
            initProgressMaxCount: 0,

            isShowPopupTerms: false,

            isComplete: false,
            isAgree: false,
            accept: 'image/jpeg,image/jpg',
            thumbnail: null,
            // thumbnailFormData: null,

            initHeaderType: 'type1',
        }
    },
    async created(){
        var isPc = this.isPc();
        if(!isPc){
            this.initPopupOkMessage = '모바일 환경에서는 감상만 가능합니다.<br/>작가 활동은 PC 환경에서 이용해주세요.'
            this.PopupOkCallbakPage = '/League';
            this.isShowPopupOkPopup = true;
            return;
        }
        // await this.mixinSiteConfigCheck();
        await this.mixinLoginCheck();

        var checkWriter = await this.checkWriter(); //작가 등록 여부 확인
        if(checkWriter === null){   //체크 에러
            this.initPopupOkMessage = '작가 정보 체크 에러'
            this.PopupOkCallbakPage = '/League';
            this.isShowPopupOkPopup = true;
            return;
        }
        else if(!checkWriter){  //작가 등록 안된 상태
            this.initPopupOkMessage = '접근 권한이 없습니다.'
            this.PopupOkCallbakPage = '/League';
            this.isShowPopupOkPopup = true;
            return;
        }

        if(this.$route.params.idx){
            this.currWebtoonIdx = Number(this.$route.params.idx);
            this.getWebtoonInfo();
            this.checkEpisodeIdx();
        }
        else{
            this.$router.push('/League')
            // this.initPopupOkMessage = '접근 권한이 없습니다.'
            // this.PopupOkCallbakPage = '/League';
            // this.isShowPopupOkPopup = true;
        }
    },
    methods:{
        async checkWriter(){
            var param = 
            {
                sign: this.$store.state.sign,
                ticket: this.$store.state.ticket,
                viewAdult: this.$store.state.isCheckedAdult ? 1: 0
            }
            return await this.$Axios.post(`${this.league_host}/challenge/writer/check`, param, { headers: { 'Pragma' : 'no-cache', 'Cache-Control': 'no-cache', 'Access-Control-Allow-Origin': '*', 'Content-type': 'application/json', }}).then(async(res) => {
                // console.log(res.data.writer);
                if (res.data.returnCode === 0) {    //작가 등록됨
                    this.writerInfo = res.data.writer;
                    return true;
                }
                else if(res.data.returnCode === 41){    //작가 등록 안됨
                    return false;
                }
                else{   //기타 에러
                    return null;
                }
            })
            .catch((error) => {
                console.log(error);
                return null;
            });
        },
        checkEpisodeIdx(){
            var param = 
            {
                sign: this.$store.state.sign,
                ticket: this.$store.state.ticket,
                viewAdult: this.$store.state.isCheckedAdult ? 1: 0,
                writerIdx: this.writerInfo.cwWriterIdx,
                wtIdx: this.currWebtoonIdx
            }
            // console.log(param);
            this.$Axios.post(`${this.league_host}/challenge/writer/webtoon/episode/find`, param, { headers: { 'Pragma' : 'no-cache', 'Cache-Control': 'no-cache', 'Access-Control-Allow-Origin': '*', 'Content-type': 'application/json', }}).then(async(res) => {
                // console.log(res.data);
                if (res.data.returnCode === 0) {    //작가 등록됨
                    this.episodeIdx = res.data.episodeIndex;
                }
            })
            .catch((error) => {
                console.log(error);
                return null;
            });
        },
        getWebtoonInfo(){
            var param = 
            {
                sign: this.$store.state.sign,
                ticket: this.$store.state.ticket,
                viewAdult: this.$store.state.isCheckedAdult ? 1 : 0,
                wtIdx: this.currWebtoonIdx,
                writerIdx: this.writerInfo.cwWriterIdx,
                wrName: this.writerInfo.cwPenName
            }
            // console.log(param)
            this.$Axios.post(`${this.league_host}/challenge/writer/webtoon`, param, { headers: { 'Pragma' : 'no-cache', 'Cache-Control': 'no-cache', 'Access-Control-Allow-Origin': '*', 'Content-type': 'application/json', }}).then(async(res) => {
                // console.log('--webtoon info--')
                // console.log(res.data);
                if (res.data.returnCode === 0) {
                    this.webtoonInfo = res.data.data;

                    // if(this.webtoonInfo.adult === 1){
                    //     if(this.$store.state.loginCheck === false){
                    //         this.initPopupOkMessage = '로그인 후 이용 가능합니다.';
                    //         this.PopupOkCallbakPage = '/';
                    //         this.isShowPopupOkPopup = true;
                    //     }
                    //     else if(this.$store.state.adult === false){
                    //         this.initPopupOkMessage = '성인 인증 후 이용 가능합니다.';
                    //         this.PopupOkCallbakPage = '/';
                    //         this.isShowPopupOkPopup = true;
                    //     }
                    // }
                }
                else if(res.data.returnCode === 300){
                    this.initPopupOkMessage = '존재하지 않는 웹툰 입니다.';
                    this.PopupOkCallbakPage = '/League';
                    this.isShowPopupOkPopup = true;
                }
                // else if(res.data.returnCode === 301){
                //     this.initPopupOkMessage = '성인 인증 후 이용 가능합니다.';
                //     this.PopupOkCallbakPage = '/';
                //     this.isShowPopupOkPopup = true;
                // }
                else{
                    this.$router.push('/League');
                }
            })
            .catch((error) => {
                console.log(error);
            });
        },
        uploadThumbnail(e) {
            let files = e.target.files || e.dataTransfer.files;
            if (!files.length) {
                return false;
            }
            let file = files[0];
            let fileName = file.name.substring(0,file.name.lastIndexOf('.'));
            let filter =  /^[ㄱ-ㅎ|가-힣|a-z|A-Z|0-9|]+$/;
            if(!filter.test(fileName)){
                this.initPopupOkMessage = '파일명은 한글, 영문, 숫자만 가능합니다.';
                this.isShowPopupOkPopup = true;
            }else{
                this.createImage(file, 208, 140);
            }
            if (document.getElementById(this.idThumbnail)) {
                document.getElementById(this.idThumbnail).value = '';
            }
        },
        createImage (file, limit_width, limit_height) {
            var that = this;
            let reader = new FileReader();
            reader.onload = (e) => {
                var image = new Image();
                image.src = e.target.result;

                image.onload = function () {
                    var height = this.height;
                    var width = this.width;
                    if (height !== limit_height || width !== limit_width) {
                        that.initPopupOkMessage = '썸네일 이미지 크기는 208*140px 로 제한됩니다.'
                        that.isShowPopupOkPopup = true;
                    } else {
                        that.thumbnail = {name: file.name, path: image.src};
                    }
                };
            }
            reader.readAsDataURL(file);
        },
        reqEpisode(isReqInspect){  //회차 수정
            if(this.title === ''){
                this.initPopupOkMessage = '제목을 입력해 주세요.'
                this.isShowPopupOkPopup = true;
                return;
            }
            if(this.thumbnail === null){
                this.initPopupOkMessage = '썸네일 이미지를 등록해 주세요.'
                this.isShowPopupOkPopup = true;
                return;
            }
            if(this.imageList.length <= 0){
                this.initPopupOkMessage = '작품 원고 이미지를 등록해 주세요.'
                this.isShowPopupOkPopup = true;
                return;
            }
            if(!this.isAgree){
                this.initPopupOkMessage = '유의사항 확인 후 항목에 동의해 주세요.'
                this.isShowPopupOkPopup = true;
                return;
            }

            if(!isReqInspect){   // 회차 저장일 경우 팝업 띄우고 종료
                this.initPopupConfrimMessage = '신규 회차 내용을 저장하시겠습니까?';
                this.PopupConfirmCallbackPage = 'newEpisode';
                this.isShowPopupConfirmPopup = true;
            } else {
                this.initPopupConfrimMessage = '신규 회차 저장 및 등록 신청하시겠습니까?<br/>2~3일의 검수 시간이 소요됩니다.';
                this.PopupConfirmCallbackPage = 'newEpisodeInspect';
                this.isShowPopupConfirmPopup = true;
            }
        },
        async reqNewEpisode(isReqInspect){
            var that = this;
            this.isShowPopupProgress = true;
            var isSuccessReqUpload = await this.reqUploadThumbnail();     //썸네일 업로드
            if(!isSuccessReqUpload){
                return;
            }

            isSuccessReqUpload = await this.reqEpisodeInfo()    //회차 정보 저장
            if(!isSuccessReqUpload){
                return;
            }
            
            var isSuccessReqImage = false;
            var pageIndex = 0;  //업로드할 원고 순서
            this.initProgressCurrCount = 0; //현재 업로드되고 있는 원고 이미지 순서 번호
            this.initProgressMaxCount = this.imageList.length;   //전체 원고 이미지 개수

            for(const image of this.imageList) {    //원고 이미지 업로드
                const file = image.formData.get('file');
                this.initProgressCurrCount++;
                isSuccessReqImage = await this.reqEpisodeImage(++pageIndex, file);
                if(!isSuccessReqImage){
                    isSuccessReqUpload = false;
                    break;
                }
            }

            this.initProgressCurrCount = 0;
            this.initProgressMaxCount = 0;

            if(!isSuccessReqUpload){    //원고 이미지 업로드 실패
                return;
            }

            if(!isReqInspect){   // 회차 저장일 경우 팝업 띄우고 종료
                setTimeout(()=>{
                    that.isShowPopupProgress = false;
                    that.initPopupOkMessage = '회차 저장 완료'
                    that.PopupOkCallbakPage = '/League/Writer/Webtoon/Info/' + this.currWebtoonIdx;
                    that.isShowPopupOkPopup = true;
                }, 3000)
                return;
            } 

            isSuccessReqUpload = this.reqEpisodeInspect();   //회차 정보&원고 이미지 업로드
            if(isSuccessReqUpload){
                setTimeout(()=>{
                    that.isShowPopupProgress = false;
                    that.initPopupOkMessage = '회차 저장 및 등록 신청 완료'
                    that.PopupOkCallbakPage = '/League/Writer/Webtoon/Info/' + this.currWebtoonIdx;
                    that.isShowPopupOkPopup = true;
                }, 3000)
            }
            else{
                this.isShowPopupProgress = false;
                this.initPopupOkMessage = '회차 저장 완료, 등록 신청 실패'
                this.PopupOkCallbakPage = '/League/Writer/Webtoon/Info/' + this.currWebtoonIdx;
                this.isShowPopupOkPopup = true;
            }
            //등록 신청일 경우 팝업 띄우고 회차 등록 신청까지 진행
        },
        async reqUploadThumbnail(){
            var fileMaxSize = 1 * 300 * 1024;

            let userUploadFile = this.$refs.files.files;
            if(userUploadFile.length <= 0){
                return false;
            }
            let file = userUploadFile[0];
            if(file.size > fileMaxSize){
                this.isShowPopupProgress = false;
                this.initPopupOkMessage = '썸네일 이미지 파일 크기가 300KB를 초과했습니다.'
                this.isShowPopupOkPopup = true;
                return false;
            }

            let formData = new FormData();
            formData.append('ticket', this.$store.state.ticket);
            formData.append('sign', this.$store.state.sign);
            formData.append('viewAdult', this.$store.state.isCheckedAdult ? 1 : 0);
            formData.append('webtoonkey',  this.webtoonInfo.ctCdnFolder);
            formData.append('writerIdx',  this.writerInfo.cwWriterIdx);
            formData.append('uploadType',  3);
            formData.append('episodeNumber',  this.episodeIdx);
            formData.append('file', file);

            // console.log(this.$store.state.ticket);
            // console.log(this.$store.state.sign);
            // console.log(this.$store.state.isCheckedAdult ? 1 : 0);
            // console.log(this.webtoonInfo.ctCdnFolder);
            // console.log(this.writerInfo.cwWriterIdx);
            // console.log(this.episodeIdx);

            return await this.$Axios.post(`${this.league_host}/challenge/writer/imageUpload/thumbnail`, formData, { headers: { 'Pragma' : 'no-cache', 'Cache-Control': 'no-cache', 'Access-Control-Allow-Origin': '*', 'Content-type': 'multipart/form-data', }}).then(async(res) => {
                // console.log(res);
                if (res.data.returnCode === 0) {
                    return true;
                }
                else if (res.data.returnCode === 609) {
                    this.isShowPopupProgress = false;
                    this.initPopupOkMessage = '썸네일 이미지 크기는 208*140px 로 제한됩니다.'
                    // this.PopupOkCallbakPage = '/League/Writer/Webtoon/Info/' + this.currWebtoonIdx;
                    this.isShowPopupOkPopup = true;
                    return false;
                }
                else{
                    this.isShowPopupProgress = false;
                    this.initPopupOkMessage = '썸네일 이미지 업로드 실패'
                    this.isShowPopupOkPopup = true;
                    return false;
                }
            })
            .catch((error) => {
                console.log(error);
                this.isShowPopupProgress = false;
                this.initPopupOkMessage = '썸네일 이미지 업로드 실패'
                this.isShowPopupOkPopup = true;
                return false;
            });
        },
        // async reqUploadEpisode(){
        //     let formData = new FormData();
        //     formData.append('ticket', this.$store.state.ticket);
        //     formData.append('sign', this.$store.state.sign);
        //     formData.append('viewAdult', this.$store.state.isCheckedAdult ? 1 : 0);
        //     formData.append('writerIdx',  this.writerInfo.cwWriterIdx);
        //     formData.append('wtIdx',  this.currWebtoonIdx);
        //     formData.append('epIdx',  this.episodeIdx);
        //     formData.append('complete',  this.complete ? 1 : 0);
        //     formData.append('epTitle',  this.title);
        //     this.imageList.forEach(item=>{
        //         formData.append('file', item.formData.get('file'));
        //     })


        //     // console.log(fileList);

        //     // console.log(this.$store.state.ticket);
        //     // console.log(this.$store.state.sign);
        //     // console.log(this.$store.state.isCheckedAdult ? 1 : 0);
        //     // console.log(this.webtoonInfo.ctCdnFolder);
        //     // console.log(this.writerInfo.cwWriterIdx);
        //     // console.log(this.episodeIdx);

        //     return await this.$Axios.post(`${this.league_host}/challenge/writer/webtoon/episode/req`, formData, { headers: { 'Pragma' : 'no-cache', 'Cache-Control': 'no-cache', 'Access-Control-Allow-Origin': '*', 'Content-type': 'multipart/form-data', }}).then(async(res) => {
        //         // console.log(res);
        //         if (res.data.returnCode === 0) {
        //             return true;
        //         }
        //         else if (res.data.returnCode === 609) {
        //             this.isShowPopupProgress = false;
        //             this.initPopupOkMessage = '회차 저장 실패<br/>원고 이미지 가로 사이즈는 720px 로 제한됩니다.'
        //             // this.PopupOkCallbakPage = '/League/Writer/Webtoon/Info/' + this.currWebtoonIdx;
        //             this.isShowPopupOkPopup = true;
        //             return true;
        //         }
        //         else{
        //             this.isShowPopupProgress = false;
        //             this.initPopupOkMessage = '회차 저장 실패'
        //             this.isShowPopupOkPopup = true;
        //             return false;
        //         }
        //     })
        //     .catch((error) => {
        //         console.log(error);
        //         this.isShowPopupProgress = false;
        //         // this.initPopupOkMessage = '회차 등록 실패'
        //         // this.isShowPopupOkPopup = true;
        //         return false;
        //     });

        // },
        async reqEpisodeInfo(){
            var param = 
            {
                sign: this.$store.state.sign,
                ticket: this.$store.state.ticket,
                viewAdult: this.$store.state.isCheckedAdult ? 1 : 0,

                writerIdx: this.writerInfo.cwWriterIdx,
                wtIdx: this.currWebtoonIdx,
                epIdx: this.episodeIdx,
                epTitle: this.title,
                complete: this.complete ? 1: 0,

                
            }
            // console.log(param);
            return await this.$Axios.post(`${this.league_host}/challenge/writer/webtoon/episode/reqInfo`, param, { headers: { 'Pragma' : 'no-cache', 'Cache-Control': 'no-cache', 'Access-Control-Allow-Origin': '*', 'Content-type': 'application/json', }}).then(async(res) => {
                // console.log('--req episode info--')
                // console.log(res.data);
                if (res.data.returnCode === 0) {
                    return true;
                }
                else{
                    this.isShowPopupProgress = false;
                    this.initPopupOkMessage = '회차 정보 저장 실패'
                    this.isShowPopupOkPopup = true;
                    return false;
                }
                // else if(res.data.returnCode === 300){
                //     this.initPopupOkMessage = '존재하지 않는 웹툰 입니다.';
                //     this.PopupOkCallbakPage = '/';
                //     this.isShowPopupOkPopup = true;
                // }
                // else{
                //     // console.log(5);
                //     this.$router.push('/');
                // }
            })
            .catch((error) => {
                console.log(error);
                return false;
            });
        },
        async reqEpisodeImage(pageIndex, file){
            let formData = new FormData();
            formData.append('ticket', this.$store.state.ticket);
            formData.append('sign', this.$store.state.sign);
            formData.append('viewAdult', this.$store.state.isCheckedAdult ? 1 : 0);
            formData.append('writerIdx',  this.writerInfo.cwWriterIdx);
            formData.append('wtIdx',  this.currWebtoonIdx);
            formData.append('epIdx',  this.episodeIdx);
            formData.append('pageIndex',  pageIndex);
            formData.append('file', file);
            
            // this.imageList.forEach(item=>{
            //     formData.append('file', item.formData.get('file'));
            // })

            return await this.$Axios.post(`${this.league_host}/challenge/writer/webtoon/episode/reqImage`, formData, { headers: { 'Pragma' : 'no-cache', 'Cache-Control': 'no-cache', 'Access-Control-Allow-Origin': '*', 'Content-type': 'multipart/form-data', }}).then(async(res) => {
                // console.log(res);
                if (res.data.returnCode === 0) {
                    return true;
                }
                else if (res.data.returnCode === 610){
                    this.isShowPopupProgress = false;
                    this.initPopupOkMessage = '업로드 가능한 용량을 초과 했습니다. (회차별 최대 용량 : 50MB)';
                    this.isShowPopupOkPopup = true;
                    return false;
                }
                else{
                    this.isShowPopupProgress = false;
                    this.initPopupOkMessage = '원고 이미지 업로드 실패<br/>( '+ file.name +' )';
                    this.isShowPopupOkPopup = true;
                    return false;
                }
            })
            .catch((error) => {
                console.log(error);
                return false;
            });

        },
        async reqEpisodeInspect(){
            var param = 
            {
                sign: this.$store.state.sign,
                ticket: this.$store.state.ticket,
                viewAdult: this.$store.state.isCheckedAdult ? 1 : 0,

                wtIdx: this.currWebtoonIdx,
                epIdx: this.episodeIdx,
                writerIdx: this.writerInfo.cwWriterIdx,
            }
            // console.log(param);
            return await this.$Axios.post(`${this.league_host}/challenge/writer/episode/inspect`, param, { headers: { 'Pragma' : 'no-cache', 'Cache-Control': 'no-cache', 'Access-Control-Allow-Origin': '*', 'Content-type': 'application/json', }}).then(async(res) => {
                // console.log('--episode inspect--')
                // console.log(res.data);
                if (res.data.returnCode === 0) {
                    return true;
                }
                else{
                    return false;
                }
                // else if(res.data.returnCode === 300){
                //     this.initPopupOkMessage = '존재하지 않는 웹툰 입니다.';
                //     this.PopupOkCallbakPage = '/';
                //     this.isShowPopupOkPopup = true;
                // }
                // else{
                //     // console.log(5);
                //     this.$router.push('/');
                // }
            })
            .catch((error) => {
                console.log(error);
                return false;
            });
        },
        imageDataChange(imageList) {
            this.imageList = imageList;
            // console.log('data', imageList);
            // Upload image api
            // axios.post('http://gostore.gostore-api.test/api/items/upload', formData).then(response => {
            //   console.log(response)
            // })
        },
        checkTitle(){
            var filter = /[^a-z|A-Z|0-9|ㄱ-ㅎ|가-힣|\-|(),._!:/'"?|\s+]/g;
            this.title = this.title.replace(filter, "");
        },
        close(popupType){
            switch(popupType){
                case 'PopupTerms':
                    this.isShowPopupTerms = false;
                    break;
                case 'PopupConfirm':
                    this.isShowPopupConfirmPopup = false;
                    break;
                case 'PopupOk':
                    this.isShowPopupOkPopup = false;
                    if(this.PopupOkCallbakPage === 'reload'){
                        location.reload();
                    }
                    else if(this.PopupOkCallbakPage !== ''){
                        this.$router.push(this.PopupOkCallbakPage);
                    }
                    this.PopupOkCallbakPage = '';
                    break;
            }
        },
        confirmOk(){
            if(this.PopupConfirmCallbackPage === 'newEpisode'){
                this.reqNewEpisode(false);
            } else if(this.PopupConfirmCallbackPage === 'newEpisodeInspect'){
                this.reqNewEpisode(true);
            }
            this.isShowPopupConfirmPopup = false;
        },
        showTermsPopup(){
            this.isShowPopupTerms = true;
        },
    }
}
</script>