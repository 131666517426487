<template>
    <div class="declaration" v-if="initIsShowPopup && initWebtoonInfo !== null && initEpisodeState !== null">
        <div class="declaration_box">
            <img src="@/assets/images/close.svg" alt="close" class="close" @click="closePopup">
            <p class="title">신고하기</p>
            <div class="info">
                <p class="name">{{initWebtoonInfo.ctTitleName}} {{initEpisodeState.ceEpisodeIdx}}</p>
                <p class="ad">{{url}}</p>
            </div>
            <b-row class="item">
                <b-col lg="4" sm="6" cols="6">
                    <b-form-radio v-model="selectedType" value="0" name="some-radios" >욕설,인신공격</b-form-radio>
                </b-col>
                <b-col lg="4" sm="6" cols="6">
                    <b-form-radio v-model="selectedType" value="1" name="some-radios" >광고,도배</b-form-radio>
                </b-col>
                <b-col lg="4" sm="6" cols="6">
                    <b-form-radio v-model="selectedType" value="2" name="some-radios" >음란성,선정성</b-form-radio>
                </b-col>
                <b-col lg="4" sm="6" cols="6">
                    <b-form-radio v-model="selectedType" value="3" name="some-radios" >개인정보 노출</b-form-radio>
                </b-col>
                <b-col lg="4" sm="6" cols="6">
                    <b-form-radio v-model="selectedType" value="4" name="some-radios" >부적절한 이름</b-form-radio>
                </b-col>
                <b-col lg="4" sm="6" cols="6">
                    <b-form-radio v-model="selectedType" value="5" name="some-radios" >기타</b-form-radio>
                </b-col>
            </b-row>
            <div class="typing">
                <b-textarea placeholder="신고 내용을 입력해 주세요." v-model="desc" :state="desc.length >= 1"></b-textarea>
            </div>
            <p class="button" @click="submitReport">신고하기</p>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Grade',
    data(){
        return {
            url : '',
            selectedType: '5',
            desc : '',
        }
    },
    props: {
        initIsShowPopup: {
            type: Boolean,
            default: function(){
                return false;
            }
        },
        initWebtoonInfo: {
            type: Object,
            default: null
        },
        initEpisodeState: {
            type: Object,
            default: null
        },
    },
    created() {
        this.url = window.location.href;
    },
    mounted(){
    },
    beforeDestroy() {
	},
    watch:{
        initIsShowPopup(){
            if(this.initIsShowPopup){
                if(this.initStarPoint !== null){
                    this.starGrade = this.initStarPoint;
                }
            }
        }
    },
    methods: {
        closePopup(){
            this.url = '';
            this.type = 0;
            this.desc = '',
            this.$emit('close');
        },
        overlapReport(){
            this.$emit('overlapReport');
        },
        successReport(){
            this.url = '';
            this.type = 0;
            this.desc = '',
            this.$emit('successReport');
        },
        submitReport(){     //신고하기

            var param = 
            {
                wtIdx: this.initEpisodeState.ceWebtoonIdx,
                epIdx: this.initEpisodeState.ceEpisodeIdx,
                sign: this.$store.state.sign,
                ticket: this.$store.state.ticket,
                viewAdult: this.$store.state.isCheckedAdult ? 1: 0,
                url: this.url,
                type: this.selectedType,
                desc: this.desc,
            }
            console.log(param);
            this.$Axios.post(`${this.league_host}/challenge/report`, param, { headers: { 'Pragma' : 'no-cache', 'Cache-Control': 'no-cache', 'Access-Control-Allow-Origin': '*', 'Content-type': 'application/json', }}).then(async(res) => {
                console.log(res.data);
                if (res.data.returnCode === 0) {
                    this.successReport();
                }
                else if(res.data.returnCode === 553){
                    this.overlapReport();
                }
                else{
                    this.closePopup();
                    console.log(res.data.description);
                }
            })
            .catch((error) => {
                console.log(error);
            });
        },
    },
}
</script>