<template>
    <div class="leagueevent wrap winner" >
    <Header :initHeaderType="initHeaderType" />
    <div class="container">
        <img src="../../assets/images/event/winnerbanner.png" class="winner-cover" >
        <div class="winner-event">

            <div class="winner-title-list row">
                <a class="col-3" @click="moveScroll(0)">대상</a>
                <a class="col-3" @click="moveScroll(1)">최우수상</a>
                <a class="col-3" @click="moveScroll(2)">우수상</a>
                <a class="col-3" @click="moveScroll(4)">장려상</a>
                <img src="../../assets/images/event/winner-bottom.png">
            </div>

            <div class="info" v-for=" (item,idx) in author" :key="idx" >
                <div class="winner-title" :id="'section-' + idx">
                    <img :src="item.winnerUrl">
                </div>
                <div class="winner-card" >
                    <div class="card-box row" >
                        <div class="card-inner col-6 col-sm-4">
                            <img :src="item.winnerPic" >
                        </div>
                        <div class="card-list col-6 col-sm-8">
                            <h3>{{item.title}}</h3>
                            <h4>작가 : {{item.name}}</h4>
                            <span v-for='(contents,i) in item.contents' :key="i">{{item.contents[i]}}</span>
                            <p class="explain">{{item.explain}}</p>
                        </div>
                        <div class="col-12 link">
                            <router-link :to="item.moreLink"><img class="winner-more" src="../../assets/images/event/winner-more.png"></router-link>
                        </div>
                    </div>   
                </div>
                <div class="winner-comment">
                    <div class="winner-text1">
                        <img src="../../assets/images/event/winner-rate.png" >
                        <p class="">심사평</p>
                    </div>
                    <div class="winner-text2">
                        <p class="">{{item.comments}}</p>
                    </div>
                </div>
            </div>
            <div class="change-ment">
                <p>※작가 개인 사정으로  장려상 1편은 수상이 취소되었음을 알려드립니다.</p>
            </div>
            <div class="winner-row">
                <h2>심사위원소개</h2>
                <div class="winner-row-inner">
                    <div class="row ">
                        <div class="col-md-4 col-3 publisher"><p>정기영 작가</p></div>
                        <div class="col-md-8 col-9 winner-right-text">
                            <p>(현) 스토리협회 회장</p>
                            <p>(현) 스토리 작가</p>
                            <p>대표작 : &lt;총수>, &lt;총수 비기닝>, &lt;오늘은 마요일>, &lt;떴다 오도리>, &lt;포커 페이스> 외 다수 인기 작품을 내놓고 있음.</p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-4 col-3 publisher"><p>이준형 작가</p></div>
                        <div class="col-md-8 col-9 winner-right-text">
                            <p>(현)웹툰 코이 스튜디오 대표</p>
                            <p>(현)웹툰 작가</p>
                            <p>이현세 화백의 문하생,스텝으로 활동하며 '천국의 신화'등 다수 작품에 참여.</p>
                            <p class="rtbt">2015년부터 웹툰 코이컨텐츠 스튜디오를 운영하며 다수의 히트작을 내놓고 있음.</p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-4 col-3 publisher"><p>송인석 본부장</p></div>
                        <div class="col-md-8 col-9 winner-right-text">
                            <p>(전)웹툰 플랫폼 투믹스 편집장</p>
                            <p>(현)웹툰 제작사 테이크원스튜디오 본부장</p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="winner-row wrsecond">
                <p class="last-ment">뜨거운 관심으로 지원해주신 모든 작가분들에게 감사 드리며<br class="pc-br"> 많은 작품 가운데 어렵게 수상작을 선정하게 되었습니다. <br>다시 한 번 성원에 감사드리며 더욱 좋은 콘텐츠로 <br class="pc-br">보답 드리는 아튜브가 되겠습니다. </p>
            </div>
        </div>
    </div>
    <Footer />
    </div>
</template>

<script>
import Footer from '@/components/common/Footer.vue'
import Header from '@/components/common/Header.vue'

export default {
    pageTitle: '이벤트',
    components: { 
        Header,
        Footer 
    },
    created(){
    },
    data(){
        return{
            initHeaderType: 'type2',

            protocol:'',
            host:'',
            author:[
                {////대상////
                    winnerPic: require('@/assets/images/event/winner-1st.png'),
                    winnerUrl:require('@/assets/images/event/winner1.png'),
                    title:'KICK',
                    name:'MAS',
                    contents:['스포츠','액션'],
                    explain:'수련을 통해 몸을 단련하고 기술을 연마해서 강해질 줄만 알았던 주인공은 체육관 안에서 여러 가지 문제들을 겪고 이겨 내며 결국엔 챔피언의 자리가 눈 앞으로 다가오는데…',
                    comments:'스토리 및 소재도 좋고, 특히 작화력과 연출력이 매우 탁월하다고 보여집니다.무에타이 스포츠가 생생하게 전달되는 액션 연출과 타격감이 훌륭하고, 이야기 전개가 매우 빠르고 흡인력 있는 좋은 작품입니다.',
                    moreLink: '/Webtoon/Info/90001',
                    bgImg:require('@/assets/images/event/winner-bg.png'),
                },
                {////최우수상////
                    winnerPic:require('@/assets/images/event/winner-2nd.png'),
                    winnerUrl:require('@/assets/images/event/winner2.png'),
                    title:'THE Endless',
                    name:'이시호',
                    contents:['스릴러','미스테리'],
                    explain:'‘메른 라이트‘는 처음 보는 마을에서 사람들에게 쫓기고 있다. 마을의 오기 전 그의 마지막 기억은 자신의 부인과의 대화였다. 아내는 마지막 말은 이랬다.“이제는 돌아갈 시간이에요.“',
                    comments:'작품의 스토리 및 소재 연출 및 작화 디자인 등 모든 면에서 매우 훌륭한 작품으로 생각됩니다. 특히 장르적인 분위기와 매우 잘어울리며, 특히 스토리 전개시 마치 게임속에 들어와있는 듯한 공간에 대한 연출이 매우 탁월하며 작품에 빠져들게 하는 매력있는 작품입니다.',
                    moreLink: '/Webtoon/Info/90002',
                    bgImg:require('@/assets/images/event/winner-bg.png'),
                },
                {////우수상////
                    winnerPic:require('@/assets/images/event/winner-3rd.png'),
                    winnerUrl:require('@/assets/images/event/winner3.png'),
                    title:'양치기 소녀들',
                    name:'쫘룰',
                    contents:['성장','드라마'],
                    explain:'가족들에 의해 억울하게 정신병원에 입원하게 된 소녀 ‘신아람’, 그러다가 우연히 같은 정신병원 환자인 ‘한예나’ 가 자신과 같다는 것을 알게 되는데…',
                    comments:'스토리 라인이 매우 짜임새 있고 자칫 지루할 수 있는 이야기를 지루하지 않게 풀어나가는 연출력과 화면 구성력이 좋습니다.플랫폼의 간판작가로 내세우기에 좋은 역량을 가진 작가로 보여집니다.',
                    moreLink: '/Webtoon/Info/90003',
                    bgImg:require('@/assets/images/event/winner-bg.png'),
                },
                {////우수상////
                    winnerPic:require('@/assets/images/event/winner-4th.png'),
                    winnerUrl:require('@/assets/images/event/winner3.png'),
                    title:'그리마 콘텐츠',
                    name:'강희석',
                    contents:['스릴러','판타지'],
                    explain:'벌레를 소재로 자극적인 방송을 하던 BJ 범례. 어느날 우연한 사고를 계기로 자신이 돈벌레 인간이 된걸 깨닫게 되는데…',
                    comments:'일상의 평범할 수 있는 소재를 참신한 아이디어로 새롭게 접근한 방식이 매우 독특합니다. 화면 구성과 호흡도 적절하며 단순하지만 강렬하고 눈에 들어오는 작화 수준도 좋은 작품입니다.',
                    moreLink: '/Webtoon/Info/90004',
                    bgImg:require('@/assets/images/event/winner-bg.png'),
                },
                {////장려상////
                    winnerPic:require('@/assets/images/event/winner-5th.png'),
                    winnerUrl:require('@/assets/images/event/winner4.png'),
                    title:'충성의 대가',
                    name:'김민관',
                    contents:['느와르','복수극'],
                    explain:'자신의 여동생을 성폭행한 대한민국 성범죄 카르텔인 ‘아틀란티스’라는 기업에게 복수를 결심한 국정원 출신의 언니 이야기',
                    comments:'작품의 장르 및 설정 등이 트렌디하며 대중적인 작품입니다. 성폭행이라는 사건 및 N번방 이슈는 자칫 위험하고 논쟁을 불러 일으킬 수 있는 소재이지만, 앞으로 어떻게 전개해나갈지 귀추가 궁금한 작품입니다.',
                    moreLink: '/Webtoon/Info/90005',
                    bgImg:require('@/assets/images/event/winner-bg.png'),
                },
                {////장려상////
                    winnerPic:require('@/assets/images/event/winner-6th.png'),
                    winnerUrl:require('@/assets/images/event/winner4.png'),
                    title:'신을 다루는 소녀',
                    name:'후하다',
                    contents:['드라마','판타지'],
                    explain:'인간 ‘신다운’ 과 신’이묵희’ 가 힘든 삶을 살고 있는 인간을 도우며, 숨어사는 신들을 찾고 어지럽혀진 세상을 바로 잡는 판타지 드라마.',
                    comments:'인간과 신의 이야기, 그리고 세계관 등 이야기 구조와 전개는 흥미롭고 앞으로 기대가 됩니다. 다만, 초반부 연출이 이야기에 집중하기에 흡인력이 약해 다소 아쉽습니다.',
                    moreLink: '/Webtoon/Info/90006',
                    bgImg:require('@/assets/images/event/winner-bg.png'),
                },
                {////장려상////
                    winnerPic:require('@/assets/images/event/winner-7th.png'),
                    winnerUrl:require('@/assets/images/event/winner4.png'),
                    title:'그리스몽키',
                    name:'비둘기야 & miri',
                    contents:['스토리','판타지'],
                    explain:'시계탑이 무너져 시간이 멈춘 세상에서 몸이 토막난 기관사와 특별한 능력을 가진 수리공이 시간을 다시 흐르게 하기위해 시계탑을 고치러 가는데…',
                    comments:'기본기가 탄탄함이 느껴지는 작품입니다. 액션씬 연출과 화면 구성은 무난합니다. 다만 하이라이트 씬등에서 디테일이 다소 약하고 임팩트가 약하다고 보여져 전체적으로 작품의 흥미를 더 할 수 있을텐데 하는 아쉬움이 남습니다.',
                    moreLink: '/Webtoon/Info/90007',
                    bgImg:require('@/assets/images/event/winner-bg.png'),
                },
            ],
        }
        

    },
    methods:{
        moveScroll(no){
            var pointY = document.getElementById('section-' + no).offsetTop;
            window.scrollTo(0, pointY);
        }
    },
}
</script>

<style>

</style>