<template>
    <div>
        <div id="buy" v-if="initIsShowPopup && initEpisodeInfo !== null && !isShowPopupOkPopup">
            <div class="box">
                <img src="@/assets/images/icon_close.svg" alt="close" class="close" @click="closePopup">
                <h1>작품 소장</h1>
                <img :src="initSiteConfig.cdnUrl +'/KR/'+initEpisodeInfo.url" alt="표지" class="cover" v-if="initSiteConfig !== null">
                <h2>{{initWebtoonInfo.titleName}}</h2>
                <p class="number">{{initEpisodeInfo.titleName}}</p>
                <div class="buttons">
                    <!-- <div class="button1" @click="rental">
                        <p>대여(3일)<span>{{initEpisodeInfo.rentalAfterPrice}} 아트포인트</span></p>
                    </div> -->
                    <div class="button2" @click="buy">
                        <p>평생소장<span>{{initEpisodeInfo.buyPrice}} 아트포인트</span></p>
                    </div>
                </div>
                <p class="watch">* 열람하지 않은 에피소드에 대해서만 구매 후 7일 이내 청약철회가 가능합니다.</p>
            </div>
        </div>
        <PopupOK :initIsShowPopup="isShowPopupOkPopup" :initPopupOkMessage="initPopupOkMessage" @close="close('PopupOk')" />
    </div>
</template>

<script>
import PopupOK from '@/components/common/PopupOK.vue'

export default {
    name: 'Buy',
    components: {
        PopupOK,
    },
    data(){
        return {
            isShowPopupOkPopup: false,
            initPopupOkMessage: '',
            PopupOkCallbakPage: '',    // 확인 팝업에서 확인 버튼 눌렀을때 이동할 페이지
        }
    },
    props: {
        initIsShowPopup: {
            type: Boolean,
            default: false
        },
        initWebtoonInfo: {
            type: Object,
            default: null
        },
        initEpisodeInfo: {
            type: Object,
            default: null
        },
        initSiteConfig: {
            type: Object,
            default: null
        },
    },
    created() {
    },
    mounted(){
    },
    beforeDestroy() {
	},
    methods: {
        closePopup(){
            this.$emit('close');
        },
        // rental(){
        //     var total_my_point = 0;
        //     total_my_point += this.$store.state.pgPoint;
        //     total_my_point += this.$store.state.pgBounsPoint;

        //     if(total_my_point <= 0){    //소유 포인트가 없을 경우
        //         this.$router.push('/Point/Charge');
        //     }
        //     else if(total_my_point < this.initEpisodeInfo.rentalAfterPrice){    //소유 포인트가 모자랄 경우
        //         this.$router.push('/Point/Charge');
        //     }
        //     else{   //대여
        //         var param = 
        //         {
        //             epidx: this.initEpisodeInfo.episodeIdx,
        //             sign: this.$store.state.sign,
        //             ticket: this.$store.state.ticket,
        //             wtidx: this.initEpisodeInfo.webtoonIdx,
        //             viewAdult: this.$store.state.isCheckedAdult ? 1: 0
        //         }
        //         this.$Axios.post(`${this.host}/member/rental`, param, { headers: { 'Pragma' : 'no-cache', 'Cache-Control': 'no-cache', 'Access-Control-Allow-Origin': '*', 'Content-type': 'application/json', }}).then(async(res) => {
        //             if (res.data.returnCode === 0) {
        //                 this.$router.push('/Webtoon/Viewer/'+ this.initEpisodeInfo.webtoonIdx + '/' + this.initEpisodeInfo.episodeIdx);
        //             }else if(res.data.returnCode === 150){
        //                 this.$router.push('/Webtoon/Viewer/'+ this.initEpisodeInfo.webtoonIdx + '/' + this.initEpisodeInfo.episodeIdx);
        //             }else{
        //                 console.log(res.data.returnCode);
        //             }
        //         })
        //         .catch((error) => {
        //             console.log(error);
        //         });

        //     }
        // },
        buy(){
            var total_my_point = 0;
            total_my_point += this.$store.state.pgPoint;
            total_my_point += this.$store.state.pgBounsPoint;
            total_my_point += this.$store.state.attPoint;
            total_my_point += this.$store.state.attBounsPoint;

            if(total_my_point < this.initEpisodeInfo.buyAfterPrice){
                this.initPopupOkMessage = '아트포인트가 부족합니다.';
                this.PopupOkCallbakPage = '/Point/Charge';
                this.isShowPopupOkPopup = true;
            }
            else{   //구매
                var param = 
                {
                    epidx: this.initEpisodeInfo.episodeIdx,
                    sign: this.$store.state.sign,
                    ticket: this.$store.state.ticket,
                    wtidx: this.initEpisodeInfo.webtoonIdx,
                    viewAdult: this.$store.state.isCheckedAdult ? 1: 0
                }
                // console.log(param);
                this.$Axios.post(`${this.host}/member/buy`, param, { headers: { 'Pragma' : 'no-cache', 'Cache-Control': 'no-cache', 'Access-Control-Allow-Origin': '*', 'Content-type': 'application/json', }}).then(async(res) => {
                    // console.log(res.data);
                    if (res.data.returnCode === 0) {
                        // this.initPopupOkMessage = '작품 소장이 완료됐습니다..';
                        // this.PopupOkCallbakPage = '/Webtoon/Viewer/'+ this.initEpisodeInfo.webtoonIdx + '/' + this.initEpisodeInfo.episodeIdx;
                        // this.isShowPopupOkPopup = true;
                        this.$router.push('/Webtoon/Viewer/'+ this.initEpisodeInfo.webtoonIdx + '/' + this.initEpisodeInfo.episodeIdx);
                    }else if(res.data.returnCode === 100){
                        this.initPopupOkMessage = '이미 소장 중입니다.';
                        this.PopupOkCallbakPage = '/Webtoon/Viewer/'+ this.initEpisodeInfo.webtoonIdx + '/' + this.initEpisodeInfo.episodeIdx;
                        this.isShowPopupOkPopup = true;
                        // this.$router.push('/Webtoon/Viewer/'+ this.initEpisodeInfo.webtoonIdx + '/' + this.initEpisodeInfo.episodeIdx);
                    }
                    else if(res.data.returnCode === 104){
                        this.initPopupOkMessage = '아트포인트가 부족합니다.';
                        this.PopupOkCallbakPage = '/Point/Charge';
                        this.isShowPopupOkPopup = true;
                    }
                    else if(res.data.returnCode === 300){
                        this.initPopupOkMessage = '해당 작품은 운영상의 문제에 위반되어 열람 및 구매 할 수 없습니다.';
                        this.PopupOkCallbakPage = '';
                        this.isShowPopupOkPopup = true;
                    }
                    else if(res.data.returnCode === 353){
                        this.initPopupOkMessage = '회당 회차는 운영상의 문제에 위반되어 열람 및 구매 할 수 없습니다.';
                        this.PopupOkCallbakPage = '';
                        this.isShowPopupOkPopup = true;
                    }
                    else{
                        this.initPopupOkMessage = '회차 소장이 실패 했습니다.';
                        this.PopupOkCallbakPage = 'reload';
                        this.isShowPopupOkPopup = true;
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
            }
        },
        buySuccess(){

        },
        close(popupType){
            switch(popupType){
                case 'PopupOk':
                    if(this.PopupOkCallbakPage !== ''){
                        this.$router.push(this.PopupOkCallbakPage);
                    }
                    this.isShowPopupOkPopup = false;
                    this.PopupOkCallbakPage = '';
                    break;
            }
        },
    },
}
</script>