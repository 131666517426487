<template>
    <div class="paging" v-if="initPagingData !== null && totalPageCount > 0">
        <a @click="movePaging(firstPageNo)" class="paging-first"><i class="icon icon-paging-first" /></a>
        <a @click="movePaging(prevPageNo)" class="paging-left"><i class="icon icon-paging-left" /></a>
        <div class="full-page-list"><a v-for="(item, index) in pageList" :key="index" :class="{'current' : item === initPagingData.pageNo}" @click="movePaging(item)">{{item}}</a></div>
        <p class="simple-page"><span class="curr-page">{{initPagingData.pageNo}}</span> / <span class="total-page">{{totalPageCount}}</span></p>
        <a @click="movePaging(nextPageNo)" class="paging-right"><i class="icon icon-paging-right" /></a>
        <a @click="movePaging(lastPageNo)" class="paging-last"><i class="icon icon-paging-last" /></a>
    </div>
</template>

<script>
export default {
    name: 'Pagination',
    components: {
    },
    data() {
        return {
            pageGroupFirstNo: 0,  //화면에 표시될 페이지 그룹의 첫 페이지 번호
            pageGroupLastNo: 0,   //화면에 표시될 페이지 그룹의 마지막 페이지 번호

            firstPageNo: 1,     //첫 페이지 번호
            lastPageNo: 0,      //마지막 페이지 번호
            
            prevPageNo: 0,      //이전 페이지 그룹의 마지막 페이지 번호
            nextPageNo: 0,      //다음 페이지 그룹의 첫 페이지 번호
            
            totalPageCount: 0,  //총 페이지 개수
            currPageGroup: 0,   //현재 표시될 페이지 그룹 번호(1~10 : 1그룹, 11~20 : 2그룹...)
        }
    },
    props: {
        initMaxRecordCount: {   //한 화면에 보여질 데이터 수
            type: Number,
            default: 10
        },
        initMaxPageCount: {     //한 화면에 보여질 페이지 수
            type: Number,
            default: 10
        },
        initPagingData : {
            type: Object,
            default: null
        },
    },
    computed:{
        pageList(){
            var page_list = [];
            for(var i = this.pageGroupFirstNo; i <= this.pageGroupLastNo; i++){
                page_list.push(i);
            }
            return page_list;
        }
    },
    watch:{
        initPagingData(){
            if(this.initPagingData !== null){
                this.totalPageCount = Math.ceil(this.initPagingData.totalRecordSize / this.initMaxRecordCount);
                this.currPageGroup = Math.ceil(this.initPagingData.pageNo / this.initMaxPageCount);
                // console.log('totalPageCount : ' + this.totalPageCount);
                // console.log('currPageGroup : ' + this.currPageGroup);
                
                this.pageGroupLastNo = this.currPageGroup * this.initMaxPageCount;
                if(this.pageGroupLastNo > this.totalPageCount) this.pageGroupLastNo = this.totalPageCount;
                
                this.pageGroupFirstNo = (this.currPageGroup * this.initMaxPageCount) - (this.initMaxPageCount - 1);
                if(this.pageGroupFirstNo <= 0) this.pageGroupFirstNo = 1;

                // console.log('pageGroupLastNo : ' + this.pageGroupLastNo);
                // console.log('pageGroupFirstNo : ' + this.currPageGroup);

                // 이전(<), 다음(>) 버튼 눌렀을때 페이지 그룹 이동 시킬떄
                // this.prevPageNo = this.pageGroupFirstNo - 1;  
                // this.nextPageNo = this.pageGroupLastNo + 1;  
                //if(this.prevPageNo <= 0) this.prevPageNo = this.initPagingData.pageNo;
                //if(this.nextPageNo > this.totalPageCount) this.nextPageNo = this.initPagingData.pageNo;

                // 이전(<), 다음(>) 버튼 눌렀을때 현재 페이지 기준 +- 1페이지씩 이동 시킬때
                this.prevPageNo = this.initPagingData.pageNo - 1;
                this.nextPageNo = this.initPagingData.pageNo + 1; 
                if(this.prevPageNo <= 0) this.prevPageNo = this.initPagingData.pageNo;
                if(this.nextPageNo > this.totalPageCount) this.nextPageNo = this.totalPageCount;

                // console.log('prevPageNo : ' + this.prevPageNo);
                // console.log('nextPageNo : ' + this.nextPageNo);

                this.lastPageNo = this.totalPageCount;
            }
        }
    },
    methods: {
        movePaging(pageNo){
            if(this.initPagingData.pageNo === pageNo){
                return;
            }
            else {
                this.$emit('movePaging', pageNo);
            }
        },
    }
}
</script>