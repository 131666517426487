<template>
    <div class="category wrap">
        <Header :initIsShowCategory="initIsShowCategory" :initCategoryIdx="currCategoryIdx" @selectCategory="selectCategory" :initHeaderType="initHeaderType" />
        <div class="menu">
            <div class="container">
                <ul>
                    <li v-for="(item, index) in categoryList" :key="index" :class="{'on' : currCategoryIdx === item.idx}">
                        <router-link tag="a" :to="'/Category/' + item.idx+ '/' + searchText">
                            {{item.name}}
                        </router-link>
                    </li>
                </ul>
            </div>
        </div>
        <b-container>
            <!-- <select>
                <option>인기순</option>
                <option>업데이트순</option>
            </select> -->
            <div class="search_line">
                <input type="text" v-model="searchText" placeholder="작품/작가명을 입력해주세요" @keyup.enter="search" id="inputSearch">
                <img src="@/assets/images/icon_search_cancel.svg" v-if="searchText.length > 0" @click="searchText = ''" class="btn-clear"/>
                <img src="@/assets/images/icon_search_black.svg" class="btn-search" @click="search" />
            </div>
            <p class="total">총 {{totalRecordSize}} 작품</p>
            <p class="no-search" v-if="totalRecordSize <= 0">검색된 작품이 없습니다.</p>
            <b-row tag="div" cols="5" sm="2" md="2">
                <b-col tag="div" v-for="(item, index) in categoryWebtoonList" :key="index" @click="moveDetail(item)">
                    <img src="@/assets/images/adult_mark.svg" alt="성인 마크" class="adult_mark" v-if="item.adult === 1">
                    <div class="top">
                        <img :src="$store.state.siteConfig.cdnUrl + '/KR/title/' + item.englishName + '.jpg'" v-if="$store.state.siteConfig !== null">
                    </div>
                    <div class="middle">
                        <p class="name">{{item.titleName}}</p>
                        <!-- <p class="up_check">UP</p> -->
                    </div>
                    <div class="bottom">
                        <p>{{item.publisher}}</p>
                        <!-- <p class="view">{{item.viewTotal}} 회</p> -->
                    </div>
                </b-col>
            </b-row>
            <Pagination :initMaxRecordCount="currentPageSize" :initMaxPageCount="maxPageCount" :initPagingData="initPagingData" @movePaging="movePaging" />
        </b-container>
        <Footer />
        <Login :initIsShowPopup="isShowLoginPopup" @close="close('Login')" />
    </div>
</template>

<script>
import Header from '@/components/common/Header.vue'
import Footer from '@/components/common/Footer.vue'
import Login from '@/components/common/Login.vue'
import Pagination from '@/components/common/Pagination.vue'

export default {
    name: 'Category',
    pageCategory: 'Common',
    components: {
        Header,
        Footer,
        Login,
        Pagination,
    },
    data() {
        return {
            initIsShowCategory: true,

            currCategoryIdx: 0,
            currScending: 0,
            currSort: 0,

            categoryList: [],
            categoryWebtoonList: [],

            searchText: '',

            currentPageNo: 1,       //현재 페이지 번호
            currentPageSize: 10,    //한번에 불러올 게시물 수
            maxPageCount:10,        //한 화면에 보여질 페이지 수
            initPagingData: null,

            totalRecordSize: 0,

            isShowLoginPopup: false,

            initHeaderType: 'type1',
        }
    },
    async created(){
        // await this.mixinSiteConfigCheck();

        if(this.$route.params.idx){
            this.currCategoryIdx = Number(this.$route.params.idx);
        }
        if(this.$route.params.searchText){
            this.searchText = this.$route.params.searchText;
        }

        this.getCategory();
        // this.getCategoryWebtoonList();
        this.getSearchList();

    },
    mounted(){
        document.getElementById('inputSearch').focus();
    },
    methods: {
        getCategory(){ // 카테고리 정보
            var param = 
            {
                // limit : 3
            }
            this.$Axios.post(`${this.host}/infocategory`, param, { headers: { 'Pragma' : 'no-cache', 'Cache-Control': 'no-cache', 'Access-Control-Allow-Origin': '*', 'Content-type': 'application/json', }}).then(res => {
                if (res.data.returnCode === 0) {
                    // console.log(res.data)
                    this.categoryList = res.data.list;
                }
            })
            .catch((error) => {
                console.log(error);
            });
        },
        getCategoryName(category_idx){
            var name = '';
            this.categoryList.forEach(element =>{
                if(category_idx === element.idx){
                    name = element.name;
                }
            })
            return name;
        },
        search(){
            this.$router.push('/Category/' + this.currCategoryIdx + '/' + this.searchText);
        },
        getSearchList(){
            var param = 
            {
                pageNo: this.currentPageNo,
                pageSize: this.currentPageSize,
                // scending: this.currScending,
                // sort: this.currSort,
                sign: this.$store.state.sign,
                ticket: this.$store.state.ticket,
                viewAdult: this.$store.state.isCheckedAdult ? 1 : 0,
                category: this.currCategoryIdx,
                word: this.searchText,
            }
            this.$Axios.post(`${this.host}/search`, param, { headers: { 'Pragma' : 'no-cache', 'Cache-Control': 'no-cache', 'Access-Control-Allow-Origin': '*', 'Content-type': 'application/json', }}).then(res => {
                // console.log(res.data);
                if (res.data.returnCode === 0) {
                    this.categoryWebtoonList = res.data.list;
                    this.initPagingData = res.data.page;
                    this.totalRecordSize = res.data.page.totalRecordSize;
                }
            })
            .catch((error) => {
                console.log(error);
            });
        },
        moveDetail(item){
            
            if(item.adult === 1){
                if(this.$store.state.loginCheck === false){
                    this.isShowLoginPopup = true;
                }
                else if(!this.$store.state.adult){
                    var src = this.$store.state.siteConfig.niceUrl + '?email=' + this.$store.state.email;
                    window.open(src, 'popupChk', 'width=500, height=550, top=100, left=100, fullscreen=no, menubar=no, status=no, toolbar=no, titlebar=no, location=no, scrollbar=no');
                }
                else{
                    // this.$router.push('/Webtoon/Info/' + item.englishName);
                    this.$router.push('/Webtoon/Info/' + item.webToonIndex);
                }
            }else{
                // this.$router.push('/Webtoon/Info/' + item.englishName);
                this.$router.push('/Webtoon/Info/' + item.webToonIndex);
            }
            
        },
        selectCategory(categoryIdx){
            this.$router.push('/Category/' + categoryIdx + '/' + this.searchText);
        },
        movePaging(pageNo){
            this.currentPageNo = pageNo;
            this.getSearchList();
        },
        close(popupType){
            switch(popupType){
                case 'Login':
                    this.isShowLoginPopup = false;
                    break;
            }
        }
    }
}
</script>