<template>
    <div id="swap">
        <div class="swap_header">
            <div class="container">
                <router-link tag="a" to="/" class="logo" id="logo">artube</router-link>
                <a href="https://medium.com/@Artube_Official/att-%ED%86%A0%ED%81%B0-%EC%8A%A4%EC%99%91-%EA%B0%80%EC%9D%B4%EB%93%9C-b80bf454b14" target="_blank" class="swap_guide">
                    <img src="@/assets/images/question_box.svg" />
                    <p>스왑 가이드</p>
                </a>
                <div class="metamask">
                    <div class="connect-wallet address" v-if="isEnableMetamask">
                        <img src="@/assets/images/icon/icon_wallet_lock.svg" class="icon lock" />
                        <span>{{account}}</span>
                        <!-- <img src="@/assets/images/icon_wallet_arrow.svg" class="icon arrow" /> -->
                    </div>
                    <div class="connect-wallet connect" @click="showMetamaskPopup" v-else>
                        <img src="@/assets/images/icon/icon_wallet.svg" class="icon wallet" />
                        <span>지갑 연결하기</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="swap_box">
            <div class="banner">
                <img src="@/assets/images/swap_main.png" />
            </div>
            <div class="status">
                <p>실시간 총 스왑 현황</p>
                <div>
                    <div class="receive_coin">
                        <p><span>스왑</span> 신청 수량</p>
                        <p>{{totalSwapReqCount | comma}}</p>
                        <p>ATT</p>
                    </div>
                    <div class="dot">
                        <ul><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li></ul>
                    </div>
                    <div class="change_coin">
                        <p><span>스왑</span> 완료 수량</p>
                        <p>{{totalSwapCompleteCount | comma}}</p>
                        <p>ATT</p>
                    </div>
                </div>
            </div>
            <div class="step">
                <div class="graph">
                    <p id="dot1">1</p>
                    <div class="line gray" v-on:class="PurpleGauge" id="purpleguage1"></div>
                    <p id="dot2" :class="[isShowPurple2 ? 'purple2' : '']">2</p>
                    <div class="line gray" id="purpleguage2"></div>
                    <p id="dot3" :class="[isShowPurple3 ? 'purple2' : '']">3</p>
                </div>
                <div class="step_text">
                    <p id="chapter1">지갑 연결</p>
                    <p id="chapter2" :class="[isShowPurple2 ? 'purple': '']">주소 확인</p>
                    <p id="chapter3" :class="[isShowPurple3 ? 'purple': '']"><sapn v-if="currTransactionState === 18">스왑 완료</sapn><span v-else>스왑 시작</span></p>
                </div>
            </div>
            <div class="cont_meta">
                <p class="swap_title" v-if="!isEnableMetamask">메타마스크 지갑을 연결 해주세요</p>
                <p class="swap_title" v-else>메타마스크 지갑 주소</p>
                <p class="metamask" v-if="!isEnableMetamask">Metamask</p>
                <p class="metamask" v-else>{{account}}</p>
                <p class="cont_wallet" @click="showMetamaskPopup" :class="[isEnableMetamask ? '':'able']">지갑연결</p>
            </div>
            <div class="network">
                <div class="ethereum">
                    <p>내 ATT 보유수량 <span>(ERC - 20)</span></p>
                    <p>{{attTokenBalance.toFixed(4) | comma}}</p>
                    <div v-if="!isEnableMetamask">
                        <p>지갑을 연결해주세요</p>
                    </div>
                </div>
                <img src="@/assets/images/icon_change_arrow_pc.svg" alt="화살표"> 
                <div class="klaytn">
                    <p>스왑가능 ATT 수량 <span>(KCT)</span></p>
                    <p v-if="onSetAttBalance">{{(attTokenBalance - myTotalReqSwapCount).toFixed(4) | comma}}</p>
                    <p v-else>0</p>
                </div>
                <!-- <div class="success_trans">
                    <img src="@/assets/images/close.svg" alt="close" class="close">
                    <div class="cont">
                        <img src="@/assets/images/success.svg" alt="recycle" class="recycle" />
                        <div>
                            <p>0x778d…C214B</p>
                            <p>전송이 완료되었습니다.</p>
                        </div>
                    </div>
                    <p class="confirm">확인</p>
                </div> -->
            </div>
            <div class="wait_trans" :class="{'show' : progressTransaction.length > 0, 'show_toast' : isShowToast,  'close_toast' : !isShowToast}" v-if="progressTransaction.length > 0">
                <div class="top">
                    <p v-if="currTransactionState != 18">트랜잭션이 완료되기를 기다리고 있습니다.</p>
                    <p v-else>전송이 완료되었습니다.</p>
                    <img src="@/assets/images/close.svg" alt="close" class="close" @click="isShowToast=false;">
                </div>
                <div class="cont">
                    <img src="@/assets/images/recycle.svg" alt="recycle" class="recycle" v-if="currTransactionState != 18"/>
                    <img src="@/assets/images/success.svg" alt="recycle" v-else/>
                    <div>
                        <a :href="contractInfo.toUrl + 'tx/' + progressTransaction[0].pqTxHash" target="_blank" class="txLink" v-if="contractInfo !== null && progressTransaction[0].pqTxHash !== null">{{progressTransaction[0].pqTxHash.substr(0,10)}}...{{progressTransaction[0].pqTxHash.substr(-6)}}</a><span v-else>-</span>
                    </div>
                </div>
                <!-- <p class="mb_confirm" v-if="progressTransaction.length > 0">상황보기</p>
                <p class="mb_confirm" v-else>확인</p> -->
            </div>
            <div class="ad">
                <p class="swap_title">주소입력</p>
                <img src="@/assets/images/exclamation.svg" class="caution" id="tooltip1"/>
                <b-tooltip target="tooltip1" triggers="hover" placement="bottom" custom-class="imgTooltip">
                    <!-- <div style="text-align:left;">
                        <p style="font-size:14px;margin-bottom:10px;">알림</p>
                        <p style="font-size:12px;">- Kalytn 메인넷 지갑 주소인지 반드시 확인해 주세요.</p>
                        <p style="font-size:12px;">- 아튜브 결제를 위해 Kaikas 지갑을 설치하신 경우 Kaikas 내 주소로 스왑하시는 것을 권장합니다.</p>
                    </div> -->
                    <img src="@/assets/images/klaytn_alarm_pc.png" />
                </b-tooltip>
                <div class="typing">
                    <input type="text" v-model="SwapKlaytnAddress" @input="inputKlayAddress">
                    <img src="@/assets/images/icon_confirmed.svg" class="ok" v-if="isCheckedAddress" />
                    <div class="confirm" @click="checkAddress" :class="[!isCheckedAddress && isEnableMetamask ? 'able': '']">주소확인</div>
                </div>
                <div class="wrong" v-if="isShowAddressAlert">
                    <p>주소가 올바르지 않습니다</p>
                </div>
            </div>
            <div class="swap_start" @click="reqSwap" :class="[isEnableMetamask && isCheckedAddress && (attTokenBalance - myTotalReqSwapCount > 0) ? 'able' : '']">스왑 시작</div>

            <div class="transaction">
                <p class="swap_title">트랜잭션 내역</p>
                <b-row class="item_name">
                    <b-col lg="2" sm="4" cols="4">주소</b-col>
                    <b-col lg="2" sm="0" cols="0">발생시간</b-col>
                    <b-col lg="2" sm="0" cols="0">수량</b-col>
                    <b-col lg="4" sm="4" cols="4">보낸 TX(Klaytn)</b-col>
                    <b-col lg="2" sm="4" cols="4">상태</b-col>
                </b-row>
                <b-row class="item" v-for="(item, index) in transactionList" :key="index">
                    <b-col lg="2" sm="4" cols="4">
                        <!-- <a :href="contractInfo.toUrl + 'account/' + item.pqReceiver" target="_blank" v-if="contractInfo !== null">
                            {{item.pqReceiver.substr(0,6)}}...{{item.pqReceiver.substr(-6)}}
                        </a
                        ><span v-else>{{item.pqReceiver.substr(0,6)}}...{{item.pqReceiver.substr(-6)}}</span> -->
                        <a :href="contractInfo.fromUrl + 'address/' + item.pqReqAddress" target="_blank" v-if="contractInfo !== null">
                            {{item.pqReqAddress.substr(0,6)}}...{{item.pqReqAddress.substr(-6)}}
                        </a
                        ><span v-else>{{item.pqReqAddress.substr(0,6)}}...{{item.pqReqAddress.substr(-6)}}</span>
                    </b-col>
                    <b-col lg="2" sm="0" cols="0">{{item.pqCreateDate.substr(0,10)}}</b-col>
                    <b-col lg="2" sm="0" cols="0">{{item.pqFinalReturnAmount | comma}}</b-col>
                    <b-col lg="4" sm="4" cols="4"><a :href="contractInfo.toUrl + 'tx/' + item.pqTxHash" target="_blank" v-if="contractInfo !== null && item.pqTxHash !== null">{{item.pqTxHash.substr(0,6)}}...{{item.pqTxHash.substr(-6)}}</a><span v-else>-</span></b-col>
                    <b-col lg="2" sm="4" cols="4" v-if="item.pqState !== 18">전환 중</b-col>
                    <b-col lg="2" sm="4" cols="4" class="changing" v-else>완료</b-col>
                </b-row>
            </div>
            <!-- <div class="transaction_m">
                <p class="swap_title">트랜잭션 내역</p>
                <b-row class="item_name">
                    <b-col sm="5" cols="5">주소</b-col>
                    <b-col sm="5" cols="5">보낸TX/받은TX</b-col>
                    <b-col sm="2" cols="2">수량/상태</b-col>
                </b-row>
                <b-row class="item" v-for="(item, index) in transactionList" :key="index">
                    <b-col sm="5" cols="5">
                        <p>0x778d…C214B</p>
                        <p>2021-10--26</p>
                    </b-col>
                    <b-col sm="5" cols="5">
                        <p class="m_send">0x5d43f…A138A</p>
                        <p class="m_receive">0x5d43f…A138A</p>
                    </b-col>
                    <b-col sm="2" cols="2">
                        <p>5,000</p>
                        <p>처리 중</p>
                    </b-col>
                </b-row>
                <div class="more">
                    <p>더보기</p>
                    <img src="@/assets/images/icon_slide_down_arrow.svg" />
                </div>
            </div> -->
            <div class="warn">
                <p>주의사항</p>
                <p>- 아튜브의 원활한 콘텐츠를 사용하기 위해서는 메타마스크보다 kaikas 지갑 사용을 권장 드립니다.</p>
                <p>- 클레이튼 네트워크로 전송 시 발생하는 가스비의 경우 본사가 부담합니다.</p>
                <p>- 기존 이더리움 네트워크의 ATT는 코인으로서 효력이 상실되므로 꼭 해당 기간인 12월 31일 안에 클레이튼 네트워크로 전환하시길 바랍니다.</p>
                <p>- 스왑 이후 메타마스크(ERC-20)에 남아있는 수량은 동결된 상태이며 사용할 수 없습니다. 이는 고객님의 가스비를 절감하기 위해 보유 수량만 체크를 했기 때문이며 따라서 전송 이후 스왑은 지원하지 않으므로 시도하지 마십시오.</p>
                <p>- 거래소 컨트렉트로 전송 완료된 코인에 대해서는 아튜브는 접근 권한이 없으며 발생한 손실에 대해서는 복구 및 지원이 불가능 합니다.</p>
                <p>- 전송 상황은 네트워크 처리 속도와 상황에 따라 지연될 수 있습니다.</p>
            </div>
        </div>

        <div id="metamask" v-if="isShowMetamaskPopup">
            <div class="metamask_box">
                <img src="@/assets/images/icon_close.svg" alt="close" class="close" @click="close('Metamask')" />
                <h1>Metamask</h1>
                <img src="@/assets/images/Metamask-logo-blanc.png" class="fox" />
                <p>메타마스크 지갑 연결이 필요합니다</p>
                <p>ATT 코인으로 충전을 하기 위해서 메타마스크 지갑을 연결하거나, 새 지갑 생성이 필요합니다.</p>
                <div class="contact_button" @click="connectWallet">지갑 연결하기</div>
                <div class="later_button" @click="close('Metamask')">다음에 하기</div>
            </div>
        </div>
        <VueMetamask @onComplete="onComplete" :initLoadMetamask="isEnableMetamask" />
        <PopupOK :initIsShowPopup="isShowPopupOkPopup" :initPopupOkMessage="initPopupOkMessage" @close="close('PopupOk')" />
        <Footer />
    </div>
</template>

<script>
import Footer from '@/components/common/Footer.vue'
import VueMetamask from 'vue-metamask';
import PopupOK from '@/components/common/PopupOK.vue'

export default {
    name: 'Swap',
    pageCategory: 'Common',
    components: {
        Footer,
        VueMetamask,
        PopupOK,
    },
    data(){
        return{
            isShowPurple2: false,
            isShowPurple3: false,
            swapHost: 'https://webtoon.artube.video:8443/crowd/api/v1',  //live
            totalSwapReqCount: 0,
            totalSwapCompleteCount: 0,

            SwapKlaytnAddress: '',      //스왑 토큰을 받을 kaikas 지갑 주소
            isCheckedAddress: false,    //Kaikas 지갑 주소 확인 여부
            isShowAddressAlert: false,
            isShowToast: false,         //트랜잭션 진행 토스트 팝업
            currTransactionState: -1,    //스왑 요청한 트랜잭션의 현재 상태

            transactionList: [],        //스왑 요청한 트랜잭션
            progressTransaction: [],    //스왑 진행중인 트랜잭션
            myTotalReqSwapCount: 0,     //누적 스왑 요청 ATT 수량
            myPossibleReqCount: 0,      //현재 요청 가능한 ATT 수량

            KlaytnShow:false,
            PurpleGauge:false,

            account: '',                //지갑 주소
            isEnableMetamask: false,    //지갑 연결 여부
            attTokenBalance: 0,         //연결된 지갑 ATT 보유량
            onSetAttBalance: false,     //ATT Token Balance 세팅된지 여부

            contractInfo: null,     //컨트랙트 정보

            isShowMetamaskPopup: false, //지갑연결 팝업

            isShowPopupOkPopup: false,  //OK 팝업
            initPopupOkMessage: '',     //OK 팝업 메세지
            PopupOkCallbakPage: '',     //OK 팝업에서 확인 버튼 눌렀을때 이동할 페이지
            
            intervalSwapList: null,
            intervalTotalResult: null,
        }
    },
    async created(){
        var that = this;
        this.getTotalResult();
        await this.getContractInfo();
        await this.checkMetamask();

        this.intervalTotalResult = setInterval(()=>{
            that.getTotalResult();
        },1000);
    },
    mounted(){
        window.scroll(0,0);
    },
    beforeDestroy(){
        if(this.intervalTotalResult !== null){
            clearInterval(this.intervalTotalResult);
        }
        if(this.intervalSwapList !== null){
            clearInterval(this.intervalSwapList);
        }
    },
    methods: {
        toggle(){
            this.KlaytnShow = !this.KlaytnShow
        },
        showFlagConnectWallet(){
            this.addClass('dot1', 'purple');
            this.addClass('chapter1', 'purple');
            // document.getElementById('dot1').setAttribute('class','purple');
            // document.getElementById('chapter1').setAttribute('class','purple');
        },
        showFlagAddressConfirm(){
            this.addClass("purpleguage1", "line");
            this.addClass("purpleguage1", "purple");
            this.addClass("dot2", "purple2");
            this.addClass("chapter2", "purple2");
            this.isShowPurple2 = true;
            this.PurpleGauge = true;

            // document.getElementById('purpleguage1').setAttribute('class','line purple');
            // document.getElementById('dot2').setAttribute('class','purple2');
            // document.getElementById('chapter2').setAttribute('class','purple2');
            
        },
        showFlagSwapStart(){
            if(this.PurpleGauge === true){
                this.addClass("purpleguage2", "line");
                this.addClass("purpleguage2", "purple");
                this.addClass("dot3", "purple2");
                this.addClass("chapter3", "purple2");
                this.isShowPurple3 = true;
                // document.getElementById('purpleguage2').setAttribute('class','line purple');
                // document.getElementById('dot3').setAttribute('class','purple2');
                // document.getElementById('chapter3').setAttribute('class','purple2');
            }
        },
        async checkMetamask(){  //메타 마스크 체크
			var that = this;
            var ethereum = await window.ethereum;
            // console.log(window.web3)

            if(ethereum){
                ethereum.isConnected();
                setTimeout(async function(){
                    try{
                        await ethereum.request({ method: 'eth_requestAccounts' }).then(async (accounts) => {
                            that.account = accounts[0];
                            that.isEnableMetamask = true;
                            // await that.getSwapReqInfo();
                            setTimeout(async function(){
                                if(that.contractInfo){
                                    await that.getAttBalance(that.contractInfo.swapDepositAddress, (balance)=>{
                                        
                                        if(balance !== null){
                                            that.attTokenBalance = Number(balance.toString());
                                        }
                                        else{
                                            that.attTokenBalance = 0;
                                        }
                                        that.onSetAttBalance = true;
                                    });
                                }
                                await that.getReqList();
                                that.showFlagConnectWallet();
                            }, 500);
                        });
                    }catch(err){
                        console.log(err);
                    }
                }, 500)
            }
        },
        async connectWallet(){
            var that = this;
            try{
                await window.ethereum.request({ method: 'eth_requestAccounts' }).then(async (accounts) => {
                    this.account = accounts[0];
                    this.isEnableMetamask = true;

                    setTimeout(async function(){
                        if(that.contractInfo){
                            await that.getAttBalance(that.contractInfo.swapDepositAddress, (balance)=>{
                                
                                if(balance !== null){
                                    that.attTokenBalance = Number(balance.toString());
                                }
                                else{
                                    that.attTokenBalance = 0;
                                }
                                that.onSetAttBalance = true;
                            });
                        }
                        await that.getReqList();
                        that.showFlagConnectWallet();
                    }, 500);

                    this.isShowMetamaskPopup = false;
                    this.initPopupOkMessage = '지갑 연결이 완료 되었습니다.';
                    this.isShowPopupOkPopup = true;
                });
            }catch(err){
                this.initPopupOkMessage = 'MetaMask 미설치 또는 잠금 상태입니다.<br/>설치된 상태면 실행하여 로그인 해주세요.<br/><a href="https://metamask.io/download.html" target="_blank" class="metamask_download">MetaMask 설치하기</a>';
                this.isShowPopupOkPopup = true;
                // console.log(err);
            }
        },
        disconnect(){   //메타 마스크 연결 끊기
            this.attTokenBalance = 0;
            this.account = '';
            this.isEnableMetamask = false;
		},
        async getContractInfo(){ // att 토큰 정보
            var param = 
            {
            }
            await this.$Axios.post(`${this.swapHost}/config/contract`, param, { headers: { 'Pragma' : 'no-cache', 'Cache-Control': 'no-cache', 'Access-Control-Allow-Origin': '*', 'Content-type': 'application/json', }}).then(res => {
                // console.log(res.data);
                if (res.data.returnCode === 0) {
                    this.contractInfo = res.data;
                }
            })
            .catch((error) => {
                console.log(error);
            });
        },
        getTotalResult(){
            var param = 
            {
            }
            this.$Axios.post(`${this.swapHost}/swap/result`, param, { headers: { 'Pragma' : 'no-cache', 'Cache-Control': 'no-cache', 'Access-Control-Allow-Origin': '*', 'Content-type': 'application/json', }}).then(res => {
                // console.log(res.data);
                if(res.data.returnCode === 0){
                    this.totalSwapReqCount = res.data.reqDecimal ? res.data.reqDecimal : 0;
                    this.totalSwapCompleteCount = res.data.resDecimal ? res.data.resDecimal : 0;
                }
            })
            .catch((error) => {
                console.log(error);
            });
        },
        async getSwapReqInfo(){
            var param = 
            {
                address: this.account
            }
            await this.$Axios.post(`${this.swapHost}/address/checkoverlap`, param, { headers: { 'Pragma' : 'no-cache', 'Cache-Control': 'no-cache', 'Access-Control-Allow-Origin': '*', 'Content-type': 'application/json', }}).then(res => {
                // console.log(res.data);
                if(res.data.returnCode === 0){
                    this.beforeReqInfo = res.data.data;
                }
            })
            .catch((error) => {
                console.log(error);
            });
        },
        async getReqList(){
            var that = this;
            var param = 
            {
                address: this.account
            }
            await this.$Axios.post(`${this.swapHost}/swap/reqlist`, param, { headers: { 'Pragma' : 'no-cache', 'Cache-Control': 'no-cache', 'Access-Control-Allow-Origin': '*', 'Content-type': 'application/json', }}).then(res => {
                // console.log(res.data);
                if(res.data.returnCode === 0){
                    this.transactionList = res.data.list;

                    this.transactionList.forEach(item=>{
                        this.myTotalReqSwapCount = item.pqFinalReturnAmount;
                    })

                    if(this.transactionList.length > 0){    //스왑 요청 건이 있을 경우
                        // console.log(this.intervalSwapList + ' / ' + this.currTransactionState + ' / ' +  this.transactionList[0].pqState);

                        this.progressTransaction = res.data.list;

                        if(this.intervalSwapList === null){
                            this.intervalSwapList = setInterval(() => that.getReqList(), 1000);    //Kaikas 잠금 여부 체크
                        }

                        //pqState 18:트랜잭션 종료. 이 외 pqState는 진행 중
                        if(this.currTransactionState === -1){   //첫 호출일 경우
                            this.isShowToast = true;
                            this.currTransactionState = this.transactionList[0].pqState;
                        }
                        
                        if(this.currTransactionState === 18){    //이전에 종료 트랜잭션을 받아서 처리한 경우
                            if(this.intervalSwapList){
                                clearInterval(this.intervalSwapList);
                            }
                            this.showFlagAddressConfirm();
                            setTimeout(function(){
                                that.showFlagSwapStart();
                            }, 1000)
                        }
                        else if(this.transactionList[0].pqState != 18){ //아직 트랜잭션이 진행 중인 경우(state 18이 아니면 모두 진행 중)
                            this.currTransactionState = this.transactionList[0].pqState;
                        }
                        else if(this.currTransactionState != 18 && this.transactionList[0].pqState === 18){   //이전에 진행 중인 트랜잭션이었는데 이번에는 트랜잭션 종료일 경우
                            this.isShowToast = true;
                            this.currTransactionState = 18;
                            if(this.intervalSwapList){
                                clearInterval(this.intervalSwapList);
                            }
                            this.showFlagAddressConfirm();
                            setTimeout(function(){
                                that.showFlagSwapStart();
                            }, 1000)
                        }
                        else{
                            if(this.intervalSwapList){
                                clearInterval(this.intervalSwapList);
                            }
                        }
                    }
                }
            })
            .catch((error) => {
                console.log(error);
            });
        },
        async checkAddress(){
            if(this.isCheckedAddress && this.isEnableMetamask){
                return;
            }
            else if(this.SwapKlaytnAddress === ''){
                this.initPopupOkMessage = 'Klaytn 지갑 주소를 입력해 주세요.';
                this.isShowPopupOkPopup = true;
                return;
            }

            var param = 
            {
                address: this.SwapKlaytnAddress
            }
            await this.$Axios.post(`${this.swapHost}/address/check`, param, { headers: { 'Pragma' : 'no-cache', 'Cache-Control': 'no-cache', 'Access-Control-Allow-Origin': '*', 'Content-type': 'application/json', }}).then(res => {
                // console.log(res.data);
                if(res.data.returnCode === 0){
                    this.isCheckedAddress = true;
                    this.showFlagAddressConfirm();
                }else{
                    this.isShowAddressAlert = true;
                }
            })
            .catch((error) => {
                console.log(error);
            });
        },
        async reqSwap(){
            if(this.attTokenBalance - this.myTotalReqSwapCount <= 0){
                return;
            }

            await this.signature().then(async(result) =>{
                var param = 
                {
                    address: result.publicAddress,
                    message: result.message,
                    signature: result.signature
                }
                await this.$Axios.post(`${this.swapHost}/address/register`, param, { headers: { 'Pragma' : 'no-cache', 'Cache-Control': 'no-cache', 'Access-Control-Allow-Origin': '*', 'Content-type': 'application/json', }}).then(res => {
                    // console.log(res.data);
                    if(res.data.returnCode === 0){
                        this.showFlagSwapStart();
                        this.initPopupOkMessage = '스왑 신청이 완료되었습니다.';
                        // this.PopupOkCallbakPage = 'reload';
                        this.isShowPopupOkPopup = true;
                        this.getReqList();
                    }
                    else if(res.data.returnCode === 200){
                        this.initPopupOkMessage = '잘못된 주소 입니다.';
                        this.isShowPopupOkPopup = true;
                    }
                    else if(res.data.returnCode === 201){
                        this.initPopupOkMessage = '중복된 스왑 신청 입니다.'
                        this.isShowPopupOkPopup = true;
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
            })
            .catch(err => {
                console.log(err);
            });
        },
        async signature(){  //메타 마스크 사이닝
            // var publicAddress = window.ethereum.selectedAddress;
            // var nonce = 'Artube Signature(' + Date.now().toString() + ')';
            var publicAddress = this.account;
            var message = this.SwapKlaytnAddress;

            return await new Promise ((resolve, reject) => 
                window.web3.personal.sign ( 
                    //web3.fromUtf8 (` 일회성 임시 값에 서명합니다 : $ {nonce} `), 
                    
                    window.web3.fromUtf8(message),
                    publicAddress, 
                    (err, signature) => { 
                        if (err) return reject (err); 
                        return resolve ({publicAddress, signature, message}); 
                    } 
                ) 
            ); 
        },
        async getAttBalance(tokenAddress, callback) {
			let walletAddress = this.account;
			let minABI = [
				{
					"constant":true,
					"inputs":[{"name":"_owner","type":"address"}],
					"name":"balanceOf",
					"outputs":[{"name":"balance","type":"uint256"}],
					"type":"function"
				},
				{
					"constant":true,
					"inputs":[],
					"name":"decimals",
					"outputs":[{"name":"","type":"uint8"}],
					"type":"function"
				}
			];
			
			let contract = await window.web3.eth.contract(minABI).at(tokenAddress);
			await contract.balanceOf(walletAddress, (error, balance) => {
				contract.decimals((error, decimals) => {
					balance = balance.div(10**decimals);
					callback(balance);
				});
			});
		},
        showMetamaskPopup(){
            if(this.isEnableMetamask){
                return;
            }
            this.isShowPopupOkPopup = false;
            this.isShowMetamaskPopup = true;
        },
        async switchNetwork() {
            //네트워크 변경
            var chainData = JSON.parse("{\"flagTest\": false , \"chainName\":\"Ethereum Mainnet\", \"chainId\": \"0x1\", \"rpcUrls\": [\"https://mainnet.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161\"], \"nativeCurrency\": { \"name\": \"ETH\", \"symbol\": \"ETH\", \"decimals\": 18 }, \"blockExplorerUrls\": [\"https://etherscan.io/\"] }");
            var ethereum = window.ethereum;
            try {
                // console.log(this.$store.state.siteConfig);
                await ethereum.request({
                    method: "wallet_switchEthereumChain",
                    params: [{ chainId: chainData.chainId }],
                });
            } catch (switchError) {
                if (switchError.code === 4902) {
                    // this.popupOpen('PopupOk', this.$t("popup_addMetamaskNetwork")); //"Metamask에 등록된 네트워크가 없으므로 추가를 실행";
                    this.addNetwork(chainData);
                }
            }
        },
        async addNetwork(chainData) {
                //네트워크 추가
                var ethereum = window.ethereum;
                try {
                    await ethereum.request({
                        method: "wallet_addEthereumChain",
                        params: [
                            // chainData
                            {
                                chainName: chainData.chainName,
                                chainId: chainData.chainId,
                                rpcUrls: chainData.rpcUrls,
                                nativeCurrency: chainData.nativeCurrency,
                                blockExplorerUrls: chainData.blockExplorerUrls,
                            },
                        ],
                    });
                } catch (addError) {
                    console.log(addError);
                    // handle "add" error
                }
            },
        close(popupType){
            switch(popupType){
                case 'Metamask':
                    this.isShowMetamaskPopup = false;
                    break;
                case 'PopupOk':
                    if(this.PopupOkCallbakPage === 'wallet-connect'){
                        this.isShowPopupOkPopup = false;
                    }
                    else if(this.PopupOkCallbakPage === 'reload'){
                        location.reload();
                    }
                    else if(this.PopupOkCallbakPage !== ''){
                        this.$router.push(this.PopupOkCallbakPage);
                    }
                    this.isShowPopupOkPopup = false;
                    this.PopupOkCallbakPage = '';
                    break;
            }
        },
        onComplete(data){   //메타 마스크 리턴 대기
			// this.metamask = data;
            // console.log(data);
			if(data.type === "CHANGED_ACCOUNT"){
                this.initPopupOkMessage = 'MetaMask 지갑이 변경되었습니다.';
                this.PopupOkCallbakPage = 'reload';
                this.isShowPopupOkPopup = true;
				// this.$router.push('/' + this.currentPageName);
				// this.disconnect();
				// this.connectMetamask();
			}
            else if(data.type === "CHANGED_NETWORK"){
                this.initPopupOkMessage = 'MetaMask Network가 변경되었습니다.';
                this.PopupOkCallbakPage = 'reload';
                this.isShowPopupOkPopup = true;
				// this.$router.push('/' + this.currentPageName);
				// this.disconnect();
				// this.connectMetamask();
			}
            else if(data.type === "EMPTY_METAMASK_ACCOUNT"){
                this.initPopupOkMessage = 'MetaMask에 연결된 지갑 없습니다.';
                this.isShowPopupOkPopup = true;
				this.disconnect();
			}
            else if(data.type === "NO_INSTALL_METAMASK"){
                this.initPopupOkMessage = 'MetaMask가 설치되지 않았습니다.';
                this.isShowPopupOkPopup = true;
                this.disconnect();
            }
            else if(data.type === 'LOAD_MATAMASK_WALLET_ERROR'){
                this.initPopupOkMessage = 'MetaMask를 정상적으로 로드하지 못했습니다.';
                this.isShowPopupOkPopup = true;
                this.disconnect();
            }
            else if(data.type === 'TESTNET'){
                this.switchNetwork();
                this.isShowMetamaskPopup = false;
                this.initPopupOkMessage = 'MetaMask Network가 메인넷이 아닙니다.';
                this.PopupOkCallbakPage = 'reload';
                this.isShowPopupOkPopup = true;
                this.disconnect();
            }
            // console.log('data : ', data);
			// console.log(this.metamask);
        },
        inputKlayAddress(){
            if(!this.isEnableMetamask){
                this.isShowMetamaskPopup = true;
            }
            this.isShowAddressAlert = false;
            this.isCheckedAddress = false;
        },
        addClass(id, classe) {
            try{
                var element = document.getElementById(id);
                var classes = element.className.split(' ');
                var getIndex = classes.indexOf(classe);

                if (getIndex === -1) {
                    classes.push(classe);
                    element.className = classes.join(' ');
                }
            }catch(err){err;}
		},
		// delClass(id, classe) {
		// 	var element = document.getElementById(id);
		// 	var classes = element.className.split(' ');
		// 	var getIndex = classes.indexOf(classe);

		// 	if (getIndex > -1) {
		// 		classes.splice(getIndex, 1);
		// 	}
		// 	element.className = classes.join(' ');
		// },
    },
}
</script>