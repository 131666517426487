<template>
    <div id="vue-kaikas"></div>
</template>

<script>
import Caver from "caver-js";
export default {
    props: {
        userMessage: {
            type: String,
            default: "null",
        },
        initLoadKaikas: {
            type: Boolean,
            default: function(){
                return false;
            }
        },
        initIsMainNetForce : {
            type: Boolean,
            default: function(){
                return false;
            }
        }
    },
    data() {
        return {
            provider: null,     //window.klaytn
            caver: null,
            MainNetId: "8217",  //klaytn 메인넷 체인 번호
            KaikasNetId: "",
            KaikasAddress: "",
            UnlockInterval: null,
            AccountInterval: null,
            NetworkInterval: null,
            isComplete: false,
            type: "INIT",
            Msg: {
                LOAD_WALLET_ERROR: "Loading kaikas error, please try later",
                EMPTY_WALLET_ACCOUNT: "Please login to your kaikas to continue with this app",
                NETWORK_ERROR: "The connection is abnormal, please try again",
                NOT_INSTALL: "Please install kaikas for this application",
                MAIN_NET_FORCE: "Please choose Main network",
                MAIN_NET: "Currently Main network",
                TEST_NET: "Currently not in the main network",
                USER_DENIED_ACCOUNT_AUTHORIZATION: "User denied account authorization",
                CHANGED_NETWORK: "Changed Network ID",
                CHANGED_ACCOUNT: "Changed Account",
                DISABLED_WALLET: "Plaese connect wallet",
                LOCKED_WALLET: "Plaese unlock kaikas"
            },
        };
    },
    methods: {
        async loadKaikas(){
            //Kaisak 설치 확인
            if (typeof window.klaytn !== 'undefined') { //Kaikas 설치 되어 있는 경우
                try {
                    this.provider = window.klaytn;     //klaytn 프로바이더 저장
                    this.caver = new Caver(this.provider);  //caver 객체 생성

                    if(!this.provider.isKaikas){    // 설치 여부 다시한번 판단(없어도 될 듯)
                        this.Log(this.Msg.NOT_INSTALL, "NOT_INSTALL");
                        return;
                    }

                    var isUnlocked = await this.checkUnlocked();    //Kaikas 잠금 체크
                    
                    // console.log(isUnlocked);
                    if(!isUnlocked){    //잠금 상태일 경우 종료
                        return;
                    }

                    this.KaikasNetId = this.provider.networkVersion;    //현재 네트워크 저장
                    // this.KaikasAddress = this.provider.selectedAddress; //현재 계정 저장

                    this.UnlockInterval = setInterval(() => this.checkUnlocked(), 500);    //Kaikas 잠금 여부 체크
                    this.AccountInterval = setInterval(() => this.checkAccounts(), 500);    //계정 변경 여부 체크
                    this.NetworkInterval = setInterval(() => this.checkNetWork(), 500);     //네트워크 변경 여부 체크
                    this.isComplete = true;

                } catch (error) {
                    this.Log(this.Msg.USER_DENIED_ACCOUNT_AUTHORIZATION,"USER_DENIED_ACCOUNT_AUTHORIZATION");
                }
            }
            else {  //Kaikas 설치 안된 경우
                this.Log(this.Msg.NOT_INSTALL, "NOT_INSTALL"); 
                console.error("Non-klaytn browser detected. You should consider trying Kaikas!");
            }
        },

        async checkUnlocked(){
            try{
                return await this.provider._kaikas.isUnlocked().then(isUnlocked =>{    //Kaikas 지갑 잠금 상태 체크
                    // console.log(isUnlocked);
                    if(!isUnlocked){
                        this.Log(this.Msg.LOCKED_WALLET, "LOCKED_WALLET");
                        return false;
                    }
                    else{
                        return true;
                    }
                });
            }
            catch(err){
                return false;
            }
        },
        async checkAccounts() {
            if(this.provider === null) return;

            try{
                const selectedAddress = this.provider.selectedAddress;
                if(selectedAddress === ''){
                    this.KaikasAddress = "";
                    this.disconnectKaikas();
                    this.Log(this.Msg.EMPTY_WALLET_ACCOUNT, "EMPTY_WALLET_ACCOUNT");
                    return;
                }
                else if(this.KaikasAddress === '' && selectedAddress !== this.KaikasAddress){
                    this.KaikasAddress = selectedAddress;
                    return;
                }
                else if(this.KaikasAddress !== '' && selectedAddress !== this.KaikasAddress){
                    this.KaikasAddress = selectedAddress;
                    this.disconnectKaikas();
                    this.Log(this.Msg.CHANGED_ACCOUNT, "CHANGED_ACCOUNT");
                    return;
                }
            }
            catch(error){
                console.log(error);
                this.Log(this.Msg.USER_DENIED_ACCOUNT_AUTHORIZATION, "USER_DENIED_ACCOUNT_AUTHORIZATION");
            }
        },
        async checkNetWork() {
            if(this.provider === null) return;

            try{
                const currentNetId = this.provider.networkVersion;
                // console.log(currentNetId)
                if(this.KaikasNetId !== currentNetId){    //네트워크가 변경된 경우
                    this.KaikasNetId = currentNetId;
                    this.disconnectKaikas();
                    this.Log(this.Msg.CHANGED_NETWORK, "CHANGED_NETWORK");
                    return;
                }
                else if(this.initIsMainNetForce && currentNetId !== this.MainNetId){     //메인넷 고정 시 메인넷이 아닐 경우
                    this.disconnectKaikas();
                    this.Log(this.Msg.MAIN_NET_FORCE, "MAIN_NET_FORCE");
                    return;
                }
                else if(!this.initIsMainNetForce && currentNetId !== this.MainNetId){       //메인넷 고정은 아니지만 메인넷이 아닐 경우
                    this.Log(this.Msg.TEST_NET, "TEST_NET");
                    return;
                }

            }
            catch(error){
                console.log(error);
                this.Log(this.Msg.USER_DENIED_ACCOUNT_AUTHORIZATION, "USER_DENIED_ACCOUNT_AUTHORIZATION");
            }
        },
        disconnectKaikas(){
            this.provider = null;
            this.caver = null;
            this.KaikasNetId = "";
            this.KaikasAddress = "";
            clearInterval(this.UnlockInterval);
            clearInterval(this.AccountInterval);
            clearInterval(this.NetworkInterval);
            this.isComplete = false;
            this.type = "INIT";
        },
        Log(msg, type = "") {
            const letType = type;
            if (letType === this.type) return;
            const message = this.userMessage === "null" ? msg : this.userMessage;
            this.type = type;
            this.$emit("onComplete", {
                caver: this.caver,
                provider: this.provider,
                KaikasAddress: this.KaikasAddress,
                KaikasNetId: this.KaikasNetId,
                type,
                message,
            });
        },
    },
    async mounted() {
        if(this.initLoadKaikas){
            this.loadKaikas();
        }
    },
    beforeDestroy(){
        this.disconnectKaikas();
    },
    watch:{
        initLoadKaikas(){
            if(this.initLoadKaikas && this.isComplete === false){
                this.loadKaikas();
            }else if(this.initLoadKaikas === false && this.isComplete === true){
                this.disconnectKaikas();
            }
        }
    },  
};
</script>

<style scoped>
    #vue-kaikas {
        position: fixed;
        top: 0;
        left: 0;
        width: 0;
        height: 0;
    }
</style>