<template>
    <div class="assist-library">
        <Header :initHeaderType="initHeaderType" />
        <b-container>
            <h1 class="title">후원 목록</h1>
            <h2 class="sub-title">후원한 작품을 확인할 수 있습니다.</h2>
            <ul class="menu">
                <li :class="{'on' : selectedTabNo === 1}" @click="$router.push('/LibraryAssist')">후원 목록</li>
                <li :class="{'on' : selectedTabNo === 2}" @click="$router.push('/LibraryAssist/Detail'), getSupportMyinfoDetailList()">후원 상세 내역</li>
            </ul>
            <div class="no-assist" v-if="selectedTabNo === 1 && supportMyinfo !== null && supportMyinfo.list.list.length === 0">
                <img src="@/assets/images/assist/no-assist.svg" />
                <p>후원한 작품이 없습니다.</p>
                <router-link tag="a" to="/Assist">후원을 해보세요</router-link>
            </div>
            <div class="yes-assist" v-if="selectedTabNo === 1 && supportMyinfo !== null && supportMyinfo.list.list.length !== 0">
                <div class="withdraw-link">
                    <div class="top">
                        <p class="withdraw-title">보유중인 페이백 아트포인트</p>
                        <p class="view-history" @click="isShowSwapHistory = !isShowSwapHistory">내역보기</p>
                    </div>
                    <p class="precautions">정수 단위로만 아트포인트로 스왑이 가능합니다.</p>
                    <p class="number">{{ (Math.floor(supportMyinfo.payBackPoint * 10000) / 10000 ) | comma }} P</p>
                    <!-- <p class="number2">( ≒800 ATT )</p> -->
                    <a @click="isShowPaybackSwap = !isShowPaybackSwap">스왑하기</a>
                </div>
                <p class="gray-text">스왑한 아트포인트는 <router-link tag="span" to="/Point/Withdraw">출금이 가능합니다.</router-link></p>
                <div class="flex assistBox" v-for="(item, index) in supportMyinfo.list.list" :key="index">
                    <img class="left" :src="$store.state.siteConfig.cdnUrl + '/KR/title/' + item.twebtoonForApi.englishName + '.jpg'" v-if="$store.state.siteConfig !== null" />
                    <div class="right">
                        <p class="name">{{ item.twebtoonForApi.titleName }}</p>
                        <p class="writer">{{ item.twebtoonForApi.publisher }}</p>
                        <div class="flex">
                            <div class="point">
                                <p class="accumulate">누적 후원 포인트</p>
                                <p><span :class="{purple : item.supportPoint > 499, blue : 499 >= item.supportPoint && item.supportPoint >= 250, orange : item.supportPoint > 49 && item.supportPoint < 250, gray : item.supportPoint < 50 && item.supportPoint > 4}">X {{ item.supportPoint | comma }}P</span></p>
                                <!-- ( ≒ {{ item.att | comma }} ATT ) -->
                            </div>
                            <div class="assistButton">
                                <!-- <button class="free" @click="isShowPopupAssist = !isShowPopupAssist, assistClick(index)">후원하기</button> -->
                                <p>기여도 <span>{{ (Math.floor(item.contribution * 100) / 100 ) }}%</span></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="details" v-if="selectedTabNo === 2">
                <div class="points">
                    <div class="contents">
                        <p class="title">누적 페이백 포인트</p>
                        <div class="number">
                            <p>{{ (Math.floor(supportMyinfoDetail.payBackPoint * 10000) / 10000 ) | comma }} P</p>
                            <p class="att">( ≒ {{ supportMyinfoDetail.payBackChangeAtt | comma }} ATT )</p>
                        </div>
                    </div>
                    <div class="contents">
                        <p class="title">누적 후원한 포인트</p>
                        <div class="number">
                            <p>{{ supportMyinfoDetail.supportPoint | comma }} P</p>
                            <p class="att">( ≒ {{ supportMyinfoDetail.supportChangeAtt | comma }} ATT )</p>
                        </div>
                    </div>
                </div>
                <div class="choice">
                    <p class="assist-title">작품 선택</p>
                    <b-row cols="5">
                        <b-col cols="6" sm="3" class="webtoon-curation-item" :class="[0 === selectedSupportSlideIdx && 4 < selectedSupportSlideIdx + 5 ? 'show' : '']">
                            <!-- , {'selected' : selectedToon === 0} -->
                            <!--  @click="selectedAll()" -->
                            <div class="boxBorder">
                                <div class="head">
                                    <img src="@/assets/images/assist/select-all.png" class="webtoon-thumbnail" />
                                </div>
                                <div class="body">
                                    <p class="webtoon-name">작품 전체</p>
                                    <p class="webtoon-intro" >아튜브</p>
                                </div>
                            </div>
                        </b-col>
                        <AssistCurationItem v-for="(item, index) in supportMyinfo.list.list" :key="index" :initWebtoonInfo="item" :class="[index + 1 >= selectedSupportSlideIdx && index + 1 < selectedSupportSlideIdx + 5 ? 'show' : '']" />
                        <!-- , {'selected' : selectedToon === index + 1} -->
                        <!--  @selected="selected(index + 1)" -->
                        <div class="moveAssist webtoon-curation-item" :class="[supportMyinfo.list.list.length + 1 >= selectedSupportSlideIdx && supportMyinfo.list.list.length + 1 < selectedSupportSlideIdx + 5 ? 'show' : '']">
                            <div class="bg"><img src="@/assets/images/assist/selectMore.svg" /></div>
                        </div>
                    </b-row>
                    <div class="next" @click="moveSlide('next', 'support')" v-if="supportMyinfo.list.list.length > 3">
                        <img src="@/assets/images/league_new_next.svg" alt="next">
                    </div>
                    <div class="prev" @click="moveSlide('prev', 'support')" v-if="supportMyinfo.list.list.length > 3">
                        <img src="@/assets/images/league_new_prev.svg" alt="prev">
                    </div>
                </div>
                <div class="payback">
                    <div class="payback-title">
                        <p class="assist-title">페이백 수입</p>
                        <div class="buttons">
                            <button :class="{'left' : summaryType === 0}" @click="summaryType = 0, getSupportSummary()">월별</button>
                            <button :class="{'right' : summaryType === 1}" @click="summaryType = 1, getSupportSummary()">연도</button>
                        </div>
                    </div>
                    <div class="graphBox">
                        <div class="top">
                            <p>작품<span>{{ this.supportSummaryData.webtoonname }}</span></p>
                            <p v-if="supportSummaryData.sum === null">수입<span class="purple">0P</span></p>
                            <p v-else>수입<span class="purple">{{ supportSummaryData.sum }}P</span></p>
                            <div class="input-group dropdown" v-if="summaryType === 0">
                                <b-dropdown :text="getSelectedYear()">
                                    <b-dropdown-item v-for="(item) in yearOption" :key="item.value" @click="changeType(item.value)">{{item.text}}</b-dropdown-item>
                                </b-dropdown>
                            </div>
                        </div>
                        <div class="bottom" v-if="summaryType === 0">
                            <ul>
                                <li v-for="(item, idx) in supportSummary" :key="idx">
                                    <p class="number">{{ (Math.floor(item.point * 100) / 100 ) }}</p>
                                    <div class="graph" :style="{height : item.graphHeight}"></div>
                                    <p class="count">{{ item.strDate }}</p>
                                </li>
                            </ul>
                        </div>
                        <div class="bottom" v-if="summaryType === 1">
                            <ul>
                                <li v-for="(item, idx) in supportSummary" :key="idx">
                                    <p class="number">{{ (Math.floor(item.point * 100) / 100 ) }}</p>
                                    <div class="graph" :style="{height : item.graphHeight}"></div>
                                    <p class="count">{{ item.strDate }}</p>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="inner-details">
                        <p class="assist-title">상세 내역</p>
                        <div class="box">
                            <b-row class="item-name">
                                <b-col>날짜</b-col>
                                <b-col>작품</b-col>
                                <b-col>작품 매출</b-col>
                                <b-col>작품 총 후원</b-col>
                                <b-col>내 후원</b-col>
                                <b-col>내 누적 후원</b-col>
                                <b-col>기여도</b-col>
                                <b-col>수입</b-col>
                            </b-row>
                            <b-row v-for="(item, idx) in supportMyinfoDetailList" :key="idx">
                                <b-col class="date">{{ item.createDate }}</b-col>
                                <b-col>{{ item.webtoontitle }}</b-col>
                                <b-col>{{ item.webtoonSales | comma }} P</b-col>
                                <b-col>{{ item.webtoonSupportAll | comma }} P</b-col>
                                <b-col>{{ item.mbSuppoerMon | comma }} P</b-col>
                                <b-col>{{ item.mbSuppoerAll | comma }} P</b-col>
                                <b-col>{{ (Math.floor(item.mbContrbution * 100) / 100 ) }} %</b-col>
                                <b-col class="income">{{ (Math.floor(item.mbPayback * 10000) / 10000 ) }} P</b-col>
                            </b-row>
                            <Pagination :initMaxRecordCount="currentPageSize" :initMaxPageCount="maxPageCount" :initPagingData="initPagingData" @movePaging="movePaging" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="assistReference" v-show="selectedTabNo === 1">
                <p>*후원은 최소 5아트포인트가 소모되며 중복해서 후원이 가능합니다</p>
                <p>*매출의 2%를 후원자의 후원 기여도 만큼 나눠서 지급됩니다.</p>
                <p>*웹툰 수익은 출금이 가능한 아트포인트로 받습니다.</p>
                <p>*돌려주는 아트포인트의 경우 작가의 수익을 제외한 매출에서 드립니다.  따라서 작가의 수익은 변하지 않습니다.</p>
            </div>
            <Banner :initBannerNumber="bannerNumber"  v-show="selectedTabNo === 1" />
        </b-container>
        <Footer />
        <PopupAssist :initIsShowPopup="isShowPopupAssist" :initSiteConfig="$store.state.siteConfig" :initWebtoonIdx="currWebtoonIdx" @close="close('PopupAssist')" />
        <PopupPaybackSwap :initIsShowPopup="isShowPaybackSwap" :initSupportMyInfo="supportMyinfo" @close="close('PopupPaybackSwap')" @openOK="openOK()" />
        <PopupSwapHistory :initIsShowPopup="isShowSwapHistory" @close="close('PopupSwapHistory')" />
        <PopupOK :initIsShowPopup="isShowPopupOkPopup" :initPopupOkMessage="initPopupOkMessage" @close="close('PopupOk')" />
        <Login :initIsShowPopup="isShowLoginPopup" @close="close('Login')" />
    </div>
</template>

<script>
import Header from '@/components/common/Header.vue'
import Footer from '@/components/common/Footer.vue'
import Banner from '@/components/common/Banner.vue'
import AssistCurationItem from '@/components/assist/AssistCurationItem.vue'
import Login from '@/components/common/Login.vue'
import Pagination from '@/components/common/Pagination.vue'
import PopupOK from '@/components/common/PopupOK.vue'
import PopupAssist from '@/components/assist/PopupAssist.vue'
import PopupPaybackSwap from '@/components/assist/PopupPaybackSwap.vue'
import PopupSwapHistory from '@/components/assist/PopupSwapHistory.vue'

export default {
    name: 'LibraryAssist',
    pageTitle: '내 서재 후원하기',
    components: {
        Header,
        Footer,
        Banner,
        AssistCurationItem,
        Login,
        Pagination,
        PopupPaybackSwap,
        PopupSwapHistory,
        PopupAssist,
        PopupOK
    },
    data() {
        return {
            initHeaderType: 'type1',
            selectedTabNo: 1,
            bannerNumber: -1,

            supportMyinfo: null,
            supportMyinfoDetail: null,
            supportSummaryData: null,
            supportMyinfoDetailList: [],
            supportSummary: [],
            selectedSupportSlideIdx: 0,

            selectedToon: 0,
            summaryType: 0,
            summaryYear: '2022',

            currWebtoonIdx: 0,

            isShowPopupOkPopup: false,
            initPopupOkMessage: '',
            PopupOkCallbakPage: '',    // 확인 팝업에서 확인 버튼 눌렀을때 이동할 페이지

            isShowPopupAssist: false,
            isShowPaybackSwap: false,
            isShowSwapHistory: false,
            isShowLoginPopup: false,

            selectedCategoryIdx: 2022,
            yearOption: [
                {value: 2022, text: '2022'},
                {value: 2021, text: '2021'},
                {value: 2020, text: '2020'},
            ],
            
            currentPageNo: 1,       //현재 페이지 번호
            currentPageSize: 5,    //한번에 불러올 게시물 수
            maxPageCount:10,        //한 화면에 보여질 페이지 수
            initPagingData: null,
        }
    },
    async created(){
        await this.mixinLoginCheck();
        
        if(!this.$store.state.loginCheck){
            this.initPopupOkMessage = '로그인 후 이용하실 수 있습니다.'
            this.PopupOkCallbakPage = '/';
            this.isShowPopupOkPopup = true;
        }
        else{
            await this.getSupportMyinfo();
            await this.getSupportMyinfoDetail();
            await this.getSupportSummary();
            
            if(this.$route.params.tabName === 'Detail'){
                this.selectedTabNo = 2;
                await this.getSupportMyinfoDetailList()
            }
        }
    },
    mounted(){
        this.bannerNumber = 801;
    },
    methods:{
        assistClick(idx) {
            this.currWebtoonIdx = this.supportMyinfo.list.list[idx].twebtoonForApi.webToonIndex
        },
        getSelectedYear(){
            var item = this.yearOption.find((item) => item.value === this.selectedCategoryIdx);
            return item.text;
        },
        changeType(value){
            if(this.selectedCategoryIdx === value) return;
            this.selectedCategoryIdx = value;
            this.summaryYear = this.selectedCategoryIdx.toString();
            // console.log(this.summaryYear)
            this.getSupportSummary()
        },
        getSupportMyinfo(){ // 회원 후원 리스트
            var param =
            {
                ticket: this.$store.state.ticket,
                sign: this.$store.state.sign,
                viewAdult: this.$store.state.isCheckedAdult ? 1 : 0,

                pageNo: 1,
                pageSize: 100
            }
            this.$Axios.post(`${this.host}/support/myinfo`, param, { headers: { 'Pragma' : 'no-cache', 'Cache-Control': 'no-cache', 'Access-Control-Allow-Origin': '*', 'Content-type': 'application/json', }}).then(res => {
                // console.log(res.data)
                if (res.data.returnCode === 0) {
                    this.supportMyinfo = res.data;
                    this.selectedSupportSlideIdx = 0;
                }
            })
            .catch((error) => {
                console.log(error);
            });
        },
        async getSupportMyinfoDetail(){ // 회원 후원 리스트 상세 내역
            var param =
            {
                ticket: this.$store.state.ticket,
                sign: this.$store.state.sign,
                viewAdult: this.$store.state.isCheckedAdult ? 1 : 0,
            }
            await this.$Axios.post(`${this.host}/support/myinfo/detail`, param, { headers: { 'Pragma' : 'no-cache', 'Cache-Control': 'no-cache', 'Access-Control-Allow-Origin': '*', 'Content-type': 'application/json', }}).then(res => {
                // console.log(res.data)
                if (res.data.returnCode === 0) {
                    this.supportMyinfoDetail = res.data;
                }
            })
            .catch((error) => {
                console.log(error);
            });
        },
        async getSupportSummary(){ // 후원 목록 상세 내역 페이백 수입
            this.supportSummary = [];
            var param =
            {
                ticket: this.$store.state.ticket,
                sign: this.$store.state.sign,
                viewAdult: this.$store.state.isCheckedAdult ? 1 : 0,

                webtoonidx: this.currWebtoonIdx,
                type: this.summaryType,
                year: this.summaryYear,
            }
            await this.$Axios.post(`${this.host}/support/myinfo/detail/summary`, param, { headers: { 'Pragma' : 'no-cache', 'Cache-Control': 'no-cache', 'Access-Control-Allow-Origin': '*', 'Content-type': 'application/json', }}).then(res => {
                // console.log(res.data)
                if (res.data.returnCode === 0) {
                    this.supportSummaryData = res.data;
                    res.data.list.forEach((item,index) => {
                        var summaryItem = item;
                        if(!this.maxNum){
                            this.maxNum = res.data.list[index].point
                            // console.log('1',this.maxNum)
                        } else if(this.maxNum < res.data.list[index].point){
                            this.maxNum = res.data.list[index].point
                            // console.log('2',this.maxNum)
                        }
                        // console.log(this.maxNum)
                        var height = this.getGraphHeight(summaryItem);  //그래프 px 계산
                        summaryItem.graphHeight = height;    //List에 그래프 높이 미리 넣어둠
                        this.supportSummary.push(item);
                    });
                }
            })
            .catch((error) => {
                console.log(error);
            });
        },
        getGraphHeight(item){
            var height;
            if(this.maxNum === item.point){
                height = 100 + '%';
            } else{
                height = (item.point / this.maxNum)*100 + '%'
            }
            // console.log(height)
            return height;
        },
        getSupportMyinfoDetailList(){ // 후원 목록 상세 내역 리스트
            var param =
            {
                ticket: this.$store.state.ticket,
                sign: this.$store.state.sign,
                viewAdult: this.$store.state.isCheckedAdult ? 1 : 0,

                webtoonidx: this.currWebtoonIdx,
                pageNo: this.currentPageNo,
                pageSize: this.currentPageSize,
            }
            this.$Axios.post(`${this.host}/support/myinfo/detail/list`, param, { headers: { 'Pragma' : 'no-cache', 'Cache-Control': 'no-cache', 'Access-Control-Allow-Origin': '*', 'Content-type': 'application/json', }}).then(res => {
                // console.log(res.data)
                if (res.data.returnCode === 0) {
                    this.supportMyinfoDetailList = res.data.list;
                    this.initPagingData = res.data.page;
                }
            })
            .catch((error) => {
                console.log(error);
            });
        },
        moveSlide(arrow, type){
            switch(type){
                case "support":
                    if(arrow === 'next' && this.selectedSupportSlideIdx + 5 < this.supportMyinfo.list.list.length + 2){this.selectedSupportSlideIdx += 1;}
                    else if(arrow === 'prev' && this.selectedSupportSlideIdx - 1 >= 0){this.selectedSupportSlideIdx -= 1;}
                    break;
            }
        },
        movePaging(pageNo){
            this.currentPageNo = pageNo;
            this.getSupportMyinfoDetailList();
        },
        selected(idx){
            this.selectedToon = idx;
            this.currWebtoonIdx = this.supportMyinfo.list.list[idx - 1].twebtoonForApi.webToonIndex
            this.getSupportSummary();
            this.getSupportMyinfoDetailList();
        },
        selectedAll(){
            this.selectedToon = 0;
            this.currWebtoonIdx = 0;
            this.getSupportSummary();
            this.getSupportMyinfoDetailList();
        },
        openOK(){
            this.initPopupOkMessage = '스왑이 완료되었습니다.';
            this.PopupOkCallbakPage = 'reload';
            this.isShowPopupOkPopup = true;
        },
        close(popupType){
            switch(popupType){
                case 'PopupAssist':
                    this.isShowPopupAssist = false;
                    this.currWebtoonIdx = 0;
                    break;
                case 'PopupPaybackSwap':
                    this.isShowPaybackSwap = false;
                    break;
                case 'PopupSwapHistory':
                    this.isShowSwapHistory = false;
                    break;
                case 'PopupOk':
                    if(this.PopupOkCallbakPage === 'reload'){
                        location.reload();
                    }
                    else if(this.PopupOkCallbakPage !== ''){
                        this.$router.push(this.PopupOkCallbakPage);
                    }
                    this.isShowPopupOkPopup = false;
                    this.PopupOkCallbakPage = '';
                    break;
                case 'Login':
                    this.isShowLoginPopup = false;
                    break;
            }
        },
    }
}
</script>