<template>
    <div class="leaguedetail wrap">
        <Header :initHeaderType="initHeaderType" />
            <div class="detail_top" v-if="$store.state.siteConfig !== null && webtoonInfo !== null">
                <img :src="$store.state.siteConfig.cdnUrl + '/CHALLENGE/cover/' + webtoonInfo.ctCdnFolder + '.jpg'" alt="커버" class="pc">
                <img :src="$store.state.siteConfig.cdnUrl + '/CHALLENGE/mainthumbnail/' + webtoonInfo.ctCdnFolder + '.jpg'" alt="커버" class="mobile">
                <b-container v-if="webtoonInfo !== null">
                    <p class="genre" v-if="categoryList.length > 0">{{getCategoryName(webtoonInfo.ctCategoryMain)}}</p>
                    <h1>{{webtoonInfo.ctTitleName}}</h1>
                    <p class="writer">{{webtoonInfo.cwPenName}}</p>
                    <div class="util">
                        <a @click="$router.go(-1)" class="back-btn"><i class="icon icon-back"></i></a>
                        <a class="start_see" @click="showEpisode(firstEpisodeInfo)">첫화 보기</a>
                        <div class="util_right" v-if="$store.state.loginCheck">
                            <a class="subscribe full" @click="unsubscribe" v-if="isSubscribe">♥ 구독 중</a>
                            <a class="subscribe empty" @click="subscribe" v-else>♡ 구독</a>
                        </div>
                    </div>
                    <div class="m_story">
                        <div class="info">
                            <img src="@/assets/images/mobile/m_genre.svg" alt="장르">
                            <p>{{getCategoryName(webtoonInfo.ctCategoryMain)}}</p>
                            <img src="@/assets/images/hot/eye.svg" alt="본 수">
                            <p>{{webtoonInfo.ctViewTotal | comma}}</p>
                            <img src="@/assets/images/mobile/star_line.svg" alt="star">
                            <p v-if="webtoonInfo !== null">{{webtoonInfo.starAvg}}</p>
                        </div>
                        <div class="text_box fold">
                            <p id="webtoonDesc" class="story_text" :class="[!isShowAllDesc && descHeight > 36 ? '' : 'show-all']" v-if="webtoonInfo !== null" v-html="webtoonInfo.ctDesc"></p>
                            <div class="story_collapse" @click="isShowAllDesc = !isShowAllDesc">
                                <p v-if="isShowAllDesc">접기</p>
                                <p v-else>더보기</p>
                                <img src="@/assets/images/mobile/icon_right_arrow.svg" alt="화살표" :class="[isShowAllDesc ? 'btn-hide' : 'btn-show']">
                            </div>
                        </div>
                    </div>
                </b-container>
            </div>
            <div class="detail_bottom" v-if="webtoonInfo !== null">
            <!-- <Recentsee /> -->
                <b-container>
                    <LeagueFloating />
                    <div class="toon_number clearfix">
                        <div class="left">
                            <div class="story">
                                <p class="title">작품 스토리</p>
                                <p class="cont" v-if="webtoonInfo !== null" v-html="webtoonInfo.ctDesc"></p>
                            </div>
                            <div class="page">
                                <div class="page_head">
                                    <p>총 {{webtoonInfo !== null ? webtoonInfo.epCount : 0}}화</p>
                                    <a @click="toggleSort">정렬변경</a>
                                </div>
                                <div class="page_cont">
                                    <div v-for="(item, index) in episodeList" :key="index" class="page_detail" @click="showEpisode(item)">
                                        <img :src="$store.state.siteConfig.cdnUrl + '/CHALLENGE/thumbnail/' + webtoonInfo.ctCdnFolder + '/' + item.ceUrl" alt="썸네일" class="ssum" v-if="webtoonInfo !== null && $store.state.siteConfig !== null">
                                        <div class="info">
                                            <p class="episode">{{item.ceEpisodeIdx}}</p>
                                            <p class="name">{{item.ceTitleName}}</p>
                                            <p class="day">{{item.ceCreateDate.substring(0,10)}}</p>
                                            <p class="lookup">조회 {{item.ceViewCount}}</p>
                                        </div>
                                        <p class="round_star">{{item.ceStarAvg}}</p>
                                    </div>
                                </div>
                            </div>
                            <Pagination :initMaxRecordCount="currentPageSize" :initMaxPageCount="maxPageCount" :initPagingData="initPagingData" @movePaging="movePaging" />
                        </div>
                        <div class="right">
                            <div class="subs_star" style="cursor:inherit;">
                                <img src="@/assets/images/star.svg" alt="star"> 
                                <p v-if="webtoonInfo !== null"><span>별점</span> {{webtoonInfo.ctStarAvg.toFixed(1)}}</p>
                            </div>
                            <div class="mobile_see">
                                <a @click="showEpisode(firstEpisodeInfo)">첫화 보기</a>
                            </div>
                            <div class="popular" v-if="bestList !== null && bestList.length > 0">
                                <p class="title">인기 작품</p>
                                <div class="line" v-for="(item, index) in bestList" :key="index" @click="moveDetail(item)">
                                    <div class="image-thumbnail">
                                        <img :src="$store.state.siteConfig.cdnUrl + '/CHALLENGE/mainthumbnail/' + item.ctCdnFolder + '.jpg'" v-if="$store.state.siteConfig !== null" />
                                    </div>
                                    <div class="info">
                                        <p class="name">{{item.ctTitleName}}</p>
                                        <p class="writer">{{item.cwPenName}}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Banner :initBannerNumber="bannerNumber" />
                </b-container>
            </div>
        <Login :initIsShowPopup="isShowLoginPopup" @close="close('Login')" />
        <!-- <Subscribe :initIsShowPopup="isShowSubscribePopup" :initWebtoonInfo="webtoonInfo" :initSiteConfig="siteConfig" @close="close('Subscribe')" /> -->
        <PopupOK :initIsShowPopup="isShowPopupOkPopup" :initPopupOkMessage="initPopupOkMessage" @close="close('PopupOk')" />
        <Footer />
    </div>
</template>

<script>
import Header from '@/components/common/Header.vue'
import Footer from '@/components/common/Footer.vue'
import Login from '@/components/common/Login.vue'
import PopupOK from '@/components/common/PopupOK.vue'
import Pagination from '@/components/common/Pagination.vue'
import Banner from '@/components/common/Banner.vue'
import LeagueFloating from '@/components/league/LeagueFloating.vue'

export default {
    name: 'LeagueWebtoonInfo',
    pageCategory: 'League',
    components: {
        Header,
        Footer,
        Login,
        PopupOK,
        Pagination,
        Banner,
        LeagueFloating,
    },
    data() {
        return {
            categoryList: [],

            currWebtoonIdx: 0,
            currWebtoonEngName: '',
            webtoonInfo: null,
            episodeList: [],
            episodeSortType: 0,     //desc : 1, asc : 0
            firstEpisodeInfo: null,
            selectedEpisodieInfo: null,
            bannerNumber: -1,

            bestList: [],
            isSubscribe: false,

            isShowLoginPopup: false,
            // isShowSubscribePopup: false,

            isShowPopupOkPopup: false,
            initPopupOkMessage: '',
            PopupOkCallbakPage: '',    // 확인 팝업에서 확인 버튼 눌렀을때 이동할 페이지


            currentPageNo: 1,      //현재 페이지 번호
            currentPageSize: 10,    //한번에 불러올 게시물 수
            maxPageCount:10,        //한 화면에 보여질 페이지 수
            initPagingData: null,

            descHeight: 0,
            isShowAllDesc: false,

            isSetWebtoonInfo: false,
            intervalSetWebtoonInfo: null,

            initHeaderType: 'type4',
        }
    },
    async created(){
        // await this.mixinSiteConfigCheck();
        await this.mixinLoginCheck();

        if(this.$route.params.idx){
            this.currWebtoonIdx = Number(this.$route.params.idx);
            this.getCategory();
            await this.getWebtoonInfo();
            this.getFirstEpisodeInfo();
            this.getEpisodeList();
            this.getBestList();
            if(this.$store.state.loginCheck){
                this.getSubscribeState();
            }
        }
        else{
            this.$router.push('/League');
        }
    },
    mounted(){
        var that = this;
        setTimeout(()=>{that.bannerNumber = 400;}, 1000);
        this.intervalSetWebtoonInfo = setInterval(()=>{
            if(that.isSetWebtoonInfo){
                clearInterval(that.intervalSetWebtoonInfo);
                var objDesc = document.getElementById('webtoonDesc');
                that.descHeight = objDesc.clientHeight;
            }
        }, 100)
        
    },
    methods: {
        toggleSort(){
            if(this.episodeSortType === 1){
                this.episodeSortType = 0;
            } else {
                this.episodeSortType = 1;
            }
            this.episodeList = [];
            this.currentPageNo = 1;
            this.getEpisodeList();
        },
        getCategory(){ // 카테고리 정보
            var param = 
            {
                // limit : 3
            }
            this.$Axios.post(`${this.host}/infocategory`, param, { headers: { 'Pragma' : 'no-cache', 'Cache-Control': 'no-cache', 'Access-Control-Allow-Origin': '*', 'Content-type': 'application/json', }}).then(res => {
                if (res.data.returnCode === 0) {
                    this.categoryList = res.data.list;
                }
            })
            .catch((error) => {
                console.log(error);
            });
        },
        getCategoryName(category_idx){
            var name = '';
            this.categoryList.forEach(element =>{
                if(category_idx === element.idx){
                    name = element.name;
                }
            })
            return name;
        },
        async getWebtoonInfo(){
            var param = 
            {
                idx: this.currWebtoonIdx,
                league: 1,
                sign: this.$store.state.sign,
                ticket: this.$store.state.ticket,
                viewAdult: this.$store.state.isCheckedAdult ? 1 : 0,
            }
            // console.log(param);
            await this.$Axios.post(`${this.league_host}/challenge/webtoon`, param, { headers: { 'Pragma' : 'no-cache', 'Cache-Control': 'no-cache', 'Access-Control-Allow-Origin': '*', 'Content-type': 'application/json', }}).then(async(res) => {
                // console.log('--webtoon info--')
                // console.log(res.data);
                if (res.data.returnCode === 0) {
                    if(res.data.data.ctAdult === 1){
                        if(this.$store.state.loginCheck === false){
                            this.initPopupOkMessage = '로그인 후 이용 가능합니다.';
                            this.PopupOkCallbakPage = '/League';
                            this.isShowPopupOkPopup = true;
                        }
                        else if(this.$store.state.adult === false){
                            this.initPopupOkMessage = '성인 인증 후 이용 가능합니다.';
                            this.PopupOkCallbakPage = '/League';
                            this.isShowPopupOkPopup = true;
                        }
                    }
                    this.webtoonInfo = res.data.data;
                    this.isSetWebtoonInfo = true;
                }
                else if(res.data.returnCode === 300){
                    this.initPopupOkMessage = '존재하지 않는 웹툰 입니다.';
                    this.PopupOkCallbakPage = '/League';
                    this.isShowPopupOkPopup = true;
                }
                else if(res.data.returnCode === 301){
                    if(this.$store.state.loginCheck === false){
                        this.initPopupOkMessage = '로그인 후 이용 가능합니다.';
                        this.PopupOkCallbakPage = '/League';
                        this.isShowPopupOkPopup = true;
                    }
                    else if(this.$store.state.adult === false){
                        this.initPopupOkMessage = '성인 인증 후 이용 가능합니다.';
                        this.PopupOkCallbakPage = '/League';
                        this.isShowPopupOkPopup = true;
                    }
                    else{
                        this.initPopupOkMessage = '성인 컨텐츠 활성화 후 이용 가능합니다.';
                        this.PopupOkCallbakPage = '/League';
                        this.isShowPopupOkPopup = true;
                    }
                }
                else{
                    this.$router.push('/League');
                }
            })
            .catch((error) => {
                console.log(error);
            });
        },
        getFirstEpisodeInfo(){
            var param = 
            {
                sign: this.$store.state.sign,
                ticket: this.$store.state.ticket,
                viewAdult: this.$store.state.isCheckedAdult ? 1 : 0,
                pageNo: 1,
                pageSize: 1,
                wtIdx: this.currWebtoonIdx,
            }
            this.$Axios.post(`${this.league_host}/challenge/webtoon/episode/list`, param, { headers: { 'Pragma' : 'no-cache', 'Cache-Control': 'no-cache', 'Access-Control-Allow-Origin': '*', 'Content-type': 'application/json', }}).then(async(res) => {
                // console.log('--webtoon episode first info--')
                // console.log(res);
                if (res.data.returnCode === 0) {
                    if(res.data.list[0]){
                        this.firstEpisodeInfo = res.data.list[0];
                    }
                }
            })
            .catch((error) => {
                console.log(error);
            });
        },
        getEpisodeList(){
            var param = 
            {
                sign: this.$store.state.sign,
                ticket: this.$store.state.ticket,
                viewAdult: this.$store.state.isCheckedAdult ? 1 : 0,
                pageNo: this.currentPageNo,
                pageSize: this.currentPageSize,
                wtIdx: this.currWebtoonIdx,
                sortSeq: this.episodeSortType
            }
            // console.log(param);
            this.$Axios.post(`${this.league_host}/challenge/webtoon/episode/list`, param, { headers: { 'Pragma' : 'no-cache', 'Cache-Control': 'no-cache', 'Access-Control-Allow-Origin': '*', 'Content-type': 'application/json', }}).then(async(res) => {
                // console.log('--webtoon episode list--')
                // console.log(res);
                if (res.data.returnCode === 0) {
                    this.episodeList = res.data.list;
                    this.initPagingData = res.data.page;
                }
                // else if(res.data.returnCode === 300){
                //     this.initPopupOkMessage = '존재하지 않는 웹툰 입니다.';
                //     this.PopupOkCallbakPage = '/League';
                //     this.isShowPopupOkPopup = true;
                // }
                // else{
                //     // console.log(5);
                //     this.$router.push('/');
                // }
            })
            .catch((error) => {
                console.log(error);
            });
        },
        getBestList(){
            var param = 
            {
            }
            this.$Axios.post(`${this.league_host}/challenge/best`, param, { headers: { 'Pragma' : 'no-cache', 'Cache-Control': 'no-cache', 'Access-Control-Allow-Origin': '*', 'Content-type': 'application/json', }}).then(async(res) => {
                // console.log('--best list--')
                // console.log(res.data);
                if (res.data.returnCode === 0) {
                    this.bestList = res.data.list;
                }
            })
            .catch((error) => {
                console.log(error);
            });
        },
        getSubscribeState(){
            var param = 
            {
                webtoonIndex: this.currWebtoonIdx,
                ticket: this.$store.state.ticket,
                sign: this.$store.state.sign,
                viewAdult: this.$store.state.isCheckedAdult ? 1 : 0
            }
            // console.log(param);
            this.$Axios.post(`${this.league_host}/challenge/mylibrary/subscribe/state`, param, { headers: { 'Pragma' : 'no-cache', 'Cache-Control': 'no-cache', 'Access-Control-Allow-Origin': '*', 'Content-type': 'application/json', }}).then(res => {
                // console.log(res.data);
                if (res.data.returnCode === 0) {
                    this.isSubscribe = res.data.data;
                    // console.log(this.isSubscribe);
                }
            })
            .catch((error) => {
                console.log(error);
            });
        },
        subscribe(){
            var param = 
            {
                webtoonIndex: this.currWebtoonIdx,
                sign: this.$store.state.sign,
                ticket: this.$store.state.ticket,
                viewAdult: this.$store.state.isCheckedAdult ? 1: 0
            }
            this.$Axios.post(`${this.league_host}/challenge/mylibrary/subscribe`, param, { headers: { 'Pragma' : 'no-cache', 'Cache-Control': 'no-cache', 'Access-Control-Allow-Origin': '*', 'Content-type': 'application/json', }}).then(async(res) => {
                // console.log(res.data);
                if (res.data.returnCode === 0) {
                    this.isSubscribe = true;
                }
            })
            .catch((error) => {
                console.log(error);
            });
        },
        unsubscribe(){
            var param = 
            {
                webtoonList: [this.currWebtoonIdx],
                sign: this.$store.state.sign,
                ticket: this.$store.state.ticket,
                viewAdult: this.$store.state.isCheckedAdult ? 1: 0
            }
            this.$Axios.post(`${this.league_host}/challenge/mylibrary/unsubscribe`, param, { headers: { 'Pragma' : 'no-cache', 'Cache-Control': 'no-cache', 'Access-Control-Allow-Origin': '*', 'Content-type': 'application/json', }}).then(async(res) => {
                // console.log(res.data);
                if (res.data.returnCode === 0) {
                    this.isSubscribe = false;
                }
            })
            .catch((error) => {
                console.log(error);
            });
        },
        close(popupType){
            switch(popupType){
                
                case 'Login':
                    this.isShowLoginPopup = false;
                    break;
                case 'PopupOk':
                    this.isShowPopupOkPopup = false;
                    if(this.PopupOkCallbakPage === 'reload'){
                        location.reload();
                    }
                    else if(this.PopupOkCallbakPage !== ''){
                        this.$router.push(this.PopupOkCallbakPage);
                    }
                    this.PopupOkCallbakPage = '';
                    break;
            }
        },
        showEpisode(episodeInfo){
            if(this.$store.state.loginCheck === false){
                this.isShowLoginPopup = true;
                return;
            }
            this.$router.push('/League/Webtoon/Viewer/'+ this.currWebtoonIdx + '/' + episodeInfo.ceEpisodeIdx);
        },
        moveDetail(item){
            if(item.ctAdult === 1){
                if(this.$store.state.loginCheck === false){
                    this.isShowLoginPopup = true;
                }
                else if(!this.$store.state.adult){
                    var src = this.$store.state.siteConfig.niceUrl + '?email=' + this.$store.state.email;
                    window.open(src, 'popupChk', 'width=500, height=550, top=100, left=100, fullscreen=no, menubar=no, status=no, toolbar=no, titlebar=no, location=no, scrollbar=no');
                }
                else if(!this.$store.state.isCheckedAdult){
                    this.initPopupOkMessage = '성인 컨텐츠 활성화 후 이용 가능합니다.';
                    // this.PopupOkCallbakPage = '/League';
                    this.isShowPopupOkPopup = true;
                }
                else{
                    this.$router.push('/League/Webtoon/Info/' + item.ctWebtoonIdx);
                }
            }else{
                this.$router.push('/League/Webtoon/Info/' + item.ctWebtoonIdx);
            }
        },
        movePaging(pageNo){
            this.currentPageNo = pageNo;
            this.getEpisodeList();
        },
    }
}
</script>