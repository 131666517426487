<template>
    <div class="enrollment2">
        <Header :initHeaderType="initHeaderType" />
        <Login />
        <b-container>
            <div class="title_area" v-if="webtoonInfo === null">
                <p>작품등록</p>
                <p>아튜브 챌린지에 등록하고 싶은 웹툰을 올려주세요.</p>
            </div>
            <div class="title_area" v-else>
                <p>작품수정</p>
                <p>내용이나 데이터를 수정하게 되면 실시간으로 임시 저장된 상태로 바뀝니다.</p>
            </div>
            <b-row class="age">
                <b-col lg="2" md="2">작품등급</b-col>
                <b-col lg="10" md="10">
                    <b-form-radio v-model="isAdult" name="some-radios" :value="true">성인</b-form-radio>
                    <b-form-radio v-model="isAdult" name="some-radios" :value="false">전연령</b-form-radio>
                </b-col>
            </b-row>
            <b-row class="p_title">
                <b-col lg="2" md="2">제목</b-col>
                <b-col lg="10" md="10">
                    <input type="text" v-model="title" maxlength="20" @input="checkTitle">
                    <p class="ps">제목은 띄어쓰기 포함 최대 20자 까지 가능합니다.</p>
                </b-col>
            </b-row>
            <b-row class="field">
                <b-col lg="2" md="2">분야선택</b-col>
                <b-col lg="10" md="10">
                    <b-row>
                        <b-col cols="1" v-for="(item, index) in checkboxCategoryList" :key="index">
                            <b-form-checkbox v-model="item.isChecked" :value="true" :unchecked-value="false" @change="changeCheck(index)">{{item.name}}</b-form-checkbox>
                        </b-col>
                    </b-row>
                    <p class="ps">최대 2개까지 선택 가능합니다.</p>
                </b-col>
            </b-row>
            <b-row class="img_upload">
                <b-col lg="2" md="2">이미지 <span>업로드</span></b-col>
                <b-col lg="10" md="10">
                    <label class="banner1" for="idThumbnailCover">
                        <img :src="thumbnailCover.path" v-if="thumbnailCover !== null" />
                        <img :src="$store.state.siteConfig.cdnUrl + '/CHALLENGE/cover/' + webtoonInfo.ctCdnFolder + '.jpg'" alt="cover" v-else-if="webtoonInfo !== null && $store.state.siteConfig !== null">
                        <p v-else>1920*320 <span>작품 목록 상단 배너</span></p>
                        <input class="display-none" id="idThumbnailCover" name="image" ref="filesCover"  @change="uploadThumbnailCover" :accept="accept" type="file">
                    </label>
                    <label class="banner2" for="idThumbnailMain">
                        <img :src="thumbnailMain.path" v-if="thumbnailMain !== null" />
                        <img :src="$store.state.siteConfig.cdnUrl + '/CHALLENGE/mainthumbnail/' + webtoonInfo.ctCdnFolder + '.jpg'" alt="main" v-else-if="webtoonInfo !== null && $store.state.siteConfig !== null">
                        <p v-else>272*272 <span>메인 썸네일</span></p>
                        <input class="display-none" id="idThumbnailMain" name="image" ref="filesMain"  @change="uploadThumbnailMain" :accept="accept" type="file">
                    </label>
                    <label class="banner3" for="idThumbnailBest">
                        <img :src="thumbnailBest.path" v-if="thumbnailBest !== null"/>
                        <img :src="$store.state.siteConfig.cdnUrl + '/CHALLENGE/bestthumbnail/' + webtoonInfo.ctCdnFolder + '.jpg'" alt="best" v-else-if="webtoonInfo !== null && $store.state.siteConfig !== null">
                        <p v-else>320*220 <span>베스트 선정 썸네일</span></p>
                        <input class="display-none" id="idThumbnailBest" name="image" ref="filesBest"  @change="uploadThumbnailBest" :accept="accept" type="file">
                    </label>
                    <p class="ps">- 파일명 : 특수문자 불가, 한글, 영문, 숫자만 가능합니다.  </p>
                    <p class="ps">- 커버 이미지 : 1920*320, 메인 이미지 : 272*272, 베스트 이미지 : 320*220</p>
                    <p class="ps">- 이미지 파일 크기는 500KB이하, jpg만 업로드 가능합니다.</p>
                </b-col>
            </b-row>
            <b-row class="contents">
                <b-col lg="2" md="2">내용</b-col>
                <b-col lg="10" md="10">
                    <div>
                    <b-textarea v-model="description" maxlength="160"></b-textarea>
                    <p>{{description.length}}/160</p>
                    </div>
                    <p class="ps">- 160자 안으로 기입해주세요. 내용이 길어질 경우에는 때에 따라 뒷부분이 잘릴 수 있으며 등록이후애도 수정이 가능합니다.</p>
                </b-col>
            </b-row>
            <p class="ps2" v-if="webtoonInfo === null">* 저장 상태는 플랫폼에 비공개된 상태이며 회차를 1회 이상 올리고 등록 신청해야 플랫폼에 노출이 됩니다.</p>
            <p class="ps2" v-else>* 등록신청 이후 플랫폼에 공개됩니다. 뒤로가기를 할 경우 해당 작품은 수정된 부분이 있을 시 비공개 되며 자동 저장 됩니다.</p>
            <div class="buttons">
                <router-link tag="p" :to="webtoonInfo === null ? '/League/Writer/Info/' : '/League/Writer/Webtoon/Info/' + webtoonInfo.ctWebtoonIdx" class="cancel">{{webtoonInfo === null ? '취소' : '뒤로가기'}}</router-link>
                <p class="next" @click="webtoonSave(false)">{{webtoonInfo === null ? '작품저장' : '등록신청'}}</p>
                <p class="save" @click="webtoonSave(true)" v-if="webtoonInfo === null">저장 후 1회 올리기</p>
            </div>
        </b-container>
        <PopupEnroll :initIsShowPopup="isShowPopupEnroll" @close="close('Enroll')" />
        <PopupOK :initIsShowPopup="isShowPopupOkPopup" :initPopupOkMessage="initPopupOkMessage" @close="close('PopupOk')" />
        <PopupConfirm :initIsShowPopup="isShowPopupConfirmPopup" :initPopupConfrimMessage="initPopupConfrimMessage" @confirmCancel="close('PopupConfirm')" @confirmOk="confirmOk" />
        <Footer />
    </div>
</template>

<script>
import Header from '@/components/common/Header.vue'
import Footer from '@/components/common/Footer.vue'
import Login from '@/components/common/Login.vue'
import PopupOK from '@/components/common/PopupOK.vue'
import PopupConfirm from '@/components/common/PopupConfirm.vue'
import PopupEnroll from '@/components/league/PopupEnroll.vue'

export default {
    name: 'LeagueWriterWebtoon',
    pageCategory: 'League',
    components: {
        Header,
        Footer,
        Login,
        PopupEnroll,
        PopupOK,
        PopupConfirm,
    },
    data(){
        return{

            //웹툰 정보 수정 시
            currWebtoonIdx: 0,
            writerInfo: null,
            webtoonInfo: null,
            webtoonKey: 0,
            
            categoryList: [],

            isShowPopupEnroll: false,

            isShowPopupOkPopup: false,
            initPopupOkMessage: '',
            PopupOkCallbakPage: '',    // 확인 팝업에서 확인 버튼 눌렀을때 이동할 페이지

            isShowPopupConfirmPopup: false,
            initPopupConfrimMessage: '',
            PopupConfirmCallbakPage: '',    // 확인 팝업에서 확인 버튼 눌렀을때 이동할 페이지

            isAdult: false,
            title: '',
            checkboxCategoryList:[
                {name:'일상', isChecked:false},
                {name:'액션', isChecked:false},
                {name:'판타지', isChecked:false},
                {name:'드라마', isChecked:false},
                {name:'스릴러', isChecked:false},
                {name:'개그', isChecked:false},
                {name:'무협', isChecked:false},
                {name:'감성', isChecked:false},
                {name:'시대극', isChecked:false},
                {name:'스포츠', isChecked:false}
            ],
            categoryCheckedCount: 0,
            description: '',

            thumbnailCover: null,
            thumbnailMain: null,
            thumbnailBest: null,
            accept: 'image/jpeg,image/jpg',
            fileMaxSize: 1 * 500 * 1024,

            initHeaderType: 'type1',
        }
    },
    async created(){
        var isPc = this.isPc();
        if(!isPc){
            this.initPopupOkMessage = '모바일 환경에서는 감상만 가능합니다.<br/>작가 활동은 PC 환경에서 이용해주세요.'
            this.PopupOkCallbakPage = '/League';
            this.isShowPopupOkPopup = true;
            return;
        }
        // await this.mixinSiteConfigCheck();
        await this.mixinLoginCheck();
        var checkWriter = await this.checkWriter(); //작가 등록 여부 확인
        if(checkWriter === null){   //체크 에러
            this.initPopupOkMessage = '작가 정보 체크 에러'
            this.PopupOkCallbakPage = '/League';
            this.isShowPopupOkPopup = true;
            return;
        }
        else if(!checkWriter){  //작가 등록 안된 상태
            this.initPopupOkMessage = '접근 권한이 없습니다.'
            this.PopupOkCallbakPage = '/League';
            this.isShowPopupOkPopup = true;
            return;
        }

        await this.getCategory();

        if(this.$route.params.idx){
            this.currWebtoonIdx = Number(this.$route.params.idx);
            this.getWebtoonInfo();
        }
        else{
            this.isShowPopupEnroll = true;
            this.reqWebtoonKey();
        }
    },
    methods:{
        async reqWebtoonKey(){
            var param = 
            {
                sign: this.$store.state.sign,
                ticket: this.$store.state.ticket,
                viewAdult: this.$store.state.isCheckedAdult ? 1: 0,
                writerIdx: this.writerInfo.cwWriterIdx
            }
            // console.log(param);
            await this.$Axios.post(`${this.league_host}/challenge/writer/webtoon/reqwebtoonkey`, param, { headers: { 'Pragma' : 'no-cache', 'Cache-Control': 'no-cache', 'Access-Control-Allow-Origin': '*', 'Content-type': 'application/json', }}).then(async(res) => {
                // console.log(res.data);
                if (res.data.returnCode === 0) {    //작가 등록됨
                    this.webtoonKey = res.data.webtoonKey;
                }
                
                if( res.data.returnCode !== 0 || this.webtoonKey === null){
                    this.isShowPopupEnroll = false;
                    this.initPopupOkMessage = '웹툰 번호 발급 실패';
                    this.PopupOkCallbakPage = '/League';
                    this.isShowPopupOkPopup = true;
                    return false;
                }
            })
            .catch((error) => {
                console.log(error);
                return null;
            });
        },
        async checkWriter(){
            var param = 
            {
                sign: this.$store.state.sign,
                ticket: this.$store.state.ticket,
                viewAdult: this.$store.state.isCheckedAdult ? 1: 0
            }
            return await this.$Axios.post(`${this.league_host}/challenge/writer/check`, param, { headers: { 'Pragma' : 'no-cache', 'Cache-Control': 'no-cache', 'Access-Control-Allow-Origin': '*', 'Content-type': 'application/json', }}).then(async(res) => {
                // console.log(res.data.writer);
                if (res.data.returnCode === 0) {    //작가 등록됨
                    this.writerInfo = res.data.writer;
                    return true;
                }
                else if(res.data.returnCode === 41){    //작가 등록 안됨
                    return false;
                }
                else{   //기타 에러
                    return null;
                }
            })
            .catch((error) => {
                console.log(error);
                return null;
            });
        },
        getWebtoonInfo(){
            var param = 
            {
                sign: this.$store.state.sign,
                ticket: this.$store.state.ticket,
                viewAdult: this.$store.state.isCheckedAdult ? 1 : 0,
                wtIdx: this.currWebtoonIdx,
                writerIdx: this.writerInfo.cwWriterIdx,
                wrName: this.writerInfo.cwPenName
            }
            this.$Axios.post(`${this.league_host}/challenge/writer/webtoon`, param, { headers: { 'Pragma' : 'no-cache', 'Cache-Control': 'no-cache', 'Access-Control-Allow-Origin': '*', 'Content-type': 'application/json', }}).then(async(res) => {
                // console.log('--webtoon info--')
                // console.log(res.data);
                if (res.data.returnCode === 0) {
                    this.webtoonInfo = res.data.data;

                    if(this.webtoonInfo.ctAdult === 1){
                        this.isAdult = true;
                    }
                    this.webtoonKey = this.webtoonInfo.ctCdnFolder;
                    this.title = this.webtoonInfo.ctTitleName;
                    this.description = this.webtoonInfo.ctDesc;
                    var mainCategoryName = null;
                    var subCategoryName = null;
                    if(this.webtoonInfo.ctCategoryMain !== null){
                        mainCategoryName = this.getCategoryName(this.webtoonInfo.ctCategoryMain);
                        this.categoryCheckedCount++;
                    }
                    if(this.webtoonInfo.ctCategorySub !== null){
                        subCategoryName = this.getCategoryName(this.webtoonInfo.ctCategorySub);
                        this.categoryCheckedCount++;
                    }
                    this.checkboxCategoryList.forEach(item=>{
                        if(item.name === mainCategoryName || item.name === subCategoryName ){
                            item.isChecked = true;
                        }
                    })
                }
                else if(res.data.returnCode === 300){
                    this.initPopupOkMessage = '존재하지 않는 웹툰 입니다.';
                    this.PopupOkCallbakPage = '/League';
                    this.isShowPopupOkPopup = true;
                }
                else if(res.data.returnCode === 301){
                    this.initPopupOkMessage = '성인 인증 후 이용 가능합니다.';
                    this.PopupOkCallbakPage = '/League';
                    this.isShowPopupOkPopup = true;
                }
                else{
                    this.$router.push('/League');
                }
            })
            .catch((error) => {
                console.log(error);
            });
        },
        async getCategory(){ // 카테고리 정보
            var param = 
            {
                // limit : 3
            }
            await this.$Axios.post(`${this.host}/infocategory`, param, { headers: { 'Pragma' : 'no-cache', 'Cache-Control': 'no-cache', 'Access-Control-Allow-Origin': '*', 'Content-type': 'application/json', }}).then(res => {
                if (res.data.returnCode === 0) {
                    this.categoryList = res.data.list;
                }
            })
            .catch((error) => {
                console.log(error);
            });
        },
        getCategoryIdx(category_name){
            var categoryInfo = this.categoryList.find((category) => category.name === category_name);
            return categoryInfo.idx;
        },
        getCategoryName(category_idx){
            var categoryInfo = this.categoryList.find((category) => category.idx === category_idx);
            return categoryInfo.name;
        },
        changeCheck(index){
            var isChecked = this.checkboxCategoryList[index].isChecked;
            
            if(isChecked){  //checked 인 상태라면
                if(this.categoryCheckedCount >= 2){ //현재 총 체크된 수가 2개 이상일때 체크 해제
                    this.checkboxCategoryList[index].isChecked = false;
                }
                else{   //2개 미만일 경우 체크된 수 + 1
                    this.categoryCheckedCount++;
                }
            }
            else{   //not_checked 상태면 총 체크 수 -1 
                this.categoryCheckedCount--;
            }
        },
        async uploadThumbnailCover(e) {
            let files = e.target.files || e.dataTransfer.files;
            if (!files.length) {
                this.initPopupOkMessage = '존재하지 않는 파일입니다.';
                this.isShowPopupOkPopup = true;
            }
            else{
                let userUploadFile = this.$refs.filesCover.files[0];
                var isCheckFile = await this.checkFileNameSize(userUploadFile);
                if(isCheckFile){
                   this.createImage(userUploadFile, 'cover', 1920, 320);
                }
            }
            if (document.getElementById('idThumbnailCover')) {
                document.getElementById('idThumbnailCover').value = '';
            }
        },
        async uploadThumbnailMain(e) {
            let files = e.target.files || e.dataTransfer.files;
            if (!files.length) {
                this.initPopupOkMessage = '존재하지 않는 파일입니다.';
                this.isShowPopupOkPopup = true;
            }
            else{
                let userUploadFile = this.$refs.filesMain.files[0];
                var isCheckFile = await this.checkFileNameSize(userUploadFile);
                if(isCheckFile){
                   this.createImage(userUploadFile, 'main', 272, 272);
                }
            }
            if (document.getElementById('idThumbnailMain')) {
                document.getElementById('idThumbnailMain').value = '';
            }
        },
        async uploadThumbnailBest(e) {
            let files = e.target.files || e.dataTransfer.files;
            if (!files.length) {
                this.initPopupOkMessage = '존재하지 않는 파일입니다.';
                this.isShowPopupOkPopup = true;
            }
            else{
                let userUploadFile = this.$refs.filesBest.files[0];
                var isCheckFile = await this.checkFileNameSize(userUploadFile);
                if(isCheckFile){
                   this.createImage(userUploadFile, 'best', 320, 220);
                }
            }
            if (document.getElementById('idThumbnailBest')) {
                document.getElementById('idThumbnailBest').value = '';
            }
        },
        checkFileNameSize(file){
            let filter =  /^[ㄱ-ㅎ|가-힣|a-z|A-Z|0-9|_|-|]+$/;
            var fileName = file.name.substring(0,file.name.lastIndexOf('.'));
            if(!filter.test(fileName)){
                this.initPopupOkMessage = '파일명은 한글, 영문, 숫자만 가능합니다.';
                this.isShowPopupOkPopup = true;
                return false;
            }
            else if(file.size > this.fileMaxSize){
                this.initPopupOkMessage = '최대 파일 사이즈는 500KB 입니다.';
                this.isShowPopupOkPopup = true;
                return false;
            }
            return true;
        },
        createImage (file, type, limit_width, limit_height) {
            var that = this;
            let reader = new FileReader();
            var isSuccessUpload = false;
            reader.onload = (e) => {
                var image = new Image();
                image.src = e.target.result;

                image.onload = async function () {
                    var height = this.height;
                    var width = this.width;
                    if (height !== limit_height || width !== limit_width) {
                        that.initPopupOkMessage = '썸네일 이미지 크기는' + limit_width + '*' + limit_height + 'px 로 제한됩니다.'
                        that.isShowPopupOkPopup = true;
                    } else {
                        if(type === 'cover'){
                            isSuccessUpload = await that.reqUploadThumbnail(0, file);
                            if(isSuccessUpload)
                                that.thumbnailCover = {name: file.name, path: image.src};
                            else{
                                if(!that.isShowPopupOkPopup){
                                    that.initPopupOkMessage = '이미지 업로드 실패.';
                                    that.isShowPopupOkPopup = true;
                                }
                            }
                        }
                        else if(type === 'main'){
                            isSuccessUpload = await that.reqUploadThumbnail(1, file);
                            if(isSuccessUpload)
                                that.thumbnailMain = {name: file.name, path: image.src};
                            else{
                                if(!that.isShowPopupOkPopup){
                                    that.initPopupOkMessage = '이미지 업로드 실패.';
                                    that.isShowPopupOkPopup = true;
                                }
                            }
                        }
                        else if(type === 'best'){
                            isSuccessUpload = await that.reqUploadThumbnail(2, file);
                            if(isSuccessUpload)
                                that.thumbnailBest= {name: file.name, path: image.src};
                            else{
                                if(!that.isShowPopupOkPopup){
                                    that.initPopupOkMessage = '썸네일 업로드 실패.';
                                    that.isShowPopupOkPopup = true;
                                }
                            }
                        }
                    }
                };
            }
            reader.readAsDataURL(file);
        },
        checkTitle(){
            var filter = /[^a-z|A-Z|0-9|ㄱ-ㅎ|가-힣|\-|(),._!:/'"?|\s+]/g;
            this.title = this.title.replace(filter, "");
        },
        async reqUploadThumbnail(type, file){
            let formData = new FormData();
            formData.append('ticket', this.$store.state.ticket);
            formData.append('sign', this.$store.state.sign);
            formData.append('viewAdult', this.$store.state.isCheckedAdult ? 1 : 0);
            formData.append('webtoonkey',  this.webtoonKey);
            formData.append('writerIdx',  this.writerInfo.cwWriterIdx);
            formData.append('uploadType',  type);
            // formData.append('episodeNumber',  1);
            formData.append('file', file);

            return await this.$Axios.post(`${this.league_host}/challenge/writer/imageUpload/thumbnail`, formData, { headers: { 'Pragma' : 'no-cache', 'Cache-Control': 'no-cache', 'Access-Control-Allow-Origin': '*', 'Content-type': 'multipart/form-data', }}).then(async(res) => {
                // console.log(res.data);
                if (res.data.returnCode === 0) {
                    return true;
                }
                else if (res.data.returnCode === 609) {
                    if(type === 0){
                        this.initPopupOkMessage = '커버 이미지는 1920*320px 로 제한됩니다.';
                    } else if(type === 1){
                        this.initPopupOkMessage = '메인 썸네일 이미지는 272*272px 로 제한됩니다.';
                    } else if(type === 2){
                        this.initPopupOkMessage = '베스트 썸네일 이미지는 320*220px 로 제한됩니다.';
                    }
                    this.isShowPopupOkPopup = true;
                    return false;
                }
                else{
                    alert(res.data.descriptioin);
                    return false;
                }
            })
            .catch(error => {
                error;
                return false;
            });
        },
        webtoonSave(isMoveEpisode){
            if(this.webtoonInfo !== null){
                this.initPopupConfrimMessage = '작품 정보를 수정하시면 검수 기간내 해당 작품은 플랫폼에 노출되지 않습니다.';
            }else{
                this.initPopupConfrimMessage = '작품 정보를 저장하시겠습니까?';
            }
            
            if(isMoveEpisode){
                this.PopupConfirmCallbackPage = 'webtoonSaveAfterEpisode';
            } else {
                this.PopupConfirmCallbackPage = 'webtoonSave';
            }
            this.isShowPopupConfirmPopup = true;
        },
        async reqWebtoonSave(isMoveEpisode){
            if(this.title === ''){
                this.initPopupOkMessage = '제목을 입력해 주세요.';
                this.isShowPopupOkPopup = true;
                return false;
            }
            if(this.categoryCheckedCount < 1){
                this.initPopupOkMessage = '분야를 선택해 주세요. (최소 1개, 최대2개)';
                this.isShowPopupOkPopup = true;
                return false;
            }
            if(this.description === ''){
                this.initPopupOkMessage = '내용을 입력해 주세요.';
                this.isShowPopupOkPopup = true;
                return false;
            }

            var mainCategoryIdx = null;
            var subCategoryIdx = null;
            this.checkboxCategoryList.forEach(item=>{
                if(item.isChecked){
                    if(mainCategoryIdx === null){
                        mainCategoryIdx = this.getCategoryIdx(item.name);
                    }else{
                        subCategoryIdx = this.getCategoryIdx(item.name);
                    }
                }
            })
            var param = 
            {
                adult: this.isAdult ? 1 : 0,
                desc:  this.description,
                mainCategory: mainCategoryIdx,
                sign: this.$store.state.sign,
                subCategory: subCategoryIdx,
                ticket: this.$store.state.ticket,
                title: this.title,
                viewAdult: this.$store.state.isCheckedAdult ? 1: 0,
                writerIdx: this.writerInfo.cwWriterIdx,
                wtIdx: this.currWebtoonIdx,
                chWebtoonKey: this.webtoonKey,
            }
            // console.log(param);
            if(this.webtoonInfo === null){
                if(this.thumbnailCover === null){
                    this.initPopupOkMessage = '커버 이미지를 등록해 주세요.';
                    this.isShowPopupOkPopup = true;
                    return false;
                }
                if(this.thumbnailMain === null){
                    this.initPopupOkMessage = '메인 썸네일 이미지를 등록해 주세요.';
                    this.isShowPopupOkPopup = true;
                    return false;
                }
                if(this.thumbnailBest === null){
                    this.initPopupOkMessage = '베스트 썸네일 이미지를 등록해 주세요.';
                    this.isShowPopupOkPopup = true;
                    return false;
                }
                await this.$Axios.post(`${this.league_host}/challenge/writer/webtoon/req`, param, { headers: { 'Pragma' : 'no-cache', 'Cache-Control': 'no-cache', 'Access-Control-Allow-Origin': '*', 'Content-type': 'application/json', }}).then(res => {
                    // console.log(res.data);
                    if (res.data.returnCode === 0) {
                        this.initPopupOkMessage = '작품 등록 완료';
                        if(isMoveEpisode){
                            this.PopupOkCallbakPage = '/League/Writer/Episode/Regist/' + res.data.data.ctWebtoonIdx;
                        } else {
                            this.PopupOkCallbakPage = '/League/Writer/Info';
                        }
                        this.isShowPopupOkPopup = true;
                    }else {
                        this.initPopupOkMessage = '작품 등록 실패<br/>('+res.data.description+')';
                        // this.PopupOkCallbakPage = '/League/Writer/Info';
                        this.isShowPopupOkPopup = true;
                    }
                })
                .catch((error) => {
                    this.initPopupOkMessage = '작품 등록 실패<br/>('+error+')';
                    // this.PopupOkCallbakPage = '/League/Writer/Info';
                    this.isShowPopupOkPopup = true;
                    // console.log(error);
                });
            }else{
                if(param.title === this.webtoonInfo.ctTitleName){
                    param.title = null;
                }
                await this.$Axios.post(`${this.league_host}/challenge/writer/webtoon/update`, param, { headers: { 'Pragma' : 'no-cache', 'Cache-Control': 'no-cache', 'Access-Control-Allow-Origin': '*', 'Content-type': 'application/json', }}).then(res => {
                    // console.log(res.data);
                    if (res.data.returnCode === 0) {
                        this.initPopupOkMessage = '작품 정보 수정 완료';
                        this.PopupOkCallbakPage = '/League/Writer/Info';
                        this.isShowPopupOkPopup = true;
                    }else {
                        this.initPopupOkMessage = '작품 정보 수정 실패<br/>('+res.data.description+')';
                        // this.PopupOkCallbakPage = '/League/Writer/Info';
                        this.isShowPopupOkPopup = true;
                    }
                })
                .catch((error) => {
                    this.initPopupOkMessage = '작품 정보 수정 실패<br/>('+error+')';
                    // this.PopupOkCallbakPage = '/League/Writer/Info';
                    this.isShowPopupOkPopup = true;
                    // console.log(error);
                });
            }
        },
        close(popupType){
            switch(popupType){
                case 'PopupConfirm':
                    this.isShowPopupConfirmPopup = false;
                    break;
                case 'Enroll':
                    this.isShowPopupEnroll = false;
                    break;
                case 'PopupOk':
                    this.isShowPopupOkPopup = false;
                    if(this.PopupOkCallbakPage !== ''){
                        this.$router.push(this.PopupOkCallbakPage);
                    }
                    this.PopupOkCallbakPage = '';
                    break;
            }
        },
        confirmOk(){
            if(this.PopupConfirmCallbackPage === 'webtoonSave'){
                this.reqWebtoonSave(false);
            }
            else if(this.PopupConfirmCallbackPage == 'webtoonSaveAfterEpisode'){
                this.reqWebtoonSave(true)
            }
            this.isShowPopupConfirmPopup = false;
        },
    }

}
</script>