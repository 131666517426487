<template>
    <div class="toondetail wrap">
        <Header :initHeaderType="initHeaderType" />
            <div class="detail_top" v-if="$store.state.siteConfig !== null && webtoonInfo !== null">
                <img :src="$store.state.siteConfig.cdnUrl + '/KR/cover/' + webtoonInfo.englishName + '.jpg'" alt="커버" class="pc">
                <img :src="$store.state.siteConfig.cdnUrl + '/KR/title/' + webtoonInfo.englishName + '.jpg'" alt="커버" class="mobile">
                <b-container v-if="webtoonInfo !== null">
                    <p class="genre" v-if="categoryList.length > 0">{{getCategoryName(webtoonInfo.category)}}</p>
                    <h1>{{webtoonInfo.titleName}}</h1>
                    <p class="writer">{{webtoonInfo.publisher}}</p>
                    <div class="util">
                        <a @click="$router.go(-1)" class="back-btn"><i class="icon icon-back"></i></a>
                        <a class="start_see" @click="showBuyPopup(firstEpisodeInfo)">첫화 보기</a>
                        <div class="util_right" v-if="$store.state.loginCheck">
                            <a class="subscribe full" @click="unsubscribe" v-if="isSubscribe">♥ 구독 중</a>
                            <a class="subscribe empty" @click="subscribe" v-else>♡ 구독</a>
                        </div>
                    </div>
                    <div class="m_story">
                        <div class="info">
                            <img src="@/assets/images/mobile/m_genre.svg" alt="장르">
                            <p>{{getCategoryName(webtoonInfo.category)}}</p>
                            <!-- <img src="@/assets/images/hot/eye.svg" alt="본 수"> -->
                            <!-- <p>{{webtoonInfo.viewCount | comma}}</p> -->
                            <img src="@/assets/images/mobile/star_line.svg" alt="star" @click="showGradePopup()">
                            <p v-if="webtoonInfo !== null">{{webtoonInfo.starAvg}}</p>
                        </div>
                        <div class="text_box fold">
                            <p id="webtoonDesc" class="story_text" :class="[!isShowAllDesc && descHeight > 36 ? '' : 'show-all']" v-if="webtoonInfo !== null" v-html="webtoonInfo.desc"></p>
                            <div class="story_collapse" @click="isShowAllDesc = !isShowAllDesc">
                                <p v-if="isShowAllDesc">접기</p>
                                <p v-else>더보기</p>
                                <img src="@/assets/images/mobile/icon_right_arrow.svg" alt="화살표" :class="[isShowAllDesc ? 'btn-hide' : 'btn-show']">
                            </div>
                        </div>
                    </div>
                </b-container>
            </div>
            <div class="detail_bottom">
            <!-- <Recentsee /> -->
                <b-container>
                    <div class="toon_number clearfix">
                        <div class="left">
                            <div class="story">
                                <p class="title">작품 스토리</p>
                                <p class="cont" v-if="webtoonInfo !== null" v-html="webtoonInfo.desc"></p>
                            </div>
                            <div class="page">
                                <div class="page_head">
                                    <p v-if="initPagingData !== null">총 {{initPagingData.totalRecordSize}}화</p>
                                    <a @click="toggleSort">정렬변경</a>
                                </div>
                                <div class="page_cont">
                                    <div v-for="(item, index) in episodeList" :key="index" class="page_detail" @click="showBuyPopup(item)">
                                        <img :src="$store.state.siteConfig.cdnUrl + '/KR/' + item.url" alt="썸네일" class="ssum" v-if="$store.state.siteConfig !== null">
                                        <div class="info">
                                            <p class="name">{{item.titleName}}</p>
                                            <p class="day">{{item.releaseDate.substring(0,10)}}</p>
                                        </div>
                                        <p class="free" v-if="item.buyPrice === 0 && item.rentalPrice === 0">무료</p>
                                        <p class="view" v-else-if="item.byMbIdx !== null">소장중</p>
                                        <p class="buy" v-else>{{item.buyPrice}} 포인트</p>
                                    </div>
                                </div>
                            </div>
                            <Pagination :initMaxRecordCount="currentPageSize" :initMaxPageCount="maxPageCount" :initPagingData="initPagingData" @movePaging="movePaging" />
                        </div>
                        <div class="right">
                            <div class="subs_star"  @click="showGradePopup()">
                                <!-- <img src="@/assets/images/heart.svg" alt="heart"> -->
                                <!-- <p><span>구독</span>100만+</p> -->
                                <img src="@/assets/images/star.svg" alt="star"> 
                                <p v-if="webtoonInfo !== null"><span>별점</span>{{webtoonInfo.starAvg}}</p>
                            </div>
                            <div class="grey_box" v-if="BuyAllInfo !== null && BuyAllInfo.countItem > 0">
                                <!-- <div class="borrow">
                                    <p class="title">대여</p>
                                    <p class="how_much">· 한회 대여(3일) <span>→</span> 1 아트포인트</p> -->
                                    <!-- <p class="how_much">· 전체 대여(7일)<span>→</span>240 아트포인트</p> -->
                                <!-- </div> -->
                                <div class="get">
                                    <p class="title">전 회차 소장</p>
                                    <p class="how_much">· 작품 {{BuyAllInfo.countItem}}개 소장 <span>→</span> {{BuyAllInfo.sumPrice}} 아트포인트</p>
                                    <!-- <p class="how_much">· 전체 소장(7일)<span>→</span>240 아트포인트</p> -->
                                </div>
                            </div>
                            <div class="button_box" v-if="BuyAllInfo !== null && BuyAllInfo.countItem > 0">
                                <!-- <a href="#" class="all_borrow">전 회차 대여</a> -->
                                <a class="all_get" @click="showAllBuyPopup">전 회차 소장</a>
                            </div>
                            <div class="mobile_see">
                                <a @click="showBuyPopup(firstEpisodeInfo)">첫화 보기</a>
                            </div>
                            <div class="popular" v-if="hitList.length > 0">
                                <p class="title">아튜브 인기작품</p>
                                <div class="line" v-for="(item, index) in hitList" :key="index" @click="moveDetail(item)">
                                    <img :src="$store.state.siteConfig.cdnUrl + '/KR/title/' + item.twebtoonForApi.englishName + '.jpg'" v-if="$store.state.siteConfig !== null">
                                    <div class="info">
                                        <p class="name">{{item.twebtoonForApi.titleName}}</p>
                                        <p class="writer">{{item.twebtoonForApi.publisher}}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Banner :initBannerNumber="bannerNumber" />
                </b-container>
            </div>
        <Login :initIsShowPopup="isShowLoginPopup" @close="close('Login')" />
        <Grade :initIsShowPopup="isShowGradePopup" :initWebtoonIdx="currWebtoonIdx" :initStarPoint="starPoint" @close="close('Grade')" />
        <!-- <Subscribe :initIsShowPopup="isShowSubscribePopup" :initWebtoonInfo="webtoonInfo" :initSiteConfig="siteConfig" @close="close('Subscribe')" /> -->
        <Buy  :initIsShowPopup="isShowBuyPopup" :initWebtoonInfo="webtoonInfo" :initEpisodeInfo="selectedEpisodieInfo" :initSiteConfig="$store.state.siteConfig" @close="close('Buy')"  />
        <Allbuy  :initIsShowPopup="isShowAllBuyPopup" :initWebtoonInfo="webtoonInfo" :initBuyAllInfo="BuyAllInfo" :initSiteConfig="$store.state.siteConfig" @close="close('AllBuy')"  />
        <PopupOK :initIsShowPopup="isShowPopupOkPopup" :initPopupOkMessage="initPopupOkMessage" @close="close('PopupOk')" />
        <Footer />
    </div>
</template>

<script>
import Header from '@/components/common/Header.vue'
import Footer from '@/components/common/Footer.vue'
import Allbuy from '@/components/webtoon/Allbuy.vue'
import Buy from '@/components/webtoon/Buy.vue'
import Login from '@/components/common/Login.vue'
import Grade from '@/components/common/Grade.vue'
import PopupOK from '@/components/common/PopupOK.vue'
import Pagination from '@/components/common/Pagination.vue'
import Banner from '@/components/common/Banner.vue'

export default {
    name: 'WebtoonInfo',
    pageCategory: 'Webtoon',
    components: {
        Header,
        Footer,
        Allbuy,
        Buy,
        Login,
        Grade,
        PopupOK,
        Pagination,
        Banner,
    },
    data() {
        return {
            categoryList: [],

            currWebtoonIdx: 0,
            currWebtoonEngName: '',
            webtoonInfo: null,
            episodeList: [],
            episodeSortType: 0,     //desc : 1, asc : 0
            firstEpisodeInfo: null,
            selectedEpisodieInfo: null,
            bannerNumber: -1,

            hitList: [],
            BuyAllInfo: null,
            starPoint: 0,
            isSubscribe: false,

            isShowLoginPopup: false,
            isShowAllBuyPopup: false,
            isShowBuyPopup: false,
            isShowGradePopup: false,
            isShowSubscribePopup: false,

            isShowPopupOkPopup: false,
            initPopupOkMessage: '',
            PopupOkCallbakPage: '',    // 확인 팝업에서 확인 버튼 눌렀을때 이동할 페이지


            currentPageNo: 1,      //현재 페이지 번호
            currentPageSize: 10,    //한번에 불러올 게시물 수
            maxPageCount:10,        //한 화면에 보여질 페이지 수
            initPagingData: null,

            descHeight: 0,
            isShowAllDesc: false,

            isSetWebtoonInfo: false,
            intervalSetWebtoonInfo: null,

            initHeaderType: 'type4',

        }
    },
    async created(){
        
        if(this.$route.params.idx){
            this.currWebtoonIdx = Number(this.$route.params.idx);
            // await this.mixinSiteConfigCheck();
            await this.mixinLoginCheck();
            this.getCategory();
            this.getWebtoonInfo();
            this.getFirstEpisodeInfo();
            this.getEpisodeList();
            this.getHit();
            if(this.$store.state.loginCheck){
                this.getStarCheck();
                this.getSubscribeState();
                this.getBuyAllInfo();
            }
        }
        else{
            this.$router.push('/');
        }
    },
    mounted(){
        var that = this;
        this.bannerNumber = 200;
        this.intervalSetWebtoonInfo = setInterval(()=>{
            if(that.isSetWebtoonInfo){
                clearInterval(that.intervalSetWebtoonInfo);
                var objDesc = document.getElementById('webtoonDesc');
                that.descHeight = objDesc.clientHeight;
            }
        }, 100)
        // window.addEventListener("resize", this.resize);
    },
    beforeDestroy(){
        if(this.intervalSetWebtoonInfo !== null){
            clearInterval(this.intervalSetWebtoonInfo);
        }
    },
    methods: {
        resize(){
            if(this.isShowAllBuyPopup){
                var objDesc = document.getElementById('webtoonDesc');
                this.descHeight = objDesc.clientHeight;
            }
        }, 
        toggleSort(){
            if(this.episodeSortType === 1){
                this.episodeSortType = 0;
            } else {
                this.episodeSortType = 1;
            }
            this.episodeList = [];
            this.initPagingData = null;
            this.currentPageNo = 1;
            this.getEpisodeList();
        },
        getCategory(){ // 카테고리 정보
            var param = 
            {
                // limit : 3
            }
            this.$Axios.post(`${this.host}/infocategory`, param, { headers: { 'Pragma' : 'no-cache', 'Cache-Control': 'no-cache', 'Access-Control-Allow-Origin': '*', 'Content-type': 'application/json', }}).then(res => {
                if (res.data.returnCode === 0) {
                    this.categoryList = res.data.list;
                }
            })
            .catch((error) => {
                console.log(error);
            });
        },
        getCategoryName(category_idx){
            var name = '';
            this.categoryList.forEach(element =>{
                if(category_idx === element.idx){
                    name = element.name;
                }
            })
            return name;
        },
        getWebtoonInfo(){
            var param = 
            {
                idx: this.currWebtoonIdx,
                sign: this.$store.state.sign,
                ticket: this.$store.state.ticket,
                viewAdult: this.$store.state.isCheckedAdult ? 1 : 0,
            }
            this.$Axios.post(`${this.host}/webtoon`, param, { headers: { 'Pragma' : 'no-cache', 'Cache-Control': 'no-cache', 'Access-Control-Allow-Origin': '*', 'Content-type': 'application/json', }}).then(async(res) => {
                // console.log('--webtoon info--')
                // console.log(res.data);
                if (res.data.returnCode === 0) {
                    this.webtoonInfo = res.data.data;
                    this.isSetWebtoonInfo = true;

                    if(this.webtoonInfo.adult === 1){
                        if(this.$store.state.loginCheck === false){
                            this.initPopupOkMessage = '로그인 후 이용 가능합니다.';
                            this.PopupOkCallbakPage = '/';
                            this.isShowPopupOkPopup = true;
                        }
                        else if(this.$store.state.adult === false){
                            this.initPopupOkMessage = '성인 인증 후 이용 가능합니다.';
                            this.PopupOkCallbakPage = '/';
                            this.isShowPopupOkPopup = true;
                        }
                    }
                }
                else if(res.data.returnCode === 300){
                    this.initPopupOkMessage = '해당 작품은 운영상의 문제에 위반되어 열람 및 구매 할 수 없습니다.';
                    this.PopupOkCallbakPage = '/';
                    this.isShowPopupOkPopup = true;
                }
                else if(res.data.returnCode === 301){
                    this.initPopupOkMessage = '성인 인증 후 이용 가능합니다.';
                    this.PopupOkCallbakPage = '/';
                    this.isShowPopupOkPopup = true;
                }
                else{
                    this.$router.push('/');
                }
                
            })
            .catch((error) => {
                console.log(error);
            });
        },
        async getWebtoonInfoName(){
            var param = 
            {
                wtName: this.currWebtoonEngName,
                sign: this.$store.state.sign,
                ticket: this.$store.state.ticket,
                viewAdult: this.$store.state.isCheckedAdult ? 1 : 0,
            }
            // console.log(param);
            await this.$Axios.post(`${this.host}/webtoon/name`, param, { headers: { 'Pragma' : 'no-cache', 'Cache-Control': 'no-cache', 'Access-Control-Allow-Origin': '*', 'Content-type': 'application/json', }}).then(async(res) => {
                // console.log('--webtoon info name--')
                // console.log(res.data);
                if (res.data.returnCode === 0) {
                    this.webtoonInfo = res.data.data;
                    this.currWebtoonIdx = this.webtoonInfo.webToonIndex;

                    if(this.webtoonInfo.adult === 1){
                        if(this.$store.state.loginCheck === false){
                            this.initPopupOkMessage = '로그인 후 이용 가능합니다.';
                            this.PopupOkCallbakPage = '/';
                            this.isShowPopupOkPopup = true;
                        }
                        else if(this.$store.state.adult === false){
                            this.initPopupOkMessage = '성인 인증 후 이용 가능합니다.';
                            this.PopupOkCallbakPage = '/';
                            this.isShowPopupOkPopup = true;
                        }
                    }
                }
                else if(res.data.returnCode === 300){
                    this.initPopupOkMessage = '해당 작품은 운영상의 문제에 위반되어 열람 및 구매 할 수 없습니다.';
                    this.PopupOkCallbakPage = '/';
                    this.isShowPopupOkPopup = true;
                }
                else if(res.data.returnCode === 301){
                    this.initPopupOkMessage = '성인 인증 후 이용 가능합니다.';
                    this.PopupOkCallbakPage = '/';
                    this.isShowPopupOkPopup = true;
                }
                else{
                    this.$router.push('/');
                }
            })
            .catch((error) => {
                console.log(error);
            });
        },
        getFirstEpisodeInfo(){
            var param = 
            {
                sign: this.$store.state.sign,
                ticket: this.$store.state.ticket,
                viewAdult: this.$store.state.isCheckedAdult ? 1 : 0,
                pageNo: 1,
                pageSize: 1,
                wtIdx: this.currWebtoonIdx,
            }
            this.$Axios.post(`${this.host}/webtoon/episode`, param, { headers: { 'Pragma' : 'no-cache', 'Cache-Control': 'no-cache', 'Access-Control-Allow-Origin': '*', 'Content-type': 'application/json', }}).then(async(res) => {
                // console.log('--webtoon episode first info--')
                // console.log(res);
                if (res.data.returnCode === 0) {
                    if(res.data.list[0]){
                        this.firstEpisodeInfo = res.data.list[0];
                    }
                } else if(res.data.returnCode === 300){
                    this.initPopupOkMessage = '해당 작품은 운영상의 문제에 위반되어 열람 및 구매 할 수 없습니다.';
                    this.PopupOkCallbakPage = '/';
                    this.isShowPopupOkPopup = true;
                    return null;
                }
            })
            .catch((error) => {
                console.log(error);
            });
        },
        getEpisodeList(){
            var param = 
            {
                sign: this.$store.state.sign,
                ticket: this.$store.state.ticket,
                viewAdult: this.$store.state.isCheckedAdult ? 1 : 0,
                pageNo: this.currentPageNo,
                pageSize: this.currentPageSize,
                wtIdx: this.currWebtoonIdx,
                sortSeq: this.episodeSortType
            }
            // console.log(param);
            this.$Axios.post(`${this.host}/webtoon/episode`, param, { headers: { 'Pragma' : 'no-cache', 'Cache-Control': 'no-cache', 'Access-Control-Allow-Origin': '*', 'Content-type': 'application/json', }}).then(async(res) => {
                // console.log('--webtoon episode list--')
                // console.log(res);
                if (res.data.returnCode === 0) {
                    this.episodeList = res.data.list;
                    this.initPagingData = res.data.page;
                }
                else if(res.data.returnCode === 300){
                    this.initPopupOkMessage = '해당 작품은 운영상의 문제에 위반되어 열람 및 구매 할 수 없습니다.';
                    this.PopupOkCallbakPage = '/';
                    this.isShowPopupOkPopup = true;
                }
                else{
                    this.initPopupOkMessage = '웹툰 정보를 불러오지 못했습니다.';
                    this.PopupOkCallbakPage = '/';
                    this.isShowPopupOkPopup = true;
                    // this.$router.push('/');
                }
            })
            .catch((error) => {
                console.log(error);
            });
        },
        // getHit(){  // 1 인기 작품
        //     var param = 
        //     {
        //         type: 1,
        //         setCount: 7,
        //         ticket: this.$store.state.ticket,
        //         sign: this.$store.state.sign,
        //         viewAdult: this.$store.state.isCheckedAdult ? 1 : 0
        //     }
        //     this.$Axios.post(`${this.host}/curation`, param, { headers: { 'Pragma' : 'no-cache', 'Cache-Control': 'no-cache', 'Access-Control-Allow-Origin': '*', 'Content-type': 'application/json', }}).then(res => {
        //         // console.log('----인기 작품----');
        //         // console.log(res.data);
        //         if (res.data.returnCode === 0) {
        //             if(res.data.setList.length > 0){
        //                 this.hitList = res.data.setList[0].list;
        //             }
        //         }
        //     })
        //     .catch((error) => {
        //         console.log(error);
        //     });
        // },
        getHit(){  // 1 인기 작품
            var param = 
            {
                pageNo: 1,
                pageSize: 7,
                ticket: this.$store.state.ticket,
                sign: this.$store.state.sign,
                viewAdult: this.$store.state.isCheckedAdult ? 1 : 0
            }
            this.$Axios.post(`${this.host}/curation/type1`, param, { headers: { 'Pragma' : 'no-cache', 'Cache-Control': 'no-cache', 'Access-Control-Allow-Origin': '*', 'Content-type': 'application/json', }}).then(res => {
                // console.log('----인기 작품----');
                // console.log(res);
                if (res.data.returnCode === 0) {
                    this.hitList = res.data.list.data;
                }
            })
            .catch((error) => {
                console.log(error);
            });
        },
        getStarCheck(){
            var param = 
            {
                idx: this.currWebtoonIdx,
                ticket: this.$store.state.ticket,
                sign: this.$store.state.sign,
                viewAdult: this.$store.state.isCheckedAdult ? 1 : 0
            }
            // console.log(param)
            this.$Axios.post(`${this.host}/star/check`, param, { headers: { 'Pragma' : 'no-cache', 'Cache-Control': 'no-cache', 'Access-Control-Allow-Origin': '*', 'Content-type': 'application/json', }}).then(res => {
                // console.log(res.data);
                if (res.data.returnCode === 0) {
                    this.starPoint = res.data.data;
                }
            })
            .catch((error) => {
                console.log(error);
            });
        },
        getSubscribeState(){
            var param = 
            {
                webtoonIndex: this.currWebtoonIdx,
                ticket: this.$store.state.ticket,
                sign: this.$store.state.sign,
                viewAdult: this.$store.state.isCheckedAdult ? 1 : 0
            }
            // console.log(param);
            this.$Axios.post(`${this.host}/mylibrary/subscribe/state`, param, { headers: { 'Pragma' : 'no-cache', 'Cache-Control': 'no-cache', 'Access-Control-Allow-Origin': '*', 'Content-type': 'application/json', }}).then(res => {
                // console.log(res.data);
                if (res.data.returnCode === 0) {
                    this.isSubscribe = res.data.data;
                    // console.log(this.isSubscribe);
                }
            })
            .catch((error) => {
                console.log(error);
            });
        },
        subscribe(){
            var param = 
            {
                webtoonIndex: this.currWebtoonIdx,
                sign: this.$store.state.sign,
                ticket: this.$store.state.ticket,
                viewAdult: this.$store.state.isCheckedAdult ? 1: 0
            }
            this.$Axios.post(`${this.host}/mylibrary/subscribe`, param, { headers: { 'Pragma' : 'no-cache', 'Cache-Control': 'no-cache', 'Access-Control-Allow-Origin': '*', 'Content-type': 'application/json', }}).then(async(res) => {
                // console.log(res.data);
                if (res.data.returnCode === 0) {
                    this.isSubscribe = true;
                }
            })
            .catch((error) => {
                console.log(error);
            });
        },
        unsubscribe(){
            var webtoonList = [];
            webtoonList.push(this.currWebtoonIdx);
            var param = 
            {
                webtoonList: webtoonList,
                sign: this.$store.state.sign,
                ticket: this.$store.state.ticket,
                viewAdult: this.$store.state.isCheckedAdult ? 1: 0
            }
            this.$Axios.post(`${this.host}/mylibrary/unsubscribe`, param, { headers: { 'Pragma' : 'no-cache', 'Cache-Control': 'no-cache', 'Access-Control-Allow-Origin': '*', 'Content-type': 'application/json', }}).then(async(res) => {
                // console.log(res.data);
                if (res.data.returnCode === 0) {
                    this.isSubscribe = false;
                }
            })
            .catch((error) => {
                console.log(error);
            });
        },
        // loginComplete(){
        //     this.isShowLoginPopup = false;
            
        //     if(this.selectedEpisodieInfo.buyAfterPrice === 0 && this.selectedEpisodieInfo.rentalAfterPrice === 0){
        //         this.$router.push('/Webtoon/Viewer/'+ this.selectedEpisodieInfo.webtoonIdx + '/' + this.selectedEpisodieInfo.episodeIdx);
        //     }else{
        //         this.isShowBuyPopup = true;
        //     }
        // },
        freeEpisodeBuy(episodeInfo){

            var param = 
            {
                epidx: episodeInfo.episodeIdx,
                sign: this.$store.state.sign,
                ticket: this.$store.state.ticket,
                wtidx: this.currWebtoonIdx,
                viewAdult: this.$store.state.isCheckedAdult ? 1: 0
            }
            // console.log(param);
            this.$Axios.post(`${this.host}/member/buy`, param, { headers: { 'Pragma' : 'no-cache', 'Cache-Control': 'no-cache', 'Access-Control-Allow-Origin': '*', 'Content-type': 'application/json', }}).then(async(res) => {
                // console.log(res.data);
                if (res.data.returnCode === 0 || res.data.returnCode === 100) {
                    this.$router.push('/Webtoon/Viewer/'+ this.currWebtoonIdx + '/' + episodeInfo.episodeIdx);
                } else if(res.data.returnCode === 300){
                    this.initPopupOkMessage = '해당 작품은 운영상의 문제에 위반되어 열람 및 구매 할 수 없습니다.';
                    this.PopupOkCallbakPage = '/';
                    this.isShowPopupOkPopup = true;
                } else if(res.data.returnCode === 353){
                    this.initPopupOkMessage = '회당 회차는 운영상의 문제에 위반되어 열람 및 구매 할 수 없습니다.';
                    this.PopupOkCallbakPage = '/';
                    this.isShowPopupOkPopup = true;
                }
            })
            .catch((error) => {
                console.log(error);
            });
        },
        getBuyAllInfo(){
                var param = 
                {
                    idx: this.currWebtoonIdx,
                    ticket: this.$store.state.ticket,
                    sign: this.$store.state.sign,
                }
                // console.log(param);
                this.$Axios.post(`${this.host}/episode/all/price`, param, { headers: { 'Pragma' : 'no-cache', 'Cache-Control': 'no-cache', 'Access-Control-Allow-Origin': '*', 'Content-type': 'application/json', }}).then(res => {
                    // console.log(res.data);
                    if (res.data.returnCode === 0) {
                        this.BuyAllInfo = res.data;
                    } else if(res.data.returnCode === 300){
                        this.initPopupOkMessage = '해당 작품은 운영상의 문제에 위반되어 열람 및 구매 할 수 없습니다.';
                        this.PopupOkCallbakPage = '/';
                        this.isShowPopupOkPopup = true;
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        showAllBuyPopup(){
            if(this.$store.state.loginCheck === false){
                this.isShowLoginPopup = true;
            }
            else{
                if(this.BuyAllInfo !== null && this.BuyAllInfo.sumPrice > 0){
                    this.isShowAllBuyPopup = true;
                }
            }
        },
        showBuyPopup(episodeInfo){
            this.selectedEpisodieInfo = episodeInfo;

            if(this.$store.state.loginCheck === false){
                this.isShowLoginPopup = true;
            }
            else if(episodeInfo.enable === 0){
                this.initPopupOkMessage = '회당 회차는 운영상의 문제에 위반되어 열람 및 구매 할 수 없습니다.';
                this.PopupOkCallbakPage = '';
                this.isShowPopupOkPopup = true;
            }
            // else if(episodeInfo.buyPrice === 0 && episodeInfo.rentalPrice === 0){
            //     this.$router.push('/Webtoon/Viewer/'+ this.currWebtoonEngName + '/' + episodeInfo.episodeIdx);
            // }
            // else if(episodeInfo.byMbIdx !== null){
            //     this.$router.push('/Webtoon/Viewer/'+ this.currWebtoonEngName + '/' + episodeInfo.episodeIdx);
            // }
            else if(episodeInfo.buyPrice === 0 && episodeInfo.rentalPrice === 0){
                // this.$router.push('/Webtoon/Viewer/'+ this.currWebtoonIdx + '/' + episodeInfo.episodeIdx);
                this.freeEpisodeBuy(episodeInfo);
            }
            else if(episodeInfo.byMbIdx !== null){
                this.$router.push('/Webtoon/Viewer/'+ this.currWebtoonIdx + '/' + episodeInfo.episodeIdx);
            }
            else{
                this.isShowBuyPopup = true;
            }
            // if((this.$store.state.pgPoint + this.$$store.state.pgBounsPoint) > episodeInfo.
        },
        showGradePopup(){
            if(this.$store.state.loginCheck === false){
                this.isShowLoginPopup = true;
            }else{
                this.isShowGradePopup = true;
            }
        },
        showSubscribePopup(){
            if(this.$store.state.loginCheck === false){
                this.isShowLoginPopup = true;
            }else{
                this.isShowSubscribePopup = true;
            }
        },
        close(popupType){
            switch(popupType){
                case 'AllBuy':
                    this.isShowAllBuyPopup = false;
                    break;
                case 'Buy':
                    this.isShowBuyPopup = false;
                    break;
                case 'Login':
                    this.isShowLoginPopup = false;
                    break;
                case 'Grade':
                    this.isShowGradePopup = false;
                    break;
                case 'Subscribe' :
                    this.isShowSubscribePopup = false;
                    break;
                case 'PopupOk':
                    this.isShowPopupOkPopup = false;
                    if(this.PopupOkCallbakPage === 'reload'){
                        location.reload();
                    }
                    else if(this.PopupOkCallbakPage !== ''){
                        this.$router.push(this.PopupOkCallbakPage);
                    }
                    this.PopupOkCallbakPage = '';
                    break;
            }
        },
        moveDetail(item){

            // console.log(item);
            
            if(item.twebtoonForApi.adult === 1){
                if(this.$store.state.loginCheck === false){
                    this.isShowLoginPopup = true;
                }
                else if(!this.$store.state.adult){
                    var src = this.$store.state.siteConfig.niceUrl + '?email=' + this.$store.state.email;
                    window.open(src, 'popupChk', 'width=500, height=550, top=100, left=100, fullscreen=no, menubar=no, status=no, toolbar=no, titlebar=no, location=no, scrollbar=no');
                }
                else{
                    // this.$router.push('/Webtoon/Info/' + item.englishName);
                    this.$router.push('/Webtoon/Info/' + item.twebtoonForApi.webToonIndex);
                }
            }else{
                // this.$router.push('/Webtoon/Info/' + item.englishName);
                this.$router.push('/Webtoon/Info/' + item.twebtoonForApi.webToonIndex);
            }
            
        },
        movePaging(pageNo){
            this.currentPageNo = pageNo;
            this.getEpisodeList();
        },
    }
}
</script>