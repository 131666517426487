<template>
    <div class="qa wrap">
        <Header :initHeaderType="initHeaderType" />
            <b-container>
                <div class="menu_left_box">
                    <Noticenav :selectedType="NavSelecteType" />
                </div>
                <div class="qa_box">
                    <div class="title">
                        <h1>자주하는 질문</h1>
                    </div>
                    <div class="menu">
                        <p :class="{'on' : selectedFaqType === 0}" @click="selectedFaqType = 0">전체</p>
                        <p :class="{'on' : selectedFaqType === 1}" @click="selectedFaqType = 1">로그인/계정</p>
                        <p :class="{'on' : selectedFaqType === 2}" @click="selectedFaqType = 2">결제/환불</p>
                        <p :class="{'on' : selectedFaqType === 3}" @click="selectedFaqType = 3">이용관련</p>
                        <p :class="{'on' : selectedFaqType === 4}" @click="selectedFaqType = 4">기타문의</p>
                    </div>
                    <div class="accordion np_tab" role="tablist">
                        <div v-for="(item, index) in faqList" :key="index">
                            <b-card no-body class="mb-1" v-if="selectedFaqType === 0 || selectedFaqType === item.type">
                                <b-card-header header-tag="header" class="p-1" role="tab">
                                    <b-button block v-b-toggle="'accordion-' + index" variant="info">
                                        <b-row>
                                            <b-col><span>Q.</span>{{item.question}}</b-col>
                                            <b-col><img src="@/assets/images/icon_down_arrow.svg" alt="화살표"></b-col>
                                        </b-row>
                                    </b-button>
                                </b-card-header>
                                <b-collapse :id="'accordion-'+index" accordion="my-accordion" role="tabpanel">
                                    <b-card-body>
                                        <b-card-text v-html="item.answer"></b-card-text>
                                        <div v-if="item.image_no" class="faq_img">
                                            <img src="@/assets/images/faq/ATT_FAQ01.jpg" v-if="item.image_no === '1'" />
                                            <img src="@/assets/images/faq/ATT_FAQ02.jpg" v-if="item.image_no === '2'" />
                                        </div>
                                    </b-card-body>
                                </b-collapse>
                            </b-card>
                        </div>
                    </div>
                </div>
            </b-container>
        <Footer />
    </div>
</template>

<script>
import Header from '@/components/common/Header.vue'
import Footer from '@/components/common/Footer.vue'
import Noticenav from '@/components/support/Noticenav.vue'

export default {
    name: 'FAQ',
    pageCategory: 'Common',
    pageTitle: '자주하는 질문',
    components: {
        Header,
        Footer,
        Noticenav,
    },
    data() {
        return {
            NavSelecteType: 'QnA',
            rows: 10,
            perPage: 1,

            selectedFaqType: 0,

            initHeaderType: 'type2',

            faqList:[
                {
                    type: 1,
                    question: '아튜브 회원가입은 어떻게 하나요'
                ,   answer:   '아튜브는 모바일과 PC에서 회원가입을 할 수 있습니다.<br/>PC버전의 경우 화면 우측 상단의 Login 버튼을 클릭하면 나타나는 팝업에서 [회원가입] 버튼을 클릭합니다.<br/>모바일의 경우에는 화면 우측 상단의 ≡ 버튼을 터치한 뒤, Login 버튼을 터치하여 이동한 화면에서 [회원가입] 버튼을 터치합니다.'
                },
                {
                    type: 1,
                    question: '비밀번호를 변경하고 싶어요'
                ,   answer:   '비밀번호의 변경은 로그인한 상태에서 우측 상단의 프로필>내 정보 화면으로 이동한 후, [계정 정보] 영역에서 새로운 비밀번호를 입력하고 [비밀번호 변경] 버튼을 클릭하여 변경할 수 있습니다'
                },
                {
                    type: 1,
                    question: '아튜브 회원을 탈퇴하려면 어떻게 헤야 하나요'
                ,   answer:   '회원탈퇴는 마이페이지 > 내정보 화면의 가장 하단에 위치한 [계정 탈퇴하기] 버튼을 클릭하면 탈퇴 신청이 진행됩니다.<br/>회원탈퇴 이전 반드시 환불신청을 먼저 진행해주시기 바랍니다.<br/>아트포인트를 보유한 상태에서 회원탈퇴가 진행되면 모든 포인트는 소실됩니다.'
                },
                {
                    type: 1,
                    question: '아이디/비밀번호를 잃어버렸어요'
                ,   answer:   'PC와 모바일 환경 모두 로그인 화면에서 [아이디 찾기] [비밀번호 재설정] 버튼을 통해 본인 명의로 가입된 아튜브 아이디와 그 비밀번호를 찾을 수 있습니다.<br/>해당 방식으로도 아이디를 찾지 못하셨다면 고객센터 1:1 문의를 통해 문의해주시길 바랍니다.'
                },
                {
                    type: 1,
                    question: '비밀번호 찾기 메일이 도착하지 않아요'
                ,   answer:   '아튜브에서 발송되는 비밀번호 재설정 메일이 스팸으로 분류되는 경우가 간혹 있습니다.<br/>우선 스팸 메일함을 확인해주시고, 메일 서비스에 따라 환경설정에서 아튜브 홈페이지 주소를 수신허용 목록에 추가해주시길 부탁드립니다.'
                },
                {
                    type: 2,
                    question: '아트포인트란 무엇인가요'
                ,   answer:   '아트로인트는 아튜브 웹툰 플랫폼에서 웹툰을 구매하고 감상하는데 사용되는 일종의 "화폐 단위"입니다.<br/>PC와 모바일 어디서든 구매하고 사용할 수 있으며, 결제를 통해 충전된 아트포인트로 작품을 감상할 수 있습니다.<br/>각 작품의 종류에 따라 필요한 아트포인트가 상이할 수 있사오니 이용 시 확인을 부탁드리겠습니다'
                },
                {
                    type: 2,
                    question: '아트포인트의 충전은 어떻게 하나요'
                ,   answer:   '화면 우측 상단의 [아트포인트] 버튼을 클릭하면 아트포인트 충전 화면으로 이동할 수 있습니다.<br/>해당 화면에서 결제할 금액의 패키지를 선택 후 결제수단을 선택하여 간편하게 결제할 수 있습니다.'
                },
                {
                    type: 2,
                    question: '결제 이후 아트포인트가 충전되지 않아요'
                ,   answer:   '사용자의 환경에 따라 아트포인트의 충전이 지연되는 경우가 간혹 존재합니다.<br/>하지만 일정 시간이 지난 이후에도 아트포인트의 충전이 이루어지지 않는다면 결제일시/결제수단/결제금액과 같은 정보를 포함하여 고객센터로 문의주시면 최대한 빠르게 도움드리겠습니다.'
                },
                {
                    type: 2,
                    question: '충전한 아트포인트를 환불받고 싶어요'
                ,   answer:   '아트포인트의 환불은 결제이후 해당 포인트를 이용하지 않았을 경우에 한하여 환불처리를 도와드리고 있습니다.<br/>충전된 아트포인트의 일부(보너스 포인트 포함)만 사용하였더라도 하나의 패키지 상품으로 취급되기에 환불이 불가하오니 이점 유의해주시길 바합니다.<br/>사용하지 않은 아트포인트의 환불은 고객센터 1:1 문의를 통해 문의해주시면 최대한 빠르게 도움드리겠습니다.'
                },
                {
                    type: 2,
                    question: 'ATT를 이용한 Artpoint충전은 어떻게 하나요'
                ,   answer:   'ATT 입금은 내 정보 혹은 ATT Coin결제 페이지를 통해 메타마스크(지갑)를 연결한 뒤, 연결된 메타마스크에 충분한 ATT수량을 확보한 상태에서 Artpoint 충전 페이지에서 결제할 ATT 수량을 직접 입력하여 충전합니다.<br/><br/>ATT를 이용하여 충전된 Artpoint는 자유로운 입/출금이 가능함에 따라 ATT코인을 통해 충전된 Artpoint 충전 수량이 모두 소모된 이후, 보너스 포인트가 소모됩니다.'
                ,   image_no: '1'
                },
                {
                    type: 2,
                    question: '충전된 ATT의 출금은 어떻게 하나요'
                ,   answer:   'ATT를 통해 충전된 Artpoint가 있을 경우에는 연결된 메타마스크로 다시 ATT를 출금하는 것이 가능합니다. ATT출금은 아튜브 웹페이지의 아트포인트 출금 페이지에서 진행할 수 있으며, 소지한 Artpoint를 실시간 ATT시시에 맞추어 계산된 수량 한도 내에서 자유롭게 출금할 수 있습니다.<br/><br/>출금 시 별도의 대기기간이나 제한은 없으며, 블록체인 네트워크에서 요구하는 Gas fee를 포함한 일정한 출금 수수료가 발생합니다. 또한, 출금신청이 완료된 이후 네트워크 상태에 따라 출금 시간이 지연될 수 있습니다. 아튜브는 블록체인 네트워크에 개입할 수 없으므로, 지연 및 이상현상 발생 시 도움을 드리기 어려운 점 양해 부탁드립니다.'
                ,   image_no: '2'
                },
                {
                    type: 2,
                    question: 'ATT를 이용한 Artpoint 충전 내역은 어디서 확인하나요'
                ,   answer:   '- PC버전: 충전페이지 > 충전내역 > ATT Coin 결제<br/>- 모바일버전: 우상단 메뉴 버튼 > 아트포인트 > 충전내역'
                },
                {
                    type: 3,
                    question: '지금까지 구매한 작품은 어디서 확인할 수 있나요'
                ,   answer:   '로그인 후, 우측 상단의 메뉴에서 [내 서재] 항목을 선택하면 지금까지 구매한 작품의 목록을 확인할 수 있습니다.'
                },
                {
                    type: 3,
                    question: '무료 작품이 유료 작품으로 바뀌었어요'
                ,   answer:   '아튜브에서는 다양한 작품을 회원분들께 주기적으로 특정 작품의 일부를 무료로 감상하실 수 있도록 이벤트를 진행합니다.<br/>이벤트가 끝난 이후엔 이벤트 대상 작품들은 다시 유료 결제가 필요하기 때문에 아트포인트를 통하여 결제를 부탁드립니다.<br/>만약 결제한 상품이 다시 결제를 요구하는 경우, 고객센터 > 1:1 문의를 통해 문의해주시면 도움드리겠습니다.'
                },
                {
                    type: 3,
                    question: '내 서재에 등록된 작품을 지울 수 있나요'
                ,   answer:   "구매한 작품은 회원님의 '내 서재' 화면에 자동으로 보관답니다.<br/>또한 내 서재 화면 상단의 체크 박스를 클릭하시면 특정 작품을 선택하고 삭제할 수 있습니다.<br/>삭제된 작품은 재열람 시 아트포인트로 재구매가 필요하게 되며, 직접 삭제하신 내역에 대해서 복구가 불가능하오니 삭제를 진행하시기 이전에 충분히 검토 후, 삭제를 진행해주시길 부탁드리겠습니다."
                },
                {
                    type: 3,
                    question: '성인작품을 감상하려면 어떻게 해야하나요'
                ,   answer:   '성인작품의 경우 회원가입 이후 1회의 성인 인증을 거친 아이디로만 감상하실 수 있습니다.<br/>성인 인증이 되어있지 않은 아이디는 로그인 하더라도 성인작품을 감상하실 수 없기 때문에 성인 인증을 반드시 진행해주셔야 합니다.<br/>만약 성인 인증을 진행한 아이디이지만 성인작품이 보이지 않는다면, 화면 우측 상단에 19 토글 버튼을 클릭하여 [ON/OFF] 모드를 변경해보시길 바랍니다.'
                },
                {
                    type: 3,
                    question: '성인인증이 가능한 나이는 어떻게 되나요?'
                ,   answer:   '청소년보호법에 따라 만 19세 이상부터 성인인증을 진행할 수 있습니다.<br/>(예: 2021년 기준으로 2001년 출생: 성인인증 가능 / 2001년 이후 출생: 성인인증 불가능)'
                },
                {
                    type: 3,
                    question: '고객센터 운영시간은 어떻게 되나요?'
                ,   answer:   '고객센터 운영시간은 월~금: 오전 10시부터 오후 6시까지 (점심시간 오후1시~오후2시) 운영 / 금요일 : 오전 10시부터 오후 5시까지 (점심시간 오후1시~오후2시) / 토, 일요일 및 공휴일은 고객센터 운영을 하지 않습니다.<br/>1:1 문의하기를 이용부탁드립니다.'
                },
                {
                    type: 3,
                    question: '아튜브 챌린지의 작품 필명을 바꾸고 싶어요'
                ,   answer:   '작가 등록할 때 기재한 필명은 바꿀 수 없습니다.'
                },
                {
                    type: 3,
                    question: '모바일에서 작가등록, 내작품페이지가 안 보여요'
                ,   answer:   '아튜브 챌린지는 모바일에서는 관람만 가능하며 작가 활동은 PC 환경에서만 이용 가능합니다.'
                },
                {
                    type: 3,
                    question: '아튜브 챌린지의 연재 조건은 어떻게 되나요?'
                ,   answer:   '아튜브 회원 중 성인 회원만 연재할 수 있습니다.'
                },
                {
                    type: 3,
                    question: '아튜브 챌린지에서 내가 등록한 작품, 회차가 안 보여요'
                ,   answer:   '아튜브 챌린지는 작품 등 록 및 수정, 회차 등록 및 수정 시 검수 과정이 있으며 2~3일 소요되나 회사 사정에 따라 더 늘어날 수 있습니다.'
                },
                {
                    type: 3,
                    question: '챌린지에서 보이던 작품, 회차가 안 보여요'
                ,   answer:   '해당 회차나 작품이 신고 누적으로 인한 리포트 후 검수를 거치고 부적절하다고 판단되었을 시 작품이 비공개될 수 있습니다.'
                },
                {
                    type: 3,
                    question: '아튜브 챌린지에서 검수 후 운영원칙 위반이 되었습니다.'
                ,   answer:   '해당 챌린지의 유의 사항을 참고해 주세요.'
                },
                {
                    type: 3,
                    question: '아튜브 챌린지의 이달의 베스트 작품은 어떻게 선정되나요?'
                ,   answer:   '누적 별점과 조회 수가 아닌 매월 새로 받은 별점과 카운트된 조회 수를 산정하여 가장 높은 순서대로 4개가 선정됩니다.'
                },
                {
                    type: 3,
                    question: '별점을 수정하고 싶어요'
                ,   answer:   '별점은 각 회차당 1회만 가능하며 수정할 수 없습니다.'
                },
                {
                    type: 3,
                    question: '작품등록까지는 했는데 아튜브 챌린지에서 안 보여요.'
                ,   answer:   '작품등록 이후 최소 1회차의 에피소드를 등록한 후 검수 신청해야 검수가 끝난 후 아튜브 챌린지에 노출됩니다.'
                },
                {
                    type: 3,
                    question: '후원을 할려고하니 성인인증이 떠요'
                ,   answer: '후원하기 컨텐츠의 경우 페이백 개념으로 인하여 성인만 참여 가능합니다.'
                },
                {
                    type: 3,
                    question: '후원한 작품의 댓글을 삭제하고 싶어요.'
                ,   answer: '댓글 삭제 기능은 지원되지 않으므로 신중히 써주세요.'
                },
                {
                    type: 3,
                    question: '후원한 댓글이 삭제됬어요.'
                ,   answer: '운영 정책상 위반되는 댓글일 경우 경고없이 삭제됩니다.'
                },
                {
                    type: 3,
                    question: '포인트가 있는데 후원하기가 안되요.'
                ,   answer: '후원하기는 보너스 포인트로 후원을 할 수 없습니다. 메뉴버튼 팝업에서 아트포인트와 보너스 아트포인트 소지 여부를 확인해 주세요.'
                },
                {
                    type: 3,
                    question: '후원을 했는데 페이백포인트가 안들어와요.'
                ,   answer: '페이백 포인트는 후원하신 작품의 매출이 실제로 발생해야 작품의 매출부분의 2%가 발생합니다. 따라서 해당 작폼의 매출이 없을 경우 페이백은 발생하지  않습니다.'
                },
                {
                    type: 3,
                    question: '페이백이 2%가 안들어 왔어요.'
                ,   answer: '2%는 해당 작품의 매출에서 총 발생하는 페이백 포인트고 작품에 후원한 사용자들의 기여도에따라 2%안에서 재분배 됩니다.'
                },
                {
                    type: 3,
                    question: '원화로는 출금을 할 수 없나요?'
                ,   answer: '페이백 포인트 서비스는 출금 가능한 아트포인트로 스왑 후 ATT출금만 지원합니다. 따라서 ATT코인의 지갑이 필요하며 거래소에서 판매 후 출금이 가능합니다.'
                },
                {
                    type: 3,
                    question: '페이백 포인트의 소수점이 아트포인트로 스왑이 안되요.'
                ,   answer:   '페이백 서비스는 정수 단위로만 스왑이 가능하며 ATT로 출금 또한 정수로만 가능합니다.'
                },
                {
                    type: 3,
                    question: '스왑한 아트포인트는 어디에다가 쓸 수있나요?'
                ,   answer:   '페이백 포인트를 받은 후 스왑한 아트포인트는 작품 구매, 후원, ATT로 출금에서 사용가능합니다.'
                },
                {
                    type: 3,
                    question: '왜 1아트 포인트로는 후원이 안되나요?'
                ,   answer:   '후원 하기는 최소 5아트포인트가 필요하며 5미만의 아트포인트는 후원 할 수 업습니다.'
                },
                {
                    type: 4,
                    question: '아튜브에 연재하고 싶습니다. 어디로 문의하면 될까요'
                ,   answer:   '아튜브 플랫폼 화면 가장 아래 쪽의 [연재문의] 항목을 클릭하여 해당 메일로 3화 정도 분량의 원고 및 시놉시스, 캐릭터 컨셉, 간략한 소개 등을 보내주시면 소중한 원고에 대한 연재 여부를 신중하게 검토하여 연락드리겠습니다.'
                },
                {
                    type: 4,
                    question: '아튜브의 작품을 다른 곳에 게시해도 되나요'
                ,   answer:   '아튜브의 모든 작품은 저작권법으로 보호받고 있으며, 아튜브 플랫폼 이외에 게시하는 모든 행위를 금지하고 있습니다.<br/>다만 공식적으로 아튜브에서 직접 공유한 이벤트 및 홍보용 이미지의 경우엔 자유롭게 사용이 가능합니다.'
                }
            ],
        }
    },
    created(){

    }
}
</script>