<template>
    <div class="leagueevent wrap winner">
        <Header :initHeaderType="initHeaderType"/>
        <div class="container">
            <img src="@/assets/images/event/challenge_result_banner.png" class="gossip-result-banner">
            <div class="gossip-event">
                <div class="info gossip-result" v-for="(item, idx) in resultList1" :key="'result1-' + idx">
                    <img :src="item.pic" :id="'result1-'+idx"/>
                    <div class="gossip-event-textbox">
                        <p v-for="(email, emailIdx) in item.emailList" :key="emailIdx">{{email}}</p>
                    </div>
                </div>

                <div class="info gossip-result" v-for="(item,idx) in resultList2" :key="'result2-' + idx">
                    <img :src="item.pic" :id="'result2-'+idx"/>
                    <div class="gossip-event-textbox2">
                        <div class="gossip-textbox-left">
                            <p v-for="(email, emailIdx) in item.emailList1" :key="emailIdx + '1'">{{email}}</p>
                        </div>
                        <div class="gossip-line"></div>
                        <div class="gossip-textbox-right">
                            <p v-for="(email, emailIdx) in item.emailList2" :key="emailIdx + '2'">{{email}}</p>
                        </div>
                    </div>
                </div>
                <div class="gossip-result-comment">
                    <h3>안녕하세요, 아튜브팀입니다.</h3>
                    <p>‘아튜브챌린지’ 오픈 기념으로 진행된 ‘소문내기 이벤트’에 <br class="gossip-result-br"> 참여해주신 모든 분들께 진심으로 감사드립니다. <br class="gossip-result-br"> 경품 별 당첨자 발표와 함께 수령 방법을 안내 드립니다.</p>
                    <p class="gossip-result-caution">※ 당첨여부는 &LT;Ctrl+F>를 누르신 후 본인의 이메일 주소를 검색하시면 편하게 찾아보실 수 있습니다.</p>
                    <p class="gossip-result-caution">※ 이벤트 당첨자분들께서는 하단 구글폼으로 &LT;성함/ 이메일주소/ 연락처/ 토큰 수령 지갑주소>를 남겨주시기 바랍니다.</p>
                    <p class="gossip-result-caution">※ 당첨정보는 3/3(목)까지 반드시 발송 부탁드리고, 해당 일자를 넘길시에는 당첨이 자동으로 취소됩니다.</p>
                </div>
                    <a href="https://docs.google.com/forms/d/116A_FxNtXOeOp0vbBSaT0XI3lwBTxosQjZboe0Jyxrc/viewform?edit_requested=true" class="gossip-result-more" target="_blank"> 당첨자 정보 등록하기</a>
            </div>
            <div class="gossip-result-footer">
                <div class="result-footer-box">
                    <h2>[참고사항]</h2>
                    <p>1. 경품은 당첨자 정보 취합 완료 후 일괄적으로 배송될 예정입니다.</p>
                    <p>2. 경품은 기프티콘 형태로 발송되며, 수신자 핸드폰 사정에 따라 스팸처리 될 수 있으며 이 경우 책임은 본인에게 있습니다.</p>
                    <p>3. 토큰은 기재해주신 클레이튼 지갑으로 발송되고, 수신자의 지갑주소 오기입에 따라 누락될 수 있으며 이 경우 책임은 본인에게 있습니다.</p>
                    <p>4. 경품은 1회 지급되며, 지급된 경품에 대한 미확인/ 분실/ 유효기간 만료 등의 사유로 재발송 되지 않습니다.</p>
                    <p>5. 기프티콘의 경우 유효기간 만료 후 연장 및 환불이 불가하니 반드시 기간 내 사용을 권장합니다.</p>
                </div>
            </div>
        </div>
        <Footer />
    </div>
</template>

<script>
import Header from '@/components/common/Header.vue'
import Footer from '@/components/common/Footer.vue'
export default {
    name: 'EventCallengeViralResult',
    pageCategory: 'Event',
    pageTitle: '이벤트',
    components: {
        Header,
        Footer,
    },

    data(){
        return{
            initHeaderType: 'type2',

            resultList1: [
                {   //1등//
                    emailList: ['so3481@naver.com'],
                    pic: require('@/assets/images/event/challenge_result_1.png'),
                },
                {   //2등//
                    emailList: ['ecohouse11@daum.net','kujal486@gmail.com','skaks1126@naver.com'],
                    pic: require('@/assets/images/event/challenge_result_2.png'),
                },
            ],
            resultList2: [
                {   //3등//
                    emailList1: ['bellile@naver.com','ditto012486@gmail.com','hgfj24345@gmail.com','kim13the@gmail.com','leehyein1230@gmail.com'],
                    emailList2: ['metamendoro6@gmail.com','rawfse@naver.com','seodong1516@gmail.com','shc0833@hanmail.net','whdgnlwkd1060@gmail.com'],
                    pic: require('@/assets/images/event/challenge_result_3.png'),
                },
                {   //4등//
                    emailList1: ['7g46iee@gmail.com','9012jsa@naver.com','acideru@nate.com','acoinzin@gmail.com','angler2002kr@gmail.com','btouch@daum.net','ccmceo@gmail.com','corlce9@naver.com','ehldhh@naver.com','ergkt7i96ueryr@gmail.com','fhkj34hiohiosrh@gmail.com','flowery30000@gmail.com','gr5uru634@gmail.com','harry73y@paran.com','jsh67080@gmail.com'],
                    emailList2: ['kdh02000@naver.com','kimjh6983@naver.com','kojeawoohwan@naver.com','kunbitt@naver.com','lgs02000@naver.com','lsha@naver.com','mate0226@nate.com','ohstinpower@naver.com','rlarudxodid@naver.com','seungjinoh1976@gmail.com','shindong0603@hanmail.net','unni99@naver.com','wide22@naver.com','wys9510@hanmail.net','yuor4523@hotmail.com'],
                    pic: require('@/assets/images/event/challenge_result_4.png'),
                },
            ]
        }
    },
}
</script>

<style>

</style>