<template>
<div class="webtoon-upload">
    <div class="p_upload">
        <label for="idUpload" class="btn-upload">작품 업로드</label>
        <div class="p_list">
            <label v-for="(image, index) in images" :key="index" @change="changeHighlight(index)" :for="'check-' + index" class="list-item" :class="[image.isChecked ? 'selected': '']">
                <b-form-checkbox :id="'check-'+index" :name="'check-'+index" v-model="image.isChecked" :value="true" :unchecked-value="false">{{image.name}}</b-form-checkbox>
            </label>
        </div>
        <div class="gray_part">
            <b-form-checkbox v-model="isCheckedAll" id="checkboxAll" name="checkboxAll" :value="true" :unchecked-value="false" @change="checkAll"></b-form-checkbox>
            <div class="button" @click="changePosition('up')"></div>
            <div class="button down" @click="changePosition('down')"></div>
            <div class="btn" @click="deleteImage">삭제</div>
        </div>
    </div>
    <div class="preview_cover">
        <a class="full" v-if="images.length > 0" @click="previewAll">전체 미리보기</a>
        <div class="preview">
            <div>
                <p class="one-image" v-if="imagePreview === ''">원고 미리보기</p>
                <img class="show-img img-responsive" :src="imagePreview" v-else>
            </div>
        </div>
    </div>
    <div>
        <input class="display-none" id="idUpload" @change="uploadFieldChange" name="images" :multiple="multiple" :accept="accept" type="file" :disabled="disabled" >
    </div>
    <PopupFullPreview :initIsShowPopup="isShowPreviewPopup" :initImageList="images" @close="previewClose"/>
    <PopupOK :initIsShowPopup="isShowPopupOkPopup" :initPopupOkMessage="initPopupOkMessage" @close="close('PopupOk')" />
</div>
</template>

<script>
// import { forEach, findIndex, orderBy, cloneDeep } from 'lodash'
import { findIndex, cloneDeep } from 'lodash'
import PopupFullPreview from '@/components/league/PopupFullPreview.vue'
import PopupOK from '@/components/common/PopupOK.vue'

export default {
    name: 'VueUploadMultipleImage',
    components: {
        // Popper,
        // VueImageLightboxCarousel,
        PopupFullPreview,
        PopupOK,
    },
    data () {
        return {
            currentIndexImage: 0,
            images: [],
            isDragover: false,
            showLightbox: false,
            arrLightBox: [],
            isCheckedAll: false,
            isShowPreviewPopup: false,
            fileMaxSize: 5 * 1024 * 1024,   //5MB
            totalFileMaxSize: 50 * 1024 * 1024, //50MB

            isShowPopupOkPopup: false,
            initPopupOkMessage: '',
            PopupOkCallbakPage: '',    // 확인 팝업에서 확인 버튼 눌렀을때 이동할 페이지
        }
    },
    props: {
        dragText: {
            type: String,
            default: '이미지 드래그'
        },
        browseText: {
            type: String,
            default: '(또는 선택)'
        },
        primaryText: {
            type: String,
            default: '기본'
        },
        markIsPrimaryText: {
            type: String,
            default: '기본값으로 설정'
        },
        popupText: {
            type: String,
            default: '이 이미지는 기본적으로 표시됩니다'
        },
        dropText: {
            type: String,
            default: '여기에 파일을 드롭하세요'
        },
        accept: {
            type: String,
            default: 'image/gif,image/jpeg,image/jpg'
            // default: 'image/gif,image/jpg'
        },
        dataImages: {
            type: Array,
            default: () => {
            return []
            }
        },
        multiple: {
            type: Boolean,
            default: true
        },
        showPrimary: {
            type: Boolean,
            default: true
        },
        maxImage: {
            type: Number,
            default: 100
        },
        idUpload: {
            type: String,
            default: 'image-upload'
        },
        idEdit: {
            type: String,
            default: 'image-edit'
        },
        showEdit: {
            type: Boolean,
            default: true
        },
        showDelete: {
            type: Boolean,
            default: true
        },
        showAdd: {
            type: Boolean,
            default: true
        },
        disabled: {
            type: Boolean,
            default: false
        },
        initIsEdit:{
            type: Boolean,
            default: false
        },
        initEpisodeInfo:{
            type: Object,
            default: null
        },
        initWriterInfo:{
            type: Object,
            default: null
        }
    },
     mounted () {
        document.body.addEventListener('dragleave', (event) => {
            event.stopPropagation()
            event.preventDefault()
            this.isDragover = false
        })
    },
    created () {
        this.images = []
        this.images = cloneDeep(this.dataImages)
    },
    computed: {
        imagePreview () {
            let index = findIndex(this.images, { highlight: 1 })
            if(index > -1){
                if(this.images[index].isChecked){
                    return this.images[index].path;
                } else {
                    return '';
                }
            } else {
                return '';
            }
        },
    },
    watch: {
        dataImages: {
            handler: function (newVal) {
                this.images = cloneDeep(newVal);
            },
            deep: true
        }
    },
    methods: {
        preventEvent (e) {
            e.preventDefault();
            e.stopPropagation();
        },
        createImage (file) {
            if (this.disabled) return;
            let reader = new FileReader();
            let formData = new FormData();
            formData.append('file', file);
            reader.onload = (e) => {
                let dataURI = e.target.result;
                if (dataURI) {
                    if (!this.images.length) {
                        this.images.push({ name: file.name, path: dataURI, highlight: 0, default: 1, isChecked: false, formData: formData });
                        this.currentIndexImage = 0;
                    } else {
                        this.images.push({ name: file.name, path: dataURI, highlight: 0, default: 0, isChecked: false, formData: formData });
                    }
                }
            }
            reader.readAsDataURL(file);
        },
        async uploadFieldChange (e) {
             let files = e.target.files || e.dataTransfer.files;
            if (!files.length) {
                return false;
            }
            if (!this.isValidNumberOfImages(files.length)) {
                return false;
            }
            var that = this;
            var isPassLoop = false;
            files = [].slice.call(files);
            files.sort((a, b) => a.name.localeCompare(b.name));
            var totalSize = 0;
            files.forEach(item=>{
                totalSize += item.size;
            })

            if(totalSize > this.totalFileMaxSize){
                this.initPopupOkMessage = '전체 업로드 사이즈는 50MB를 초과할 수 없습니다.';
                this.isShowPopupOkPopup = true;
            }
            else{
                for(const file of files) {
                    let fileName = file.name.substring(0,file.name.lastIndexOf('.'));
                    let filter =  /^[ㄱ-ㅎ|가-힣|a-z|A-Z|0-9|_|-|]+$/;
                    if(!isPassLoop){
                        if(file.size > this.fileMaxSize){
                            this.initPopupOkMessage = '최대 파일 사이즈는 5MB 입니다.<br/>( ' + file.name + ' )';
                            this.isShowPopupOkPopup = true;
                            isPassLoop = true;
                            break;
                        }
                        else if(!filter.test(fileName)){
                            this.initPopupOkMessage = '파일명은 한글, 영문, 숫자만 가능합니다.<br/>( ' + file.name + ' )';
                            this.isShowPopupOkPopup = true;
                            isPassLoop = true;
                            break;
                        }
                        else{
                            let formData = new FormData();
                            formData.append('file', file);
                            
                            const fileReader = new this.SyncFileReader(file);
                            var image = await fileReader.getImageObject();
                            var width = image.width;

                            if (width !== 720) {
                                that.initPopupOkMessage = '원고 이미지 가로 사이즈는 720px 로 제한됩니다.<br/>( ' + file.name + ' )';
                                that.isShowPopupOkPopup = true;
                                isPassLoop = true;
                            } else {
                                that.images.push({ name: file.name, path: image.src, highlight: 0, default: 0, isChecked: false, formData: formData });
                            }
                        }
                    }
                }
            }
            if (document.getElementById('idUpload')) {
                document.getElementById('idUpload').value = [];
            }

            this.$emit('imageDataChange', this.images);
        },
        SyncFileReader(file) {
            let self = this;
            let ready = false;
            let result = '';
            let image = new Image();

            const sleep = function (ms) {
            return new Promise(resolve => setTimeout(resolve, ms));
            }

            self.readAsDataURL = async function() {
                while (ready === false) {
                    await sleep(100);
                }

                return result;
            }
            self.getImageObject = async function(){
                while (ready === false) {
                    await sleep(100);
                }
                return image;
            }

            const reader = new FileReader();
            reader.onloadend = function(evt) {
                result = evt.target.result;
                image.src = result;
                image.onload = function () {
                    ready = true;
                }
            };
            reader.readAsDataURL(file);
        },

        checkFileSize(file){
            if(file.size > this.fileMaxSize){
                this.initPopupOkMessage = '최대 파일 사이즈는 5MB 입니다.';
                this.isShowPopupOkPopup = true;
                return false;
            }
            return true;
        },
        changeHighlight (currentIndex) {
            this.isCheckedAll = false;
            this.currentIndexImage = currentIndex;
            let arr = this.images;
            this.images = [];
            arr.map((item, index) => {
                if (currentIndex === index) {
                    if(item.isChecked) item.highlight = 1;
                    else item.highlight = 0;
                } else {
                    item.highlight = 0;
                }
                return item;
            })
            this.images = arr;
        },
        checkAll(){
            var isChecked = this.isCheckedAll;
            this.images.forEach(item=>{
                if(item.isChecked === false && item.highlight === 1){item.highlight = 0;}
                item.isChecked = isChecked;
            })
        },
        changePosition(type){
            var checkedItemCount = 0;
            var fromIndex = findIndex(this.images, { isChecked: true });
            var toIndex = -1;
            this.images.forEach(item =>{
                if(item.isChecked) checkedItemCount++;
            })
            if(checkedItemCount === 1){
                if(type === 'up' && fromIndex > 0){
                    toIndex = fromIndex--;
                } else if(type === 'down' && (fromIndex + 1) < this.images.length){
                    toIndex = fromIndex++;
                } else {
                    return;
                }
                var fromItem = this.images[fromIndex];
                this.images.splice(fromIndex, 1);
                this.images.splice(toIndex, 0, fromItem);
            }
            this.$emit('imageDataChange', this.images);
        },
        deleteImage(){
            var checkedCount = 0;
            this.images.forEach(item=>{
                if(item.isChecked){
                    checkedCount++;
                }
            });
            for(;checkedCount>0;checkedCount--){
                this.spliceImage();
            }
            if(this.isCheckedAll){
                this.isCheckedAll = false;
            }
            this.$emit('imageDataChange', this.images);
        },
        spliceImage(){
            this.images.forEach((item, index) =>{
                if(item.isChecked){
                    this.images.splice(index, 1)
                    return;
                }
            })
        },
        isValidNumberOfImages (amount) {
            if (amount > this.maxImage) {
                this.$emit('limit-exceeded', amount);
                return false;
            } else {
                return true;
            }
        },
        previewAll(){
            this.isShowPreviewPopup = true;
        },
        previewClose(){
            this.isShowPreviewPopup = false;
        },
        close(popupType){
            switch(popupType){
                case 'PopupConfirm':
                    this.isShowPopupConfirmPopup = false;
                    break;
                case 'PopupOk':
                    this.isShowPopupOkPopup = false;
                    if(this.PopupOkCallbakPage !== ''){
                        this.$router.push(this.PopupOkCallbakPage);
                    }
                    this.PopupOkCallbakPage = '';
                    break;
            }
        },
    },
}
</script>