<template>
    <div id="viewerheader" v-if="initWebtoonInfo !== null && initEpisodeState !== null && initisShowViewer">
        <div class="container">
            <router-link tag="h1" to="/" class="logo">artube</router-link>
            <p class="back-list">{{initWebtoonInfo.ctTitleName}}</p>
            <p>></p>
            <p>{{initEpisodeIdx}}화</p>
            <div class="level">
                <div class="prev" @click="prevEpisode" :class="[initPreEpisodeIdx === null ? 'none' : '']"></div>
                <div class="now">{{initEpisodeIdx}}화</div>
                <div class="next" @click="nextEpisode" :class="[initNextEpisodeIdx === null ? 'none' : '']"></div>
            </div>
        </div>
        <Login :initIsShowPopup="isShowLoginPopup" @close="close('Login')" />
    </div>
</template>

<script>

import Login from '@/components/common/Login.vue'

export default {
    name: 'ViewerHeader',
    components: {
        Login,
    },
    data(){
        return {
            chagneEpisodeInfo: null,

            isShowLoginPopup: false,
        }
    },
    props: {
        initWebtoonInfo: {
            type: Object,
            default: null
        },
        initEpisodeIdx:{
            type: Number,
            default: 0,
        },
        initEpisodeState: {
            type: Object,
            default: null
        },
        initPreEpisodeIdx: {
            type: Object,
            default: null
        },
        initNextEpisodeIdx: {
            type: Object,
            default: null
        },
        initisShowViewer: {
            type: Boolean,
            default: true,
        }
    },
    async created() {
        await this.$store.commit("setSiteConfig", this.host);
        window.scroll(0,0);
    },
    mounted(){
    },
    beforeDestroy() {
	},
    methods: {
        closePopup(){
            this.$emit('close');
        },
        async prevEpisode(){
            if(this.initPreEpisodeIdx !== null){
                this.checkEpisodeState(this.initWebtoonInfo.ctWebtoonIdx, this.initPreEpisodeIdx.idx);
            }
        },
        async nextEpisode(){
            if(this.initNextEpisodeIdx !== null){
                // console.log(this.initEpisodeState);
                this.checkEpisodeState(this.initWebtoonInfo.ctWebtoonIdx, this.initNextEpisodeIdx.idx);
            }
        },
        async checkEpisodeState(wtIdx, epIdx){
            var param = 
            {
                ticket: this.$store.state.ticket,
                sign: this.$store.state.sign,
                viewAdult: this.$store.state.isCheckedAdult ? 1 : 0,

                webtoonIdx: wtIdx,
                episodeIdx: epIdx,
                // lang: 'KR'
            }
            // console.log(param);
            await this.$Axios.post(`${this.league_host}/challenge/episode/state`, param, { headers: { 'Pragma' : 'no-cache', 'Cache-Control': 'no-cache', 'Access-Control-Allow-Origin': '*', 'Content-type': 'application/json', }}).then(async(res) => {
                // console.log('----episode state----');
                // console.log(res);

                if(res.data.epData != null){
                    this.chagneEpisodeInfo = res.data.epData;
                }

                if (res.data.returnCode === 0) {
                    this.$router.push('/League/Webtoon/Viewer/' + wtIdx + '/' + epIdx);
                }
                else if(res.data.returnCode === 503){   //로그인 필요
                    this.isShowLoginPopup = true;
                }
                else{
                    console.log(res.data.dsscription);
                }
            })
            .catch((error) => {
                console.log(error);
            });
        },
        backList(){
            this.$router.push('/League/Webtoon/Info/' + this.initWebtoonInfo.ctWebtoonIdx);
        },
        close(popupType){
            switch(popupType){
                case 'Login':
                    this.isShowLoginPopup = false;
                    break;
            }
        },
        
    },
}
</script>