<template>
    <div class="overlay-container" v-if="!$store.state.isShowLoginPopupForce && isShowNoticePopup">
        <div class="black-overlay"></div>
        <div class="popup">
            <!-- <h1 class="title">{{noticePopupList[currentNoticeIdx].title}}</h1> -->
            <img src="@/assets/images/icon_popup_close.png" alt="close" class="close-popup" @click="closePopup" />
            <div class="content" v-html="noticePopupList[currentNoticeIdx].message"></div>
            <div class="footer">
                <div class="todayClose" v-if="noticePopupList[currentNoticeIdx].flagForce === 0" @click="closePopup24Hour">
                    <img src="@/assets/images/icon_check.png" />
                    24시간 동안 보지않기
                </div>
            </div>
        </div>
        
    </div>
</template>

<script>
export default {
    name: 'PopupNotice',
    data(){
        return {
            //팝업 공지용
            noticeLocalStorage: [],
            noticePopupList: [],
            currentNoticeIdx: 0,
            isShowNoticePopup: false,
            checkedTodayClose: false,
        }
    },
    props: {
    },
    created() {
        var param_nr = this.$route.query.nr;
        if(param_nr){
            localStorage.removeItem('noticePopup');
        }
        if(this.fn_getItemLocalStorage("noticePopup")){
            this.noticeLocalStorage =  JSON.parse(this.fn_getItemLocalStorage("noticePopup"));
        }
    },
    mounted(){
        this.getPopupNotice();
    },
    beforeDestroy() {
	},
    methods: {
        //공지 팝업을 띄우는 코드
        displayMov() {
            //현재 날짜 객체를 구한다.
            var currentTime = new Date();

            for(; this.currentNoticeIdx < this.noticePopupList.length && this.isShowNoticePopup === false; ){
                var nextShowPopupTime = this.noticePopupList[this.currentNoticeIdx].nextShowTime;
                if(nextShowPopupTime !== null){
                    var compareDate = new Date(nextShowPopupTime);
                    if(compareDate.getTime() <= currentTime.getTime() || this.noticePopupList[this.currentNoticeIdx].flagForce === 1){
                        this.isShowNoticePopup = true;
                    }else{
                        this.currentNoticeIdx++;
                    }
                }else{
                    this.isShowNoticePopup = true;
                }
            }
        },
        //팝업을 닫고 1일간 띄우지 않게끔 로컬스토리지 설정.
        displayNoneMov(){
            var currentTime = new Date(); 
            currentTime.setDate(currentTime.getDate() + 1);
            // currentTime.setSeconds(currentTime.getSeconds() + 60);
            
            var isExistsNotice = false;
            this.noticeLocalStorage.forEach((item, index) =>{
                if(item.idx === this.noticePopupList[this.currentNoticeIdx].idx){
                    this.noticeLocalStorage[index].nextShowTime = currentTime;
                    isExistsNotice = true;
                }
            })
            if(isExistsNotice === false){
                this.noticeLocalStorage.push({
                    idx : this.noticePopupList[this.currentNoticeIdx].idx,
                    nextShowTime : currentTime
                });
            }
            
            this.fn_setLocalStorage("noticePopup", JSON.stringify(this.noticeLocalStorage));
            this.currentNoticeIdx++;
            this.checkedTodayClose = false;
            this.displayMov();
        },
        getPopupNotice(){
            var param = 
            {
            }
            this.$Axios.post(`${this.host}/newNotice/popup`, param, { headers: { 'Pragma' : 'no-cache', 'Cache-Control': 'no-cache', 'Access-Control-Allow-Origin': '*', 'Content-type': 'application/json', }}).then(async(res) => {
                // console.log('----팝업공지----');
                // console.log(res.data);
                if (res.data.returnCode === 0) {
                    res.data.list.forEach((noticeItem) =>{
                        var objNotice = {
                            author: noticeItem.author,
                            count: noticeItem.count,
                            createDate: noticeItem.createDate,
                            flagForce: noticeItem.flagForce,
                            flagOnce: noticeItem.flagOnce,
                            flagPopup: noticeItem.flagPopup,
                            flagTop: noticeItem.flagTop,
                            idx: noticeItem.idx,
                            lang: noticeItem.lang,
                            message: noticeItem.message,
                            showPeriod: noticeItem.showPeriod,
                            state: noticeItem.state,
                            subTitle: noticeItem.subTitle,
                            title: noticeItem.title,
                            type: noticeItem.type,
                            updateDate: noticeItem.updateDate,
                            nextShowTime: null
                        }
                        if(this.noticeLocalStorage !== null){
                            this.noticeLocalStorage.forEach(strorageItem =>{
                                if(strorageItem.idx === noticeItem.idx){
                                    objNotice.nextShowTime = strorageItem.nextShowTime;
                                }
                            })
                        }
                        this.noticePopupList.push(objNotice);
                    });
                    this.displayMov();
                }
            })
            .catch((error) => {
                console.log(error);
            });
        },
        closePopup(){
            this.isShowNoticePopup = false;
            this.currentNoticeIdx++;
            this.displayMov();
        },
        closePopup24Hour(){
            this.isShowNoticePopup = false;
            this.displayNoneMov();
        }
    },
}
</script>