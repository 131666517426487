<template>
    <div id="viewer" :class="{'comic-book' : webtoonInfo !== null && webtoonInfo.viewType == 1}">
        <Header :initHeaderType="initHeaderType"  :initIsHeaderShow="isShowViewer" @changeState="changeState"/>
        <Viewerheader :initWebtoonInfo="webtoonInfo" :initEpisodeIdx="currEpisodeIdx" :initEpisodeState="episodeState" :initisShowViewer="isShowViewer" />
        <div class="container" v-if="webtoonInfo !== null && webtoonInfo.viewType == 0">
            <div id="viewer-img">
                <img v-for="(item,index) in imageList" :key="index" @click="touchImg"
                    :src="  host + '/episode/image/view/' 
                            + $store.state.ticket + '/'
                            + $store.state.sign + '/'
                            + currWebtoonIdx + '/'
                            + currEpisodeIdx + '/'
                            + item.page + '/'
                            + 'KR'"
                />
            </div>
            <div class="group" v-if="episodeList.length > 0 && $store.state.siteConfig !== null">
                <div class="prev" @click="prevPage()">prev</div>
                <div class="toon" v-for="(item, index) in episodeList" :key="index" :class="[item.episodeIdx === currEpisodeIdx ? 'now' : 'secret']" @click="changeEpisode(currWebtoonIdx, item.episodeIdx)">
                    <img :src="$store.state.siteConfig.cdnUrl + '/KR/' + item.url" alt="썸네일" class="ssum">
                    <p>{{item.episodeIdx}}화</p>
                </div>
                <div class="next" @click="nextPage()">next</div>
            </div>
            <div class="buttons">
                <div class="list" @click="backList">목록 보기</div>
                <!-- <div class="all" @click="showAllBuyPopup">전 회차 소장하기</div> -->
            </div>
        </div>
        <div v-if="webtoonInfo !== null && webtoonInfo.viewType == 1">
            <div class="comic-viewer" v-if="$store.state.siteConfig !== null">
                <VueSlickCarousel v-bind="sliderViewerSettings" @init="initSliderViewer" @beforeChange="sliderViewerBeforeChange" class="slider" v-if="imageList.length > 0 && isShowSlickViewer" ref="comicSlider" :style="{maxHeight: currImgHeight+'px'}">
                    <div v-for="(item,index) in imageList" :key="index" >
                        <img @click="touchImg"  @load="onImgLoaded(index)" :id="'comicImg-' + index"
                        :src="  host + '/episode/image/view/' 
                                + $store.state.ticket + '/'
                                + $store.state.sign + '/'
                                + currWebtoonIdx + '/'
                                + currEpisodeIdx + '/'
                                + item.page + '/'
                                + 'KR'"
                        />
                    </div>
                    <div v-if="imageList.length % 2 === 1">
                        <img src="@/assets/images/viewer/last_blank.jpg" @click="touchImg"/>
                    </div>
                </VueSlickCarousel>
            </div>
        </div>
        <Footer  v-if="webtoonInfo !== null && webtoonInfo.viewType == 0"/>
        <Viewermove :initWebtoonInfo="webtoonInfo" :initEpisodeState="episodeState" :initisShowViewer="isShowViewer" :initEpisodeIdx="currEpisodeIdx" @scrollBottom="scrollBottom" :initPagingData="initPagingData" :initEpisodeOptimum="episodeOptimum" @checkEpisodeState="changeEpisode" />
        <Login :initIsShowPopup="isShowLoginPopup" @close="close('Login')" />
        <Buy  :initIsShowPopup="isShowBuyPopup" :initWebtoonInfo="webtoonInfo" :initEpisodeInfo="changeEpisodeInfo" :initSiteConfig="$store.state.siteConfig" @close="close('Buy')"  />
        <ViewerWidthPopup :initWebtoonInfo="webtoonInfo" :initIsShowPopup="isShowPopup" :initPopupOkMessage1="initPopupOkMessage1" :initPopupOkMessage2="initPopupOkMessage2" :initPopupOkMessage3="initPopupOkMessage3" @close="close('ViewerWidthPopup')" v-if="webtoonInfo !== null && webtoonInfo.viewType == 1" />
        <!-- <Allbuy  :initIsShowPopup="isShowAllBuyPopup" :initWebtoonInfo="webtoonInfo" :initBuyAllInfo="BuyAllInfo" :initSiteConfig="siteConfig" @close="close('AllBuy')"  /> -->
    </div>
</template>

<script>
import Viewerheader from '@/components/webtoon/Viewerheader.vue'
import Footer from '@/components/common/Footer.vue'
import Header from '@/components/common/Header.vue'
import VueSlickCarousel from 'vue-slick-carousel'
import Viewermove from '@/components/webtoon/Viewermove.vue'
import ViewerWidthPopup from '@/components/webtoon/ViewerWidthPopup.vue'
import Buy from '@/components/webtoon/Buy.vue'
import Login from '@/components/common/Login.vue'

export default {
    name: 'WebtoonViewer',
    pageCategory: 'Webtoon',
    components: {
        Viewerheader,
        Footer,
        Header,
        Viewermove,
        VueSlickCarousel,
        Buy,
        Login,
        ViewerWidthPopup,
    },
    data() {
        return {
            currWebtoonEngName: '',
            currWebtoonIdx: 0,
            currEpisodeIdx: 0,

            webtoonInfo: null,
            episodeState: null,
            episodeOptimum: null,
            imageList: [],
            episodeList: [],
            BuyAllInfo: null,
            changeEpisodeInfo: null,

            selectedEpisodieInfo: null,

            isShowLoginPopup: false,
            isShowBuyPopup: false,
            isShowAllBuyPopup: false,

            currentPageNo: 1,      //현재 페이지 번호
            currentPageSize: 5,    //한번에 불러올 게시물 수
            maxPageCount:1,        //한 화면에 보여질 페이지 수

            listCount: 0,
            pageCount: 0,
            pageNo: 0,
            pageSize: 0,
            totalRecordSize: 0,
            initPagingData: null,

            pageList:[],
            bannerFeaturedList : [],

            isShowViewer: true,
            isShowMypageState: false,   //헤더 햄버거 마이페이지 팝업 열려있는지 상태를 받아와서 저장(여기서 값 수정 ㄴㄴ)
            initHeaderType: 'type5',

            sliderViewerSettings:{lazyLoad: "ondemand", dots: false, arrows: true, fade: false, infinite: false, speed: 0, autoplaySpeed: 5000, slidesToShow: 2, slidesToScroll: 2, autoplay: false, rtl: false,},
            selectedViewerSlideIdx: 0,
            isShowSlickViewer: false,

            initPopupOkMessage1: '',
            initPopupOkMessage2: '',
            initPopupOkMessage3: '',
            isShowPopup: true,

            currViewDirection: 0,       //가로형 작품 표시 순서( 0: 정방향, 1: 역방향)
            resizeTimer: null,          //resize 완료 시에만 메소드 실행하도록 타이머 생성
            isViewerFirstLoad: true,    //페이지 첫 로딩일 경우
            beforeViewerPageCount: 2,   //risize 전 화면에 몇개의 페이지가 표시되고 있는지
            
            maxImgHeight: 0,            //만화 이미지 최대 세로 길이
            maxImgWidth: 600,           //만화 이미지 최대 가로 길이

            currImgHeight: 0,        //현재 만화 이미지 최대 세로 길이
        }
    },
    async created(){
        await this.mixinLoginCheck();
        // await this.mixinSiteConfigCheck();

        if(this.$route.params.wtidx){
            this.currWebtoonIdx = Number(this.$route.params.wtidx);
        }else{
            this.$router.push('/');
        }

        // if(this.$route.params.engName){
        //     this.currWebtoonEngName = this.$route.params.engName;
        // }else{
        //     this.$router.push('/');
        // }

        if(this.$route.params.epidx){
            this.currEpisodeIdx = Number(this.$route.params.epidx);
        }else{
            this.$router.push('/');
        }
        
        if(!this.$store.state.loginCheck){
            this.$router.push('/');
        }else{
            // await this.getWebtoonInfoName();
            await this.getWebtoonInfo();
            await this.getEpisodeState();
            await this.getEpisodeOptimum();
            await this.getImageList();
            var start_idx = 1;
            var end_idx = this.currentPageSize;

            if(this.currEpisodeIdx === 0){
                this.currentPageNo = 1;
                this.getEpisodeList();
            }
            else{
                for(var i=1;i<100;i++)
                {

                    if(this.currEpisodeIdx >= start_idx && this.currEpisodeIdx <= end_idx){
                        this.currentPageNo = i;
                        await this.getEpisodeList();

                        var isContain = false;
                        this.episodeList.forEach(element =>{
                            if(element.episodeIdx === this.currEpisodeIdx){
                                isContain = true;
                            }
                        });

                        if(!isContain){
                            this.currentPageNo ++;
                            this.getEpisodeList();
                        }

                        break;
                    }else{
                        start_idx = start_idx + this.currentPageSize;
                        end_idx = end_idx + this.currentPageSize
                    }
                }
            }
        }
    },
    mounted(){
        window.addEventListener('scroll', this.scrollHandler);
    },
    beforeDestroy(){
        window.removeEventListener('scroll', this.scrollHandler);
        window.removeEventListener('resize', this.resizeEnded);
    }, 
    methods: {
        popupText(){
            if(this.webtoonInfo !== null && this.webtoonInfo.viewDirection == 0){
                this.initPopupOkMessage1 = '정방향 넘김'
                this.initPopupOkMessage2 = '왼쪽에서 오른쪽으로 읽는 작품입니다.';
                this.initPopupOkMessage3 = '양쪽 끝을 클릭하거나 좌우 스와이프로 페이지를 넘길 수 있습니다. ';
            }
            else if(this.webtoonInfo !== null && this.webtoonInfo.viewDirection == 1){
                this.initPopupOkMessage1 = '역방향 넘김'
                this.initPopupOkMessage2 = '오른쪽에서 왼쪽으로 읽는 작품입니다.';
                this.initPopupOkMessage3 = '양쪽 끝을 클릭하거나 좌우 스와이프로 페이지를 넘길 수 있습니다. ';
            }
        },
        offPopup(){
            this.isShowPopup = false;
        },
        onImgLoaded(idx){
            if(this.isViewerFirstLoad){
                this.isViewerFirstLoad = false;
                
                this.resizeHandler(idx);
            }
        },
        resizeEnded(){
            var that = this;
            if(this.resizeTimer){
                clearTimeout(this.resizeTimer);
            }
            this.resizeTimer = setTimeout(()=>{
                that.resizeHandler()
            }, 500);
        },
        resizeHandler(idx = this.selectedViewerSlideIdx){
            var currWinWidth = window.innerWidth;       //resize 후 브라우저 가로 길이
            var currWinHeight = window.innerHeight;     //resize 후 브라우저 세로 길이

            var currImgWidth = document.getElementById('comicImg-' + idx).clientWidth;      //resize 후 이미지 가로
            var currImgHeight = this.currImgHeight = document.getElementById('comicImg-' + idx).clientHeight;    //resize 후 이미지 세로            

            if(this.maxImgHeight < currImgHeight){  //현재 이미지 세로가 최대 길이일 경우 최대 세로 변수 값 갱신
                this.maxImgHeight = currImgHeight;
            }

            this.changeViewerCount(currWinWidth, currWinHeight, currImgWidth, currImgHeight);
        },
        changeViewerCount(currWinWidth, currWinHeight, currImgWidth, currImgHeight){
            var that = this; 

            if(currWinWidth >= 1200 && currWinHeight >= this.maxImgHeight + 88){
                //브라우저 가로/세로가 이미지 최대 크기보다 클때 뷰어 2쪽, 상단 패딩 추가
                this.changeSlideShowCount(2);
            } else if(currWinWidth >= 1200 && currWinHeight < this.maxImgHeight + 88) {
                //브라우저 가로가 이미지 최대 보다 크고, 브라우저 세로가 이미지 최대 보다 작을떄 뷰어 2쪽, 상단 패딩 제거
                this.changeSlideShowCount(1);
            } else if(currWinWidth < 1200){
                //브라우저 가로가 이미지 최대 보다 작고, 브라우저 세로가 이미지 최대 보다 클때 뷰어 1쪽, 상단 패딩 추가
                this.changeSlideShowCount(1);
            } 
            // else if(currWinWidth < 1200 && currWinHeight) {
            //     //브라우저 가로/세로가 이미지 최대 크기보다 작을때 뷰어 1쪽, 상단 패딩 제거
            //     this.changeSlideShowCount(1);
            //     this.changeTopPadding(currWinHeight, currImgHeight);
            // }
            setTimeout(()=>{
                that.currImgHeight = currImgHeight = document.getElementById('comicImg-' + that.selectedViewerSlideIdx).clientHeight;
                that.changeTopPadding(currWinWidth, currWinHeight, currImgHeight);
                that.changeMaxHeight(currWinWidth, currWinHeight, currImgWidth, currImgHeight);
            }, 200);
        },
        changeSlideShowCount(showCount){ //가로형 뷰어 slide 개수 변경
            if(showCount === 1){    // 1개로 변경
                if(this.beforeViewerPageCount === 2){   //이전에 보여지던 페이지 수가 2개일때 페이지 수를 1개로 변경
                    this.beforeViewerPageCount = 1;
                    this.sliderViewerSettings.slidesToShow = 1;
                    this.sliderViewerSettings.slidesToScroll = 1;
                    this.addClass('viewer', 'one');

                    if(this.currViewDirection === 1 && this.selectedViewerSlideIdx % 2 === 0){  //화면에 보여질 인덱스 번호를 다시 계산
                        //역방향
                        this.selectedViewerSlideIdx = this.selectedViewerSlideIdx + 1;
                        this.$refs.comicSlider.goTo(this.selectedViewerSlideIdx, true);
                    }
                    else if(this.currViewDirection === 0 && this.selectedViewerSlideIdx % 2 === 1){
                        //정방향
                        this.selectedViewerSlideIdx = this.selectedViewerSlideIdx - 1;
                        this.$refs.comicSlider.goTo(this.selectedViewerSlideIdx, true);
                    }
                }
            } else if(showCount === 2){ //2개로 변경
                if(this.beforeViewerPageCount === 1){   //이전에 보여지던 페이지 수가 1개였을때 
                    this.beforeViewerPageCount = 2;     //페이지 수를 2개로 변경
                    this.sliderViewerSettings.slidesToShow = 2;
                    this.sliderViewerSettings.slidesToScroll = 2;
                    this.delClass('viewer', 'one');

                    if(this.selectedViewerSlideIdx % 2 === 1){  //화면에 보여질 인덱스 번호를 다시 계산
                        this.selectedViewerSlideIdx = this.selectedViewerSlideIdx - 1;
                        this.$refs.comicSlider.goTo(this.selectedViewerSlideIdx, true);
                    }
                }
            }
            // return document.getElementById('comicImg-' + idx).clientHeight;    //현재 이미지 높이 값 갱신
        },
        changeTopPadding(currWinWidth, currWinHeight, currImgHeight){   //상단 padding 얼마 넣을지 구분
            this.delClass('viewer', 'top-padding-161');
            this.delClass('viewer', 'top-padding-98');
            this.delClass('viewer', 'top-padding-88');
            this.delClass('viewer', 'top-padding-0');

            if(currWinWidth <= 575){    //Mobile일 경우
                if(currWinHeight < currImgHeight + 98){
                    this.addClass('viewer', 'top-padding-0');
                } else if(currWinHeight >= currImgHeight + 98){
                    this.addClass('viewer', 'top-padding-98');
                }
            }
            else {  //PC일 경우
                if(currWinHeight <= currImgHeight + 88){
                    this.addClass('viewer', 'top-padding-0');
                } else if(currWinHeight <= currImgHeight + 161){
                    this.addClass('viewer', 'top-padding-88');
                } else if(currWinHeight > currImgHeight + 161){
                    this.addClass('viewer', 'top-padding-161');
                }
            }
        },
        changeMaxHeight(currWinWidth, currWinHeight, currImgWidth, currImgHeight){  //이미지 가로 혹은 세로 max 크기 구분
            if(currWinWidth >= 1200){ //브라우저 가로가 이미지 최대보다 클떄
                if(currWinHeight > currImgHeight){  //브라우저 세로가 이미지 세로보다 클때
                    this.delClass('viewer', 'max-height');
                } else {    //브라우저 세로가 이미지 세로 이하일떄
                    this.addClass('viewer', 'max-height');
                }
            } else {    //브라우저 가로가 이미지 최대보다 작을 떄
                if(currWinWidth > currImgWidth){    //브라우저 가로가 이미지 가로 보다 클떄
                    if(currWinHeight > currImgHeight){  //브라우저 세로가 이미지 세로보다 클때
                        this.delClass('viewer', 'max-height');
                    } else if(currWinHeight < currImgHeight) {
                        this.delClass('viewer', 'max-height');
                    } else {    //브라우저 세로가 이미지 세로 이하일떄
                        this.addClass('viewer', 'max-height');
                    }
                } else if(currWinWidth < currImgWidth){
                    this.delClass('viewer', 'max-height');
                } else {    //브라우저 가로가 이미지 가로 이하일때
                    if(currWinHeight > currImgHeight){  //브라우저 세로가 이미지 세로보다 클때
                        this.delClass('viewer', 'max-height');
                    } else if(currWinHeight < currImgHeight) {
                        this.delClass('viewer', 'max-height');
                    } else {    //브라우저 세로가 이미지 세로 이하일떄
                        this.addClass('viewer', 'max-height');
                    }
                }
            }
        },
        initSliderViewer() {
            var that = this;
            setTimeout(()=>{
                that.$refs.comicSlider.goTo(that.selectedViewerSlideIdx, true);
            }, 0);
        },
        sliderViewerBeforeChange(oldSlideIndex, newSlideIndex) {
            this.selectedViewerSlideIdx = Number(newSlideIndex);
            if(this.imageList[newSlideIndex + this.sliderViewerSettings.slidesToShow]){
                this.isShowViewer = false;
            } else {
                this.isShowViewer = true;
            }
        },
        scrollBottom(){
            var element = document.getElementById('viewer-img').clientHeight;
            document.documentElement.scrollTop = document.documentElement.scrollHeight - (document.documentElement.scrollHeight - element + window.innerHeight - 82);
        },
        scrollHandler(){
            if(this.isShowMypageState){
                return;
            }
            var nowlocation = document.documentElement.scrollTop;
            var windowlocation = window.innerHeight;
            var endlocation = document.documentElement.scrollHeight - document.documentElement.scrollTop;
            if(endlocation <= windowlocation + 62){
                this.isShowViewer = true;
            } else if(nowlocation > 0){
                this.isShowViewer = false;
            } else{
                this.isShowViewer = true;
            }
        },
        touchImg(){
            // if(!this.isPc()){
                this.isShowViewer = !this.isShowViewer
            // }
        },
        prevPage(){
            if(this.currentPageNo > 1){
                this.currentPageNo--;
                this.getEpisodeList();
            }
        },
        nextPage(){
            if(this.currentPageNo < this.pageCount){
                this.currentPageNo++;
                this.getEpisodeList();
            }
        },
        async getImageList(){
            var param = 
            {
                pageNo: 1,
                pageSize: 1000,
                episodeIdx: this.currEpisodeIdx,
                webtoonIdx: this.currWebtoonIdx,
                lang: 'KR'
            }
            await this.$Axios.post(`${this.host}/episode/image/list`, param, { headers: { 'Pragma' : 'no-cache', 'Cache-Control': 'no-cache', 'Access-Control-Allow-Origin': '*', 'Content-type': 'application/json', }}).then(async(res) => {
                // console.log('----image list----');
                // console.log(res.data);
                if (res.data.returnCode === 0) {
                    this.currViewDirection = this.webtoonInfo.viewDirection;

                    if(this.webtoonInfo.viewDirection === 1){   //역방향
                        this.imageList = res.data.list.reverse();
                        if(this.imageList.length > 0){
                            this.selectedViewerSlideIdx = this.imageList.length - 2;
                        }
                    }
                    else {  //정방향
                        this.imageList = res.data.list;
                        this.selectedViewerSlideIdx = 0;
                    }
                    this.isShowSlickViewer = true;
                }else{
                    // console.log(res.data.description);
                    this.$router.push('/');
                    // this.$router.push('/Webtoon/Info/' + this.currWebtoonEngName);
                }
            })
            .catch((error) => {
                console.log(error);
            });
        },
        async getWebtoonInfo(){
            var param = 
            {
                idx: this.currWebtoonIdx,
                sign: this.$store.state.sign,
                ticket: this.$store.state.ticket,
                viewAdult: this.$store.state.isCheckedAdult ? 1 : 0,
            }
            await this.$Axios.post(`${this.host}/webtoon`, param, { headers: { 'Pragma' : 'no-cache', 'Cache-Control': 'no-cache', 'Access-Control-Allow-Origin': '*', 'Content-type': 'application/json', }}).then(async(res) => {
                // console.log('------getWebtoon Info------');
                // console.log(res.data);
                if (res.data.returnCode === 0) {
                    this.webtoonInfo = res.data.data;
                    this.popupText();
                    if(this.webtoonInfo.viewType == 1){
                        this.addClass('viewer', 'comic-book');
                        window.addEventListener('resize', this.resizeEnded);
                    }
                }
                else if(res.data.returnCode === 300){
                    this.initPopupOkMessage = '해당 작품은 운영상의 문제에 위반되어 열람 및 구매 할 수 없습니다.';
                    this.PopupOkCallbakPage = '/';
                    this.isShowPopupOkPopup = true;
                }
                else{
                    // console.log(res.data.returnCode);
                    this.$router.push('/');
                }
            })
            .catch((error) => {
                console.log(error);
            });
        },
        async getWebtoonInfoName(){
            var param = 
            {
                wtName: this.currWebtoonEngName,
                sign: this.$store.state.sign,
                ticket: this.$store.state.ticket,
                viewAdult: this.$store.state.isCheckedAdult ? 1 : 0,
            }
            await this.$Axios.post(`${this.host}/webtoon/name`, param, { headers: { 'Pragma' : 'no-cache', 'Cache-Control': 'no-cache', 'Access-Control-Allow-Origin': '*', 'Content-type': 'application/json', }}).then(async(res) => {
                // console.log('----webtoon info name----');
                // console.log(res.data);
                if (res.data.returnCode === 0) {
                    this.webtoonInfo = res.data.data;
                    this.currWebtoonIdx = this.webtoonInfo.webToonIndex;

                    if(this.webtoonInfo.adult === 1){
                        if(this.$store.state.loginCheck === false){
                            this.$router.push('/');
                        }
                        else if(this.$store.state.adult === false){
                            this.$router.push('/');
                        }
                    }
                }
                else if(res.data.returnCode === 300){
                    this.initPopupOkMessage = '해당 작품은 운영상의 문제에 위반되어 열람 및 구매 할 수 없습니다.';
                    this.PopupOkCallbakPage = '/';
                    this.isShowPopupOkPopup = true;
                }
                // else if(res.data.returnCode === 301){
                //     console.log(res.data.description);
                //     this.$router.push('/');
                // }
                else{
                    console.log(res.data.description);
                    this.$router.push('/');
                }
            })
            .catch((error) => {
                console.log(error);
            });
        },
        async getEpisodeList(){
            var param = 
            {
                sign: this.$store.state.sign,
                ticket: this.$store.state.ticket,
                viewAdult: this.$store.state.isCheckedAdult ? 1 : 0,
                pageNo: this.currentPageNo,
                pageSize: this.currentPageSize,
                wtIdx: this.currWebtoonIdx,
            }
            // console.log(param);
            await this.$Axios.post(`${this.host}/webtoon/episode`, param, { headers: { 'Pragma' : 'no-cache', 'Cache-Control': 'no-cache', 'Access-Control-Allow-Origin': '*', 'Content-type': 'application/json', }}).then(async(res) => {
                // console.log('----episode list----');
                // console.log(res);
                if (res.data.returnCode === 0) {
                    this.episodeList = res.data.list;
                    this.initPagingData = res.data.page;
                    this.listCount       = res.data.page.listCount;
                    this.pageCount       = res.data.page.pageCount;
                    this.pageNo          = res.data.page.pageNo;
                    this.pageSize        = res.data.page.pageSize;
                    this.totalRecordSize = res.data.page.totalRecordSize;
                    // this.changePage();
                }
                else if(res.data.returnCode === 300){
                    this.initPopupOkMessage = '해당 작품은 운영상의 문제에 위반되어 열람 및 구매 할 수 없습니다.';
                    this.PopupOkCallbakPage = '/';
                    this.isShowPopupOkPopup = true;
                }
                else{
                    // console.log(res.data.description);
                    this.$router.push('/');
                }
            })
            .catch((error) => {
                console.log(error);
            });
        },
        async getEpisodeState(){
            var param = 
            {
                ticket: this.$store.state.ticket,
                sign: this.$store.state.sign,
                viewAdult: this.$store.state.isCheckedAdult ? 1 : 0,

                webtoonIdx: this.currWebtoonIdx,
                episodeIdx: this.currEpisodeIdx,
                lang: 'KR'
            }
            await this.$Axios.post(`${this.host}/episode/state`, param, { headers: { 'Pragma' : 'no-cache', 'Cache-Control': 'no-cache', 'Access-Control-Allow-Origin': '*', 'Content-type': 'application/json', }}).then(async(res) => {
                // console.log('----episode state----');
                // console.log(res);
                if (res.data.returnCode === 0) {
                    this.episodeState = res.data;
                }
                else if(res.data.returnCode === 300){
                    this.initPopupOkMessage = '해당 작품은 운영상의 문제에 위반되어 열람 및 구매 할 수 없습니다.';
                    this.PopupOkCallbakPage = '/';
                    this.isShowPopupOkPopup = true;
                }
                else if(res.data.returnCode === 353){
                    this.initPopupOkMessage = '회당 회차는 운영상의 문제에 위반되어 열람 및 구매 할 수 없습니다.';
                    this.PopupOkCallbakPage = '/';
                    this.isShowPopupOkPopup = true;
                }
                else{
                    this.$router.push('/');
                }
            })
            .catch((error) => {
                console.log(error);
            });
        },
        async getEpisodeOptimum(){
            var param =
            {
                webtoonidx: this.currWebtoonIdx
            }
            await this.$Axios.post(`${this.host}/episode/nxindex`, param, { headers: { 'Pragma' : 'no-cache', 'Cache-Control': 'no-cache', 'Access-Control-Allow-Origin': '*', 'Content-type': 'application/json', }}).then(async(res) => {
                // console.log(res);
                if(res.data.returnCode === 0){
                    this.episodeOptimum = res.data;
                }
                else{
                    this.$router.push('/');
                }
            })
            .catch((error) => {
                console.log(error);
            });
        },
        backList(){
            this.$router.push('/Webtoon/Info/' + this.currWebtoonIdx);
        },
        changeEpisode(wtIdx, epIdx){
            var param = 
            {
                ticket: this.$store.state.ticket,
                sign: this.$store.state.sign,
                viewAdult: this.$store.state.isCheckedAdult ? 1 : 0,

                webtoonIdx: wtIdx,
                episodeIdx: epIdx,
                lang: 'KR'
            }
            this.$Axios.post(`${this.host}/episode/state`, param, { headers: { 'Pragma' : 'no-cache', 'Cache-Control': 'no-cache', 'Access-Control-Allow-Origin': '*', 'Content-type': 'application/json', }}).then(async(res) => {
                // console.log('----episode state----');
                // console.log(res);

                if(res.data.epData != null){
                    this.changeEpisodeInfo = res.data.epData;
                }

                if (res.data.returnCode === 0) {
                    if(res.data.epData.buyPrice === 0 && res.data.epData.rentalPrice === 0){
                        this.freeEpisodeBuy(this.changeEpisodeInfo);
                    } else {
                        this.$router.push('/Webtoon/Viewer/' + wtIdx + '/' + epIdx);
                    }
                }
                else if(res.data.returnCode === 252){   //성인인증 필요
                    this.initPopupOkMessage = '성인 인증 후 이용 가능합니다.';
                    this.PopupOkCallbakPage = '/';
                    this.isShowPopupOkPopup = true;
                }
                else if(res.data.returnCode === 300){
                    this.initPopupOkMessage = '해당 작품은 운영상의 문제에 위반되어 열람 및 구매 할 수 없습니다.';
                    this.PopupOkCallbakPage = '';
                    this.isShowPopupOkPopup = true;
                }
                else if(res.data.returnCode === 353){
                    this.initPopupOkMessage = '회당 회차는 운영상의 문제에 위반되어 열람 및 구매 할 수 없습니다.';
                    this.PopupOkCallbakPage = '';
                    this.isShowPopupOkPopup = true;
                }
                else if(res.data.returnCode === 503){   //로그인 필요
                    this.isShowLoginPopup = true;
                }
                else if(res.data.returnCode === 504){   //구매 필요
                    this.isShowBuyPopup = true;
                }
                // else{
                //     console.log(res.data.dsscription);
                // }
            })
            .catch((error) => {
                console.log(error);
            });
        },
        freeEpisodeBuy(episodeInfo){
            var param = 
            {
                epidx: episodeInfo.episodeIdx,
                sign: this.$store.state.sign,
                ticket: this.$store.state.ticket,
                wtidx: episodeInfo.webtoonIdx,
                viewAdult: this.$store.state.isCheckedAdult ? 1: 0
            }
            // console.log(param);
            this.$Axios.post(`${this.host}/member/buy`, param, { headers: { 'Pragma' : 'no-cache', 'Cache-Control': 'no-cache', 'Access-Control-Allow-Origin': '*', 'Content-type': 'application/json', }}).then(async(res) => {
                if (res.data.returnCode === 0 || res.data.returnCode === 100) {
                    this.$router.push('/Webtoon/Viewer/'+ episodeInfo.webtoonIdx + '/' + episodeInfo.episodeIdx);
                } else if(res.data.returnCode === 300){
                    this.initPopupOkMessage = '해당 작품은 운영상의 문제에 위반되어 열람 및 구매 할 수 없습니다.';
                    this.PopupOkCallbakPage = '';
                    this.isShowPopupOkPopup = true;
                } else if(res.data.returnCode === 353){
                    this.initPopupOkMessage = '회당 회차는 운영상의 문제에 위반되어 열람 및 구매 할 수 없습니다.';
                    this.PopupOkCallbakPage = '';
                    this.isShowPopupOkPopup = true;
                }
            })
            .catch((error) => {
                console.log(error);
            });
        },
        showAllBuyPopup(){
            if(this.$store.state.loginCheck === false){
                this.isShowLoginPopup = true;
            }
            else{
                var param = 
                {
                    idx: this.currWebtoonIdx,
                    ticket: this.$store.state.ticket,
                    sign: this.$store.state.sign,
                }
                this.$Axios.post(`${this.host}/episode/all/price`, param, { headers: { 'Pragma' : 'no-cache', 'Cache-Control': 'no-cache', 'Access-Control-Allow-Origin': '*', 'Content-type': 'application/json', }}).then(res => {
                    // console.log(res.data);
                    if (res.data.returnCode === 0) {
                        this.BuyAllInfo = res.data;
                        if(this.BuyAllInfo.sumPrice > 0){
                            this.isShowAllBuyPopup = true;
                        } else if(res.data.returnCode === 300){
                            this.initPopupOkMessage = '해당 작품은 운영상의 문제에 위반되어 열람 및 구매 할 수 없습니다.';
                            this.PopupOkCallbakPage = '';
                            this.isShowPopupOkPopup = true;
                        }
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
            }
        },
        close(popupType){
            switch(popupType){
                case 'AllBuy':
                    this.isShowAllBuyPopup = false;
                    break;
                case 'Login':
                    this.isShowLoginPopup = false;
                    break;
                case 'Buy':
                    this.isShowBuyPopup = false;
                    break;
                case 'ViewerWidthPopup':
                    this.isShowPopup = false;
                    break;
            }
        },
        changeState(statePopup, state){
            switch(statePopup){
                case 'isOpenMypage':
                    this.isShowMypageState = state;
                    break;
            }
        },
        addClass(id, className) {
            try{
                var element = document.getElementById(id);
                var classNames = element.className.split(' ');
                var getIndex = classNames.indexOf(className);

                if (getIndex === -1) {
                    classNames.push(className);
                    element.className = classNames.join(' ');
                }
            }catch(err){err;}
		},
		delClass(id, className) {
            try{
                var element = document.getElementById(id);
                var classNames = element.className.split(' ');
                var getIndex = classNames.indexOf(className);

                if (getIndex > -1) {
                    classNames.splice(getIndex, 1);
                }
                element.className = classNames.join(' ');
            }catch(err){err;}
		},
        existsClass(id, className){     //css 특정 클래스가 존재하는지 체크
            var element = document.getElementById(id);
            var classNames = element.className.split(' ');
            var getIndex = classNames.indexOf(className);

            if(getIndex > -1){
                return true;
            } else {
                return false;
            }
        }
    }
}
</script>