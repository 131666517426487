import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

export default new Vuex.Store({
    plugins: [
        createPersistedState({
            paths: [ "email", "sign", "ticket", "loginCheck", "isCheckedAdult", "walletAddress", "isEnableMetamask", "isEnableKaikas", "isEnableKlip", "reqOAuthKey", "callbackOAuthEmail", "isOAuthRedirectPage", "isOAuthShowLoginPopup" ]
        })
    ],
    state: {
        siteConfig: null,

        ticket: '',
        sign: '',
        loginCheck: false,
        adult: false,
        alarm: false,
        attBounsPoint: 0,
        attPoint: 0,
        createDate: '',
        email: '',
        contactEmail: '',
        leaveDate: '',
        nationality: '',
        nickName: '',
        pgBounsPoint: 0,
        pgPoint: 0,
        photo: '',
        rank: 0,
        social: '',     //0:artube, 1:google, 2:apple, 3:facebook, 4:naver, 5:kakao
        state: false,
        writerIdx: 0,
        chargeCount: 0,
        erc20Address: '',
        klipAddress: '',

        isCheckedAdult: false,
        
        walletAddress: '',
        attTokenBalance: 0,

        isEnableMetamask: false,
        isEnableKaikas: false,
        isEnableKlip: false,

        tokenInfo: null,
        tokenInfoTimerCount: 0,

        reqOAuthKey: '',                //OAuth 요청 페이지 db key
        callbackOAuthEmail: '',         //OAuth 인증 중 이미 회원가입된 이메일일 경우 해당 주소 받아와서 로그인할 수 있도록 입력해 줌
        isOAuthRedirectPage: false,     //OAuth 인증 후 Redirect 된 페이지 인지 체크
        isOAuthShowLoginPopup: false,   //OAuth 인증 후 로그인 팝업 활성 유무
        isShowLoginPopupForce: false,   //OAuth 인증 후 로그인 팝업이 활성될때 동시에 뜨는 팝업들을 띄우지 않게 함

        //Floating Popup
        wsAlram: null,  //알림 웹소켓
        intervalCheckWebSocketState: null,
        wsCheckState: 0,    //0 연결 안함, 1: 연결 확인 요청 중, 2: 연결 확인 됨
        isShowFloating: false,
        floatingData: null,
    },
    mutations: {
        setEmail: function (state, email) {
            state.email = email;
        },
        loginTicket: function (state, ticket) {
            state.ticket = ticket;
            state.loginCheck = true;
        },
        loginSign: function (state, sign) {
            state.sign = sign;
        },
        logout: function (state) {
            state.ticket = '',
            state.sign = '',
            state.loginCheck = false,
            state.adult = false,
            state.alarm = false,
            state.attBounsPoint = 0,
            state.attPoint = 0,
            state.createDate = '',
            state.email = '',
            state.contactEmail = '',
            state.leaveDate = '',
            state.nationality = '',
            state.nickName = '',
            state.pgBounsPoint = 0,
            state.pgPoint = 0,
            state.photo = '',
            state.rank = 0,
            state.social = '',
            state.state = false,
            state.writerIdx = 0,
            state.chargeCount = 0,
            state.erc20Address = '',
            state.klipAddress = '',

            state.isCheckedAdult = false,

            state.walletAddress = '',
            state.attTokenBalance = 0,

            state.isEnableMetamask = false,
            state.isEnableKaikas = false,
            state.isEnableKlip = false,

            state.tokenInfo = null,
            state.tokenInfoTimerCount = 0

            state.wsAlram = null;
        },
        setProfile: async function (state, host) {
            var param = 
            {
                sign: state.sign,
                ticket: state.ticket,
                viewAdult: state.isCheckedAdult ? 1 : 0
            }
            return await axios.post(`${host}/member/info`, param, { headers: { 'Pragma' : 'no-cache', 'Cache-Control': 'no-cache', 'Access-Control-Allow-Origin': '*', 'Contcurrent-type': 'application/json', }}).then(res => {
                // console.log(res.data);
                if (res.data.returnCode === 0) {
                    state.adult         = res.data.data.adult        ;
                    state.alarm         = res.data.data.alarm        ;
                    state.attBounsPoint = res.data.data.attBounsPoint;
                    state.attPoint      = res.data.data.attPoint     ;
                    state.createDate    = res.data.data.createDate   ;
                    state.email         = res.data.data.email        ;
                    state.contactEmail  = res.data.data.contactEmail ;
                    state.leaveDate     = res.data.data.leaveDate    ;
                    state.nationality   = res.data.data.nationality  ;
                    state.nickName      = res.data.data.nickName     ;
                    state.pgBounsPoint  = res.data.data.pgBounsPoint ;
                    state.pgPoint       = res.data.data.pgPoint      ;
                    state.photo         = res.data.data.photo        ;
                    state.rank          = res.data.data.rank         ;
                    state.social        = res.data.data.social       ;
                    state.state         = res.data.data.state        ;
                    state.writerIdx     = res.data.data.writerIdx    ;
                    state.chargeCount   = res.data.data.chargeCount  ;
                    state.erc20Address  = res.data.data.erc20Address ;
                    state.klipAddress   = res.data.data.klipAddress  ;
                    return true;
                } else {
                    return false;
                }
            })
        },
        setSiteConfig: async function (state, host) {
            if(state.siteConfig === null){
                var param = 
                {
                }
                await axios.post(`${host}/config/site`, param, { headers: { 'Pragma' : 'no-cache', 'Cache-Control': 'no-cache', 'Access-Control-Allow-Origin': '*', 'Content-type': 'application/json', }}).then(res => {
                    // console.log(res.data.data);
                    if(res.data.returnCode === 0){
                        state.siteConfig = res.data.data;
                    }
                })
            }
        },
        checkAdult: function (state, flag){
            state.isCheckedAdult = flag;
        },
        disconnectMetamask: function (state){
            state.walletAddress = '',
            state.attTokenBalance = 0,
            state.isEnableMetamask = false
        },
        connectKaikas: function(state, account){
            state.isEnableKaikas = true,
            state.walletAddress = account
        },
        disconnectKaikas: function(state){
            state.walletAddress = '',
            state.attTokenBalance = 0,
            state.isEnableKaikas = false
        },
        connectKlip: function(state, account){
            state.isEnableKlip = true,
            state.walletAddress = account
        },
        disconnectKlip: function(state){
            state.walletAddress = '',
            state.attTokenBalance = 0,
            state.isEnableKlip = false
        },
        disconnectWebSocket: function(state){
            (state.wsAlram = null),
            (state.intervalCheckWebSocketState = null),
            (state.wsCheckState = 0);
        },
        setReqOAuthKey: function(state, key){
            state.reqOAuthKey = key
        },
        setIsOAuthRedirectPage: function(state){
            state.isOAuthRedirectPage = true
        },
        setIsOAuthShowLoginPopup: function(state, isShowPopup){
            state.isOAuthShowLoginPopup = isShowPopup
        },
        setIsShowLoginPopupForce: function(state){
            state.isShowLoginPopupForce = true
        },
        setCallbackOAuthEmail: function(state, email){
            state.callbackOAuthEmail = email
        },
        resetOAuthData: function(state){
            state.reqOAuthKey = '',
            state.callbackOAuthEmail = ''
            state.isOAuthRedirectPage = false,
            state.isOAuthShowLoginPopup = false
        },
        resetFloating: function (state) {
            (state.isShowFloating = false), (state.floatingData = null);
        },
    },
    actions: {

    },
    modules: {

    }
})
