import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from './i18n'
import axios from 'axios'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import VueMoment from 'vue-moment';
import VueClipboard from 'vue-clipboard2'
import VueAppleSignin from "vue-apple-signin";
// import VueAppleSigninM from "vue-apple-signin-m";

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import './assets/css/common.css'
import './assets/css/component.css'
import './assets/css/event.css'
import './assets/css/header.css'
import './assets/css/index.css'
import './assets/css/league.css'
import './assets/css/login.css'
import './assets/css/member.css'
import './assets/css/point.css'
import './assets/css/webtoon.css'
import './assets/css/assist.css'
import './assets/css/mobile.css'

Vue.prototype.$Axios = axios;
Vue.config.productionTip = false;

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(VueMoment)
Vue.use(VueClipboard)
Vue.use(VueAppleSignin, {
    clientId: "video.artube",
    scope: "name email",
    // redirectURI: "https://attwallet.numbers21.com:31443/oauth/api/v1/applelogin",    //test
    redirectURI: "https://webtoon.artube.video:31443/oauth/api/v1/applelogin",      //live
    state: "-",
    nonce: '',
    usePopup: true
});
// Vue.use(VueAppleSigninM, {
//     clientId: "video.artube",
//     scope: "name email",
    // redirectURI: "https://attwallet.numbers21.com:31443/oauth/api/v1/mapplelogin",   //test
    // redirectURI: "https://webtoon.artube.video:31443/oauth/api/v1/mapplelogin",     //live
//     state: "-",
//     nonce: '',
//     usePopup: true
// });

Vue.mixin({
    data(){
        return{
            
            // host: 'https://attwallet.numbers21.com:5443/attwebtoon/api/v1',         //test
            // league_host: 'https://attwallet.numbers21.com:5443/attwebtoon/api/v2',  //test
            // oauth_host: 'https://attwallet.numbers21.com:31443/oauth/api/v1',       //test
            // ws_host: 'wss://attwallet.numbers21.com:5443/attwebtoon/sconnect',      //test
            
            host: 'https://webtoon.artube.video:5443/attwebtoon/api/v1',            //live
            league_host: 'https://webtoon.artube.video:5443/attwebtoon/api/v2',     //live
            oauth_host: 'https://webtoon.artube.video:31443/oauth/api/v1',          //live
            ws_host: 'wss://webtoon.artube.video:5443/attwebtoon/sconnect',         //live
        }
    },
    beforeDestroy(){
    },
    methods: {
        isPc() {
            var ua = window.navigator.userAgent.toString().toLowerCase() || '';
            if (ua.match(/linux|windows (nt|98)|macintosh|cros/) && !ua.match(/android|mobile|polaris|lgtelecom|uzard|natebrowser|ktf;|skt;/)) {
                return true;
            }
            return false;
        },
        isTablet() {
            var ua = window.navigator.userAgent.toString().toLowerCase() || '';
            if (ua.match(/ipad/) || (ua.match(/android/) && !ua.match(/mobi|mini|fennec/))) {
                return true;
            }
            return false;
        },
        isMobile() {
            var ua = window.navigator.userAgent.toString().toLowerCase() || '';
            if (ua.match(/ip(hone|od)|android.+mobile|windows (ce|phone)|blackberry|bb10|symbian|webos|firefox.+fennec|opera m(ob|in)i|polaris|iemobile|lgtelecom|nokia|sonyericsson|dolfin|uzard|natebrowser|ktf;|skt;/)) {
                return true;
            } else {
                return false;
            }
        },
        isArtubeWebview() {
            var ua = window.navigator.userAgent.toString().toLowerCase() || '';
            if (ua.match(/artubewebview/)) {
                return true;
            } else {
                return false;
            }
        },
        async mixinLoginCheck() {
            if(this.$store.state.sign !== '' && this.$store.state.ticket !== ''){
                var param = 
                {
                    sign: this.$store.state.sign,
                    ticket: this.$store.state.ticket,
                    viewAdult: this.$store.state.isCheckedAdult ? 1 : 0
                }
                await this.$Axios.post(`${this.host}/member/ticket/check`, param, { headers: { 'Pragma' : 'no-cache', 'Cache-Control': 'no-cache', 'Access-Control-Allow-Origin': '*', 'Content-type': 'application/json', }}).then(async res => {
                    // console.log('mixin login check');
                    // console.log(res);
                    if (res.data.returnCode === 0) {
                        await this.$store.commit("setProfile", this.host);
                    }
                    else {
                        await this.$store.commit("logout");
                    }
                }).catch(error => {
                    error;
                    console.log(error);
                    // this.$store.commit("logout");
                    // this.$router.push('/');
                })
            }
            else{
                await this.$store.commit("logout");
            }
        },
        async mixinReqPageView() {      //페이지 접근 카운팅
            var param = 
            {
                page: location.href
            }
            return await this.$Axios.post(`${this.host}/pv`, param, { headers: { 'Pragma' : 'no-cache', 'Cache-Control': 'no-cache', 'Access-Control-Allow-Origin': '*', 'Content-type': 'application/json', }}).then(async res => {
                if (res.data.returnCode === 0) {
                    return true;
                }
                else {
                    return false;
                }
            }).catch(error => {
                error;
                return false;
            })
        },
        async mixinCheckOAuthRedirect(showLoginPopupCallback = null, passLoginPopupCallback = null) {    //OAuth 인증 후 진입하는 페이지일 경우 로그인 팝업 띄울지 체크

            // console.log('---mixin OAuth Check ---');
            // console.log(this.$store.state.isOAuthRedirectPage);
            // console.log(this.$store.state.isOAuthShowLoginPopup);
            // console.log(this.$store.state.isShowLoginPopupForce);

            if(this.$store.state.isOAuthRedirectPage){
                var isShowLoginPopup = this.$store.state.isOAuthShowLoginPopup ? true : false;
                var loginEmail = this.$store.state.callbackOAuthEmail;
                await this.$store.commit("resetOAuthData");
                if(isShowLoginPopup){
                    var res = {
                        email: loginEmail
                    }
                    await this.$store.commit("setIsShowLoginPopupForce");
                    if(showLoginPopupCallback) showLoginPopupCallback(res);
                } else {
                    if(passLoginPopupCallback) passLoginPopupCallback();
                }
            } else {
                await this.$store.commit("resetOAuthData");
                if(passLoginPopupCallback) passLoginPopupCallback();
            }
        },
        //로컬스토리지에 객체저장
        fn_setLocalStorage(name,obj){
            localStorage.setItem(name, obj);
        },
        //로컬스토리지에 특정 객체 삭제
        fn_removeLocalStorage(name){
            localStorage.removeItem(name);
        },
        //로컬스토리지에서 특정 객체 가져오기
        fn_getItemLocalStorage(name){
            return localStorage.getItem(name);
        },
    }
})

Vue.filter('comma', function (val) {
    var filter_str = '';
    var isMinus = false;
    if(val < 0){
        isMinus = true;
        val = val.toString().replace('-', '');
    }

    if(val >= 0 && val !== null && val !== undefined){
        var split_num = val.toString().split('.');
        filter_str = String(split_num[0]).replace(/\B(?=(\d{3})+(?!\d))/g, ",");

        if(split_num[1]){
            filter_str = filter_str + '.' + split_num[1];
        }
    }

    if(filter_str === '' || filter_str === null || filter_str === undefined){
        filter_str = '0';
    }

    if(isMinus){
        filter_str = '-' + filter_str;
    }

    return filter_str;
})

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app')
