<template>
    <div class="mybookroom wrap">
        <Header :initHeaderType="initHeaderType" />
        <div class="box">
            <b-container>
                <div class="mybookroom-title-box">
                    <h2>내 서재</h2>
                    <h3>최근 감상 작품, 관심 작품,소장 작품 등을 확인 할 수 있습니다.</h3>
                </div>
                <!-- <h1>내 서재</h1> -->
                <div class="mybook-category-tab">
                    <ul class="category-tab-box">
                        <li :class="{'on' : selectedTab === 0}"><a @click="changeTab(0)">웹툰</a></li>
                        <li :class="{'on' : selectedTab === 1}"><a @click="changeTab(1)">아튜브 챌린지</a></li>
                        <!-- <li :class="{'on' : selectedTab === 3}"><a @click="changeTab(3)">NFT 갤러리</a></li> -->
                    </ul>
                </div>
                <section id="mybookWebtoon">
                    <div class="mybook-category">
                        <div class="menu">
                            <ul>
                                <li @click="changeSubTab(0)" :class="[selectedSubTab === 0 ? 'on' : '']"><a href="#">감상한 작품</a></li>
                                <li @click="changeSubTab(1)" :class="[selectedSubTab === 1 ? 'on' : '']"><a>즐겨찾기</a></li>
                                <li @click="changeSubTab(2)" :class="[selectedSubTab === 2 ? 'on' : '']" v-if="selectedTab === 0"><a>소장 작품</a></li>
                            </ul>
                        </div>
                    </div>
                    <div class="mybook-system-bar">
                        <ul class=" mybook-system-box">
                            <!-- /인풋박스/ -->
                            <li class="mybook-system-check">
                                <a class="" @click="toggleFilter"><i class="icon icon-mybook-system" /></a>
                                <div class="box-mybook-check" v-if="isOpenMybookSearch" v-click-outside="toggleFilter" >
                                    <div class="box-mybook-check-top">
                                        <b-form-group label="종류" v-slot="{ ariaTypeAdult }">
                                            <b-form-radio-group v-model="selectedAdult" :options="radioAdultOptions" :aria-describedby="ariaTypeAdult" plain stacked @change="changeFilter"></b-form-radio-group>
                                        </b-form-group>                                        
                                        <!-- <h4>종류</h4>
                                        <div>
                                            <input type="checkbox" class="styled-checkbox" id="check-value1" v-model="isCheckedTypeAll" value="0" @click="changeAdultType">
                                            <label for="check-value1">전체</label>
                                        </div>
                                        <div>
                                            <input type="checkbox" class="styled-checkbox" id="check-value2" v-model="isCheckedTypeAdult" value="1" @change="changeAdultType">
                                            <label for="check-value2">성인</label>
                                        </div>
                                        <div>
                                            <input type="checkbox" class="styled-checkbox" id="check-value3" v-model="isCheckedTypeNonAdult" value="2" @change="changeAdultType">
                                            <label for="check-value3">비성인</label>
                                        </div> -->
                                    </div>
                                    <div class="box-mybook-check-btm">
                                        <b-form-group label="정렬" v-slot="{ ariaTypeSort }">
                                            <b-form-radio-group v-model="selectedSort" :options="radioSortOptions" :aria-describedby="ariaTypeSort" plain stacked @change="changeFilter"></b-form-radio-group>
                                        </b-form-group>
                                        <!-- <h4>정렬</h4>
                                        <div>
                                            <input type="checkbox" class="styled-checkbox" id="check-sort1"  v-model="isCheckedSortView" @change="changeSortType(0)">
                                            <label for="check-sort1">최근 감상 순</label>
                                        </div>
                                        <div>
                                            <input type="checkbox" class="styled-checkbox" id="check-sort2"  v-model="isCheckedSortUpdate" @change="changeSortType(1)">
                                            <label for="check-sort2">업데이트 순</label>
                                        </div> -->
                                    </div>
                                </div>
                            </li>
                                
                            <li class="mybook-system-title">{{getFilterAdult()}} / {{getFilterSort()}}</li>
                            <!-- /검색박스/ -->
                        </ul>
                        <ul class=" mybook-system-box">
                            <li class="mybook-system-search">
                                <div class="box-mybook-search">
                                    <input type="text" placeholder="작품명으로 검색" id="inputSearch" v-model="searchText">
                                    <img src="@/assets/images/icon_search_black.svg" class="btn-search" @click="search()" />
                                </div>
                            </li>
                            <!-- /삭제박스/ -->
                            <li class="mybook-system-delete">
                                <div class="box-mybook-delete">
                                    <div v-show="isDeleteBtn">
                                        <button class="delete" @click="deleteButton">삭제</button>
                                    </div>
                                    <div v-show="!isDeleteBtn">
                                        <button class="cancle" @click="deleteCancle">취소</button>
                                        <button class="submit" @click="confirmDelete">확인</button>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div class="container mynft-none" v-if="mybookList.length <= 0">
                        <p><img src="@/assets/images/icon/icon_nft_none.png" alt=""></p>
                        
                        <h2 v-if="isSearch">검색된 작품이 없습니다.</h2>
                        <h2 v-else-if="selectedSubTab === 0">최근에 본 작품이 없습니다.</h2>
                        <h2 v-else-if="selectedSubTab === 1">즐겨찾기한 작품이 없습니다.</h2>
                        <h2 v-else-if="selectedSubTab === 2">소장한 작품이 없습니다.</h2>
                        <router-link tag="a" to="/DailyToon" class="mynft-buy" v-if="!isSearch && selectedTab === 0 && selectedSubTab === 0">작품을 감상해 보세요</router-link>
                        <router-link tag="a" to="/League" class="mynft-buy" v-else-if="!isSearch && selectedTab === 1 && selectedSubTab === 0">작품을 감상해 보세요</router-link>
                        <!-- <router-link tag="a" to="/DailyToon" class="mynft-buy">NFT 작품 구매하기</router-link> -->
                    </div>
                    <b-row tag="div" cols="4" sm="2" md="4" v-else-if="selectedTab === 0">
                        <b-col tag="div" v-for="(item, index) in mybookList" :key="index" class="mybook-content-inner">
                            <div class="webtoon-container">
                                <img src="@/assets/images/adult_mark.svg" alt="성인 마크" class="adult_mark" v-if="item.twebtoonForApi.adult === 1">
                                <img :src="$store.state.siteConfig.cdnUrl + '/KR/title/' + item.twebtoonForApi.englishName + '.jpg'" class="img-webtoon" v-if="$store.state.siteConfig !== null" @click="moveDetail(item.twebtoonForApi)">
                                <div class="webtoon-info" @click="moveDetail(item.twebtoonForApi)">
                                    <div class="info">
                                        <p class="name">{{item.twebtoonForApi.titleName}}</p>
                                        <span>총 {{item.epCount}}화</span>
                                        <span>&middot;</span>
                                        <span>{{getCategoryName(item.twebtoonForApi.category)}}</span>
                                        <p class="recent-time">{{getUpdateTime(item)}}</p>
                                    </div>
                                </div>
                                <div class="episode-info">
                                    <div class="info" :class="{'hidden' : !isDeleteBtn}">
                                        <p class="next" @click="moveNextEpisode(item)" v-if="selectedSubTab === 0 && item.tviewMylibrary.vmEpisodeIdx !== item.tviewMylibrary.vmNextEpisodeIdx">{{item.tviewMylibrary.vmNextEpisodeIdx}}화 보러가기 ></p>
                                        <p @click="moveDetail(item.twebtoonForApi)" v-else-if="selectedSubTab === 0 && item.twebtoonForApi.complete === 1 ">완결</p>
                                        <p @click="moveDetail(item.twebtoonForApi)" v-else-if="selectedSubTab === 0 && item.twebtoonForApi.complete === 0 ">업데이트 중</p>
                                    </div>
                                </div>
                            </div>
                            <div class="full-overlay delete-bg" v-if="!isDeleteBtn" >
                                <div class="content">
                                    <input type="checkbox" name="check" class="styled-checkbox" :id="'delete-check-' + index" :value="item.twebtoonForApi.webToonIndex" @change="changeDeleteItem">
                                    <label :for="'delete-check-' + index"></label>
                                </div>
                            </div>
                        </b-col>
                    </b-row>
                    <b-row tag="div" cols="4" sm="2" md="4" v-else-if="selectedTab === 1">
                        <b-col tag="div" v-for="(item, index) in mybookList" :key="index" class="mybook-content-inner">
                            <div class="webtoon-container">
                                <img src="@/assets/images/adult_mark.svg" alt="성인 마크" class="adult_mark" v-if="item.tchallengeWebtoonJoinEpisode.ctAdult === 1">
                                <img :src="$store.state.siteConfig.cdnUrl + '/CHALLENGE/mainthumbnail/' + item.tchallengeWebtoonJoinEpisode.ctCdnFolder + '.jpg'" class="img-webtoon" v-if="$store.state.siteConfig !== null" @click="moveChallengeDetail(item.tchallengeWebtoonJoinEpisode)">
                                <div class="webtoon-info" @click="moveChallengeDetail(item.tchallengeWebtoonJoinEpisode)">
                                    <div class="info">
                                        <p class="name">{{item.tchallengeWebtoonJoinEpisode.ctTitleName}}</p>
                                        <span>총 {{item.tchallengeWebtoonJoinEpisode.epCount !== null ? item.tchallengeWebtoonJoinEpisode.epCount : 0}}화</span>
                                        <span>&middot;</span>
                                        <span>{{getCategoryName(item.tchallengeWebtoonJoinEpisode.ctCategoryMain)}}</span>
                                        <p class="recent-time">{{getUpdateTime(item)}}</p>
                                    </div>
                                </div>
                                <div class="episode-info">
                                    <div class="info" :class="{'hidden' : !isDeleteBtn}">
                                        <p class="next" @click="moveNextEpisode(item)" v-if="selectedSubTab === 0 && item.tchallengeViewMylibrary.cmEpisodeIdx !== item.tchallengeViewMylibrary.cmNextEpisodeIdx">{{item.tchallengeViewMylibrary.cmNextEpisodeIdx}}화 보러가기 ></p>
                                        <p @click="moveChallengeDetail(item.tchallengeWebtoonJoinEpisode)" v-else-if="selectedSubTab === 0 && item.tchallengeWebtoonJoinEpisode.ctComplete === 1 ">완결</p>
                                        <p @click="moveChallengeDetail(item.tchallengeWebtoonJoinEpisode)" v-else-if="selectedSubTab === 0 && item.tchallengeWebtoonJoinEpisode.ctComplete === 0 ">업데이트 중</p>
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="m_alarm ring"><img src="@/assets/images/mobile/bell_off.svg" alt="알람"></div> -->
                            <div class="full-overlay delete-bg" v-if="!isDeleteBtn" >
                                <div class="content">
                                    <input type="checkbox" name="check" class="styled-checkbox" :id="'delete-check-' + index" :value="item.tchallengeWebtoonJoinEpisode.ctWebtoonIdx" @change="changeDeleteItem">
                                    <label :for="'delete-check-' + index"></label>
                                </div>
                            </div>
                            
                            <div class="full-overlay inspected-bg" v-else-if="item.tchallengeWebtoonJoinEpisode.ctState === 0" @click="moveChallengeDetail(item.tchallengeWebtoonJoinEpisode)">
                                <div class="content">
                                    <img class="inspected-img" src="@/assets/images/icon/icon_challange_inspected.svg" alt="">
                                    <p>검수 중</p>
                                </div>
                            </div>
                            <div class="full-overlay banned-bg" v-else-if="item.tchallengeWebtoonJoinEpisode.ctState === 2" @click="moveChallengeDetail(item.tchallengeWebtoonJoinEpisode)">
                                <div class="content">
                                    <img class="banned-img" src="@/assets/images/icon/icon_challange_banned.svg" alt="">
                                    <p>운영 원칙 위반</p>
                                </div>
                            </div>
                        </b-col>
                    </b-row>
                    <b-row tag="div" cols="4" sm="2" md="4" v-else-if="selectedTab === 2">
                        <!-- <b-col tag="div" v-for="(item, index) in mybookList" :key="index" class="mybook-content-inner">
                            <div @click="moveChallengeDetail(item.tchallengeWebtoonJoinEpisode)">
                                <img src="@/assets/images/adult_mark.svg" alt="성인 마크" class="adult_mark" v-if="item.tchallengeWebtoonJoinEpisode.ctAdult === 1">
                                <img :src="$store.state.siteConfig.cdnUrl + '/CHALLENGE/mainthumbnail/' + item.tchallengeWebtoonJoinEpisode.ctCdnFolder + '.jpg'" v-if="$store.state.siteConfig !== null">
                                <div class="webtoon-info">
                                    <span><i class="icon-nft-heart"></i> 713</span>
                                    <p class="name">Crushed Heart and …</p>
                                    <span>&middot;</span>
                                    <span>장르</span>
                                    <p class="recent-time">???시간전</p>
                                    <router-link tag="p" to="#" class="more" >{{item.tchallengeWebtoonJoinEpisode.vmNextEpisodeIdx}}화 보러가기 ></router-link>
                                    <p class="writer">{{item.twebtoonForApi.publisher}}</p>
                                    <p class="time" v-html="item.twebtoonForApi.desc"></p>
                                </div>
                            </div>
                            <div class="m_alarm ring"><img src="@/assets/images/mobile/bell_off.svg" alt="알람"></div>
                            <div class="delete-bg" v-show="!isDeleteBtn" >
                                <input type="checkbox" name="check" class="styled-checkbox" >
                                <label for="delete-check">
                                
                                </label>
                            </div>
                        </b-col> -->
                    </b-row>
                    <Pagination :initMaxRecordCount="currentPageSize" :initMaxPageCount="maxPageCount" :initPagingData="initPagingData" @movePaging="movePaging" />

                </section>
                <!-- /마이북 웹툰/ -->
            </b-container>
        </div>
        <Footer />
        <PopupConfirm :initIsShowPopup="isShowPopupConfirmPopup" :initPopupConfrimMessage="initPopupConfrimMessage" @confirmCancel="close('PopupConfirm')" @confirmOk="reqDelete()" />
        <PopupOK :initIsShowPopup="isShowPopupOkPopup" :initPopupOkMessage="initPopupOkMessage" @close="close('PopupOk')" />
        <Login :initIsShowPopup="isShowLoginPopup" @close="close('Login')" />
        <Buy  :initIsShowPopup="isShowBuyPopup" :initWebtoonInfo="initWebtoonInfo" :initEpisodeInfo="initEpisodeInfo" :initSiteConfig="$store.state.siteConfig" @close="close('Buy')"  />
    </div>
</template>

<script>
import Header from '@/components/common/Header.vue'
import Footer from '@/components/common/Footer.vue'
import Login from '@/components/common/Login.vue'
import Pagination from '@/components/common/Pagination.vue'
import PopupOK from '@/components/common/PopupOK.vue'
import PopupConfirm from '@/components/common/PopupConfirm.vue'
import vClickOutside from 'v-click-outside'
import Buy from '@/components/webtoon/Buy.vue'

export default {
    name: 'MemberLibrary',
    pageCategory: 'Member',
    pageTitle: '내 서재',
    directives: {
        clickOutside: vClickOutside.directive
    },
    components: {
        Header,
        Footer,
        Login,
        Pagination,
        PopupOK,
        PopupConfirm,
        Buy,
    },
    data() {
        return {
            selectedTab: 0,     // 0 - 웹툰 1 - 아튜브 챌린지
            selectedSubTab: 0,  // 0 - 감상한 작품 1 - 즐겨찾기 2 - 소장 작품
            selectedAdult: 2,   // 0 - 비성인 1 - 성인 2 - 전체
            selectedSort: 1,    // 0 - 업데이트순 1 - 최근본 작품 순
            
            radioAdultOptions: [ { text: '전체', value: 2 }, { text: '성인', value: 1 }, { text: '비성인', value: 0 } ],
            radioSortOptions:  [ { text: '최근 감상 순', value: 1 }, { text: '업데이트 순', value: 0 } ],

            searchText: '',

            categoryList: [],
            mybookList: [],

            currentPageNo: 1,      //현재 페이지 번호
            currentPageSize: 12,    //한번에 불러올 게시물 수
            maxPageCount:10,        //한 화면에 보여질 페이지 수
            initPagingData: null,

            isShowPopupOkPopup: false,
            initPopupOkMessage: '',
            PopupOkCallbakPage: '',    // 확인 팝업에서 확인 버튼 눌렀을때 이동할 페이지
            
            isShowLoginPopup: false,
            initWebtoonInfo: null,
            initEpisodeInfo: null,

            isShowBuyPopup: false,

            initHeaderType: 'type2',

            isSubscribe:true,
            isOpenMybookSearch: false,
            isSearch: false,
            isDeleteBtn:true,

            deleteList: [],

            isShowPopupConfirmPopup: false,
            initPopupConfrimMessage: '',
        }
    },
    async created(){
        // await this.mixinSiteConfigCheck();
        await this.mixinLoginCheck();

        if(!this.$store.state.loginCheck){
            this.initPopupOkMessage = '로그인 후 이용하실 수 있습니다.'
            this.PopupOkCallbakPage = '/';
            this.isShowPopupOkPopup = true;
        }
        else{
            this.getCategory();
            this.changeTab(this.selectedTab);
        }
    },
    methods: {
        changeTab(tabNo){
            this.selectedTab = tabNo;
            this.resetSubTab();
            this.resetFilter();
            this.resetListData();
            this.getList();
        },
        changeSubTab(subTabNo){
            this.selectedSubTab = subTabNo;
            this.resetFilter();
            this.resetListData();
            this.getList();
        },
        changeFilter(){
            this.resetListData();
            this.searchText = '';
            this.getList();
        },
        search(){
            this.resetListData();
            this.isSearch = true;
            this.getList();
        },
        resetSubTab(){
            this.selectedSubTab = 0;
        },
        resetFilter(){
            this.selectedAdult = 2;
            this.selectedSort = 1;
            this.isDeleteBtn = true;
            this.isSearch = false;
            this.searchText = '';
        },
        resetListData(){
            this.isSearch = false;
            this.mybookList = [];
            this.currentPageNo = 1;
            this.totalRecordSize = 0;
            this.initPagingData = null;
        },
        getList(){
            if(this.selectedSubTab === 0){    //감상한 작품
                if(this.selectedTab === 0) this.getViewList();
                else if(this.selectedTab === 1) this.getChallengeViewList();
            } else if(this.selectedSubTab === 1){ //즐겨찾기
                if(this.selectedTab === 0) this.getSubscribeList();
                else if(this.selectedTab === 1) this.getChallengeSubscribeList();
            } else if(this.selectedSubTab === 2){ //소장 작품
                if(this.selectedTab === 0) this.getBuyList();
            }
        },
        toggleFilter(){
            this.isOpenMybookSearch = !this.isOpenMybookSearch;
        },
        getCategory(){ // 카테고리 정보
            var param = 
            {
                // limit : 3
            }
            this.$Axios.post(`${this.host}/infocategory`, param, { headers: { 'Pragma' : 'no-cache', 'Cache-Control': 'no-cache', 'Access-Control-Allow-Origin': '*', 'Content-type': 'application/json', }}).then(res => {
                if (res.data.returnCode === 0) {
                    this.categoryList = res.data.list;
                }
            })
            .catch((error) => {
                console.log(error);
            });
        },
        getCategoryName(category_idx){
            var name = '';
            this.categoryList.forEach(element =>{
                if(category_idx === element.idx){
                    name = element.name;
                }
            })
            return name;
        },
        getUpdateTime(webtoonInfo){
            var serverTime = null;
            var updateTime = null;
            var lastViewMinute = null;
            if(this.selectedTab === 0){   //웹툰
                if(this.selectedSubTab === 0){    //감상한 작품
                    if(webtoonInfo.tviewMylibrary !== null){
                        serverTime = Date.parse(webtoonInfo.tviewMylibrary.serverNow);
                        updateTime = Date.parse(webtoonInfo.tviewMylibrary.vmUpdate);
                        lastViewMinute = Math.floor((serverTime - updateTime) / 1000 / 60 / 60);
                        return this.getViewInfoText(lastViewMinute, webtoonInfo.tviewMylibrary.vmUpdate);                           
                    }else {
                        return '';
                    }
                } else if(this.selectedSubTab === 1 || this.selectedSubTab === 2){ //구독한 작품, 소장 작품
                    if(webtoonInfo.twebtoonForApi.complete === 1){
                        return '완결';
                    } else {
                        return this.getDateText(webtoonInfo.twebtoonForApi.episodeUpdate);
                    }
                }
            } else if(this.selectedTab === 1){    //챌린지
                if(this.selectedSubTab === 0){    //감상한 작품
                    if(webtoonInfo.tchallengeViewMylibrary !== null){
                        serverTime = Date.parse(webtoonInfo.tchallengeViewMylibrary.serverNow);
                        updateTime = Date.parse(webtoonInfo.tchallengeViewMylibrary.cmUpdate);
                        lastViewMinute = Math.floor((serverTime - updateTime) / 1000 / 60 / 60);
                        return this.getViewInfoText(lastViewMinute, webtoonInfo.tchallengeViewMylibrary.cmUpdate);                        
                    }else {
                        return '';
                    }
                } else if(this.selectedSubTab === 1){ //구독한 작품
                    if(webtoonInfo.tchallengeWebtoonJoinEpisode.complete === 1){
                        return '완결';
                    } else {
                        return this.getDateText(webtoonInfo.tchallengeWebtoonJoinEpisode.ctInspectPassDate);
                    }
                    
                }
            }
        },
        getViewInfoText(lastViewMinute, updateDate){
            if(lastViewMinute < 1){
                return '방금';
            } else if(lastViewMinute >= 1 && lastViewMinute < 24){
                return lastViewMinute + '시간 전';
            } else {
                return this.getDateText(updateDate);
            }
        },
        getDateText(updateDate){
            if(updateDate !== null){
                return updateDate.substring(2, 4) + '.' + updateDate.substring(5,7) + '.' + updateDate.substring(8,10);
            }else {
                return '';
            }
        },
        getViewList(){  //본 작품 리스트
            this.selectedSubTab = 0;
            this.mybookList = [];

            var param = 
            {
                pageNo: this.currentPageNo,
                pageSize: this.currentPageSize,
                sort: this.selectedSort,
                sign: this.$store.state.sign,
                ticket: this.$store.state.ticket,
                viewAdult: this.$store.state.isCheckedAdult ? 1 : 0,
                filterAdult: this.selectedAdult,
                search: this.searchText === '' ? null : this.searchText
            }
            this.$Axios.post(`${this.host}/mylibrary/viwelist`, param, { headers: { 'Pragma' : 'no-cache', 'Cache-Control': 'no-cache', 'Access-Control-Allow-Origin': '*', 'Content-type': 'application/json', }}).then(res => {
                // console.log('-----본 작품 리스트-----');
                // console.log(res.data);
                if (res.data.returnCode === 0) {
                    this.mybookList = res.data.list;
                    this.initPagingData = res.data.page;
                }
            })
            .catch((error) => {
                console.log(error);
            });
        },
        getSubscribeList(){ //구독한 작품 리스트
            this.selectedSubTab = 1;
            this.mybookList = [];
   
            var param = 
            {
                pageNo: this.currentPageNo,
                pageSize: this.currentPageSize,
                sort: this.selectedSort,
                sign: this.$store.state.sign,
                ticket: this.$store.state.ticket,
                viewAdult: this.$store.state.isCheckedAdult ? 1 : 0,
                filterAdult: this.selectedAdult,
                search: this.searchText === '' ? null : this.searchText
            }
            this.$Axios.post(`${this.host}/mylibrary/subscribe/list`, param, { headers: { 'Pragma' : 'no-cache', 'Cache-Control': 'no-cache', 'Access-Control-Allow-Origin': '*', 'Content-type': 'application/json', }}).then(res => {
                // console.log('-----구독한 리스트-----');
                // console.log(res.data);
                if (res.data.returnCode === 0) {
                    this.mybookList = res.data.list;
                    this.initPagingData = res.data.page;
                }
            })
            .catch((error) => {
                console.log(error);
            });
        },
        getBuyList(){   //소장한 작품 리스트
            this.selectedSubTab = 2;
            this.mybookList = [];

            var param = 
            {
                pageNo: this.currentPageNo,
                pageSize: this.currentPageSize,
                sort: this.selectedSort,
                sign: this.$store.state.sign,
                ticket: this.$store.state.ticket,
                viewAdult: this.$store.state.isCheckedAdult ? 1 : 0,
                filterAdult: this.selectedAdult,
                search: this.searchText === '' ? null : this.searchText
            }
            this.$Axios.post(`${this.host}/mylibrary/buy/list`, param, { headers: { 'Pragma' : 'no-cache', 'Cache-Control': 'no-cache', 'Access-Control-Allow-Origin': '*', 'Content-type': 'application/json', }}).then(res => {
                // console.log('-----구매 리스트-----');
                // console.log(res.data);
                if (res.data.returnCode === 0) {
                    this.mybookList = res.data.list;
                    this.initPagingData = res.data.page;
                }
            })
            .catch((error) => {
                console.log(error);
            });
        },
        moveDetail(item){
            // if(item.enable === 0){
            //     this.initPopupOkMessage = '해당 작품은 운영상의 문제에 위반되어 열람 및 구매 할 수 없습니다.';
            //     this.isShowPopupOkPopup = true;
            //     return;
            // }
            
            if(item.adult === 1){
                if(this.$store.state.loginCheck === false){
                    this.isShowLoginPopup = true;
                }
                else if(!this.$store.state.adult){
                    var src = this.$store.state.siteConfig.niceUrl + '?email=' + this.$store.state.email;
                    window.open(src, 'popupChk', 'width=500, height=550, top=100, left=100, fullscreen=no, menubar=no, status=no, toolbar=no, titlebar=no, location=no, scrollbar=no');
                }
                else{
                    // this.$router.push('/Webtoon/Info/' + item.englishName);
                    this.$router.push('/Webtoon/Info/' + item.webToonIndex);
                }
            }else{
                // this.$router.push('/Webtoon/Info/' + item.englishName);
                this.$router.push('/Webtoon/Info/' + item.webToonIndex);
            }
            
        },
        getChallengeViewList(){  //챌린지 본 작품 리스트
            this.selectedSubTab = 0;
            this.mybookList = [];

            var param = 
            {
                pageNo: this.currentPageNo,
                pageSize: this.currentPageSize,
                sort: this.selectedSort,
                sign: this.$store.state.sign,
                ticket: this.$store.state.ticket,
                viewAdult: this.$store.state.isCheckedAdult ? 1 : 0,
                filterAdult: this.selectedAdult,
                search: this.searchText === '' ? null : this.searchText
            }
            this.$Axios.post(`${this.league_host}/challenge/mylibrary/viwelist`, param, { headers: { 'Pragma' : 'no-cache', 'Cache-Control': 'no-cache', 'Access-Control-Allow-Origin': '*', 'Content-type': 'application/json', }}).then(res => {
                // console.log('-----챌린지 본 작품 리스트-----');
                // console.log(res.data);
                if (res.data.returnCode === 0) {
                    this.mybookList = res.data.list;
                    this.initPagingData = res.data.page;
                }
            })
            .catch((error) => {
                console.log(error);
            });
        },
        getChallengeSubscribeList(){ //챌린지 구독한 작품 리스트
            this.selectedSubTab = 1;
            this.mybookList = [];
   
            var param = 
            {
                pageNo: this.currentPageNo,
                pageSize: this.currentPageSize,
                sort: this.selectedSort,
                sign: this.$store.state.sign,
                ticket: this.$store.state.ticket,
                viewAdult: this.$store.state.isCheckedAdult ? 1 : 0,
                filterAdult: this.selectedAdult,
                search: this.searchText === '' ? null : this.searchText
            }
            this.$Axios.post(`${this.league_host}/challenge/mylibrary/subscribe/list`, param, { headers: { 'Pragma' : 'no-cache', 'Cache-Control': 'no-cache', 'Access-Control-Allow-Origin': '*', 'Content-type': 'application/json', }}).then(res => {
                // console.log('-----챌린지 구독한 리스트-----');
                // console.log(res.data);
                if (res.data.returnCode === 0) {
                    this.mybookList = res.data.list;
                    this.initPagingData = res.data.page;
                }
            })
            .catch((error) => {
                console.log(error);
            });
        },
        moveChallengeDetail(item){
            if(item.ctState === 0){
                this.initPopupOkMessage = '해당 작품은 검수 중이므로 볼 수 없습니다.';
                this.isShowPopupOkPopup = true;
                return;
            } else if(item.ctState === 2){
                this.initPopupOkMessage = '해당 작품은 운영 원칙 위반으로 <br/>열람이 중지된 작품입니다.';
                this.isShowPopupOkPopup = true;
                return;
            }
            
            if(item.ctAdult === 1){
                if(this.$store.state.loginCheck === false){
                    this.isShowLoginPopup = true;
                }
                else if(!this.$store.state.adult){
                    var src = this.$store.state.siteConfig.niceUrl + '?email=' + this.$store.state.email;
                    window.open(src, 'popupChk', 'width=500, height=550, top=100, left=100, fullscreen=no, menubar=no, status=no, toolbar=no, titlebar=no, location=no, scrollbar=no');
                }
                else if(!this.$store.state.isCheckedAdult){
                    this.initPopupOkMessage = '성인 컨텐츠 활성화 후 이용 가능합니다.';
                    // this.PopupOkCallbakPage = '/League';
                    this.isShowPopupOkPopup = true;
                }
                else{
                    this.$router.push('/League/Webtoon/Info/' + item.ctWebtoonIdx);
                }
            }else{
                this.$router.push('/League/Webtoon/Info/' + item.ctWebtoonIdx);
            }
        },
        deleteButton(){
            this.deleteList = [];
            this.isDeleteBtn = !this.isDeleteBtn;
        },
        deleteCancle(){
            this.deleteList = [];
            this.isDeleteBtn = true;
        },
        confirmDelete(){
            if(this.deleteList.length > 0){
                this.isShowPopupConfirmPopup = true;
                this.initPopupConfrimMessage = '선택한 작품을 삭제하시겠습니까?'
            }
        },
        reqDelete(){    //리스트 삭제
            if(this.selectedSubTab === 0){    //감상한 작품
                if(this.selectedTab === 0) this.reqDeleteViewList();
                else if(this.selectedTab === 1) this.reqDeleteChallengeViewList();
            } else if(this.selectedSubTab === 1){ //즐겨찾기
                if(this.selectedTab === 0) this.reqDeleteSubscribeList();
                else if(this.selectedTab === 1) this.reqDeleteChallengeSubscribeList();
            } else if(this.selectedSubTab === 2){ //소장 작품
                if(this.selectedTab === 0) this.reqDeleteBuyList();
            }
            this.close('PopupConfirm');
        },
        reqDeleteViewList(){
            var param = 
            {
                delWtList: this.deleteList,
                sign: this.$store.state.sign,
                ticket: this.$store.state.ticket,
                viewAdult: this.$store.state.isCheckedAdult ? 1: 0
            }
            this.$Axios.post(`${this.host}/mylibrary/viwelist/delete`, param, { headers: { 'Pragma' : 'no-cache', 'Cache-Control': 'no-cache', 'Access-Control-Allow-Origin': '*', 'Content-type': 'application/json', }}).then(async(res) => {
                if (res.data.returnCode === 0) {
                    this.isDeleteBtn = true;
                    this.resetListData();
                    this.getList();
                }
            })
            .catch((error) => {
                console.log(error);
            });
        },
        reqDeleteSubscribeList(){
            var param = 
            {
                webtoonList: this.deleteList,
                sign: this.$store.state.sign,
                ticket: this.$store.state.ticket,
                viewAdult: this.$store.state.isCheckedAdult ? 1: 0
            }
            this.$Axios.post(`${this.host}/mylibrary/unsubscribe`, param, { headers: { 'Pragma' : 'no-cache', 'Cache-Control': 'no-cache', 'Access-Control-Allow-Origin': '*', 'Content-type': 'application/json', }}).then(async(res) => {
                if (res.data.returnCode === 0) {
                    this.isDeleteBtn = true;
                    this.resetListData();
                    this.getList();
                }
            })
            .catch((error) => {
                console.log(error);
            });
        },
        reqDeleteBuyList(){
            var param = 
            {
                webtoonList: this.deleteList,
                sign: this.$store.state.sign,
                ticket: this.$store.state.ticket,
                viewAdult: this.$store.state.isCheckedAdult ? 1: 0
            }
            this.$Axios.post(`${this.host}/mylibrary/buy/delete`, param, { headers: { 'Pragma' : 'no-cache', 'Cache-Control': 'no-cache', 'Access-Control-Allow-Origin': '*', 'Content-type': 'application/json', }}).then(async(res) => {
                if (res.data.returnCode === 0) {
                    this.isDeleteBtn = true;
                    this.resetListData();
                    this.getList();
                }
            })
            .catch((error) => {
                console.log(error);
            });
        },
        reqDeleteChallengeViewList(){
            var param = 
            {
                delWtList: this.deleteList,
                sign: this.$store.state.sign,
                ticket: this.$store.state.ticket,
                viewAdult: this.$store.state.isCheckedAdult ? 1: 0
            }
            this.$Axios.post(`${this.league_host}/challenge/mylibrary/viwelist/delete`, param, { headers: { 'Pragma' : 'no-cache', 'Cache-Control': 'no-cache', 'Access-Control-Allow-Origin': '*', 'Content-type': 'application/json', }}).then(async(res) => {
                if (res.data.returnCode === 0) {
                    this.isDeleteBtn = true;
                    this.resetListData();
                    this.getList();
                }
            })
            .catch((error) => {
                console.log(error);
            });
        },
        reqDeleteChallengeSubscribeList(){
            var param = 
            {
                webtoonList: this.deleteList,
                sign: this.$store.state.sign,
                ticket: this.$store.state.ticket,
                viewAdult: this.$store.state.isCheckedAdult ? 1: 0
            }
            this.$Axios.post(`${this.league_host}/challenge/mylibrary/unsubscribe`, param, { headers: { 'Pragma' : 'no-cache', 'Cache-Control': 'no-cache', 'Access-Control-Allow-Origin': '*', 'Content-type': 'application/json', }}).then(async(res) => {
                if (res.data.returnCode === 0) {
                    this.isDeleteBtn = true;
                    this.resetListData();
                    this.getList();
                }
            })
            .catch((error) => {
                console.log(error);
            });
        },
        changeDeleteItem(event){
            var value = Number(event.target.value);
            var checked = event.target.checked;

            if(checked){
                var isExistsValue = this.deleteList.find((item) => item === value);
                if(isExistsValue === undefined){
                    this.deleteList.push(value);
                }
            } else {
                this.deleteList.forEach((item,index) =>{
                    if(item === value){
                        this.deleteList.splice(index, 1);
                    }
                })
            }
        },
        movePaging(pageNo){
            this.currentPageNo = pageNo;
            this.getList()
        },
        async moveNextEpisode(webtoonInfo){
            var webtoonIdx = webtoonInfo.twebtoonForApi.webToonIndex;
            var nextEpisodeIdx = webtoonInfo.tviewMylibrary.vmNextEpisodeIdx;

            var episodeInfo = null;
            episodeInfo = await this.getEpisodeState(webtoonIdx, nextEpisodeIdx);

            if (episodeInfo.returnCode === 0) {
                if(episodeInfo.epData.buyPrice === 0 && episodeInfo.epData.rentalPrice === 0){
                    this.freeEpisodeBuy(episodeInfo.epData);    //무료보기 일 경우
                } else {
                    this.$router.push('/Webtoon/Viewer/' + episodeInfo.epData.webtoonIdx + '/' + episodeInfo.epData.episodeIdx);    //이미 구매한 경우
                }
            }
            else if(episodeInfo.returnCode === 252){   //성인인증 필요
                this.initPopupOkMessage = '성인 인증 후 이용 가능합니다.';
                this.PopupOkCallbakPage = '/';
                this.isShowPopupOkPopup = true;
            }
            else if(episodeInfo.returnCode === 300){
                this.initPopupOkMessage = '해당 작품은 운영상의 문제에 위반되어 열람 및 구매 할 수 없습니다.';
                this.PopupOkCallbakPage = '';
                this.isShowPopupOkPopup = true;
            }
            else if(episodeInfo.returnCode === 353){
                this.initPopupOkMessage = '회당 회차는 운영상의 문제에 위반되어 열람 및 구매 할 수 없습니다.';
                this.PopupOkCallbakPage = '';
                this.isShowPopupOkPopup = true;
            }
            else if(episodeInfo.returnCode === 503){   //로그인 필요
                this.isShowLoginPopup = true;
            }
            else if(episodeInfo.returnCode === 504){   //구매 필요
                this.initWebtoonInfo = webtoonInfo.twebtoonForApi;
                this.initEpisodeInfo = episodeInfo.epData;
                this.isShowBuyPopup = true;
            }
        },
        async getEpisodeState(webtoonIdx, episodeIdx){
            var param = 
            {
                ticket: this.$store.state.ticket,
                sign: this.$store.state.sign,
                viewAdult: this.$store.state.isCheckedAdult ? 1 : 0,

                webtoonIdx: webtoonIdx,
                episodeIdx: episodeIdx,
                lang: 'KR'
            }
            return await this.$Axios.post(`${this.host}/episode/state`, param, { headers: { 'Pragma' : 'no-cache', 'Cache-Control': 'no-cache', 'Access-Control-Allow-Origin': '*', 'Content-type': 'application/json', }}).then(async(res) => {
                // console.log('----episode state----');
                // console.log(res.data);
                return res.data;
            })
            .catch((error) => {
                console.log(error);
            });
        },
        freeEpisodeBuy(episodeInfo){

            var param = 
            {
                epidx: episodeInfo.episodeIdx,
                sign: this.$store.state.sign,
                ticket: this.$store.state.ticket,
                wtidx: episodeInfo.webtoonIdx,
                viewAdult: this.$store.state.isCheckedAdult ? 1: 0
            }
            // console.log(param);
            this.$Axios.post(`${this.host}/member/buy`, param, { headers: { 'Pragma' : 'no-cache', 'Cache-Control': 'no-cache', 'Access-Control-Allow-Origin': '*', 'Content-type': 'application/json', }}).then(async(res) => {
                // console.log(res.data);
                if (res.data.returnCode === 0 || res.data.returnCode === 100) {
                    this.$router.push('/Webtoon/Viewer/'+ episodeInfo.webtoonIdx + '/' + episodeInfo.episodeIdx);
                } else if(res.data.returnCode === 300){
                    this.initPopupOkMessage = '해당 작품은 운영상의 문제에 위반되어 열람 및 구매 할 수 없습니다.';
                    this.PopupOkCallbakPage = '';
                    this.isShowPopupOkPopup = true;
                } else if(res.data.returnCode === 353){
                    this.initPopupOkMessage = '회당 회차는 운영상의 문제에 위반되어 열람 및 구매 할 수 없습니다.';
                    this.PopupOkCallbakPage = '';
                    this.isShowPopupOkPopup = true;
                }
            })
            .catch((error) => {
                console.log(error);
            });
        },
        close(popupType){
            switch(popupType){
                case 'Login':
                    this.isShowLoginPopup = false;
                    break;
                case 'Buy':
                    this.isShowBuyPopup = false;
                    break;
                case 'PopupOk':
                    this.isShowPopupOkPopup = false;
                    if(this.PopupOkCallbakPage !== ''){
                        this.$router.push(this.PopupOkCallbakPage);
                    }
                    this.PopupOkCallbakPage = '';
                    break;
                case 'PopupConfirm':
                    this.isShowPopupConfirmPopup = false;
                    break;
            }
        },
        getFilterAdult(){
            var option = this.radioAdultOptions.find((option) => option.value === this.selectedAdult);
            return option.text;
        },
        getFilterSort(){
            var option = this.radioSortOptions.find((option) => option.value === this.selectedSort);
            return option.text;
        }
    }
}
</script>