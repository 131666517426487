<template>
    <div id="attatchchange" v-if="initIsShowPopup">
        <div class="attatchchange_box">
            <p v-html="initPopupConfrimMessage"></p>
            <div class="attatchchange_button">
            <a href="#" class="cancel" @click="cancel">취소</a>
            <a href="#" class="on" @click="confirmOk">확인</a>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'PopupOk',
    data(){
        return {
        }
    },
    props: {
        initIsShowPopup: {
            type: Boolean,
            default: false
        },
        initPopupConfrimMessage:{
            type: String,
            default: ''
        },
    },
    created() {
    },
    mounted(){
    },
    beforeDestroy() {
	},
    methods: {
        cancel(){
            this.$emit('confirmCancel');
        },
        confirmOk(){
            this.$emit('confirmOk');
        }
    },
}
</script>