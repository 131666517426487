<template>
    <div id="popup_ok" class="viwer-width-popup" v-if="initIsShowPopup" @click="closePopup">
        <div class="popup_ok_box">
            <p class="title" v-html="initPopupOkMessage1"></p>
            <p v-html="initPopupOkMessage2"></p>
            <img src="@/assets/images/width-page-move2.png" class="left" v-if="initWebtoonInfo !== null && initWebtoonInfo.viewDirection == 1"/>
            <img src="@/assets/images/width-page-move.png" class="right" v-if="initWebtoonInfo !== null && initWebtoonInfo.viewDirection == 0"/>
        </div>
        <div class="popup-swipe-box">
            <p v-html="initPopupOkMessage3"></p>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ViewerWidthPopup',
    data(){
        return {
        }
    },
    props: {
        initWebtoonInfo: {
            type: Object,
            default: null
        },
        initIsShowPopup: {
            type: Boolean,
            default: false
        },
        initPopupOkMessage1:{
            type: String,
            default: ''
        },
        initPopupOkMessage2:{
            type: String,
            default: ''
        },
        initPopupOkMessage3:{
            type: String,
            default: ''
        },
    },
    created() {
    },
    mounted(){
    },
    beforeDestroy() {
	},
    methods: {
        closePopup(){
            this.$emit('close');
        },
    },
}
</script>