<template>
    <div class="repass wrap">
        <Header :initHeaderType="initHeaderType" />
        <div class="repass_box" v-if="isShowPage">
            <h1>비밀번호 재설정</h1>
            <p>새로운 비밀번호를 다시 설정해주세요.</p>
            <div class="email">
                <p>이메일(아이디)</p>
                <input type="text" v-model="email" disabled='true'>
            </div>
            <div class="pass">
                <p>비밀번호 변경</p>
                <input type="password" v-model="password" autocomplete="none" placeholder="비밀번호(8~32자리)">
                <input type="password" v-model="password_confirm" autocomplete="none" placeholder="비밀번호 확인">
            </div>
            <div class="confirm" @click="resetPassword">확인</div>
        </div>
        <PopupOK :initIsShowPopup="isShowPopupOkPopup" :initPopupOkMessage="initPopupOkMessage" @close="close('PopupOk')" />
        <Footer />
    </div>
</template>

<script>
import Header from '@/components/common/Header.vue'
import Footer from '@/components/common/Footer.vue'
import PopupOK from '@/components/common/PopupOK.vue'

export default {
    name: 'PasswordReset',
    pageCategory: 'Member',
    components: {
        Header,
        Footer,
        PopupOK,
    },
    data() {
        return {
            email: '',
            code: '',
            password: '',
            password_confirm: '',

            isShowPage: false,

            isShowPopupOkPopup: false,
            initPopupOkMessage: '',
            PopupOkCallbakPage: '',    // 확인 팝업에서 확인 버튼 눌렀을때 이동할 페이지

            initHeaderType: 'type1',
        }
    },
    created(){
        // await this.mixinSiteConfigCheck();

        if(this.$route.params.email){
            this.email = this.$route.params.email;
        }else{
            this.$router.push('/');
        }

        if(this.$route.params.code){
            this.code = this.$route.params.code;
        }else{
            this.$router.push('/');
        }

        this.codeCheck();
    },
    methods: {
        codeCheck(){
            var param = 
            {
                email: this.email,
                cert: this.code,
            }
            this.$Axios.post(`${this.host}/password/checkticket`, param, { headers: { 'Pragma' : 'no-cache', 'Cache-Control': 'no-cache', 'Access-Control-Allow-Origin': '*', 'Content-type': 'application/json', }}).then(res => {
                if (res.data.returnCode === 0) {
                    this.isShowPage = true;
                }else{
                    this.initPopupOkMessage = '시간이 만료되었거나 잘못된 접근입니다.';
                    this.PopupOkCallbakPage = '/';
                    this.isShowPopupOkPopup = true;
                }
            })
            .catch((error) => {
                console.log(error);
            });
        },
        resetPassword(){ 

            if(this.password === '' || this.password_confirm === ''){
                this.initPopupOkMessage = '비밀번호를 입력해 주세요.';
                this.isShowPopupOkPopup = true;
                return;
            }
            else if(this.password !== this.password_confirm){
                this.initPopupOkMessage = '비밀번호가 동일하지 않습니다.';
                this.isShowPopupOkPopup = true;
                return;
            }

            var param = 
            {
                email: this.email,
                cert: this.code,
                newPassword: this.password
            }
            this.$Axios.post(`${this.host}/password/result`, param, { headers: { 'Pragma' : 'no-cache', 'Cache-Control': 'no-cache', 'Access-Control-Allow-Origin': '*', 'Content-type': 'application/json', }}).then(res => {
                // console.log('----인기 작품----');
                // console.log(res.data);
                if (res.data.returnCode === 0) {
                    this.initPopupOkMessage = '비밀번호 재설정이 완료 되었습니다.';
                    this.PopupOkCallbakPage = '/';
                    this.isShowPopupOkPopup = true;
                }
                else{
                    alert(res.data.description);
                }
            })
            .catch((error) => {
                console.log(error);
            });
        },
        close(popupType){
            switch(popupType){
                case 'PopupOk':
                    this.isShowPopupOkPopup = false;
                    if(this.PopupOkCallbakPage !== ''){
                        this.$router.push(this.PopupOkCallbakPage);
                    }
                    this.PopupOkCallbakPage = '';
                    break;
            }
        },
    }
}
</script>