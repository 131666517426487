<template>
    <div class="enrollment wrap">
        <Header :initHeaderType="initHeaderType" />
        <Login />
        <b-container>
            <div class="enrollment_box">
                <h1 class="purpose">작가등록</h1>
                <div class="writer_name">
                    <p class="title">필명등록</p>
                    <div class="name_input">
                        <input type="text" v-model="writerName" maxlength="20" @input="checkWriterName">
                        <p>{{writerName.length}} / 20</p>
                    </div>
                    <p class="ps">특수문자 불가, 제목은 띄어쓰기 포함 최대 20자 까지 <span>가능합니다. 이후 변경이 불가하오니 한번 더 확인해 주세요.</span></p>
                </div>
                <!-- <div class="mail_cert">
                    <p class="title">이메일 인증</p>
                    <div class="mail_input">
                        <input type="text"> 
                        <p class="button">인증하기</p>
                    </div>
                    <div class="number_input">
                        <input type="text"> 
                        <p class="button">인증확인</p>
                        <div class="more_info">
                            <div class="time">
                                <p>남은시간 <span>09:58</span></p>
                            </div>
                            <div class="cert_result">
                                <p>인증번호가 일치합니다.</p>
                                <p>인증번호가 일치하지 않습니다.</p>
                            </div>
                        </div>
                    </div>
                </div> -->
                <!-- <div class="identity">
                    <p class="title">본인 인증</p>
                    <div class="identity_box">
                        <p>본인인증을 완료해주세요</p>
                        <p>인증하기</p>
                    </div>
                </div> -->
                <div class="b_button">
                    <router-link tag="a" to="/League">취소</router-link>
                    <a @click="writerReq">작가등록</a>
                </div>
            </div>
        </b-container>
        <PopupOK :initIsShowPopup="isShowPopupOkPopup" :initPopupOkMessage="initPopupOkMessage" @close="close('PopupOk')" />
        <Footer />
    </div>
</template>

<script>
import Header from '@/components/common/Header.vue'
import Footer from '@/components/common/Footer.vue'
import Login from '@/components/common/Login.vue'
import PopupOK from '@/components/common/PopupOK.vue'

export default {
    name: 'LeagueWriterSignup',
    pageCategory: 'League',
    components: {
        Header,
        Footer,
        Login,
        PopupOK,
    },
    data(){
        return{
            writerName: '',

            isShowPopupOkPopup: false,
            initPopupOkMessage: '',
            PopupOkCallbakPage: '',    // 확인 팝업에서 확인 버튼 눌렀을때 이동할 페이지

            initHeaderType: 'type1',
        }
    },
    async created(){
        var isPc = this.isPc();
        if(!isPc){
            this.initPopupOkMessage = '모바일 환경에서는 작가 등록이 불가능합니다.<br/>작가 활동은 PC 환경에서 이용해주세요.'
            this.PopupOkCallbakPage = '/League';
            this.isShowPopupOkPopup = true;
            return;
        }
        await this.mixinLoginCheck();
        this.checkAccessAuthority();
    },
    methods:{
        async checkAccessAuthority(){
            if(this.$store.state.loginCheck === false){ //로그인 확인
                this.initPopupOkMessage = '로그인 후 시도해 주세요.'
                this.PopupOkCallbakPage = '/League';
                this.isShowPopupOkPopup = true;
            }
            else if(this.$store.state.adult === false){ //성인 인증 확인
                this.initPopupOkMessage = '성인 인증 후 시도해 주세요.'
                this.PopupOkCallbakPage = '/League';
                this.isShowPopupOkPopup = true;
            }
            else{
                var checkWriter = await this.checkWriter(); //작가 등록 여부 확인
                if(checkWriter === null){   //체크 에러
                    this.initPopupOkMessage = '작가 등록 여부 체크 에러'
                    this.PopupOkCallbakPage = '/League';
                    this.isShowPopupOkPopup = true;
                }
                else if(checkWriter){   //작가 등록된 상태
                    this.$router.push('/League/Writer/Info')
                }
            }
        },
        async checkWriter(){
            var param = 
            {
                sign: this.$store.state.sign,
                ticket: this.$store.state.ticket,
                viewAdult: this.$store.state.isCheckedAdult ? 1: 0
            }
            return await this.$Axios.post(`${this.league_host}/challenge/writer/check`, param, { headers: { 'Pragma' : 'no-cache', 'Cache-Control': 'no-cache', 'Access-Control-Allow-Origin': '*', 'Content-type': 'application/json', }}).then(async(res) => {
                // console.log(res.data);
                if (res.data.returnCode === 0) {    //작가 등록됨
                    return true;
                }
                else if(res.data.returnCode === 41){    //작가 등록 안됨
                    return false;
                }
                else{   //기타 에러
                    return null;
                }
            })
            .catch((error) => {
                console.log(error);
                return null;
            });
        },
        writerReq(){

            if(this.writerName === ''){
                this.initPopupOkMessage = '필명을 입력해 주세요.'
                this.isShowPopupOkPopup = true;
            }

            var param = 
            {
                sign: this.$store.state.sign,
                ticket: this.$store.state.ticket,
                viewAdult: this.$store.state.isCheckedAdult ? 1: 0,
                penName: this.writerName
            }
            // console.log(param);
            this.$Axios.post(`${this.league_host}/challenge/writer/req`, param, { headers: { 'Pragma' : 'no-cache', 'Cache-Control': 'no-cache', 'Access-Control-Allow-Origin': '*', 'Content-type': 'application/json', }}).then(async(res) => {
                // console.log(res.data);
                if (res.data.returnCode === 0) {
                    this.initPopupOkMessage = '작가 등록이 완료되었습니다.'
                    this.PopupOkCallbakPage = '/League/Writer/Info';
                    this.isShowPopupOkPopup = true;
                }
                else{
                    this.initPopupOkMessage = res.data.description;
                    this.PopupOkCallbakPage = '/League';
                    this.isShowPopupOkPopup = true;
                }
            })
            .catch((error) => {
                console.log(error);
                return null;
            });
        },
        checkWriterName(){
            var filter = /[^a-z|A-Z|0-9|ㄱ-ㅎ|가-힣]/g;

            this.writerName = this.writerName.replace(filter, "");
        },
        close(popupType){
            switch(popupType){
                case 'PopupOk':
                    this.isShowPopupOkPopup = false;
                    if(this.PopupOkCallbakPage !== ''){
                        this.$router.push(this.PopupOkCallbakPage);
                    }
                    this.PopupOkCallbakPage = '';
                    break;
            }
        },
    }
}
</script>