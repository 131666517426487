<template>
    <div class="joinus wrap" :class="[isPassAgree ? 'joinus2_bg' : '']">
        <Header :initHeaderType="initHeaderType" v-if="!isWebview" />
        <div id="joinus" v-if="!isPassAgree">
            <div class="agree_box">
                <h1>artube</h1>
                <div class="join_box">
                    
                    <h2>Artube계정</h2>
                    
                    <p class="plz">서비스 이용 약관에 동의해 주세요</p>

                    <input class="styled-checkbox no" id="cbxAllAgree" type="checkbox" v-model="checkedAllAgree" @change="allAgree()">
                    <label for="cbxAllAgree">모두 동의합니다</label>
                    <p class="text">전체동의는 필수 및 선택정보에 대한 동의도 포함되어 있으며, 개별적으로도 동의를 선택하실 수 있습니다. 선택항목에 대한 동의를 거부하시는 경우에도 서비스는 이용이 가능합니다.</p>
                    
                    <div class="check_box">
                        <input class="styled-checkbox" id="agree1" type="checkbox" v-model="checkedAgree1" @change="agree(1)">
                        <label for="agree1">만 14세 이상입니다</label>
                    </div>

                    <div class="check_box">
                        <input class="styled-checkbox" id="agree2" type="checkbox" v-model="checkedAgree2" @change="agree(2)">
                        <label for="agree2">[필수] 서비스 이용약관</label>
                        <i @click="Terms" class="icon-right-arrow arrow-more"></i>
                    </div>

                    <div class="check_box">
                        <input class="styled-checkbox" id="agree3" type="checkbox" v-model="checkedAgree3" @change="agree(3)">
                        <label for="agree3">[필수] 개인정보 수집 및 이용 동의</label>
                        <i @click="Policy" class="icon-right-arrow arrow-more"></i>
                    </div>
                    <div class="check_box">
                        <input class="styled-checkbox" id="agree4" type="checkbox" v-model="checkedAgree4" @change="agree(4)">
                        <label for="agree4">[선택] 프로모션 이메일 알림</label>
                    </div>
                    <div class="agree_button" @click="goNext">동의</div>
                </div>
            </div>
        </div>
        <div id="joinus2" v-else>
            <div class="agree_box">
                <h1>회원가입</h1>
                <p class="plz">Artube 계정 정보를 입력해주세요</p>
                <div class="email">
                    <p>이메일(아이디)</p>
                    <input type="text" autocomplete="off" placeholder="이메일 주소를 입력해주세요" class="id" v-model="email" @input="validateEmail" :disabled="isSendEmail||isPassValidEmail">
                    <a class="verify_email" :class="[isValidEmail && !isReqEmail && !isSendEmail && !isPassValidEmail ? 'on' : '' ]" @click="verifyEmail">인증하기</a>
                    <div class="empty_box" v-if="isSendEmail">
                        <input type="text" autocomplete="off" placeholder="인증 번호" class="email_cert_no" v-model="emailCertNo" />
                        <a class="confirm_cert_no" :class="[isValidEmail ? 'on' : '' ]" @click="confirmCertNo">인증번호확인</a>
                        <p class="remain_time">남은 시간 - <span :class="[isWarningRemainTime ? 'error' : '']">{{remainTime}}</span></p>
                    </div>
                    <div class="empty_box" >
                        <p class="sending" v-if="isReqEmail">이메일 발송 중입니다. 잠시만 기다려 주세요...</p>
                        <p class="error"  v-if="isShowErrorEmail" v-html="errorMsgEmail" :class="{'error-sns': error2}"></p>
                        <p class="success" v-if="isPassValidEmail">이메일 인증 완료</p>
                    </div>
                </div>
                <div class="pass_box">
                    <p>비밀번호</p>
                    <input type="password" autocomplete="new-password" placeholder="비밀번호(8~32자리)" class="pass" v-model="password" @input="validatePassword">
                    <input type="password" autocomplete="new-password" placeholder="비밀번호 재입력" class="repass" v-model="password_confirm" @input="validatePasswordConfirm">
                    <div class="empty_box">
                        <p :class="[isShowErrorPassword === true ? 'error' : '']"  v-if="isShowErrorPassword">{{errorMsgPassword}}</p>
                    </div>
                </div>
                <div class="name_box">
                    <p>닉네임</p>
                    <input type="text" placeholder="닉네임을 입력해주세요" class="name" v-model="nickname" @input="validateNickname">
                    <div class="empty_box">
                        <p :class="[isShowErrorNickname === true ? 'error' : '']"  v-if="isShowErrorNickname">{{errorMsgNickname}}</p>
                    </div>
                </div>
                <div class="button_box">
                    <router-link tag="p" to="/" class="no">취소</router-link>
                    <a class="signup" @click="signup">회원 가입</a>
                    <!-- <p class="nextlevel">다음 단계로</p> -->
                </div>
                <div class="empty_box">
                    <p :class="[isShowReturnError === true ? 'error' : '']"  v-if="isShowReturnError">{{errorMsgReturn}}</p>
                </div>
            </div>
        </div>
        <Policy :initIsShowPopup="isClickPolicyPopup" @close="close('PopupPolicy')"/>
        <Terms :initIsShowPopup="isClickTermsPopup" @close="close('PopupTerms')"/>
        <PopupOK :initIsShowPopup="isShowPopupOkPopup" :initPopupOkMessage="initPopupOkMessage" @close="close('PopupOk')" />
        <Footer v-if="!isWebview" />
    </div>
</template>

<script>
import Header from '@/components/common/Header.vue'
import Footer from '@/components/common/Footer.vue'
import PopupOK from '@/components/common/PopupOK.vue'
import Policy from '@/components/support/Policy.vue'
import Terms from '@/components/support/Terms.vue'

export default {
    name: 'Signup',
    pageCategory: 'Member',
    pageTitle: '회원가입',
    components: {
        Header,
        Footer,
        PopupOK,
        Policy,
        Terms,
    },
    data() {
        return {
            isWebview: false,

            //서비스 이용 약관
            checkedAllAgree: false,
            checkedAgree1: false,
            checkedAgree2: false,
            checkedAgree3: false,
            checkedAgree4: false,

            PDFUrl1: '',
            PDFUrl2: '',

            isPassAgree: false,

            //회원정보 입력
            email: '',
            password: '',
            password_confirm: '',
            nickname: '',
            ticket: '',
            sign: '',
            social: 0,

            isShowErrorEmail: false,
            isShowErrorPassword: false,
            isShowErrorNickname: false,
            isShowReturnError: false,

            errorMsgEmail: '',
            errorMsgPassword: '',
            errorMsgNickname: '',
            errorMsgReturn: '',

            isValidEmail: false,
            isPassValidEmail: false,
            isReqEmail: false,
            isSendEmail : false,

            emailCertNo: '',

            distance: 600 * 1000,
            second: 1000,
            minute: 1000 * 60,
            hour: 1000 * 60 * 60,
            remainTime: '',
            isWarningRemainTime: false,
            timer: null,

            isShowPopupOkPopup: false,
            initPopupOkMessage: '',
            PopupOkCallbakPage: '',    // 확인 팝업에서 확인 버튼 눌렀을때 이동할 페이지

            initHeaderType: 'type2',

            error2:false,
            typeKakao:false,
            typeNaver:false,
            typeFb:false,
            typeGoogle:false,

            isClickPolicyPopup: false,
            isClickTermsPopup: false,

            timerAppApi: null,
        }
    },
    created(){
        this.isWebview = this.isArtubeWebview();  
    },
    mounted(){
    },
    beforeDestroy(){
        clearInterval(this.timer);
        clearInterval(this.timerAppApi);
    },
    methods: {
        Policy(){
            this.isClickPolicyPopup= true;
        },
        Terms(){
            this.isClickTermsPopup= true;
        },
        allAgree(){
            this.checkedAgree1 = this.checkedAllAgree;
            this.checkedAgree2 = this.checkedAllAgree;
            this.checkedAgree3 = this.checkedAllAgree;
            this.checkedAgree4 = this.checkedAllAgree;
        },
        agree(no){
            if(no === 1){
                if(!this.checkedAgree1){
                    this.checkedAgree1 = false;
                }else{
                    this.checkedAgree1 = true;
                }
            }
            else if(no === 2){
                if(!this.checkedAgree2){
                    this.checkedAgree2 = false;
                }else{
                    this.checkedAgree2 = true;
                }
            }
            else if(no === 3){
                if(!this.checkedAgree3){
                    this.checkedAgree3 = false;
                }else{
                    this.checkedAgree3 = true;
                }
            }
            else if(no === 4){
                if(!this.checkedAgree4){
                    this.checkedAgree4 = false;
                }else{
                    this.checkedAgree4 = true;
                }
            }
            
            if(this.checkedAgree1 && this.checkedAgree2 && this.checkedAgree3 && this.checkedAgree4){
                this.checkedAllAgree = true;
            } else{
                this.checkedAllAgree = false;
            }
        },
        goNext(){

            var isValidAgree = false;
            if(!this.checkedAgree1){ isValidAgree = true }
            else if(!this.checkedAgree2) { isValidAgree = true }
            else if(!this.checkedAgree3) { isValidAgree = true }
            // else if(!this.checkedAgree4) return;

            if(isValidAgree){
                this.initPopupOkMessage = '서비스 이용 약관에 동의해 주세요.';
                this.isShowPopupOkPopup = true;
                return;
            }
            this.isPassAgree = true;
            // this.$router.push('/Signup2')
        },
        validateEmail() {
            this.isShowErrorEmail = false;
            this.isValidEmail = false;
            this.error2 = false;
            
            let filter = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;

            if(this.email === ''){
                this.isShowErrorEmail = false;
                this.isValidEmail = false;
            }
            else if (!filter.test(this.email)) {
                this.isShowErrorEmail = true;
                this.isValidEmail = false;
                this.errorMsgEmail = '잘못된 이메일 형식 입니다.';
            }
            else {
                this.isShowErrorEmail = false;
                this.isValidEmail = true;
            }
        },
        validatePassword(){
            this.isShowErrorPassword = false;
            this.password_confirm = '';
        },
        validatePasswordConfirm(){
            this.isShowErrorPassword = false;

            if(this.password === this.password_confirm){
                this.isShowErrorPassword = false;
            }
            else {
                this.errorMsgPassword = '비밀번호가 일치하지 않습니다.';
                this.isShowErrorPassword = true;
            }
        },
        validateNickname(){
            this.isShowErrorNickname = false;
            // let filter = /^[a-z0-9]{4,10}$/g;
            // if(!filter.test(this.id)){
            //     this.isValidId = false;
            // }
            // else {
            //     this.isValidId = true;
            // }
        },
        verifyEmail(){

            var that = this;
            this.isShowErrorEmail = false;
            this.error2 = false;
            
            if(this.isValidEmail === true && this.isPassValidEmail === false){

                if(this.isReqEmail === true){
                    // console.log('이메일 전송 중');
                    return;
                }
                else if(this.isSendEmail === true){
                    // console.log('인증 번호 입력 대기 중');
                    return;
                }
                else{
                    this.isReqEmail = true;

                    var param = 
                    {
                        email: this.email,
                        lagn: 'KR'
                    }
                    this.$Axios.post(`${this.host}/email/register/request`, param, { headers: { 'Pragma' : 'no-cache', 'Cache-Control': 'no-cache', 'Access-Control-Allow-Origin': '*', 'Content-type': 'application/json', }}).then(async(res) => {
                        // console.log(res.data);
                        this.isReqEmail = false;
                        if (res.data.returnCode === 0) {
                            this.initPopupOkMessage = '이메일로 인증번호가 발송 되었습니다.<br/>발송된 인증번호는 10분간만 유효합니다.';
                            this.isShowPopupOkPopup = true;

                            this.isSendEmail = true;
                            this.isWarningRemainTime = false;
                            this.distance = 600 * 1000;
                            this.timer = setInterval(()=>{that.CountDownTimer()}, 1000);
                        }
                        else if(res.data.returnCode === 42){
                            this.errorMsgEmail = "이미 가입된 이메일입니다.";

                            switch(res.data.social){    //0:artube, 1:google, 2:apple, 3:facebook, 4:naver, 5:kakao
                                case 0:
                                    this.errorMsgEmail = "이미 가입된 이메일입니다.";
                                    break;
                                case 1:
                                    this.errorMsgEmail = "&ast;이미 구글 계정으로 연결된 이메일입니다.<br /><a href='" + this.$store.state.siteConfig.csGoogle + "'>구글로 로그인</a>해주세요.";
                                    this.error2 = true;
                                    break;
                                case 3:
                                    this.errorMsgEmail = "&ast;이미 페이스북 계정으로 연결된 이메일입니다.<br /><a href='" + this.$store.state.siteConfig.csFacebook + "'>페이스북으로 a</span>해주세요.";
                                    this.error2 = true;
                                    break;
                                case 4:
                                    this.errorMsgEmail = "&ast;이미 네이버 계정으로 연결된 이메일입니다.<br /><a href='" + this.$store.state.siteConfig.csNaver + "'>네이버로 로그인</a>해주세요.";
                                    this.error2 = true;
                                    break;
                                case 5:
                                    this.errorMsgEmail = "&ast;이미 카카오톡 계정으로 연결된 이메일입니다.<br /><a href='" + this.$store.state.siteConfig.csKakao + "'>카카오톡으로 로그인</a>해주세요.";
                                    this.error2 = true;
                                    break;
                            }
                            this.isShowErrorEmail = true;
                        }
                        else {
                            this.errorMsgEmail = "이메일 발송이 실패했습니다.";
                            this.isShowErrorEmail = true;
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                    });
                }
            }          
        },
        confirmCertNo(){
            this.isShowErrorEmail = false;

            var param = 
            {
                email: this.email,
                cert: this.emailCertNo,
            }
            this.$Axios.post(`${this.host}/email/register/result`, param, { headers: { 'Pragma' : 'no-cache', 'Cache-Control': 'no-cache', 'Access-Control-Allow-Origin': '*', 'Content-type': 'application/json', }}).then(async(res) => {
                // console.log(res.data);
                if (res.data.returnCode === 0) {
                    this.isPassValidEmail = true;
                    this.isSendEmail = false;
                    if(this.timer){
                        clearInterval(this.timer);
                    }
                }else if(res.data.returnCode === 201){
                    this.errorMsgEmail = "잘못된 인증 번호 입니다.";
                    this.isShowErrorEmail = true;
                    // this.errorMsgEmail = "잘못된 인증 번호 입니다.";
                    // this.isShowErrorEmail = true;
                }
                else {
                    this.errorMsgEmail = "인증 번호 확인이 실패했습니다.";
                    this.isShowErrorEmail = true;
                    // this.errorMsgEmail = res.data.description;
                    // this.isShowErrorEmail = true;
                }
            })
            .catch((error) => {
                console.log(error);
            });

        },
        async signup(){

            this.isShowErrorEmail = false;
            this.isShowErrorPassword = false;
            this.isShowErrorNickname = false;

            let filter = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;

            if(this.email === ''){
                this.errorMsgEmail = "이메일을 입력해 주세요.";
                this.isShowErrorEmail = true;
                return;
            }
            else if(!this.isPassValidEmail){
                this.errorMsgEmail = "이메일을 인증해 주세요.";
                this.isShowErrorEmail = true;
                return;
            }
            else if(!filter.test(this.email)){
                this.errorMsgEmail = "잘못된 이메일 형식 입니다.";
                this.isShowErrorEmail = true;
                return;
            }

            if(this.password === '' || this.password_confirm === ''){
                this.errorMsgPassword = '비밀번호를 입력해 주세요.';
                this.isShowErrorPassword = true;
                return;
            }
            else if(this.password !== this.password_confirm){
                this.errorMsgPassword = '비밀번호가 일치하지 않습니다.';
                this.isShowErrorPassword = true;
                return;
            }

            if(this.nickname === ''){
                this.errorMsgNickname = '닉네임을 입력해 주세요.';
                this.isShowErrorNickname = true;
                return;
            }

            var param = 
            {
                email: this.email,
                emailCert: this.emailCertNo,
                nickname: this.nickname,
                password: this.password
            }
            await this.$Axios.post(`${this.host}/member/register`, param, { headers: { 'Pragma' : 'no-cache', 'Cache-Control': 'no-cache', 'Access-Control-Allow-Origin': '*', 'Content-type': 'application/json', }}).then(async(res) => {
                if (res.data.returnCode === 0) {

                    var isSuccessLogin = await this.loginSubmit();
                    if(isSuccessLogin){
                        if(!this.isWebview){    //아튜브 앱이 아닐 경우 로그인 처리
                            this.PopupOkCallbakPage = '/Signup/Complete';
                        } else {
                            this.PopupOkCallbakPage = 'AppLoginSuccess';
                        }
                        this.initPopupOkMessage = "회원 가입이 완료 되었습니다.";
                        this.isShowPopupOkPopup = true;
                    }
                    else{
                        this.$router.push('/Signup/Complete');
                    }
                }
                else if(res.data.returnCode === 42){
                    this.errorMsgEmail = "중복된 이메일 입니다.";
                    this.isShowErrorEmail = true;
                }
                else if(res.data.returnCode === 43){
                    this.errorMsgNickname = "중복된 닉네임 입니다.";
                    this.isShowErrorNickname = true;
                }
                else {
                    this.errorMsgReturn = "회원 가입이 실패 했습니다.";
                    this.isShowReturnError = true;
                }
            })
            .catch((error) => {
                console.log(error);
            });
        },

        async loginSubmit() {

            var param = 
            {
                email: this.email,
                password: this.password
            }
            return await this.$Axios.post(`${this.host}/member/login`, param, { headers: { 'Pragma' : 'no-cache', 'Cache-Control': 'no-cache', 'Access-Control-Allow-Origin': '*', 'Content-type': 'application/json', }}).then(async(res) => {
                // console.log(res);
                if (res.data.returnCode === 0) {
                    if(!this.isWebview){
                        await this.$store.commit("setEmail", this.email);
                        await this.$store.commit("loginTicket", res.data.ticket);
                        await this.$store.commit("loginSign", res.data.sign);
                        await this.$store.commit("setProfile", this.host);
                    }
                    this.ticket = res.data.ticket;
                    this.sign = res.data.sign;
                    this.email = '';
                    this.password = '';
                    return true;
                } 
            })
            .catch((error) => {
                console.log(error);
                return false;
            });
        },

        CountDownTimer() {
            if (this.distance < 0) {

                clearInterval(this.timer);
                this.remainTime = '00:00';
                this.isSendEmail = false;

                return;
            }
            else if(this.distance < 60 * 1000){
                this.isWarningRemainTime = true;
            }
            var _minutes = Math.floor((this.distance % this.hour) / this.minute);
            var _seconds = Math.floor((this.distance % this.minute) / this.second);

            this.remainTime = _minutes < 10 ? '0' + _minutes.toString() : _minutes.toString();
            this.remainTime += ' : ';
            this.remainTime += _seconds < 10 ? '0' + _seconds.toString() : _seconds.toString();

            this.distance = this.distance - this.second;
        },
        close(popupType){
            var that = this;
            switch(popupType){
                case 'PopupOk':
                    this.isShowPopupOkPopup = false;
                    if(this.PopupOkCallbakPage !== ''){
                        if(this.PopupOkCallbakPage === 'AppLoginSuccess'){
                            this.timerAppApi = setInterval(()=>{
                                window.appApi("loginSuccess", {
                                    returnCode: 0,
                                    ticket: that.ticket,
                                    sign: that.sign,
                                    social: that.social
                                })
                            }, 1000)
                        } else {
                            this.$router.push(this.PopupOkCallbakPage);
                        }
                    }
                    this.PopupOkCallbakPage = '';
                    break;
                case 'PopupPolicy':
                    this.isClickPolicyPopup = false;
                    if(this.PopupOkCallbakPage !== ''){
                        this.$router.push(this.PopupOkCallbakPage);
                    }
                    this.PopupOkCallbakPage = '';
                    break;
                case 'PopupTerms':
                    this.isClickTermsPopup = false;
                    if(this.PopupOkCallbakPage !== ''){
                        this.$router.push(this.PopupOkCallbakPage);
                    }
                    this.PopupOkCallbakPage = '';
                    break;
            }
        },
    }
}
</script>