<template>
    <div class="assistPopup popup" v-if="initIsShowPopup && supportInfo !== null">
        <div class="popupBox">
            <div class="assistHead mb">
                <p>작가 후원하기</p>
                <a class="close" @click="closePopup"><img src="@/assets/images/assist/close.svg" /></a>
            </div>
            <ul class="menu">
                <li :class="{'on' : selectedTabNo === 1}" @click="selectedTabNo = 1">후원하기</li>
                <li :class="{'on' : selectedTabNo === 2}" @click="selectedTabNo = 2">댓글 보기</li>
            </ul>
            <div class="cover">
                <div class="left" :class="{'block' : selectedTabNo === 1}">
                    <div class="work">
                        <img :src="$store.state.siteConfig.cdnUrl + '/KR/title/' + supportInfo.webtoon.twebtoonForApi.englishName + '.jpg'" v-if="$store.state.siteConfig !== null" />
                        <div class="info">
                            <p class="title">{{ supportInfo.webtoon.twebtoonForApi.titleName }}</p>
                            <p class="writer">{{ supportInfo.webtoon.twebtoonForApi.publisher }}</p>
                            <p class="receive">받은 총 후원 {{ supportInfo.supportPointAll }}P</p>
                        </div>
                    </div>
                    <div class="sales">
                        <p class="leftTitle">작품 월 매출</p>
                        <p class="point">{{ supportInfo.preMonSales | comma }}P</p>
                        <p class="smallText">발생한 페이백 포인트 {{ (Math.floor(supportInfo.prePayBackPoint * 10000) / 10000 ) | comma }}P</p>
                    </div>
                    <div class="myAssist">
                        <p class="leftTitle">내가 후원한 포인트</p>
                        <p class="point">{{ supportInfo.supportPointMe | comma }}P</p>
                        <p class="smallText">현재 기여도 <span>{{ (Math.floor(supportInfo.contribution * 100) / 100 ) }}%</span></p>
                    </div>
                    <div class="goAssist">
                        <p class="leftTitle">후원하기 ( 내 아트포인트 : {{ $store.state.pgPoint + $store.state.attPoint | comma }}P)</p>
                        <p class="smallText">후원은 최소 5아트포인트가 필요합니다.</p>
                        <div class="buttons">
                            <button @click="sumPoint(5)">+5</button>
                            <button @click="sumPoint(50)">+50</button>
                            <button @click="sumPoint(250)">+250</button>
                            <button @click="sumPoint(500)">+500</button>
                        </div>
                        <div class="typing">
                            <p>후원할 포인트</p>
                            <input v-model="assistPoint" @keyup="commasAssistPoint()" min="5">
                            <p class="point">p</p>
                            <img src="@/assets/images/assist/reset.svg" @click="assistPoint = 0" />
                        </div>
                        <textarea placeholder="작가님에게 응원의 댓글을 써주세요" v-model="fighting" maxlength="100"></textarea>
                        <div class="warn">
                            <p class="red">* 후원한 포인트는 환불이 불가능 하며 운영상 위반,광고, 작가 비방, 스포일러 댓글을 작성 할 시 강제 삭제 될 수 있습니다.</p>
                            <p>*후원에 관련된 자세한 내용은 <router-link tag="span" to="/LibraryAssist">후원 목록 탭</router-link>을 참고하세요.</p>
                        </div>
                        <button class="complete" @click="letterNumberCheck()" :class="{off : fighting === '' || assistPoint < 5}">후원하기</button>
                    </div>
                </div>
                <div class="right" :class="{'block' : selectedTabNo === 2}">
                    <a class="close pc" @click="closePopup"><img src="@/assets/images/assist/close.svg" /></a>
                    <div class="top">
                        <div>
                            <p>댓글 {{ initPagingData.totalRecordSize }}개</p>
                            <img src="@/assets/images/assist/recycle.svg" class="recycle" @click="getSupportComment()" />
                        </div>
                        <div class="sort" @click="changeSort()">
                            <img src="@/assets/images/assist/sort.svg" />
                            <p>정렬 변경</p>
                        </div>
                    </div>
                    <div class="commentArea">
                        <div v-for="(item,idx) in supportComment" :key="idx" class="box" :class="{purple : item.point > 499, blue : 499 >= item.point && item.point >= 250, orange : item.point > 49 && item.point < 250, gray : item.point < 50 && item.point > 4}">
                            <div class="topInfo">
                                <img :src="$store.state.siteConfig.cdnUrl + '/KR/profilepicture/'+ item.mbPhoto" v-if="item.mbPhoto !== null" />
                                <img src="@/assets/images/img_profile_default.svg" v-else />
                                <p class="name">{{ item.mbNickName }}</p>
                                <p><span :class="{purple : item.point > 499, blue : 499 >= item.point && item.point >= 250, orange : item.point > 49 && item.point < 250, gray : item.point < 50 && item.point > 4}">X {{ item.point | comma }}P</span></p>
                            </div>
                            <p class="text" v-html="item.comment"></p>
                        </div>
                    </div>
                    <div class="more" @click="moreComment()" v-if="supportComment.length !== initPagingData.totalRecordSize">
                        <p>더보기</p>
                        <img src="@/assets/images/assist/more.svg" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'PopupAssist',
    data(){
        return {
            selectedTabNo: 1,

            supportInfo: null,
            supportComment: [],

            assistPoint: 0,
            numberAssistPoint: 0,
            fighting: '',

            commentSort: 1,

            currentPageNo: 1,      //현재 페이지 번호
            currentPageSize: 7,    //한번에 불러올 게시물 수
            initPagingData: null,
        }
    },
    props: {
        initIsShowPopup: {
            type: Boolean,
            default: false
        },
        initSiteConfig: {
            type: Object,
            default: null
        },
        initWebtoonIdx: {
            type: Number,
            default: 0
        },
    },
    watch: {
        initWebtoonIdx : function(){
            if(this.initWebtoonIdx === 0){
                this.supportInfo = null
                this.supportComment = []
            } else{
                this.getSupportInfo();
                this.getSupportComment();
            }
        },
    },
    async created() {
    },
    async mounted(){
    },
    beforeDestroy() {
	},
    methods: {
        getSupportInfo(){ // 후원 웹툰 정보
            var param =
            {
                ticket: this.$store.state.ticket,
                sign: this.$store.state.sign,
                viewAdult: this.$store.state.isCheckedAdult ? 1 : 0,

                webtoonidx: this.initWebtoonIdx
            }
            this.$Axios.post(`${this.host}/support/info`, param, { headers: { 'Pragma' : 'no-cache', 'Cache-Control': 'no-cache', 'Access-Control-Allow-Origin': '*', 'Content-type': 'application/json', }}).then(res => {
                // console.log(res.data)
                if (res.data.returnCode === 0) {
                    this.supportInfo = res.data;
                } else if(res.data.returnCode === 680){
                    this.$router.push('/');
                }
            })
            .catch((error) => {
                console.log(error);
            });
        },
        getSupportComment(){ // 후원 웹툰 댓글
            var param =
            {
                pageNo: this.currentPageNo,
                pageSize: this.currentPageSize,

                webtoonidx: this.initWebtoonIdx,
                sort: this.commentSort
            }
            this.$Axios.post(`${this.host}/support/comment`, param, { headers: { 'Pragma' : 'no-cache', 'Cache-Control': 'no-cache', 'Access-Control-Allow-Origin': '*', 'Content-type': 'application/json', }}).then(res => {
                // console.log(res.data)
                if (res.data.returnCode === 0) {
                    this.supportComment = res.data.list;
                    this.initPagingData = res.data.page;
                }
            })
            .catch((error) => {
                console.log(error);
            });
        },
        changeSort(){
            if(this.commentSort === 0){
                this.commentSort = 1;
                this.getSupportComment();
            } else if(this.commentSort === 1){
                this.commentSort = 0;
                this.getSupportComment();
            }
        },
        moreComment(){
            this.currentPageSize = this.currentPageSize + 7;
            this.getSupportComment();
        },
        Support(){ // 후원하기
            var param =
            {
                ticket: this.$store.state.ticket,
                sign: this.$store.state.sign,
                viewAdult: this.$store.state.isCheckedAdult ? 1 : 0,

                webtoonidx: this.initWebtoonIdx,
                point: this.numberAssistPoint,
                comment: this.fighting,
            }
            this.$Axios.post(`${this.host}/support`, param, { headers: { 'Pragma' : 'no-cache', 'Cache-Control': 'no-cache', 'Access-Control-Allow-Origin': '*', 'Content-type': 'application/json', }}).then(res => {
                // console.log(res.data)
                if (res.data.returnCode === 0) {
                    this.assistPoint = 0;
                    this.numberAssistPoint = 0;
                    this.fighting = '';
                    this.$store.commit("setProfile", this.host);
                    this.getSupportInfo();
                    this.getSupportComment();
                } else if(res.data.returnCode === 682){
                    console.log()
                }
            })
            .catch((error) => {
                console.log(error);
            });
        },
        commasAssistPoint() {
            var x = this.assistPoint.toString();
            x = x.replace(/[^0-9]/g, "");
            // x = Number(x).toString();
            var decimalNumber = '';
            if(x.indexOf('.', 0) >= 0){
                decimalNumber = x.substring(x.indexOf('.', 0));
            }
            else{
                x = Number(x).toString();
            }

            if(decimalNumber.length > 1){
                if(Number(decimalNumber.substring(1)) > 0){
                    x = Number(x.substring(0, x.indexOf('.'))).toString();
                    x += decimalNumber.substring(0,5);
                }
            }
            
            if(this.$store.state.pgPoint + this.$store.state.attPoint < Number(x)){
                x = (this.$store.state.pgPoint + this.$store.state.attPoint).toString();
            }
            this.assistPoint = this.setComma(x);
            this.numberAssistPoint = Number(this.assistPoint.replace(/,/g, ""));
        },
        setComma(obj) {
            var regx = new RegExp(/(-?\d+)(\d{3})/);
            var bExists = obj.indexOf(".", 0);//0번째부터 .을 찾는다.
            var strArr = obj.split('.');
            while (regx.test(strArr[0])) {//문자열에 정규식 특수문자가 포함되어 있는지 체크
                //정수 부분에만 콤마 달기 
                strArr[0] = strArr[0].replace(regx, "$1,$2");//콤마추가하기
            }
            if (bExists > -1) {
                //. 소수점 문자열이 발견되지 않을 경우 -1 반환
                obj = strArr[0] + "." + strArr[1];
            } else { //정수만 있을경우 //소수점 문자열 존재하면 양수 반환 
                obj = strArr[0];
            }
            return obj;//문자열 반환
        },
        sumPoint(sum){
            var regx = new RegExp(/,/);
            if(regx.test(this.assistPoint) === true){
                this.assistPoint = Number(this.assistPoint.replace(/,/g, "")) + sum;
            }else {
                this.assistPoint = Number(this.assistPoint) + sum;
            }
            this.commasAssistPoint();
        },
        letterNumberCheck(){
            if(0 < this.fighting.length <= 100){
                this.Support();
            } else{
                console.log();
            }
        },
        closePopup(){
            this.assistPoint = 0;
            this.numberAssistPoint = 0;
            this.fighting = '';
            this.currentPageSize = 7;
            this.commentSort = 1;
            this.$emit('close');
        },
    },
}
</script>