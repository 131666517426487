<template>
    <div class="day-webtoon">
        <Header :initHeaderType="initHeaderType" />
        <!-- banner -->
        <div id="featured" v-if="$store.state.siteConfig !== null">
            <VueSlickCarousel v-bind="FeaturedSliderSettings" class="slider" ref="featuredSlider" @beforeChange="featuredSlideChange()" v-if="bannerFeaturedList.length > 0">
                <a v-for="(item, index) in bannerFeaturedList" :key="index" class="slide" @click="clickBanner(item)">
                    <img :src="$store.state.siteConfig.cdnUrl + item.pcPath + item.pcFilename" class="pc"/>
                    <img :src="$store.state.siteConfig.cdnUrl + item.mpath + item.mfilename" class="mobile"/>
                </a>
            </VueSlickCarousel>
        </div>
        <!-- banner end -->
        <div class="menu">
            <b-container>
                <ul>
                    <li v-for="(item, index) in daywebtoonList" :key="index">
                        <router-link tag="a" :to="'/DailyToon/' + index" v-for='(day,index) in item.day' :key="index" :class="['day-button' + index, {'on' : currDayIdx === index + 1}]">{{item.day[index]}}<span :class="now === item.day[index] ? 'dot show' : 'dot'"></span></router-link>
                    </li>
                </ul>
            </b-container>
        </div>
        <div class="day-suggestion">
            <b-container>
                <p class="title">{{whatDayrecommend.title}}</p>
                <b-row>
                    <b-col v-for="(item,index) in recommendList" :key="index" class="col" cols="12" sm="6" :class="[index < selectedDaySlideIdx || index >= selectedDaySlideIdx + 2 ? 'hidden' : '']">
                        <div class="col-inner" @click="moveDetail(item)">
                            <div class="left">
                                <img :src="$store.state.siteConfig.cdnUrl + '/KR/title/' + item.twebtoonForApi.englishName + '.jpg'" class="league_img_set2" v-if="$store.state.siteConfig !== null" />
                                <img src="@/assets/images/adult_mark.svg" alt="성인 마크" class="adult_mark" v-if="item.twebtoonForApi.adult === 1">
                            </div>
                            <div class="right">
                                <p class="name">{{item.twebtoonForApi.titleName}}</p>
                                <p class="all_hwa" v-if="item.twebtoonForApi.complete === 1">{{item.epCount !== null ? item.epCount : 0}}화 완결</p>
                                <p class="all_hwa" v-else>{{item.epCount !== null ? item.epCount : 0}}화 연재</p>
                                <div class="emblem-box">
                                    <p class="emblem emblem-genre">{{getCategoryName(item.twebtoonForApi.category)}}</p>
                                </div>
                                <p class="desc">{{item.twebtoonForApi.desc}}</p>
                            </div>
                        </div>
                    </b-col>
                </b-row>
                <div class="next" @click="moveSlide('next', 'day')" v-if="recommendList.length > 2">
                    <img src="@/assets/images/league_new_next.svg" alt="next">
                </div>
                <div class="prev" @click="moveSlide('prev', 'day')" v-if="recommendList.length > 2">
                    <img src="@/assets/images/league_new_prev.svg" alt="prev">
                </div>
            </b-container>
        </div>
        <div class="day-list">
            <div class="m_menu">
                <b-container>
                    <ul>
                        <li v-for="(item, index) in categoryList" :key="index" :class="{'on' : currCategoryIdx === item.idx}">
                            <a @click="changeSelectedCategory(item.idx)">{{item.name}}</a>
                        </li>
                    </ul>
                </b-container>
            </div>
            <b-container>
                <div class="top-box">
                    <p class="title">{{whatDay.title}}</p>
                    <div class="top">
                        <ul class="sort">
                            <li :class="[currSortIdx === 2 ? 'on' : '']" @click="changeSort(2)">업데이트순</li>
                            <li :class="[currSortIdx === 1 ? 'on' : '']" @click="changeSort(1)">인기순</li>
                        </ul>
                        <b-select v-model="currCategoryIdx" @change="changeCategory">
                            <b-select-option v-for="(item,index) in categoryList" :key="index" :value="item.idx">{{item.name}}</b-select-option>
                        </b-select>
                    </div>
                </div>
                <p class="sub-title">{{whatDay.desc}}</p>
                <b-row>
                    <b-col cols="5" sm="2" class="jagpum" v-for="(item, index) in dayList" :key="index">
                        <div class="jagpum-inner" @click="moveDetail(item)">
                            <div class="top">
                                <img :src="$store.state.siteConfig.cdnUrl + '/KR/title/' + item.twebtoonForApi.englishName + '.jpg'" class="league_img_set2" v-if="$store.state.siteConfig !== null" />
                                <img src="@/assets/images/adult_mark.svg" alt="성인 마크" class="adult_mark" v-if="item.twebtoonForApi.adult === 1">
                            </div>
                            <div class="bottom">
                                <p class="name">{{item.twebtoonForApi.titleName}}</p>
                                <p class="all_hwa" v-if="item.twebtoonForApi.complete === 1">{{item.epCount !== null ? item.epCount : 0}}화 완결</p>
                                <p class="all_hwa" v-else>{{item.epCount !== null ? item.epCount : 0}}화 연재</p>
                                <div class="emblems">
                                    <p class="emblem emblem-up" v-if="item.twebtoonSymbolForApi !== null && item.twebtoonSymbolForApi.up">UP</p>
                                    <p class="emblem emblem-event" v-if="item.twebtoonSymbolForApi !== null && item.twebtoonSymbolForApi.event">이벤트</p>
                                    <p class="emblem emblem-genre">{{getCategoryName(item.twebtoonForApi.category)}}</p>
                                    <p class="emblem emblem-new" v-if="item.twebtoonSymbolForApi !== null && item.twebtoonSymbolForApi.newWebtoon">NEW</p>
                                    <p class="emblem emblem-hot" v-if="item.twebtoonSymbolForApi !== null && item.twebtoonSymbolForApi.hot">HOT</p>
                                </div>
                            </div>
                        </div>
                    </b-col>
                </b-row>
                <Pagination :initMaxRecordCount="currentPageSize" :initMaxPageCount="maxPageCount" :initPagingData="initPagingData" @movePaging="movePaging" />
            </b-container>
        </div>
        <b-container>
            <Banner :initBannerNumber="bannerNumber2" />
        </b-container>
        <Footer />
        <Login :initIsShowPopup="isShowLoginPopup" @close="close('Login')" />
        <PopupOK :initIsShowPopup="isShowPopupOkPopup" :initPopupOkMessage="initPopupOkMessage" @close="close('PopupOk')" />
    </div>
</template>

<script>
import Header from '@/components/common/Header.vue'
import Footer from '@/components/common/Footer.vue'
import VueSlickCarousel from 'vue-slick-carousel'
import Login from '@/components/common/Login.vue'
import Pagination from '@/components/common/Pagination.vue'
import Banner from '@/components/common/Banner.vue'
import PopupOK from '@/components/common/PopupOK.vue'

export default {
    name: 'DailyToon',
    pageCategory: 'Common',
    components: {
        Header,
        Footer,
        VueSlickCarousel,
        Login,
        Banner,
        Pagination,
        PopupOK,
    },
    data(){
        return {
            categoryList: [],
            currCategoryIdx: 0,

            dayList: [],
            whatDay: [],
            currDayIdx: 1,
            currSortIdx: 2,

            recommendList: [],
            whatDayrecommend: [],

            selectedDaySlideIdx : 0, 

            bannerFeaturedList : [],    //키비주얼 슬라이드
            bannerNumber2: -1,

            FeaturedSliderSettings:{lazyLoad: "ondemand", dots: true, arrows: true, fade: false, infinite: true, speed: 500, autoplaySpeed: 5000, slidesToShow: 1, slidesToScroll: 1, autoplay: true,},

            currentPageNo: 1,       //현재 페이지 번호
            currentPageSize: 25,    //한번에 불러올 게시물 수
            maxPageCount:10,        //한 화면에 보여질 페이지 수
            initPagingData: null,

            isShowLoginPopup: false,

            isShowPopupOkPopup: false,
            initPopupOkMessage: '',
            PopupOkCallbakPage: '',    // 확인 팝업에서 확인 버튼 눌렀을때 이동할 페이지

            initHeaderType: 'type3',

            daywebtoonList:[
                {day: ['월요일','화요일','수요일','목요일','금요일','토요일','일요일','완결'],}
            ],

            now: '',
        }
    },
    created() {
        this.setFeaturedList();
        var dayIdx = this.today();

        if(this.$route.params.day){
            this.currDayIdx = Number(this.$route.params.day) + 1;
        } else {
            this.currDayIdx = dayIdx;
        }
        this.getCategory();
        this.getDaliyList();
    },
    mounted(){
        this.bannerNumber2 = 701;
    },
    beforeDestroy() {
	},
    methods: {
        today(){
            var d = new Date();
            var week = new Array('일요일','월요일','화요일','수요일','목요일','금요일','토요일');
            var dayIdx = d.getDay();
            this.now = week[dayIdx];
            return dayIdx;
        },
        featuredSlideChange(event, slick) {
            // if(slider_no === 0){
                //console.log('handleBeforeChange', event, slick, currentSlide, nextSlide);
                this.selectedFeaturedSlideIdx = Number(slick);
            // }
        },
        setFeaturedList(){
            var param = 
            {
                ticket: this.$store.state.ticket,
                sign: this.$store.state.sign,
                viewAdult: this.$store.state.isCheckedAdult ? 1 : 0,
                number: 700
            }
            this.$Axios.post(`${this.host}/bannerintegration/list`, param, { headers: { 'Pragma' : 'no-cache', 'Cache-Control': 'no-cache', 'Access-Control-Allow-Origin': '*', 'Content-type': 'application/json', }}).then(async(res) => {
                if (res.data.returnCode === 0) {
                    this.bannerFeaturedList = res.data.list;
                    this.bannerFeaturedList.sort(function(){return Math.random() - Math.random();});    //순서 랜덤
                }
            })
            .catch((error) => {
                console.log(error);
            });
        },
        movePaging(pageNo){
            this.currentPageNo = pageNo;
            this.getDaliyList();
        },
        close(popupType){
            switch(popupType){
                case 'PopupOk':
                    this.isShowPopupOkPopup = false;
                    if(this.PopupOkCallbakPage !== ''){
                        this.$router.push(this.PopupOkCallbakPage);
                    }
                    this.PopupOkCallbakPage = '';
                    break;
                case 'Login':
                    this.isShowLoginPopup = false;
                    break;
            }
        },
        async clickBanner(bannerInfo){
            // console.log(bannerInfo);
            // private Integer newPage;    새로운페이지여부(1:새창 0:현재페이지)
            // private Integer linkType;	링크타입(0:풀URL 1:내부 라우터 2:웹툰)

            var linkUrl = bannerInfo.link;
            var linkType = bannerInfo.linkType;
            var newPage = bannerInfo.newPage;
            await this.reqBannerClick(bannerInfo.idx);      //배너 클릭 카운팅

            if(linkType === 2){     //웹툰 링크
                var webtoonInfo = await this.checkWebtoon(linkUrl);
                if(webtoonInfo === null){return;}
                if(webtoonInfo.adult === 1){
                    if(this.$store.state.loginCheck === false){
                        this.isShowLoginPopup = true;
                        return;
                    }
                    else if(!this.$store.state.adult){
                        var src = this.$store.state.siteConfig.niceUrl + '?email=' + this.$store.state.email;
                        window.open(src, 'popupChk', 'width=500, height=550, top=100, left=100, fullscreen=no, menubar=no, status=no, toolbar=no, titlebar=no, location=no, scrollbar=no');
                        return;
                    }
                    else{
                        linkUrl = '/Webtoon/Info/' + webtoonInfo.webToonIndex;
                    }
                } else {
                    linkUrl = '/Webtoon/Info/' + webtoonInfo.webToonIndex;
                }
            }

            if(newPage === 0){   //현재 창에서 이동
                if(linkType === 0){  //외부 링크
                    location.href = linkUrl;
                } else {
                    this.$router.push(linkUrl)
                }
            }
            else if(newPage === 1){  //새창으로 열기
                if(linkType !== 0){
                    let route = this.$router.resolve({path: linkUrl});
                    linkUrl = route.href;
                }
                window.open(linkUrl);
            }
        },
        async reqBannerClick(idx){
            var param = 
            {
                index: idx
            }
            return await this.$Axios.post(`${this.host}/click/banner`, param, { headers: { 'Pragma' : 'no-cache', 'Cache-Control': 'no-cache', 'Access-Control-Allow-Origin': '*', 'Content-type': 'application/json', }}).then(async(res) => {
                if(res.data.returnCode === 0){
                    return true;
                }
                else {
                    return false;
                }
                
            })
            .catch((error) => {
                console.log(error);
                return false;
            });
        },
        async checkWebtoon(webtoonIdx){
            var niceSrc = this.$store.state.siteConfig.niceUrl + '?email=' + this.$store.state.email;
            var param = 
            {
                idx: webtoonIdx,
                sign: this.$store.state.sign,
                ticket: this.$store.state.ticket,
                viewAdult: this.$store.state.isCheckedAdult ? 1 : 0,
            }
            return await this.$Axios.post(`${this.host}/webtoon`, param, { headers: { 'Pragma' : 'no-cache', 'Cache-Control': 'no-cache', 'Access-Control-Allow-Origin': '*', 'Content-type': 'application/json', }}).then(async(res) => {
                // console.log('--webtoon info--')
                // console.log(res.data);
                if (res.data.returnCode === 0) {
                    return res.data.data;
                }
                else if(res.data.returnCode === 252){
                    window.open(niceSrc, 'popupChk', 'width=500, height=550, top=100, left=100, fullscreen=no, menubar=no, status=no, toolbar=no, titlebar=no, location=no, scrollbar=no');
                    return null;
                }
                else if(res.data.returnCode === 300){
                    this.initPopupOkMessage = '해당 작품은 운영상의 문제에 위반되어 열람 및 구매 할 수 없습니다.';
                    this.PopupOkCallbakPage = '';
                    this.isShowPopupOkPopup = true;
                    return null;
                }
                else if(res.data.returnCode === 301){
                    if(this.$store.state.loginCheck === false){
                        this.isShowLoginPopup = true;
                    }
                    else if(!this.$store.state.adult){
                        window.open(niceSrc, 'popupChk', 'width=500, height=550, top=100, left=100, fullscreen=no, menubar=no, status=no, toolbar=no, titlebar=no, location=no, scrollbar=no');
                    }
                    return null;
                }
                else {
                    return null;
                }
                
            })
            .catch((error) => {
                console.log(error);
                return null;
            });
        },
        getCategory(){ // 카테고리 정보
            var param = 
            {
                // limit : 3
            }
            this.$Axios.post(`${this.host}/infocategory`, param, { headers: { 'Pragma' : 'no-cache', 'Cache-Control': 'no-cache', 'Access-Control-Allow-Origin': '*', 'Content-type': 'application/json', }}).then(res => {
                if (res.data.returnCode === 0) {
                    this.categoryList = res.data.list;
                }
            })
            .catch((error) => {
                console.log(error);
            });
        },
        getCategoryName(category_idx){
            var name = '';
            this.categoryList.forEach(element =>{
                if(category_idx === element.idx){
                    name = element.name;
                }
            })
            return name;
        },
        getDaliyList(){
            var param = 
            {
                category: this.currCategoryIdx,
                dayType: this.currDayIdx, 
                pageNo: this.currentPageNo,
                pageSize: this.currentPageSize,
                sign: this.$store.state.sign,
                sortType: this.currSortIdx, // 인기순  : 1, 업데이트 순  : 2
                ticket: this.$store.state.ticket,
                viewAdult: this.$store.state.isCheckedAdult ? 1 : 0,
            }
            // console.log(param);
            this.$Axios.post(`${this.host}/week`, param, { headers: { 'Pragma' : 'no-cache', 'Cache-Control': 'no-cache', 'Access-Control-Allow-Origin': '*', 'Content-type': 'application/json', }}).then(async(res) => {
                // console.log('-----week webtoon info');
                // console.log(res.data);
                if (res.data.returnCode === 0) {
                    this.whatDay = res.data.day.list;
                    this.dayList = res.data.day.list.list;
                    this.whatDayrecommend = res.data.recommend.list;
                    this.recommendList = res.data.recommend.list.list;
                    this.initPagingData = res.data.day.page;
                    this.selectedDaySlideIdx = 0;
                }
            })
            .catch((error) => {
                console.log(error);
            });
        },
        changeSelectedCategory(idx){
            this.currCategoryIdx = idx;
            this.currentPageNo = 1;
            this.dayList = [];
            this.getDaliyList();
        },
        changeCategory(){
            this.currentPageNo = 1;
            this.dayList = [];
            this.getDaliyList();
        },
        changeSort(idx){
            this.currentPageNo = 1;
            this.currSortIdx = idx;
            this.dayList = [];
            this.getDaliyList();
        },
        moveDetail(item){
            // console.log(item);
            if(item.twebtoonForApi.adult === 1){
                if(this.$store.state.loginCheck === false){
                    this.isShowLoginPopup = true;
                }
                else if(!this.$store.state.adult){
                    var src = this.$store.state.siteConfig.niceUrl + '?email=' + this.$store.state.email;
                    window.open(src, 'popupChk', 'width=500, height=550, top=100, left=100, fullscreen=no, menubar=no, status=no, toolbar=no, titlebar=no, location=no, scrollbar=no');
                }
                // else if(!this.$store.state.isCheckedAdult){
                //     this.initPopupOkMessage = '성인 컨텐츠 활성화 후 이용 가능합니다.';
                //     // this.PopupOkCallbakPage = '/League';
                //     this.isShowPopupOkPopup = true;
                // }
                else{
                    this.$router.push('/Webtoon/Info/' + item.twebtoonForApi.webToonIndex);
                }
            }else{
                this.$router.push('/Webtoon/Info/' + item.twebtoonForApi.webToonIndex);
            }
        },
        moveSlide(arrow, type){
            switch(type){
                case "day":
                    if(arrow === 'next' && this.selectedDaySlideIdx + 2 < this.recommendList.length){this.selectedDaySlideIdx += 1;}
                    else if(arrow === 'prev' && this.selectedDaySlideIdx - 1 >= 0){this.selectedDaySlideIdx -= 1;}
                    break;
            }
        },
    },
}
</script>