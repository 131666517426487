<template>
    <div class="changeinfo wrap">
        <Header :initHeaderType="initHeaderType" />
        <div class="changeinfo_box">
            <h1>비밀번호 변경</h1>
            <p class="plz">안전한 비밀번호로 회원님의 소중한 정보를 보호하세요.</p>
            
            <div class="changepass_box">
                <p>비밀번호 변경</p>
                <input placeholder="현재 비밀번호" type="password" class="pass" v-model="currPassword" autocomplete='off' @input="inputPassword"/>
                <input placeholder="비밀번호(8~32자리)" type="password" class="pass" v-model="password" autocomplete='off' @input="inputPassword"/>
                <input placeholder="비밀번호 재입력" type="password" class="repass" v-model="password_confirm" autocomplete='off' @input="inputPassword"/>
                <div class="empty_box">
                    <p class="error" v-if="isShowErrorPassword">{{errorMsgPassword}}</p>
                    <p class="success"  v-if="isShowSuccessPassword">비밀번호가 변경되었습니다.</p>
                </div>
            </div>
            <div class="desc_box">
                <div class="title">
                    <img src="@/assets/images/loud-speaker.svg" />
                    <p>주의 하세요!</p>
                </div>
                <p class="desc">- 비밀번호는 8~32자리까지 변경 가능합니다.</p>
                <p class="desc">- 다른 사이트에서 사용하지 않은 비밀번호를 권장합니다.</p>
                <p class="desc">- 보안을 위해서 최소 6개월 단위로 비밀번호는 변경하여 사용해주세요.</p>
            </div>
            <div class="button_box">
                <p class="btn-save" @click="changePassword">변경</p>
                <router-link tag="a" to="/Member/Info" class="btn-cancel">취소하기</router-link>
            </div>
        </div>
        <!-- <Secession :initIsShowPopup="isShowLeavePopup" @close="close('Leave')" /> -->
        <!-- <Metamask :initIsShowPopup="isShowMetamaskPopup" @close="close('Metamask')" /> -->
        <PopupOK :initIsShowPopup="isShowPopupOkPopup" :initPopupOkMessage="initPopupOkMessage" @close="close('PopupOk')" />
        <Footer />
    </div>
</template>

<script>
import Header from '@/components/common/Header.vue'
import Footer from '@/components/common/Footer.vue'
import PopupOK from '@/components/common/PopupOK.vue'

export default {
    name: 'MemberPasswordChagne',
    pageCategory: 'Member',
    pageTitle: '비밀번호 변경',
    components: {
        Header,
        Footer,
        PopupOK,
    },
    data(){
        return{

            currPassword: '',
            password: '',
            password_confirm: '',

            isShowErrorPassword: false,
            isShowSuccessPassword: false,
            errorMsgPassword: '',

            isShowPopupOkPopup: false,
            initPopupOkMessage: '',
            PopupOkCallbakPage: '',    // 확인 팝업에서 확인 버튼 눌렀을때 이동할 페이지

            initHeaderType: 'type2',
        }
    },
    async created(){
        // await this.mixinSiteConfigCheck();
        await this.mixinLoginCheck();

        if(!this.$store.state.loginCheck){
            this.initPopupOkMessage = '로그인 후 이용하실 수 있습니다.'
            this.PopupOkCallbakPage = '/';
            this.isShowPopupOkPopup = true;
        }
    },
    methods:{
        inputPassword(){
            this.isShowErrorPassword = false;
            this.isShowSuccessPassword = false;
        },
        changePassword(){

            this.isShowErrorPassword = false;
            this.isShowSuccessPassword = false;
            
            if(this.currPassword === ''){
                this.initPopupOkMessage = '현재 비밀번호를 입력해 주세요.';
                this.isShowPopupOkPopup = true;

                this.errorMsgPassword = '현재 비밀번호를 입력해 주세요.';
                this.isShowErrorPassword = true;
                return;
            }
            else if(this.password === '' || this.password_confirm === ''){
                this.initPopupOkMessage = '변경할 비밀번호를 입력해 주세요.';
                this.isShowPopupOkPopup = true;

                this.errorMsgPassword = '변경할 비밀번호를 입력해 주세요.';
                this.isShowErrorPassword = true;
                return;
            }
            else if(this.password !== this.password_confirm){
                this.initPopupOkMessage = '변경할 비밀번호가 일치하지 않습니다.';
                this.isShowPopupOkPopup = true;

                this.errorMsgPassword = '변경할 비밀번호가 일치하지 않습니다.';
                this.isShowErrorPassword = true;
                return;
            }

            var param = 
                {
                    sign: this.$store.state.sign,
                    ticket: this.$store.state.ticket,
                    priorPassword: this.currPassword,
                    newPassword: this.password
                }
                this.$Axios.post(`${this.host}/member/update/password`, param, { headers: { 'Pragma' : 'no-cache', 'Cache-Control': 'no-cache', 'Access-Control-Allow-Origin': '*', 'Content-type': 'application/json', }}).then(async(res) => {
                    // console.log(res.data);
                    if (res.data.returnCode === 0) {
                        this.initPopupOkMessage = '비밀번호가 변경되었습니다.';
                        this.PopupOkCallbakPage = '/Member/Info'
                        this.isShowPopupOkPopup = true;

                        this.currPassword = '';
                        this.password = '';
                        this.password_confirm = '';
                        // this.isShowSuccessPassword = true;
                    }
                    else if(res.data.returnCode === 92){
                        this.initPopupOkMessage = '현재 비밀번호가 일치하지 않습니다.';
                        this.isShowPopupOkPopup = true;

                        this.errorMsgPassword = '현재 비밀번호가 일치하지 않습니다.';
                        this.isShowErrorPassword = true;
                    }
                    else {
                        this.initPopupOkMessage = '비밀번호 변경이 실패했습니다.';
                        this.isShowPopupOkPopup = true;

                        // this.errorMsgNickname = res.data.description;
                        // this.isShowErrorNickname = true;
                    }
                })
                .catch((error) => {
                    console.log(error);
                });

        },
        close(popupType){
            switch(popupType){
                case 'PopupOk':
                    this.isShowPopupOkPopup = false;
                    if(this.PopupOkCallbakPage === 'reload'){
                        location.reload();
                    }
                    else if(this.PopupOkCallbakPage !== ''){
                        this.$router.push(this.PopupOkCallbakPage);
                    }
                    this.PopupOkCallbakPage = '';
                    break;
            }
        },
    }
}
</script>