<template>
    <div class="league_mypage">
        <Header :initHeaderType="initHeaderType" />
        <div class="profile_area">
            <b-container>
                <div class="profile_image">
                    <img src="@/assets/images/img_profile_default.svg" v-if="$store.state.photo === '' || $store.state.photo === null || $store.state.siteConfig === null" />
                    <img :src="$store.state.siteConfig.cdnUrl + '/KR/profilepicture/'+ $store.state.photo" v-else/>
                </div>
                <div class="profile_info">
                    <p class="name" v-if="writerInfo !== null">{{writerInfo.cwPenName}}</p>
                    <p class="mail">{{$store.state.email}}</p>
                </div>
            </b-container>
        </div>
        <div class="work_area">
            <b-container>
                <div class="title_box">
                    <p class="title">등록작품</p>
                    <div class="ps">
                        <p>- 웹툰 정보의 등록, 수정으로 인한 검수 중 상태나 운영원칙 위반 시 해당 작품이 플랫폼에 노출이 되지 않습니다. </p>
                        <p>- 해당 페이지에서 작품이 운영원칙 위반인 경우 작품 정보를 수정해 주세요.</p>
                    </div>
                </div>
                <b-row>
                    <b-col v-for="(item, index) in writerWebtoonList" :key="index" lg="4" md="6" class="col" @click="moveDetail(item)">
                        <div class="img_dum">
                            <img :src="$store.state.siteConfig.cdnUrl + '/CHALLENGE/mainthumbnail/' + item.ctCdnFolder + '.jpg'" v-if="$store.state.siteConfig !== null">
                        </div>
                        <div class="text">
                            <!-- <p class="state">작품 등록 검수 중</p> -->
                            <p class="state inspection" v-if="item.ctState === 0">작품 등록 검수 중</p>
                            <p class="state violation2" v-if="item.ctState === 2">운영 정책 위반</p>

                            <p class="name">{{item.ctTitleName}}</p>
                            <p class="epcount">총 {{item.epCount}}화</p>
                            <p class="viewcount">조회 {{item.ctViewTotal}}</p>
                            <p class="round_star">{{item.ctStarAvg}}</p>
                        </div>
                    </b-col>
                    <b-col class="new_enroll" @click="addWebtoon">
                        <div class="plus">
                            <div class="cross"></div>
                        </div>
                        <p>새 작품 등록</p>
                        <p>다음 작품을 등록하세요</p>
                    </b-col>
                </b-row>
            </b-container>
        </div>
        <Footer />
        <PopupOK :initIsShowPopup="isShowPopupOkPopup" :initPopupOkMessage="initPopupOkMessage" @close="close('PopupOk')" />
    </div>
</template>

<script>
import Header from '@/components/common/Header.vue'
import Footer from '@/components/common/Footer.vue'
import PopupOK from '@/components/common/PopupOK.vue'

export default {
    name: 'LeagueWriterInfo',
    pageCategory: 'League',
    components: {
        Header,
        Footer,
        PopupOK,
    },
    data(){
        return{
            isShowPopup : true,
            
            writerInfo: null,
            writerWebtoonList: [],

            isShowPopupOkPopup: false,
            initPopupOkMessage: '',
            PopupOkCallbakPage: '',    // 확인 팝업에서 확인 버튼 눌렀을때 이동할 페이지

            initHeaderType: 'type1',
        }
    },
    async created(){
        var isPc = this.isPc();
        if(!isPc){
            this.initPopupOkMessage = '모바일 환경에서는 작가 활동이 제한됩니다.<br/>PC 환경에서 이용해주세요.'
            this.PopupOkCallbakPage = '/League';
            this.isShowPopupOkPopup = true;
            return;
        }
        // await this.mixinSiteConfigCheck();
        await this.mixinLoginCheck();
        this.checkAccessAuthority();
    },
    methods:{
        async checkAccessAuthority(){
            if(this.$store.state.loginCheck === false){ //로그인 확인
                this.initPopupOkMessage = '로그인 후 시도해 주세요.'
                this.PopupOkCallbakPage = '/League';
                this.isShowPopupOkPopup = true;
            }
            else if(!this.$store.state.adult){ //성인 인증 확인
                this.initPopupOkMessage = '성인 인증 후 시도해 주세요.'
                this.PopupOkCallbakPage = '/League';
                this.isShowPopupOkPopup = true;
            }
            else{
                var checkWriter = await this.checkWriter(); //작가 등록 여부 확인
                if(checkWriter === null){   //체크 에러
                    this.initPopupOkMessage = '작가 등록 여부 체크 에러'
                    this.PopupOkCallbakPage = '/League';
                    this.isShowPopupOkPopup = true;
                }
                else if(!checkWriter){  //작가 등록 안된 상태
                    this.$router.push('/League/Writer/Signup');
                }
                else{
                    this.getWriterWebtoonList();
                }
            }
        },
        async checkWriter(){
            var param = 
            {
                sign: this.$store.state.sign,
                ticket: this.$store.state.ticket,
                viewAdult: this.$store.state.isCheckedAdult ? 1: 0
            }
            return await this.$Axios.post(`${this.league_host}/challenge/writer/check`, param, { headers: { 'Pragma' : 'no-cache', 'Cache-Control': 'no-cache', 'Access-Control-Allow-Origin': '*', 'Content-type': 'application/json', }}).then(async(res) => {
                // console.log(res.data.writer);
                if (res.data.returnCode === 0) {    //작가 등록됨
                    this.writerInfo = res.data.writer;
                    return true;
                }
                else if(res.data.returnCode === 41){    //작가 등록 안됨
                    return false;
                }
                else{   //기타 에러
                    return null;
                }
            })
            .catch((error) => {
                console.log(error);
                return null;
            });
        },
        getWriterWebtoonList(){
            var param = 
            {
                sign: this.$store.state.sign,
                ticket: this.$store.state.ticket,
                viewAdult: this.$store.state.isCheckedAdult ? 1 : 0,
                writerIdx: this.writerInfo.cwWriterIdx,
                pageNo: 1,
                pageSize: 100
            }
            this.$Axios.post(`${this.league_host}/challenge/writer/webtoon/list`, param, { headers: { 'Pragma' : 'no-cache', 'Cache-Control': 'no-cache', 'Access-Control-Allow-Origin': '*', 'Content-type': 'application/json', }}).then(async(res) => {
                // console.log('--writer webtoon list--')
                // console.log(res.data);
                if (res.data.returnCode === 0) {
                    this.writerWebtoonList = res.data.list;
                }
            })
            .catch((error) => {
                console.log(error);
            });
        },
        moveDetail(webtoonInfo){
            this.$router.push('/League/Writer/Webtoon/Info/' + webtoonInfo.ctWebtoonIdx)
        },
        addWebtoon(){
            this.$router.push('/League/Writer/Webtoon');
        },
        close(popupType){
            switch(popupType){
                case 'PopupOk':
                    this.isShowPopupOkPopup = false;
                    if(this.PopupOkCallbakPage !== ''){
                        this.$router.push(this.PopupOkCallbakPage);
                    }
                    this.PopupOkCallbakPage = '';
                    break;
            }
        },
    }
}
</script>