<template>
    <div id="viewer">
        <Header :initHeaderType="initHeaderType" :initIsHeaderShow="isShowViewer" @changeState="changeState" />
        <LeagueViewerheader :initWebtoonInfo="webtoonInfo" :initEpisodeIdx="currEpisodeIdx" :initEpisodeState="episodeState" :initNextEpisodeIdx="nextEpisodeIdx" :initPreEpisodeIdx="preEpisodeIdx" :initisShowViewer="isShowViewer" />
        <div class="container">
            <div id="viewer-img">
                <img v-for="(item,index) in imageList" :key="index" @click="touchImg"
                    :src="league_host + '/challenge/episode/image/view/' 
                            + $store.state.ticket + '/'
                            + $store.state.sign + '/'
                            + currWebtoonIdx + '/'
                            + currEpisodeIdx + '/'
                            + item.ciPageIdx"
                />
            </div>
            <p class="warn">아튜브의 모든 콘텐츠는 저작권 법에 의거하여 보호받고 있습니다. <br>저작권자 또는 아튜브의 승인없이 콘텐츠의 일부 또는 전부를 복제, 변경, 전송, 배포 등의 <br>방법으로 이용할 경우 <span>저작권법에 의거하여 법적 조치에 처해질 수 있습니다.</span></p>
            <p class="logo">artube</p>
            <div class="star">
                <p>이번 회차의 점수를 평가 해주세요.</p>
                <div class="startRadio" v-if="!isCheckedStar">
                    <label class="startRadio__box" @click="checkStarGrade(0.5)">
                        <input type="radio" name="star" id="" :checked="starGrade >= 0.5 ? 'checked' : ''">
                        <span class="startRadio__img"><span class="blind"></span></span>
                    </label>
                    <label class="startRadio__box" @click="checkStarGrade(1)">
                        <input type="radio" name="star" id="" :checked="starGrade >= 1 ? 'checked' : ''">
                        <span class="startRadio__img"><span class="blind"></span></span>
                    </label>
                    <label class="startRadio__box" @click="checkStarGrade(1.5)">
                        <input type="radio" name="star" id="" :checked="starGrade >= 1.5 ? 'checked' : ''">
                        <span class="startRadio__img"><span class="blind"></span></span>
                    </label>
                    <label class="startRadio__box" @click="checkStarGrade(2)">
                        <input type="radio" name="star" id="" :checked="starGrade >= 2 ? 'checked' : ''">
                        <span class="startRadio__img"><span class="blind"></span></span>
                    </label>
                    <label class="startRadio__box" @click="checkStarGrade(2.5)">
                        <input type="radio" name="star" id="" :checked="starGrade >= 2.5 ? 'checked' : ''">
                        <span class="startRadio__img"><span class="blind"></span></span>
                    </label>
                    <label class="startRadio__box" @click="checkStarGrade(3)">
                        <input type="radio" name="star" id="" :checked="starGrade >= 3 ? 'checked' : ''">
                        <span class="startRadio__img"><span class="blind"></span></span>
                    </label>
                    <label class="startRadio__box" @click="checkStarGrade(3.5)">
                        <input type="radio" name="star" id="" :checked="starGrade >= 3.5 ? 'checked' : '' ">
                        <span class="startRadio__img"><span class="blind"></span></span>
                    </label>
                    <label class="startRadio__box" @click="checkStarGrade(4)">
                        <input type="radio" name="star" id="" :checked="starGrade >= 4 ? 'checked' : ''">
                        <span class="startRadio__img"><span class="blind"></span></span>
                    </label>
                    <label class="startRadio__box" @click="checkStarGrade(4.5)">
                        <input type="radio" name="star" id="" :checked="starGrade >= 4.5 ? 'checked' : ''">
                        <span class="startRadio__img"><span class="blind"></span></span>
                    </label>
                    <label class="startRadio__box" @click="checkStarGrade(5)">
                        <input type="radio" name="star" id="" :checked="starGrade >= 5 ? 'checked' : ''">
                        <span class="startRadio__img"><span class="blind"></span></span>
                    </label>
                </div>
                <div class="show-star-point" v-else>
                    <div>
                        <img class="star" v-if="starGrade >= 1" src="@/assets/images/icon_gpa_star2.png"/>
                        <img class="star" v-else-if="starGrade >= 0.5" src="@/assets/images/icon_star_gpa_half2.png" />
                        <img class="star" v-else src="@/assets/images/icon_star_gpa_empty2.png" />

                        <img class="star" v-if="starGrade >= 2" src="@/assets/images/icon_gpa_star2.png"/>
                        <img class="star" v-else-if="starGrade >= 1.5" src="@/assets/images/icon_star_gpa_half2.png" />
                        <img class="star" v-else src="@/assets/images/icon_star_gpa_empty2.png" />

                        <img class="star" v-if="starGrade >= 3" src="@/assets/images/icon_gpa_star2.png"/>
                        <img class="star" v-else-if="starGrade >= 2.5" src="@/assets/images/icon_star_gpa_half2.png" />
                        <img class="star" v-else src="@/assets/images/icon_star_gpa_empty2.png" />

                        <img class="star" v-if="starGrade >= 4" src="@/assets/images/icon_gpa_star2.png"/>
                        <img class="star" v-else-if="starGrade >= 3.5" src="@/assets/images/icon_star_gpa_half2.png" />
                        <img class="star" v-else src="@/assets/images/icon_star_gpa_empty2.png" />

                        <img class="star" v-if="starGrade >= 5" src="@/assets/images/icon_gpa_star2.png"/>
                        <img class="star" v-else-if="starGrade >= 4.5" src="@/assets/images/icon_star_gpa_half2.png" />
                        <img class="star" v-else src="@/assets/images/icon_star_gpa_empty2.png" />
                    </div>
                </div>
            </div>
            <div class="confirm" v-if="!isCheckedStar" @click="submitGrade">확인</div>
            <div class="start-complete" v-else>이미 참여 하셨습니다.</div>
            <div class="declaration_button" @click="showPopupReport">신고하기</div>

            <div class="group" v-if="episodeList.length > 0">
                <div class="prev" @click="prevPage()">prev</div>
                    <div class="toon" v-for="(item, index) in episodeList" :key="index" :class="[item.ceEpisodeIdx === currEpisodeIdx ? 'now' : 'secret']" @click="changeEpisode(currWebtoonIdx, item.ceEpisodeIdx)">
                        <img :src="$store.state.siteConfig.cdnUrl + '/CHALLENGE/thumbnail/' + webtoonInfo.ctCdnFolder + '/' + item.ceUrl" alt="썸네일" class="ssum" v-if="webtoonInfo !== null && $store.state.siteConfig !== null">
                        <p>{{item.ceEpisodeIdx}}화</p>
                    </div>
                <div class="next" @click="nextPage()">next</div>
            </div>
            <div class="buttons2">
                <div class="list2" @click="backList">목록 보기</div>
            </div>
            <p class="suggestion_button" @click="isUnFold = !isUnFold">비슷한 도전 만화 추천 보기</p>
            <div class="new_upload"  v-if="isUnFold">
                <b-row class="suggestion">
                    <b-col lg="2" class="jagpum" v-for="(item,index) in categoryWebtoonList" :key="index" :class="[index < selectedCategoryWebtoonSlideIdx || index >= selectedCategoryWebtoonSlideIdx + 5 ? 'hidden' : '' ]" @click="moveDetail(item)">
                        <div class="top">
                            <img :src="$store.state.siteConfig.cdnUrl + '/CHALLENGE/mainthumbnail/' + item.ctCdnFolder + '.jpg'" class="league_img_set2"  v-if="$store.state.siteConfig !== null"/>
                        </div>
                        <div class="middle">
                            <p class="name">{{item.ctTitleName}}</p>
                            <p class="star">{{item.ctStarAvg.toFixed(1)}}</p>
                        </div>
                        <div class="bottom">
                            <p class="all_hwa">총{{item.epCount}}화</p>
                            <p class="view_count">{{item.ctViewCount}}회</p>
                            <!-- <p class="introduce">{{item.ctDesc}}</p> -->
                        </div>
                    </b-col>
                </b-row>
                <div class="next" @click="moveSlide('next', 'categoryWebtoon')">
                    <img src="@/assets/images/league_new_next.svg" alt="next">
                </div>
                <div class="prev" @click="moveSlide('prev', 'categoryWebtoon')">
                    <img src="@/assets/images/league_new_prev.svg" alt="next">
                </div>
            </div>
        </div>
        <Footer />
        <LeagueViewermove :initWebtoonInfo="webtoonInfo" :initEpisodeState="episodeState" :initNextEpisodeIdx="nextEpisodeIdx" :initPreEpisodeIdx="preEpisodeIdx" :initisShowViewer="isShowViewer" :initEpisodeIdx="currEpisodeIdx" @scrollBottom="scrollBottom" :initPagingData="initPagingData" :initEpisodeOptimum="episodeOptimum"/>
        <Login :initIsShowPopup="isShowLoginPopup" @close="close('Login')" />
        <Declaration :initIsShowPopup="isShowDeclarationPopup" :initWebtoonInfo="webtoonInfo" :initEpisodeState="episodeState" @close="close('Declaration')" @successReport="successReport" @overlapReport="overlapReport" />
        <PopupOK :initIsShowPopup="isShowPopupOkPopup" :initPopupOkMessage="initPopupOkMessage" @close="close('PopupOk')" />
    </div>
</template>

<script> 
import Footer from '@/components/common/Footer.vue'
import Header from '@/components/common/Header.vue'
import Login from '@/components/common/Login.vue'
import LeagueViewerheader from '@/components/league/LeagueViewerheader.vue'
import LeagueViewermove from '@/components/league/LeagueViewermove.vue'
import Declaration from '@/components/league/Declaration.vue'  //신고하기
import PopupOK from '@/components/common/PopupOK.vue'

export default {
    name: 'LeagueWebtoonViewer',
    pageCategory: 'League',
    components: {
        Footer,
        Header,
        Login,
        LeagueViewerheader,
        LeagueViewermove,
        Declaration,
        PopupOK,
    },
    data() {
        return {
            currWebtoonEngName: '',
            currWebtoonIdx: 0,
            currEpisodeIdx: 0,

            webtoonInfo: null,
            episodeState: null,
            episodeOptimum: null,
            nextEpisodeIdx: null,
            preEpisodeIdx : null,
            imageList: [],
            episodeList: [],
            chagneEpisodeInfo: null,
            categoryWebtoonList: [],
            selectedCategoryWebtoonSlideIdx: 0,

            selectedEpisodieInfo: null,

            isShowLoginPopup: false,
            isUnFold: false,

            currentPageNo: 1,      //현재 페이지 번호
            currentPageSize: 7,    //한번에 불러올 게시물 수
            maxPageCount:1,        //한 화면에 보여질 페이지 수

            listCount: 0,
            pageCount: 0,
            pageNo: 0,
            pageSize: 0,
            totalRecordSize: 0,
            initPagingData: null,

            pageList:[],

            starGrade : 0,
            isCheckedStar: false,

            isShowDeclarationPopup: false,

            isShowPopupOkPopup: false,
            initPopupOkMessage: '',
            PopupOkCallbakPage: '',    // 확인 팝업에서 확인 버튼 눌렀을때 이동할 페이지

            isShowViewer: true,
            isShowMypageState: false,   //헤더 햄버거 마이페이지 팝업 열려있는지 상태를 받아와서 저장(여기서 값 수정 ㄴㄴ)
            initHeaderType: 'type5',
        }
    },
    async created(){
        await this.mixinLoginCheck();

        if(this.$route.params.wtidx){
            this.currWebtoonIdx = Number(this.$route.params.wtidx);
        }else{
            this.$router.push('/League');
        }

        if(this.$route.params.epidx){
            this.currEpisodeIdx = Number(this.$route.params.epidx);
        }else{
            this.$router.push('/League');
        }
        
        if(!this.$store.state.loginCheck){
            this.$router.push('/League');
        }else{
            await this.getWebtoonInfo();
            await this.getEpisodeState();
            await this.getEpisodeOptimum();
            await this.getImageList();
            this.checkStarPorint();
            this.getCategoryWebtoonList();
            var start_idx = 1;
            var end_idx = this.currentPageSize;

            if(this.currEpisodeIdx === 0){
                this.currentPageNo = 1;
                this.getEpisodeList();
            }
            else{
                for(var i=1;i<100;i++)
                {

                    if(this.currEpisodeIdx >= start_idx && this.currEpisodeIdx <= end_idx){
                        this.currentPageNo = i;
                        await this.getEpisodeList();

                        var isContain = false;
                        this.episodeList.forEach(element =>{
                            if(element.ceEpisodeIdx === this.currEpisodeIdx){
                                isContain = true;
                            }
                        });

                        if(!isContain){
                            this.currentPageNo ++;
                            this.getEpisodeList();
                        }

                        break;
                    }else{
                        start_idx = start_idx + this.currentPageSize;
                        end_idx = end_idx + this.currentPageSize
                    }
                }
            }
        }
    },
    mounted(){
        window.addEventListener('scroll', this.scrollHandler);
    },
    beforeDestroy(){
        window.removeEventListener('scroll', this.scrollHandler);
    }, 
    methods: {
        scrollBottom(){
            var element = document.getElementById('viewer-img').clientHeight;
            // console.log(document.documentElement.scrollHeight)
            // console.log(document.documentElement.scrollHeight - element);
            document.documentElement.scrollTop = document.documentElement.scrollHeight - (document.documentElement.scrollHeight - element + window.innerHeight - 82);
            // console.log(document.documentElement.scrollHeight - (document.documentElement.scrollHeight - element))
        },
        scrollHandler(){
            if(this.isShowMypageState){
                return;
            }
            var nowlocation = document.documentElement.scrollTop;
            // console.log(this.document.documentElement.scrollHeight)
            // console.log(nowlocation)
            var windowlocation = window.innerHeight;
            // console.log(windowlocation)
            var endlocation = document.documentElement.scrollHeight - document.documentElement.scrollTop;
            // console.log(endlocation);
            if(endlocation <= windowlocation + 62){
                this.isShowViewer = true;
            } else if(nowlocation > 0){
                this.isShowViewer = false;
            } else{
                this.isShowViewer = true;
            }
        },
        touchImg(){
            // if(!this.isPc()){
                this.isShowViewer = !this.isShowViewer
            // }
        },
        checkStarGrade(grade){
            this.starGrade = grade;
        },
        submitGrade(){  //별점 주기
            if(this.starGrade === 0){
                return;
            }
            var param = 
            {
                wtIdx: this.currWebtoonIdx,
                epIdx: this.currEpisodeIdx,
                score: this.starGrade,
                sign: this.$store.state.sign,
                ticket: this.$store.state.ticket,
                viewAdult: this.$store.state.isCheckedAdult ? 1: 0
            }
            this.$Axios.post(`${this.league_host}/challenge/star`, param, { headers: { 'Pragma' : 'no-cache', 'Cache-Control': 'no-cache', 'Access-Control-Allow-Origin': '*', 'Content-type': 'application/json', }}).then(async(res) => {
                if (res.data.returnCode === 0) {
                    this.checkStarPorint();
                }else{
                    console.log(res.data.description);
                }
            })
            .catch((error) => {
                console.log(error);
            });
        },
        checkStarPorint(){  //별점 줬는지 체크(이미 줬다면 점수 확인)
            var param = 
            {
                wtIdx: this.currWebtoonIdx,
                epIdx: this.currEpisodeIdx,
                score: 0,
                sign: this.$store.state.sign,
                ticket: this.$store.state.ticket,
                viewAdult: this.$store.state.isCheckedAdult ? 1: 0
            }
            this.$Axios.post(`${this.league_host}/challenge/star/check`, param, { headers: { 'Pragma' : 'no-cache', 'Cache-Control': 'no-cache', 'Access-Control-Allow-Origin': '*', 'Content-type': 'application/json', }}).then(async(res) => {
                // console.log('---- check star ----')
                // console.log(res.data);
                if (res.data.returnCode === 0) {
                    this.isCheckedStar = true;
                    this.starGrade = res.data.score;
                }
                else if(res.data.returnCode === 83){
                    this.isCheckedStar = false;
                }
                else{
                    console.log(res.data.description);
                }
            })
            .catch((error) => {
                console.log(error);
            });
        },
        showPopupReport(){  //신고하기 팝업
            this.isShowDeclarationPopup = true;
        },
        prevPage(){
            if(this.currentPageNo > 1){
                this.currentPageNo--;
                this.getEpisodeList();
            }
        },
        nextPage(){
            if(this.currentPageNo < this.pageCount){
                this.currentPageNo++;
                this.getEpisodeList();
            }
        },
        async getImageList(){
            var param = 
            {
                sign: this.$store.state.sign,
                ticket: this.$store.state.ticket,
                viewAdult: this.$store.state.isCheckedAdult ? 1 : 0,
                episodeIdx: this.currEpisodeIdx,
                webtoonIdx: this.currWebtoonIdx,
                pageNo: 1,
                pageSize: 1000,
                // lang: 'KR'
            }
            await this.$Axios.post(`${this.league_host}/challenge/episode/image/list`, param, { headers: { 'Pragma' : 'no-cache', 'Cache-Control': 'no-cache', 'Access-Control-Allow-Origin': '*', 'Content-type': 'application/json', }}).then(async(res) => {
                // console.log('----image list----');
                // console.log(res.data);
                if (res.data.returnCode === 0) {
                    this.imageList = res.data.list;
                }
                else{
                    console.log(res.data.description);
                    // this.$router.push('/League');
                }
            })
            .catch((error) => {
                console.log(error);
            });
        },
        async getWebtoonInfo(){
            var param = 
            {
                idx: this.currWebtoonIdx,
                league: 1,
                sign: this.$store.state.sign,
                ticket: this.$store.state.ticket,
                viewAdult: this.$store.state.isCheckedAdult ? 1 : 0,
            }
            await this.$Axios.post(`${this.league_host}/challenge/webtoon`, param, { headers: { 'Pragma' : 'no-cache', 'Cache-Control': 'no-cache', 'Access-Control-Allow-Origin': '*', 'Content-type': 'application/json', }}).then(async(res) => {
                // console.log('----webtoon info----');
                // console.log(res.data);
                if (res.data.returnCode === 0) {
                    this.webtoonInfo = res.data.data;
                }
                else{
                    console.log(res.data.returnCode);
                    // this.$router.push('/League');
                }
            })
            .catch((error) => {
                console.log(error);
            });
        },
        async getEpisodeList(){
            var param = 
            {
                sign: this.$store.state.sign,
                ticket: this.$store.state.ticket,
                viewAdult: this.$store.state.isCheckedAdult ? 1 : 0,
                pageNo: this.currentPageNo,
                pageSize: this.currentPageSize,
                wtIdx: this.currWebtoonIdx,
            }
            // console.log(param);
            await this.$Axios.post(`${this.league_host}/challenge/webtoon/episode/list`, param, { headers: { 'Pragma' : 'no-cache', 'Cache-Control': 'no-cache', 'Access-Control-Allow-Origin': '*', 'Content-type': 'application/json', }}).then(async(res) => {
                // console.log('----episode list----');
                // console.log(res.data);
                if (res.data.returnCode === 0) {
                    this.episodeList = res.data.list;
                    this.initPagingData = res.data.page;
                    this.listCount       = res.data.page.listCount;
                    this.pageCount       = res.data.page.pageCount;
                    this.pageNo          = res.data.page.pageNo;
                    this.pageSize        = res.data.page.pageSize;
                    this.totalRecordSize = res.data.page.totalRecordSize;
                    // this.changePage();
                }
                // else{
                //     console.log(res.data.description);
                //     this.$router.push('/');
                // }
            })
            .catch((error) => {
                console.log(error);
            });
        },
        async getEpisodeState(){
            var param = 
            {
                ticket: this.$store.state.ticket,
                sign: this.$store.state.sign,
                viewAdult: this.$store.state.isCheckedAdult ? 1 : 0,

                webtoonIdx: this.currWebtoonIdx,
                episodeIdx: this.currEpisodeIdx,
                // lang: 'KR'
            }
            // console.log(param)
            await this.$Axios.post(`${this.league_host}/challenge/episode/state`, param, { headers: { 'Pragma' : 'no-cache', 'Cache-Control': 'no-cache', 'Access-Control-Allow-Origin': '*', 'Content-type': 'application/json', }}).then(async(res) => {
                // console.log('----episode state----');
                // console.log(res.data);
                if (res.data.returnCode === 0) {
                    this.episodeState = res.data.epData;
                    if(res.data.preEpisodeIdx !== null){
                        this.preEpisodeIdx = {idx : res.data.preEpisodeIdx};
                    }
                    if(res.data.nextEpisodeIdx !== null){
                        this.nextEpisodeIdx = {idx : res.data.nextEpisodeIdx};
                    }
                }
                // else{
                //     this.$router.push('/');
                // }
            })
            .catch((error) => {
                console.log(error);
            });
        },
        async getEpisodeOptimum(){
            var param =
            {
                webtoonidx: this.currWebtoonIdx
            }
            await this.$Axios.post(`${this.league_host}/challenge/episode/nxindex`, param, { headers: { 'Pragma' : 'no-cache', 'Cache-Control': 'no-cache', 'Access-Control-Allow-Origin': '*', 'Content-type': 'application/json', }}).then(async(res) => {
                // console.log(res);
                if(res.data.returnCode === 0){
                    this.episodeOptimum = res.data;
                }
                else{
                    this.$router.push('/');
                }
            })
            .catch((error) => {
                console.log(error);
            });
        },
        getCategoryWebtoonList(){
            var param = 
            {
                sign: this.$store.state.sign,
                ticket: this.$store.state.ticket,
                viewAdult: this.$store.state.isCheckedAdult ? 1 : 0,
                pageNo: 1,
                pageSize: 10,
                // category: this.webtoonInfo.ctCategoryMain,
                category: 0,
                sort: 1, //조회 순  : 0, 업데이트 순  : 1, 별점 순  : 2
                league: 1,
            }
            // console.log(param);
            this.$Axios.post(`${this.league_host}/challenge/webtoon/list`, param, { headers: { 'Pragma' : 'no-cache', 'Cache-Control': 'no-cache', 'Access-Control-Allow-Origin': '*', 'Content-type': 'application/json', }}).then(async(res) => {
                // console.log('--league list--')
                // console.log(res.data);
                if (res.data.returnCode === 0) {
                    this.categoryWebtoonList = res.data.list;
                }
            })
            .catch((error) => {
                console.log(error);
            });
        },
        backList(){
            this.$router.push('/League/Webtoon/Info/' + this.currWebtoonIdx);
        },
        changeEpisode(wtIdx, epIdx){
            var param = 
            {
                ticket: this.$store.state.ticket,
                sign: this.$store.state.sign,
                viewAdult: this.$store.state.isCheckedAdult ? 1 : 0,

                webtoonIdx: wtIdx,
                episodeIdx: epIdx,
                lang: 'KR'
            }
            this.$Axios.post(`${this.league_host}/challenge/episode/state`, param, { headers: { 'Pragma' : 'no-cache', 'Cache-Control': 'no-cache', 'Access-Control-Allow-Origin': '*', 'Content-type': 'application/json', }}).then(async(res) => {
                // console.log('----episode state----');
                // console.log(res);

                if(res.data.epData != null){
                    this.chagneEpisodeInfo = res.data.epData;
                }

                if (res.data.returnCode === 0) {
                    this.$router.push('/League/Webtoon/Viewer/' + wtIdx + '/' + epIdx);
                }
                else if(res.data.returnCode === 503){   //로그인 필요
                    this.isShowLoginPopup = true;
                }
                else{
                    console.log(res.data.dsscription);
                }
            })
            .catch((error) => {
                console.log(error);
            });
        },
        moveSlide(arrow, type){
            switch(type){
                case "categoryWebtoon":
                    if(arrow === 'next' && this.selectedCategoryWebtoonSlideIdx + 5 < this.categoryWebtoonList.length){this.selectedCategoryWebtoonSlideIdx += 1;}
                    else if(arrow === 'prev' && this.selectedCategoryWebtoonSlideIdx - 1 >= 0){this.selectedCategoryWebtoonSlideIdx -= 1;}
                    break;
            }
        },
        moveDetail(item){
            // if(item.adult === 1){
            //     if(this.$store.state.loginCheck === false){
            //         this.isShowLoginPopup = true;
            //     }
            //     else if(!this.$store.state.adult){
            //         var src = this.$store.state.siteConfig.niceUrl + '?email=' + this.$store.state.email;
            //         window.open(src, 'popupChk', 'width=500, height=550, top=100, left=100, fullscreen=no, menubar=no, status=no, toolbar=no, titlebar=no, location=no, scrollbar=no');
            //     }
            //     else{
            //         // this.$router.push('/Webtoon/Info/' + item.englishName);
            //         this.$router.push('/Webtoon/Info/' + item.webToonIndex);
            //     }
            // }else{
            //     // this.$router.push('/Webtoon/Info/' + item.englishName);
            //     this.$router.push('/Webtoon/Info/' + item.webToonIndex);
            // }
            this.$router.push('/League/Webtoon/Info/' + item.ctWebtoonIdx);
        },
        close(popupType){
            switch(popupType){
                case 'Login':
                    this.isShowLoginPopup = false;
                    break;
                case 'Declaration':
                    this.isShowDeclarationPopup = false;
                    break;
                case 'PopupOk':
                    this.isShowPopupOkPopup = false;
                    if(this.PopupOkCallbakPage === 'reload'){
                        location.reload();
                    }
                    else if(this.PopupOkCallbakPage !== ''){
                        this.$router.push(this.PopupOkCallbakPage);
                    }
                    this.PopupOkCallbakPage = '';
                    break;
            }
        },
        changeState(statePopup, state){
            switch(statePopup){
                case 'isOpenMypage':
                    this.isShowMypageState = state;
                    break;
            }
        },
        overlapReport(){
            this.isShowDeclarationPopup = false;
            this.initPopupOkMessage = '이미 해당 웹툰을 신고하셨습니다.';
            // this.PopupOkCallbakPage = '/League';
            this.isShowPopupOkPopup = true;
        },
        successReport(){
            this.isShowDeclarationPopup = false;
            this.initPopupOkMessage = '신고하기가 완료되었습니다.';
            // this.PopupOkCallbakPage = '/League';
            this.isShowPopupOkPopup = true;
        }
    }
}
</script>