<template>
    <div id="service" v-if="initIsShowPopup">
        <div class="terms_box">
            <div class="title">
                <h1>아튜브 서비스 이용약관</h1>
                <img src="@/assets/images/close.svg" alt="닫기" class="close" @click="closePopup">
            </div>
            <div class="article_box">
                <div class="article">
                    <h2>제1조 (목적)</h2>
                    <p>본 약관은 (주)넘버스(전자상거래 사업자)가 운영하는 아튜브 (이하 “회사”라 합니다)에서 제공하는 컨텐츠 서비스(이하 “서비스”)를 이용함에 있어 회사와 회원의 권리/의무 및 책임사항을 규정함을 목적으로 합니다.</p>
                </div>
                <div class="article">
                    <h2>제2조 (용어의 정의)</h2>
                    <p>① 본 약관에서 사용하는 용어의 정의는 다음과 같습니다.</p>
                    <p class="sub_p">1. “사이트”란 (주)넘버스가 각종 웹툰, 출판만화, 도서(이하 “컨텐츠 등”)을 회원에게 제공하기 위하여 컴퓨터 등 정보통신설비를 이용하여 재화 등을 거래할 수 있도록 설정한 가상의 영업장을 말하며, 아울러 본 사이트를 운영하는 사업자의 의미로도 사용합니다.</p>
                    <p class="sub_p">2. “회원”란 “사이트”에 접속하여 이 약관에 따라 “사이트”가 제공하는 서비스를 받는 회원 및 비회원을 통칭하여 말합니다.</p>
                    <p class="sub_p">3. “회원”이란 함은 “사이트”에 개인정보를 제공하여 회원등록을 한 자로서, “사이트”의 정보를 지속적으로 제공받으며, “사이트”가 제공하는 서비스를 계속적으로 이용할 수 있는 자를 말합니다.</p>
                    <p class="sub_p">4. “비회원”’이란 회원에 가입하지 않고 “사이트”가 제공하는 서비스를 이용하는 자를 말합니다.</p>
                    <p class="sub_p">5. “콘텐츠”라 함은 “회사”가 “아튜브”를 통하여 “회원” 또는 “비회원”에게 제공하는 콘텐츠를 의미합니다.</p>
                    <p>② 본 조에서 정의하지 않은 용어에 대해서는 관계 법령, 안내, “회사”의 서비스 정책 등에서 정하는 바에 의하며, 정하지 않은 것은 일반적 관례에 의합니다.</p>
                </div>
                <div class="article">
                    <h2>제3조 (약관의 게시와 개정)</h2>
                    <p>1. 회사는 이 약관을 회원이 그 전부를 확인할 수 있도록 서비스 초기 화면에 게시합니다.</p>
                    <p>2. 회사는 회원이 약관에 동의하기에 앞서 약관에 정하여져 있는 내용 중 청약철회/ 환불조건 등과 같은 중요한 내용을 회원이 이해할 수 있도록 별도의 연결 화면 또는 팝업 화면 등을 제공합니다.</p>
                    <p>3. 회사는 ‘콘텐츠산업진흥법’, ‘전자상거래등에서의소비자보호에관한법률’, ‘약관의규제에관한법률’ 등 관련 법을 위배하지 않는 범위에서 이 약관을 개정할 수 있습니다.</p>
                    <p>4. 회사가 약관을 개정할 경우에는 적용일자 및 개정사유를 명시하여 현행 약관과 함께 “사이트”의 초기화면에 그 적용일자 7일이전부터 적용일자 전일까지 공지합니다. 다만, 회원에게 불리한 약관 개정의 경우에는 최소한 30일 이상의 사전 유예기간을 두고 공지합니다. 이 경우 회사는 개정 전 내용과 개정 후 내용을 명확하게 비교하여 회원이 알기 쉽도록 표시합니다.</p>
                    <p>5. 회사가 전항에 따라 개정약관을 공지 또는 통지하면서 회원에게 전항의 공지기간 내에 의사표시를 하지 않으면 의사표시가 표명된 것으로 본다는 뜻을 명확하게 공지 또는 통지하였음에도 회원이 명시적으로 거부의 의사표시를 하지 아니한 경우 회원이 개정약관에 동의한 것으로 봅니다.</p>
                    <p>6. 회원이 개정약관의 적용에 동의하지 않는 경우는 회사는 개정 약관의 내용을 적용할 수 없으며, 이 경우 회원은 이용계약을 해지할 수 있습니다. 다만, 기존 약관을 적용할 수 없는 특별한 사정이 있는 경우에는 회사는 이용 계약을 해지할 수 있습니다.</p>
                </div>
                <div class="article">
                    <h2>제4조(약관의 해석)</h2>
                    <p>이 약관에서 정하지 아니한 사항과 이 약관의 해석에 관하여는 ‘콘텐츠진흥법’, ‘전자상거래등에서의소비자보호에관한법률’, ‘약관의규제에관한법률’, 문화체육관광부장관이 정하는 ‘콘텐츠회원보호지침’, 기타 관계법령, ‘네이버 이용약관’ 또는 상관례에 따릅니다.</p>
                </div>
                <div class="article">
                    <h2>제5조(회원가입)</h2>
                    <p>1. 이용계약은 회원이 되고자 하는 자(이하 "가입신청자")가 약관의 내용에 대하여 동의를 한 다음 회원가입신청을 하고 회사가 이러한 신청에 대하여 승낙함으로써 체결됩니다.</p>
                    <p>2. 회원으로 가입하여 서비스를 이용하고자 하는 회원은 회사의 사이트를 통해 “회원가입” 메뉴에서 약관 동의 절차를 거친 후 회사 소정의 가입신청양식에 요구하는 사항을 기재하고 '가입' 버튼을 누름으로써 이용을 신청합니다.</p>
                    <p>3. 온라인 가입신청양식에 기재하는 모든 회원정보는 실제와 일치하는 데이터인 것으로 간주됩니다. 고객이 가입신청양식에 실명이나 실제정보를 입력하지 않은 경우에는 법적인 보호를 받을 수 없으며, 회사는 당해 고객에 대하여 서비스의 이용을 제한할 수 있습니다.</p>
                    <p>4. 회사는 회원에 대하여 등급별로 구분하여 이용시간, 이용횟수, 서비스 메뉴 등을 세분하여 이용에 차등을 둘 수 있습니다.</p>
                    <p>5. 회사는 가입신청고객이 관계 법령에서 규정하는 미성년자일 경우에 서비스 별 안내에서 정하는 바에 따라 승낙을 보류할 수 있습니다.</p>
                </div>
                <div class="article">
                    <h2>제6조(회원탈퇴 및 자격상실)</h2>
                    <p>1. 회원은 회사에 언제든지 탈퇴를 요청할 수 있으며 회사는 즉시 회원탈퇴 신청을 접수합니다. 회사는 회원의 탈퇴신청을 빠르 시간 내에 처리하여야 합니다.</p>
                    <p>2. 회원이 이용 계약을 해지하고자 할 때는 회원 본인이 [내정보] 메뉴를 통해 직접 탈퇴 신청하여야 합니다.</p>
                    <p>3. 회원이 이용계약을 해지하는 경우, 회사는 사이트 개인정보보호정책에 따라 회원 기록은 삭제하며, 복구 및 이용이 불가합니다. 또한 회사가 직권으로 이용계약을 해지하고자 하는 경우에는 말소 전에 회원에게 소명의 기회를 부여합니다.</p>
                    <p>4. 회원이 직접 이용계약을 해지하고 탈퇴한 경우에는 사이트 이용중 획득한 모든 아트포인트(무료,우료) 및 암호화폐 자산은 즉시 소멸되며, 복구 및 이용이 불가능합니다.</p>
                    <p>5. 회사는 회원의 이용 신청이 다음의 어느 하나에 따른 사유가 발견되는 경우 승낙하지 않거나 서비스 이용을 제한하는 등의 회원 자격을 상실 시킬 수 있습니다.</p>
                    <p class="check"><span>&#10003;</span> 가입신청자가 이 약관에 의하여 이전에 회원자격을 상실한 적이 있는 경우, 단 회사의 회원 재가입 승낙을 얻은 경우에는 예외로 합니다.</p>
                    <p class="check"><span>&#10003;</span> 실명이 아니거나 타인의 명의를 이용한 경우</p>
                    <p class="check"><span>&#10003;</span> 14세 미만의 아동이 가입 신청자일 경우</p>
                    <p class="check"><span>&#10003;</span> 회사의 정책을 위반하고 부정한 방법으로 이용계약을 체결하거나 부당한 이익을 취했다고 판단될 경우</p>
                    <p class="check"><span>&#10003;</span> 부정한 의도나 목적으로 본 서비스를 이용하고자 하는 것으로 판단되는 경우</p>
                    <p class="check"><span>&#10003;</span> 허위의 정보를 기재하거나, 회사가 제시하는 내용을 기재하지 않은 경우</p>
                    <p class="check"><span>&#10003;</span> 미성년자가 ‘청소년보호법’등 관련 법령에 의해서 이용이 금지되는 유료 서비스를 이용하고자 하는 경우</p>
                    <p class="check"><span>&#10003;</span> 서비스 관련 설비의 여유가 없거나 기술상 또는 업무수행상 지장이 있는 경우</p>
                    <p class="check"><span>&#10003;</span> 기타 규정한 제반 사항을 위반하며 신청한 경우</p>
                    <p>6. 회사는 정보통신망 이용촉진 및 정보보호 등에 관한 법률 제29조 (개인정보의 파기)에 따라 1년의 기간 동안 사이트 이용 기록이 없는 회원의 개인정보를 보호하기 위해 1년 후 운영 데이터와 별도 분리 보관하며, 분리 보관 시점을 기준일로 4년 후 파기 합니다. 회원은 개인정보의 파기 이전 총 5년의 기간 중 언제든지 사이트 및 관리자를 통해 휴면 계정을 해제할 수 있습니다.</p>
                </div>
                <div class="article">
                    <h2>제7조(회원에 대한 통지)</h2>
                    <p>1. 회사는 회원에 대한 통지를 하는 경우 회원이 등록한 전자우편 주소 등으로 통지합니다.</p>
                    <p>2. 회사는 회원 전체에 대해서 통지하는 경우 7일 이상 “아튜브”내에 게시하거나 팝업 화면 등을 제시함으로써 개별 통지에 갈음할 수 있습니다. 다만, 회원 본인의 거래와 관련하여 중대한 영향을 미치는 사항에 대해서는 개별 통지합니다.</p>
                </div>
                <div class="article">
                    <h2>제8조(개인정보보호)</h2>
                    <p>1. 회사는 "정보통신망법" 등 관계 법령이 정하는 바에 따라 회원의 개인정보를 보호하기 위해 노력합니다. 개인정보의 보호 및 사용에 대해서는 관련법 및 회사의 개인정보취급방침이 적용됩니다. 다만, 회사의 공식 사이트 이외의 링크된 사이트에서는 회사의 개인정보취급방침이 적용되지 않습니다.</p>
                    <p>2. 회원의 아이디(ID) 및 비밀번호의 관리책임은 회원에게 있습니다. 이를 소홀이 관리하여 발생하는 서비스 이용상의 손해 또는 제3자에 의한 부정이용 등에 대한 책임은 회원에게 있으며 회사는 그에 대한 책임을 지지 않습니다.</p>
                    <p>3. 회사는 이용자의 정보수집시 구매계약 이행에 필요한 최소한의 정보를 수집합니다.</p>
                    <p class="bar">- 이메일 주소</p>
                    <p class="bar">- 회원비밀번호</p>
                    <p>4. 회사는 제1항에도 불구하고, 성인인증, 실명인증 등을 위하여 필요한 경우에는 다음 각호의 개인정보를 추가로 수집 또는 이용할 수 있습니다.</p>
                    <p class="bar">- 성명</p>
                    <p class="bar">- 성별</p>
                    <p class="bar">- 생년월일</p>
                    <p class="bar">- CI</p>
                </div>
                <div class="article">
                    <h2>제9조(서비스의 제공 및 변경)</h2>
                    <p>1. 회사는 웹툰, 만화 등의 콘텐츠를 저작권자와의 직접 계약 또는 저작권물의 유통을 총판하는 유통사와의 계약을 통해 제공합니다.</p>
                    <p>2. 회사에서 제공하는 콘텐츠는 연령에 대한 등급 및 제한이 있을 수 있습니다.</p>
                    <p>3. 회사가 제공하는 콘텐츠는 저작권자와의 계약 종료, 변경 또는 신규 체결되는 계약의 변경 사항에 따라 콘텐츠의 내용 및 제공방법, 제공 일자가 변경될 수 있습니다. </p>
                    <p>4. 회사에서 제공하는 모든 콘텐츠는 회원이 별도의 재화를 통해 이용료 및 이용기간을 구매하더라도 회원에게는 콘텐츠에 대한 이용권만 부여되며, 지적재산권 등 기타 권리는 이전되지 않습니다.</p>
                    <p>5. 사이트를 통해 구매하거나 소장한 콘텐츠를 삭제한 경우에는 복구가 불가능하며, 본인이 삭제하지 않은 경우에도 해당 콘텐츠의 저작권자와의 계약 조건의 변경에 따라 서비스 제공이 불가능한 경우 회사에서 콘텐츠를 삭제할 수 있습니다.</p>
                    <p>6. 회원이 게재한 게시물 또는 콘텐츠에 대한 백업은 본인이 수행해야 하며, 당사는 회원이 생성한 콘텐츠에 대한 백업 의무가 없습니다.</p>
                    <p>7. 회원이 구매 또는 소유한 콘텐츠의 계약 종료 또는 변경에 의해 서비스 제공이 불가능한 경우 회사는 이를 회원에게 고지하며, 별도의 보상이나 재화의 환불은 이루어 지지 않습니다.</p>
                    <p>8. 서비스 이용은 회사의 업무상 또는 기술상 특별한 지장이 없는 한 연중무휴, 1일 24시간 운영을 원칙으로 합니다. 단, 회사는 시스템 정기점검, 증설 및 교체를 위해 회사가 정한 날이나 시간에 서비스를 일시 중단할 수 있으며, 예정되어 있는 작업으로 인한 서비스 일시 중단은 사이트를 통해 사전에 공지합니다.</p>
                </div>
                <div class="article">
                    <h2>제10조(서비스의 중단)</h2>
                    <p>1. 회사는 긴급한 시스템 점검, 증설 및 교체, 설비의 장애, 서비스 이용의 폭주, 국가비상사태, 정전 등 부득이한 사유가 발생한 경우 사전 예고 없이 일시적으로 서비스의 전부 또는 일부를 중단할 수 있습니다.</p>
                    <p>2. 회사가 제공하기로 한 계약을 컨텐츠 저작권자와의 계약 종료 또는 기술적 사양의 사유로 서비스 제공이 어려울 시 불가피하게 서비스를 중단할 수 있습니다.</p>
                    <p>3. 회사의 양도, 합병, 분할, 영업 폐지 등 경영상의 문제로 원활한 서비스를 공급하지 못할 시 서비스 일부 또는 전부를 중단할 수 있습니다.</p>
                    <p>4. 위 사항으로 인해 회사가 서비스의 일부 또는 전부를 중단할 시 30일 이전에 홈페이지를 통해 공지하는 것으로 원칙으로 하며, 부득이한 사정으로 통보가 어려울 경우에는 SMS 또는 전자우편 등을 통해 통보합니다.</p>
                    <p>5. 회사는 서비스 중단의 사유가 회사에 있다고 인정되지 않은 이상 회원에게 별도의 보상이나 반환은 이루어지지 않습니다.</p>
                </div>
                <div class="article">
                    <h2>제11조(회사의 의무)</h2>
                    <p>1. 회사는 법령과 이 약관이 금지하거나 공서양속에 반하는 행위를 하지 않으며 이 약관이 정하는 바에 따라 지속적이고, 안정적으로 재화/용역을 제공하는데 최선을 다하여야 합니다.</p>
                    <p>2. 회사는 회원이 안전하게 인터넷 서비스를 이용할 수 있도록 회원의 개인정보(신용정보 포함) 보호를 위한 보안 시스템을 최대한 갖추어야 합니다.</p>
                    <p>3. 회사가 상품이나 용역에 대하여 「표시/광고의공정화에관한법률」 제3조 소정의 부당한 표시/광고행위를 함으로써 회원이 손해를 입은 때에는 이를 배상할 책임을 집니다.</p>
                    <p>4. 회사는 회원이 원하지 않는 영리목적의 광고성 전자우편을 발송하지 않습니다.</p>
                    <p>5. 회사는 계약의 체결, 변경 해지 등 회원과의 계약 관련 절차에 있어 편의를 제공하도록 노력하여야 합니다.</p>
                </div>
                <div class="article">
                    <h2>제12조(회원의 의무)</h2>
                    <p>① 회원은 아래 각 호에서 정한 행위를 해서는 안됩니다.</p>
                    <p class="sub_p">1. 신청 또는 변경 시 허위 내용의 등록</p>
                    <p class="sub_p">2. 타인의 정보 도용</p>
                    <p class="sub_p">3. 음란한 정보 등 공서양속 및 법령에 위반되는 내용의 정보 등을 발송 또는 게시</p>
                    <p class="sub_p">4. 소스 코드 추출 등의 방법으로 콘텐츠를 무단 복제, 유출, 분해, 변형</p>
                    <p class="sub_p">5. 회사 또는 제3자의 저작권, 상표권, 특허권 등의 지식재산권, 명예권, 사생활권, 기타 법령상 또는 계약상의 권리를 침해하는 경우</p>
                    <p class="sub_p">6. 회사 서비스 계정에 대한 매매, 양도</p>
                    <p class="sub_p">7. 타인으로부터 아트포인트 및 서비스 재화 대행 구매 및 판매</p>
                    <p>② 12조 1항의 의무사항을 위반할 시 계정 정지 및 재가입 불가, 법적인 손해 배상 책임을 물을 수 있습니다.</p>
                </div>
                <div class="article">
                    <h2>제13조(요금 및 유료 정보)</h2>
                    <p>1. 회사가 제공하는 콘텐츠는 유료와 무료로 구분되며, 유료 콘텐츠의 경우 해당 콘텐츠 정보에 명시된 재화 및 유료서비스를 별도의 요금 지불을 통해 구매한 후 이용이 가능합니다.</p>
                    <p>2. 회사가 제공하는 유료 콘텐츠를 이용하기 위한 재화(아트포인트) 이용 회원은 특별한 사유가 없는 한 결제일로 부터 7일 이후에는 서비스 중도 해지 또는 환불이 불가합니다.
                    <p>3. 충전일을 기준으로 아트포인트의 경우 5년, 무료로 지급된 재화(보너스포인트) 등의 경우 1년 동안 이용하지 않을 경우 각 기간 종료일 다음날 소멸합니다.
                    <p>4. 회사는 위 3항에 따라 소멸시효를 적용하나 이용 기록이 없는 휴면 계정의 경우에만 소멸시효를 적용, 운영합니다. 휴면 계정 즉 1년간 이용 기록이 없는 사용자의 경우 휴면
                    <p>계정 적용 즉시 “보너스포인트”가 소멸되며, 휴면 계정 적용 이후 4년 경과 시까지 별도의 휴면 계정 해제 요청이 없을 시 회원 개인정보 파기와 함께 “아트포인트”도 소멸됩니다.
                    <p>5. 아트포인트의 경우 총 5년의 유예 기간을 두고 휴면 계정 개인정보의 파기 절차를 이행함에 따라 소멸시키는 것이며, 지속적으로 아튜브 서비스를 이용하는 일반 계정의 경우 해당되지 않습니다.
                    <p>6. 회원이 약관을 위반하거나 불법적, 비정상적인 행위로 인해 회원의 서비스에 제한이 생긴 경우 이전 결제된 재화에 대해서는 환불이 불가능합니다.</p>
                </div>
                <div class="article">
                    <h2>제14조(대금결제)</h2>
                    <p>1. 콘텐츠에 대한 구매 대금은 원칙적으로 온라인사이트에서 정하는 방법과 정책에 따라 부과되며 납부 방법 역시 정해진 방법에 따라 납부해야 합니다.</p>
                    <p>2. 회사의 정책 및 결제 업체의 정책, 정부의 방침에 따라 각 결제 수단별로 결제 한도가 부여될 수 있습니다.</p>
                    <p>3. 아트포인트 또는 보너스포인트 구입 시 회원이 구매 대금을 외화 또는 암호화폐로 지급 하는 경우에는 환율 및 시세의 변동 등으로 실제 회원에게 청구되는 금액이 회원이 구매시점에 회사에 지불할 것으로 예상했던 금액과 달라질 수 있습니다</p>
                </div>
                <div class="article">
                    <h2>제15조(서비스 수수료)</h2>
                    <p>1. 회사는 회원에게 출금 서비스를 제공하는 대가로 수수료를 부과합니다. 수수료는 회사의 홈페이지 이용방법에 명시되어 있습니다.</p>
                </div>
                <div class="article">
                    <h2>제16조(이용요금 등의 환불 및 기타)</h2>
                    <p>1. 회사와 유료 서비스 이용에 관한 계약을 체결한 경우 회원은 구매일로부터 7일 이내에청약의 철회를 신청할 수 있습니다. 단, 유료 서비스를 통해 콘텐츠를 사용한 내역이 있는 경우 디지털 콘텐츠 특성 상 재화의 효용가치를 사용한 것으로 간주되어 결제 취소 및 환불이 처리되지 않습니다.</p>
                    <p>2. 회사는 대금을 환급함에 있어서 회원이 신용카드 또는 전자화폐 등의 결제수단으로 컨텐츠 등의 대금을 지급한 때에는 지체없이 당해 결제수단을 제공한 사업자로 하여금 재화 등의 대금의 청구를 정지 또는 취소하도록 요청합니다.</p>
                    <p>3. 암호화폐를 통해 결제한 경우, 처리결과에 따라 환불이 필요한 경우 아트포인트 또는 코인로 환불됩니다. 아트포인트를 출금할 경우에 필요한 수수료는 회원이 부담합니다.</p>
                    <p>4. 캐시의 환불의 경우 환불 수수료(요청한 금액의 10%)를 환불할 수 있으나, 회사의 귀책으로 인한 환불의 경우에는 환불 수수료를 공제하지 않습니다.</p>
                    <p>5. 회원의 환불 및 의사 표시에 대하여 회사가 회신한 날로부터 영업일 기준 15일 이내에 환불 처리되며, 환불이 불가할 경우에는 사전에 고지합니다.</p>
                    <p>6. 청약철회 등의 경우 콘텐츠 등의 반환에 필요한 비용은 회원이 부담합니다. 단, 컨텐츠 등의 내용이 표시/광고 내용과 다르거나 계약내용과 다르게 이행되어 청약철회등을 하는 경우 반환에 필요한 비용은 회사가 부담합니다.</p>
                </div>
                <div class="article">
                    <h2>제17조(저작권의 귀속 및 이용제한)</h2>
                    <p>1. 회사가 작성한 저작물에 대한 저작권 기타 지적재산권은 회사에 귀속합니다.</p>
                    <p>2. 회원은 서비스를 이용함으로써 얻은 정보 중 회사에게 지적재산권이 귀속된 정보를 회사의 사전 승낙없이 복제, 송신, 출판, 배포, 방송 기타 방법에 의하여 영리목적으로 이용하거나 제 3자에게 이용하게 하여서는 안됩니다.</p>
                    <p>3. 회사는 약정에 따라 회원에게 귀속된 저작권을 사용하는 경우 당해 회원에게 통보하여야 합니다.</p>
                </div>
                <div class="article">
                    <h2>제18(면책사항)</h2>
                    <p>1. 회사는 천재지변, 전쟁, 기간통신사업자의 서비스 중지 및 법령이나 정부 정책의 변경 등 기타 이에 준하는 불가항력으로 인하여 서비스를 제공할 수 없는 경우에는 서비스 제공에 대한 책임이 면제됩니다.</p>
                    <p>2. 회사는 서비스용 설비의 보수, 교체, 정기점검, 공사 등 부득이한 사유로 발생한 손해에 대한 책임이 면제됩니다.</p>
                    <p>3. 회사는 회원의 컴퓨터 오류에 의해 손해가 발생한 경우, 또는 회원이 신상정보 및 전자우편 주소를 부실하게 기재하여 손해가 발생한 경우 책임을 지지 않습니다.</p>
                    <p>4. 회사는 회원이 서비스에 게재한 각종 정보, 자료, 사실의 신뢰도, 정확성 등 내용에 대하여 책임을 지지 않으며, 회원 상호간 및 회원과 제 3자 상호 간에 서비스를 매개로 발생한 분쟁에 대해 개입할 의무가 없고, 이로 인한 손해를 배상할 책임도 없습니다.</p>
                    <p>5. 회사는 회원의 게시물을 등록 전에 사전심사 하거나 상시적으로 게시물의 내용을 확인 또는 검토하여야 할 의무가 없으며, 그 결과에 대한 책임지지 않습니다.</p>
                    <p>6. 회사는 회사의 사이트에 연결되거나 제휴한 업체(타 온라인사이트)에 포함되어 있는 내용의 유효성, 적합성, 법적 합리성, 저작권 준수 여부 등에 책임을 지지 않으며, 이로 인한 어떠한 손해에 대하여도 책임을 지지 않습니다.</p>
                    <p>7. 회사는 회원이 서비스를 이용하면서 얻은 자료로 인한 물리적, 정신적 피해에 대하여 보상할 책임을 지지 않습니다.</p>
                    <p>8. 회사는 회원 상호간 및 회원과 제 3자 상호간에 서비스를 매개로 발생한 분쟁에 대하여 개입할 의무가 없으며, 이에 다른 손해를 배상할 책임도 없습니다.</p>
                </div>
                <div class="article">
                    <h2>제19(분쟁의 해결)</h2>
                    <p>1. 회사와 회원은 서비스와 관련하여 발생한 분쟁을 원만하게 해결하기 위하여 필요한 모든 노력을 하여야 합니다.</p>
                    <p>2. 회사는 회원으로부터 제출되는 불만사항 및 의견은 우선적으로 그 사항을 처리합니다. 다만, 신속한 처리가 곤란한 경우에는 이용자에게 그 사유와 처리일정을 즉시 통보해드립니다.</p>
                </div>
                <div class="article">
                    <h2>제20조(판권 및 준거법)</h2>
                    <p>1. 회사와 이용자간에 발생한 전자상거래 분쟁에 관한 소송은 제소 당시의 회원의 주소에 의하고, 주소가 없는 경우에는 거소를 관할하는 지방법원의 전속관할로 합니다. 단, 제소 당시 회원의 주소 또는 거소가 분명하지 않거나 외국 거주자일 경우에는 민사소송법상의 관할법원에 제기합니다.</p>
                    <p>2. 회사와 이용자간에 제기된 전자상거래 소송에는 대한민국 법률을 적용합니다.</p>
                </div>
                <div class="article last">
                    <h2>약관공표</h2>
                    <p>공표 : 2021년 9월 28일</p>
                    <p>적용 : 2021년 9월 28일</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Terms',
    data(){
        return {
        }
    },
    props: {
        initIsShowPopup: {
            type: Boolean,
            default: false
        }
    },
    created() {
    },
    mounted(){
    },
    beforeDestroy() {
	},
    methods: {
        closePopup(){
            this.$emit('close');
        }
    },
}
</script>