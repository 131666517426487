<template>
<div class="wrap" v-if="isWebview">
    <div class="sign-loading mobile">
        <div class="loading-box">
            <div class="loading-box-top">
                <img src="@/assets/images/login/sns_loading_logo.png" >
                <p>잠시만 기다려 주세요</p>
            </div>
            <div class="loading-box-bottom">
                <!-- <img src="@/assets/images/login/sns_loading_arrow.png" class="loading_arrow_around"> -->
                <svg class="spinner" width="65px" height="65px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
                    <circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
                </svg>
                <p>SNS 인증을 완료하고 있습니다.</p>
                <p>이 과정은 자동으로 진행됩니다.</p>
            </div>
        </div>
    </div>
</div>
</template>

<script>

export default {
    name: 'OAuthCheck',
    pageCategory: 'Member',
    pageTitle: 'SNS 인증 확인',
    components: {
    },
    data(){
        return{
            isWebview: false,

            resType: -1,
            resTicket: '',
            resSign: '',
            resEmail: '',
            resSocial: 0,

            isExistRequestPageUrl: false,
            requestPageUrl: '/',
            requestIsShowPopup: false,

            timerAppApi: null,
        }
    },
    created(){
        this.$store.commit("setSiteConfig", this.host);
        this.isWebview = this.isArtubeWebview();
        // this.isWebview = this.isMobile();
        if(!this.isWebview){
            alert('잘못된 접근 입니다.');
            this.$router.push('/');
            return;
        }
    },
    async mounted(){
        
        if(this.$route.params.type){
            this.resType = Number(this.$route.params.type);

            if(this.resType === 3){ //email 필요
                if(this.$route.params.email){
                    this.resEmail = this.$route.params.email;
                }
                else{
                    this.$router.push('/M/Member/Login');
                }
            }
            else if(this.resType === 0 || this.resType === 1){ //ticket, sign 필요
                if(this.$route.params.ticket){
                    this.resTicket = this.$route.params.ticket;
                }
                else{
                    this.$router.push('/M/Member/Login');
                }
                if(this.$route.params.sign){
                    this.resSign = this.$route.params.sign;
                }
                else{
                    this.$router.push('/M/Member/Login');
                }
                if(this.$route.params.social){
                    this.resSocial = this.$route.params.social;
                }
                else{
                    this.$router.push('/M/Member/Login');
                }
            }
            else{
                this.$router.push('/M/Member/Login');
            }
        }
        else{
            this.$router.push('/M/Member/Login');
        }

        // await this.getRequestPage();
        this.checkCallBackData();
    },
    beforeDestroy(){
        clearInterval(this.timerAppApi);
    },
    methods:{
        async getRequestPage(){
            // console.log('--Call getRequestPage--');
            // console.log(this.$store.state.reqOAuthKey);
            var param = 
            {
                key: this.$store.state.reqOAuthKey,
            }
            await this.$Axios.post(`${this.host}/frontpos/load`, param, { headers: { 'Pragma' : 'no-cache', 'Cache-Control': 'no-cache', 'Access-Control-Allow-Origin': '*', 'Content-type': 'application/json', }}).then(async(res) => {
                // console.log('--FrontPosLoad--');
                // console.log(res.data);
                if (res.data.returnCode === 0) {
                    this.requestPageUrl = res.data.url;
                    this.requestIsShowPopup = res.data.popup === 1 ? true : false;
                    this.isExistRequestPageUrl = true;
                } else {
                    this.requestPageUrl = '/'
                }
                this.$store.state.reqOAuthKey = '';
                this.$store.state.reqOAuthIsPopup = false;
            })
            .catch((error) => {
                console.log(error);
                return null;
            });
        },
        async checkCallBackData(){
            var that = this;

            if(this.resType === 0){     //로그인
                if(this.resTicket !== '' && this.resSign !== ''){
                    // await this.$store.commit("loginTicket", this.resTicket);
                    // await this.$store.commit("loginSign", this.resSign);
                    this.timerAppApi = setInterval(()=>{
                        window.appApi("loginSuccess", {
                            returnCode: 0,
                            ticket: that.resTicket,
                            sign: that.resSign,
                            social: that.resSocial
                        })
                    }, 1000)

                    // window.appApi = function (type, params) {
                    //     if (type === 'loadingBar') {
                    //         const { visible } = params;
                    //         postMessage_rn(type, params);
                    //     } else if (type === 'loginSuccess') {
                    //         // returnCode: 0 - 성공, 41 - 회원정보 없음,  44 - 블록된 회원, 46 - 탈퇴한 회원, 53 - 일반 회원이 아님. 소셜 회원, 73 - 티켓/사인 생성에 실패, 91 - 패스워드 5회 오류, 92 - 패스워드가 틀림
                    //         // social: 소셜번호(0 - 아튜브사이트 1 - 구글 3 - 페이스북 4 - 네이버 5 - 카카오)
                    //         // 소셜번호 0(아튜브사이트)인경우 email, password 반환
                    //         const { returnCode, ticket, sign, social, email, password } = params;
                    //         postMessage_rn(type, params);
                    //     }
                    // }
                } 
                // else {
                //     await this.$store.commit("setIsOAuthRedirectPage");
                //     await this.$store.commit("setIsOAuthShowLoginPopup", this.requestIsShowPopup);
                // }
            }
            // else if(this.resType === 1){    //회원가입
            //     await this.$store.commit("loginTicket", this.resTicket);
            //     await this.$store.commit("loginSign", this.resSign);
            //     this.$router.push('/Signup/' + this.resTicket + '/' + this.resSign);
            //     return;
            // }
            // else if(this.resType === 2){    //닫기
            //     await this.$store.commit("setIsOAuthRedirectPage");
            //     await this.$store.commit("setIsOAuthShowLoginPopup", this.requestIsShowPopup);
            // }
            // else if(this.resType === 3){    //중복 회원 존재시 해당 email 로 로그인 팝업창 띄움
            //     await this.$store.commit("setIsOAuthRedirectPage");
            //     await this.$store.commit("setIsOAuthShowLoginPopup", this.requestIsShowPopup);
            //     await this.$store.commit("setCallbackOAuthEmail", this.resEmail)
            // }

            // if(this.isExistRequestPageUrl){
            //     location.href = this.requestPageUrl;
            // }
            // else {
            //     this.$router.push('/');
            // }
        }
    }
}
</script>