<template>
    <div id="competition">
        <Header :initHeaderType="initHeaderType" />
        <Login />
        <div class="competition_box">
            <img src="@/assets/images/competition.png" class="bg"/>
            <img src="@/assets/images/competition_font.png" class="image_font" />
            <h6 class="term">응모기간</h6>
            <h6 class="term_day">2021.10.14 - 2022.01.28</h6>
            <div class="notice-wrap">
                <div class="notice-box">
                    <p>
                        안녕하세요 아튜브팀 입니다.<br class="enter-force" />
                        제1회 아튜브 웹툰 공모전 마감일이 12월 14일까지 였으나, <br />
                        보다 많은 참여와 수준높은 응모작 준비를 위한 시간을 드리기 위해 <br />
                        접수기간을 1월 28일 금요일 24시까지 46일 연장하게 되었음을 <br />
                        알려 드립니다.<br />
                    </p>

                    <div class="notice-table">
                        <div class="notice-col">
                            <div class="col-title">
                                <p>- 응모기간 : </p>
                            </div>
                            <div class="col-info">
                                <p>2021년 10월 14일(화) ~ </p>
                                <p>2022년 1월 28일(금)</p>
                            </div>
                        </div>
                        <div class="notice-col">
                            <div class="col-title">
                                <p>- 수상작 발표 : </p>
                            </div>
                            <div class="col-info">
                                <p>2022년 2월 18일(금)</p>
                            </div>
                        </div>
                        <div class="notice-col">
                            <div class="col-title">
                                <p>- 이메일 접수 : </p>
                            </div>
                            <div class="col-info">
                                <p>platform.artube@gmail.com</p>
                            </div>
                        </div>
                    </div>
                    <p>
                        기존 접수 마감일정에 맞추어 참가하셨던 참여자분들 중 <br/>
                        제출한 작품을 수정, 보완 하시고 싶으신 분은 <br/>
                        수정 후 접수 이메일로 다시 제출 부탁드리겠습니다. <br/>
                        아울러 이번 공모전에 참여해주시고 관심가져주신 분들께 <br/>
                        양해의 말씀을 드리며, 변경 일정에 맞추어 더욱 성공적인 공모전이 <br/>
                        될 수 있도록 노력을 다하겠습니다.<br class="enter-force"/><br class="enter-force"/>
                        감사합니다.
                    </p>
                </div>
            </div>
            <p class="gray_font"><span>경력, 장르 무관!</span> 누구나 작가가 될 수 있는 기회!</p>
            <h2 class="red_font">총 3,000만원 + α 이상의 혜택까지!</h2>
            <div class="result_box clearfix">
                <div class="gold_medal">
                    <p>대상</p>
                    <p>1,500만원</p>
                </div>
                <div class="other_medal">
                    <div class="silver_medal">
                        <p>최우수상 1명</p>
                        <p>500만원</p>
                    </div>
                    <div class="bronze_medal">
                        <p>우수상 2명</p>
                        <p>300만원</p>
                    </div>
                    <div class="no_medal">
                        <p>장려상 4명</p>
                        <p>100만원</p>
                    </div>
                </div>
            </div>
            <a href="mailto:platform.artube@gmail.com" target="_blank" class="support">이메일 지원하기</a>
            <div class="reference">
                <p>·  이메일 접수 : platform.artube@gmail.com</p>
                <p>·  공모전 관련 상세안내는 밑 세부사항 안내를 참고해주시기 바랍니다.</p>
                <p>·  접수기간을 반드시 준수해 주세요. 마감일은 접속 지연 현상이 발생할 수 있으므로 시간 여유를 두고<br>등록해주시기 바랍니다.</p>
                <p>·  원고 수정은 접수기간 내에만 가능합니다 [접수 마감 이후에는 수정 불가]</p>
            </div>
            <div class="detail_box">
                <h3>세부사항 안내</h3>
                <div class="one">
                    <p class="title">1. 공모자격</p>
                    <p class="contents">-  만 18세 이상</p>
                    <p class="contents">-  신인, 기성 작가 제한 없음</p>
                    <p class="contents">-  상업적 이용 혹은 정식 계약이 된 작품 응모 불가</p>
                    <p class="contents">-  표절, 트레이싱, 무단 도용 등의 저작권 관련 문제는 제외</p>
                    <p class="contents">-  2편 이상의 작품 접수 가능</p>
                    <p class="contents">-  공모전에 접수된 원고와 모든 서류는 공모전 종료 후에 별도의 반환 없이 일괄 폐기합니다.</p>
                    <p class="contents">-  상금은 제세공과금을 제외하고 지급됩니다.</p>
                </div>
                <div class="two">
                    <p class="title">2. 공모부문</p>
                    <p class="contents">- 웹툰, 웹소설</p>
                    <p class="contents">- 성인 / 액션 / 느와르 / 로맨스 / 로판 등 장르불문 지원 가능</p>
                </div>
                <div class="three">
                    <p class="title">3. 공모전 진행방식</p>
                    <p class="contents">- 세부 진행 일정은 아튜브웹툰 사정에 따라 변경될 수 있습니다.</p>
                    <p class="contents">- 연재 및 매니지먼트는 아튜브웹툰 기본 운영방침에 따라 추후 협의 예정입니다.</p>
                    <p class="contents">- 작품 심사는 아튜브웹툰 편집부 내부 심사 기준을 따릅니다.</p>
                    <p class="contents">- 수상자 최종 고지에 앞서 작가 개별 인터뷰가 진행됩니다.(이메일)</p>
                    <p class="contents">- 인터뷰에서 정식 연재 의사 타진 및 연재 가능 여부 점검 후 최종 수상이 결정됩니다.</p>
                    <p class="contents">- 결과는 아튜브웹툰를 통해 발표되며, 접수 메일로 개별 연락 예정입니다.</p>
                </div>
                <div class="four">
                    <p class="title">4. 응모기간 및 수상작 발표</p>
                    <p class="contents">- 응모기간 : 2021년 10월 14일 ~ 2022년 01월 28일</p>
                    <p class="contents">- 수상작 발표 : 2022년 02월 18일(조정가능)</p>
                </div>
                <div class="five">
                    <p class="title">5. 지원방법</p>
                    <div>
                        <p class="contents">[접수 방법]</p>
                        <p class="contents">- 이메일 접수(platform.artube@gmail.com)</p>
                        <p class="contents">- 제출 시간 : 접수 시작일 00시 00분 00초부터 접수 마감일 23시 59분 59초까지</p>
                        <p class="contents tip">※ 마감 시간 이후 접수가 불가합니다.</p>
                    </div>
                    <div>
                        <p class="contents">[웹툰 부문 출품 규격]</p>
                        <p class="contents">- 필수 자료: 완성 원고 3화 분량, 추가 자료[자유 형식의 시놉시스와 캐릭터 시트]</p>
                        <p class="contents tip">※ 웹툰플랫폼 연재 경력 1년 이상 또는 50화 이상 연재한 작가 : 2,3화는 콘티로 접수 가능.</p>
                        <p class="contents tip">단, 글/그림 작가가 다른 경우 각각 연재 경력 1년 이상 또는 50화 이상 연재한 작가여야함</p>
                        <p class="contents">- 원고 형식 : 스크롤 뷰 / 화당 컷 수 제한 없음 / 가로 690 PX / JPG 포맷</p>
                    </div>
                    <div>
                        <p class="contents">[웹소설 부문 출품 규격]</p>
                        <p class="contents">- 각 회차별 최소 글자수는 공백 포함 5,000자 이상입니다.</p>
                        <p class="contents tip">(소설 본문이 아닌 텍스트는 글자수에서 제외)</p>
                        <p class="contents">- 1화당 공백 미포함 3,000 자 이상(프롤로그 제외), 30화 이상 분량</p>
                        <p class="contents">- 선정작은 웹소설 바탕의 IP 협업 제안 예정</p>
                    </div>
                    <div>
                        <p class="contents">[심사기준]
                        <p class="contents">- 창의성 : 스토리 및 작화에 담긴 고유의 개성</p>
                        <p class="contents">- 대중성 : 일반 대중이 몰입하여 감상하기에 충분한 재미 요소</p>
                        <p class="contents">- 완성도 : 장르적 특정에 적합한 연출과 전개 표현</p>
                    </div>
                    <div>
                        <p class="contents">[유의사항]</p>
                        <p class="contents">수상작은 정식 연재를 전제로 하며, 연재 의사가 없거나 기타 사정으로 연재 계약이 체결되지 않은 경우</p>
                        <p class="contents">최종 선정이 불가함. 다음과 같은 경우 응모가 불가하며, 결격 사유 확인 시 수상 취소 가능.</p>
                        <p class="contents">· 표절 / 트레이싱 / 무단도용 등 다른 이의 지적재산권을 침해한 것으로 확인되는 경우</p>
                        <p class="contents">· 종이책,전자책으로 정식 출판되거나 연재 등의 형식으로 국내외에서 유료 판매된 작품</p>
                        <p class="contents tip2">(본인의 작품을 독립 출판한 경우 해당 없음)</p>
                        <p class="contents">· 종이책, 전자책, 영상 판권, 매니지먼트 등 국내외 타사와 이미 계약이 체결된 작품</p>
                        <p class="contents">· 국내외 타 플랫폼 및 공모전에서 이미 수상한 작품</p>
                        <p class="contents">· 타인의 ID와 개인정보로 작품을 제출한 경우</p>
                        <p class="contents">· 결격 사유로 수상이 취소되는 경우 기 지급된 상금이 전액반환 조치 될 수 있습니다.</p>
                        <p class="contents">· 작가정보에 기재된 내용이 사실과 다를 경우</p>
                        <p class="contents">· 공모전 운영원칙에 어극나는 불공정 행위가 발견될 경우</p>
                    </div>
                </div>
                <div class="six">
                    <p class="title">6. 문의처</p>
                    <p class="contents">platform.artube@gmail.com</p>
                </div>
            </div>
            <div class="detail_box">
                <h3>[FAQ]</h3>
                <div class="one">
                    <p class="title">Q.지원 자격이 어떻게 되나요?</p>
                    <p class="contents">만 18세 이상이면 연재 경력 상관 없이 누구나 참여 가능합니다.<br>(참여 불가 조건 유의사항 참고)</p>
                </div>
                <div>
                    <p class="title">Q.2,3화를 콘티로 접수할 수 있는 경력 작가의 기준은 무엇인가요?</p>
                    <p class="contents">웹툰 플랫폼에서 정식 연재로 1년 이상 연재한 경력 기준입니다.<br>(단,현재 원고가 게시되어 확인할 수 있는 작품에 한 함)</p>
                </div>
                <div>
                    <p class="title">Q.글/그림 작가 중 한 쪽만 경력 1년 이상이어도 2,3화를 콘티로 제출 할 수 있나요?</p>
                    <p class="contents">각각 연재 경력 1년 이상이어야 콘티로 제출이 가능합니다.</p>
                </div>
                <div>
                    <p class="title">Q.시놉시스와 캐릭터시트는 어떻게 작성해야 하나요?</p>
                    <p class="contents">시놉시스는 결말까지의 전체 스토리가 요약되어 담겨있어야 하며, 캐릭터시트에는<br>웹툰에 등장하는 주요 캐릭터들의 이미지와 설명을 담아 주시면 됩니다.<br>파일 형식은 시놉시스는 word / hwp / ppt / pdf를, 캐릭터시트는 jpg / pdf 를 권장드립니다.</p>
                </div>
                <div>
                    <p class="title">Q.수상작은 몇 화 분량으로 언제부터 아튜브웹툰에서 연재하게 되나요?</p>
                    <p class="contents">수상작의 연재 기간과 시기는 아튜브퉵툰 편집부와 전체 계획을 검토하고 현의해 진행하며,<br>결과 발표 후 6개월 이내 오픈을 목표로 합니다.</p>
                </div>
                <div>
                    <p class="title">Q.수상작의 경우에도, 아튜브웹툰 연재 시 고료가 별도 지급되나요?</p>
                    <p class="contents">네. 정식 연재 시 아튜브웹툰의 고료 기준에 따라 별도로 지급됩니다.</p>
                </div>
                <div>
                    <p class="title">Q.소재/장르에 제한이 있나요?</p>
                    <p class="contents">창작의 영역에 제한을 두지 않습니다.</p>
                </div>
                <div>
                    <p class="title">Q.상금은 세전 금액인가요?</p>
                    <p class="contents">네. 제세공과금이 제외되고 지급됩니다.</p>
                </div>
                <div>
                    <p class="title">Q.공모전에 접수한 원고는 공모전 종료 후 어떻게 처리되나요?</p>
                    <p class="contents">본 공모전을 통해 접수된 원고 등은 수상작 선정을 위해서만 활용되며, 공모전 종료 후 별도의<br>반환 절차 없이 일괄 폐기됩니다.<br>단, 접수한 작가의 이메일 정보는 별도의 웹툰 작업에 대한 제안(스토리/각색/작화 등)을 위해<br>접수일로부터 1년까지 활용될 수 있습니다.</p>
                </div>
            </div>
        </div>
        <Footer />
    </div>
</template>

<script>
import Header from '@/components/common/Header.vue'
import Footer from '@/components/common/Footer.vue'
import Login from '@/components/common/Login.vue'

export default {
    name: 'EventCompetition',
    pageCategory: 'Event',
    pageTitle: '이벤트',
    components: {
        Header,
        Footer,
        Login,
    },
    data(){
        return{
            initHeaderType: 'type2',
        }
    }
}
</script>