<template>
    <div class="eventfeatured wrap">
        <Header :initHeaderType="initHeaderType" />
        <img src="../../assets/images/eventfeatured_banner.png" />
        <b-container>
            <div class="featured_list" v-for="(item, index) in webtoonList" :key="index">
                <img src="../../assets/images/eventfeatured_list1.png" v-if="index === 0"/>
                <img src="../../assets/images/eventfeatured_list2.png" v-if="index === 1"/>
                <img src="../../assets/images/eventfeatured_list3.png" v-if="index === 2"/>
                <img src="../../assets/images/eventfeatured_list4.png" v-if="index === 3"/>
                <img src="../../assets/images/eventfeatured_list5.png" v-if="index === 4"/>
                <img src="../../assets/images/eventfeatured_list6.png" v-if="index === 5"/>
                <div class="info">
                    <div class="title_box">
                        <p class="title">{{item.title}}</p>
                        <!-- <div class="up_mark">UP</div> -->
                    </div>
                    <p v-html="item.desc"></p>
                    <a @click="moveDetail(item)">보러가기</a>
                </div>
            </div>
        </b-container>
        <Footer />
        <Login :initIsShowPopup="isShowLoginPopup" @close="close('Login')" />
    </div>
</template>

<script>
import Header from '@/components/common/Header.vue'
import Footer from '@/components/common/Footer.vue'
import Login from '@/components/common/Login.vue'

export default {
    name: 'EventFeatured',
    pageCategory: 'EventFeatured',
    pageTitle: '이벤트',
    components: {
        Header,
        Footer,
        Login,
    },
    data() {
        return {
            isShowLoginPopup: false,
            initHeaderType: 'type2',

            webtoonList:[
                {webToonIndex: 10035,  adult: 1, title: '친구아빠', desc: '어느 밤, 용기를 내 친구 아빠의 방으로 들어갔다.<br> “아저씨는 내가 여자로 보이지 않나요?”<br>  나는 그와 사랑을 나누기 위해 돌이킬 수 없는 선택을 했다.'},
                {webToonIndex: 300009, adult: 0, title: '강호대란 생과벌', desc: '강호는 평화를 완전히 잃었다. 조종이 아닌 혼돈… 치유가 아닌 행동…<br> 회복이 아닌 잔상(殘傷)만이 난무했으니… 이때를 무림인들은 암흑의 시대…<br> 즉, 강호대란이라 명명했고…<br class="pc"> 삶은 물론 죽음까지도 벌(伐)하던 혹독한 생사벌(生死伐)의 시기였다고 말한다.'},
                {webToonIndex: 300010, adult: 0, title: '고교최강', desc: '언제부턴가 각 지역 학교에서 이상한 게임이 유행하게 되는데…<br> 그 게임의 이름은 바로 [베팅격투]<br> 그런데 이 게임…<br class="pc"> 단순한 패싸움이 아니라 배후가 있는 것 같다…!'},
                {webToonIndex: 300004, adult: 0, title: '고시원', desc: '[고시원에 살인마가 사는 것 같아요…]<br> 사이코 원장, 수상한 사람들…<br> 과연 이곳은 무얼 하는 곳인가!'},
                {webToonIndex: 300001, adult: 0, title: '샤넬의 딸', desc: '[이제 다시는 너를 두고 뒤돌아서지 않을거야.]<br> 패션에 대한 꿈과 노력, 평생 기억될 사랑..<br> 그리고 벗어날 수 없는 출생의 비밀을 가진 소녀들의 이야기'},
                {webToonIndex: 300002, adult: 0, title: '태주씨의 완벽한 결혼생활', desc: '[그 남자 주변에 벚꽃이 휘날리고 있었더랬지.]<br> 반드시 결혼해야 하는 여자 손태주와<br> 너무~너무 완벽한 남자 오우연의 결혼을 향한 줄다리기'},
            ]
        }
    },
    methods:{
        moveDetail(item){
            if(item.adult === 1){
                if(this.$store.state.loginCheck === false){
                    this.isShowLoginPopup = true;
                }
                else if(!this.$store.state.adult){
                    var src = this.$store.state.siteConfig.niceUrl + '?email=' + this.$store.state.email;
                    window.open(src, 'popupChk', 'width=500, height=550, top=100, left=100, fullscreen=no, menubar=no, status=no, toolbar=no, titlebar=no, location=no, scrollbar=no');
                }
                else{
                    // this.$router.push('/Webtoon/Info/' + item.englishName);
                    this.$router.push('/Webtoon/Info/' + item.webToonIndex);
                }
            }else{
                // this.$router.push('/Webtoon/Info/' + item.englishName);
                this.$router.push('/Webtoon/Info/' + item.webToonIndex);
            }
        },
        close(popupType){
            switch(popupType){
                case 'Login':
                    this.isShowLoginPopup = false;
                    break;
            }
        },
    }
}
</script>