<template>
    <div class="notice wrap">
        <Header :initHeaderType="initHeaderType" />
            <b-container>
                <div class="menu_left_box">
                    <Noticenav :selectedType="NavSelecteType" />
                </div>
                <div class="notice_box">
                    <div class="title">
                        <h1>공지사항</h1>
                    </div>
                    <div class="accordion np_tab" role="tablist" v-if="NoticeInfo !== null">
                        <b-card class="head">
                            <b-row>
                            <b-col>제목</b-col>
                            <b-col>날짜</b-col>
                            </b-row>
                        </b-card>
                        <b-card class="mb-1 no-line">
                            <div class="title_date">
                                <p>{{ NoticeInfo.title }}</p>
                                <p class="date">{{NoticeInfo.createDate.substring(0,10)}}</p>
                            </div>
                            <div class="contents">
                                <p v-html="NoticeInfo.message"></p>
                            </div>
                        </b-card>
                        <router-link tag="a" to="/Notice" class="go_list">목록보기</router-link>
                    </div>
                </div>
            </b-container>
        <Footer />
    </div>
</template>

<script>
import Header from '@/components/common/Header.vue'
import Footer from '@/components/common/Footer.vue'
import Noticenav from '@/components/support/Noticenav.vue'

export default {
    name: 'NoticeDetail',
    pageCategory: 'Common',
    pageTitle: '공지사항',
    components: {
        Header,
        Footer,
        Noticenav,
    },
    data() {
        return {
            NavSelecteType: 'Notice',
            // rows: 10,
            // perPage: 1,
            currentNoticeIdx: 0,

            NoticeInfo: null,

            initHeaderType: 'type2',
        }
    },
    created(){
        if(this.$route.params.idx){
            this.currentNoticeIdx = Number(this.$route.params.idx);
            this.getNoticeDetail();
        }
        else{
            this.$router.push('/Notice');
        }
    },
    methods:{
        getNoticeDetail(){
            var param = 
            {
                noticeIndex: this.currentNoticeIdx,
            }
            this.$Axios.post(`${this.host}/newNotice/info`, param, { headers: { 'Pragma' : 'no-cache', 'Cache-Control': 'no-cache', 'Access-Control-Allow-Origin': '*', 'Content-type': 'application/json', }}).then(async(res) => {
                // console.log(res.data);
                if (res.data.returnCode === 0) {
                    this.NoticeInfo = res.data.data;
                } else {
                    this.$router.push('/Notice');
                }
            })
            .catch((error) => {
                console.log(error);
                this.$router.push('/Notice');
            });
        },
    }
}

</script>