<template>
    <div class="withdraw wrap">
        <Header :initHeaderType="initHeaderType" />
            <b-container>
                <div class="menu_left_box">
                    <Chargenav :selectedType="NavSelecteType" />
                </div>
                <div class="withdraw_box">
                    <div class="title">
                        <h1>아트포인트 출금</h1>
                        <p>아튜브로부터 회원님의 외부 지갑으로 아트포인트를 ATT코인으로 전환하여 출금합니다.</p>
                    </div>
                    <div class="qoute2">
                        <p class="title">ATT 실시간 시세 <span>※ 제공 : 코인원</span></p>
                        <div class="qoute_box">
                            <div class="number_box">
                                <p v-if="calculateInfo !== null">{{calculateInfo.price | comma}} <span>KRW</span></p>
                                <p v-else>- <span>KRW</span></p>
                            </div>
                            <div class="refresh_box">
                                <div class="button" @click="getWithdrawCalculate">
                                    시세 갱신    
                                </div>
                                <p>{{calculateTimerCount}}초 후 자동 갱신</p>
                            </div>
                        </div>
                    </div>   
                    <div class="quote">
                        <h2>출금 가능한 아트포인트</h2>
                        <div class="quote_box">
                            <div class="change_box">
                                <div class="artpoint">
                                    <div class="question_box">
                                        <p>
                                            출금 가능한 총 포인트
                                            <img src="@/assets/images/question.svg" id="tooltip1"/>
                                            <b-tooltip target="tooltip1" custom-class="longTooltip" triggers="hover">
                                                <div style="text-align:left;">
                                                    <p style="font-size:14px;margin-bottom:10px;">아트포인트 잔액과 출금가능액은 다릅니다.</p>
                                                    <p style="font-size:12px;">- 아트포인트 출금은 ATT코인을 통해 충전한 부분만 출금 가능합니다.</p>
                                                    <p style="font-size:12px;">- 보너스 포인트 누적 사용분을 제외한 수량만큼 출금이 가능합니다.</p>
                                                    <p style="font-size:12px;">- 출금 시 해당 포인트만큼 보너스 포인트가 차감됩니다.</p>
                                                </div>
                                            </b-tooltip>
                                        </p>
                                    </div>
                                    <p class="point">{{$store.state.attPoint | comma}} <span>AP</span></p>
                                </div>
                                <img src="@/assets/images/icon_exchange.svg" alt="화살표">
                                <div class="now_quote">
                                    <p>현재시세 대비 변환</p>
                                    <p class="att" v-if="calculateInfo !== null">{{(($store.state.attPoint * 200) / calculateInfo.price).toFixed(4) | comma}} <span>ATT</span></p>
                                    <p class="att" v-else>0 <span>ATT</span></p>
                                    <p class="krw">(≈ {{($store.state.attPoint * 200) | comma}} KRW)</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="typing">
                        <h2>출금 신청 아트포인트</h2>
                        <div class="send_box">
                            <div class="input_area">
                                <input placeholder="출금할 아트포인트를 입력해 주세요" v-model="attDepositAmount" @keyup="commasAttDepositAmount()" min="0">
                                <p>AP</p>
                            </div>
                            <div class="button" @click="setMaxAmount">최대</div>
                            <!-- <p v-if="calculateInfo !== null">(최소 {{ calculateInfo.minWithdraw}} AP)</p>
                            <p v-else>(최소- AP)</p> -->
                        </div>
                        <!-- <div class="button_box">
                            <div class="button" @click="changeAmount(0.1)">10%</div>
                            <div class="button" @click="changeAmount(0.25)">25%</div>
                            <div class="button" @click="changeAmount(0.50)">50%</div>
                            <div class="button" @click="changeAmount(0.75)">75%</div>
                            <div class="button" @click="setMaxAmount">최대</div>
                            <div class="button" @click="resetAmount">초기화</div>
                        </div> -->
                        <!-- <div class="warn">
                            <p>보유한 잔액을 초과했습니다. 다시 입력해 주세요.</p>
                        </div> -->
                        <div class="fees_box">
                            <div class="fees">
                                <p>
                                    출금 수수료 
                                    <img src="@/assets/images/question.svg" id="tooltip2">
                                    <b-tooltip target="tooltip2" custom-class="longTooltip" triggers="hover">
                                        <div style="text-align:left;">
                                            <p style="font-size:12px;">ATT 출금 수수료는 현재 Gas Fee 시세에 따라  변동 될 수 있으므로, 화면에 표시된 금액과 차이가 있을 수 있습니다.</p>
                                        </div>
                                    </b-tooltip>
                                </p>
                                <p class="number" v-if="calculateInfo !== null">{{calculateInfo.feePoint | comma}} AP</p>
                                <p class="number" v-else> - AP</p>
                            </div>
                            <div class="change_coin">
                                <p>총 차감 포인트</p>
                                <p class="number" v-if="calculateInfo !== null && numberAttDepositAmount > 0">{{Math.ceil(calculateInfo.feePoint + calculateInfo.requestPoint) | comma}} AP</p>
                                <p class="number" v-else>0 AP</p>
                            </div>

                        </div>
                    </div>
                    <div class="expectation">
                        <h2>예상 ATT 출금액</h2>
                        <div class="point">
                            <p v-if="numberAttDepositAmount > 0">{{calculateInfo.transferAmount | comma}} <span>ATT</span></p>
                            <p v-else>0 <span>ATT</span></p>
                            <p class="same" v-if="numberAttDepositAmount > 0">(≈ {{ (calculateInfo.transferAmount * calculateInfo.price).toFixed(2) | comma}} KRW)</p>
                            <p class="same" v-else>(≈ 0 KRW)</p>
                        </div>
                    </div>
                    <div class="acc_pass">
                        <h2>계정 비밀번호</h2>
                        <div class="password_box">
                            <input class="acc_password" type="password" v-model="password" placeholder="비밀번호를 입력해주세요">
                        </div>
                        <div class="warn">
                            <p v-if="isShowErrorPassword">{{errorMsgPassword}}</p>
                        </div>
                        <div class="button2" @click="withdraw">출금 신청</div>
                    </div>
                    <div class="guide_box">
                        <p class="guide">출금 이용안내</p>
                        <p class="text1">- ATT는 소수점 4자리까지 출금 가능합니다.</p>
                        <p class="text">- 가상자산 특성상 출금신청이 완료되면 취소가 불가하기 때문에, 출금 시 내 지갑 주소를 꼭 확인하시기 바랍니다.</p>
                        <p class="text">- 출금신청 완료 후 블록체인 네트워크에서 처리되어 네트워크 상태에 따라 출금 시간이 지연될 수 있습니다. 아튜브는 블록체인 네트워크에 개입할 수 없으므로, 지연 및 이상현상 발생 시 추가 조치가 불가합니다. 이점 양해 부탁 드립니다.</p>
                        <p class="text">- 부정거래가 의심될 경우 출금이 제한될 수 있습니다.</p>
                    </div>
                </div>
            </b-container>
        <PopupWallet :initIsShowPopup="isShowWalletPopup" @close="close('Wallet')" />
        <PopupOK :initIsShowPopup="isShowPopupOkPopup" :initPopupOkMessage="initPopupOkMessage" @close="close('PopupOk')" />
        <PopupQR :initIsShowPopup="isShowPopupQR" :initRequestKey="initRequestKey" :initRequestType="initRequestType" @close="close('PopupQR')" @onComplete="onComplete" />
        <Footer />
    </div>
</template>

<script>
import Header from '@/components/common/Header.vue'
import Footer from '@/components/common/Footer.vue'
import Chargenav from '@/components/point/Chargenav.vue'
import PopupWallet from '@/components/wallet/PopupWallet.vue'
import PopupOK from '@/components/common/PopupOK.vue'
import Caver from "caver-js";
import PopupQR from '@/components/point/PopupQR.vue'
import { prepare } from 'klip-sdk'

export default {
    name: 'PointWithdraw',
    pageCategory: 'Point',
    pageTitle: '포인트 출금',
    components: {
        Header,
        Footer,
        Chargenav,
        PopupWallet,
        PopupOK,
        PopupQR
    },
    data() {
        return {
            show:true,

            NavSelecteType: 'Withdraw',

            attDepositAmount: 0,
            numberAttDepositAmount: 0,
            password: '',

            isShowErrorPassword: false,
            errorMsgPassword: '',
            isShowMetamaskPopup: false,
            isShowWalletPopup: false,

            isShowPopupOkPopup: false,
            initPopupOkMessage: '',
            PopupOkCallbakPage: '',    // 확인 팝업에서 확인 버튼 눌렀을때 이동할 페이지

            calculateInfo: null,
            calculateTimerCount: 10,
            calculateInterval: false,

            isShowPopupQR: false,
            initRequestKey: null,
            initRequestType: 0,

            initHeaderType: 'type2',

        }
    },
    async created(){
        var that = this;

        // await this.mixinSiteConfigCheck();
        await this.mixinLoginCheck();

        if(!this.$store.state.loginCheck){
            this.initPopupOkMessage = '로그인 후 이용하실 수 있습니다.'
            this.PopupOkCallbakPage = '/';
            this.isShowPopupOkPopup = true;
            return;
        }
        if(!this.$store.state.adult){
            this.initPopupOkMessage = '성인인증 후 이용하실 수 있습니다.'
            this.PopupOkCallbakPage = '/';
            this.isShowPopupOkPopup = true;
            return;
        }

        if(this.checkWallet()){
            this.getWithdrawCalculate();
            this.calculateInterval = setInterval(() => {
                if(that.calculateTimerCount <= 0){
                    that.getWithdrawCalculate();
                }else{
                    that.calculateTimerCount--;
                }
            }, 1000);
        }
    },
    mounted(){
    },
    beforeDestroy(){
        if(this.calculateInterval){
            clearInterval(this.calculateInterval);
        }
    },
    methods:{
        checkWallet(){
            if(!this.$store.state.isEnableKaikas && !this.$store.state.isEnableKlip){
                this.isShowWalletPopup = true;
                return false;
            }
            return true;
        },
        async getWithdrawCalculate(){
            this.calculateTimerCount = 10;
            var param = 
            {
                amount: this.numberAttDepositAmount,
            }
            await this.$Axios.post(`${this.host}/erc20/withdraw/calculate`, param, { headers: { 'Pragma' : 'no-cache', 'Cache-Control': 'no-cache', 'Access-Control-Allow-Origin': '*', 'Content-type': 'application/json', }}).then(res => {
                // console.log(res.data);
                if (res.data.returnCode === 0) {
                    this.calculateInfo = res.data;
                }
            })
            .catch((error) => {
                console.log(error);
            });
        },
        withdraw(){
            this.isShowErrorPassword = false;
            if(this.numberAttDepositAmount < Number(this.calculateInfo.minWithdraw)){
                this.initPopupOkMessage = '출금 신청은 ' + this.calculateInfo.minWithdraw + 'AP 이상만 가능합니다.'
                this.isShowPopupOkPopup = true;
                return;
            }

            if(this.password === ''){
                this.initPopupOkMessage = '비밀번호를 입력해 주세요.'
                this.isShowPopupOkPopup = true;

                this.errorMsgPassword = '비밀번호를 입력해 주세요.';
                this.isShowErrorPassword = true;
                return;
            }

            if(this.numberAttDepositAmount > 0){
                if(this.$store.state.isEnableKaikas){
                    this.withdrawKaikas();
                } else if(this.$store.state.isEnableKlip){
                    this.reqKlipRequest();
                }
            }
        },
        async withdrawKaikas(){  //메타 마스크, 카이카스 사이닝
            var caver = new Caver(window.klaytn);
            var message = 'Artube Signature(' + Date.now().toString() + ')';

            await caver.klay.sign(message, this.$store.state.walletAddress).then(signature => {
                var param = 
                {
                    address: this.$store.state.walletAddress,
                    message: message,
                    signature : signature,
                    sign: this.$store.state.sign,
                    ticket: this.$store.state.ticket,
                    amount: this.numberAttDepositAmount,
                    password: this.password
                }
                this.$Axios.post(`${this.host}/erc20/withdraw/request`, param, { headers: { 'Pragma' : 'no-cache', 'Cache-Control': 'no-cache', 'Access-Control-Allow-Origin': '*', 'Content-type': 'application/json', }}).then(res => {
                    if (res.data.returnCode === 0) {
                        this.initPopupOkMessage = '출금 신청이 완료 되었습니다.<br/>출금 진행상황은 출금내역 페이지에서 확인하실 수 있습니다.'
                        this.PopupOkCallbakPage = '/Point/History/Withdraw';
                        this.isShowPopupOkPopup = true;
                    }
                    else if(res.data.returnCode === 92){
                        this.initPopupOkMessage = '비밀번호가 일치하지 않습니다.<br/>확인 후 다시 시도해 주세요.'
                        this.isShowPopupOkPopup = true;

                        this.errorMsgPassword = '비밀번호가 일치 하지 않습니다.';
                        this.isShowErrorPassword = true;
                    }
                    else if(res.data.returnCode === 252){
                        this.initPopupOkMessage = '성인인증 후 지갑연결이 가능합니다.';
                        this.isShowPopupOkPopup = true;   
                    }
                    else if(res.data.returnCode === 917){
                        this.initPopupOkMessage = '보유 아트포인트가 부족합니다.'
                        this.isShowPopupOkPopup = true;
                    }
                    else if(res.data.returnCode === 919){
                        this.initPopupOkMessage = '출금 수수료가 출금 신청 금액을 초과합니다.'
                        this.isShowPopupOkPopup = true;
                    }
                    else if(res.data.returnCode === 918){
                        this.initPopupOkMessage = '출금 기능이 제한 중입니다.<br/>공지사항 또는 1:1문의로 확인바랍니다.'
                        this.PopupOkCallbakPage = '/Notice';
                        this.isShowPopupOkPopup = true;
                    }
                    else if(res.data.returnCode === 920){
                        this.initPopupOkMessage = '연결된 지갑 주소와 일치 하지 않습니다.'
                        this.PopupOkCallbakPage = '/Notice';
                        this.isShowPopupOkPopup = true;
                    }
                    else{
                        this.initPopupOkMessage = '출금 신청이 실패 했습니다.'
                        this.isShowPopupOkPopup = true;
                    }
                })
                // .catch((error) => {
                //     console.log(error);
                // });
            })
            .catch(err => {
                err;
            })
        },
        async reqKlipRequest(){
            try{
                const res = await prepare.auth({bappName: "Artube"});
                this.initRequestKey = res.request_key;
                this.initRequestType = 1;
                this.isShowPopupQR = true;
            } catch(err){
                console.log(err);
            }
        },
        onComplete(data){   //Klip 리턴 대기
            if(data.type === 'FAILED_CREATE_QRCODE'){
                this.initPopupOkMessage = 'QR 코드를 생성하지 못했습니다.<br/>잠시 후 다시 시도해 주세요.';
                this.isShowPopupOkPopup = true;
            }
            else if(data.type === 'FAILED_REQUEST'){
                this.initPopupOkMessage = '모바일 기기에서 이용 가능한 기능입니다.<br/>카카오톡이 설치된 스마트폰에서 이용해 주세요.';
                this.isShowPopupOkPopup = true;
            }
            else if(data.type === 'CANCLED_KLIP_RESULT'){
                this.initPopupOkMessage = 'KLIP 인증을 취소하셨습니다.';
                this.isShowPopupOkPopup = true;
            }
            else if(data.type === 'SUCCESS_KLIP_RESULT'){
                if(data.requestType === 1){     //requestType - 1:prepareAuth, 2:prepareToken
                    this.withdrawKlip(data.requestKey);
                }
            }
        },
        async withdrawKlip(requestKey){
            var param = 
            {
                address: this.$store.state.walletAddress,
                amount: this.numberAttDepositAmount,
                password: this.password,
                requestKey: requestKey,
                sign: this.$store.state.sign,
                ticket: this.$store.state.ticket,
                viewAdult: this.$store.state.isCheckedAdult ? 1 : 0
            }
            // console.log(param);
            this.$Axios.post(`${this.host}/klip/withdraw/request`, param, { headers: { 'Pragma' : 'no-cache', 'Cache-Control': 'no-cache', 'Access-Control-Allow-Origin': '*', 'Content-type': 'application/json', }}).then(res => {
                if (res.data.returnCode === 0) {
                    this.initPopupOkMessage = '출금 신청이 완료 되었습니다.<br/>출금 진행상황은 출금내역 페이지에서 확인하실 수 있습니다.'
                    this.PopupOkCallbakPage = '/Point/History/Withdraw';
                    this.isShowPopupOkPopup = true;
                }
                else if(res.data.returnCode === 92){
                    this.initPopupOkMessage = '비밀번호가 일치하지 않습니다.<br/>확인 후 다시 시도해 주세요.'
                    this.isShowPopupOkPopup = true;

                    this.errorMsgPassword = '비밀번호가 일치 하지 않습니다.';
                    this.isShowErrorPassword = true;
                }
                else if(res.data.returnCode === 252){
                    this.initPopupOkMessage = '성인인증 후 지갑연결이 가능합니다.';
                    this.isShowPopupOkPopup = true;   
                }
                else if(res.data.returnCode === 917){
                    this.initPopupOkMessage = '보유 아트포인트가 부족합니다.'
                    this.isShowPopupOkPopup = true;
                }
                else if(res.data.returnCode === 919){
                    this.initPopupOkMessage = '출금 수수료가 출금 신청 금액을 초과합니다.'
                    this.isShowPopupOkPopup = true;
                }
                else if(res.data.returnCode === 918){
                    this.initPopupOkMessage = '출금 기능이 제한 중입니다.<br/>공지사항 또는 1:1문의로 확인바랍니다.'
                    this.PopupOkCallbakPage = '/Notice';
                    this.isShowPopupOkPopup = true;
                }
                else if(res.data.returnCode === 920){
                    this.initPopupOkMessage = '연결된 지갑 주소와 일치 하지 않습니다.'
                    this.PopupOkCallbakPage = '/Notice';
                    this.isShowPopupOkPopup = true;
                }
                else{
                    this.initPopupOkMessage = '출금 신청이 실패 했습니다.'
                    this.isShowPopupOkPopup = true;
                }
            })
            .catch((error) => {
                error;
            });
        },
        close(popupType){
            switch(popupType){
                case 'PurchasePnlink':
                    this.selectedGoodsPrice = 0;
                    this.isShowPurchasePopup = false;
                    break;
                case 'Login':
                    this.isShowLoginPopup = false;
                    break;
                case 'Wallet':
                    this.isShowWalletPopup = false;
                    if(!this.checkWallet()){
                        this.initPopupOkMessage = '지갑 연결 후 이용하실 수 있습니다.'
                        this.PopupOkCallbakPage = '/';
                        this.isShowPopupOkPopup = true;
                    }
                    break;
                case 'Metamask':
                    this.isShowMetamaskPopup = false;
                    if(!this.checkWallet()){
                        this.initPopupOkMessage = '지갑 연결 후 이용하실 수 있습니다.'
                        this.PopupOkCallbakPage = '/';
                        this.isShowPopupOkPopup = true;
                    }
                    break;
                case 'PopupOk':
                    this.isShowPopupOkPopup = false;
                    if(this.PopupOkCallbakPage !== ''){
                        this.$router.push(this.PopupOkCallbakPage);
                    }
                    this.PopupOkCallbakPage = '';
                    break;
                case 'PopupQR':
                    this.isShowPopupQR = false;
                    this.initRequestKey = null;
                    this.initRequestType = 0;
                    break;
            }
        },
        commasAttDepositAmount() {
            var x = this.attDepositAmount.toString();
            x = x.replace(/[^-.0-9]/g, "");
            // x = Number(x).toString();
            var decimalNumber = '';
            if(x.indexOf('.', 0) >= 0){
                decimalNumber = x.substring(x.indexOf('.', 0));
            }
            else{
                x = Number(x).toString();
            }

            if(decimalNumber.length > 1){
                if(Number(decimalNumber.substring(1)) > 0){
                    x = Number(x.substring(0, x.indexOf('.'))).toString();
                    x += decimalNumber.substring(0,5);
                }
            }
            
            if(this.$store.state.attPoint <= Number(x.toString())){
                x = this.$store.state.attPoint.toString();
            }
            if(Number(x) > (this.$store.state.attPoint - this.calculateInfo.feePoint)){
                x = (this.$store.state.attPoint - this.calculateInfo.feePoint).toFixed(0).toString();
            }

            if(Number(x) < 0){
                x = '0';
            }

            this.numberAttDepositAmount = Number(x);
            this.attDepositAmount = this.setComma(x);
            this.getWithdrawCalculate();
            // console.log(this.attChargeAmount);
        },
        changeAmount(per){
            var amount = Number((this.$store.state.attPoint * per).toFixed(4).toString());
            this.attDepositAmount = this.setComma(amount.toString());
            this.numberAttDepositAmount = amount;
        },
        // setMaxAmount(){
        //     this.attDepositAmount = this.setComma(this.$store.state.attPoint.toString());
        //     this.numberAttDepositAmount = this.$store.state.attPoint;
        //     this.getWithdrawCalculate();    
        // },
        setMaxAmount(){
            //this.attDepositAmount = this.setComma(this.$store.state.attPoint.toString());
            //this.numberAttDepositAmount = this.$store.state.attPoint;

            var calc = this.$store.state.attPoint;
            if(this.calculateInfo !== null){
                calc = calc - this.calculateInfo.feePoint;
            }
            if(calc < 0)
                calc = 0;

            this.attDepositAmount = this.setComma(calc.toString());
            this.numberAttDepositAmount = calc;

            this.getWithdrawCalculate();
        },
        resetAmount(){
            this.attDepositAmount = 0;
            this.numberAttDepositAmount = 0;
        },
        setComma(obj) {
            var regx = new RegExp(/(-?\d+)(\d{3})/);
            var bExists = obj.indexOf(".", 0);//0번째부터 .을 찾는다.
            var strArr = obj.split('.');
            while (regx.test(strArr[0])) {//문자열에 정규식 특수문자가 포함되어 있는지 체크
                //정수 부분에만 콤마 달기 
                strArr[0] = strArr[0].replace(regx, "$1,$2");//콤마추가하기
            }
            if (bExists > -1) {
                //. 소수점 문자열이 발견되지 않을 경우 -1 반환
                obj = strArr[0] + "." + strArr[1];
            } else { //정수만 있을경우 //소수점 문자열 존재하면 양수 반환 
                obj = strArr[0];
            }
            return obj;//문자열 반환
        },
    }
}
</script>