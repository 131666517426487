<template>
    <div class="enrollmentpopup" v-if="initIsShowPopup">
        <div class="enrollmentpopup_box">
            <p class="title">작품등록</p>
            <div class="middle">
                <p>작품, 회차 등록시 주의사항</p>
                <ul>
                    <li>1. 직접적인 욕설, 인신공격</li>
                    <li>2. 광고, 도배</li>
                    <li>3. 과도한 음란성, 선정성</li>
                    <li>4. 개인정보 노출</li>
                    <li>5. 부적절한 단어 사용</li>
                    <li>6. 저작권 도용, 위반</li>
                    <li>7. 기타 운영정책에 맞지 않는 게시물</li>
                </ul>
                <p class="ps">위의 사항을 지키지 않을 경우 수정 권고를 받을 수 있으며 다른 사람의 권리를 침해하거나 불법 사용, 명예훼손 하는 경우 이용약관 및 관련 법률에 따라 제재 받을 수 있습니다.</p>
            </div>
            <div class="bottom">
                <p>작가님들은 본 주의 사항을 꼭 지켜 주세요!!</p>
                <p class="ps">운영 정책과 내용을 숙지를 않아서 발생할 수 있는 모든 불이익은 작가님에게 있습니다. 또한 경고/ 계정 정지 조치가 될 수 있습니다.</p>
            </div>
            <p class="ok" @click="closePopup">확인</p>
        </div>
    </div>
</template>

<script>
export default {
    name: 'PopupEnrollment',
    data(){
        return {
        }
    },
    props: {
        initIsShowPopup: {
            type: Boolean,
            default: false
        },
    },
    created() {
    },
    mounted(){
    },
    beforeDestroy() {
	},
    methods: {
        closePopup(){
            this.$emit('close');
        },
    },
}
</script>