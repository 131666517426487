<template>
    <div id="viewer">
        <Header :initHeaderType="initHeaderType" :initIsHeaderShow="isShowViewer" @changeState="changeState" />
        <LeagueMyViewerheader :initWebtoonInfo="webtoonInfo" :initEpisodeIdx="currEpisodeIdx" :initEpisodeState="episodeState" :initNextEpisodeIdx="nextEpisodeIdx" :initPreEpisodeIdx="preEpisodeIdx" :initWriterInfo="writerInfo" :initisShowViewer="isShowViewer" />
        <div class="container">
            <img v-for="(item,index) in imageList" :key="index" @click="touchImg" 
                :src="league_host + '/challenge/episode/image/view/' 
                        + $store.state.ticket + '/'
                        + $store.state.sign + '/'
                        + writerInfo.cwWriterIdx + '/'
                        + currWebtoonIdx + '/'
                        + currEpisodeIdx + '/'
                        + item.ciPageIdx"
            />
            <p class="warn">아튜브의 모든 콘텐츠는 저작권 법에 의거하여 보호받고 있습니다. <br>저작권자 또는 아튜브의 승인없이 콘텐츠의 일부 또는 전부를 복제, 변경, 전송, 배포 등의 <br>방법으로 이용할 경우 <span>저작권법에 의거하여 법적 조치에 처해질 수 있습니다.</span></p>
            <p class="logo">artube</p>

            <div class="group" v-if="episodeList.length > 0">
                <div class="prev" @click="prevPage()">prev</div>
                    <div class="toon" v-for="(item, index) in episodeList" :key="index" :class="[item.ceEpisodeIdx === currEpisodeIdx ? 'now' : 'secret']" @click="changeEpisode(currWebtoonIdx, item.ceEpisodeIdx)">
                        <img :src="$store.state.siteConfig.cdnUrl + '/CHALLENGE/thumbnail/' + webtoonInfo.ctCdnFolder + '/' + item.ceUrl" alt="썸네일" class="ssum" v-if="webtoonInfo !== null && $store.state.siteConfig !== null">
                        <p>{{item.ceEpisodeIdx}}화</p>
                    </div>
                <div class="next" @click="nextPage()">next</div>
            </div>
            <div class="buttons2">
                <div class="list2" @click="backList">목록 보기</div>
            </div>
        </div>
        <Footer />
        <LeagueMyViewermove :initWebtoonInfo="webtoonInfo" :initEpisodeState="episodeState" :initNextEpisodeIdx="nextEpisodeIdx" :initPreEpisodeIdx="preEpisodeIdx" :initisShowViewer="isShowViewer" />
        <Login :initIsShowPopup="isShowLoginPopup" @close="close('Login')" />
        <PopupOK :initIsShowPopup="isShowPopupOkPopup" :initPopupOkMessage="initPopupOkMessage" @close="close('PopupOk')" />
    </div>
</template>

<script>
import Footer from '@/components/common/Footer.vue'
import Header from '@/components/common/Header.vue'
import Login from '@/components/common/Login.vue'
import LeagueMyViewerheader from '@/components/webtoon/Viewerheader.vue'
import LeagueMyViewermove from '@/components/webtoon/Viewermove.vue'
import PopupOK from '@/components/common/PopupOK.vue'

export default {
    name: 'LeagueWriterViewer',
    pageCategory: 'League',
    components: {
        Footer,
        Header,
        Login,
        LeagueMyViewerheader,
        LeagueMyViewermove,
        PopupOK,
    },
    data() {
        return {
            currWebtoonEngName: '',
            currWebtoonIdx: 0,
            currEpisodeIdx: 0,

            writerInfo: null,
            webtoonInfo: null,
            episodeState: null,
            nextEpisodeIdx: null,
            preEpisodeIdx : null,
            imageList: [],
            episodeList: [],
            chagneEpisodeInfo: null,
            categoryWebtoonList: [],
            selectedCategoryWebtoonSlideIdx: 0,

            selectedEpisodieInfo: null,

            isShowLoginPopup: false,
            isUnFold: false,

            currentPageNo: 1,      //현재 페이지 번호
            currentPageSize: 7,    //한번에 불러올 게시물 수
            maxPageCount:1,        //한 화면에 보여질 페이지 수

            listCount: 0,
            pageCount: 0,
            pageNo: 0,
            pageSize: 0,
            totalRecordSize: 0,

            pageList:[],

            starGrade : 0,
            isCheckedStar: false,

            isShowDeclarationPopup: false,

            isShowPopupOkPopup: false,
            initPopupOkMessage: '',
            PopupOkCallbakPage: '',    // 확인 팝업에서 확인 버튼 눌렀을때 이동할 페이지

            isShowViewer: true,
            isShowMypageState: false,   //헤더 햄버거 마이페이지 팝업 열려있는지 상태를 받아와서 저장(여기서 값 수정 ㄴㄴ)
            initHeaderType: 'type5',
        }
    },
    async created(){
        await this.mixinLoginCheck();
        // await this.mixinSiteConfigCheck();

        var checkWriter = await this.checkWriter(); //작가 등록 여부 확인
        if(checkWriter === null){   //체크 에러
            this.initPopupOkMessage = '작가 정보 체크 에러'
            this.PopupOkCallbakPage = '/League';
            this.isShowPopupOkPopup = true;
            return;
        }
        else if(!checkWriter){  //작가 등록 안된 상태
            this.initPopupOkMessage = '접근 권한이 없습니다.'
            this.PopupOkCallbakPage = '/League';
            this.isShowPopupOkPopup = true;
            return;
        }

        if(this.$route.params.wtidx){
            this.currWebtoonIdx = Number(this.$route.params.wtidx);
        }else{
            this.$router.push('/League/Writer/Info');
        }

        if(this.$route.params.epidx){
            this.currEpisodeIdx = Number(this.$route.params.epidx);
        }else{
            this.$router.push('/League/Writer/Info');
        }
        
        if(!this.$store.state.loginCheck){
            this.$router.push('/League/Writer/Info');
        }else{
            await this.getWebtoonInfo();
            await this.getEpisodeState();
            await this.getImageList();
            // this.checkStarPorint();
            // this.getCategoryWebtoonList();
            var start_idx = 1;
            var end_idx = this.currentPageSize;

            if(this.currEpisodeIdx === 0){
                this.currentPageNo = 1;
                this.getEpisodeList();
            }
            else{
                for(var i=1;i<100;i++)
                {

                    if(this.currEpisodeIdx >= start_idx && this.currEpisodeIdx <= end_idx){
                        this.currentPageNo = i;
                        await this.getEpisodeList();

                        var isContain = false;
                        this.episodeList.forEach(element =>{
                            if(element.ceEpisodeIdx === this.currEpisodeIdx){
                                isContain = true;
                            }
                        });

                        if(!isContain){
                            this.currentPageNo ++;
                            this.getEpisodeList();
                        }

                        break;
                    }else{
                        start_idx = start_idx + this.currentPageSize;
                        end_idx = end_idx + this.currentPageSize
                    }
                }
            }
        }
    },
    mounted(){
        window.addEventListener('scroll', this.scrollHandler);
    },
    beforeDestroy(){
        window.removeEventListener('scroll', this.scrollHandler);
    }, 
    methods: {
        scrollHandler(){
            if(this.isShowMypageState){
                return;
            }
            var nowlocation = document.documentElement.scrollTop;
            // console.log(this.document.documentElement.scrollHeight)
            // console.log(nowlocation)
            var windowlocation = window.innerHeight;
            // console.log(windowlocation)
            var endlocation = document.documentElement.scrollHeight - document.documentElement.scrollTop;
            // console.log(endlocation);
            if(endlocation <= windowlocation + 62){
                this.isShowViewer = true;
            } else if(nowlocation > 0){
                this.isShowViewer = false;
            } else{
                this.isShowViewer = true;
            }
        },
        touchImg(){
            // if(!this.isPc()){
                this.isShowViewer = !this.isShowViewer
            // }
        },
        async checkWriter(){
            var param = 
            {
                sign: this.$store.state.sign,
                ticket: this.$store.state.ticket,
                viewAdult: this.$store.state.isCheckedAdult ? 1: 0
            }
            return await this.$Axios.post(`${this.league_host}/challenge/writer/check`, param, { headers: { 'Pragma' : 'no-cache', 'Cache-Control': 'no-cache', 'Access-Control-Allow-Origin': '*', 'Content-type': 'application/json', }}).then(async(res) => {
                // console.log(res.data.writer);
                if (res.data.returnCode === 0) {    //작가 등록됨
                    this.writerInfo = res.data.writer;
                    return true;
                }
                else if(res.data.returnCode === 41){    //작가 등록 안됨
                    return false;
                }
                else{   //기타 에러
                    return null;
                }
            })
            .catch((error) => {
                console.log(error);
                return null;
            });
        },
        prevPage(){
            if(this.currentPageNo > 1){
                this.currentPageNo--;
                this.getEpisodeList();
            }
        },
        nextPage(){
            if(this.currentPageNo < this.pageCount){
                this.currentPageNo++;
                this.getEpisodeList();
            }
        },
        async getImageList(){
            var param = 
            {
                sign: this.$store.state.sign,
                ticket: this.$store.state.ticket,
                viewAdult: this.$store.state.isCheckedAdult ? 1 : 0,
                episodeIdx: this.currEpisodeIdx,
                webtoonIdx: this.currWebtoonIdx,
                writerIdx: this.writerInfo.cwWriterIdx,
                pageNo: 1,
                pageSize: 1000,
                // lang: 'KR'
            }
            await this.$Axios.post(`${this.league_host}/challenge/writer/episode/image/list`, param, { headers: { 'Pragma' : 'no-cache', 'Cache-Control': 'no-cache', 'Access-Control-Allow-Origin': '*', 'Content-type': 'application/json', }}).then(async(res) => {
                // console.log('----image list----');
                // console.log(res.data);
                if (res.data.returnCode === 0) {
                    this.imageList = res.data.list;
                }
                else{
                    console.log(res.data.description);
                    // this.$router.push('/League');
                }
            })
            .catch((error) => {
                console.log(error);
            });
        },
        async getWebtoonInfo(){
            var param = 
            {
                sign: this.$store.state.sign,
                ticket: this.$store.state.ticket,
                viewAdult: this.$store.state.isCheckedAdult ? 1 : 0,
                wtIdx: this.currWebtoonIdx,
                writerIdx: this.writerInfo.cwWriterIdx,
            }
            await this.$Axios.post(`${this.league_host}/challenge/writer/webtoon`, param, { headers: { 'Pragma' : 'no-cache', 'Cache-Control': 'no-cache', 'Access-Control-Allow-Origin': '*', 'Content-type': 'application/json', }}).then(async(res) => {
                // console.log('----webtoon info----');
                // console.log(res.data);
                if (res.data.returnCode === 0) {
                    this.webtoonInfo = res.data.data;
                }
                else{
                    // console.log(res.data.returnCode);
                    this.$router.push('/League');
                }
            })
            .catch((error) => {
                console.log(error);
            });
        },
        async getEpisodeList(){
            var param = 
            {
                sign: this.$store.state.sign,
                ticket: this.$store.state.ticket,
                viewAdult: this.$store.state.isCheckedAdult ? 1 : 0,
                pageNo: this.currentPageNo,
                pageSize: this.currentPageSize,
                wtIdx: this.currWebtoonIdx,
                writerIdx: this.writerInfo.cwWriterIdx
            }
            // console.log(param);
            await this.$Axios.post(`${this.league_host}/challenge/writer/webtoon/episode/list`, param, { headers: { 'Pragma' : 'no-cache', 'Cache-Control': 'no-cache', 'Access-Control-Allow-Origin': '*', 'Content-type': 'application/json', }}).then(async(res) => {
                // console.log('----episode list----');
                // console.log(res.data);
                if (res.data.returnCode === 0) {
                    this.episodeList = res.data.list;
                    this.listCount       = res.data.page.listCount;
                    this.pageCount       = res.data.page.pageCount;
                    this.pageNo          = res.data.page.pageNo;
                    this.pageSize        = res.data.page.pageSize;
                    this.totalRecordSize = res.data.page.totalRecordSize;
                    // this.changePage();
                }
                // else{
                //     console.log(res.data.description);
                //     this.$router.push('/');
                // }
            })
            .catch((error) => {
                console.log(error);
            });
        },
        async getEpisodeState(){
            var param = 
            {
                ticket: this.$store.state.ticket,
                sign: this.$store.state.sign,
                viewAdult: this.$store.state.isCheckedAdult ? 1 : 0,

                webtoonIdx: this.currWebtoonIdx,
                episodeIdx: this.currEpisodeIdx,
                writerIdx: this.writerInfo.cwWriterIdx
                // lang: 'KR'
            }
            // console.log(param)
            await this.$Axios.post(`${this.league_host}/challenge/writer/episode/state`, param, { headers: { 'Pragma' : 'no-cache', 'Cache-Control': 'no-cache', 'Access-Control-Allow-Origin': '*', 'Content-type': 'application/json', }}).then(async(res) => {
                // console.log('----episode state----');
                // console.log(res.data);
                if (res.data.returnCode === 0) {
                    this.episodeState = res.data.epData;
                    if(res.data.preEpisodeIdx !== null){
                        this.preEpisodeIdx = {idx : res.data.preEpisodeIdx};
                    }
                    if(res.data.nextEpisodeIdx !== null){
                        this.nextEpisodeIdx = {idx : res.data.nextEpisodeIdx};
                    }
                }
                // else{
                //     this.$router.push('/');
                // }
            })
            .catch((error) => {
                console.log(error);
            });
        },
        backList(){
            this.$router.push('/League/Writer/Webtoon/Info/' + this.currWebtoonIdx);
        },
        changeEpisode(wtIdx, epIdx){
            var param = 
            {
                ticket: this.$store.state.ticket,
                sign: this.$store.state.sign,
                viewAdult: this.$store.state.isCheckedAdult ? 1 : 0,

                webtoonIdx: wtIdx,
                episodeIdx: epIdx,
                writerIdx: this.writerInfo.cwWriterIdx,
                // lang: 'KR'
            }
            // console.log(param);
            this.$Axios.post(`${this.league_host}/challenge/writer/episode/state`, param, { headers: { 'Pragma' : 'no-cache', 'Cache-Control': 'no-cache', 'Access-Control-Allow-Origin': '*', 'Content-type': 'application/json', }}).then(async(res) => {
                // console.log('----episode state----');
                // console.log(res);

                if(res.data.epData != null){
                    this.chagneEpisodeInfo = res.data.epData;
                }

                if (res.data.returnCode === 0) {
                    this.$router.push('/League/Writer/Webtoon/Viewer/' + wtIdx + '/' + epIdx);
                }
                else if(res.data.returnCode === 503){   //로그인 필요
                    this.isShowLoginPopup = true;
                }
                else{
                    console.log(res.data.dsscription);
                }
            })
            .catch((error) => {
                console.log(error);
            });
        },
        close(popupType){
            switch(popupType){
                case 'Login':
                    this.isShowLoginPopup = false;
                    break;
                case 'PopupOk':
                    this.isShowPopupOkPopup = false;
                    if(this.PopupOkCallbakPage === 'reload'){
                        location.reload();
                    }
                    else if(this.PopupOkCallbakPage !== ''){
                        this.$router.push(this.PopupOkCallbakPage);
                    }
                    this.PopupOkCallbakPage = '';
                    break;
            }
        },
        changeState(statePopup, state){
            switch(statePopup){
                case 'isOpenMypage':
                    this.isShowMypageState = state;
                    break;
            }
        },
    }
}
</script>