<template>
    <div id="noticenav">
        <ul>
            <router-link tag="li" to="/Notice" :class="{'on' : selectedType === 'Notice'}">공지사항</router-link>
            <router-link tag="li" to="/FAQ" :class="{'on' : selectedType === 'QnA'}">자주하는 질문</router-link>
            <router-link tag="li" to="/Contact" :class="{'on' : selectedType === 'Contact'}">1:1 문의하기</router-link>
        </ul>
    </div>
</template>

<script>
export default {
    name: 'NoticeNav',
    data(){
        return {
            colorType: this.selectedType,
        }
    },
    props: {
        selectedType : {
            type: String,
            default: 'Notice'
        },
    },
    created() {        
    },
    mounted(){
    },
    beforeDestroy() {
	},
    methods: {

    },
}
</script>