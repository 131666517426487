<template>
    <div class="league-floating" v-if="isShowFloating">
        <img src="@/assets/images/league-floating.png" @click="moveWriterInfo" />
    </div>
</template>

<script>
export default {
    name: 'LeagueFloating',
    data(){
        return {
            isShowFloating: false,
        }
    },
    created(){
        this.isShowFloating = this.isPc();
    },
    methods: {
        moveWriterInfo(){
                this.$router.push('/League/Writer/info');
            }
        },
}
</script>