<template>
    <div id="policy" v-if="initIsShowPopup">
        <div class="terms_box">
            <div class="title">
                <h1>주식회사 넘버스 청소년 보호정책</h1>
                <img src="@/assets/images/close.svg" alt="닫기" class="close" @click="closePopup">
            </div>
            <div class="article_box">
                <div class="article">
                    <p> 주식회사 넘버스(이하 "회사"라 합니다)는 회사가 운영하는 플랫폼 아튜브에서 청소년의 보호를 위하여 정보통신망 이용촉진 및 정보보호 등에 관한 법률 및 청소년보호법에 근거하여 아래와 같이 청소년보호정책을 수립, 시행하고 있습니다</p>
                </div>
                <div class="article">
                    <h2>1. 성인 작품 등에 대한 청소년 접근 제한 및 관리 조치</h2>
                    <p> 회사는 청소년이 아무런 제한장치 없이 청소년 유해정보에 접근하는 일이 발생하지 않도록 청소년 유해매체물에 대한 관련 법령에 따른 연령 확인 절차, 청소년 유해 간행물 표시 등의 별도 절차를 마련하여 청소년의 19 세 이상 이용가로 분류된 웹툰 작품에 대한 접근을 방지하고 있습니다.</p>
                </div>
                <div class="article">
                    <h2>2. 유해정보로부터의 청소년보호를 위한 업무 담당자 교육 시행</h2>
                    <p> 회사는 정보통신업무 종사자를 대상으로 청소년보호 관련 법령 및 제재 기준, 유해정보 발견 시 대처방법, 위반사항 처리에 대한 보고 절차 등을 교육하고 있습니다.</p>
                </div>
                <div class="article">
                    <h2>3. 청소년 유해정보로 인한 피해상담 및 고충처리</h2>
                    <p> 회사는 청소년 유해정보로 인한 피해상담 및 고충처리를 위한 전문인력을 배치하여 그 피해가 확산되지 않도록 하고 있습니다. 이용자 분들께서는 아래 4. 청소년보호 책임자 및 담당자의 소속, 성명 및 연락처 항을 참고하여 전화나 메일을 통하여 피해상담 및 고충처리를 요청할 수 있습니다.</p>
                </div>
                <div class="article">
                    <h2>4. 청소년보호 책임자 및 담당자의 소속, 성명 및 연락처</h2>
                    <p> 회사는 아래와 같이 청소년 보호 책임자 및 청소년 보호 담당자를 지정하여 청소년 보호를 위해 최선을 다하고 있습니다.</p>
                    <table class="person">
                        <th>청소년 보호 책임자</th>
                        <th>청소년 보호 담당자</th>
                        <tr>
                            <td>이름 : 전종경<br>소속 : 개발팀<br>직위 : CIO<br>전화 : 02-3444-5567<br>이메일 : contact@artube.video</td>
                            <td>이름 : 황진중<br>소속 : 사업실<br>직위 : 팀장<br>전화 : 02-3444-5567<br>이메일 : contact@artube.video</td>
                        </tr>
                    </table>
                    <p>※  이용자 피해구제 신고 및 청소년보호를 위한 관련기관안내</p>
                    <p class="middot"><span>&middot;</span> 개인정보침해신고센터 (privacy.kisa.or.kr / 국번없이 118)</p>
                    <p class="middot"><span>&middot;</span> 대검찰청 사이버수사과 (www.spo.go.kr / 국번없이 1301)</p>
                    <p class="middot"><span>&middot;</span> 경찰청 사이버수사국 (police.go.kr / 국번없이 182)</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Youth',
    data(){
        return {
        }
    },
    props: {
        initIsShowPopup: {
            type: Boolean,
            default: false
        }
    },
    created() {
    },
    mounted(){
    },
    beforeDestroy() {
	},
    methods: {
        closePopup(){
            this.$emit('close');
        }
    },
}
</script>