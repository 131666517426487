<template>
    <div class="chargehistory2 wrap">
        <Header :initHeaderType="initHeaderType" />
            <b-container>
                <div class="menu_left_box">
                    <Chargenav :selectedType="NavSelecteType" />
                </div>
                <div class="chargehistory2_box">
                    <div class="title">
                        <h1>포인트 충전내역</h1>
                    </div>
                    <div class="change-charge-history">
                        <router-link tag="p" to="/Point/History/Charge">일반 결제</router-link>
                        <router-link tag="p" to="/Point/History/Charge/ATT" class="on">ATT Coin 결제</router-link>
                    </div>
                    <div class="searchArea">
                        <div class="searchOption">
                            <!-- <select v-model="selectedCurrencyIdx" @change="changeCurrency()">
                                <option value="-1">전체 종목</option>
                                <option v-for="(item, index) in $store.state.currencyList" :key="index" :value="item.idx">{{item.name}}</option>
                            </select>
                            -->
                            <datepicker :format="customFormatter" class="date date1" v-model="startDate" :language="ko" v-on:closed="changeStartDate()"></datepicker>
                            <span class="wave">~</span>
                            <datepicker :format="customFormatter" class="date date2" v-model="endDate" :language="ko" v-on:closed="changeEndDate()"></datepicker>
                        </div>
                        <div class="button" @click="getChargeHistory">
                            <p>조회</p>
                        </div>
                    </div>
                    <div class="table_box">
                        <table>
                            <tr>
                                <th class="date">날짜</th>
                                <th>입금 ATT</th>
                                <th>기준시세 (KRW)</th>
                                <th>아트포인트 지급</th>
                                <th>보너스 포인트 지급</th>
                                <th>합계</th>
                                <th>상태</th>
                            </tr>
                            <tr class="list1" v-for="(item, index) in chargeHistoryList" :key="index" :class="[index % 2 === 1 ? 'dark' : '']">
                                <td>{{item.createDate.substring(0,16)}}</td>
                                <td>{{item.amount | comma}}</td>
                                <td>{{item.tokenPrice | comma}}</td>
                                <td>{{item.pointBase | comma}}</td>
                                <td>{{item.pointBonus | comma}}</td>
                                <td>{{item.pointBase + item.pointBonus | comma}}</td>
                                <td>
                                    <a class="state_complete" :href="$store.state.siteConfig.etherscanUrl + 'tx/' + item.txHash" target="_blank" v-if="$store.state.siteConfig !== null"><img src="@/assets/images/link.svg" /></a>
                                </td>
                            </tr>
                        </table>
                    </div>
                    <div class="no_history">
                        <img src="@/assets/images/icon_no_history.svg" />
                        <p>출금 내역이 없습니다.</p>
                    </div>
                    <Pagination :initMaxRecordCount="currentPageSize" :initMaxPageCount="maxPageCount" :initPagingData="initPagingData" @movePaging="movePaging" />
                </div>
            </b-container>
        <Footer />
    </div>
</template>

<script>
import Header from '@/components/common/Header.vue'
import Footer from '@/components/common/Footer.vue'
import Chargenav from '@/components/point/Chargenav.vue'
import Datepicker from 'vuejs-datepicker';
import {ko} from 'vuejs-datepicker/dist/locale'
import Pagination from '@/components/common/Pagination.vue'

export default {
    name: 'PointHistoryChargeATT',
    pageCategory: 'Point',
    pageTitle: '포인트(ATT) 충전내역',
    components: {
        Header,
        Footer,
        Chargenav,
        Datepicker,
        Pagination,
    },
    data() {
        return {
            NavSelecteType: 'ChargeHistory2',

            ko: ko,
            startDate: '',
            endDate: '',
            lastStartDate: '',
            lastEndDate: '',

            chargeHistoryList:[],

            currentPageNo: 1,      //현재 페이지 번호
            currentPageSize: 10,    //한번에 불러올 게시물 수
            maxPageCount:10,        //한 화면에 보여질 페이지 수
            initPagingData: null,

            initHeaderType: 'type2',
        }
    },
    async created(){
        // await this.mixinSiteConfigCheck();
        this.setDefaultDate();
        this.getChargeHistory();
    },
    methods:{
        customFormatter(date){
            return this.$moment(date).format('YYYY-MM-DD');
        },
        leadingZeros(n, digits) {
            var zero = '';
            n = n.toString();
            if (n.length < digits) {
                for (var i = 0; i < digits - n.length; i++)
                    zero += '0';
            }
            return zero + n;
        },
        setDefaultDate(){
            var startDate = new Date();
            var endDate = new Date();
            startDate.setDate(startDate.getDate() - 6);
            var convertStartDate = this.leadingZeros(startDate.getFullYear(), 4) + '-' + this.leadingZeros(startDate.getMonth() + 1, 2) + '-' + this.leadingZeros(startDate.getDate(), 2);
            var convertEndDate = this.leadingZeros(endDate.getFullYear(), 4) + '-' + this.leadingZeros(endDate.getMonth() + 1, 2) + '-' + this.leadingZeros(endDate.getDate(), 2);
            
            this.startDate = convertStartDate;
            this.lastStartDate = convertStartDate;
            
            this.endDate = convertEndDate;
            this.lastEndDate = convertEndDate;
        },
        changeStartDate(){
            var selectedStartDate = this.customFormatter(new Date(this.startDate));
            this.startDate = selectedStartDate;

            if(this.lastStartDate === this.startDate){
                return;
            }
            else{
                this.lastStartDate = this.startDate;
            }
        },
        changeEndDate(){
            var selectedEndDate = this.customFormatter(new Date(this.endDate));
            this.endDate = selectedEndDate;
            
            if(this.lastEndDate === this.endDate){
                return;
            }
            else{
                this.lastEndDate = this.endDate;
            }
        },
        getChargeHistory(){
            var param = 
            {
                apiStartDate: this.startDate,
                apiEndDate: this.endDate,
                pageNo: this.currentPageNo,
                pageSize: this.currentPageSize,
                sign: this.$store.state.sign,
                ticket: this.$store.state.ticket,
            }
            this.$Axios.post(`${this.host}/erc20/deposit/history`, param, { headers: { 'Pragma' : 'no-cache', 'Cache-Control': 'no-cache', 'Access-Control-Allow-Origin': '*', 'Content-type': 'application/json', }}).then(async(res) => {
                // console.log(res.data);
                if (res.data.returnCode === 0) {
                    this.chargeHistoryList = res.data.list;
                    this.initPagingData = res.data.page;
                }
                else{
                    this.$router.push('/');
                }
            })
            .catch((error) => {
                console.log(error);
            });
        },
        movePaging(pageNo){
            this.currentPageNo = pageNo;
            this.getChargeHistory();
        },
    }
}
</script>