<template>
    <div class="airdrop">
        <Header :initHeaderType="initHeaderType" />
        <div class="top">
            <b-container>
                <p class="text1">GOVERNANCE &amp; NFT Market <span>Pre-Open Event!</span></p>
                <img src="@/assets/images/event/airdrop/title.svg" class="title" />
                <p class="text2">Governance 모델, NFT 마켓 <span>오픈기념 사전 이벤트!</span></p>
                <img src="@/assets/images/event/airdrop/day.png" class="day" />
            </b-container>
        </div>
        <div class="bottom">
            <b-container>
                <p class="white">플랫폼의 미래를 여러분이 직접 결정하는<span>Governance 모델과 NFT 마켓이 곧 오픈합니다.</span><span>오픈을 대비해 지갑 인증하고,</span>아튜브에서 매일매일 에어드랍을 받아보세요!</p>
                <p class="term-title">이벤트 기간</p>
                <p class="term">2022년 5월 9일(월) ~ 2022년 5월 23일(월) 23:59 <span>(KST)</span></p>
            <b-row class="participate-title">
                <b-col class="line"></b-col>
                <b-col class="text">Event 참여방법</b-col>
                <b-col class="line"></b-col>
            </b-row>
            <div class="participate-box">
                <div class="left">
                    <img src="@/assets/images/event/airdrop/step1.svg" class="step" />
                    <p class="white">카카오에서 클립(Klip) 지갑을<span>만들어주세요.</span></p>
                    <a href="https://klipwallet.com" class="button" target="_blank">클립지갑 생성 가이드</a>
                </div>
                <img src="@/assets/images/event/airdrop/klip.png" class="right" />
            </div>
            <div class="participate-box step2">
                <div class="left">
                    <img src="@/assets/images/event/airdrop/step2.svg" class="step" />
                    <p class="white">만든 지갑 주소를 코인원에서<span>출금 주소로 등록해 주세요.</span></p>
                </div>
                <img src="@/assets/images/event/airdrop/address.png" class="right" />
            </div>
            <div class="participate-box">
                <div class="left">
                    <img src="@/assets/images/event/airdrop/step3.svg" class="step" />
                    <p class="white">인증 완료된 주소의 스크린샷을<span>아래 구글폼에 인증하면 응모가</span>완료됩니다.</p>
                    <a href="https://forms.gle/KHthvb74gfe4Uv2CA" class="button" target="_blank">구글폼 입력하기</a>
                </div>
                <img src="@/assets/images/event/airdrop/form-photo.png" class="right" />
            </div>
            <b-row class="participate-title guide">
                <b-col class="line"></b-col>
                <b-col class="text">에어드랍 안내</b-col>
                <b-col class="line"></b-col>
            </b-row>
            <div class="guide-text">
                <p>- 지갑 주소당 1회만  참가 가능하며, 에어드랍은 인증 완료 확인 후 지급됩니다.<span>- 코인원의 등록된 지갑 주소는 반드시 거래소 인증이 완료된 지갑이어야 합니다.</span><span>- 클립 지갑 외, 카이카스(Kaikas) 지갑도 무방하며, 이미 생성하신 분도 참여 가능합니다.</span></p>
            </div>
            </b-container>
        </div>
        <Footer />
    </div>
</template>

<script>
import Header from '@/components/common/Header.vue'
import Footer from '@/components/common/Footer.vue'

export default {
    name: 'EventAirDrop',
    pageTitle: '이벤트',
    components: {
        Header,
        Footer,
    },
    data(){
        return {
            initHeaderType: 'type2',
        }
    },
    created() {
    },
    mounted(){
    },
    beforeDestroy() {
	},
    methods: {
    },
}
</script>