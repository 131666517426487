<template>
    <div class="notice wrap">
        <Header :initHeaderType="initHeaderType" />
            <b-container>
                <div class="menu_left_box">
                    <Noticenav :selectedType="NavSelecteType" />
                </div>
                <div class="notice_box">
                    <div class="title">
                        <h1>공지사항</h1>
                    </div>
                    <div class="accordion np_tab" role="tablist">
                        <b-card class="head">
                            <b-row>
                            <b-col>제목</b-col>
                            <b-col>날짜</b-col>
                            </b-row>
                        </b-card>
                        <b-card no-body class="mb-1" v-for="(item, index) in noticeList" :key="index">
                            <b-card-header header-tag="header" class="p-1" role="tab">
                                <b-button block v-b-toggle="'accordion-' + index" variant="info">
                                    <router-link tag="b-row" :to="'/Notice/'+item.idx">
                                        <b-col>[공지] {{item.title}} </b-col>
                                        <b-col>{{item.createDate.toString().substring(0,10)}}</b-col>
                                    </router-link>
                                </b-button>
                            </b-card-header>
                        </b-card>
                    </div>
                    <Pagination :initMaxRecordCount="currentPageSize" :initMaxPageCount="maxPageCount" :initPagingData="initPagingData" @movePaging="movePaging" />
                </div>
            </b-container>
        <Footer />
    </div>
</template>

<script>
import Header from '@/components/common/Header.vue'
import Footer from '@/components/common/Footer.vue'
import Noticenav from '@/components/support/Noticenav.vue'
import Pagination from '@/components/common/Pagination.vue'

export default {
    name: 'Notice',
    pageCategory: 'Common',
    pageTitle: '공지사항',
    components: {
        Header,
        Footer,
        Noticenav,
        Pagination,
    },
    data() {
        return {
            NavSelecteType: 'Notice',
            // rows: 10,
            // perPage: 1,

            noticeList: [],

            currentPageNo: 1,      //현재 페이지 번호
            currentPageSize: 10,    //한번에 불러올 게시물 수
            maxPageCount:10,        //한 화면에 보여질 페이지 수
            initPagingData: null,

            initHeaderType: 'type2',
        }
    },
    created(){
        this.getNotice();
    },
    methods:{
        getNotice(){
            var param = 
            {
                pageNo: this.currentPageNo,
                pageSize: this.currentPageSize,
                sign: this.$store.state.sign,
                ticket: this.$store.state.ticket
            }
            this.$Axios.post(`${this.host}/newNotice/list`, param, { headers: { 'Pragma' : 'no-cache', 'Cache-Control': 'no-cache', 'Access-Control-Allow-Origin': '*', 'Content-type': 'application/json', }}).then(async(res) => {
                // console.log(res.data);
                if (res.data.returnCode === 0) {
                    this.noticeList = res.data.list;
                    this.initPagingData = res.data.page;
                }
            })
            .catch((error) => {
                console.log(error);
            });
        },
        movePaging(pageNo){
            this.currentPageNo = pageNo;
            this.getNotice();
        },
    }
}

</script>