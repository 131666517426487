var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"viewer"}},[_c('Header',{attrs:{"initHeaderType":_vm.initHeaderType,"initIsHeaderShow":_vm.isShowViewer},on:{"changeState":_vm.changeState}}),_c('LeagueMyViewerheader',{attrs:{"initWebtoonInfo":_vm.webtoonInfo,"initEpisodeIdx":_vm.currEpisodeIdx,"initEpisodeState":_vm.episodeState,"initNextEpisodeIdx":_vm.nextEpisodeIdx,"initPreEpisodeIdx":_vm.preEpisodeIdx,"initWriterInfo":_vm.writerInfo,"initisShowViewer":_vm.isShowViewer}}),_c('div',{staticClass:"container"},[_vm._l((_vm.imageList),function(item,index){return _c('img',{key:index,attrs:{"src":_vm.league_host + '/challenge/episode/image/view/' 
                    + _vm.$store.state.ticket + '/'
                    + _vm.$store.state.sign + '/'
                    + _vm.writerInfo.cwWriterIdx + '/'
                    + _vm.currWebtoonIdx + '/'
                    + _vm.currEpisodeIdx + '/'
                    + item.ciPageIdx},on:{"click":_vm.touchImg}})}),_vm._m(0),_c('p',{staticClass:"logo"},[_vm._v("artube")]),(_vm.episodeList.length > 0)?_c('div',{staticClass:"group"},[_c('div',{staticClass:"prev",on:{"click":function($event){return _vm.prevPage()}}},[_vm._v("prev")]),_vm._l((_vm.episodeList),function(item,index){return _c('div',{key:index,staticClass:"toon",class:[item.ceEpisodeIdx === _vm.currEpisodeIdx ? 'now' : 'secret'],on:{"click":function($event){return _vm.changeEpisode(_vm.currWebtoonIdx, item.ceEpisodeIdx)}}},[(_vm.webtoonInfo !== null && _vm.$store.state.siteConfig !== null)?_c('img',{staticClass:"ssum",attrs:{"src":_vm.$store.state.siteConfig.cdnUrl + '/CHALLENGE/thumbnail/' + _vm.webtoonInfo.ctCdnFolder + '/' + item.ceUrl,"alt":"썸네일"}}):_vm._e(),_c('p',[_vm._v(_vm._s(item.ceEpisodeIdx)+"화")])])}),_c('div',{staticClass:"next",on:{"click":function($event){return _vm.nextPage()}}},[_vm._v("next")])],2):_vm._e(),_c('div',{staticClass:"buttons2"},[_c('div',{staticClass:"list2",on:{"click":_vm.backList}},[_vm._v("목록 보기")])])],2),_c('Footer'),_c('LeagueMyViewermove',{attrs:{"initWebtoonInfo":_vm.webtoonInfo,"initEpisodeState":_vm.episodeState,"initNextEpisodeIdx":_vm.nextEpisodeIdx,"initPreEpisodeIdx":_vm.preEpisodeIdx,"initisShowViewer":_vm.isShowViewer}}),_c('Login',{attrs:{"initIsShowPopup":_vm.isShowLoginPopup},on:{"close":function($event){return _vm.close('Login')}}}),_c('PopupOK',{attrs:{"initIsShowPopup":_vm.isShowPopupOkPopup,"initPopupOkMessage":_vm.initPopupOkMessage},on:{"close":function($event){return _vm.close('PopupOk')}}})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"warn"},[_vm._v("아튜브의 모든 콘텐츠는 저작권 법에 의거하여 보호받고 있습니다. "),_c('br'),_vm._v("저작권자 또는 아튜브의 승인없이 콘텐츠의 일부 또는 전부를 복제, 변경, 전송, 배포 등의 "),_c('br'),_vm._v("방법으로 이용할 경우 "),_c('span',[_vm._v("저작권법에 의거하여 법적 조치에 처해질 수 있습니다.")])])
}]

export { render, staticRenderFns }