<template>
    <div class="paybackSwap popup" v-if="initIsShowPopup">
        <div class="popupBox">
            <div class="popupSwapHead">
                <p>페이백 포인트 스왑</p>
                <a class="close" @click="closePopup"><img src="@/assets/images/assist/close.svg" /></a>
            </div>
            <div class="have">
                <p>보유중인 페이백 포인트</p>
                <p class="point">{{ (Math.floor(initSupportMyInfo.payBackPoint * 10000) / 10000 ) | comma }} P</p>
            </div>
            <div class="will-swap">
                <p>스왑 할 포인트</p>
                <div>
                    <input type="text" v-model="swapPoint" @keyup="commasSwapPoint()">
                    <button @click="setMaxAmount">최대</button>
                </div>
            </div>
            <div class="ps">
                <p>* 페이백 포인트와 아트포인트는 1:1 비율로 계산 됩니다.</p>
                <p>* 정수 단위로만 아트포인트로 스왑이 가능합니다.</p>
            </div>
            <button class="swap" @click="Swap()">스왑</button>
        </div>
    </div>
</template>

<script>
export default {
    name: 'PopupPaybackSwap',
    data(){
        return {
            have: 3.1544,
            swapPoint: 0,
            numberSwapPoint: 0,
        }
    },
    props: {
        initIsShowPopup: {
            type: Boolean,
            default: false
        },
        initSupportMyInfo: {
            tpye: Object,
            default: null
        }
    },
    async created() {
    },
    mounted(){
    },
    beforeDestroy() {
	},
    methods: {
        Swap(){ // 스왑하기
            var param =
            {
                ticket: this.$store.state.ticket,
                sign: this.$store.state.sign,
                viewAdult: this.$store.state.isCheckedAdult ? 1 : 0,

                point: this.numberSwapPoint,
            }
            this.$Axios.post(`${this.host}/support/swap`, param, { headers: { 'Pragma' : 'no-cache', 'Cache-Control': 'no-cache', 'Access-Control-Allow-Origin': '*', 'Content-type': 'application/json', }}).then(res => {
                // console.log(res.data)
                if (res.data.returnCode === 0) {
                    this.swapPoint = 0;
                    this.numberSwapPoint = 0;
                    this.$emit('close');
                    this.$emit('openOK');
                }
            })
            .catch((error) => {
                console.log(error);
            });
        },
        commasSwapPoint() {
            var x = this.swapPoint.toString();
            x = x.replace(/[^0-9]/g, "");
            // x = Number(x).toString();
            var decimalNumber = '';
            if(x.indexOf('.', 0) >= 0){
                decimalNumber = x.substring(x.indexOf('.', 0));
            }
            else{
                x = Number(x).toString();
            }

            if(decimalNumber.length > 1){
                if(Number(decimalNumber.substring(1)) > 0){
                    x = Number(x.substring(0, x.indexOf('.'))).toString();
                    x += decimalNumber.substring(0,5);
                }
            }
            
            if(Math.floor(this.initSupportMyInfo.payBackPoint) < Number(x)){
                x = Math.floor(this.initSupportMyInfo.payBackPoint).toString();
            }
            this.swapPoint = this.setComma(x);
            this.numberSwapPoint = Number(this.swapPoint.replace(/,/g, ""));
        },
        setComma(obj) {
            var regx = new RegExp(/(-?\d+)(\d{3})/);
            var bExists = obj.indexOf(".", 0);//0번째부터 .을 찾는다.
            var strArr = obj.split('.');
            while (regx.test(strArr[0])) {//문자열에 정규식 특수문자가 포함되어 있는지 체크
                //정수 부분에만 콤마 달기 
                strArr[0] = strArr[0].replace(regx, "$1,$2");//콤마추가하기
            }
            if (bExists > -1) {
                //. 소수점 문자열이 발견되지 않을 경우 -1 반환
                obj = strArr[0] + "." + strArr[1];
            } else { //정수만 있을경우 //소수점 문자열 존재하면 양수 반환 
                obj = strArr[0];
            }
            return obj;//문자열 반환
        },
        setMaxAmount(){
            this.swapPoint = Math.floor(this.initSupportMyInfo.payBackPoint);
            this.commasSwapPoint()
        },
        closePopup(){
            this.swapPoint = 0;
            this.numberSwapPoint = 0;
            this.$emit('close');
        },
    },
}
</script>