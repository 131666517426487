<template>
    <div class="eventcategory wrap">
        <Header :initHeaderType="initHeaderType" />
        <b-container v-if="$store.state.siteConfig !== null">
            <b-row>
                <b-col tag="div" md="4" sm="6" cols="12" v-for="(item, index) in EventList" :key="index">
                    <img :src="$store.state.siteConfig.cdnUrl + item.epPath + item.epFileName" @click="clickEvent(item)" />
                </b-col>
            </b-row>
        </b-container>
        <Pagination :initMaxRecordCount="currentPageSize" :initMaxPageCount="maxPageCount" :initPagingData="initPagingData" @movePaging="movePaging" />
        <Login :initIsShowPopup="isShowLoginPopup" @close="close('Login')" />
        <PopupOK :initIsShowPopup="isShowPopupOkPopup" :initPopupOkMessage="initPopupOkMessage" @close="close('PopupOk')" />
        <Footer />
    </div>
</template>

<script>
import Header from '@/components/common/Header.vue'
import Footer from '@/components/common/Footer.vue'
import Login from '@/components/common/Login.vue'
import Pagination from '@/components/common/Pagination.vue'
import PopupOK from '@/components/common/PopupOK.vue'

export default {
    name: 'Event',
    pageCategory: 'Event',
    components: {
        Header,
        Footer,
        Login,
        Pagination,
        PopupOK,
    },
    data() {
        return {
            EventList: [],

            currentPageNo: 1, 
            currentPageSize: 9,
            maxPageCount: 10,
            initPagingData: null,

            isShowPopupOkPopup: false,
            initPopupOkMessage: '',
            PopupOkCallbakPage: '',    // 확인 팝업에서 확인 버튼 눌렀을때 이동할 페이지

            isShowLoginPopup: false,

            initHeaderType: 'type1',
        }
    },
    created(){
        this.getEventCategory();
    },
    methods:{
        getEventCategory(){
            var param = 
            {
                pageNo: this.currentPageNo,
                pageSize: this.currentPageSize,
                sign: this.$store.state.sign,
                ticket: this.$store.state.ticket,
                viewAdult: this.$store.state.isCheckedAdult ? 1 : 0
            }
            this.$Axios.post(`${this.host}/eventpage/list`, param, { headers: { 'Pragma' : 'no-cache', 'Cache-Control': 'no-cache', 'Access-Control-Allow-Origin': '*', 'Content-type': 'application/json', }}).then(async(res) => {
                // console.log(res.data);
                if (res.data.returnCode === 0) {
                    this.EventList = res.data.list;
                    this.initPagingData = res.data.page;
                }
            })
            .catch((error) => {
                console.log(error);
            });
        },
        movePaging(pageNo){
            // console.log(pageNo);
            this.currentPageNo = pageNo;
            this.getEventCategory();
        },
        async movePage(item){
            if(item.epLinkType === 0){
                var ressult = await this.eventClick(item.epIdx);
                if(ressult){
                    if(item.epNewPage === null || item.epNewPage === 0){
                        location.href = item.epLink;
                    }
                    else if(item.epNewPage === 1){
                        window.open(item.epLink);
                    }
                }
            }
        },
        async clickEvent(eventInfo){
            // console.log(bannerInfo);
            // private Integer newPage;    새로운페이지여부(1:새창 0:현재페이지)
            // private Integer linkType;	링크타입(0:풀URL 1:내부 라우터 2:웹툰)

            var linkUrl = eventInfo.epLink;
            var linkType = eventInfo.epLinkType;
            var newPage = eventInfo.epNewPage;
            await this.reqEventClick(eventInfo.idx);      //배너 클릭 카운팅

            if(linkType === 2){     //웹툰 링크
                var webtoonInfo = await this.checkWebtoon(linkUrl);
                if(webtoonInfo.adult === 1){
                    if(this.$store.state.loginCheck === false){
                        this.isShowLoginPopup = true;
                        return;
                    }
                    else if(!this.$store.state.adult){
                        var src = this.$store.state.siteConfig.niceUrl + '?email=' + this.$store.state.email;
                        window.open(src, 'popupChk', 'width=500, height=550, top=100, left=100, fullscreen=no, menubar=no, status=no, toolbar=no, titlebar=no, location=no, scrollbar=no');
                        return;
                    }
                    else{
                        linkUrl = '/Webtoon/Info/' + webtoonInfo.webToonIndex;
                    }
                } else {
                    linkUrl = '/Webtoon/Info/' + webtoonInfo.webToonIndex;
                }
            }

            if(newPage === 0){   //현재 창에서 이동
                if(linkType === 0){  //외부 링크
                    location.href = linkUrl;
                } else {
                    this.$router.push(linkUrl)
                }
            }
            else if(newPage === 1){  //새창으로 열기
                if(linkType !== 0){
                    let route = this.$router.resolve({path: linkUrl});
                    linkUrl = route.href;
                }
                window.open(linkUrl);
            }
        },
        async reqEventClick(idx){
            var param = 
            {
                eventIndex: idx
            }
            return await this.$Axios.post(`${this.host}/eventpage/click`, param, { headers: { 'Pragma' : 'no-cache', 'Cache-Control': 'no-cache', 'Access-Control-Allow-Origin': '*', 'Content-type': 'application/json', }}).then(async(res) => {
                if(res.data.returnCode === 0){
                    return true;
                }
                else {
                    return false;
                }
                
            })
            .catch((error) => {
                console.log(error);
                return false;
            });
        },
        async checkWebtoon(webtoonIdx){
            var param = 
            {
                idx: webtoonIdx,
                sign: this.$store.state.sign,
                ticket: this.$store.state.ticket,
                viewAdult: this.$store.state.isCheckedAdult ? 1 : 0,
            }
            return await this.$Axios.post(`${this.host}/webtoon`, param, { headers: { 'Pragma' : 'no-cache', 'Cache-Control': 'no-cache', 'Access-Control-Allow-Origin': '*', 'Content-type': 'application/json', }}).then(async(res) => {
                // console.log('--webtoon info--')
                // console.log(res.data);
                if (res.data.returnCode === 0) {
                    return res.data.data;
                }
                else if(res.data.returnCode === 300){
                    this.initPopupOkMessage = '해당 작품은 운영상의 문제에 위반되어 열람 및 구매 할 수 없습니다.';
                    this.PopupOkCallbakPage = '';
                    this.isShowPopupOkPopup = true;
                    return null;
                }
                else if(res.data.returnCode === 301){
                    if(this.$store.state.loginCheck === false){
                        this.isShowLoginPopup = true;
                    }
                    else if(!this.$store.state.adult){
                        var src = this.$store.state.siteConfig.niceUrl + '?email=' + this.$store.state.email;
                        window.open(src, 'popupChk', 'width=500, height=550, top=100, left=100, fullscreen=no, menubar=no, status=no, toolbar=no, titlebar=no, location=no, scrollbar=no');
                    }
                    return null;
                }
                else {
                    return null;
                }
                
            })
            .catch((error) => {
                console.log(error);
                return null;
            });
        },
        close(popupType){
            switch(popupType){
                case 'Login':
                    this.isShowLoginPopup = false;
                    break;
                 case 'PopupOk':
                    this.isShowPopupOkPopup = false;
                    if(this.PopupOkCallbakPage === 'reload'){
                        location.reload();
                    }
                    else if(this.PopupOkCallbakPage !== ''){
                        this.$router.push(this.PopupOkCallbakPage);
                    }
                    this.PopupOkCallbakPage = '';
                    break;
            }
        }
    }
}
</script>